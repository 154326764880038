import { useCallback, useState } from "react";
import { Form, Row, Col, Select, Button, Modal, Input, message, Spin } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { getObjProp } from "@/utils";
import { allBusinessLine, allSupplier } from "@/utils/constant";
import { getValidFirmCode } from "@/api/systemSetting";

import { getFeedOption, addUser, saveEmailId } from "@/api/userManagement";
const { Option } = Select;

// 定义函数组件的props相关参数
type myProps = {
  doUpdateList?: () => void;
  btnStyle?: any;
};

const AddNewUser = (props: myProps) => {
  const [form] = Form.useForm();
  const [showFirm, setShowFirm] = useState(false);
  const [isFirmId, setIsFirmId] = useState(false);
  const [isAddUserVisible, setIsAddUserVisible] = useState(false);
  const [isFeedMillUser, setIsFeedMillUser] = useState(false);
  const [companyInfoLoading, setCompanyInfoLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [feedOptions, setFeedOptions] = useState<any[]>([]);
  const [firmDetails, setFirmDetails] = useState({
    firmCode: "",
    customerType: "",
    cargillOrganization1Code: "",
    cargillOrganization1Description: "",
    cargillOrganization2Code: "",
    cargillOrganization2Description: "",
    cargillOrganization4Code: "",
    cargillOrganization4Description: "",
    customerTypeDescription: null,
    accountNumber: "",
    accountLongName: "",
    parentAccountNumber: null,
    parentAccountLongName: null,
    customerRelationship: ""
  });
  const rules = [
    { required: true },
    { message: "请输入中文或英文字符", validateTrigger: "onblur" },
    () => ({
      validator(_: any, values: any) {
        const regStr = /^[a-zA-Z\u4e00-\u9fa5]+$/;
        if (!regStr.test(values)) {
          return Promise.reject("请输入中文或英文字符");
        }
        return Promise.resolve();
      }
    })
  ];

  const onHandleAddUserModalOpen = useCallback(() => {
    setIsAddUserVisible(true);
  }, []);

  const getAppConfig = (key = "") => getObjProp(key, window.__APP_CONFIG__);

  const handleFeedOption = () => {
    getFeedOption({ firmCode: form.getFieldValue("companyId") || "" }).then(res => {
      if (res?.data[0]) {
        setFeedOptions(res.data);
      }
    });
  };

  const handleFirmDetail = useCallback(() => {
    if (form.getFieldValue("feedMillUser") === 1) {
      handleFeedOption();
    }
    if (form.getFieldValue("companyId")) {
      const request = {
        accountNumber: form.getFieldValue("companyId")
      };
      setCompanyInfoLoading(true);
      getValidFirmCode(request)
        .then(res => {
          if (res.data.customerInfoEntitlement.accountLongName) {
            setShowFirm(true);
            setIsFirmId(true);
            setFirmDetails(res.data.customerInfoEntitlement);
          } else {
            setShowFirm(true);
            setIsFirmId(false);
            form.setFieldsValue({ companyId: null });
            setFirmDetails({
              firmCode: "",
              customerType: "",
              cargillOrganization1Code: "",
              cargillOrganization1Description: "",
              cargillOrganization2Code: "",
              cargillOrganization2Description: "",
              cargillOrganization4Code: "",
              cargillOrganization4Description: "",
              customerTypeDescription: null,
              accountNumber: "",
              accountLongName: "",
              parentAccountNumber: null,
              parentAccountLongName: null,
              customerRelationship: ""
            });
          }
        })
        .finally(() => setCompanyInfoLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const onFeedMillUserChange = useCallback(() => {
    if (form.getFieldValue("feedMillUser") === 1) {
      setIsFeedMillUser(true);
      handleFeedOption();
    } else {
      setIsFeedMillUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleFirmIdChange = useCallback(() => {
    if (!form.getFieldValue("companyId")) {
      setShowFirm(false);
    }
  }, [form]);

  const handleCancel = useCallback(() => {
    setIsAddUserVisible(false);
    form.resetFields();
    setFirmDetails({
      firmCode: "",
      customerType: "",
      cargillOrganization1Code: "",
      cargillOrganization1Description: "",
      cargillOrganization2Code: "",
      cargillOrganization2Description: "",
      cargillOrganization4Code: "",
      cargillOrganization4Description: "",
      customerTypeDescription: null,
      accountNumber: "",
      accountLongName: "",
      parentAccountNumber: null,
      parentAccountLongName: null,
      customerRelationship: ""
    });
    setIsFeedMillUser(false);
  }, [form]);

  const onFinish = useCallback(
    (values: any) => {
      const handleEntitlements = (values: any) => {
        const requestorApp = getAppConfig("requestorApp");
        const requestorType = getAppConfig("requestorType");
        const controlType = getAppConfig("controlType");
        return [
          {
            requestorApp,
            requestorType,
            requestorGroup: values.requestorGroup,
            controlType,
            controlIdentifier: values.companyId,
            otherControls: {
              organizationName: firmDetails.accountLongName,
              companyAccountType: firmDetails.customerType,
              companyType: "信用用户"
            }
          }
        ];
      };

      const entitlements = handleEntitlements(values);
      const judgeGROUP = entitlements.map(item => item.otherControls.companyAccountType);
      if (entitlements.length > 1 && judgeGROUP.indexOf("GROUP") === -1) {
        message.error("未添加集团账户代码");
        return;
      }

      const userObj = {
        profile: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          locale: getAppConfig("userLocale") || "zh_CN",
          primaryPhone: values.primaryPhone,
          businessLine: values.businessLine,
          plant: values.plant
        },
        groups: [values.requestorGroup, getAppConfig("externalUserUniqueID")],
        entitlements,
        isActivate: "false"
      };
      setActionLoading(true);
      addUser(userObj)
        .then((res: any) => {
          if (res.data) {
            Modal.success({
              title: "邀请邮件发送成功",
              content: `您的邀请已被发送至${res.data.profile.lastName}${res.data.profile.firstName}待账户激活`
            });
            if (values.feedMillUser === 1) {
              const emailRequest = {
                feedCode: values.destination,
                oktaId: values.email,
                status: "create"
              };
              saveEmailId(emailRequest, {});
            }
            props?.doUpdateList?.();
          } else if (res.error) {
            Modal.success({ title: "该用户已添加！", content: "您输入的用户已被授予对系统的访问权限。" });
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          message.error("添加失败，请稍后重试。");
        })
        .finally(() => {
          setActionLoading(false);
          setIsAddUserVisible(false);
          form.resetFields();
          setFirmDetails({
            firmCode: "",
            customerType: "",
            cargillOrganization1Code: "",
            cargillOrganization1Description: "",
            cargillOrganization2Code: "",
            cargillOrganization2Description: "",
            cargillOrganization4Code: "",
            cargillOrganization4Description: "",
            customerTypeDescription: null,
            accountNumber: "",
            accountLongName: "",
            parentAccountNumber: null,
            parentAccountLongName: null,
            customerRelationship: ""
          });
          setIsFeedMillUser(false);
        });
    },
    [firmDetails.accountLongName, firmDetails.customerType, form, props]
  );

  const getCompanyInfoTipsDom = () => {
    if (showFirm) {
      return isFirmId ? (
        <div style={{ fontWeight: 500, fontSize: 12, color: "#191919", marginTop: -25 }}>
          {firmDetails.accountLongName}
        </div>
      ) : (
        <div style={{ fontWeight: 600, fontSize: 12, color: "#d80842", marginTop: -25 }}>
          <ExclamationCircleFilled style={{ color: "#cd0d15", marginRight: 8 }} />
          请输入有效的公司代码！
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Button data-testid="test-add-new-user" onClick={onHandleAddUserModalOpen} type="primary" style={props.btnStyle}>
        添加外部用户
      </Button>
      <Modal
        forceRender
        className="add-modal"
        title="添加外部用户"
        visible={isAddUserVisible}
        onCancel={handleCancel}
        footer={null}
        width={1240}
        maskClosable={false}
      >
        <Spin spinning={actionLoading}>
          <Form
            layout="vertical"
            name="add_user_form"
            form={form}
            onFinish={onFinish}
            initialValues={{
              feedMillUser: 2,
              plant: "",
              businessLine: ""
            }}
          >
            <Row gutter={[16, 16]}>
              <Col md={6} xs={12}>
                <Form.Item label="姓氏" name="lastName" rules={rules}>
                  <Input data-testid="test-lastName-input" placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col md={6} xs={12}>
                <Form.Item label="名字" name="firstName" rules={rules}>
                  <Input data-testid="test-firstName-input" placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col md={6} xs={12}>
                <Form.Item
                  label="用户联系方式"
                  name="primaryPhone"
                  rules={[
                    { required: true },
                    () => ({
                      validator(_, value) {
                        const reg = /^[1-9]\d{10}$/;
                        if (!reg.test(value)) {
                          value = value?.replace(/\D/g, "");
                          if (value?.length > 11) {
                            value = value.substring(0, 11);
                          }
                          form.setFieldsValue({ primaryPhone: value });
                          return Promise.reject("联系方式应为11位数字");
                        }
                        return Promise.resolve();
                      }
                    })
                  ]}
                >
                  <Input data-testid="test-primaryPhone-input" placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col md={6} xs={12}>
                <Form.Item
                  label="邮箱"
                  name="email"
                  rules={[
                    { required: true },
                    () => ({
                      validator(_, value) {
                        const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
                        if (!reg.test(value)) {
                          return Promise.reject("请输入正确的邮箱地址");
                        }
                        return Promise.resolve();
                      }
                    })
                  ]}
                >
                  <Input data-testid="test-email-input" placeholder="请输入" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col md={6} xs={12} className="black-label">
                <Form.Item label="用户类型" name="requestorGroup" rules={[{ required: true }]}>
                  <Select data-testid="test-requestorGroup-select" placeholder="请选择">
                    <Option value="DXP_DSC_Role_CN_Manager">采购经理</Option>
                    <Option value="DXP_DSC_Role_CN_Staff">采购内勤</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} xs={12} className="black-label">
                <Form.Item label="是否为饲料厂用户" name="feedMillUser">
                  <Select data-testid="test-feedMillUser-select" placeholder="请选择" onChange={onFeedMillUserChange}>
                    <Option value={1}>是</Option>
                    <Option value={2}>否</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} xs={12}>
                <Form.Item label="公司代码" name="companyId" rules={[{ required: true }]}>
                  <Input
                    data-testid="test-companyId-input"
                    placeholder="请输入"
                    onBlur={handleFirmDetail}
                    onChange={handleFirmIdChange}
                  />
                </Form.Item>
                <Spin spinning={companyInfoLoading}>{getCompanyInfoTipsDom()}</Spin>
              </Col>
              {isFeedMillUser ? (
                <Col md={6} xs={12}>
                  <Form.Item label="目的地" name="destination" rules={[{ required: true }]}>
                    <Select data-testid="test-destination-select">
                      {feedOptions.map(item => (
                        <Option value={item.feedCode} key={item.feedCode}>
                          {item.feedName}-{item.feedCode}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : null}

              <Col md={6} xs={12}>
                <Form.Item label="卖方" name="plant" rules={[{ required: true }]}>
                  <Select data-testid="test-plant-select" options={allSupplier()} />
                </Form.Item>
              </Col>
              <Col md={6} xs={12}>
                <Form.Item label="业务线" name="businessLine" rules={[{ required: true }]}>
                  <Select data-testid="test-businessLine-select" options={allBusinessLine()} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="align-right">
                  <Form.Item>
                    <Button data-testid="test-submit-add-btn" type="primary" htmlType="submit">
                      确认添加
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default AddNewUser;
