import React, { useState, useEffect, useRef, useCallback } from "react";
import { Card, Table, ConfigProvider, message } from "antd";
import moment from "moment";
import "./index.scss";
import { sendSmsInfo } from "@/api/systemSetting";
import MessageSearchFilters from "../components/messageSearchFilters";
import { supplierList } from "@/utils/constant";

const MessageRecord = () => {
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState({
    sendStatus: 1,
    limit: 10,
    offset: 0
  });

  const messageFilters_Ref: any = useRef();
  const [messageFiltersData, setMessageFiltersData] = useState({
    siteCode: null, // 工厂编码
    siteName: null, // 工厂名称
    platform: null, // 发货平台
    categoryName: null // 产品类型
  });
  // 预约状态
  const bookingStatus = [
    { label: "待预约", value: "1" },
    { label: "已预约", value: "2" },
    { label: "所有司机", value: "" }
  ];
  // 物流状态
  const logisticsStatus = [
    { label: "已分组未开卡", value: "1" },
    { label: "已开卡未称重", value: "2" },
    { label: "所有状态（不包含已离场）", value: "" }
  ];

  useEffect(() => {
    // 获取短信通知记录
    const getListData = () => {
      setLoading(true);
      const values = requestData;
      const queryParams = { ...messageFiltersData, sendStatus: 1 };
      sendSmsInfo(values, queryParams)
        .then((responseJSON: any) => {
          if (responseJSON.code && responseJSON.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(responseJSON.errorMsg);
          } else if (responseJSON?.data) {
            const tableRecordData = Array.isArray(responseJSON.data.resultList) ? responseJSON.data.resultList : [];
            setTotal(responseJSON.data.metadata?.totalCount ?? 0);
            setData(tableRecordData);
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          message.error("获取数据失败，请稍后重试。");
          setData([]);
          setTotal(0);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getListData();
  }, [requestData, messageFiltersData]);

  // 通过value展示label
  const showLabel = (value: string, array: any[]) => {
    const filterResults = array.filter(item => item.value === value);
    if (filterResults && filterResults.length > 0) {
      return filterResults[0].label;
    }
    return "";
  };

  const onPageChange = (page: number) => {
    setCurrent(page);
    const pager = JSON.parse(JSON.stringify(requestData));
    pager.offset = (page - 1) * 10;
    setRequestData(pager);
  };
  const paginationOpt: any = {
    showQuickJumper: true,
    total: total,
    pageSize: 10,
    showSizeChanger: false,
    onChange: onPageChange,
    current: current,
    size: "default"
  };

  const columns: any = [
    { title: "工厂", dataIndex: "siteName", key: "siteName" },
    { title: "发货平台", dataIndex: "platform", key: "platform" },
    { title: "产品类型", dataIndex: "categoryName", key: "categoryName" },
    { title: "车型", dataIndex: "vehicleType", key: "vehicleType" },
    { title: "规格组", dataIndex: "specGroup", key: "specGroup" },
    {
      title: "发送范围",
      dataIndex: "publishEndDate",
      key: "publishEndDate",
      render: (_: any, record: any) => {
        let sendObject = "通知司机和客户";
        if (record.sendType === "0") {
          sendObject = "仅通知司机";
        } else if (record.sendType === "1") {
          sendObject = "仅通知客户";
        }
        return `${showLabel(record.appointmentStatus, bookingStatus)}, ${sendObject}, ${record.appointmentDate ||
          ""} ${showLabel(record.logisticsStatus, logisticsStatus)}`;
      }
    },
    {
      title: "通知内容",
      dataIndex: "sendContent",
      key: "sendContent"
    },
    { title: "短信发送条数", dataIndex: "sendSmsNumber", key: "sendSmsNumber" },
    {
      title: "发布人",
      dataIndex: "createBy",
      key: "createBy"
    },
    {
      title: "发布时间",
      dataIndex: "sendTime",
      key: "sendTime",
      render: (current: any) => moment(current).format("YYYY-MM-DD HH:mm:ss")
    }
  ];

  // 回调
  const handleMessageFiltersCallBack = useCallback(() => {
    const currentMessageFilterData = messageFilters_Ref.current.getFormFields();
    const siteName = showLabel(currentMessageFilterData.siteCode, supplierList);
    const values = { ...currentMessageFilterData, siteName: siteName };
    // 将条件所有值传递给父组件
    // 将时间拆分成两个字段并转换为字符串
    values.startCreateTime = "";
    values.endCreateTime = "";
    if (values.sendDateRange && values.sendDateRange.length > 0) {
      values.startCreateTime = moment(values.sendDateRange[0]).format("YYYY-MM-DD");
      values.endCreateTime = moment(values.sendDateRange[1]).format("YYYY-MM-DD");
    }
    // 删除不需要传递的字段
    delete values.sendDateRange;
    setMessageFiltersData(values);
  }, []);

  //自定义的空状态
  const customizeRenderEmpty = useCallback(
    () => (
      <div style={{ textAlign: "center" }}>
        <br />
        <p>{"没有符合条件的公告"}</p>
        <br />
      </div>
    ),
    []
  );

  return (
    <div className="production-record">
      <MessageSearchFilters ref={messageFilters_Ref} get_message_filters={handleMessageFiltersCallBack} />
      <Card
        style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 10 }}
        bodyStyle={{ paddingTop: 0 }}
        title={
          <div>
            <span>公告记录列表</span>
            <div className="total-item" style={{ float: "right" }}>
              共 {total} 条
            </div>
          </div>
        }
      >
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table
            columns={columns}
            pagination={paginationOpt}
            dataSource={data}
            scroll={{ x: "max-content" }}
            loading={loading}
            size="small"
          />
        </ConfigProvider>
      </Card>
    </div>
  );
};
export default MessageRecord;
