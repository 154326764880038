import { Card, Modal } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import AccountSettlementTableList from "./tableList";
import "./index.scss";

type myProps = {
  visible: boolean,
  loading: boolean,
  tableData: any;
  pagination: object;
  fieldsDesc: any;
  accountSettlementSearchResultRecordList: any,
  currentacccountSettlementSearchResultRecordIndex: any,
  handleCancel: () => void;
  handleClickDocumentNumber: (value: any, type: string) => void;
  handlePreviousQueryAccountSettlement: () => void;
  handleNextQueryAccountSettlement: () => void;
  cardExtra: () => any;
}

const AccountSettlementModal = (props: myProps) => {
  return (
    <>
      <Modal
        title={
          <div
            style={{
              width: '100%',
              position: 'relative'
            }}
          >
            清账明细
            {props.accountSettlementSearchResultRecordList.length > 1 && (
              <div className="arrow-btn-group">
                {props.currentacccountSettlementSearchResultRecordIndex > 0 && (
                  <ArrowLeftOutlined onClick={() => props.handlePreviousQueryAccountSettlement()} />
                )}
                {props.currentacccountSettlementSearchResultRecordIndex >= 0 && props.currentacccountSettlementSearchResultRecordIndex < props.accountSettlementSearchResultRecordList.length - 1 && (
                  <ArrowRightOutlined onClick={() => props.handleNextQueryAccountSettlement()} />
                )}
              </div>
            )}
          </div>
        }
        wrapClassName="account-settlement-modal"
        maskClosable={true}
        visible={props.visible}
        footer={null}
        onCancel={props.handleCancel}
        width="calc(100% - 200px)"
        getContainer=".account-settlement-modal-container"
      >
        <Card
          style={{ border: "none" }}
          bodyStyle={{ padding: 0 }}
          title=""
          headStyle={{
            backgroundColor: "white",
            borderBottom: "none",
            padding: "0"
          }}
          extra={props.cardExtra()}
        >
          <AccountSettlementTableList
            tableData={props.tableData}
            pagination={props.pagination}
            loading={props.loading}
            fieldsDesc={props.fieldsDesc}
            handleClickDocumentNumber={props.handleClickDocumentNumber}
          />
          {
            props.tableData.length > 0 && (
              <div className="tips">
                <ExclamationCircleOutlined />
                <span className="label">此页面款项状态仅代表清账前的状态</span>
              </div>
            )
          }
        </Card>
      </Modal>
    </>
  );
};

export default AccountSettlementModal;