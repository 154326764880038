import { getSwitcherPreferencesApi, updateSwitcherPreferencesApi } from "@/api/swicher";
import { getAppointmentModeApi } from "@/api/slotbooking";

import { setLocalStorageItem, isHaveThisPermission, getLocalStorageItem } from "@/utils";
import { message } from "antd";

// 获取slotBookingType的相关信息
const doGetAppointmentMode = (firmCodeValue: string) => {
  const getParams = { customerCode: firmCodeValue };
  return getAppointmentModeApi(getParams)
    .then((responseJSON: any) => {
      setLocalStorageItem("localSlotBookingType", responseJSON.data.appointmentMode || "");
    })
    .catch((exception: any) => {
      console.log(`Failure: ${exception}`);
    });
};

const doGetSwitcherSetting = () => {
  return getSwitcherPreferencesApi()
    .then(async (responseJSON: any) => {
      if (responseJSON.code && responseJSON.code === "99999") {
        // 99999 提示后端的错误信息
        console.log(responseJSON.errorMsg);
        return null;
      } else {
        if (responseJSON?.data) {
          // 将最新SwitcherData保存至本地localSwitcherData
          setLocalStorageItem("localSwitcherData", JSON.stringify(responseJSON.data));
          // 外部用户需要获取slotbookingType的相关信息并本地保存
          if (responseJSON.data.preference?.customerCode && isHaveThisPermission("CNExternalUsers")) {
            await doGetAppointmentMode(responseJSON.data.preference.customerCode);
          }
        }
        return responseJSON.data || null;
      }
    })
    .catch(exception => {
      console.log(`error in doGetSwitcherSetting ${exception}`);
      return null;
    });
};

// 获取switcher数据
const getSwitcherSetting = async () => {
  let switcherDataRes: any;
  const localSwitcherData = getLocalStorageItem("localSwitcherData");
  if (localSwitcherData && localSwitcherData !== "undefined") {
    switcherDataRes = JSON.parse(localSwitcherData);
  } else {
    switcherDataRes = await doGetSwitcherSetting();
  }
  return switcherDataRes;
};

const doUpdateSwitcherSetting = (payload: any) => {
  const { data, setLoading, setVisible } = payload;
  setLoading && setLoading(true);
  return updateSwitcherPreferencesApi(data)
    .then(async (responseJSON: any) => {
      if (responseJSON.code && responseJSON.code === "99999") {
        // 99999 提示后端的错误信
        message.error(responseJSON.errorMsg);
        return null;
      } else {
        if (responseJSON?.data) {
          // 将最新SwitcherData保存至本地localSwitcherData
          setLocalStorageItem("localSwitcherData", JSON.stringify(responseJSON.data));
          // 外部用户需要获取slotBookingType的相关信息并本地保存
          if (responseJSON.data.preference?.firmCode && isHaveThisPermission("CNExternalUsers")) {
            await doGetAppointmentMode(responseJSON.data.preference.firmCode);
          }
        }
        setVisible && setVisible(false);
        return responseJSON.data || null;
      }
    })
    .catch(exception => {
      console.log(`error in Patch updateSwitcherPreferencesApi ${exception}`);
      return null;
    })
    .finally(() => {
      setLoading && setLoading(false);
    });
};

// 更新switcher数据
const updateSwitcherSetting = async (data: any) => {
  const switcherNewInfo = await doUpdateSwitcherSetting(data);
  if (switcherNewInfo) {
    return switcherNewInfo;
  }
  return null;
};

export { getSwitcherSetting, doGetSwitcherSetting, updateSwitcherSetting, doUpdateSwitcherSetting };
