import { Component } from "react";
import { PageHeader, Card, Table, Tooltip, Button, message, Popover } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { connect } from "react-redux";
import {
  getInvoiceDataListApi,
  downloadAttachmentApi,
  downloadInvoiceDataReportApi,
  saveInvoiceDataListFieldsOrderApi
} from "@/api/availableBalance";
import { downloadFile, deviceWidth, isMobileDevice, filterColumnsData, formatNumberToThousands } from "@/utils";
import FieldSetting from "@/pages/common/fieldSetting";
import SearchFilters from "./components/searchFilters";
import NotificationTips from "@/pages/common/notificationTips";

import "./index.scss";
import { getFirmDetailsList } from "@/api/constant";

interface Props {
  permissions: any;
  history: any;
  switcher: any;
  userDetail: any;
}

interface State {
  isTableLoading: boolean;
  isReportDownloadLoading: boolean;
  isAttachmentDownloadLoading: boolean;
  selectedRowKeys: object;
  columnsData: object;
  tableDataSource: object;
  fieldsDesc: any;
  allFields: any;
  pagination: object;
  searchParams: object;
  existAttachment: boolean;
  buyers: any;
}

type NumberTypes = number | null | undefined;
type StringTypes = string | null | undefined;

class Invoice extends Component<Props, State> {
  public readonly state = {
    isTableLoading: false,
    isReportDownloadLoading: false,
    isAttachmentDownloadLoading: false,
    selectedRowKeys: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showQuickJumper: true,
      size: deviceWidth() > 576 ? ("default" as any) : ("small" as any)
    },
    tableDataSource: [],
    fieldsDesc: [],
    allFields: [],
    buyers: [],
    columnsData: [
      {
        title: "发票号",
        dataIndex: "invoiceNo",
        key: "invoiceNo",
        fixed: deviceWidth() > 576 ? ("left" as any) : false
      },
      { title: "发票代码", dataIndex: "invoiceCode", key: "invoiceCode" },
      { title: "开票日期", dataIndex: "invoiceTime", key: "invoiceTime" },
      { title: "购方税号", dataIndex: "buyerTaxNo", key: "buyerTaxNo" },
      { title: "发票业务", dataIndex: "businessLine", key: "businessLine" },
      {
        title: "有无附件",
        dataIndex: "fileKey",
        key: "fileKey",
        render: (fileKey: NumberTypes) => {
          return fileKey ? "有" : "无";
        }
      },
      { title: "合同编号", dataIndex: "contractNo", key: "contractNo" },
      { title: "合同批次号", dataIndex: "batchNo", key: "batchNo" },
      { title: "商品名称", dataIndex: "productName", key: "productName" },
      { title: "购方名称", dataIndex: "buyerName", key: "buyerName" },
      { title: "客户编码", dataIndex: "buyer", key: "buyer" },
      { title: "卖方名称", dataIndex: "sellerName", key: "sellerName" },
      { title: "结算单号", dataIndex: "settNo", key: "settNo" },
      {
        title: "发票含税总金额",
        dataIndex: "invoiceTotalAmount",
        key: "invoiceTotalAmount",
        align: "right",
        render: (invoiceTotalAmount: NumberTypes) => {
          if (invoiceTotalAmount) {
            return formatNumberToThousands(invoiceTotalAmount);
          } else {
            return "-";
          }
        }
      },
      {
        title: "税额（元）",
        dataIndex: "invoiceTaxAmount",
        key: "invoiceTaxAmount",
        align: "right",
        render: (invoiceTaxAmount: NumberTypes) => {
          if (invoiceTaxAmount) {
            return formatNumberToThousands(invoiceTaxAmount);
          } else {
            return "-";
          }
        }
      },
      {
        title: "发票金额（不含税）",
        dataIndex: "invoiceAmount",
        key: "invoiceAmount",
        align: "right",
        render: (invoiceAmount: NumberTypes) => {
          if (invoiceAmount) {
            return formatNumberToThousands(invoiceAmount);
          } else {
            return "-";
          }
        }
      },
      { title: "税率", dataIndex: "taxRate", key: "taxRate" },
      {
        title: "备注",
        dataIndex: "remark",
        key: "remark",
        ellipsis: {
          showTitle: false
        },
        render: (remark: StringTypes) => {
          if (remark && remark.length > 20) {
            return <Tooltip placement="topLeft" title={remark}>{`${remark?.substring(0, 20)}...`}</Tooltip>;
          } else {
            return <span>{remark}</span>;
          }
        }
      },
      { title: "发票类型", dataIndex: "invoiceType", key: "invoiceType" },
      { title: "发票状态", dataIndex: "invoiceStatus", key: "invoiceStatus" },
      {
        title: "直连合同",
        dataIndex: "b2bTag",
        key: "b2bTag",
        render: (b2bTag: StringTypes) => {
          return b2bTag === "Y" ? "是" : "否";
        }
      }
    ],
    searchParams: { hasAttachment: "" },
    existAttachment: false
  };

  searchFiltersData: any = {};
  urlParams = {
    limit: 10,
    offset: 0
  };

  componentDidMount() {
    document.title = "发票管理";
    const { userDetail } = this.props;
    const userDataCheck = userDetail && Object.keys(userDetail).length && userDetail.entitlements;
    if (userDataCheck) {
      const { entitlements } = userDetail;
      const {
        otherControls: { companyAccountType },
        controlIdentifier
      } = entitlements[0];
      if (companyAccountType === "GROUP") {
        getFirmDetailsList({ accountNumber: controlIdentifier })
          .then((res: any) => {
            if (res.data) {
              if (res.data.customerInfoEntitlement?.length > 0) {
                let options = res.data.customerInfoEntitlement.map((val: any) => {
                  return {
                    label: `${val.accountLongName}(${val.accountNumber})`,
                    value: `${val.accountLongName}(${val.accountNumber})`,
                    key: val.accountNumber,
                    name: val.accountLongName
                  };
                });
                this.setState({ buyers: options });
              }
            }
          })
          .catch((exception: any) => {
            console.log(`Failure: ${exception}`);
            message.error("获取数据失败，请稍后重试。");
          });
      }
    } else {
      this.setState({ buyers: [] });
    }
  }

  // 获取发票列表数据
  getInvoiceDataList = () => {
    const { pagination } = this.state;

    // 重新获取数据时 取消选中
    this.setState({ selectedRowKeys: [] });

    let paginationTemp = pagination;
    if (this.urlParams.offset === 0 && pagination.current !== 1) {
      // 重置页码
      paginationTemp = { ...pagination, current: 1 };
      this.setState({ pagination: paginationTemp });
    }
    // 筛选条件
    const postDataObj = { ...this.searchFiltersData, sapFlag: true };
    this.setState({ isTableLoading: true });
    getInvoiceDataListApi(this.urlParams, postDataObj)
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
        } else if (responseJSON?.data) {
          const tableRecordData = Array.isArray(responseJSON.data.invoiceDataList)
            ? responseJSON.data.invoiceDataList
            : [];
          const fieldsDescArr = this.getDefaultFieldDesc(JSON.parse(responseJSON.data.fieldsOrder.fieldDesc));
          this.setState({
            tableDataSource: tableRecordData.map(this.mapTableData) || [],
            pagination: {
              ...paginationTemp,
              total: responseJSON.data.metadata?.totalCount ?? 0
            },
            fieldsDesc: this.filterFieldsList(fieldsDescArr),
            allFields: this.filterFieldsList(JSON.parse(responseJSON.data.fieldsOrder.allFields)),
            existAttachment: responseJSON.data.metadata?.hasAttachment
          });
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        this.setState({
          tableDataSource: [],
          pagination: {
            ...paginationTemp,
            total: 0
          }
        });
      })
      .finally(() => {
        this.setState({ isTableLoading: false });
      });
  };

  // 处理table相关参数变化
  handleTableDataChange = (value: any) => {
    const { pagination } = this.state;

    if (value.pageSize !== pagination.pageSize) {
      // 每页显示的条数改变
      this.urlParams = {
        ...this.urlParams,
        offset: 0,
        limit: value.pageSize
      };
      this.setState(
        {
          pagination: {
            ...pagination,
            current: 1,
            pageSize: value.pageSize
          }
        },
        () => {
          // 获取新的数据
          this.getInvoiceDataList();
        }
      );
    } else if (value.current !== pagination.current) {
      // 当前页码改变
      this.urlParams = {
        ...this.urlParams,
        offset: value.pageSize * (value.current - 1),
        limit: value.pageSize
      };
      this.setState(
        {
          pagination: {
            ...pagination,
            current: value.current,
            pageSize: value.pageSize
          }
        },
        () => {
          // 获取新的数据
          this.getInvoiceDataList();
        }
      );
    }
  };

  // 格式化处理table的展示的信息
  mapTableData = (data: any) => {
    // 抽取公共方法替换原来的||运算符
    const revertValue = (value: any) => {
      if (!value) {
        value = "-";
      }
      return value;
    };
    return {
      ...data,
      invoiceNo: revertValue(data.invoiceNo),
      invoiceCode: revertValue(data.invoiceCode),
      invoiceTime: data.invoiceTime ? moment(data.invoiceTime).format("YYYY-MM-DD") : "-",
      buyerTaxNo: revertValue(data.buyerTaxNo),
      businessLine: revertValue(data.businessLine),
      fileKey: data.fileKey || null,
      contractNo: revertValue(data.contractNo),
      batchNo: revertValue(data.batchNo),
      productName: revertValue(data.productName),
      buyerName: revertValue(data.buyerName),
      buyer: revertValue(data.buyer),
      sellerName: revertValue(data.sellerName),
      settNo: revertValue(data.settNo),
      invoiceTotalAmount: data.invoiceTotalAmount ? data.invoiceTotalAmount.toFixed(4) : null,
      invoiceTaxAmount: data.invoiceTaxAmount ? data.invoiceTaxAmount.toFixed(4) : null,
      invoiceAmount: data.invoiceAmount ? data.invoiceAmount.toFixed(4) : null,
      taxRate: revertValue(data.taxRate),
      remark: revertValue(data.remark),
      invoiceType: revertValue(data.invoiceType),
      invoiceStatus: revertValue(data.invoiceStatus)
    };
  };

  // 确保字段不重复
  getDefaultFieldDesc = (data: any) => {
    // 清除重复数据
    let newData: any = [];
    let dataValue: any = [];
    data.forEach((val: any) => {
      if (dataValue.indexOf(val.value) === -1) {
        dataValue.push(val.value);
        newData.push(val);
      }
    });
    return newData;
  };

  // 可根据角色不同，给到不同的字段信息
  filterFieldsList = (dataSource: any) => {
    if (dataSource) {
      const externalUsersIgnoreFields: any = ["businessLine"];
      if (this.props.permissions.CNExternalUsers) {
        dataSource = dataSource.filter((val: any) => !externalUsersIgnoreFields.includes(val.value));
      }
    }
    return dataSource;
  };

  // Card组件的extra信息展示
  cardExtra = () => {
    const {
      selectedRowKeys,
      pagination,
      isReportDownloadLoading,
      isAttachmentDownloadLoading,
      fieldsDesc,
      allFields
    } = this.state;
    return (
      <div className="hdp-uf hdp-uf-vc hdp-uf-ww card-extra">
        <div className="extra-total extra-item">
          <span>共 </span>
          <span>{pagination.total}</span>
          <span> 条</span>
        </div>
        {!isMobileDevice() && (
          <div className="extra-item">
            <FieldSetting fieldDesc={fieldsDesc} allFields={allFields} getColumns={this.getColumns} />
          </div>
        )}
        <div className="extra-item">
          <Popover content="下载当前列表选中项包含的发票附件" trigger="hover">
            <Button
              data-testid="test-download-attachment"
              type="primary"
              loading={isAttachmentDownloadLoading}
              icon={<DownloadOutlined />}
              disabled={!selectedRowKeys.length}
              onClick={this.handleDownloadAttachment}
            >
              下载附件{selectedRowKeys.length > 0 && `(${selectedRowKeys.length})`}
            </Button>
          </Popover>
        </div>
        <div className="extra-item">
          <Button
            data-testid="test-download-reports"
            type="primary"
            loading={isReportDownloadLoading}
            icon={<DownloadOutlined />}
            disabled={pagination.total === 0 || selectedRowKeys.length > 0}
            onClick={this.handleDownloadReports}
          >
            下载报表
          </Button>
        </div>
      </div>
    );
  };

  // 获取字段设置后的columns
  getColumns = (data: any, dataSource: any, setVisibleFun: any) => {
    let temp = data.map((item: any) => {
      return dataSource.find((val: any) => val.value === item);
    });
    temp = temp.filter((val: any) => val);
    saveInvoiceDataListFieldsOrderApi({
      type: "sapInvoiceDataList",
      fieldDesc: JSON.stringify(temp)
    })
      .then(Response => {
        if (Response) {
          this.getInvoiceDataList();
        }
      })
      .catch(err => {
        message.error("字段设置失败，请稍后重试！");
        console.log(err);
      })
      .finally(() => {
        setVisibleFun(false);
      });
  };

  //下载附件 -- 指定条目的附件
  handleDownloadAttachment = () => {
    const { tableDataSource, selectedRowKeys } = this.state;
    const fileKeysArr: any[] = [];
    if (tableDataSource.length > 0 && selectedRowKeys.length > 0) {
      tableDataSource.forEach((invoiceItem: any) => {
        if (selectedRowKeys.includes(invoiceItem.id as never)) {
          fileKeysArr.push(invoiceItem.fileKey);
        }
      });
    }
    // 如果存在fileKey的相关信息才执行下载
    if (fileKeysArr.length > 0) {
      const downloadParams = {
        isAllFile: "N"
      };
      const postDataObj = {
        fileKeys: fileKeysArr.join(",")
      };
      this.setState({ isAttachmentDownloadLoading: true });
      downloadAttachmentApi(downloadParams, postDataObj)
        .then((response: any) => {
          if (response) {
            downloadFile(response, `发票附件(${moment().format("YYYY-MM-DD")}).zip`);
            this.setState({ selectedRowKeys: [] });
          } else {
            message.error("下载失败，请稍后重试。");
          }
        })
        .catch((exception: any) => {
          message.error("下载失败，请稍后重试。");
          console.log(exception);
        })
        .finally(() => {
          this.setState({ isAttachmentDownloadLoading: false });
        });
    }
  };

  //下载报表
  handleDownloadReports = () => {
    const downloadParams = {
      ...this.searchFiltersData,
      sapFlag: true
    };
    this.setState({ isReportDownloadLoading: true });
    downloadInvoiceDataReportApi(downloadParams)
      .then((response: any) => {
        if (response) {
          downloadFile(response, `发票列表(${moment().format("YYYY-MM-DD")}).xls`);
        } else {
          message.error("下载失败，请稍后重试。");
        }
      })
      .catch((exception: any) => {
        message.error("下载失败，请稍后重试。");
        console.log(exception);
      })
      .finally(() => {
        this.setState({ isReportDownloadLoading: false });
      });
  };

  // 选中的列表
  onSelectChange = (selectedRowKeys: any) => {
    this.setState({ selectedRowKeys });
  };

  // 处理查询
  handleSearch = (value: any) => {
    this.searchFiltersData = { ...this.searchFiltersData, ...value };
    this.urlParams = { ...this.urlParams, offset: 0 };
    if (this.searchFiltersData.sellerName === "All" || this.searchFiltersData.sellerName === "全部卖方") {
      this.searchFiltersData.sellerName = "";
    }
    this.setState({ searchParams: { ...this.searchFiltersData } });
    this.getInvoiceDataList();
  };

  public render() {
    const {
      selectedRowKeys,
      tableDataSource,
      pagination,
      isTableLoading,
      columnsData,
      fieldsDesc,
      buyers
    } = this.state;

    return (
      <div className="invoice-page">
        <div className="hdp-uf hdp-uf-dc">
          <PageHeader
            className="page-header"
            title="发票管理"
            // extra={[
            //   <Button key="jumpToCtsInvoice" onClick={this.jumpToCtsInvoice} type="primary">
            //     旧发票管理页面链接
            //   </Button>
            // ]}
          >
            <NotificationTips />
          </PageHeader>
          {/* 查询条件区域 */}
          <SearchFilters doSearch={this.handleSearch} buyers={buyers} />
          {/* table数据展示区域 */}
          <div className="invoice-table-container">
            <Card
              style={{ boxShadow: "0 5px 9px #dedbdb" }}
              bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
              title="发票列表"
              headStyle={{
                backgroundColor: "white",
                borderBottom: "none"
              }}
              extra={this.cardExtra()}
            >
              <Table
                size="small"
                loading={isTableLoading}
                rowSelection={{
                  type: "checkbox",
                  fixed: true,
                  selectedRowKeys,
                  getCheckboxProps: (item: any) => {
                    return { disabled: !item.fileKey };
                  },
                  onChange: this.onSelectChange
                }}
                dataSource={tableDataSource}
                columns={filterColumnsData(columnsData, fieldsDesc)}
                pagination={pagination}
                onChange={this.handleTableDataChange}
                rowKey="id"
                scroll={{ x: "max-content" }}
              />
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  switcher: state.switcher,
  userDetail: state.userDetails
});

export default connect(mapStateToProps, null)(Invoice);
