/*
 * action types
 */
export const UP_SUCCESS_MSG = "更新成功";

export const YYYY_MM_DD = "YYYY-MM-DD";

export const ROLE_MANAGER_LABEL = "采购经理";

export const ROLE_STAFF_LABEL = "采购内勤";

export const ROLE_MANAGER_VALUE = "DXP_DSC_Role_CN_Manager";

export const ROLE_STAFF_VALUE = "DXP_DSC_Role_CN_Staff";

export const STATE_ACTIVE_LABEL = "已激活";

export const STATE_PROVISIONED_LABEL = "待激活";

export const STATE_EXPIRED_LABEL = "已失效";

export const STATE_ACTIVE_VALUE = "ACTIVE";

export const STATE_PROVISIONED_VALUE = "PROVISIONED";

export const STATE_EXPIRED_VALUE = "PASSWORD_EXPIRED";

export const VIEW_INVOICES_FAIL = "VIEW_INVOICES_FAIL";

export const FETCH_CONTRACT = "FETCH_CONTRACT";

export const FETCH_LOAD_MORE = 'FETCH_LOAD_MORE';
