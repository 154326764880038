import { useCallback, useState } from "react";
import { Form, Row, Col, Select, Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
const { Option } = Select;

const SearchFilter = (props: any) => {
  const [form] = Form.useForm();
  const [requestListValue] = useState({
    limit: 10,
    offset: 0,
    documentNo: "",
    taskState: "",
    calledSystem: ""
  });

  const handleReset = useCallback(() => {
    form.resetFields();
    props.setFields(requestListValue);
    props.setCurrent(1);
  }, [form, props, requestListValue]);

  const onFinish = useCallback(
    (values: any) => {
      const temp = JSON.parse(JSON.stringify(requestListValue));
      temp.offset = 0;
      temp.limit = 10;
      temp.documentNo = values.documentNo;
      temp.taskState = values.taskState;
      temp.calledSystem = values.calledSystem;
      props.setFields(temp);
      props.setCurrent(1);
    },
    [props, requestListValue]
  );

  return (
    <div className="search-filter">
      <div className="base-filter">
        <Form form={form} name="contract-confirmation-form" layout="vertical" autoComplete="off" onFinish={onFinish}>
          <Row justify="space-between">
            <Col md={5} xs={24}>
              <Form.Item name="taskState">
                <Select placeholder="请选择API状态">
                  <Option value="">全部状态</Option>
                  <Option value="01">未触发</Option>
                  <Option value="02">已触发未执行</Option>
                  <Option value="03">触发成功</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="calledSystem">
                <Select placeholder="请选择被调用方">
                  <Option value="">全部系统</Option>
                  <Option value="TW">TW</Option>
                  <Option value="APEX">APEX</Option>
                  <Option value="Dell Boomi">Dell Boomi</Option>
                  <Option value="MiddleWare">MiddleWare</Option>
                  <Option value="CDP">CDP</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={5} xs={24}>
              <Form.Item name="documentNo">
                <Input allowClear style={{ width: "100%" }} placeholder="请输入单号" />
              </Form.Item>
            </Col>

            <Col md={8} xs={24} className="align-right advance-btn">
              <Button
                data-testid="reset-btn"
                style={{ marginRight: 10, backgroundColor: "#5c5c5c", width: "fit-content" }}
                type="primary"
                onClick={handleReset}
              >
                清空
              </Button>
              <Button data-testid="submit-btn" type="primary" htmlType="submit">
                <SearchOutlined />
                查询
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default SearchFilter;
