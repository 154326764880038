import { useState, useEffect, useRef, useCallback } from "react";
import { AutoComplete, Input, Spin } from "antd";

interface Props {
  options: any;
  dependence?: any; // 前置依赖项
  onChange?: any;
  onFocus?: any;
  onSelect?: any;
  placeholder?: string;
  value?: any;
  disabled?: any;
  style?: any;
  className?: string;
  onBlur?: any;
  dropdownClassName?: string;
  isLoading?: boolean;
  type?: string;
  allowClear?: boolean;
}

let inputFlag = true;
const MyAutoComplete = (props: Props) => {
  const {
    options,
    placeholder,
    value,
    disabled,
    style,
    className,
    dropdownClassName,
    isLoading,
    type,
    onSelect,
    allowClear
  } = props;
  const [isInputShow, setIsInputShow] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [currentValue, setCurrentValue] = useState("");
  const [autoFocus, setAutoFocus] = useState(false);
  const input: any = useRef();

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  // 搜索匹配
  useEffect(() => {
    const flag = options.findIndex((val: any) => val.value === value);
    if ((flag === -1 && type === "sap") || type !== "sap") {
      if (inputValue !== undefined && inputFlag) {
        props.onFocus && props.onFocus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleFocus = useCallback(() => {
    const { dependence, onFocus } = props;
    if (value === "-") {
      setCurrentValue("");
      handleInputChange("");
    }
    if ((!value && type === "sap") || type !== "sap") {
      onFocus && onFocus();
    }
    setIsInputShow(!(dependence === "" && dependence !== undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, type, value]);

  const handleInputChange = useCallback(
    (e: any) => {
      setInputValue(e);
      setCurrentValue(e);
      props.onChange && props.onChange(e);
    },
    [props]
  );

  const onBlur = useCallback(() => {
    setIsInputShow(false);
    setAutoFocus(false);
    props.onBlur && props.onBlur();
  }, [props]);

  const onComposition = useCallback(
    (e: any) => {
      if (e.type === "compositionend") {
        inputFlag = true;
        props.onFocus && props.onFocus();
      } else {
        inputFlag = false;
      }
    },
    [props]
  );

  const handleSelect = useCallback(() => {
    input.current.blur();
    onSelect && onSelect();
  }, [onSelect]);

  return (
    <>
      <AutoComplete
        data-testid="autocomplete-input"
        allowClear={allowClear}
        ref={input}
        open={isInputShow}
        onFocus={handleFocus}
        onBlur={onBlur}
        onChange={handleInputChange}
        onSelect={handleSelect}
        placeholder={placeholder}
        dropdownClassName={"input-tips " + dropdownClassName}
        options={options}
        value={currentValue}
        disabled={disabled}
        style={style}
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        autoFocus={autoFocus}
        className={className}
      >
        <Input onCompositionEnd={onComposition} onCompositionStart={onComposition} />
      </AutoComplete>
    </>
  );
};

export default MyAutoComplete;
