import RequestService from "@/api/request";
import axios from "axios";

// 获取合同列表
const getContracts = (dataObj: object) =>
  RequestService({
    url: "/contractsap/sap/listContract?limit=10&type=01",
    method: "get",
    params: dataObj,
    cancelToken: axios.CancelToken.source().token
  });

// 获取旧合同列表
const getOldContracts = (dataObj: object) =>
  RequestService({
    url: "/contractsap?limit=10&type=01",
    method: "get",
    params: dataObj,
    cancelToken: axios.CancelToken.source().token
  });

// 合同延期 -- 被取消使用-2023-04-10
const setLockUnlockContractList = (params: object, dataObj: object) =>
  RequestService({
    url: "/contractsap/sap/lockUnlock/batch",
    method: "post",
    params: params,
    data: dataObj
  });

// 获取公司名称
const getSapFirmDetailsList = (dataObj: object) =>
  RequestService({
    url: "/contractsap/sap/vagueListCustomer",
    method: "get",
    params: dataObj,
    cancelToken: axios.CancelToken.source().token
  });

// 获取产品名称
const getProductDetailList = (dataObj: object) =>
  RequestService({
    url: "/contractsap/sap/products",
    method: "get",
    params: dataObj,
    cancelToken: axios.CancelToken.source().token
  });

// 获取合同详情
const getContractDetailsById = (dataObj: object) =>
  RequestService({
    url: "/contractsap/sap/contractDetails?type=contractDetail",
    method: "get",
    params: dataObj
  });

// 获取合同详情
const getOldContractDetailsById = (dataObj: object) =>
  RequestService({
    url: "/contractsap/contractDetails?type=contractDetail",
    method: "get",
    params: dataObj
  });

// 获取嘉吉发货地址
const getShipAddressDescList = (dataObj: object) => {
  return RequestService({
    url: "/contractsap/plantLocations",
    method: "get",
    params: dataObj
  })
}

export {
  getContracts,
  getOldContracts,
  getSapFirmDetailsList,
  getContractDetailsById,
  setLockUnlockContractList,
  getProductDetailList,
  getOldContractDetailsById,
  getShipAddressDescList
};