import { useState, useEffect } from "react";
import { message, PageHeader } from "antd";
import { connect } from "react-redux";
import "./home.scss";
import { isMobileDevice } from "@/utils/common";
import { getNoticeInfo } from "@/api/userManagement";

function Home(props: any) {
  const [userName, setUserName] = useState("");
  const [updateInfo, setUpdateInfo] = useState("");

  useEffect(() => {
    document.title = "嘉吉供应链系统";
    getNoticeContent();
  }, []);

  useEffect(() => {
    if (props.userDetailsData && Object.keys(props.userDetailsData).length > 0) {
      const { profile } = props.userDetailsData;
      setUserName(profile.lastName + profile.firstName);
    }
  }, [props.userDetailsData]);

  const getNoticeContent = () => {
    getNoticeInfo({})
      .then(res => {
        if (res.data !== null) {
          let decode = atob(res.data.content);
          let str = decodeURI(decode);
          setUpdateInfo(str);
        }
      })
      .catch(() => {
        message.error("获取公告失败，请稍后重试。");
      });
  };

  return (
    <>
      <div className="home-page">
        <div className="home-dashboard" />
        <div className="home-bg" />
        {!isMobileDevice() && <PageHeader title={`您好，${userName}`} className="main-page-header" ghost={false} />}
        <div className="home-content">
          <div className="welcome">
            欢迎来到，
            <span>嘉吉供应链系统 !</span>
          </div>
          <br />
          <div>
            嘉吉通过将全球食品体系的有效连接，帮助世界实现繁荣发展。我们帮助农民了解并开拓市场，为客户提供可持续营养解决方案，进而践行滋养世界的目标。
          </div>
          <br />
          <div className="update-info">
            <div
              dangerouslySetInnerHTML={{ __html: updateInfo }}
              style={{ whiteSpace: "pre-wrap", marginBottom: 60 }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  userDetailsData: state.userDetails
});

export default connect(mapStateToProps, null)(Home);
