import {Button, Tooltip} from "antd";
import "./index.scss"
import {useCallback} from "react";
import { cutBatchNoZero } from "@/utils";

interface Props {
  data: any;
  onOk: any;
  onCancel: any;
  changeTipsType: string;
}

const ChangeContractModal = (props: Props) => {
  // 根据优先执行类型初始化弹窗内容
  const getModalContent = useCallback(() => {
    const noticeContentTemp: any = {};
    noticeContentTemp.tips = `点击同意后将优先执行${props.data[0]?.contractNo + '-' + cutBatchNoZero(props.data[0]?.batch)}合同`;
    if (["1", "4"].includes(props.changeTipsType)) {
      noticeContentTemp.title = "请按照合同顺序执行";
    } else if (props.changeTipsType === "2") {
      noticeContentTemp.title = `合同开始执行日期≥40天，且余量≥4吨的合同需要优先执行`
    } else if (props.changeTipsType === "3") {
      noticeContentTemp.title = `合同开始执行日期≥40天，且余量≥2吨的合同需要优先执行`
    }
    return noticeContentTemp;
  }, [props.changeTipsType, props.data])

  return (
    <div className="change-contract-modal-box">
      <div className="change-contract-modal-main">
        <div className="title">{getModalContent().title}</div>
        <div className="tips">{getModalContent().tips}</div>
        <div className="button">
          <Button type="primary" data-testid="agree-btn" onClick={props.onOk}>同意</Button>
          {props.changeTipsType !== "1" && <Tooltip placement="right" title="如有特殊需求要优先执行该合同，请线下联系业务经理" color="#007681">
            <Button style={{marginLeft: 20}} data-testid="disagree-btn" onClick={props.onCancel} disabled>不同意</Button>
          </Tooltip>}
          {props.changeTipsType === "1" && <Button style={{marginLeft: 20}} data-testid="disagree-btn" onClick={props.onCancel}>不同意</Button>}
        </div>
      </div>
    </div>
  );
};

export default ChangeContractModal;
