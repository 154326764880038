import { Component } from "react";
import { PageHeader, Tabs, Button, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./index.scss";

import { tipsInfoData } from "@/utils/constant";
import PendingList from "./components/pendingList";
import ScheduledList from "./components/scheduledList";
import NotificationTips from "@/pages/common/notificationTips";
import { AA_PageLoadedEvent } from "@/monitor/adobe_analytics";

const { TabPane } = Tabs;

interface Props {
  location: any;
}
interface State {
  currentActiveKey: string;
}

class Booking extends Component<Props, State> {
  public readonly state = {
    currentActiveKey: "pending-list"
  };

  bookingTipsInfo = {
    title: "预约须知",
    width: "50%",
    content: (
      <div className="slot-booking-tips">
        <p>尊敬的司机朋友：</p>
        {tipsInfoData.map(item => (
          <div key={`slot-booking_tips_${item.num}`} className="hdp-uf list-item">
            <div>{`${item.num}. `}</div>
            <div className="list-item-content">{item.value}</div>
          </div>
        ))}
      </div>
    ),
    okText: "关闭"
  };

  componentDidMount() {
    document.title = "预约提货";
    AA_PageLoadedEvent("预约提货");
    if (this.props.location.state) {
      this.setState({ currentActiveKey: this.props.location.state.activeKey });
    }
  }

  modalBookingTips = () => {
    Modal.info(this.bookingTipsInfo);
  };

  tabRightOperations = () => {
    return (
      <Button
        data-testid="test-booking-tips"
        type="link"
        icon={<ExclamationCircleOutlined />}
        onClick={this.modalBookingTips}
      >
        预约须知
      </Button>
    );
  };

  // Tab切换
  doChangeTabName = (tabName: string) => {
    if (tabName) {
      this.setState({ currentActiveKey: tabName });
    }
  };

  public render() {
    const { currentActiveKey } = this.state;

    return (
      <div className="booking-page">
        <div className="hdp-uf hdp-uf-dc">
          <PageHeader className="page-header" title="预约提货">
            <NotificationTips />
          </PageHeader>
          <div className="tabs-container">
            <Tabs
              activeKey={currentActiveKey}
              onTabClick={this.doChangeTabName}
              tabBarExtraContent={this.tabRightOperations()}
              destroyInactiveTabPane
            >
              <TabPane key="pending-list" tab="待预约列表">
                <PendingList onChangeTabName={this.doChangeTabName} />
              </TabPane>
              <TabPane key="scheduled-list" tab="已预约列表">
                <ScheduledList />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default Booking;
