import { Button, message } from "antd";
import { downloadNotification } from "@/api/systemSetting";
import { downloadFile } from "@/utils";
import { useCallback } from "react";

interface Props {
  data: any;
  filename?: string;
  text?: boolean;
  type?: any;
}

const DownloadNotification = (props: Props) => {
  const handleDownloadReports = useCallback(() => {
    downloadNotification(props.data, {})
      .then((response: any) => {
        if (response) {
          downloadFile(response, `appendix.xls`);
        } else {
          message.error("下载失败，请稍后重试。");
        }
      })
      .catch((exception: any) => {
        message.error("下载失败，请稍后重试。");
        console.log(exception);
      });
  }, [props.data]);

  return (
    <>
      <Button
        type="link"
        onClick={handleDownloadReports}
        style={{ fontSize: 12, padding: 0, marginLeft: 0, width: "fit-content", textDecoration: "underline" }}
      >
        <u>{props.text}</u>
      </Button>
    </>
  );
};

export default DownloadNotification;
