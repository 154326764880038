import RequestService from "@/api/request";

const getProductionSettingList = (dataObj: object) => {
  return RequestService({
    url: "notificationsap/filter",
    method: "get",
    params: dataObj
  });
};

const saveNotification = (dataObj: object, param: any) =>
  RequestService({
    url: "notificationsap/save",
    method: "post",
    data: dataObj,
    params: param
  });

const upDateContract = (dataObj: object) =>
RequestService({
  url: "contractsap/sap/triggerSingleQuantity",
  method: "get",
  params: dataObj
});

const getFirmDetails = (dataObj: object) =>
RequestService({
  url: "contracts/firmDetails",
  method: "get",
  params: dataObj
});

const getValidFirmCode = (dataObj: object) =>
RequestService({
  url: "contractsap/sap/customerDetail",
  method: "get",
  params: dataObj
});

const getBuyerList = ( dataObj:object ) =>
  RequestService({
    url: "notificationsap/buyerList",
    method: "get",
    params: dataObj,
  });

const delNotification = ( dataObj:object, delId: string) =>
  RequestService({
    url: `notificationsap/${delId}/del`,
    method: "post",
    data: dataObj,
  });

const submitNotification = (dataObj: object, param: any) =>
RequestService({
  url: "notificationsap/changeSubmit",
  method: "post",
  data: dataObj,
  params: param
});

const downloadNotices = (id:any,param: any) => {
  return RequestService({
    url: `notificationsap/document/download/notices/${id}`,
    method: "get",
    params: param,
    responseType: "blob",
    headers:{ idtoken:true }
  });
}
const downloadNotification = (id:any,param: any) => {
  return RequestService({
    url: `notificationsap/document/download/${id}`,
    method: "get",
    params: param,
    responseType: "blob",
    headers:{ idtoken:true }
  });
}

const revertNotification = (id:number) => {
  return RequestService({
    url: `notificationsap/${id}/revert`,
    method: "patch",
    data: {},
    params: { revertStatus:1 }
  });
}

const getSmsTemplates = (paramsObj: object = {}) => {
  return RequestService({
    url: `slotbookingsap/appointment/sms/templates`,
    method: "get",
    params: paramsObj,
  });
};

const saveSmsNoticeInfo = (dataObj: object = {}) => {
  return RequestService({
    url: "slotbookingsap/appointment/sms/saveSmsNoticeInfo",
    method: "post",
    data: dataObj
  });
}

const sendSmsInfo = (params: object = {}, dataObj: object = {}) => {
  return RequestService({
    url: "slotbookingsap/appointment/sms/sendInfos",
    method: "post",
    data: dataObj,
    params: params
  });
}

const deleteSmsNoticeInfo = (dataObj: object = {}) => {
  return RequestService({
    url: "slotbookingsap/appointment/sms/deleteSmsNoticeInfo",
    method: "post",
    data: dataObj,
    params: dataObj
  });
}

const sendSmsNotice = (paramsObj: object = {}) => {
  return RequestService({
    url: `slotbookingsap/appointment/sms/sendSmsNotice`,
    method: "get",
    params: paramsObj,
  });
}

// 自动审单查询
const autoExamineQuery = (paramsObj: object = {}) => {
  return RequestService({
    url: `/commodity-movementsap/autoExamine/query`,
    method: "get",
    params: paramsObj,
  });
};

// 自动审单新增或修改
const autoExamineUpdate = (dataObj: object = {}) => {
  return RequestService({
    url: `/commodity-movementsap/autoExamine/saveOrUpdate`,
    method: "post",
    data: dataObj,
  });
};

export { 
  getProductionSettingList,
  saveNotification, 
  upDateContract, 
  getFirmDetails, 
  delNotification, 
  submitNotification, 
  getValidFirmCode, 
  getBuyerList,
  downloadNotices,
  downloadNotification,
  revertNotification,
  getSmsTemplates,
  saveSmsNoticeInfo,
  sendSmsInfo,
  deleteSmsNoticeInfo,
  sendSmsNotice,
  autoExamineQuery,
  autoExamineUpdate
}