import { useState, useEffect, useCallback, useRef } from "react";
import { message } from "antd";
import { saveMovementFieldsOrder } from "@/api/constant";
import { getDeliveryOrders } from "@/api/deliveryOrder";
import TabFilter from "../components/tabFilter";
import TabContent from "../components/tabTable";
import * as type from "../../constant";
import { formatStrTrim } from "@/utils";

interface Props {}

const PendingEdit = (props: Props) => {
  const [data, setData] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [fieldsDesc, setFieldsDesc] = useState([]);
  const [searchValue, setSearchValue] = useState({ deliveryType: "All", haveEntry: "All" });
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const mounting = useRef(true); // useEffect模拟 componentDidUpdata

  const getTable = useCallback(() => {
    // 格式化列表数据
    const mapTableData = (tableSourceData: any) => ({
      ...tableSourceData,
      // 需要单独提前处理的字段
      truckPlateNumber: formatStrTrim(tableSourceData.truckPlateNumber, true),
      driverName: formatStrTrim(tableSourceData.driverName, true)
    });

    setLoading(true);
    getDeliveryOrders({
      offset: offset,
      limit: limit,
      type: "ztEditDo",
      ...searchValue
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          if (res.data.deliveryOrder instanceof Array) {
            setData(res.data.deliveryOrder.map(mapTableData));
            setTotalCount(res.data.metadata.totalCount);
          } else {
            setData([]);
            setTotalCount(0);
          }
          res.data.fieldsOrder.allFields && setAllFields(JSON.parse(res.data.fieldsOrder.allFields));
          res.data.fieldsOrder.fieldDesc && setFieldsDesc(JSON.parse(res.data.fieldsOrder.fieldDesc));
          setSelectedRowKeys([]);
          setSelectedRows([]);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        setData([]);
        setTotalCount(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [limit, offset, searchValue]);

  useEffect(() => {
    if (mounting.current) {
      mounting.current = false;
      return;
    }
    getTable();
  }, [getTable, limit, offset, searchValue]);

  // 获取搜索条件
  const getSearchValue = useCallback((data: any) => {
    setSearchValue({ ...data });
    setCurrent(1);
    setOffset(0);
  }, []);

  // 获取字段设置后的columns
  const getColumns = useCallback(
    (data: any, dataSource: any, setVisibleFun: any) => {
      let temp = data.map((item: any) => {
        return dataSource.find((val: any) => val.value === item);
      });
      temp = temp.filter((val: any) => val);
      saveMovementFieldsOrder({
        type: "ztEditDo",
        fieldDesc: JSON.stringify(temp)
      })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          message.error("获取数据失败，请稍后重试。");
        })
        .finally(() => {
          setVisibleFun(false);
          getTable();
        });
    },
    [getTable]
  );

  // 获取分页信息
  const getMetadata = useCallback((data: any) => {
    setOffset(data.offset);
    setLimit(data.limit);
    setCurrent(data.current);
  }, []);

  const setLoadingStatus = useCallback((loading: boolean) => {
    setLoading(loading);
  }, []);

  return (
    <div className="pending-edit">
      <TabFilter isDeliveryDate={true} isPendingEdit={true} isApplyStatus={true} getSearchValue={getSearchValue} />
      <TabContent
        data={data}
        totalCount={totalCount}
        getColumns={getColumns}
        getTable={getTable}
        getMetadata={getMetadata}
        loading={loading}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        fieldsDesc={fieldsDesc}
        allFields={allFields}
        isPendingEditPage={true}
        current={current}
        searchValue={searchValue}
        keys={type.EDITED_DO}
        downloadType="ztEditDo"
        downloadFileName={type.EDITEDDOREPORT}
        setLoadingStatus={setLoadingStatus}
        TableButtonConfig={{
          isBatchEditButton: true,
          isRefuseButton: true,
          isConfirmSubmitButton: true,
          isCombineButton: true,
          isDownloadButton: true,
          isCancelCombineButton: true
        }}
      />
    </div>
  );
};

export default PendingEdit;
