import { put, takeEvery, all, call } from "redux-saga/effects";
import * as userDetails from "../actions/userDetails";

export function* getUserDetailsData(action: any): any {
  try {
    const response: any = yield call(action.Get);
    let payloadObj = null;
    if (response) {
      payloadObj = { ...response };
    }
    yield put(userDetails.getUserDetailsSuccess(payloadObj));
  } catch (error) {
    yield put(userDetails.getUserDetailsError(error));
  }
}

export function* getUserDetailsDataSaga() {
  yield takeEvery("GET_USER_DATA", getUserDetailsData);
}

export function* rootUserDetailsSaga() {
  yield all([getUserDetailsDataSaga()]);
}
