const getNotificationFlag = () => ({
  type: "GET_NOTIFICATION_FLAG"
});

const updateNotificationFlag = () => ({
  type: "UPDATE_NOTIFICATION_FLAG",
  Update: (flag: boolean) => {
    const currentFlagObj = { changeFlag: !flag };
    localStorage.setItem("notificationFlag", JSON.stringify(currentFlagObj));
    return { changeFlag: !flag };
  }
});

export { getNotificationFlag, updateNotificationFlag };
