import { getUnilab } from "@/api/deliveryLetterList";
import { updateDos } from "@/api/deliveryOrder";
import MyAutoComplete from "@/pages/common/autoComplete";
import { Button, Form, message, Modal, Radio } from "antd";
import { useCallback, useState } from "react";

interface Props {
  data?: any;
  getTable?: any;
  text?: boolean;
  isPendingPage?: boolean;
  isFulfillmentOrderPage?: boolean;
  isPendingEditPage?: boolean;
}

const BatchEdit = (props: Props) => {
  const { data, getTable, text, isPendingPage } = props;
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [tipsVisible, setTipsVisible] = useState(false);
  const [unilabOptions, setUnilabOptions] = useState([]);
  const [values, setValues] = useState({} as any);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const onBatchEdit = useCallback(() => {
    let prCode = data[0].productCode;
    data.filter((val: any) => val.productCode !== prCode).length === 0 ? setVisible(true) : setTipsVisible(true);
  }, [data]);

  const unilabFocus = useCallback(() => {
    setIsSearchLoading(true);
    setUnilabOptions([]);
    getUnilab({
      productCode: data[0].productCode,
      customerCode: data[0].customerCode,
      orgCode: data[0].orgCode,
      businessDivision: data[0].businessDivision || "",
      searchTerm: values.unilab
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          const data = res.data.unilab;
          const temp =
            data &&
            data.map((val: any) => {
              return {
                label: val.name,
                value: val.name
              };
            });
          setUnilabOptions(temp);
        }
      })
      .catch((exception: any) => {
        console.error(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      })
      .finally(() => {
        setIsSearchLoading(false);
      });
  }, [data, values.unilab]);

  // 确定
  const onDoFinish = useCallback(
    (values: any) => {
      const temp = data.map((val: any) => {
        val.unilab = values.unilab;
        val.bigOrder = values.bigOrder;
        return val;
      });
      updateDos(temp, {
        type: "editDo"
      })
        .then((res: any) => {
          if (res.code && res.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(res.errorMsg);
          } else {
            getTable();
            form.resetFields();
            setValues({});
            setVisible(false);
          }
        })
        .catch((exception: any) => {
          console.error(`Failure: ${exception}`);
          message.error("获取数据失败，请稍后重试。");
        });
    },
    [data, form, getTable]
  );

  const cancelHandler = useCallback(() => {
    form.resetFields();
    setValues({});
    setVisible(false);
  }, [form]);

  const onDoSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const onDoTipsClose = useCallback(() => {
    setTipsVisible(false);
  }, []);

  return (
    <>
      <Button
        data-testid="batch-edit"
        type={text ? "text" : "default"}
        onClick={onBatchEdit}
        disabled={data.length === 0}
      >
        批量编辑
      </Button>
      {/* 批量编辑 */}
      {visible && (
        <Modal
          title="批量编辑"
          visible={visible}
          onCancel={cancelHandler}
          onOk={onDoSubmit}
          centered
          maskClosable={false}
          destroyOnClose
          okText="保存"
        >
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={onDoFinish}
            initialValues={{
              bigOrder: "N"
            }}
          >
            {!isPendingPage && (
              <Form.Item label="Unilab" name="unilab">
                <MyAutoComplete options={unilabOptions} onFocus={unilabFocus} isLoading={isSearchLoading} />
              </Form.Item>
            )}
            <Form.Item label="大提单" name="bigOrder">
              <Radio.Group>
                <Radio value="Y">是</Radio>
                <Radio value="N">否</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {/* 提示 */}
      <Modal
        title="提示"
        visible={tipsVisible}
        onCancel={onDoTipsClose}
        centered
        maskClosable={false}
        footer={
          <Button type="primary" onClick={onDoTipsClose}>
            确定
          </Button>
        }
      >
        批量编辑仅适用于处理同一产品的提货委托函，请检查并重新选择。
      </Modal>
    </>
  );
};

export default BatchEdit;
