import { useCallback, useEffect, useState } from "react";
import { Select, Input, Button, Form, Row, Col, Collapse, Divider, DatePicker, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { allSupplier, allBusinessLine, logisticsStatus, doStatus, qualityFileOptions, poundFileOptions, typeOfShippingOptions } from "@/utils/constant";
import * as type from "../../constant";
import MyAutoComplete from "@/pages/common/autoComplete";
import { getProductDetailList } from "@/api/contractReport";
import { getCode, noRepeat } from "@/utils/common";
import moment from "moment";

const { Panel } = Collapse;

interface Props {
  getSearchValue: any;
  switcher: any;
  getOffset: any;
  contractNo?: any;
  setSortOrder: any;
  getSort: any;
}

const ExternalSearchFilter = (props: Props) => {
  const { getSearchValue, switcher, getOffset, contractNo, getSort, setSortOrder } = props;

  const [form] = Form.useForm();
  const [isClick, setIsClick] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [expandCollapseText, setExpandCollapseText] = useState("展开高级筛选");
  const [searchValue, setSearchValue] = useState({
    divisionCode: "",
    orgCode: "",
    productCode: "",
    customerCode: ""
  } as any);

  const handelExpandCollapse = useCallback(() => {
    setIsClick(!isClick);
    setIsExpand(!isExpand);
    setExpandCollapseText(isExpand ? "展开高级筛选" : "收起高级筛选");
  }, [isClick, isExpand]);

  const getCurrentAndThreeMonthsAgo = () => {
    const currentDate = moment(); // 获取当前日期
    const threeMonthsAgo = moment().subtract(3, 'months'); //三个月前的日期
    const firstDayOfThreeMonthsAgo = threeMonthsAgo.startOf('month'); // 获取三个月前的月份的第一天
    return [moment(firstDayOfThreeMonthsAgo.format("YYYY-MM-DD"), "YYYY-MM-DD"), moment(currentDate.format("YYYY-MM-DD"), "YYYY-MM-DD")];
  };

  useEffect(() => {
    if (contractNo) {
      form.setFieldsValue({ contractNumber: contractNo });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractNo]);

  useEffect(() => {
    if (switcher) {
      setProductOptions([]);
      form.setFieldsValue({
        divisionCode: switcher.divisionCode === null ? "" : switcher.divisionCode,
        orgCode: switcher.orgCode === null ? "" : switcher.orgCode
      });
      let value = searchValue;
      value.divisionCode = switcher.divisionCode === null ? "" : switcher.divisionCode;
      value.orgCode = switcher.orgCode === null ? "" : switcher.orgCode;
      value.customerCode = switcher.customerCode;
      value.isNew = true;
      // 获取提单提交日期范围
      const createdDate = getCurrentAndThreeMonthsAgo();
      value.createdDateFrom = createdDate[0]?.format("YYYY-MM-DD") || "";
      value.createdDateTo = createdDate[1]?.format("YYYY-MM-DD") || "";
      getSearchValue(value);
      getOffset(0, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, switcher.divisionCode, switcher.orgCode, switcher.customerCode]);

  const getProductName = useCallback(() => {
    setIsSearchLoading(true);
    setProductOptions([]);
    getProductDetailList({
      salesOrgs: searchValue.orgCode,
      productName: searchValue.productName
    })
      .then((res: any) => {
        if (res.data) {
          if (res.data.products.length > 0) {
            let options = res.data.products.map((val: any) => {
              return {
                label: val.productName,
                value: val.productName,
                key: val.productCode
              };
            });
            options = noRepeat(options);
            setProductOptions(options);
          } else {
            setProductOptions([]);
            message.error("No Results Found");
          }
        }
      })
      .finally(() => {
        setIsSearchLoading(false);
      });
  }, [searchValue.orgCode, searchValue.productName]);

  // 查询
  const onFinish = useCallback(
    (values: any) => {
      let temp = {
        completedDateFrom: values.completedDate ? values.completedDate[0].format("YYYY-MM-DD") : "",
        completedDateTo: values.completedDate ? values.completedDate[1].format("YYYY-MM-DD") : "",
        contractNumber: values.contractNumber,
        createdDateFrom: values.createdDate ? values.createdDate[0].format("YYYY-MM-DD") : "",
        createdDateTo: values.createdDate ? values.createdDate[1].format("YYYY-MM-DD") : "",
        customerCode: values.customerCode ? getCode(values.customerCode) : switcher.customerCode,
        customerOwnContractNumber: values.customerOwnContractNumber,
        deliveryStatus: values.deliveryStatus,
        distributionNo: "",
        divisionCode: values.divisionCode,
        doApplicationStatus: values.doApplicationStatus,
        isNew: true,
        orgCode: values.orgCode,
        packageSpecification: values.packageSpecification,
        productCode: values.productName,
        productName: "",
        truckNumber: values.truckNumber,
        warehouseTag: values.warehouseTag,
        salesOrderNumber: values.salesOrderNumber, // 新增提单号查询
        qualityFile: values.qualityFile,
        poundFile: values.poundFile,
        transportTool: values.transportTool,
        estimatedDeliveryDate: values.estimatedDeliveryDate ? values.estimatedDeliveryDate.format("YYYY-MM-DD") : ""
      };
      getSearchValue(temp);
      getOffset(0, 1);
    },
    [getOffset, getSearchValue, switcher.customerCode]
  );

  const onValueChange = useCallback(
    (changeValue: any, allValues: any) => {
      if (changeValue.contractNumber !== undefined) {
        const value = changeValue.contractNumber.replace(/[^\-?\d.]/g, "");
        form.setFieldsValue({ contractNumber: value });
        allValues.contractNumber = value;
      }
      const newSearchValues = Object.assign(searchValue, allValues);
      setSearchValue(newSearchValues);
    },
    [form, searchValue]
  );

  // 清空
  const resetValue = useCallback(() => {
    form.resetFields();
    form.setFieldsValue({
      divisionCode: switcher.divisionCode === null ? "" : switcher.divisionCode,
      orgCode: switcher.orgCode === null ? "" : switcher.orgCode,
      contractNumber: "",
      createdDate: null
    });
    let value: any = {
      divisionCode: switcher.divisionCode === null ? "" : switcher.divisionCode,
      orgCode: switcher.orgCode === null ? "" : switcher.orgCode,
      customerCode: switcher.customerCode,
      productCode: "",
      contractNumber: "",
      isNew: true
    };
    let sort = {
      sortDirection: type.DO_SORT_BY_ID_DESC,
      sort: type.DO_DESC
    };
    setProductOptions([]);
    getSort(sort, true);
    setSortOrder(false);
    getSearchValue(value);
    getOffset(0, 1);
  }, [
    form,
    getOffset,
    getSearchValue,
    getSort,
    setSortOrder,
    switcher.customerCode,
    switcher.divisionCode,
    switcher.orgCode
  ]);

  return (
    <div className="search-filter">
      <div className="base-filter">
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          autoComplete="off"
          onValuesChange={onValueChange}
          initialValues={{
            deliveryStatus: "",
            doApplicationStatus: "",
            createdDate: getCurrentAndThreeMonthsAgo()
          }}
        >
          <Row gutter={{ md: 16, xs: 8 }}>
            <Col md={5} xs={24}>
              <Form.Item name="productName">
                <MyAutoComplete
                  onFocus={getProductName}
                  isLoading={isSearchLoading}
                  options={productOptions}
                  placeholder="请输入产品名称"
                  type="sap"
                  value={searchValue.productCode}
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item name="createdDate">
                <DatePicker.RangePicker
                  data-testid="createdDate"
                  style={{ width: "100%" }}
                  dropdownClassName="range-picker-drop-down-mobile"
                  placeholder={["提货单提交日", "提货单提交日"]}
                />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="truckNumber">
                <Input placeholder="请输入车船号" />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="deliveryStatus">
                <Select style={{ width: "100%" }} options={logisticsStatus} placeholder="请选择物流状态" />
              </Form.Item>
            </Col>
            <Col xs={24} md={3}>
              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  className="base-submit-btn"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                  disabled={isClick}
                >
                  查询
                </Button>
              </Form.Item>
            </Col>
          </Row>
          {/* 展开筛选 */}
          <Collapse
            style={{ marginTop: -25 }}
            ghost
            expandIconPosition="right"
            onChange={handelExpandCollapse}
            collapsible="header"
          >
            <Panel header={<span style={{ color: "#007ed9" }}>{expandCollapseText}</span>} key="1" forceRender={true}>
              <Divider dashed={true} style={{ width: "101%", margin: "-10px 0 13px -14px" }} />
              <Row gutter={{ md: 16, xs: 8 }}>
                <Col md={6} xs={24}>
                  <Form.Item label="合同编号" name="contractNumber">
                    <Input data-testid="contractNumber" placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="包装规格" name="packageSpecification">
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="提货委托状态" name="doApplicationStatus">
                    <Select style={{ width: "100%" }} options={doStatus} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="提货完成日期" name="completedDate">
                    <DatePicker.RangePicker
                      data-testid="completedDate"
                      dropdownClassName="range-picker-drop-down-mobile"
                      style={{ width: "100%" }}
                      placeholder={["请选择", "请选择"]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ md: 16, xs: 8 }}>
                <Col md={6} xs={24}>
                  <Form.Item name="customerOwnContractNumber" label="买方自有合同号">
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item name="orgCode" className="no-label-form-item">
                    <Select
                      disabled={switcher.orgCode !== "All" && switcher.orgCode}
                      style={{ width: "100%" }}
                      options={allSupplier()}
                    />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item name="divisionCode" className="no-label-form-item">
                    <Select
                      disabled={switcher.divisionCode && switcher.divisionCode !== "All"}
                      style={{ width: "100%" }}
                      options={allBusinessLine()}
                    />
                  </Form.Item>
                </Col>
                {/* 新增提单头编号查询 */}
                <Col md={6} xs={24}>
                  <Form.Item label="提单头编号" name="salesOrderNumber">
                    <Input data-testid="salesOrderNumber" placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="是否有质量报告" name="qualityFile">
                    <Select placeholder="请选择" options={qualityFileOptions} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="是否有电子磅单" name="poundFile">
                    <Select placeholder="请选择" options={poundFileOptions} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="运输工具" name="transportTool">
                    <Select placeholder="请选择" options={typeOfShippingOptions} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item name="truckNumber" label="车牌号" className="form-item">
                    <Input allowClear placeholder="请输入车牌号" />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item
                    label="预计提货日期"
                    name="estimatedDeliveryDate"
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="请选择"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="advance-btn-col">
                  <Form.Item>
                    <div>
                      <Button
                        data-testid="reset-btn"
                        style={{ marginRight: 10, backgroundColor: "#5c5c5c" }}
                        onClick={resetValue}
                        type="primary"
                      >
                        清空
                      </Button>
                      <Button data-testid="submit-btn" type="primary" htmlType="submit">
                        立即查询
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </div>
  );
};

export default ExternalSearchFilter;
