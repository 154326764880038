import React, { useCallback, useEffect, useState } from "react";
import { AutoComplete, Spin, AutoCompleteProps } from "antd";

// 场景: 该组件封装用于需要进行输入后进行远程请求建议可选结果列表，以输入为主，选择为辅
// 赋值：未选择某条列表项，则自动保留输入值
const RemoteAutoComplete: React.FC<{
  placeholder: string;
  style: React.CSSProperties;
  loading?: boolean;
  value?: string;
  fetchOptions: (value: string | undefined, callback: Function, currentValue: string | undefined) => void;
  onChange?: (value: string) => void;
  dataTestId?: string;
  id?: string;
}> = props => {
  const [data, setData] = useState<AutoCompleteProps["options"]>([]);
  const [value, setValue] = useState<string | undefined>(props.value);
  const [isNeedFetch, setIsNeedFetch] = useState<boolean>(true);

  const onHandleInputChange = useCallback(
    (newValue: string) => {
      setIsNeedFetch(true);
      setValue(newValue);
      // 触发change事件更新到父级
      props.onChange && props.onChange(newValue);
    },
    [props]
  );

  const onHandleSelect = useCallback(
    (value: string, option: any) => {
      setIsNeedFetch(false);
      setValue(value);
      // 触发change事件更新到父级
      props.onChange && props.onChange(value);
    },
    [props]
  );

  useEffect(() => {
    // 表单主动清空值则重置信息
    if (!props.value) {
      setValue(props.value);
      setData([]);
    }
  }, [props.value]);

  useEffect(() => {
    const currentValue = value;
    // 自建防抖查询策略
    let autoCompleteTimeoutID: ReturnType<typeof setTimeout> | null | undefined;
    if (value && isNeedFetch) {
      if (autoCompleteTimeoutID) {
        clearTimeout(autoCompleteTimeoutID);
        autoCompleteTimeoutID = null;
      }
      autoCompleteTimeoutID = setTimeout(() => props.fetchOptions(value, setData, currentValue), 800);
    } else if (!value) {
      setData([]);
    }

    return () => {
      if (autoCompleteTimeoutID) {
        clearTimeout(autoCompleteTimeoutID);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <AutoComplete
      id={props.id || undefined}
      data-testid={props.dataTestId || null}
      allowClear
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      value={value}
      options={data || []}
      onSearch={onHandleInputChange}
      onSelect={onHandleSelect}
      notFoundContent={props.loading ? <Spin size="small" /> : null}
    />
  );
};

export default RemoteAutoComplete;
