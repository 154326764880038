import { Col, Row, Tooltip, Divider, PageHeader, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Component } from "react";
import { connect } from "react-redux";
import * as type from "./constant";
import "./index.scss";
import { getEnumInfo } from "@/utils";
import moment from "moment";
import { isHaveRolePermissionFeedFactory } from "@/utils/common";
import WechatDetails from "./wechatDetails";
import SlotBookingType from "./slotBookingType";
import UpdatePassword from "./updatePassword";

interface Props {
  userDetailsData: any;
  switcherDetails: any;
  permissions: any;
}

interface State {
  userInfo: any;
  isChangePwd: boolean; // 修改密码collapse
  buyerName: string;
  firmCodeValue: string;
  loading: boolean;
}

class UserCenter extends Component<Props, State> {
  public readonly state = {
    isChangePwd: false,
    buyerName: "",
    firmCodeValue: "",
    userInfo: {} as any,
    loading: false
  };

  componentDidMount() {
    const { switcherDetails } = this.props;
    document.title = "我的账户";
    this.setState({ loading: true });
    this.getUserInfo();
    this.setState({
      buyerName: switcherDetails ? switcherDetails.buyer : "",
      firmCodeValue: switcherDetails ? switcherDetails.customerCode : ""
    });
  }

  componentDidUpdate(preProps: any) {
    if (preProps.switcherDetails.buyer !== this.props.switcherDetails.buyer) {
      this.setState({
        buyerName: this.props.switcherDetails ? this.props.switcherDetails.buyer : "",
        firmCodeValue: this.props.switcherDetails ? this.props.switcherDetails.customerCode : ""
      });
    }
  }

  // 获取用户信息
  getUserInfo = async () => {
    const { userDetailsData } = this.props;
    if (userDetailsData) {
      let userType = "";
      let state = "";
      const { entitlements, profile, groups } = userDetailsData;
      if (entitlements) {
        if (entitlements[0].requestorGroup === type.ROLE_MANAGER_VALUE) {
          userType = type.ROLE_MANAGER_LABEL;
        } else if (entitlements[0].requestorGroup === type.ROLE_STAFF_VALUE) {
          userType = type.ROLE_STAFF_LABEL;
        }
      }
      if (userDetailsData.status === type.STATE_ACTIVE_VALUE) {
        state = type.STATE_ACTIVE_LABEL;
      } else if (userDetailsData.status === type.STATE_PROVISIONED_VALUE) {
        state = type.STATE_PROVISIONED_LABEL;
      } else if (userDetailsData.status === type.STATE_EXPIRED_VALUE) {
        state = type.STATE_EXPIRED_LABEL;
      }
      const myEnumInfo = await getEnumInfo();
      const profileValues = {
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        email: profile?.email,
        phoneNumber: profile?.primaryPhone,
        buyer: entitlements && entitlements[0].otherControls && entitlements[0].otherControls.organizationName,
        userType,
        state,
        userId: userDetailsData?.id,
        createDate:
          entitlements && entitlements[0].createdDate && moment(entitlements[0].createdDate).format(type.YYYY_MM_DD),
        groups,
        feedName: myEnumInfo.FEED_MODEL ? myEnumInfo.FEED_MODEL.feedName : ""
      };
      this.setState({
        userInfo: profileValues,
        loading: false
      });
    }
  };

  public render() {
    const { userInfo, buyerName, firmCodeValue, loading } = this.state;

    return (
      <>
        <PageHeader className="page-header" title="我的账户" />
        <div className="user-center">
          <Spin spinning={loading}>
            <div className="user-info-item">
              <span className="info-title">用户编号: -</span>
              <span className="msg">【{userInfo.state}】</span>
              <div className="create-date">用户创建日期：{userInfo.createDate}</div>
            </div>
            <div className="user-info-item">
              <span className="info-title" style={{ marginRight: 5 }}>
                账户信息
              </span>
              <Tooltip title="如需修改，请联系嘉吉" placement="right">
                <ExclamationCircleOutlined />
              </Tooltip>
              <div className="info-detail">
                <Row gutter={{ xs: 8, md: 16 }}>
                  <Col md={3} xs={9}>
                    <span className="label">姓氏</span>
                  </Col>
                  <Col md={5} xs={15}>
                    {userInfo.lastName || "-"}
                  </Col>
                  <Col className="row-underline-mobile" xs={24}>
                    <Divider />
                  </Col>
                  <Col md={3} xs={9}>
                    <span className="label">名字</span>
                  </Col>
                  <Col md={5} xs={15}>
                    {userInfo.firstName || "-"}
                  </Col>
                  <Col className="row-underline-mobile" xs={24}>
                    <Divider />
                  </Col>
                </Row>
                <Row className="row-underline-pc" gutter={[16, 16]}>
                  <Col span={8}>
                    <Divider />
                  </Col>
                  <Col span={8}>
                    <Divider />
                  </Col>
                </Row>
              </div>
              <div className="info-detail">
                <Row gutter={{ xs: 8, md: 16 }}>
                  <Col md={3} xs={9}>
                    <span className="label">联系方式</span>
                  </Col>
                  <Col md={5} xs={15}>
                    {userInfo.phoneNumber || "-"}
                  </Col>
                  <Col className="row-underline-mobile" xs={24}>
                    <Divider />
                  </Col>
                  <Col md={3} xs={9}>
                    <span className="label">邮箱</span>
                  </Col>
                  <Col md={5} xs={15}>
                    {userInfo.email || "-"}
                  </Col>
                  <Col className="row-underline-mobile" xs={24}>
                    <Divider />
                  </Col>
                </Row>
                <Row className="row-underline-pc" gutter={[16, 0]}>
                  <Col md={8} xs={24}>
                    <Divider />
                  </Col>
                  <Col md={8} xs={24}>
                    <Divider />
                  </Col>
                </Row>
              </div>
              <div className="info-detail">
                {isHaveRolePermissionFeedFactory() ? (
                  <Row gutter={{ xs: 8, md: 16 }}>
                    <Col md={3} xs={9}>
                      <span className="label">买方</span>
                    </Col>
                    <Col md={5} xs={15}>
                      {userInfo.buyer || "-"}
                    </Col>
                    <Col className="row-underline-mobile" xs={24}>
                      <Divider />
                    </Col>
                    <Col md={3} xs={9}>
                      <span className="label">用户目的地</span>
                    </Col>
                    <Col md={5} xs={15}>
                      {userInfo.feedName || "-"}
                    </Col>
                    <Col className="row-underline-mobile" xs={24}>
                      <Divider />
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={{ xs: 8, md: 16 }}>
                    <Col md={3} xs={9}>
                      <span className="label">买方</span>
                    </Col>
                    <Col md={5} xs={15}>
                      {userInfo.buyer || "-"}
                    </Col>
                    <Col className="row-underline-mobile" xs={24}>
                      <Divider />
                    </Col>
                    <Col md={3} xs={9}>
                      <span className="label">用户类型</span>
                    </Col>
                    <Col md={5} xs={15}>
                      {userInfo.userType || "-"}
                    </Col>
                    <Col className="row-underline-mobile" xs={24}>
                      <Divider />
                    </Col>
                  </Row>
                )}
                <Row className="row-underline-pc" gutter={[16, 0]}>
                  <Col md={8} xs={24}>
                    <Divider />
                  </Col>
                  <Col md={8} xs={24}>
                    <Divider />
                  </Col>
                </Row>
              </div>
            </div>
            {/* 预约方式 */}
            {this.props.permissions.CNExternalUsers && (
              <SlotBookingType
                userInfo={userInfo}
                buyerName={buyerName}
                firmCodeValue={firmCodeValue}
                userDetailsData={this.props.userDetailsData}
                switcherDetails={this.props.switcherDetails}
              />
            )}
            {/* 微信 */}
            <WechatDetails profileValues={userInfo} />
            {/* 修改密码 */}
            <UpdatePassword />
          </Spin>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  userDetailsData: state.userDetails,
  switcherDetails: state.switcher,
  permissions: state.userPermissions
});

export default connect(mapStateToProps, null)(UserCenter);
