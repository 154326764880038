import { Component } from "react";
import { Layout } from "antd";
import { connect } from "react-redux";
//当前页面路由配置
import { Route, Switch } from "react-router-dom";
import Home from "@/pages/home/home";
import Error from "@/pages/error/error";

import { dynamicRoutingPermissionList } from "./constant";
import { getLocalStorageItem } from "@/utils";

import { KeepAlive } from 'react-activation'

import "./cg-content.scss";

const { Content } = Layout;

interface State {
  myRoutes: object;
}

interface Props {
  userDetailsData: any;
  userPermissionsData: any;
}
class CgContent extends Component<Props, State> {
  public state = {
    myRoutes: []
  };

  componentDidMount() {
    this.settingDynamicRoutes();
  }

  // 获取动态路由 -- 增强页面访问权限的安全性
  settingDynamicRoutes = () => {
    const { userPermissionsData, userDetailsData } = this.props;
    const { groups } = userDetailsData;
    const menuPermissionsStr = getLocalStorageItem("localEnumInfo") || "";
    let menuPermissions: any = [];
    if (menuPermissionsStr && menuPermissionsStr.length > 0) {
      menuPermissions = JSON.parse(menuPermissionsStr)[groups[0]].permissions;
    }
    if (menuPermissions && menuPermissions.length > 0 && userPermissionsData) {
      const myRoutesTemp = dynamicRoutingPermissionList.filter((item: any) => {
        let isViewPermission = false;
        let isMenuPermission = false;
        // user-services 页面访问权限
        if (item.viewPermission.length === 0) {
          isViewPermission = true;
        } else {
          const viewPermissionLenArr = item.viewPermission.filter((itemView: any) => userPermissionsData[itemView]);
          isViewPermission = viewPermissionLenArr.length === item.viewPermission.length;
        }

        // DSC 菜单权限
        if (item.menuPermission.length > 0) {
          const menuPermissionLenArr = item.menuPermission.filter((itemMenu: any) =>
            menuPermissions.includes(itemMenu)
          );
          isMenuPermission = menuPermissionLenArr.length === item.menuPermission.length;
        }

        return isViewPermission && isMenuPermission;
      });
      this.setState({ myRoutes: myRoutesTemp || [] });
    }
  };

  public render() {
    const { myRoutes } = this.state;
    return (
      <Content className="cg-content">
        <Switch>
          {/* 动态路由，提高页面访问权限的安全性 */}
          <Route path="/main" exact component={Home} />
          <Route path="/main/home" component={Home} />
          {/* 动态路由注册，只注册该用户可以访问的路由 */}
          {myRoutes.map((item: any) => {
            return <Route path={item.path} key={item.path} component={item.keepAlive ? () => <KeepAlive when={item.keepAlive}><item.component /></KeepAlive> : item.component} />;
          })}
          <Route path="*" component={Error} />
        </Switch>
      </Content>
    );
  }
}

const mapStateToProps = (state: any) => ({
  userDetailsData: state.userDetails,
  userPermissionsData: state.userPermissions
});

export default connect(mapStateToProps, null)(CgContent);
