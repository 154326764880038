import { allOccBusinessLine, allSupplier } from "@/utils/constant";
import { Button, Col, Form, Row, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { useCallback, useEffect } from "react";
// 定义函数组件的props相关参数
interface myProps{
  switcher: any,
  userDetails: any,
  permissions: any,
  onSearch: (values: any) => void;
};

const SearchFilter = (props: myProps) => {
  const { switcher } = props;
  const [form] = Form.useForm();

  const onFinish = useCallback(
    (values?: any) => {
      if (values.orgCode === "All") {
        delete values.orgCode;
      }
      if (values.businessLine === "All") {
        delete values.businessLine;
      }
      props.onSearch(values);
    },
    [props]
  );

  useEffect(() => {
    if (switcher) {
      form.setFieldsValue({
        orgCode: switcher.orgCode === "All" ? "" : switcher.orgCode
      });
    }
  }, [form, switcher]);

  return (
    <div className="search-filter">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          orgCode: "",
          businessLine: ""
        }}
      >
        <Row gutter={{ xs: 8, md: 16 }} >
          <Col md={6} xs={24}>
            <Form.Item name="orgCode" label="卖方">
              <Select style={{ width: "100%" }} placeholder="请选择" options={allSupplier()} />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item name="businessLine" label="业务类型">
              <Select style={{ width: "100%" }} allowClear placeholder="请选择" options={allOccBusinessLine()} />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item>
              <Button
                style={{ marginTop: 30 }}
                type="primary"
                className="submit-btn"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                查询
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
const 
mapStateToProps = (state: any) => ({
  switcher: state.switcher,
  userDetails: state.userDetails,
  permissions: state.userPermissions
});

export default connect(mapStateToProps, null)(SearchFilter);