import { useState } from "react";
import "./index.scss";

// 定义函数组件的props相关参数
type myProps = {
  loadingRule: string;
  itemData: any;
  index: number;
};

const transportColorDom = (_props: any, itemData: any, timeStrText: string, remainingBookings: number) => {
  return (
    <>
      {_props.loadingRule === "VA" ? (
        <li
          key={`time3_${_props.index}_${new Date().getTime()}`}
          className="hdp-uf hdp-uf-dc hdp-uf-hc hdp-uf-vc datetime-item time datetime-item-transport"
        >
          <div>{timeStrText}</div>
          <div>{itemData.haveBookingPackage ? `已预约${itemData.haveBookingPackage}件` : ""}</div>
        </li>
      ) : (
        <li
          key={`time3_${_props.index}_${new Date().getTime()}`}
          className="hdp-uf hdp-uf-dc hdp-uf-hc hdp-uf-vc datetime-item time datetime-item-transport"
        >
          <div>{timeStrText}</div>
          <div>{`（剩余${remainingBookings}/总${itemData.bookingNumber}）`}</div>
        </li>
      )}
    </>
  );
};

const notOpenDom = (_props: any, itemData: any, timeStrText: string, remainingBookings: number) => {
  return (
    <>
      {_props.loadingRule === "VA" ? (
        <li
          key={`time3_${_props.index}_${new Date().getTime()}`}
          className="hdp-uf hdp-uf-dc hdp-uf-hc hdp-uf-vc datetime-item time datetime-item-disabled"
        >
          <div>{timeStrText}</div>
          <div>{itemData.haveBookingPackage ? `已预约${itemData.haveBookingPackage}件` : ""}</div>
        </li>
      ) : (
        <li
          key={`time3_${_props.index}_${new Date().getTime()}`}
          className="hdp-uf hdp-uf-dc hdp-uf-hc hdp-uf-vc datetime-item time datetime-item-disabled"
        >
          <div>{timeStrText}</div>
          <div>{`（剩余${remainingBookings}/总${itemData.bookingNumber}）`}</div>
        </li>
      )}
    </>
  );
};

const TimeDomSet = (_props: myProps) => {
  const [currentTime] = useState("");

  const itemData = _props.itemData;
  const index = _props.index;
  // 计算剩余预约量
  const remainingBookings = itemData.bookingNumber - itemData.haveBookingNumber;
  const timeStrText = itemData.timeStr ? `${itemData.timeStr.split(":")[0]}:${itemData.timeStr.split(":")[1]}` : "-";

  if (itemData.transportColorFlag) {
    return transportColorDom(_props, itemData, timeStrText, remainingBookings);
  }

  if (remainingBookings === 0 || itemData.status !== "OPEN") {
    return notOpenDom(_props, itemData, timeStrText, remainingBookings);
  }

  return (
    <>
      {_props.loadingRule === "VA" ? (
        <li
          key={`time3_${index}_${new Date().getTime()}`}
          className={`hdp-uf hdp-uf-dc hdp-uf-hc hdp-uf-vc datetime-item time ${
            currentTime === itemData.timeStr ? "datetime-item-on" : ""
          }`}
          role="presentation"
        >
          <div>{timeStrText}</div>
        </li>
      ) : (
        <li
          key={`time3_${index}_${new Date().getTime()}`}
          className={`hdp-uf hdp-uf-dc hdp-uf-hc hdp-uf-vc datetime-item time ${
            currentTime === itemData.timeStr ? "datetime-item-on" : ""
          }`}
          role="presentation"
        >
          <div>{timeStrText}</div>
          <div>{`（剩余${remainingBookings}/总${itemData.bookingNumber}）`}</div>
        </li>
      )}
    </>
  );
};

export default TimeDomSet;
