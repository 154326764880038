import { Button, message } from "antd";
import { downloadNotices } from "@/api/systemSetting";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { downloadFile } from "@/utils";
import { useCallback } from "react";

interface Props {
  data: any;
  filename?: string;
  text?: boolean;
  type?: any;
}

const DownloadNotice = (props: Props) => {
  const handleDownloadReports = useCallback(() => {
    downloadNotices(props.data, {})
      .then((response: any) => {
        if (response) {
          downloadFile(response, "附件");
        } else {
          message.error("下载失败，请稍后重试。");
        }
      })
      .catch((exception: any) => {
        message.error("下载失败，请稍后重试。");
        console.log(exception);
      });
  }, [props.data]);

  return (
    <>
      <Button
        type="text"
        icon={<VerticalAlignBottomOutlined />}
        onClick={handleDownloadReports}
        style={{ width: 20 }}
      ></Button>
    </>
  );
};

export default DownloadNotice;
