import { useState, useEffect, useCallback } from "react";
import { Card, PageHeader } from "antd";
import AddNewUser from "./addNewUser";
import SearchFilter from "./searchFilter";
import TableList from "./tableList";
import { getUserList } from "@/api/userManagement";
import { isMobileDevice } from "@/utils/common";
import "./index.scss";

const UserList = () => {
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [requestListValue, setRequestListValue] = useState({
    limit: 10,
    offset: 0,
    status: "all",
    group: "DXP_DSC_CN_ExternalUsers",
    sortby: "date",
    sort: "desc"
  });

  const onPageChange = (page: number, pageSize: number) => {
    if (pageSize === requestListValue.limit) {
      setPageSize(requestListValue.limit);
      setCurrent(page);
      const pager = JSON.parse(JSON.stringify(requestListValue));
      pager.offset = (page - 1) * pageSize;
      setRequestListValue(pager);
    } else {
      setCurrent(1);
      const pager = JSON.parse(JSON.stringify(requestListValue));
      pager.offset = 0;
      pager.limit = pageSize;
      setRequestListValue(pager);
      setPageSize(pager.limit);
    }
  };

  const paginationOpt: any = {
    showQuickJumper: true,
    total: total,
    showSizeChanger: true,
    onChange: onPageChange,
    current: current,
    size: "default",
    pageSize: pageSize
  };

  const getListData = useCallback(() => {
    setLoading(true);
    const values = requestListValue;
    setPageSize(values.limit);
    getUserList(values)
      .then(res => {
        if (res?.data) {
          const tableData = res.data.users || [];
          const total = res.data.count ?? 0;
          setData(tableData);
          setTotal(total);
        } else {
          setData([]);
          setTotal(0);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        setData([]);
        setTotal(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [requestListValue]);

  useEffect(() => {
    document.title = "用户列表";
  }, []);

  useEffect(() => {
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestListValue]);

  return (
    <>
      <PageHeader
        className="page-header"
        title={
          <>
            用户列表{" "}
            {isMobileDevice() ? (
              <AddNewUser doUpdateList={getListData} btnStyle={{ position: "absolute", right: 10, bottom: 16 }} />
            ) : (
              <AddNewUser doUpdateList={getListData} btnStyle={{ position: "absolute", right: 40, bottom: 20 }} />
            )}
          </>
        }
      ></PageHeader>
      <div className="settlement-list">
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb" }}
          bodyStyle={{ paddingTop: 0 }}
          title="查询条件"
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
        >
          <SearchFilter setFields={setRequestListValue} setCurrent={setCurrent} />
        </Card>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
          bodyStyle={{ paddingTop: 0 }}
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
          title={
            <div>
              <span style={{ fontWeight: 700, letterSpacing: 1 }}>用户列表</span>
              <div className="report-header">
                <span className="total">共 {total} 条</span>
              </div>
            </div>
          }
        >
          <TableList tableData={data} paginationOpt={paginationOpt} doUpdateList={getListData} loading={loading} />
        </Card>
      </div>
    </>
  );
};

export default UserList;
