import React, { useCallback, useEffect, useState } from "react";
import { Select } from "antd";
import { getDestinationLocations } from "@/api/constant";
import { uniqBy } from "lodash";

type ValueType = string | undefined | null;

// 买方收货地址
const AddressIdItem: React.FC<{
  orderType: string;
  customerCode: string;
  value?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  onChange?: (value: ValueType, option: any) => void;
}> = props => {
  const [value, setValue] = useState<ValueType>();
  const [destinationLocationOption, setDestinationLocationOption] = useState([]); // 发货地址

  // 获取买方收获地址List
  const getDestinationLocationOption = async (codeVal: any) => {
    if (!codeVal) {
      setValue(null);
      setDestinationLocationOption([]);
      return;
    }
    getDestinationLocations({
      accountNumber: codeVal,
      limit: 500
    }).then((res: any) => {
      if (res?.data?.accountsHarmonization?.length > 0) {
        const { accountsHarmonization } = res.data;
        // 买方收货地址只显示 "送达方" 的
        let options: any = accountsHarmonization.filter((item: any) => item.accountType === "SH");
        options = options.map((val: any) => ({
          label: val.address_line,
          value: val.address_id,
          accountnumber: val.account_number,
          key: val.address_id
        }));
        options = uniqBy(options, "value");
        setDestinationLocationOption(options);
        const initOption = options.filter((item: any) => item.value === props.value);
        // 如果买方地址、运输方式、嘉吉发货地址value不在选项之中，显示“请选择”
        if (initOption?.length > 0) {
          onHandleChange(props.value, initOption[0]);
        } else {
          onHandleChange(null, {});
        }
      } else {
        setDestinationLocationOption([]);
      }
    });
  };

  const onHandleChange = useCallback(
    (newValue: ValueType, currentOption: any) => {
      setValue(newValue);
      props.onChange && props.onChange(newValue, currentOption);
    },
    [props]
  );

  useEffect(() => {
    getDestinationLocationOption(props.customerCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.customerCode]);

  return (
    <Select
      data-testid="addressId"
      value={value}
      options={destinationLocationOption}
      placeholder="请选择"
      dropdownMatchSelectWidth={false}
      onChange={onHandleChange}
      disabled={props.orderType === "自提"}
      style={{ width: "80%" }}
    />
  );
};

export default AddressIdItem;
