import { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Card, Col, Collapse, Divider, message, PageHeader, Row, Spin } from "antd";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import {
  connectMaterialTextAndSpec,
  cutBatchNoZero,
  expandDelayDateDisplay,
  getNotNullValue,
  getProductNameInitValue
} from "@/utils/formatUtils";
import { isHaveRolePermissionFeedFactory } from "@/utils/common";
import { getContractDetailsById } from "@/api/contractReport";
import ProductNameSelect from "@/pages/common/productNameSelect";
import "./index.scss";

const { Panel } = Collapse;

interface Props {
  location: any;
  history: any;
  permission: any;
}

interface State {
  batch: any;
  contractDetailInfo: any;
  loading: boolean;
}

class ContractDetail extends Component<Props, State> {
  public readonly state = {
    batch: [] as any,
    contractDetailInfo: {} as any,
    loading: false
  };

  componentDidMount() {
    document.title = "合同详情";
    this.doGetContractDetail();
  }

  doGetContractDetail = () => {
    if (this.props.location?.state?.contractNo) {
      const contractNo = this.props.location.state.contractNo;
      this.setState({ loading: true }, () => {
        getContractDetailsById({ contractNo: contractNo })
          .then((res: any) => {
            if (res.code && res.code === "99999") {
              // 99999 提示后端的错误信息
              message.error(res.errorMsg);
            } else {
              const { batch, contractDetailInfo } = res.data.contract;
              this.setState({
                batch: batch,
                contractDetailInfo: contractDetailInfo
              });
            }
          })
          .catch((exception: any) => {
            console.log(`Failure: ${exception}`);
            message.error("获取数据失败，请稍后重试。");
            this.setState({
              batch: [],
              contractDetailInfo: {}
            });
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      });
    }
  };

  dealToleranceDisplay = (tolerance: any) => {
    if (tolerance) {
      return `±${tolerance}%`;
    } else if (tolerance === 0) {
      return "0";
    }
    return "-";
  };

  getProductName = (value: any, data: any) => {
    const batch: any = this.state.batch;
    if (!data || !value) {
      return;
    }
    const productList: any = data.productList.filter((product: any) => {
      let validateName = this.props.permission.CNInternalUsers
        ? product.productName
        : connectMaterialTextAndSpec(product.materialSalesText, product.packageSpecificationCn);
      if (product.businessLine === "包装油业务") {
        validateName = product.productName;
      }
      return validateName === value;
    });
    if (!productList || productList.length <= 0) {
      return;
    }
    const product = productList[0];
    const newItem: any = Object.assign(data, {
      packageSpecification: product.packageSpecification,
      packageSpecificationCn: product.packageSpecificationCn,
      packingUnit: product.packingUnit,
      packingUnitDesc: product.packingUnitDesc,
      productCode: product.productCode,
      conversionFactor: product.conversionFactor,
      productName: product.productName,
      materialSalesText: product.materialSalesText
    });
    for (let index = 0; index < batch.length; index += 1) {
      const element = batch[index];
      const doId1 = element.contractNo + cutBatchNoZero(element.batch);
      const doId2 = newItem.contractNo + cutBatchNoZero(newItem.batch);
      if (doId1 === doId2) {
        batch[index] = newItem;
        break;
      }
    }
    this.setState({ batch: batch });
  };

  // 设置买方发货地址的显示
  setShipAddressDesc = (item: { deliveryLocation: any; deliveryLocationCode: any }) => {
    if (item?.deliveryLocation && item?.deliveryLocationCode && this.props.permission.CNInternalUsers) {
      return `${item.deliveryLocationCode} (${item.deliveryLocation})`;
    }
    return item.deliveryLocation;
  };

  jumpToSapcontractreport = () => {
    this.props.history.push({
      pathname: "/main/contractmanagement/sapcontractreport",
      state: { referrerPage: "sapcontractreport" }
    });
  };

  public render() {
    const { permission } = this.props;
    const { batch, contractDetailInfo, loading } = this.state;

    return (
      <>
        <div className="contract-detail">
          <PageHeader className="page-header" title="合同详情" />
          <Divider style={{ margin: 0 }} />
          <Card style={{ height: "100%" }} bordered={false}>
            <Spin spinning={loading}>
              {!!batch.length && contractDetailInfo && (
                <>
                  <div className="title">
                    <span>合同号：</span>
                    <span>{contractDetailInfo?.contractNo}</span>
                    <span className="light">{`【${contractDetailInfo.divisionCode} - ${contractDetailInfo.businessLine}】`}</span>
                    <div className="billing-details">
                      <span style={{ color: "#07c", marginRight: 5, cursor: "pointer", fontSize: 12 }}>
                        <Link
                          to={{
                            pathname: "/main/delivery/sapdeliverylist",
                            state: { contractNo: contractDetailInfo.contractNo }
                          }}
                        >
                          查看开单明细
                        </Link>
                      </span>
                      <RightOutlined style={{ fontSize: 12 }} />
                    </div>
                  </div>

                  <Row gutter={{ md: 0, xs: 16 }} className="base-info-row">
                    <Col md={6} xs={24}>
                      <span>客户自有合同号: </span>
                      <span className="row-content">{contractDetailInfo.customerContractNumber || "-"}</span>
                    </Col>
                    <Col md={6} xs={24}>
                      <span>买方: </span>
                      <span className="row-content">{contractDetailInfo.buyer || "-"}</span>
                    </Col>
                    <Col md={6} xs={24}>
                      <span>卖方: </span>
                      <span className="row-content">{contractDetailInfo.plant || "-"}</span>
                    </Col>
                    <Col md={6} xs={24}>
                      <span>合同签订日期: </span>
                      <span className="row-content">{contractDetailInfo.contractDate || "-"}</span>
                    </Col>
                  </Row>

                  <div className="contract-card">
                    <div className="title">合同行项目编号</div>
                    {batch.length &&
                      batch.map((item: any) => {
                        return (
                          <Collapse
                            ghost
                            key={item.batch}
                            expandIconPosition="right"
                            style={{ marginBottom: 20 }}
                            defaultActiveKey={item.batch}
                          >
                            <Panel
                              header={cutBatchNoZero(item.batch)}
                              extra={<span>{item.customerContractType || "-"}</span>}
                              key={item.batch}
                            >
                              <Divider style={{ color: "#d9d9d9", marginTop: "-10px" }} />
                              <Row gutter={[16, 16]}>
                                <Col md={6} xs={12}>
                                  <span>客户自有合同号</span>
                                  <span className="row-content">{item.customerContractNumber || "-"}</span>
                                </Col>
                                <Col md={6} xs={12}>
                                  <span>合同数量</span>
                                  <span className="row-content">
                                    {(getNotNullValue(item.batchQuantity) && item.batchQuantity.toFixed(3)) || "-"}
                                  </span>
                                </Col>
                                <Col md={6} xs={12}>
                                  <span>已开单量</span>
                                  <span className="row-content">
                                    {getNotNullValue(item.approvedDoQuantityBatch) &&
                                      item.approvedDoQuantityBatch.toFixed(3)}
                                  </span>
                                </Col>
                                <Col md={6} xs={12}>
                                  <span>未开单量</span>
                                  <span className="row-content">
                                    {(getNotNullValue(item.remainingAvailableDOQtyBatch) &&
                                      item.remainingAvailableDOQtyBatch.toFixed(3)) ||
                                      "-"}
                                  </span>
                                </Col>
                              </Row>
                              <Row gutter={[16, 16]} style={{ marginTop: 20, marginBottom: 20 }}>
                                <Col md={6} xs={12}>
                                  <span>定金比例/履约保证金比例</span>
                                  <span className="row-content">
                                    {item.depositRate === "" || item.depositRate === null
                                      ? "-"
                                      : Number(item.depositRate).toFixed(2) + "%"}
                                  </span>
                                </Col>
                                <Col md={6} xs={12}>
                                  <span>{`结算单价（${item.settlementPriceUnitCn || "-"}）`}</span>
                                  <span className="row-content">
                                    {(!isHaveRolePermissionFeedFactory() &&
                                      getNotNullValue(item.batchSettlementPrice) &&
                                      item.batchSettlementPrice?.toFixed(2)) ||
                                      "-"}
                                  </span>
                                </Col>
                                <Col md={6} xs={12}>
                                  <span>{`基差价格（${item.settlementPriceUnitCn || "-"}）`}</span>
                                  <span className="row-content">
                                    {!isHaveRolePermissionFeedFactory() && item.basis
                                      ? parseFloat(item.basis).toFixed(2)
                                      : "-"}
                                  </span>
                                </Col>
                                <Col md={6} xs={12}>
                                  <span>合约月份</span>
                                  <span className="row-content">{item.dce || "-"}</span>
                                </Col>
                              </Row>
                              <Row gutter={[16, 16]} style={{ marginTop: 20, marginBottom: 20 }}>
                                <Col md={6} xs={12}>
                                  <span>交/提货方式</span>
                                  <span className="row-content">{item.shipmentMethod || "-"}</span>
                                </Col>
                                <Col md={6} xs={12}>
                                  <span>收货地址</span>
                                  <span className="row-content">{item.destinationLocationDesc || "-"}</span>
                                </Col>
                                <Col md={6} xs={12}>
                                  <span>发货地址</span>
                                  <span className="row-content">{this.setShipAddressDesc(item) || "-"}</span>
                                </Col>
                                <Col md={6} xs={12}>
                                  <span>交货地点</span>
                                  <span className="row-content">{item.incotermsLocationName || "-"}</span>
                                </Col>
                              </Row>
                              <Row gutter={[16, 16]} style={{ marginTop: 20, marginBottom: 20 }}>
                                <Col md={6} xs={12}>
                                  <span>提货开始日期</span>
                                  <span className="row-content">{item.executionStartDate || "-"}</span>
                                </Col>
                                <Col md={6} xs={12}>
                                  <span>提货截止日期</span>
                                  <span className="row-content">{item.executionEndDate || "-"}</span>
                                </Col>
                                <Col md={6} xs={12}>
                                  <span>包装规格</span>
                                  <span className="row-content">{item.packageSpecificationCn || "-"}</span>
                                </Col>
                                <Col md={6} xs={12}>
                                  <span>计数单位</span>
                                  <span className="row-content">{item.packingUnitDesc || "-"}</span>
                                </Col>
                              </Row>
                              <Row gutter={[16, 16]} style={{ marginTop: 20, marginBottom: 20 }}>
                                <Col md={6} xs={12}>
                                  <span>产品名称</span>
                                  <span className="row-content">
                                    <ProductNameSelect
                                      data={item}
                                      onChange={this.getProductName}
                                      initValue={getProductNameInitValue(item.productList[0])}
                                    />
                                  </span>
                                </Col>
                                {permission.CNInternalUsers && (
                                  <>
                                    <Col md={6} xs={12}>
                                      <span>预提</span>
                                      <span className="row-content">
                                        {expandDelayDateDisplay(item.withholdingDate)}
                                      </span>
                                    </Col>
                                    <Col md={6} xs={12}>
                                      <span>延期</span>
                                      <span className="row-content">{expandDelayDateDisplay(item.delayDate)}</span>
                                    </Col>
                                    <Col md={6} xs={12}>
                                      <span>产品代码</span>
                                      <span className="row-content">
                                        {item.productCode && item.productCode.replace(/\b(0+)/gi, "")}
                                      </span>
                                    </Col>
                                  </>
                                )}
                              </Row>
                              {permission.CNInternalUsers && (
                                <Row gutter={[16, 16]}>
                                  <Col md={6} xs={12}>
                                    <span>运输方式</span>
                                    <span className="row-content">{item.transportDesc || "-"}</span>
                                  </Col>
                                  <Col md={6} xs={12}>
                                    <span>溢短装</span>
                                    <span className="row-content">{`${this.dealToleranceDisplay(
                                      item.tolerance
                                    )}`}</span>
                                  </Col>
                                  <Col md={6} xs={12}>
                                    <span>有效延期</span>
                                    <span className="row-content">{expandDelayDateDisplay(item.delayDate)}</span>
                                  </Col>
                                  <Col md={6} xs={12}>
                                    <span>结价状态</span>
                                    <span className="row-content">{item.customerPricingStatus || "-"}</span>
                                  </Col>
                                </Row>
                              )}
                            </Panel>
                          </Collapse>
                        );
                      })}
                  </div>
                  {/* <Link style={{ float: "right" }} to="/main/contractmanagement/sapcontractreport">
                    <Button type="primary">返回列表页</Button>
                  </Link> */}
                  <Button type="primary" style={{ float: "right" }} onClick={() => this.jumpToSapcontractreport()}>返回列表页</Button>
                </>
              )}
            </Spin>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  permission: state.userPermissions
});

export default connect(mapStateToProps, null)(ContractDetail);
