import { isMobileDevice } from "@/utils/common";
import { Button, Modal } from "antd";
import { useState, useEffect, useCallback } from "react";
import TableTransfer from "./TableTransfer";
import "./index.scss";

interface Props {
  fieldDesc: any;
  allFields: any;
  getColumns?: any;
  text?: boolean;
}

let oldTargetKeys: any = [];

const FieldSetting = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const [targetKeys, setTargetKeys] = useState([] as any);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // 处理null字段
    const fieldDesc = props.fieldDesc.filter((val: any) => val);
    const options = fieldDesc.filter((item: any) => !fieldDesc.includes(item.value));
    const initialKeys: any = options.map((val: any) => val.value);
    // 合并获得数据源
    let data = fieldDesc.concat(props.allFields);
    let obj: any = {};
    const newData = data.reduce(function(pre: any, cur: any) {
      if (!obj.hasOwnProperty(cur.value)) {
        pre.push(cur);
      }
      obj[cur.value] = true;
      return pre;
    }, []);
    setDataSource(newData);
    setTargetKeys(initialKeys);
    setLoading(false);
    oldTargetKeys = initialKeys;
  }, [props.allFields, props.fieldDesc]);

  // 获取拖拽后数据
  const getChangedColumns = useCallback((data: any) => {
    let temp = data.map((item: any) => item.value);
    setTargetKeys(temp);
  }, []);

  // 穿梭框
  const onChange = useCallback((nextTargetKeys: any, direction: string, moveKeys: any) => {
    if (direction === "right") {
      const noChangeKeys = nextTargetKeys.filter((val: any) => {
        return !moveKeys.includes(val);
      });
      setTargetKeys([...noChangeKeys, ...moveKeys]);
    } else {
      setTargetKeys(nextTargetKeys);
    }
  }, []);

  // 字段设置确定
  const fieldsSetting = useCallback(() => {
    oldTargetKeys = targetKeys;
    setLoading(true);
    props.getColumns(targetKeys, dataSource, setVisible);
  }, [dataSource, props, targetKeys]);

  // 取消设置
  const cancelConfirm = useCallback(() => {
    setTargetKeys(oldTargetKeys);
    setVisible(false);
  }, []);

  const leftColumns = [
    {
      dataIndex: "label",
      title: "可选字段",
      key: "label",
      render: (_: any, record: any, index: number) => {
        return `${index + 1}. ${record.label}`;
      }
    }
  ];

  const rightColumns = [
    {
      dataIndex: "label",
      title: isMobileDevice() ? "我的字段" : "我的字段（上下拖拽可实现字段排序）", //（拖拽可排序）
      key: "label",
      render: (_: any, record: any, index: number) => {
        return `${index + 1}. ${record.label}`;
      }
    }
  ];

  const onHandleShowModal = useCallback(() => {
    setVisible(true);
  }, []);

  return (
    <div className="field-setting">
      <Button data-testid="field-setting" type={props.text ? "text" : "primary"} onClick={onHandleShowModal}>
        字段设置
      </Button>
      <Modal
        title="字段设置"
        visible={visible}
        width={800}
        centered
        maskClosable={false}
        destroyOnClose
        wrapClassName="field-setting-modal"
        onCancel={cancelConfirm}
        footer={
          <div>
            <div className="tips">
              <span></span>
              注：该字段为默认显示字段，不可移除。
            </div>
            <Button data-testid="cancel-btn" onClick={cancelConfirm}>
              取消
            </Button>
            <Button data-testid="confirm-btn" loading={loading} type="primary" onClick={fieldsSetting}>
              确定
            </Button>
          </div>
        }
      >
        <TableTransfer
          dataSource={dataSource}
          targetKeys={targetKeys}
          onChange={onChange}
          leftColumns={leftColumns}
          rightColumns={rightColumns}
          getChangedColumns={getChangedColumns}
        />
      </Modal>
    </div>
  );
};

export default FieldSetting;
