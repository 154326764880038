import React, { useCallback, useEffect, useState } from "react";
import { AutoComplete, message } from "antd";
import { history } from "@/api/constant";

// 备注历史数据参考
const ExternalMemoItem: React.FC<{
  value?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: (value: string, option: any) => void;
}> = props => {
  const [value, setValue] = useState<string | undefined>(props.value);
  const [userRemarkOptions, setUserRemarkOptions] = useState<any>([]); // 历史备注可选列表

  const onHandleChange = useCallback(
    (newValue: string, currentOption: any) => {
      setValue(newValue);
      props.onChange && props.onChange(newValue, currentOption);
    },
    [props]
  );

  const onFilterOption = useCallback((inputValue: any, option: any) => {
    return option?.value.indexOf(inputValue) !== -1;
  }, []);

  // 获取输入的历史参考数据
  const getUserRemarkHistory = useCallback(
    (event?: any) => {
      // 只在每次渲染后，第一次focus是获取一次
      if (userRemarkOptions && userRemarkOptions.length > 0) {
        return;
      }
      history({ type: "userRemark", searchTerm: "" })
        .then((res: any) => {
          if (res.code && res.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(res.errorMsg);
          } else if (res?.data?.history) {
            const data = res.data.history.map((val: any) => {
              return { label: val, value: val };
            });
            setUserRemarkOptions(data);
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          message.error("获取数据失败，请稍后重试。");
        });
    },
    [userRemarkOptions]
  );

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <AutoComplete
      data-testid="test-externalMemo"
      placeholder="请输入"
      value={value}
      options={userRemarkOptions}
      onFocus={getUserRemarkHistory}
      filterOption={onFilterOption}
      onChange={onHandleChange}
    />
  );
};

export default ExternalMemoItem;
