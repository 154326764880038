import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Table } from "antd";
import { getBuyerList } from "@/api/systemSetting";

const BuyerModal = (props: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [requestListValue, setRequestListValue] = useState({
    limit: 10,
    offset: 0,
    id: props.id
  });

  useEffect(() => {
    const getListData = () => {
      const values = requestListValue;
      getBuyerList(values).then(res => {
        const tableData = res.data.notices.content;
        const total = res.data.notices.totalElements;
        setData(tableData);
        setTotal(total);
      });
    };

    if (isModalVisible) {
      getListData();
    }
  }, [isModalVisible, requestListValue]);

  const onPageChange = (page: number) => {
    const pager = JSON.parse(JSON.stringify(requestListValue));
    pager.offset = (page - 1) * 10;
    setRequestListValue(pager);
  };

  const paginationOpt = {
    showQuickJumper: false,
    total: total,
    pageSize: 10,
    showSizeChanger: false,
    onChange: onPageChange
  };

  const handleOk = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleVisible = useCallback(() => {
    setIsModalVisible(!isModalVisible);
  }, [isModalVisible]);

  const columns = [
    {
      title: "公司代码",
      dataIndex: "buyerCode",
      key: "buyerCode"
    },
    {
      title: "买方",
      dataIndex: "buyerName",
      key: "buyerName"
    }
  ];
  return (
    <div className="record-btn">
      {props.isAssignBuyer === "0" ? (
        <Button type="link" onClick={handleVisible} style={{ padding: 0, width: 56, height: 22 }}>
          全部买方
        </Button>
      ) : (
        <> {props.receiveCustomerCode}</>
      )}
      <Modal
        title="查看详情"
        visible={isModalVisible}
        footer={
          <Button type="primary" onClick={handleOk}>
            确定
          </Button>
        }
        onCancel={handleCancel}
      >
        <div>
          <span>全部买方列表</span>
          <div className="total-item" style={{ float: "right" }}>
            共 {total} 条
          </div>
        </div>
        <Table columns={columns} pagination={paginationOpt} dataSource={data} scroll={{ x: "max-content" }} />
      </Modal>
    </div>
  );
};
export default BuyerModal;
