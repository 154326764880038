import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Tabs, PageHeader } from 'antd';
import "./index.scss"
import ProductionSetting from "./productionSetting";
import FinanceSetting from "./financeSetting";
import ContractSetting from "./contractSetting";
import NotificationTips from "@/pages/common/notificationTips";
import { connect } from "react-redux";
import MessageSetting from "./messageSetting";

const { TabPane } = Tabs;

interface Props extends RouteComponentProps { permissions: any, userDetailsData: any; }
interface State { activeKey: string }

class Setting extends Component<Props, State> {
  readonly state = {
    activeKey: this.props.userDetailsData.groups?.includes("DXP_DSC_Role_CN_Cargill_Planner")?"1":"2"
  }
  onChange = (key: any) => {
    this.setState({ activeKey: key })
  }
  componentDidMount() {
    document.title = "站内通知设置"
  }
  public render() {
    const { permissions, userDetailsData } = this.props
    return (
      <>
        <div className="setting-tab-container">
          <PageHeader
            className="page-header-cyrus"
            style={{ padding: "16px 0px" }}
            title="公告及通知设置"
          ><NotificationTips /></PageHeader>
          <Tabs onChange={this.onChange}>
            {userDetailsData.groups?.includes("DXP_DSC_Role_CN_Cargill_Planner") && <TabPane tab="短信通知" key="1">
              {this.state.activeKey === "1" && <MessageSetting />}
            </TabPane>}
            
            <TabPane tab="生产公告" key="2">
              {this.state.activeKey === "2" && <ProductionSetting />}
            </TabPane>
            {permissions.PaymentNotifyEdit && <TabPane tab="财务类" key="3">
              {this.state.activeKey === "3" && <FinanceSetting />}
            </TabPane>}
            {permissions.ContractMaginNotifyEdit && <TabPane tab="合同类" key="4">
              {this.state.activeKey === "4" && <ContractSetting />}
            </TabPane>}
          </Tabs>
        </div>
      </>
    )
  }
}
const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  userDetailsData: state.userDetails
});

export default connect(mapStateToProps, null)(Setting);