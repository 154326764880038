import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Button, Checkbox, Divider, Form, message, Modal, Select, Spin } from "antd";
import { SwapOutlined } from "@ant-design/icons";
import { allSupplier, allBusinessLine } from "@/utils/constant";
import * as switcherActions from "@/store/actions/switcher";
import { getFirmDetailsList } from "@/api/constant";

const ChangeBusinessLine = (props: any) => {
  const { switcher, permissions, userDetailsData } = props;
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false); // 是否显示选择的Modal
  const [isCurrentDefault, setIsCurrentDefault] = useState(switcher?.isDefault || false); // 是否设为默认
  const [customerOptions, setCustomerOptions] = useState([]); // 全部客户公司可选项
  const [loading, setLoading] = useState(false);

  const doSetInitCustomerData = useCallback(
    (resDataSource: any) => {
      let options = resDataSource.map((val: any) => {
        return {
          label: `${val.accountLongName.trim()}(${val.accountNumber})`,
          value: val.accountNumber,
          name: val.accountLongName.trim()
        };
      });
      if (options.length > 1) {
        options.unshift({
          label: "全部买方",
          value: "All",
          name: ""
        });
      } else {
        form.setFieldsValue({ customerCode: options[0].value });
      }
      setCustomerOptions(options);
    },
    [form]
  );

  // 目前只可选单个和全部的情况，全部时，置换成 “All”
  const formatCustomerCodeInitVal = useCallback((switcherCustomerCode: string) => {
    if (switcherCustomerCode?.split(",").length > 1) {
      return "All";
    }
    return switcherCustomerCode;
  }, []);

  const formatValue = useCallback((val: any) => {
    return val || null;
  }, [])

  // 提交Switcher数据
  const onHandleSubmit = useCallback(() => {
    const customerCodeValArr: string[] = [];
    const buyerValArr: string[] = [];
    // 外部用户--组装 customerCode 和 customerName(buyer), 字符串“,”分隔
    if (permissions.CNExternalUsers) {
      const customerCodeFieldVal = form.getFieldValue("customerCode");
      if (customerCodeFieldVal === "All") {
        customerOptions
          .filter((item: any) => item.value !== "All")
          .forEach((val: any) => {
            customerCodeValArr.push(val.value);
            buyerValArr.push(val.name);
          });
      } else {
        const customerItemData: any[] = customerOptions.filter((val: any) => val.value === customerCodeFieldVal);
        if (customerItemData?.length > 0) {
          customerCodeValArr.push(customerItemData[0]?.value);
          buyerValArr.push(customerItemData[0]?.name);
        }
      }
      if (!customerCodeFieldVal) {
        message.error("请设置所属公司");
        return;
      }
    }

    // 内部用户--要求选择卖方公司
    if (permissions.CNInternalUsers) {
      const orgCodeFieldVal = form.getFieldValue("orgCode");
      if (!orgCodeFieldVal) {
        message.error("请选择卖方");
        return;
      }
    }

    const payloadObj: any = {
      buyer: formatValue(buyerValArr.join(",")),
      customerCode: formatValue(customerCodeValArr.join(",")),
      orgCode: formatValue(form.getFieldValue("orgCode")),
      divisionCode: formatValue(form.getFieldValue("divisionCode")),
      isDefault: isCurrentDefault,
      isFirstLogin: false
    };

    // 提交switcher设置变更
    props.updateSwitcherData(payloadObj, setLoading, setVisible);
  }, [permissions.CNExternalUsers, permissions.CNInternalUsers, form, isCurrentDefault, props, customerOptions, formatValue]);

  // 打开切换卖方/业务线
  const onOpenChangeBusinessAndBuyer = useCallback(() => {
    setVisible(true);
  }, []);

  // 更新是否默认
  const getCheckValue = useCallback((e: any) => {
    setIsCurrentDefault(e.target.checked);
  }, []);

  const onHandleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const onFilterOption = useCallback((inputValue: any, option: any) => {
    return ((option?.label as unknown) as string).toLowerCase().includes(inputValue?.toLowerCase());
  }, []);

  // 获取orgCode的初始值
  const getInitialOrgCode = useCallback(() => {
    const isCNInternalUsers = !!permissions.CNInternalUsers;
    const orgCode = switcher?.orgCode || "All";
    if (isCNInternalUsers) {
      return orgCode === "All" ? "" : orgCode;
    }
    return orgCode;
  }, [permissions.CNInternalUsers, switcher?.orgCode]);

  // 内、外部用户处理逻辑 -- 在弹如下条件必须窗选择对应的值
  useEffect(() => {
    // 检测switcher的设置情况,内、外部用户必须设置switcher信息，需要提醒设置相关值，可选择全部，可不选择默认
    // 外部用户如果没设置默认的话，再次登录进来后端会把他的值置为空返给前端，需要重新选择，所以需要判断 customerCode、orgCode、divisionCode 是否有值即可
    if ((permissions?.CNExternalUsers && (!switcher?.customerCode || !switcher?.orgCode || !switcher?.divisionCode)) ||
        (permissions?.CNInternalUsers && (!switcher?.orgCode || switcher?.orgCode === "All"))) {
      setVisible(true);
    }
  }, [permissions?.CNExternalUsers, permissions?.CNInternalUsers, switcher]);

  // 外部用户处理逻辑 -- 获取所有可选公司数据
  useEffect(() => {
    if (permissions.CNExternalUsers && visible) {
      const { entitlements } = userDetailsData;
      if (entitlements?.length > 0) {
        const { controlIdentifier, customerCode: accountNumber } = entitlements[0];
        setLoading(true);
        getFirmDetailsList({ accountNumber: accountNumber || controlIdentifier })
          .then((res: any) => {
            if (res.code && res?.code === "99999") {
              // 99999 提示后端的错误信息
              message.error(res.errorMsg);
              setCustomerOptions([]);
              form.setFieldsValue({ customerCode: null });
            } else if (res.data?.customerInfoEntitlement?.length > 0) {
              doSetInitCustomerData(res.data?.customerInfoEntitlement);
            } else {
              setCustomerOptions([]);
              form.setFieldsValue({ customerCode: null });
            }
          })
          .catch((err: any) => {
            message.error(err.errorMsg || "获取数据失败，请稍后重试。");
            setCustomerOptions([]);
            form.setFieldsValue({ customerCode: null });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [visible, permissions.CNExternalUsers, userDetailsData, form, switcher.customerCode, doSetInitCustomerData]);

  return (
    <>
      <span data-testid="open" style={{ marginRight: 6 }} onClick={onOpenChangeBusinessAndBuyer}>
        切换卖方/业务线
      </span>
      <SwapOutlined />
      {/* 切换业务线 */}

      <Modal
        visible={visible}
        title="切换卖方/业务线"
        centered
        destroyOnClose
        forceRender
        maskClosable={false}
        // closable={!permissions?.CNExternalUsers && !switcher.isFirstLogin && !loading}
        closable={!permissions?.CNExternalUsers && !switcher.isFirstLogin && switcher.orgCode && switcher.orgCode !== "All"}
        width={450}
        onCancel={onHandleCancel}
        bodyStyle={{ padding: "30px 25px" }}
        footer={
          <div style={{ padding: "0 10px" }}>
            <Checkbox
              data-testid="checkbox"
              style={{ float: "left" }}
              checked={isCurrentDefault}
              onChange={getCheckValue}
            >
              设为默认
            </Checkbox>
            <Button data-testid="confirm" type="primary" onClick={onHandleSubmit} disabled={loading}>
              确定
            </Button>
          </div>
        }
      >
        <div className="tips">您可以根据所需，切换卖方或业务线进行查看与操作。</div>
        <Spin spinning={loading}>
          <Form
            form={form}
            preserve={false}
            initialValues={{
              customerCode: formatCustomerCodeInitVal(switcher?.customerCode) || null,
              orgCode: getInitialOrgCode(),
              divisionCode: switcher?.divisionCode || "All"
            }}
          >
            {/* 买方客户 */}
            {permissions.CNExternalUsers && (
              <>
                <Divider style={{ margin: "10px 0" }} />
                <Form.Item style={{ marginBottom: 0 }} name="customerCode">
                <Select
                    showSearch
                    data-testid="customerCode"
                    style={{ width: "100%" }}
                    placeholder="选择买方"
                    optionFilterProp="label"
                    options={customerOptions}
                    filterOption={onFilterOption}         
                  />
                </Form.Item>
              </>
            )}
            {/* 卖方 */}
            <Form.Item name="orgCode" style={{ marginBottom: 0 }}>
              <Select
                data-testid="orgCode"
                style={{ width: "100%", margin: "20px  0" }}
                placeholder="选择卖方"
                options={permissions.CNInternalUsers ? allSupplier(["All"]) : allSupplier([""])}
              />
            </Form.Item>
            {/* 业务线 */}
            <Form.Item style={{ marginBottom: 0 }} name="divisionCode">
              <Select
                data-testid="divisionCode"
                style={{ width: "100%" }}
                placeholder="选择业务线"
                options={allBusinessLine([""])}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  switcher: state.switcher,
  userDetailsData: state.userDetails
});

const mapDispatchToProps = (dispatch: (arg0: { type: string }) => void) => ({
  getSwitcherInfo: () => {
    dispatch(switcherActions.getSwitcherData());
  },
  updateSwitcherData: (data: any, setLoading: any, setVisible: any) => {
    dispatch(switcherActions.updateSwitcherData(data, setLoading, setVisible));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeBusinessLine);
