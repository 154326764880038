import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { uniqBy } from "lodash";
import { Button, message, Modal, Tooltip, Spin } from "antd";
import moment from "moment";
import CancelCombine from "@/pages/common/cancelCombine";
import CombineButton from "@/pages/common/combineButton";
import FieldSetting from "@/pages/common/fieldSetting";
import TableDelete from "@/pages/deliveryOrder/components/TableDelete";
import { isHaveRolePermissionFeedFactory, isMobileDevice, filterColumnsData, extractNumber } from "@/utils/common";
import { convertUTCDateToChinaDate, cutBatchNoZero, setShipAddressDesc } from "@/utils/formatUtils";
import { saveMovementFieldsOrder } from "@/api/constant";
import { getDeliveryOrders, updateDos } from "@/api/deliveryOrder";
import TableList from "./components/tableList/index";

interface MyProps {
  refGo: (availableProperties: any) => void;
  setDoIdRow: (row: any) => void; // 编辑的do对象
  userPermissions: any; // 权限数据
  updateContractListData: (searchParams?: any, actionData?: any) => void; // 更新合同数据
  doActionCbForEditCheck: (actionDataArr: any[], actionType: string) => void; // 提交或者删除后，通知父级组件
}

interface MyState {
  doTableDataSource: any[];
  fieldsDesc: any[];
  allFields: any[];
  columns: any[];
  submitVisible: boolean;
  submitInternalVisible: boolean;
  totalCount: number;
  currentPageNum: number;
  offset: number;
  limit: number;
  checkedRows: any[];
  selectedRowKeys: any[];
  editRow: object;
  tableDataLoading: boolean;
  submitLoading: boolean;
  currentNewDoId: number;
  sequenceExecutionVisible: boolean;
  missingMergedDosVisible: boolean;
}

class DoCreatedList extends Component<MyProps, MyState> {
  public readonly state = {
    doTableDataSource: [],
    fieldsDesc: [],
    allFields: [],
    columns: [],
    submitVisible: false,
    submitInternalVisible: false,
    totalCount: 0,
    currentPageNum: 1,
    offset: 0,
    limit: 10,
    checkedRows: [],
    selectedRowKeys: [],
    editRow: {},
    tableDataLoading: false,
    submitLoading: false,
    currentNewDoId: 0,
    sequenceExecutionVisible: false,
    missingMergedDosVisible: false,
  };

  componentDidMount() {
    this.getDoTableList();

    // 绑定自定义的 RefGo，可将要暴露给父级组件的相关属性汇总在此处
    this.props.refGo({
      getDoTableList: this.getDoTableList
    });
  }

  // 获取表格新数据 tag：delete｜add｜edit
  getDoTableList = (tag: any = "", newDoId?: string | number) => {
    const { currentPageNum, doTableDataSource, offset, limit } = this.state;

    let newOffset = offset;
    // 删除时，如果当前页只有一条数据，需要定位到上一页面
    if (tag === "delete" && currentPageNum > 1 && doTableDataSource.length === 1) {
      newOffset = (currentPageNum - 2) * limit;
      this.setState({ currentPageNum: currentPageNum - 1, offset: newOffset });
    }

    this.setState({ tableDataLoading: true });
    getDeliveryOrders({
      offset: newOffset,
      limit: 100
    })
      .then((res: any) => {
        if (res?.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else if (res?.data?.deliveryOrder) {
          let currentNewDoIdTemp = 0;
          if (tag === "add" && res?.data?.deliveryOrder?.length > 0 && newDoId) {
            const currentNewDo = res.data.deliveryOrder.filter((item: any) => item.doId === newDoId);
            currentNewDoIdTemp = currentNewDo?.[0]?.doId || 0;
          }
          const resDataSource = res.data?.deliveryOrder || [];
          const defaultAllFields = this.filterFieldsList(JSON.parse(res.data.fieldsOrder?.allFields || []));
          const defaultFieldDesc = this.filterFieldsList(JSON.parse(res.data.fieldsOrder?.fieldDesc || []));
          // 设置最终Table可展示的字段信息
          this.setState({ currentNewDoId: currentNewDoIdTemp }, () => {
            this.doSetTableColumns(defaultFieldDesc);
          });
          // 设置其他数据源
          this.setState({
            doTableDataSource: resDataSource,
            allFields: defaultAllFields,
            fieldsDesc: defaultFieldDesc,
            totalCount: res.data?.metadata?.totalCount,
            selectedRowKeys: [],
            checkedRows: []
          });
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        this.setState({ doTableDataSource: [], totalCount: 0 });
      })
      .finally(() => {
        this.setState({ tableDataLoading: false });
      });
  };

  // 获取点击选中平台号进行编辑
  doSetDoIdRow = (currentRowItem: any, index: number) => (event: any) => {
    const { currentNewDoId } = this.state;
    if (currentRowItem.doId === currentNewDoId) {
      // 点击编辑，则取消 new 的Tag
      this.setState({ currentNewDoId: 0 });
    }
    // 自动选中
    this.handleSelectChange([index], [currentRowItem]);
    // 更新当前被编辑的条目数据
    this.setState({ editRow: currentRowItem });
    // 通知父级最新的编辑数据
    console.log(currentRowItem);
    this.props.setDoIdRow(currentRowItem);
  };

  allColumns = () => {
    const { currentNewDoId } = this.state;
    return [
      {
        title: "平台序列号",
        dataIndex: "doId",
        key: "doId",
        className: "drag-visible",
        render: (_: any, record: any, index: number) => (
          <Tooltip placement="leftBottom" title="点击后可修改" color="#007681">
            <span data-testid="doId" className="do-id" onClick={this.doSetDoIdRow(record, index)}>
              {record.doId}
            </span>
            {record.doMerge && <span className="do-merge">已拼单</span>}
            {!record.doMerge && record.doId === currentNewDoId && <span className="do-merge">新建</span>}
          </Tooltip>
        )
      },
      { title: "合同编号", dataIndex: "contractNo", key: "contractNo" },
      {
        title: "合同行项目编号",
        dataIndex: "batch",
        key: "batch",
        render: (_: any, record: any) => {
          return cutBatchNoZero(record.batch);
        }
      },
      {
        title: "创建时间",
        dataIndex: "createdDate",
        key: "createdDate",
        render: (_: any, record: any) => {
          return record.createdDate ? moment(record.createdDate).format("YYYY-MM-DD HH:mm") : "";
        }
      },
      { title: "买方", dataIndex: "buyer", key: "buyer" },
      {
        title: "产品名称",
        dataIndex: "productName",
        key: "productName",
        render: (_: any, record: any) => {
          return this.props.userPermissions.CNInternalUsers ? record.productName : record.materialSalesText;
        }
      },
      { title: "包装规格", dataIndex: "packageSpecificationCn", key: "packageSpecification" },
      {
        title: "嘉吉发货地址",
        dataIndex: "shipAddressDesc",
        key: "shipAddressDesc",
        render: (_: any, record: any) => {
          return setShipAddressDesc(record);
        }
      },
      { title: "买方收货地址", dataIndex: "receiveAddressDesc", key: "receiveAddressDesc" },
      { title: "紧急/一次性买方收货地址", dataIndex: "emergencyReceiveAddress", key: "emergencyReceiveAddress" },
      { title: "计划提货数量", dataIndex: "doQuantity", key: "doQuantity" },
      {
        title: "计划提货数量（箱/件）",
        dataIndex: "doQuantityByPackage",
        key: "doQuantityByPackage",
        render: (_: any, record: any) => {
          return record.doQuantityByPackage || "-";
        }
      },
      { title: "车/船号", dataIndex: "truckPlateNumber", key: "truckPlateNumber" },
      { title: "运输方式", dataIndex: "transportTypeDesc", key: "transportTypeDesc" },
      {
        title: "车辆限载吨位",
        dataIndex: "truckLoadingLimit",
        key: "truckLoadingLimit",
        render: (_: any, record: any) => {
          return record.truckLoadingLimit || "";
        }
      },
      { title: "承运人联系方式", dataIndex: "driverContactNumber", key: "driverContactNumber" },
      { title: "承运人姓名", dataIndex: "driverName", key: "driverName" },
      { title: "买方自有合同号（选填）", dataIndex: "buyerContactNumber", key: "buyerContactNumber" },
      { title: "提单打印信息", dataIndex: "shippingRemark", key: "shippingRemark" },
      { title: "发货要求", dataIndex: "shippingInstruction", key: "shippingInstruction" },
      { title: "备注", dataIndex: "externalMemo", key: "externalMemo" },
      {
        title: "大提单",
        dataIndex: "bigOrder",
        key: "bigOrder",
        render: (_: any, record: any) => {
          return record.bigOrder && record.bigOrder === "Y" ? "是" : "否";
        }
      },
      {
        title: "预计提货日期",
        dataIndex: "estimatedDeliveryDate",
        key: "estimatedDeliveryDate",
        render: (_: any, record: any) => {
          return record.estimatedDeliveryDate ? moment(record.estimatedDeliveryDate).format("YYYY-MM-DD") : "";
        }
      },
      {
        title: "离厂状态短信通知",
        dataIndex: "smsRequired",
        key: "smsNotification",
        render: (_: any, record: any) => {
          return record.smsRequired ? "开启" : "不开启";
        }
      },
      {
        title: "保存时间",
        dataIndex: "modifiedDate",
        key: "modifiedDate",
        render: (_: any, record: any) => {
          return record.modifiedDate && moment(record.modifiedDate).format("YYYY-MM-DD HH:mm");
        }
      },
      { title: "拼单编号", dataIndex: "mergeRemark", key: "mergeRemark" },
      {
        title: "跨客户拼单",
        dataIndex: "customerMerge",
        key: "customerMerge",
        render: (_: any, record: any) => {
          return record.customerMerge ? "是" : "否";
        }
      },
      { title: "序号", dataIndex: "doPriority", key: "doPriority" },
      {
        title: "优先执行",
        dataIndex: "contractExecutionSequenceControl",
        key: "contractExecutionSequenceControl",
        render: (_: any, record: any) => {
          return record.contractExecutionSequenceControl ? "是" : "否";
        }
      },
    ];
  };

  mapSavedTime = (date: any) => {
    const chinaDateTime = convertUTCDateToChinaDate(date);
    return `${moment(chinaDateTime.date).format("YYYY-MM-DD")} ${chinaDateTime.time}`;
  };

  filterFieldsList = (fieldsData: any) => {
    if (fieldsData) {
      const externalUsersIgnoreFields = ["transportTypeDesc", "shippingInstruction"];
      const internalUsersIgnoreFields = ["externalMemo"];
      const factoryIgnoreFields = ["batchSettlementPrice", "unbilledAmountUnit"];
      if (this.props.userPermissions.CNExternalUsers) {
        fieldsData = fieldsData.filter((val: any) => !externalUsersIgnoreFields.includes(val.value));
      } else {
        fieldsData = fieldsData.filter((val: any) => !internalUsersIgnoreFields.includes(val.value));
      }
      // 判断饲料厂用户额外字段过滤
      if (fieldsData.length > 0 && isHaveRolePermissionFeedFactory()) {
        fieldsData = fieldsData.filter((item: any) => !factoryIgnoreFields.includes(item.value));
      }
    }
    return uniqBy(fieldsData, "value");
  };

  // 删除do单成功后的回调处理
  onHandleDoDeleteActionSuccess = (doData: any) => {
    const { updateContractListData, doActionCbForEditCheck } = this.props;
    // 刷新当前页面的DO单列表
    this.getDoTableList("delete");
    // 由于删除操作会带来剩余可预制开单量的变化
    // 通知待执行合同列表，DO单有数据删除，自行根据操作的DO单的 “合同号” 判断是否更新自身列表数据
    updateContractListData(null, doData);
    // 通知父级组件,用于判断是否该条数据的“合同号”与正在编辑的数据的“合同号”相同,则需要被清空
    doActionCbForEditCheck([doData], "delete");
  };

  // 过滤出Table可以展示的字段
  doSetTableColumns = (newFieldsDesc: any) => {
    let currentShowColumns: any = filterColumnsData(this.allColumns(), newFieldsDesc);
    currentShowColumns.push({
      title: "删除",
      align: "center" as "center",
      dataIndex: "delete",
      key: "delete",
      fixed: "right" as "right",
      render: (_: any, record: any) => <TableDelete data={record} cb_success={this.onHandleDoDeleteActionSuccess} />
    });

    this.setState({ columns: currentShowColumns });
  };

  // 变更字段设置后的columns
  getFieldsSettingNewColumns = (data: any, dataSource: any, setVisibleFun: any) => {
    let temp = data.map((item: any) => {
      return dataSource.find((val: any) => val.value === item);
    });
    temp = temp.filter((val: any) => val);
    temp = temp.map((val: any) => {
      if (val.value === "doId") val.fixed = true;
      return val;
    });
    saveMovementFieldsOrder({
      type: "deliveryOrderSaveList",
      fieldDesc: JSON.stringify(temp)
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          this.getDoTableList();
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      })
      .finally(() => {
        setVisibleFun(false);
      });
  };

  handleSelectChange = (key: any, rows: any) => {
    this.setState({ selectedRowKeys: key, checkedRows: rows });
  };

  // 分页变换处理
  onPageChange = (page: number) => {
    this.setState({ currentPageNum: page });
  };

  // 获取所有选中的平台序列号
  getSelectedDoIds = () => {
    const { checkedRows } = this.state;
    const selectedDoIds: any[] = [];
    checkedRows.forEach((row: any) => {
      if (row.doId) {
        selectedDoIds.push(row.doId);
      }
    });
    return selectedDoIds;
  };

  // 获取未选中的提货委托函
  getUnSelectedRows = () => {
    const { doTableDataSource } = this.state;
    const selectedDoIds = this.getSelectedDoIds();
    const unSelectedRows: any[] = [];
    doTableDataSource.forEach((data: any) => {
      if (selectedDoIds.indexOf(data.doId) === -1) {
        unSelectedRows.push(data)
      }
    });
    return unSelectedRows;
  };

  // 1、外部用户创建委托，自动copy合同发货要求 + 外部备注，并且拼接到提单打印信息后面
  // 2、内部用户创建委托，自动copy合同发货要求
  formatCheckedRows = (checkedRows: any) => {
    const { userPermissions } = this.props;
    return checkedRows.map((row: any) => {
      const baseShippingRemark = row.shippingRemark || "";
      let shippingInstruction = row.shippingInstruction || "";
      // 针对外部用户特殊处理
      if (userPermissions.CNExternalUsers && row.externalMemo) {
        shippingInstruction = shippingInstruction ? `${shippingInstruction}；用户备注：${row.externalMemo}` : row.externalMemo;
      }
      const shippingRemark = [baseShippingRemark, shippingInstruction]
        .filter(Boolean)
        .join('；')
        .trim();
      row.shippingInstruction = shippingInstruction;
      row.shippingRemark = shippingRemark;
      return row;
    });
  };

  /**
   * 检查优先执行顺序的逻辑
   * @param checkedRows 
   * @param unSelectedRows 
   * @param nonPriortyDos 
   * @param unSelectedPriortyDos 
   * @returns 
   */
  checkPriorityExecution = (
    checkedRows: any[],
    unSelectedRows: any[],
    nonPriortyDos: any[],
    unSelectedPriortyDos: any[]
  ): boolean => {
    // 如果没有选中的行，或者未选中的行为空（表示全选），直接返回false
    if (!checkedRows.length || !unSelectedRows.length) {
      return false;
    }

    // 判断两个委托函是否具有相同的基本属性
    const hasSameBasicProperties = (item1: any, item2: any): boolean => {
      const properties = ['orgCode', 'buyer', 'businessLine', 'productCode', 'packageSpecificationCn'];
      return properties.every(prop => item1[prop] === item2[prop]);
    };

    // 情况1：选中的行中包含非优先执行的提货委托函
    if (nonPriortyDos.length > 0) {
      return nonPriortyDos.some(nonPriorityItem =>
        unSelectedPriortyDos.some(priorityItem =>
          hasSameBasicProperties(priorityItem, nonPriorityItem) &&
          priorityItem.contractExecutionSequenceControl
        )
      );
    }

    // 情况2：选中的都是优先执行的提货委托函
    return checkedRows.some(checkedItem =>
      unSelectedPriortyDos.some(priorityItem =>
        hasSameBasicProperties(priorityItem, checkedItem) &&
        priorityItem.contractExecutionSequenceControl &&
        new Date(priorityItem.createdDate) < new Date(checkedItem.createdDate)
      )
    );
  };

  // 执行选中数据的提交
  doSubmitDos = () => {
    const { checkedRows } = this.state;

    if (!checkedRows || checkedRows.length === 0) {
      return;
    }
    // 提交时：请先提交以下提货委托函，再提交其他委托函，条件：选择提交的提货委托中包含非优先执行且未选择的部分包含需要优先执行的，弹出这个提示。
    const unSelectedRows: any[] = this.getUnSelectedRows(); // 未选中的提货委托函
    // 选中的非优先执行的提货委托函
    const nonPriorityDos = checkedRows.filter((row: any) => {
      return !row.contractExecutionSequenceControl;
    });
    // 获取未选中的提货委托函中是否存在需要优先执行的
    const unSelectedPriorityDos = unSelectedRows.length > 0 ? unSelectedRows.filter((row: any) => row.contractExecutionSequenceControl) : [];

    if (this.checkPriorityExecution(checkedRows, unSelectedRows, nonPriorityDos, unSelectedPriorityDos)) {
      // 打开按序优先执行提示弹窗
      this.setState({ sequenceExecutionVisible: true });
      return;
    }
    // 同一组拼车的提单一起提交，否则会提交失败
    const mergedDos = checkedRows.filter((row: any) => {
      return row.doMerge && row.mergeGroup && row.mergeOrder;
    });
    if (mergedDos) {
      if (this.hasMissingMergedDos(mergedDos)) {
        // 打开拼单提交提示
        this.setState({ missingMergedDosVisible: true });
        return;
      }
    }
    this.setState({ submitLoading: true });
    const checkedRowsTemp = this.formatCheckedRows(checkedRows);
    this.doUpldateDos(checkedRowsTemp, "submitPreview");
  };

  hasMissingMergedDos = (mergedDos: any) => {
    const mergeDosGroup: any = {};
    let hasMissingMergedDosTemp = false;
    mergedDos.forEach((item: any) => {
      const groupKey = `${item.mergeGroup}_${item.mergeOrder}`
      if (Object.keys(mergeDosGroup).includes(groupKey)) {
        mergeDosGroup[groupKey].push(item);
      } else {
        mergeDosGroup[groupKey] = [item];
      }
    });
    if (Object.keys(mergeDosGroup).length) {
      hasMissingMergedDosTemp = Object.keys(mergeDosGroup).some((key: any) => {
        // 获取拼单组总的拼单数量
        const totalMergeDos = extractNumber(mergeDosGroup[key][0].mergeRemark);
        return totalMergeDos !== null && totalMergeDos > mergeDosGroup[key].length
      })
    }
    return hasMissingMergedDosTemp;
  };

  doUpldateDos = (checkedRows: any, type: string) => {
    const { userPermissions, doActionCbForEditCheck } = this.props;
    this.setState({ submitLoading: true });
    updateDos(checkedRows, { type })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          // 如果有数据正在被编辑，则需要通知表单直接清空数据(因为表单提交会清空列表选中项，会造成编辑区域数据无法定位到DO单列表中的)
          doActionCbForEditCheck(checkedRows, "submitPreview");
          // 清空已选择项
          this.setState({ selectedRowKeys: [], checkedRows: [] });
          if (userPermissions.CNInternalUsers) {
            this.setState({ submitInternalVisible: true });
          } else {
            this.setState({ submitVisible: true });
          }
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("提交数据失败，请稍后重试。");
      })
      .finally(() => {
        this.setState({ submitLoading: false });
      });
  };

  // 外部用户提交DO单后
  doCloseSubmitVisibleModal = () => {
    this.setState({ submitVisible: false });
    this.getDoTableList();
  };

  // 内部用户提交DO单后
  doCloseSubmitInternalVisibleModal = () => {
    this.setState({ submitInternalVisible: false });
    this.getDoTableList();
  };

  // 关闭按序优先执行提示弹窗
  doCloseSequenceExecutionVisibleModal = () => {
    this.setState({ sequenceExecutionVisible: false });
  };

  // 关闭拼单提交提示
  doCloseMissingMergedDosVisibleModal = () => {
    this.setState({ missingMergedDosVisible: false })
  };

  public render() {
    const {
      totalCount,
      fieldsDesc,
      allFields,
      columns,
      doTableDataSource,
      tableDataLoading,
      selectedRowKeys,
      checkedRows,
      currentPageNum,
      submitLoading,
      submitVisible,
      submitInternalVisible,
      sequenceExecutionVisible,
      missingMergedDosVisible
    } = this.state;

    return (
      <div className="created-table">
        <Spin spinning={submitLoading}>
          <div className="created-table-header">
            <div>已建提货委托函</div>
            <div>
              <span className="total">共{totalCount}条</span>
              <FieldSetting fieldDesc={fieldsDesc} allFields={allFields} getColumns={this.getFieldsSettingNewColumns} />
            </div>
          </div>
          {/* 可手动调整顺序的 */}
          <TableList
            columns={columns}
            data={doTableDataSource}
            getTable={this.getDoTableList}
            loading={tableDataLoading}
            rowSelection={{ type: "checkbox", fixed: true, onChange: this.handleSelectChange, selectedRowKeys }}
            pagination={{
              current: currentPageNum,
              total: totalCount,
              showQuickJumper: true,
              showSizeChanger: true,
              size: isMobileDevice() ? "small" : "default",
              onChange: this.onPageChange
            }}
          />
          {/* <Pagination
            style={{ marginTop: 20, width: "100%", textAlign: "right" }}
            showQuickJumper
            showSizeChanger
            current={currentPageNum}
            size={isMobileDevice() ? "small" : "default"}
            onChange={this.onPageChange}
            total={totalCount}
          /> */}
          <div className="button" style={{ height: 30, marginTop: 20 }}>
            <Button
              disabled={checkedRows.length === 0}
              type="primary"
              data-testid="submit-btn"
              loading={submitLoading}
              onClick={this.doSubmitDos}
              style={{ float: "right", marginRight: 10 }}
            >
              提交
            </Button>
            <CancelCombine
              getTable={this.getDoTableList}
              data={checkedRows}
              style={{ float: "right", marginRight: 10 }}
            />
            <CombineButton
              data={checkedRows}
              getTable={this.getDoTableList}
              style={{ float: "right", marginRight: 10 }}
            />
          </div>
        </Spin>

        {/* 提交成功提示框 -- 外部用户 */}
        <Modal
          title="提交成功"
          visible={submitVisible}
          centered
          maskClosable={false}
          onCancel={this.doCloseSubmitVisibleModal}
          footer={
            <div>
              <Button data-testid="cancel-btn" style={{ marginRight: 10 }} onClick={this.doCloseSubmitVisibleModal}>
                继续停留
              </Button>
              <Link data-testid="jump-btn" to={"/main/delivery/sapdeliverylist"}>
                <Button type="primary">立即跳转</Button>
              </Link>
            </div>
          }
        >
          <span>您是否想要立即跳转至提货单列表页面或者继续停留在此页面？</span>
        </Modal>

        {/* 提交成功后内部跳转提货委托函列表 -- 内部用户 */}
        <Modal
          title="提交成功"
          visible={submitInternalVisible}
          centered
          maskClosable={false}
          onCancel={this.doCloseSubmitInternalVisibleModal}
          footer={
            <div>
              <Button
                data-testid="cancel-btn"
                onClick={this.doCloseSubmitInternalVisibleModal}
                style={{ marginRight: 10 }}
              >
                继续停留
              </Button>
              <Link data-testid="jump-btn" to={{ pathname: "/main/delivery/deliveryletterlist" }}>
                <Button type="primary">立即跳转</Button>
              </Link>
            </div>
          }
        >
          <span>您是否想要立即跳转至提货委托函列表页面或者继续停留在此页面？</span>
        </Modal>
        <Modal
          title="提示"
          visible={sequenceExecutionVisible}
          centered
          maskClosable={false}
          onCancel={this.doCloseSequenceExecutionVisibleModal}
          footer={
            <Button type="primary" onClick={this.doCloseSequenceExecutionVisibleModal}>
              确认
            </Button>
          }
        >
          存在比您所选择的提货委托函更早创建且需要优先执行的提货委托函，请优先提交，参考 “优先执行“ 标志和 “创建时间” 查找
        </Modal>
        <Modal
          title="提示"
          visible={missingMergedDosVisible}
          centered
          maskClosable={false}
          onCancel={this.doCloseMissingMergedDosVisibleModal}
          footer={
            <Button type="primary" onClick={this.doCloseMissingMergedDosVisibleModal}>
              确认
            </Button>
          }
        >
          同一拼车的所有提单必须同时提交
        </Modal>
      </div >
    );
  }
}

const mapStateToProps = (state: any) => ({
  userPermissions: state.userPermissions
});

export default connect(mapStateToProps, null)(DoCreatedList);
