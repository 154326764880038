import { useCallback, useEffect, useState } from "react";
import { Button, Modal, Form, Input, Row, Col, Select, message, Spin } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import moment from "moment";
import { getValidFirmCode } from "@/api/systemSetting";
import { getObjProp } from "@/utils";
import { allBusinessLine, allSupplier } from "@/utils/constant";
import {
  deactivateUser,
  reinviteUser,
  resetPassword,
  saveUser,
  getFeedOption,
  saveEmailId
} from "@/api/userManagement";
import SlotBookingType from "../slotBookingType";

const { Option } = Select;

type myProps = {
  doUpdateList: () => void;
  doCloseModal: () => void;
  dataSource: any;
  feedMillUsers: any[];
  viewType: string;
};

const EditUser = (props: myProps) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showFirm, setShowFirm] = useState(false);
  const [isFirmId, setIsFirmId] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [companyInfoLoading, setCompanyInfoLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [removed, setRemoved] = useState(false);
  const [feedOptions, setFeedOptions] = useState<any[]>([]);
  const [firmDetails, setFirmDetails] = useState({
    firmCode: "",
    customerType: "",
    cargillOrganization1Code: "",
    cargillOrganization1Description: "",
    cargillOrganization2Code: "",
    cargillOrganization2Description: "",
    cargillOrganization4Code: "",
    cargillOrganization4Description: "",
    customerTypeDescription: null,
    accountNumber: "",
    accountLongName: "",
    parentAccountNumber: null,
    parentAccountLongName: null,
    customerRelationship: ""
  });
  const [entitlements, setEntitlements] = useState<any[]>([]);

  // props发生变化时更新相关数据状态
  useEffect(() => {
    setIsModalVisible(!!props.viewType);
    setIsEdit(props.viewType === "edit");
    const currentDataSource = props.dataSource;

    const setNewEntitlements = (entitlements: any) => {
      setEntitlements([
        {
          controlIdentifier: entitlements.controlIdentifier,
          controlType: entitlements.controlType,
          otherControls: entitlements.otherControls,
          requestorApp: "DSC",
          requestorGroup: entitlements.requestorGroup,
          requestorType: entitlements.requestorType
        }
      ]);
    };
    setNewEntitlements(currentDataSource.entitlements ? currentDataSource.entitlements[0] : []);

    let arrnew: any[] = [];
    currentDataSource?.entitlements?.forEach((e: any) => {
      arrnew.push(e.controlIdentifier);
    });
    form.setFieldsValue({
      controlIdentifier: arrnew
    });

    const returnFeedCode = (recordData: any) => {
      let feedCode = "";
      for (let item of props.feedMillUsers) {
        if (item?.oktaId === recordData?.profile?.email) {
          feedCode = item.feedCode;
          break;
        }
      }
      return feedCode;
    };

    // 判定是否是饲料厂用户
    const isFeedMillUserVal = props?.feedMillUsers?.find(
      (feedUserItem: any) => feedUserItem.oktaId === currentDataSource?.profile?.email
    )
      ? 1
      : 2;

    form.setFieldsValue({
      lastName: currentDataSource?.profile?.lastName,
      firstName: currentDataSource?.profile?.firstName,
      primaryPhone: currentDataSource?.profile?.primaryPhone,
      email: currentDataSource?.profile?.email,
      requestorGroup: currentDataSource?.entitlements ? currentDataSource?.entitlements?.[0]?.requestorGroup : null,
      companyId: currentDataSource?.entitlements ? currentDataSource?.entitlements?.[0]?.controlIdentifier : null,
      controlIdentifier: currentDataSource?.entitlements ? returnArr(currentDataSource?.entitlements).split(",") : null,
      feedMillUser: isFeedMillUserVal,
      destination: isFeedMillUserVal === 1 ? returnFeedCode(currentDataSource) : "",
      plant: currentDataSource?.plant,
      businessLine: currentDataSource?.businessLine
    });

    // 默认获取公司代码详细信息
    handleFirmDetail();

    // 饲料厂用户需要获取目的地选项
    if (isFeedMillUserVal === 1) {
      getFeedOption({ firmCode: form.getFieldValue("companyId") }).then(res => {
        if (res?.data[0]) {
          setFeedOptions(res.data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSource, form, props.feedMillUsers, props.viewType]);

  const handleModal = (res: any) => {
    if (res?.data === "User DeActivated Successfully" || res?.data === "User has been DeActivated Successfully") {
      Modal.success({
        content: "该用户已失效成功！",
        afterClose: () => {
          setIsModalVisible(false);
          setIsEdit(false);
          props.doUpdateList();
          props.doCloseModal();
        }
      });
    } else if (res?.data === "Password Reset email has been sent successfully") {
      Modal.success({
        content: "密码重置流程已发起。",
        afterClose: () => {
          setIsModalVisible(false);
          setIsEdit(false);
          props.doUpdateList();
          props.doCloseModal();
        }
      });
    }
  };

  const handleDeactivateUser = useCallback(() => {
    setActionLoading(true);
    deactivateUser(form.getFieldValue("email"))
      .then(res => handleModal(res))
      .finally(() => {
        setActionLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleReinviteUser = useCallback(() => {
    setActionLoading(true);
    reinviteUser(form.getFieldValue("email"))
      .then(res => {
        if (res.data === "User Reactivated Successfully") {
          Modal.success({
            title: "邀请邮件发送成功",
            content: "您的激活邮件已再次发送，等待激活。",
            afterClose: () => {
              setIsModalVisible(false);
              setIsEdit(false);
              props.doUpdateList();
              props.doCloseModal();
            }
          });
        }
      })
      .finally(() => setActionLoading(false));
  }, [form, props]);

  const handleResetPassword = useCallback(() => {
    setActionLoading(true);
    resetPassword(form.getFieldValue("email"))
      .then(res => handleModal(res))
      .finally(() => setActionLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleFirmDetail = useCallback(() => {
    if (form.getFieldValue("companyId")) {
      const request = {
        accountNumber: form.getFieldValue("companyId")
      };
      setCompanyInfoLoading(true);
      getValidFirmCode(request)
        .then(res => {
          if (res.data.customerInfoEntitlement.accountLongName) {
            setShowFirm(true);
            setIsFirmId(true);
            setFirmDetails(res.data.customerInfoEntitlement);
          } else {
            setShowFirm(true);
            setIsFirmId(false);
            form.setFieldsValue({ companyId: null });
            setFirmDetails({
              firmCode: "",
              customerType: "",
              cargillOrganization1Code: "",
              cargillOrganization1Description: "",
              cargillOrganization2Code: "",
              cargillOrganization2Description: "",
              cargillOrganization4Code: "",
              cargillOrganization4Description: "",
              customerTypeDescription: null,
              accountNumber: "",
              accountLongName: "",
              parentAccountNumber: null,
              parentAccountLongName: null,
              customerRelationship: ""
            });
          }
        })
        .finally(() => setCompanyInfoLoading(false));
    }
  }, [form]);

  const returnStatus = (status: any) => {
    if (status === "ACTIVE" || status === "RECOVERY") {
      return "已激活";
    } else if (status === "PROVISIONED") {
      return "待激活";
    } else {
      return status;
    }
  };

  const returnArr = (arr: any) => {
    let arrnew: any[] = [];
    if (arr && arr.length > 1) {
      const companyTypeList = arr.map((item: any) => item.otherControls.companyAccountType);
      const keyIndex = companyTypeList.indexOf("GROUP");
      const keyItem = arr.splice(keyIndex, 1);
      arr.unshift(keyItem[0]);
    }
    arr.forEach((e: any) => {
      arrnew.push(e.controlIdentifier);
    });
    return arrnew.join(",");
  };
  const rules = [
    { required: true },
    { message: "请输入中文或英文字符", validateTrigger: "onblur" },
    () => ({
      validator(_: any, values: any) {
        const regStr = /^[a-zA-Z\u4e00-\u9fa5]+$/;
        if (!regStr.test(values)) {
          return Promise.reject("请输入中文或英文字符");
        }
        return Promise.resolve();
      }
    })
  ];

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    setIsEdit(false);
    setFirmDetails({
      firmCode: "",
      customerType: "",
      cargillOrganization1Code: "",
      cargillOrganization1Description: "",
      cargillOrganization2Code: "",
      cargillOrganization2Description: "",
      cargillOrganization4Code: "",
      cargillOrganization4Description: "",
      customerTypeDescription: null,
      accountNumber: "",
      accountLongName: "",
      parentAccountNumber: null,
      parentAccountLongName: null,
      customerRelationship: ""
    });
    if (removed) {
      props.doUpdateList();
      setRemoved(false);
    }
    props.doCloseModal();
  }, [props, removed]);

  const getAppConfig = (key = "") => getObjProp(key, window.__APP_CONFIG__);

  // 执行保存修改用户操作
  const onFinish = useCallback(
    (values: any) => {
      const handleEntitlements: any = (values: any) => {
        const requestorApp = getAppConfig("requestorApp");
        const requestorType = getAppConfig("requestorType");
        const controlType = getAppConfig("controlType");
        // 将作为参数的信息添加用户角色字段
        if (entitlements && entitlements.length > 0) {
          return entitlements.map((item: any) => {
            const thisItem = item;
            thisItem.requestorGroup = values.requestorGroup;
            return thisItem;
          });
        }
        return [
          {
            requestorApp,
            requestorType,
            requestorGroup: values.userRole,
            controlType,
            controlIdentifier: values.companyId,
            otherControls: {
              organizationName: firmDetails.accountLongName,
              companyAccountType: firmDetails.customerType,
              companyType: "信用用户"
            }
          }
        ];
      };

      const entitlementsData = handleEntitlements(values);
      const judgeGROUP = entitlementsData.map((item: any) => item.otherControls.companyAccountType);
      if (entitlementsData.length > 1 && judgeGROUP.indexOf("GROUP") === -1) {
        message.error("未添加集团账户代码");
        return;
      }

      const userObj = {
        customerCode: values.companyId,
        id: props.dataSource.id,
        profile: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          locale: getAppConfig("userLocale") || "zh_CN",
          login: values.email,
          primaryPhone: values.primaryPhone,
          businessLine: values.businessLine,
          plant: values.plant
        },
        groups: [values.requestorGroup],
        entitlements: entitlementsData,
        isActivate: "false"
      };
      setActionLoading(true);
      saveUser(userObj)
        .then(res => {
          if (res.data.entitlements) {
            Modal.success({ content: "保存成功" });
            if (values.feedMillUser === 1) {
              const emailRequest = {
                feedCode: values.destination,
                oktaId: values.email,
                status: "create"
              };
              saveEmailId(emailRequest, {});
            }
            props.doUpdateList();
            setIsModalVisible(false);
            setIsEdit(false);
            props.doCloseModal();
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          message.error("保存失败，请稍后重试。");
        })
        .finally(() => setActionLoading(false));
    },
    [entitlements, firmDetails.accountLongName, firmDetails.customerType, props]
  );

  const handleFirmIdChange = useCallback(() => {
    if (!form.getFieldValue("companyId")) {
      setShowFirm(false);
    }
  }, [form]);

  const getCompanyInfoTipsDom = () => {
    if (showFirm) {
      return isFirmId ? (
        <div style={{ fontWeight: 500, fontSize: 12, color: "#191919", marginTop: -25 }}>
          {firmDetails.accountLongName}
        </div>
      ) : (
        <div style={{ fontWeight: 600, fontSize: 12, color: "#d80842", marginTop: -25 }}>
          <ExclamationCircleFilled style={{ color: "#cd0d15", marginRight: 8 }} />
          请输入有效的公司代码！
        </div>
      );
    }
    return null;
  };

  return (
    <div className="contract-confirmation-table">
      <Modal
        className="add-modal"
        title={isEdit ? "编辑外部用户" : "外部用户详情"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1240}
        forceRender
        maskClosable={false}
      >
        <span style={{ fontWeight: 600, fontSize: 16, color: "#191919", letterSpacing: 1 }}>
          用户编号：{props.dataSource.id}
        </span>
        <span style={{ fontWeight: 500, fontSize: 14, color: "#666666", letterSpacing: 1, paddingLeft: 10 }}>
          【{props.dataSource.isDeactivated ? "已失效" : returnStatus(props.dataSource.status)}】
        </span>
        <div style={{ fontWeight: 500, fontSize: 14, color: "#666666", letterSpacing: 1, padding: "8px 0" }}>
          账号创建日期：{moment(props.dataSource.created).format("YYYY-MM-DD")}
        </div>
        <Spin spinning={actionLoading}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              feedMillUser: 2,
              plant: "",
              businessLine: ""
            }}
          >
            <Row gutter={[16, 16]}>
              <Col md={6} xs={12}>
                <Form.Item label="姓氏" name="lastName" rules={rules}>
                  <Input disabled={!isEdit} placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col md={6} xs={12}>
                <Form.Item label="名字" name="firstName" rules={rules}>
                  <Input disabled={!isEdit} placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col md={6} xs={12}>
                <Form.Item
                  label="用户联系方式"
                  name="primaryPhone"
                  rules={[
                    { required: true, message: "联系方式应为11位数字" },
                    { len: 11, message: "联系方式应为11位数字", validateTrigger: "onblur" },
                    () => ({
                      validator(_, value) {
                        const reg = /^[1-9]\d{10}$/;
                        if (!reg.test(value)) {
                          value = value.replace(/\D/g, "");
                          if (value.length > 11) {
                            value = value.substring(0, 11);
                          }
                          form.setFieldsValue({ primaryPhone: value });
                          return Promise.reject("联系方式应为11位数字");
                        }
                        return Promise.resolve();
                      }
                    })
                  ]}
                >
                  <Input disabled={!isEdit} placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col md={6} xs={12}>
                <Form.Item label="邮箱" name="email" rules={[{ required: true }]}>
                  <Input disabled placeholder="请输入" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={6} xs={12} className="black-label">
                <Form.Item label="用户类型" name="requestorGroup" rules={[{ required: true }]}>
                  <Select disabled={!isEdit} placeholder="请选择">
                    <Option value="DXP_DSC_Role_CN_Manager">采购经理</Option>
                    <Option value="DXP_DSC_Role_CN_Staff">采购内勤</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} xs={12} className="black-label">
                <Form.Item label="是否为饲料厂用户" name="feedMillUser">
                  <Select disabled placeholder="请选择">
                    <Option value={1}>是</Option>
                    <Option value={2}>否</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} xs={12}>
                <Form.Item label="公司代码" name="companyId" rules={[{ required: true }]}>
                  <Input
                    disabled={
                      !isEdit || props?.dataSource?.entitlements?.[0]?.otherControls?.companyAccountType !== "GROUP"
                    }
                    placeholder="请输入"
                    onBlur={handleFirmDetail}
                    onChange={handleFirmIdChange}
                  />
                </Form.Item>
                <Spin spinning={companyInfoLoading}> {getCompanyInfoTipsDom()} </Spin>
              </Col>
              {form.getFieldValue("feedMillUser") === 1 ? (
                <Col md={6} xs={12}>
                  <Form.Item label="目的地" name="destination" rules={[{ required: true }]}>
                    <Select disabled>
                      {feedOptions.map(item => (
                        <Option value={item.feedCode} key={item.feedCode}>
                          {item.feedName}-{item.feedCode}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : null}
              <Col md={6} xs={12}>
                <Form.Item label="卖方" name="plant" rules={[{ required: true }]}>
                  <Select disabled={!isEdit} options={allSupplier()} />
                </Form.Item>
              </Col>
              <Col md={6} xs={12}>
                <Form.Item label="业务线" name="businessLine" rules={[{ required: true }]}>
                  <Select disabled={!isEdit} options={allBusinessLine()} />
                </Form.Item>
              </Col>
            </Row>
            {isFirmId && <SlotBookingType recordData={props.dataSource} />}
            {isEdit ? (
              <div className="align-right">
                <Form.Item>
                  <span className="edit-user-button">
                    <Button style={{ marginRight: 10 }} onClick={handleDeactivateUser}>
                      失效该用户
                    </Button>
                    <Button
                      data-testid="save-edit-user"
                      type="primary"
                      style={{ marginRight: 10, width: "fit-content" }}
                      htmlType="submit"
                    >
                      保存
                    </Button>
                  </span>
                  {props.dataSource.status === "PROVISIONED" ? (
                    <Button type="primary" onClick={handleReinviteUser}>
                      发起邀请流程
                    </Button>
                  ) : (
                    <Button type="primary" onClick={handleResetPassword}>
                      发起重置密码
                    </Button>
                  )}
                </Form.Item>
              </div>
            ) : null}
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

export default EditUser;
