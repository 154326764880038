import { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { Card, message } from "antd";
import TableList from "./components/tableList";
import PricePintInput from "./components/pricePointInput";
import RefuseModal from "./components/refuseModal";
import { getPointPriceConfirmationList } from "@/api/directSys";
import SearchFilters from "../searchFilters";

const PricePoint = (props: any) => {
  const childRef = useRef<any>();
  const { switcher } = props;
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestListValue, setRequestListValue] = useState({
    limit: 5,
    offset: 0,
    pointStateList: "02",
    divisionCode: switcher.divisionCode,
    orgCode: switcher.orgCode,
    basisContractNo: "",
    appliedNo: ""
  });

  const onPageChange = (page: number) => {
    setCurrent(page);
    const pager = JSON.parse(JSON.stringify(requestListValue));
    pager.offset = (page - 1) * 5;
    setRequestListValue(pager);
    resetAllFields();
  };
  const paginationOpt: any = {
    showQuickJumper: true,
    total: total,
    pageSize: 5,
    showSizeChanger: false,
    onChange: onPageChange,
    current: current,
    size: "default"
  };

  const resetAllFields = () => {
    childRef.current?.resetAllFields();
  };

  const onHandleSearch = useCallback(
    (values: any) => {
      const temp = JSON.parse(JSON.stringify(requestListValue));
      temp.offset = 0;
      temp.limit = 5;
      temp.divisionCode = switcher.divisionCode;
      temp.orgCode = values.seller;
      temp.basisContractNo = values.basisContractNo;
      temp.appliedNo = values.appliedNo;
      setRequestListValue(temp);
      setCurrent(1);
      resetAllFields();
    },
    [requestListValue, switcher.divisionCode]
  );

  const getListData = useCallback(() => {
    const values = requestListValue;
    setLoading(true);
    getPointPriceConfirmationList(values)
      .then(res => {
        if (res?.data?.metadata) {
          const tableData = res.data.pointPriceList || [];
          const total = res.data.metadata.totalCount ?? 0;
          setData(tableData);
          setTotal(total);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        setData([]);
        setTotal(0);
      })
      .finally(() => {
        setLoading(false);
        setHasSelected(false);
      });
  }, [requestListValue]);

  useEffect(() => {
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestListValue, refresh]);

  return (
    <>
      <SearchFilters doSearch={onHandleSearch} />
      <Card
        style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
        bodyStyle={{ paddingTop: 0 }}
        headStyle={{
          backgroundColor: "white",
          fontWeight: 100,
          borderBottom: "none"
        }}
        title={
          <div>
            <div className="report-header">
              <span className="total">共 {total} 条</span>
              <RefuseModal hasSelected={hasSelected} selectedRow={selectedRow[0] || []} getListData={getListData} />
            </div>
          </div>
        }
      >
        <TableList
          tableData={data}
          paginationOpt={paginationOpt}
          setSelectedRow={setSelectedRow}
          setHasSelected={setHasSelected}
          loading={loading}
        />
      </Card>
      <Card
        style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
        bodyStyle={{ paddingTop: 0 }}
        title="点价信息录入"
        headStyle={{
          backgroundColor: "white",
          fontWeight: 100,
          borderBottom: "none"
        }}
      >
        <PricePintInput selectedRow={selectedRow[0] || []} setRefresh={setRefresh} refresh={refresh} cRef={childRef} />
      </Card>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  userDetails: state.userDetails,
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(PricePoint);
