// slot-booking 查询条件
import { useState, forwardRef, useCallback, useImperativeHandle } from "react";
import { Form, Select, message, Spin, Row, Col, Card, Button, DatePicker } from "antd";
import "./index.scss";

import { allSupplier } from "@/utils/constant";
import { getProductQueryApi } from "@/api/slotbooking";

import { SearchOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

// 定义函数组件的props相关参数
type myProps = {
  get_message_filters?: () => void;
};

const MessageSearchFilters = forwardRef((props: myProps, ref: any) => {
  const [form] = Form.useForm(); // form表单实例
  const [platformDataOptions, setPlatformDataOptions] = useState([]); // platform可选列表
  const [isActionLoading, setIsActionLoading] = useState<boolean>(false);
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [siteCode, setSiteCode] = useState("");

  useImperativeHandle(ref, () => ({
    getFormFields
  }));

  const getFormFields = useCallback(() => {
    return { ...form.getFieldsValue(true) };
  }, [form]);

  // 获取平台可选数据
  const getPlatformsDataOptions = (siteNameVal: string) => {
    const getParamsObj = {
      vague: "",
      orgCode: siteNameVal,
      queryType: "platforms"
    };
    setIsActionLoading(true);
    getProductQueryApi(getParamsObj)
      .then((responseJSON: any) => {
        if (responseJSON?.data?.platforms) {
          const platformsData = responseJSON.data.platforms;
          const NewData = platformsData.map((item: any) => ({
            label: item.name,
            value: item.name,
            loading_rule: item.loadingRule
          }));
          setPlatformDataOptions(NewData);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
      })
      .finally(() => {
        setIsActionLoading(false);
      });
  };

  //处理卖方工厂 siteCode 的切换,确定 siteName的值
  const handleSiteNameChange = useCallback(
    (value: any) => {
      form.setFieldsValue({
        siteCode: value,
        platform: null,
        categoryName: null,
        specGroup: null
      });
      // 如果siteName有值则重新获取对应的platform的数据
      if (value) {
        getPlatformsDataOptions(value);
      }
      setSiteCode(value);
    },
    [form]
  );

  //处理平台 platform 的切换: 确定platform 和 loadingRule的值
  const handlePlatformChange = useCallback(
    (value: any) => {
      // 获取相关字段的可选数据
      const getSuggestions = (inputValue: any, id: string) => {
        setIsActionLoading(true);
        if (id === "categoryName") {
          const paramsObj = {
            vague: inputValue,
            orgCode: form.getFieldValue("siteCode"),
            platform: form.getFieldValue("platform"),
            queryType: "categorys"
          };
          getProductQueryApi(paramsObj)
            .then((responseJSON: any) => {
              if (responseJSON.code && responseJSON.code === "99999") {
                // 99999 提示后端的错误信息
                message.error(responseJSON.errorMsg);
                setCategoryOptions([]);
              } else if (responseJSON?.data) {
                const categorysData = responseJSON.data.categorys || [];
                const newData = categorysData.map((item: any) => {
                  return { label: item.name, value: item.name, efficiency: item.efficiency };
                });
                setCategoryOptions(newData);
              }
            })
            .catch((exception: any) => {
              console.log(`Failure: ${exception}`);
              setCategoryOptions([]);
            })
            .finally(() => {
              setIsActionLoading(false);
            });
        }
      };

      if (value) {
        form.setFieldsValue({
          platform: value,
          categoryName: null,
          specGroup: null
        });
        getSuggestions(value, "categoryName");
      } else {
        form.setFieldsValue({
          platform: null,
          categoryName: null,
          specGroup: null
        });
      }
    },
    [form]
  );

  // 处理 categoryName 的切换 确定categoryName的值
  const onHandleChange = (field: string) => (selectionObj: any, currentOption: any) => {
    if (field === "categoryName") {
      if (selectionObj) {
        form.setFieldsValue({
          categoryName: selectionObj
        });
      }
    }
  };

  const handleMySubmit = useCallback(
    (formValues?: any) => {
      const notify_current_change_data = () => {
        if (props.get_message_filters) {
          props.get_message_filters();
        }
      };

      notify_current_change_data();
    },
    [props]
  );

  return (
    <div className="message-search-filters">
      <Spin spinning={isActionLoading}>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb" }}
          bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
          title="查询条件"
          headStyle={{
            backgroundColor: "white",
            borderBottom: "none"
          }}
        >
          <Form
            form={form}
            name="reservation_filters_form"
            layout="vertical"
            labelAlign="left"
            onFinish={handleMySubmit}
            initialValues={{
              siteCode: "",
              siteName: null, // 工厂编码
              platform: null, // 发货平台
              categoryName: null // 产品类型
            }}
          >
            <Row gutter={80} justify="start">
              <Col md={6} xs={24}>
                <Form.Item label="工厂" name="siteCode">
                  <Select
                    data-testid="test-siteName-select"
                    placeholder="请选择工厂"
                    options={allSupplier(["All"])}
                    onChange={handleSiteNameChange}
                  />
                </Form.Item>
              </Col>

              {siteCode && (
                <Col md={6} xs={24}>
                  <Form.Item label="发货平台" name="platform">
                    <Select
                      data-testid="test-platform-select"
                      placeholder="请选择发货平台"
                      options={platformDataOptions}
                      onChange={handlePlatformChange}
                      disabled={!form.getFieldValue("siteCode")}
                    />
                  </Form.Item>
                </Col>
              )}

              {siteCode && (
                <Col md={6} xs={24}>
                  <Form.Item shouldUpdate label="选择产品类型" name="categoryName">
                    <Select
                      data-testid="test-category-select"
                      placeholder="请选择产品类型"
                      options={categoryOptions}
                      onChange={onHandleChange("categoryName")}
                      disabled={!form.getFieldValue("siteCode") || !form.getFieldValue("platform")}
                    ></Select>
                  </Form.Item>
                </Col>
              )}

              <Col md={6} xs={24}>
                <Form.Item name="sendDateRange" label="提交的起始日期和截止日期">
                  <RangePicker data-testid="test-booking-dateRange" placeholder={["起始日期", "截止日期"]} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Form.Item shouldUpdate className="form-item">
                {() => (
                  <Button
                    data-testid="test-message-search-basic-filters"
                    type="primary"
                    htmlType="submit"
                    icon={<SearchOutlined />}
                  >
                    查询
                  </Button>
                )}
              </Form.Item>
            </Row>
          </Form>
        </Card>
      </Spin>
    </div>
  );
});

export default MessageSearchFilters;
