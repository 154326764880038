import { forceLock } from "@/api/deliveryLetterList";
import { Button, message, Modal } from "antd";
import { useCallback, useState } from "react";

interface Props {
  data: any;
  getTable?: any;
  text?: boolean;
}

const ForceLock: React.FC<Props> = (props: any) => {
  const { data, getTable, text } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const handleForceLock = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const handleOk = useCallback(() => {
    setLoading(true);
    forceLock({
      doId: data[0]?.doId
    })
    .then((res: any) => {
      if (res.code && res.code === "99999") {
        message.error(res.errorMsg)
      } else {
        getTable();
      }
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      setVisible(false);
      setLoading(false);
    })
  }, [data, getTable]);

  return (
    <>
      <Button type={text ? "text" : "default"} onClick={handleForceLock} disabled={data.length === 0}>
        强制锁定余量
      </Button>
      <Modal
        title="提示"
        visible={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        footer={
          <>
            <Button onClick={handleCancel}>取消</Button>
            <Button data-testid="submit-btn" loading={loading} type="primary" onClick={handleOk}>
              确定
            </Button>
          </>
        }
        centered
        maskClosable={false}
      >
        是否确认强制余量锁定？
      </Modal>
    </>
  );
};

export default ForceLock;