import { useCallback, useState } from "react";
import { Modal, Button, message } from "antd";
import { approveSettlement } from "@/api/directSys";

const ApproveButton = (props: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleApproveSettlement = useCallback(() => {
    setLoading(true);
    const requestArr: any = [];
    props.selectedRow.forEach((e: any) => {
      requestArr.push({ id: e.id });
    });
    approveSettlement(requestArr, {})
      .then((res: any) => {
        if (res?.result) {
          props.getListData();
          setIsModalVisible(true);
        } else {
          message.error(res?.data?.description);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props]);

  return (
    <>
      <Modal
        title="提交成功"
        maskClosable={false}
        visible={isModalVisible}
        footer={
          <Button data-testid="test-confirm-btn" type="primary" onClick={handleClose}>
            确定
          </Button>
        }
        onCancel={handleClose}
      >
        提交成功，等待系统处理。
      </Modal>
      <Button
        loading={loading}
        type={props.isText ? "text" : "primary"}
        onClick={handleApproveSettlement}
        disabled={!props.isEnabledAction}
      >
        审批通过
      </Button>
    </>
  );
};
export default ApproveButton;
