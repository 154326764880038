import { useState, useCallback } from "react";
import { Modal, Button, Input, Form, message, Row, Col } from "antd";
import { EditFilled } from "@ant-design/icons";
import { updateOriginContractNo } from "@/api/directSys";
const { TextArea } = Input;

const UpdateModal = (props: any) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageFlag, setMessageFlag] = useState(false);

  const handleOk = useCallback(
    (values: any) => {
      setLoading(true);
      console.log(values.updatedOriginContractNo);
      const request = {
        updatedOriginContractNo: values.updatedOriginContractNo,
        crglContractNo: props.crglContractNo
      };
      updateOriginContractNo(request, {})
        .then(res => {
          if (res?.data?.result) {
            props.getListData();
          } else {
            message.error(res?.data?.description);
            setMessageFlag(!messageFlag);
          }
        })
        .finally(() => {
          setIsModalVisible(false);
          setLoading(false);
          form.setFieldsValue({
            updatedOriginContractNo: ""
          });
        });
    },
    [form, messageFlag, props]
  );

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    form.setFieldsValue({
      updatedOriginContractNo: ""
    });
  }, [form]);

  const returnContractNo = (s: any) => {
    if (s !== "" && s !== null) {
      let arr = s.split(",");
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].split("_")[0] + "_" + arr[i].split("_")[1]?.replace(/\b(0+)/gi, "");
      }
      return arr.toString();
    } else {
      return "";
    }
  };

  const handleVisible = useCallback(() => {
    setIsModalVisible(!isModalVisible);
    if (props.basisContractNo) {
      let arr = props.basisContractNo.split(",");
      form.setFieldsValue({
        updatedOriginContractNo: arr.toString()
      });
    }
  }, [form, isModalVisible, props.basisContractNo]);

  return (
    <div className="update-modal">
      {props.basisContractNo === null ? (
        <Button type="link" onClick={handleVisible}>
          -<EditFilled />
        </Button>
      ) : (
        <Button type="link" onClick={handleVisible}>
          {returnContractNo(props.basisContractNo)}
          <EditFilled />
        </Button>
      )}
      <Modal title="编辑原合同号" maskClosable={false} visible={isModalVisible} footer={null} onCancel={handleCancel}>
        <Form form={form} onFinish={handleOk}>
          <Form.Item name="updatedOriginContractNo">
            <TextArea data-testid="test-textarea" />
          </Form.Item>
          <Row gutter={[16, 16]}>
            <Col style={{ marginLeft: "auto", marginRight: 0 }}>
              <Form.Item>
                <div style={{ marginTop: 0 }}>
                  <Button
                    style={{ marginRight: 10 }}
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    data-testid="test-submit"
                  >
                    保存
                  </Button>
                  <Button type="primary" style={{ backgroundColor: "#5c5c5c" }} onClick={handleCancel}>
                    取消
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default UpdateModal;
