
import { getFirmDetailListApi, getNetAccountBalance } from "@/api/availableBalance";
import RemoteSelect from "@/pages/common/remoteSelect";
import { Button, Col, Form, Input, Row, Select, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { formatNumberToFixedThousands, formatReverseNumberToFixedThousands, getCode } from "@/utils/common";
import moment from "moment";
import { getFirmDetailsList } from "@/api/constant";
import "./index.scss";

interface Props {
  switcher: any,
  userDetails: any,
  permissions: any,
  defaultSearchValue?: any
  goToTab: Function
}
const CreditBalanceOverview = (props: Props) => {
  const { switcher, userDetails, permissions, goToTab } = props;
  const [loading, setLoading] = useState(false);
  const [isSelectFetching, setIsSelectFetching] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [isShowResult, setIsShowResult] = useState(false);
  const [creditBalanceData, setCreditBalanceData] = useState({} as any);
  const [searchValue, setSearchValue] = useState({} as any);

  const [form] = Form.useForm();

  // 内部用户--获取买方选项(防抖输入查询)
  const fetchCustomerOptions_Internal = useCallback(
    (value: string | undefined, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      getFirmDetailListApi({
        customerName: value || ""
      })
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            callback([]);
            message.error(res.errorMsg);
          } else if (res.data?.customerList?.length > 0 && currentValue === value) {
            let data: any[] = res.data?.customerList?.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName + "(" + val.customerCode + ")",
                key: val.customerCode,
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    []
  );

  // 外部Group用户筛选公司
  const onFilterOption = useCallback((inputValue: any, option: any) => {
    return ((option?.label as unknown) as string).toLowerCase().includes(inputValue?.toLowerCase());
  }, []);

  // 外部 group 用户查询公司（集团公司）
  const fetchCustomerOptions_External = useCallback((controlIdentifier: any) => {
    getFirmDetailsList({ accountNumber: controlIdentifier })
      .then((res: any) => {
        if (res.data && res.data.customerInfoEntitlement?.length > 0) {
          let options = res.data.customerInfoEntitlement.map((val: any) => {
            return {
              label: `${val.accountLongName}(${val.accountNumber})`,
              value: `${val.accountLongName}(${val.accountNumber})`,
              key: val.accountNumber,
              name: val.accountLongName
            };
          });
          const currentCustomerOption = options.find((val: any) => val.key === switcher.customerCode);
          setCustomerOptions(options);
          form.setFieldsValue({
            customerCode: currentCustomerOption?.value
          });
        } else {
          setCustomerOptions([]);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      });
  }, [form, switcher]);

  // 查询开单额度
  const onFinished = useCallback((values: any) => {
    setLoading(true);
    setSearchValue(values);
    const valuesTemp = { ...values };
    valuesTemp.customerCode = getCode(valuesTemp.customerCode);
    getNetAccountBalance(valuesTemp)
      .then((res: any) => {
        if (res.data && Object.keys(res.data).length) {
          if (res.data.statusCode === 200) {
            // to do 处理成功响应
            setCreditBalanceData({
              accountBalance: res.data.accountBalance, // 现金余额
              creditLimit: res.data.creditLimit, // 信用额度
              netCreditBalance: res.data.netCreditBalance, // 可开单额度
              timeStamp: res.data.timeStamp
            });
            setIsShowResult(true);
          } else {
            message.error(res.data.statusMessage);
            // to do 处理失败响应
            setIsShowResult(false);
          }
        } else {
          message.error("No Results Found");
          setIsShowResult(false);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        setIsShowResult(false);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  const goToCashBalanceTab = useCallback(() => {
    goToTab("2", searchValue);
  }, [goToTab, searchValue]);

  useEffect(() => {
    if (switcher) {
      if (permissions.CNInternalUsers) {
        // 内部用户
        form.setFieldsValue({ customerCode: null });
      } else if (permissions.CNExternalUsers) {
        // 判断是否为集团用户
        const userDataCheck = userDetails && Object.keys(userDetails).length && userDetails.entitlements;
        if (userDataCheck) {
          const { entitlements } = userDetails;
          const {
            otherControls: { companyAccountType },
            controlIdentifier
          } = entitlements[0];
          if (companyAccountType === "GROUP") {
            fetchCustomerOptions_External(controlIdentifier);
          } else {
            form.setFieldsValue({
              customerCode: switcher.customerName + "(" + switcher.customerCode + ")"
            });
          }
        }
      }
    }
  }, [form, switcher, permissions, userDetails, fetchCustomerOptions_External]);


  return (
    <div className="credit-balance-overview-wrapper">
      <div className="search-filter">
        {permissions.CNInternalUsers && <div className="title">查询开单额度</div>}
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinished}
        >
          <Row gutter={{ md: 16, xs: 8 }}>
            {permissions.CNInternalUsers && (
              <Col md={6} xs={24}>
                <Form.Item
                  label="公司名称"
                  name="customerCode"
                  rules={[{ required: true, message: "请输入公司名称" }]}
                >
                  <RemoteSelect
                    dataTestId="test-internalUser-customerCode-select-01"
                    placeholder="请输入名称查询"
                    fetchOptions={fetchCustomerOptions_Internal}
                    loading={isSelectFetching}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}
            {permissions.CNExternalUsers && userDetails?.isGroup && (
              <Col md={6} xs={24}>
                <Form.Item shouldUpdate label="公司名称" name="customerCode">
                  <Select
                    showSearch
                    placeholder="请选择公司名称"
                    options={customerOptions}
                    optionFilterProp="label"
                    filterOption={onFilterOption}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}
            {permissions.CNExternalUsers && !userDetails?.isGroup && (
              <Col md={6} xs={24}>
                <Form.Item label="公司名称" name="customerCode">
                  <Input disabled />
                </Form.Item>
              </Col>

            )}
            <Col md={4} xs={24}>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    data-testid="test-submit-btn-01"
                    type="primary"
                    htmlType="submit"
                    className="submit-btn"
                    loading={loading}
                    disabled={!form.getFieldValue("customerCode")}
                  >
                    查询开单额度
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="content">
        {isShowResult ? (<div className="search-result">
          <div className="money-info-container">
            <Row>
              <Col>
                <div className="result-col-item-container">
                  <div className="result-col-item">
                    <span className="label">可开单额度（元）：</span>
                    <span className="value">{creditBalanceData.netCreditBalance == null ? "-" : formatNumberToFixedThousands(creditBalanceData.netCreditBalance, 2)}</span>
                  </div>
                  <div className="tips">
                    <ExclamationCircleOutlined />
                    <span className="label">可开单额度 = 信用额度 + 现金余额（如果子公司共享母公司额度，则公式不成立，请以实际显示的数据为准）</span>
                  </div>
                </div>

                <div className="result-col-item-container">
                  <div className="result-col-item">
                    <span className="label">信用额度（元）：</span>
                    <span className="value">{creditBalanceData.creditLimit == null ? "-" : formatNumberToFixedThousands(creditBalanceData.creditLimit, 2)}</span>
                  </div>
                </div>

                <div className="result-col-item-container">
                  <div className="result-col-item">
                    <span className="label">现金余额（元）：</span>
                    <span className="value">{creditBalanceData.accountBalance == null ? "-" : formatReverseNumberToFixedThousands(creditBalanceData.accountBalance, 2)}</span>
                  </div>
                  <Button data-testid="test-view-cash-balance-btn" type="link" onClick={goToCashBalanceTab}>查看现金余额</Button>
                  <div className="tips">
                    <ExclamationCircleOutlined />
                    <span className="label">现金金额 = 账面余额 - 保证金金额 - 提单占用总额</span>
                  </div>
                </div>
                {creditBalanceData.timeStamp &&
                  <div className="tips">
                    <ExclamationCircleOutlined />
                    <span className="label">以上数据系截止至【{moment(creditBalanceData.timeStamp).format("YYYY年MM月DD日 HH:mm")}】贵司在嘉吉全球的参考金额，可用金额解释应洽嘉吉确认，实际交易金额以合同为准。</span>
                  </div>}
              </Col>
            </Row>
          </div>
        </div>) : (
          <div className="tips">
            <ExclamationCircleOutlined />
            <span className="label">请输入以上信息后查看。</span>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  switcher: state.switcher,
  userDetails: state.userDetails,
  permissions: state.userPermissions
});

export default connect(mapStateToProps, null)(CreditBalanceOverview);