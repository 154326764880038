import { useState, useEffect, useCallback } from "react";
import { Table, Tooltip } from "antd";
import DownloadPdf from "../downloadPdf";
import { useHistory } from "react-router-dom";
import TableDelete from "../TableDelete";
const TableList = (props: any) => {
  const history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const cellStyle = () => {
    return {
      style: {
        maxWidth: 150,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        cursor: "pointer"
      }
    };
  };
  const returnIsEnable = (rows: any) => {
    if (
      rows.find((item: any) => item.settState === "匹配成功") ||
      rows.find((item: any) => item.settState === "已开票") ||
      rows.find((item: any) => item.settState === "已拒绝")
    ) {
      return false;
    } else {
      return true;
    }
  };
  const onSelectChange = (selectedRowKeys: any, rows: any) => {
    setSelectedRowKeys(selectedRowKeys);
    props.setIsEnableAction(rows.length > 0 && returnIsEnable(rows));
    props.setSelectedRow(rows);
  };
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
    getCheckboxProps: (record: any) => {
      return {
        disabled: !(
          record.settState === "待匹配" ||
          record.settState === "待确认" ||
          record.settState === "匹配失败" ||
          record.settState === "已导出" ||
          record.settState === "待导出" ||
          (record.settState === "已开票" && record.systemSource !== "TW")
        )
      };
    }
  };
  const returnTime = (time: string) => {
    let s = time;
    s = s
      .replace(/T/g, " ")
      .replace(/-/g, "-")
      .replace(/\.\d+/, " ");
    return s;
  };
  const handleSettlementlistRedirect = (rowData: any) => {
    if (rowData) {
      history.push({
        pathname: "/main/direct-sys/settlementdetail",
        state: rowData.settNo
      });
    }
  };

  // 删除后的回调
  const onHandleDeleteSuccess = useCallback(() => {
    props.deleteSuccess();
  }, [props]);

  const columns: any = [
    {
      title: "结算单号",
      render: (record: any) => {
        const showText = record.settWeight ? `${record.settNo}_${record.settWeight.toFixed(4)}` : record.settNo;
        return record.settNo.slice(0, 3) === "INV" ? (
          showText
        ) : (
          <div
            className="link-field"
            onClick={() => handleSettlementlistRedirect(record)}
            onKeyDown={() => handleSettlementlistRedirect(record)}
            role="button"
            tabIndex={0}
            style={{ cursor: "pointer", color: "#007681" }}
          >
            {showText}
          </div>
        );
      }
    },
    {
      title: "客户公司",
      dataIndex: "buyer",
      key: "buyer"
    },
    {
      title: "卖方",
      dataIndex: "seller",
      key: "seller"
    },
    {
      title: "嘉吉合同号",
      dataIndex: "crglContractNo",
      key: "crglContractNo",
      render: (text: any) => (
        <>{text === null ? "-" : text.split("_")[0] + "_" + text.split("_")[1]?.replace(/\b(0+)/gi, "")}</>
      )
    },
    {
      title: "提单头编号",
      dataIndex: "salesOrderNumber",
      key: "salesOrderNumber",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "物料名称",
      dataIndex: "materialName",
      key: "materialName"
    },
    {
      title: "提货委托函重量",
      dataIndex: "doQuantity",
      key: "doQuantity",
      render: (text: any) => <>{!text ? "-" : text.toFixed(3)}</>
    },
    {
      title: "发货重量",
      dataIndex: "supplierWeighSum",
      key: "supplierWeighSum",
      render: (text: any) => <>{!text ? "-" : text.toFixed(3)}</>
    },
    {
      title: "运输类型",
      dataIndex: "transportType",
      key: "transportType",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "过磅依据",
      dataIndex: "weighBasis",
      key: "weighBasis",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "结算重量",
      dataIndex: "settWeight",
      key: "settWeight",
      render: (text: any) => <>{!text ? "-" : text.toFixed(3)}</>
    },
    {
      title: "结算含税金额（元）",
      dataIndex: "settAmount",
      key: "",
      render: (text: any) => <>{text === null ? "-" : text.toFixed(2)}</>
    },
    {
      title: "结算单来源",
      dataIndex: "systemSource",
      key: "systemSource"
    },
    {
      title: "嘉吉备注",
      onCell: cellStyle,
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.cargillRemark ? record.cargillRemark : null}>
          {record.cargillRemark ? record.cargillRemark : null}
        </Tooltip>
      )
    },
    {
      title: "客户备注",
      onCell: cellStyle,
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.thirdRemark ? record.thirdRemark : null}>
          {record.thirdRemark ? record.thirdRemark : null}
        </Tooltip>
      )
    },
    {
      title: "结算单接收时间",
      dataIndex: "settAcceptTime",
      key: "settAcceptTime",
      render: (text: any) => <>{!text ? "-" : returnTime(text)}</>
    },
    {
      title: "结算单状态",
      dataIndex: "settState",
      key: "settState"
    },
    {
      title: "发票日期",
      dataIndex: "invoiceTime",
      key: "invoiceTime",
      render: (text: any) => <>{!text ? "-" : returnTime(text)}</>
    },
    {
      title: "发票含税总金额",
      dataIndex: "invoiceTaxAmount",
      key: "invoiceTaxAmount",
      render: (text: any) => <>{!text ? "-" : text.toFixed(2)}</>
    },
    {
      title: "发票号",
      dataIndex: "invoiceNo",
      key: "invoiceNo"
    },
    {
      title: "发票代码",
      dataIndex: "invoiceCode",
      key: "invoiceCode"
    },
    {
      title: "嘉吉发票备注",
      onCell: cellStyle,
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.invoiceCargillRemark ? record.invoiceCargillRemark : null}>
          {record.invoiceCargillRemark ? record.invoiceCargillRemark : null}
        </Tooltip>
      )
    },
    {
      title: "客户发票备注",
      onCell: cellStyle,
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.invoiceThirdRemark ? record.invoiceThirdRemark : null}>
          {record.invoiceThirdRemark ? record.invoiceThirdRemark : null}
        </Tooltip>
      )
    },
    {
      title: "付款批次号",
      dataIndex: "payBatchNo",
      key: "payBatchNo"
    },
    {
      title: "付款日期",
      dataIndex: "payTime",
      key: "payTime",
      render: (text: any) => <>{!text ? "-" : returnTime(text)}</>
    },
    {
      title: "付款总金额",
      dataIndex: "payAmount",
      key: "payAmount"
    },
    {
      title: "差值",
      dataIndex: "amountDifference",
      key: "amountDifference"
    },
    {
      title: "付款备注",
      dataIndex: "payThirdRemark",
      key: "payThirdRemark"
    },
    {
      title: "下载发票",
      fixed: "right" as "right",
      render: (record: any) => (
        <DownloadPdf params={record.invoiceNo} filename="appendix.xls" pdfDownloadFlag={record.pdfDownloadFlag} />
      )
    },
    {
      title: "删除",
      align: "center" as "center",
      dataIndex: "delete",
      key: "delete",
      fixed: "right" as "right",
      render: (_: any, record: any) => <TableDelete data={record} cb_success={onHandleDeleteSuccess} />
    }
  ];

  useEffect(() => setSelectedRowKeys([]), [props.tableData]);

  return (
    <div className="contract-confirmation-table">
      <Table
        columns={columns}
        pagination={props.paginationOpt}
        rowSelection={rowSelection}
        scroll={{ x: "max-content" }}
        dataSource={props.tableData}
        rowKey="id"
        loading={props.loading}
        size="small"
      />
    </div>
  );
};

export default TableList;
