import { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Select, Button, Collapse, Divider, Input, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { allSupplier, kaClientOptions } from "@/utils/constant";
import { cutOutRightCodeStr } from "@/utils/common";
import { vagueListCustomer, getProductList } from "@/api/directSys";
import RemoteSelect from "@/pages/common/remoteSelect";

const { Option } = Select;
const { Panel } = Collapse;

const SearchFilter = (props: any) => {
  const { switcher, resetContent } = props;
  const [isClick, setIsClick] = useState(false);
  const [expandCollapseText, setExpandCollapseText] = useState("展开高级筛选");
  const [isSelectFetching, setIsSelectFetching] = useState(false);
  const [form] = Form.useForm();
  const [requestListValue] = useState({
    limit: 10,
    offset: 0,
    divisionCode: switcher.divisionCode,
    orgCode: switcher.orgCode,
    buyer: null,
    crglContractNo: null,
    materialName: null,
    settState: null,
    settNo: null,
    acceptStartTime: null,
    acceptEndTime: null,
    deliveryNo: null,
    kaClient: null
  });
  // 上一个resetContent的值
  const preRestContent = useRef(false);

  const handleReset = useCallback(() => {
    form.resetFields();
    form.setFieldsValue({
      seller: switcher.orgCode || "",
      businessLine: switcher.divisionCode || ""
    });
    props.setFields({
      limit: 10,
      offset: 0,
      divisionCode: switcher.divisionCode,
      orgCode: switcher.orgCode,
      buyer: null,
      crglContractNo: null,
      materialName: null,
      settState: null,
      settNo: null,
      acceptStartTime: null,
      acceptEndTime: null,
      deliveryNo: null,
      kaClient: null
    });
    props.setCurrent(1);
  }, [form, props, switcher.divisionCode, switcher.orgCode]);

  const handelExpandCollapse = useCallback(() => {
    setIsClick(!isClick);
    setExpandCollapseText(isClick ? "展开高级筛选" : "收起高级筛选");
  }, [isClick]);

  // 查询客户名称
  const getCostumerOptions = useCallback(
    (value: string, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      vagueListCustomer({
        customerName: value || "",
        orgCode: form.getFieldValue("seller") || "",
        divisionCode: switcher.divisionCode
      })
        .then((res: any) => {
          if (res.data?.customerList?.length > 0 && currentValue === value) {
            const data = res.data.customerList.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch((err: any) => {
          console.log(err);
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    [form, switcher.divisionCode]
  );

  // 查询产品名称
  const getProductOptions = useCallback(
    (value: string, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      getProductList({
        productName: value || "",
        salesOrgs: form.getFieldValue("seller") || ""
      })
        .then((res: any) => {
          if (res.data?.products?.length > 0 && currentValue === value) {
            const data = res.data.products.map((val: any) => {
              return {
                label:
                  val.productName +
                  getCtsCode(val.productName, val.ctsCode) +
                  "(" +
                  val.productCode?.replace(/\b(000000000+)/gi, "") +
                  ")",
                value:
                  val.productName +
                  getCtsCode(val.productName, val.ctsCode) +
                  "(" +
                  val.productCode?.replace(/\b(000000000+)/gi, "") +
                  ")"
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch((err: any) => {
          console.log(err);
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    [form]
  );

  const getCtsCode = (productName: string, ctsCode: string) => {
    let index = productName.indexOf("-");
    if (index !== -1 || ctsCode === null) {
      return "";
    } else {
      return "-" + ctsCode;
    }
  };
  const onFinish = useCallback(
    (values: any) => {
      const temp = JSON.parse(JSON.stringify(requestListValue));
      temp.offset = 0;
      temp.limit = 10;
      temp.divisionCode = switcher.divisionCode;
      temp.orgCode = values.seller;
      temp.crglContractNo = values.crglContractNo;
      temp.settState = values.settState;
      temp.settNo = values.settNo;
      temp.acceptStartTime = values.acceptStartTime ? values.acceptStartTime.format("YYYY-MM-DDT00:00:00") : null;
      temp.acceptEndTime = values.acceptEndTime ? values.acceptEndTime.format("YYYY-MM-DDT23:59:59") : null;
      temp.deliveryNo = values.deliveryNo;
      temp.productCode = values.materialName ? cutOutRightCodeStr(values.materialName) : null;
      temp.buyer = values.buyer || null; // 此功能特殊传中文名称
      temp.kaClient = values.kaClient || null;
      props.setFields(temp);
      props.setCurrent(1);
    },
    [props, requestListValue, switcher.divisionCode]
  );

  useEffect(() => {
    let value: any = requestListValue;
    if (resetContent !== preRestContent.current) {
      value = {
        limit: 10,
        offset: 0,
        divisionCode: switcher.divisionCode,
        orgCode: switcher.orgCode,
        buyer: null,
        crglContractNo: null,
        materialName: null,
        settState: null,
        settNo: null,
        acceptStartTime: null,
        acceptEndTime: null,
        deliveryNo: null,
        kaClient: null
      }
      form.resetFields();
      preRestContent.current = resetContent;
    }
    if (switcher) {
      const { orgCode } = switcher;
      value.orgCode = orgCode || "";
      form.setFieldsValue({
        seller: orgCode || ""
      });
      props.setFields(value);
    }
  }, [form, props, requestListValue, switcher, resetContent]);

  return (
    <div className="search-filter">
      <div className="base-filter">
        <Form form={form} name="contract-confirmation-form" layout="vertical" autoComplete="off" onFinish={onFinish}>
          <Row justify="space-between">
            <Col md={5} xs={24}>
              <Form.Item name="seller">
                <Select placeholder="请选择卖方" options={allSupplier()} />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="buyer">
                <RemoteSelect
                  dataTestId="buyer-input"
                  placeholder="请输入公司名称查询并选择"
                  fetchOptions={getCostumerOptions}
                  loading={isSelectFetching}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="crglContractNo">
                <Input style={{ width: "100%" }} placeholder="请输入嘉吉合同号" />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="settState">
                <Select placeholder="请选择结算单状态">
                  <Option value="">全部状态</Option>
                  <Option value="01">待确认</Option>
                  <Option value="02">待匹配</Option>
                  <Option value="03">待导出</Option>
                  <Option value="04">匹配失败</Option>
                  <Option value="05">已拒绝</Option>
                  <Option value="06">已开票</Option>
                  <Option value="07">已导出</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={3} xs={24}>
              <Button type="primary" htmlType="submit" disabled={isClick}>
                <SearchOutlined />
                查询
              </Button>
            </Col>
          </Row>

          <Collapse ghost expandIconPosition="right" onChange={handelExpandCollapse} collapsible="header">
            <Panel header={<span style={{ color: "#007ed9" }}>{expandCollapseText}</span>} key="1">
              <Divider dashed={true} style={{ width: "101%", margin: "-10px 0 13px -14px" }} />
              <div className="expand-collapse">
                <Row gutter={[16, 16]}>
                  <Col md={3} xs={24}>
                    <Form.Item label="结算单号" name="settNo">
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="物料名称" name="materialName">
                      <RemoteSelect
                        dataTestId="productName-input"
                        placeholder="请输入查询并选择"
                        fetchOptions={getProductOptions}
                        loading={isSelectFetching}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="结算单接收起始日期" name="acceptStartTime">
                      <DatePicker style={{ width: "100%" }} placeholder="请选择" />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="结算单接收截止日期" name="acceptEndTime">
                      <DatePicker style={{ width: "100%" }} placeholder="请选择" />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item
                      label="提单号"
                      name="deliveryNo"
                      rules={[
                        () => ({
                          validator(_, value) {
                            const reg = /^\d*$/;
                            if (value && !reg.test(value)) {
                              if (value.toString().length === 1) {
                                value = value?.replace(/[^1-9]/g, "");
                              } else {
                                value = value?.replace(/\D/g, "");
                              }
                              form.setFieldsValue({ deliveryNo: value });
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <Input data-testid="deliveryNo-input" placeholder="请输入提单号" />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="客户标识" name="kaClient">
                      <Select placeholder="请选择客户标识" options={kaClientOptions} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col style={{ marginLeft: "auto" }} md={5} xs={24}>
                    <Form.Item>
                      <div style={{ marginTop: 30 }} className="align-right advance-btn">
                        <Button
                          style={{ marginRight: 10, backgroundColor: "#5c5c5c", width: "fit-content" }}
                          type="primary"
                          data-testid="reset-btn"
                          onClick={handleReset}
                        >
                          清空
                        </Button>
                        <Button type="primary" data-testid="submit-btn" htmlType="submit">
                          立即查询
                        </Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  userDetails: state.userDetails,
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(SearchFilter);
