import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { Form, Select, Row, Col, Radio, Input, Button, Divider, Table, Spin, message, Modal, Tooltip } from "antd";
import moment from "moment";
import BuyerModal from "../buyerModal";
import "./index.scss";
import {
  getSmsTemplates,
  saveSmsNoticeInfo,
  sendSmsNotice,
  sendSmsInfo,
  deleteSmsNoticeInfo
} from "@/api/systemSetting";
import MessageFilters from "../components/messageFilters";
import { supplierList } from "@/utils/constant";

const MessageSetting = (props: any) => {
  const { switcher, userDetails } = props;
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState<any[]>([]);
  const [smsTemplates, setSmsTemplates] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [selected, setSelected] = useState(false);

  const [loading, setLoading] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [showBookingTime, setShowBookingTime] = useState(false);

  const messageFilters_Ref: any = useRef();
  const [messageFiltersData, setMessageFiltersData] = useState({
    siteCode: null, // 工厂编码
    siteName: null, // 工厂名称
    platform: null, // 发货平台
    categoryName: null, // 产品类型
    specGroup: null, // 规格组
    vehicleType: null //车型
  });
  const [dateStrArr, setDateStrArr] = useState<object[]>([]); // 可选日期--详细配置信息
  const [messageContentNode, setMessageContentNode] = useState<any>(""); // 短信内容节点
  const [messageTemplates, setMessageTemplates] = useState<any>(); //短信模板的options
  const [messageIsShow, setMessageIsShow] = useState<boolean>(false); //短信内容是否显示
  const [submitEnable, setSubmitEnable] = useState(false);
  // 短信内容输入
  const [messageInputValueArray, setMessageInputValueArray] = useState<string[]>([]);
  // 确认发送短信提示
  const [showConfirmSendSms, setShowConfirmSendSms] = useState<boolean>(false);
  // 当前发送短信的信息
  const [currentSmsInfo, setCurrentSmsInfo] = useState<any>({});

  // 预约状态
  const bookingStatus = [
    { label: "待预约", value: "1" },
    { label: "已预约", value: "2" },
    { label: "所有司机", value: "" }
  ];
  // 物流状态
  const logisticsStatus = [
    { label: "已分组未开卡", value: "1" },
    { label: "已开卡未称重", value: "2" },
    { label: "所有状态（不包含已离场）", value: "" }
  ];

  // 通过value展示label
  const showLabel = (value: any, array: any[]) => {
    const filterResults = array.filter(item => item.value === value);
    if (filterResults && filterResults.length > 0) {
      return filterResults[0].label;
    }
    return "";
  };

  const onPageChange = (page: number) => {
    setCurrent(page);
    const pager = JSON.parse(JSON.stringify(requestData));
    pager.offset = (page - 1) * 10;
    setRequestData(pager);
  };
  const paginationOpt: any = {
    showQuickJumper: true,
    total: total,
    pageSize: 10,
    showSizeChanger: false,
    onChange: onPageChange,
    current: current,
    size: "default"
  };
  const [requestData, setRequestData] = useState({
    sendStatus: 0,
    limit: 10,
    offset: 0
  });

  const columns: any = [
    { title: "工厂", dataIndex: "siteName", key: "siteName" },
    { title: "发货平台", dataIndex: "platform", key: "platform" },
    { title: "产品类型", dataIndex: "categoryName", key: "categoryName" },
    { title: "车型", dataIndex: "vehicleType", key: "vehicleType" },
    { title: "规格组", dataIndex: "specGroup", key: "specGroup" },
    {
      title: "发送范围",
      dataIndex: "publishEndDate",
      key: "publishEndDate",
      render: (_: any, record: any) => {
        let sendTypeObj = "通知司机和客户";
        if (record.sendType === "0") {
          sendTypeObj = "仅通知司机";
        } else if (record.sendType === "1") {
          sendTypeObj = "仅通知客户";
        }
        return `${showLabel(record.appointmentStatus, bookingStatus)}, ${sendTypeObj}, ${record.appointmentDate ||
          ""} ${showLabel(record.logisticsStatus, logisticsStatus)}`;
      }
    },
    {
      title: "通知内容",
      dataIndex: "sendContent",
      key: "sendContent"
    },
    { title: "创建人", dataIndex: "createBy", key: "createBy" },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
      render: (current: any) => moment(current).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      title: "删除",
      render: (record: any) => (
        <BuyerModal
          delId={record.id}
          handleOk={onHandleDeleteOk(record.id)}
          noticeFrom={0}
          getListData={getListData}
          notificationType="短信通知"
        />
      ),
      fixed: "right" as "right"
    }
  ];

  // Similar to componentDidMount and componentDidUpdate
  // Just run the first time rendering
  useEffect(() => {
    // 设置当前天往后推7天的日期
    setDateStrsArrInit();
  }, []);

  // 设置可查看和配置的时间
  const setDateStrsArrInit = () => {
    const dateStrArrTemp = [];
    const currentDateTime = new Date();
    const currentDateYear = currentDateTime.getFullYear();
    const currentDateMonth =
      currentDateTime.getMonth() + 1 < 10 ? `0${currentDateTime.getMonth() + 1}` : currentDateTime.getMonth() + 1;
    const currentDateDay = currentDateTime.getDate() < 10 ? `0${currentDateTime.getDate()}` : currentDateTime.getDate();
    const oneDay = 24 * 3600 * 1000;
    // 加入前3天的日期
    for (let i = 3; i > 0; i--) {
      const previousDateTime = new Date(currentDateTime.getTime() - oneDay * i );
      const previousDateTimeYear = previousDateTime.getFullYear();
      const previousDateTimeMonth =
        previousDateTime.getMonth() + 1 < 10 ? `0${previousDateTime.getMonth() + 1}` : previousDateTime.getMonth() + 1;
      const previousDateTimeDay =
        previousDateTime.getDate() < 10 ? `0${previousDateTime.getDate()}` : previousDateTime.getDate();
      dateStrArrTemp.push({
        value: [previousDateTimeYear, previousDateTimeMonth, previousDateTimeDay].join("-"),
        label: [previousDateTimeMonth, previousDateTimeDay].join("/")
      });
    }
    // 加入当天日期
    dateStrArrTemp.push({
      value: [currentDateYear, currentDateMonth, currentDateDay].join("-"),
      label: `${[currentDateMonth, currentDateDay].join("/")}（今日）`
    });

    // 设置未来6天的日期
    for (let i = 1; i < 7; i += 1) {
      const nowDateTime = new Date(currentDateTime.getTime() + oneDay * i);
      const nowDateTimeYear = nowDateTime.getFullYear();
      const nowDateTimeMonth =
        nowDateTime.getMonth() + 1 < 10 ? `0${nowDateTime.getMonth() + 1}` : nowDateTime.getMonth() + 1;
      const nowDateTimeDay = nowDateTime.getDate() < 10 ? `0${nowDateTime.getDate()}` : nowDateTime.getDate();
      dateStrArrTemp.push({
        value: [nowDateTimeYear, nowDateTimeMonth, nowDateTimeDay].join("-"),
        label: [nowDateTimeMonth, nowDateTimeDay].join("/")
      });
    }

    // 设置时间数组
    setDateStrArr(dateStrArrTemp);
  };

  // 获取预约时间初始值
  const dateInitValue = () => {
    const currentDateTime = new Date();
    const currentDateYear = currentDateTime.getFullYear();
    const currentDateMonth =
      currentDateTime.getMonth() + 1 < 10 ? `0${currentDateTime.getMonth() + 1}` : currentDateTime.getMonth() + 1;
    const currentDateDay = currentDateTime.getDate() < 10 ? `0${currentDateTime.getDate()}` : currentDateTime.getDate();
    return [currentDateYear, currentDateMonth, currentDateDay].join("-");
  };

  useEffect(() => {
    getListData();
    getMessageTemplates();
  }, [requestData]); // eslint-disable-line react-hooks/exhaustive-deps

  //当categoryName和specGroup改变时,重新渲染模板内容
  useEffect(() => {
    if (messageIsShow) {
      onHandleTemplateChange("", messageTemplates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageFiltersData.siteCode, messageFiltersData.categoryName, messageFiltersData.specGroup]);

  // 获取短信模板列表
  const getMessageTemplates = () => {
    setLoading(true);
    getSmsTemplates()
      .then((res: any) => {
        if (res && res.data.length > 0) {
          setSmsTemplates(
            res.data.map((template: any) => {
              return { ...template, label: template.templateTitle, value: template.templateId };
            })
          );
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
      })
      .finally(() => setLoading(false));
  };

  // 获取短信通知记录
  const getListData = () => {
    setLoading(true);
    const values = requestData;
    sendSmsInfo(values, { sendStatus: 0 })
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
        } else if (responseJSON?.data) {
          const tableRecordData = Array.isArray(responseJSON.data.resultList) ? responseJSON.data.resultList : [];
          setTotal(responseJSON.data.metadata?.totalCount ?? 0);
          setData(tableRecordData);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        setData([]);
        setTotal(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 删除短信记录
  const deleteSmsRecord = (id: any) => {
    setLoading(true);
    const values = { offset: 1, id: id };
    deleteSmsNoticeInfo(values)
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
        } else if (responseJSON?.data) {
          getListData();
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("删除失败，请稍后重试。");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
    setSelectedRowKeys(selectedRowKeys);
    if (selectedRowKeys.length !== 0) {
      setSelected(true);
      setCurrentSmsInfo({
        id: selectedRowKeys[0]
      });
    } else {
      setSelected(false);
    }
  };

  // 选择预约状态，决定是否显示预约时间
  const onHandleBookingStatusChange = useCallback((selectValue: string, currentOption: any) => {
    setShowBookingTime(selectValue === "2");
  }, []);

  // 选择模板，展示内容
  const onHandleTemplateChange = useCallback(
    (selectValue: string, currentOption: any) => {
      if (!messageIsShow) {
        setMessageTemplates(currentOption);

        //设置参数的初始值 第一次全置空
        const array = currentOption.templateContent.match(/(?=\{).+?(\})/g).map(() => "");
        setMessageInputValueArray(array);
      } else {
        //设置参数的初始值 filter回调使用，保留后两个值
        const array = currentOption.templateContent.match(/(?=\{).+?(\})/g).map((item: any, index: number) => {
          if (index === 3) {
            return messageInputValueArray[3] ? messageInputValueArray[3] : "";
          } else if (index === 4) {
            return messageInputValueArray[4] ? messageInputValueArray[4] : "";
          } else {
            return "";
          }
        });
        setMessageInputValueArray(array);
      }
      setMessageIsShow(true);

      const node = getSmsContent(currentOption.templateContent, messageFiltersData);
      setMessageContentNode(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageFiltersData, messageInputValueArray, messageIsShow]
  );

  // 确认删除
  const onHandleDeleteOk = (id: any) => (event?: any) => {
    deleteSmsRecord(id);
  };

  const rowSelection = {
    type: "radio" as any,
    hideSelectAll: true,
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    fixed: true
  };

  const onFinish = useCallback(
    (values: any) => {
      //第三四个参数不能为空
      if (!messageInputValueArray[3] || !messageInputValueArray[4]) {
        message.error("请完成通知发送内容！");
        return;
      }

      const siteName = showLabel(messageFiltersData.siteCode, supplierList);
      const siteShortName = returnSiteShortName(messageFiltersData.siteCode);
      const request = {
        ...values,
        ...messageFiltersData,
        sendParameters: [messageInputValueArray[3], messageInputValueArray[4]],
        siteCode: messageFiltersData.siteCode === "" ? "" : messageFiltersData.siteCode,
        siteShortName: siteShortName,
        siteName: siteName,
        appointmentDate:
          values.appointmentDate && values.appointmentDate.length > 0 ? values.appointmentDate.join(",") : null
      };
      setSpinning(true);
      saveSmsNoticeInfo(request)
        .then((responseJSON: any) => {
          if (responseJSON.code && responseJSON.code === "99999") {
            // 99999 提示后端错误信息提示
            message.error(responseJSON.errorMsg);
          } else {
            getListData();
            form.resetFields();
            setMessageContentNode("");
            setMessageIsShow(false);
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
        })
        .finally(() => {
          setSpinning(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, messageFiltersData, messageInputValueArray]
  );

  // 点击提交
  const handleSubmit = useCallback(() => {
    // 获取短信条数
    const getSmsCountRequest = () => {
      const request = {
        id: selectedRowKeys[0],
        type: ""
      };
      setSpinning(true);
      sendSmsNotice(request)
        .then((responseJSON: any) => {
          if (responseJSON.code && responseJSON.code === "99999") {
            // 99999 提示后端错误信息提示
            message.error(responseJSON.errorMsg);
          } else {
            const smsInfo = currentSmsInfo;
            setCurrentSmsInfo({ ...smsInfo, count: responseJSON.data });
            setShowConfirmSendSms(true);
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
        })
        .finally(() => {
          setSpinning(false);
        });
    };

    getSmsCountRequest();
  }, [currentSmsInfo, selectedRowKeys]);

  // model确认发送
  const handleOk = useCallback(() => {
    // 发送短信
    const sendSmsNoticeRequest = () => {
      const request = {
        id: selectedRowKeys[0],
        type: "send"
      };
      setSpinning(true);
      sendSmsNotice(request)
        .then((responseJSON: any) => {
          if (responseJSON.code && responseJSON.code === "99999") {
            // 99999 提示后端错误信息提示
            message.error(responseJSON.errorMsg);
          } else {
            getListData();
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
        })
        .finally(() => {
          setSelected(false);
          setSpinning(false);
        });
    };

    sendSmsNoticeRequest();
    setShowConfirmSendSms(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowKeys]);

  // model取消发送
  const handleCancel = useCallback(() => {
    setShowConfirmSendSms(false);
  }, []);

  // 输入框
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const number = Number(e.target.id);
      let array = messageInputValueArray;
      array[number] = e.target.value;
      setMessageInputValueArray(array);
    },
    [messageInputValueArray]
  );

  // 将短信模板转换成输入框
  const getSmsContent = (info: string, messageFiltersDatas: any) => {
    const shortName = returnSiteShortName(messageFiltersDatas.siteCode);
    let reg = /(?=\{)(.+?)\}/g,
      match,
      lastIndex = 0,
      ret = [],
      i = 0,
      bindId = 0;
    match = reg.exec(info);
    while (match) {
      if (match.index !== lastIndex) {
        ret.push(React.createElement("span", { key: i++ }, info.slice(lastIndex, match.index)));
      }

      switch (bindId) {
        case 0:
          ret.push(
            <Input
              style={{ display: "inline", width: "100px" }}
              id={bindId.toString()}
              key={`message-content-input${bindId}`}
              onChange={handleInputChange}
              readOnly
              value={shortName || ""}
            ></Input>
          );
          break;
        case 1:
          ret.push(
            <Input
              style={{ display: "inline", width: "100px" }}
              id={bindId.toString()}
              key={`message-content-input${bindId}`}
              onChange={handleInputChange}
              readOnly
              value={messageFiltersDatas.categoryName === null ? "" : messageFiltersDatas.categoryName}
            ></Input>
          );
          break;
        case 2:
          ret.push(
            <Input
              style={{ display: "inline", width: "100px" }}
              id={bindId.toString()}
              key={`message-content-input${bindId}`}
              onChange={handleInputChange}
              readOnly
              value={messageFiltersDatas.specGroup === null ? "" : messageFiltersDatas.specGroup}
            ></Input>
          );
          break;
        case 3:
          ret.push(
            <Tooltip
              key={`message-content-input-tooltips${bindId}`}
              title="建议控制在10个中文字以内，超过将发送2条短信"
            >
              <Input
                style={{ display: "inline", width: "300px" }}
                id={bindId.toString()}
                key={`message-content-input${bindId}`}
                onChange={handleInputChange}
              ></Input>
            </Tooltip>
          );
          break;
        case 4:
          ret.push(
            <Input
              style={{ display: "inline", width: "300px" }}
              id={bindId.toString()}
              key={`message-content-input${bindId}`}
              onChange={handleInputChange}
            ></Input>
          );
          break;
        default:
          ret.push(
            <Input
              style={{ display: "inline", width: "100px" }}
              id={bindId.toString()}
              key={`message-content-input${bindId}`}
              onChange={handleInputChange}
            ></Input>
          );
      }

      lastIndex = match.index + match[0].length;
      bindId += 1;
      match = reg.exec(info);
    }
    if (info && lastIndex !== info.length) {
      ret.push(info.slice(lastIndex));
    }
    return ret;
  };

  const returnSiteShortName = (str: any) => {
    switch (str) {
      case "1959":
        return "日照";
      case "1142":
        return "阳江";
      case "1006":
        return "南通";
      case "1140":
      case "1721":
        return "东莞";
      case "1254":
        return "嘉好";
      default:
        return null;
    }
  };

  // 回调
  const handleMessageFiltersCallBack = useCallback(() => {
    const currentMessageFilterData = messageFilters_Ref.current.getFormFields();
    // set button enable
    let enableHight;
    if (["1959", "1254"].includes(currentMessageFilterData.siteCode)) {
      if (currentMessageFilterData.platform === "粕类发货平台") {
        enableHight = currentMessageFilterData.specGroup && currentMessageFilterData.vehicleType;
        setSubmitEnable(enableHight);
      } else {
        enableHight =
          currentMessageFilterData.siteCode &&
          currentMessageFilterData.platform &&
          currentMessageFilterData.categoryName;
        setSubmitEnable(enableHight);
      }
    } else {
      if (currentMessageFilterData.platform === "粕类发货平台") {
        enableHight = currentMessageFilterData.specGroup;
        setSubmitEnable(enableHight);
      } else {
        enableHight =
          currentMessageFilterData.siteCode &&
          currentMessageFilterData.platform &&
          currentMessageFilterData.categoryName;
        setSubmitEnable(enableHight);
      }
    }

    setMessageFiltersData({ ...messageFiltersData, ...currentMessageFilterData });
  }, [messageFiltersData]);

  return (
    <div className="setting-content">
      <MessageFilters
        ref={messageFilters_Ref}
        switcherData={switcher || null}
        userDetailsData={userDetails || null}
        get_message_filters={handleMessageFiltersCallBack}
      />
      <Spin spinning={spinning}>
        <div className="form-container">
          <Form name="message-setting-form" layout="vertical" autoComplete="off" onFinish={onFinish} form={form}>
            <div className="form-title">通知发送范围</div>
            <Row gutter={80} justify="start">
              <Col md={6} xs={24}>
                <Form.Item
                  label={
                    <>
                      <span className="required-mark">*</span>预约状态
                    </>
                  }
                  name="appointmentStatus"
                  initialValue="1"
                >
                  <Select
                    data-testid="test-appointmentStatus-select"
                    options={bookingStatus}
                    onChange={onHandleBookingStatusChange}
                  />
                </Form.Item>
              </Col>
              {showBookingTime && (
                <Col md={6} xs={24}>
                  <Form.Item
                    label={
                      <>
                        <span className="required-mark">*</span>预约时间
                      </>
                    }
                    name="appointmentDate"
                    initialValue={[dateInitValue()]}
                  >
                    <Select
                      data-testid="test-appointmentDate-select"
                      options={dateStrArr}
                      mode="multiple"
                      allowClear
                      showSearch={false}
                      placeholder="请选择预约时间"
                      defaultActiveFirstOption={false}
                      showArrow={true}
                      filterOption={false}
                      maxTagCount="responsive"
                    />
                  </Form.Item>
                </Col>
              )}
              <Col md={6} xs={24}>
                <Form.Item
                  label={
                    <>
                      <span className="required-mark">*</span>物流状态
                    </>
                  }
                  name="logisticsStatus"
                  initialValue="1"
                >
                  <Select data-testid="test-logisticsStatus-select" options={logisticsStatus} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={80} justify="start">
              <Col md={6} xs={24}>
                <Form.Item name="sendType" rules={[{ required: true, message: "请选择通知对象" }]}>
                  <Radio.Group className="radio-group">
                    <Radio value={"0"}>仅通知司机</Radio>
                    <Radio value={"1"}>仅通知客户</Radio>
                    <Radio value={"2"}>通知司机和客户</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div className="form-title">通知内容</div>
            <Row gutter={80} justify="start">
              <Col md={6} xs={24}>
                <Form.Item
                  label="选择模板"
                  name="sendTemplateId"
                  rules={[{ required: true, message: "请选择短信模板" }]}
                >
                  <Select
                    data-testid="test-sendTemplateId-select"
                    placeholder="请选择短信模板"
                    disabled={!smsTemplates.length}
                    options={smsTemplates}
                    onChange={onHandleTemplateChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div>
              <div>通知发送内容</div>
              <div>{messageContentNode}</div>
            </div>
            <Row>
              <Col span={24} className="align-right">
                <Form.Item>
                  <Button data-testid="save-btn" type="primary" disabled={!submitEnable} htmlType="submit">
                    保存
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <Divider />
        <div className="table-container">
          <Row>
            <Col span={12} className="table-title">
              已建短信通知
            </Col>
            <Col span={12} className="align-right total-item">
              共 {total} 条
            </Col>
          </Row>
          <Table
            columns={columns}
            pagination={paginationOpt}
            dataSource={data}
            scroll={{ x: "max-content" }}
            rowSelection={rowSelection}
            rowKey="id"
            loading={loading}
            size="small"
          />
          <Row>
            <Col span={24} className="align-right">
              <Button
                data-testid="test-submit"
                type="primary"
                onClick={handleSubmit}
                disabled={!selected}
                style={{ marginTop: 10 }}
              >
                提交
              </Button>
            </Col>
          </Row>
        </div>
      </Spin>
      {showConfirmSendSms && (
        <Modal
          title="确认提交？"
          visible={true}
          footer={
            <Button type="primary" onClick={handleOk}>
              确定
            </Button>
          }
          onCancel={handleCancel}
        >
          点击确认按钮后将发送{currentSmsInfo.count}条短信通知
        </Modal>
      )}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  userDetails: state.userDetails,
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(MessageSetting);
