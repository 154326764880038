import { updateDos } from "@/api/deliveryOrder";
import DeleteFilled from "@ant-design/icons/lib/icons/DeleteFilled";
import { Button, message, Modal } from "antd";
import { useCallback, useState } from "react";

interface MyProps {
  data: any; // 当前要删除的列表数据
  cb_success?: (record: any) => void; // 删除成功操作后的回调函数
  cb_fail?: (record: any) => void; // 删除失败操作后的回调函数
}

const TableDelete = (props: MyProps) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleClick = useCallback(() => {
    if (props.data) {
      setVisible(true);
    }
  }, [props.data]);

  const onDoDelete = useCallback(() => {
    setConfirmLoading(true);
    updateDos([props.data], {
      type: "deleteDo"
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
          if (props.cb_fail) {
            props.cb_fail(props.data);
          }
        } else if (props.cb_success) {
          props.cb_success(props.data);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        if (props.cb_fail) {
          props.cb_fail(props.data);
        }
      })
      .finally(() => {
        setConfirmLoading(false);
        setVisible(false);
      });
  }, [props]);

  const onHandleModalClose = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <>
      <Button className="delete-icon" onClick={handleClick} type="text" style={{ width: 50, fontSize: 16, padding: 0 }} disabled={props.data.contractExecutionSequenceControl}>
        <DeleteFilled />
      </Button>
      {/* 删除提示框 */}
      <Modal
        title="删除"
        width={300}
        visible={visible}
        centered
        maskClosable={false}
        onCancel={onHandleModalClose}
        footer={
          <Button data-testid="delete-btn" loading={confirmLoading} type="primary" onClick={onDoDelete}>
            确认
          </Button>
        }
      >
        <span>您确定删除该条提货委托函申请吗？</span>
      </Modal>
    </>
  );
};

export default TableDelete;
