import React, { useCallback, useEffect, useState } from "react";
import { Select, SelectProps } from "antd";
import { getTransportTypes } from "@/api/constant";

type ValueType = string | undefined | null;

// 运输方式
const TransportTypeItem: React.FC<{
  businessDivision: any;
  value?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: (value: ValueType, option: any) => void;
}> = props => {
  const [value, setValue] = useState<ValueType>();
  const [transportTypeOption, setTransportTypeOption] = useState<SelectProps["options"]>([]); // 运输方式列表

  // 获取运输方式
  const doSetTransportTypeOption = (val: any) => {
    if (!val) {
      setTransportTypeOption([]);
      return;
    }
    getTransportTypes({ businessDivision: val }).then((res: any) => {
      if (res.data) {
        setTransportTypeOption(res.data);
        const initOption = res.data.filter((item: any) => item.value === props.value);
        // 如果买方地址、运输方式、嘉吉发货地址value不在选项之中，显示“请选择”
        if (initOption?.length > 0) {
          onHandleChange(props.value, initOption[0]);
        } else {
          onHandleChange(null, {});
        }
      } else {
        setTransportTypeOption([]);
      }
    });
  };

  const onHandleChange = useCallback(
    (newValue: ValueType, currentOption: any) => {
      setValue(newValue);
      props.onChange && props.onChange(newValue, currentOption);
    },
    [props]
  );

  useEffect(() => {
    doSetTransportTypeOption(props.businessDivision);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.businessDivision]);

  return (
    <Select
      data-testid="transportType"
      value={value}
      options={transportTypeOption}
      placeholder="请选择"
      dropdownMatchSelectWidth={false}
      onChange={onHandleChange}
      style={{ width: "80%" }}
    />
  );
};

export default TransportTypeItem;
