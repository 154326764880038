import { allOccBusinessLine, allSupplier } from "@/utils/constant";
import { Button, Col, Form, Input, Radio, RadioChangeEvent, Row, Select, TimePicker } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import ListItemInput from "@/pages/common/listItemInput";

interface Props {
  dataSource: any,
  onFinish: (values: any) => void;
};

const ConfigurationDetails: React.FC<Props> = (props) => {
  const [nextDay, setNextDay] = useState(0); // 时间范围类型，0为当天，1为第二天
  const [isDisabledNextDayRadio, setIsDisabledNextDayRadio] = useState(false);
  const [isDisabledEndTime, setIsDisabledEndTime] = useState(true);
  const [endDate, setEndDate] = useState(null);
  const { dataSource } = props;

  const [form] = useForm();
  // const [values, setValues] = useState<any>({});

  /**
   * 监听表单字段的变化
   */
  // const onValuesChange = useCallback((changedValues: any, allValues: any) => {
  //   setValues(allValues);
  // }, [form]);

  /**
   * 监听表单提交
   */
  const onFinish = useCallback((values: any) => {
    values.warehouseCodes = values.warehouseCodes ? values.warehouseCodes.split(",") : null;
    values.nextDay = nextDay;
    values.startDate = values.startDate ? moment(values.startDate).format("HH:mm") : null;
    values.endDate = values.endDate ? moment(values.endDate).format("HH:mm") : null;
    props.onFinish(values);
  }, [props, nextDay]);

  /**
   * 计算disabled时间范围
   * @param start 
   * @param end 
   * @returns 
   */
  const range = (start: any, end: any) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const handleNextDayChange = useCallback((e: RadioChangeEvent) => {
    setNextDay(e.target.value);
  }, []);

  /**
   * 设置结束时间的可选范围 - 当天
   * @param startDateHour 
   * @param startDateMin 
   * @returns 
   */
  const disabledEndTimeCurrentDayOptions = useCallback((startDateHour: any, startDateMin: any) => {
    return {
      disabledHours: () => {
        return range(0, startDateHour);
      },
      disabledMinutes: (selectedHour: number) => {
        if (selectedHour !== -1 && selectedHour === startDateHour && startDateMin === 30) {
          return range(0, 60).splice(0, startDateMin);
        }
        return [];
      }
    }
  }, []);

  /**
   * 设置结束时间的可选范围 -第二天
   * @param startDateHour 
   * @param startDateMin 
   * @returns 
   */
  const disabledEndTimeNextDayOptions = useCallback((startDateHour: any, startDateMin: any) => {
    return {
      disabledHours: () => {
        if (startDateMin === 0) {
          return range(startDateHour, 60);
        }
        if (startDateMin === 30) {
          return range(startDateHour + 1, 60);
        }
      },
      disabledMinutes: (selectedHour: number) => {
        if (startDateMin === 30 && startDateHour === selectedHour) {
          return range(30, 60);
        }
        return [];
      }
    }
  }, [])

  /**
   * 设置结束时间的可选范围
   */
  const disabledEndTime: any = useCallback(() => {
    if (form.getFieldValue('startDate')) {
      const hour = form.getFieldValue('startDate').hour();
      const min = form.getFieldValue('startDate').minute();
      if (nextDay !== 1) {
        return disabledEndTimeCurrentDayOptions(hour, min);
      } else {
        return disabledEndTimeNextDayOptions(hour, min);
      }
    }
    return [];
  }, [nextDay, form, disabledEndTimeCurrentDayOptions, disabledEndTimeNextDayOptions]);

  /**
   * 处理开始时间的改变
   */
  const handleStartTimeChange = useCallback((time: any, timeString: string) => {
    const isDisabledEndTimeTemp = time ? false : true;
    const isDisabledNextDayRadioTemp = timeString === "00:00" ? true : false;
    setIsDisabledNextDayRadio(isDisabledNextDayRadioTemp);
    setIsDisabledEndTime(isDisabledEndTimeTemp);
    // 清空结束时间
    form.resetFields(["endDate"]);
    setNextDay(0);
    setEndDate(null);
  }, [setIsDisabledEndTime, form]);

  /**
   * 处理结束时间的改变
   */
  const handleEndTimeChange = useCallback((time: any, timeString: string) => {
    setEndDate(time);
  }, []);

  useEffect(() => {
    if (dataSource) {
      const initValues = {
        id: dataSource.id,
        orgCode: dataSource.orgCode,
        businessLine: dataSource.businessLine,
        active: dataSource.active,
        accordingTo: dataSource.accordingTo,
        startDate: dataSource.startDate ? moment(dataSource.startDate, "HH:mm") : null,
        endDate: dataSource.endDate ? moment(dataSource.endDate, "HH:mm") : null,
        warehouseCodes: dataSource?.warehouseCodes?.join(","),
        nextDay: !dataSource.nextDay ? 0 : dataSource.nextDay
      }
      setEndDate(dataSource.endDate);
      setNextDay(initValues.nextDay);
      form.setFieldsValue(initValues);
      if (initValues.startDate) {
        setIsDisabledEndTime(false);
      }
    }
  }, [dataSource, form]);

  return (
    <div className="configuration-details">
      <Form
        form={form}
        layout="vertical"
        // onValuesChange={onValuesChange}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col md={6} xs={24}>
            <Form.Item label="编号" name="id" rules={[{ required: true, message: "" }]}>
              <Input placeholder="请输入" disabled />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item name="orgCode" label="卖方" rules={[{ required: true, message: "" }]}>
              <Select style={{ width: "100%" }} placeholder="请选择" disabled options={allSupplier()} />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item label="业务类型" name="businessLine" rules={[{ required: true, message: "" }]}>
              <Select style={{ width: "100%" }} placeholder="请选择" disabled options={allOccBusinessLine()} />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item label="自动审单开关" name="active" rules={[{ required: true, message: "" }]}>
              <Select style={{ width: "100%" }} placeholder="请选择" options={[{ label: "开", value: true }, { label: "关", value: false }]} />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item label="限量判断日期" name="accordingTo" rules={[{ required: true, message: "" }]}>
              <Select style={{ width: "100%" }} placeholder="请选择" options={[{
                label: "提货委托函提交日期",
                value: "提货委托函提交日期"
              }, {
                label: "预计提货日期",
                value: "预计提货日期"
              }]} />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item label="暂停自动审单开始时间" name="startDate">
              <TimePicker
                style={{ width: "100%" }}
                format="HH:mm"
                placeholder="选择开始时间"
                minuteStep={30}
                hideDisabledOptions
                showNow={false}
                onChange={handleStartTimeChange}
              />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item label="暂停自动审单结束时间" name="endDate">
              <TimePicker
                style={{ width: "100%" }}
                format="HH:mm"
                placeholder="选择结束时间"
                minuteStep={30}
                hideDisabledOptions
                showNow={false}
                disabledTime={disabledEndTime}
                onChange={handleEndTimeChange}
                disabled={isDisabledEndTime}
                renderExtraFooter={() => (
                  <div>
                    <Radio.Group value={nextDay} onChange={handleNextDayChange} size="small">
                      <Radio value={0}>当天</Radio>
                      <Radio value={1} disabled={isDisabledNextDayRadio}>第二天</Radio>
                    </Radio.Group>
                  </div>
                )}
              />
            </Form.Item>
            <div style={{ position: "absolute", left: "57px", top: "50%", transform: "translateY(-50%)", paddingTop: "4px" }}>{endDate && (
              <span>{nextDay !== 1 ? "当天" : "第二天"}</span>
            )}</div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={6} xs={24}>
            <Form.Item label="自动审单仓库" name="warehouseCodes">
              <ListItemInput placeholer="请输入自动审单仓库" />
            </Form.Item>
          </Col>
          <Col md={6} xs={24} className="hdp-uf hdp-uf-vfe hdp-uf-hfe" style={{ marginLeft: "auto" }}>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  data-testid="submit-button"
                  type="primary"
                  htmlType="submit"
                  disabled={!dataSource || !Object.keys(dataSource).length}
                >
                  保存
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div >
  );
};

export default ConfigurationDetails;
