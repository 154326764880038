import { put, takeEvery, all, call } from "redux-saga/effects";
import * as switcher from "../actions/switcher";

export function* getSwitcherData(action: any): any {
  try {
    const response = yield call(action.Get);
    let payloadObj = null;
    if (response.preference) {
      payloadObj = { ...response.preference };
    }
    yield put(switcher.getSwitcherSuccess(payloadObj));
  } catch (error) {
    yield put(switcher.getSwitcherError(error));
  }
}

export function* updateSwitcherData(action: any): any {
  try {
    const response = yield call(action.Patch, action.payload);
    if (response.status === "error") {
      throw response.responseError;
    }
    let payloadObj = null;
    if (response.preference) {
      payloadObj = { ...response.preference };
    }
    yield put(switcher.updateSwitcherSuccess(payloadObj));
  } catch (error) {
    const err: any = error;
    if (err.errorCode === "10012") {
      yield put(switcher.updateSwitcherError(err.message));
    }
  }
}

// the 'watcher' - on every 'GET_ALERTS' action, run our get alerts function

export function* getSwitcherDataSaga() {
  yield takeEvery("GET_SWITCHER_DATA", getSwitcherData);
}

export function* updateSwitcherDataSaga() {
  yield takeEvery("UPDATE_SWITCHER_DATA", updateSwitcherData);
}

export function* rootSwitcherSaga() {
  yield all([getSwitcherDataSaga(), updateSwitcherDataSaga()]);
}
