import { useState, useEffect, useCallback } from "react";
import { Tabs, PageHeader } from "antd";
import "./index.scss";

import NotificationTips from "@/pages/common/notificationTips";
import PricePoint from "./pricePoint";
import PricePointConfirmed from "./pricePointConfirmed";

const { TabPane } = Tabs;

const PricePointConfirmation = (props: any) => {
  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    document.title = "点价确认";
  }, []);

  const onTabChange = useCallback((key: any) => {
    setActiveKey(key);
  }, []);

  return (
    <>
      <PageHeader className="page-header" title="点价确认">
        <NotificationTips />
      </PageHeader>
      <div className="price-point-comfirmation">
        <Tabs defaultActiveKey="1" onChange={onTabChange}>
          <TabPane tab="待处理" key="1" style={{ backgroundColor: "#fff" }}>
            {activeKey === "1" && <PricePoint />}
          </TabPane>
          <TabPane tab="已处理" key="2">
            {activeKey === "2" && <PricePointConfirmed />}
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default PricePointConfirmation;
