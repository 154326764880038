import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Checkbox, message } from "antd";
import { doLogout } from "@/utils";
import { termsAndConditionsConfig } from "./constant";
import { doTermsConditionApi } from "@/api/user";

import "./index.scss";

interface Props extends RouteComponentProps {}
interface State {
  is_agree_privacy_policy: boolean;
  is_agree_business_information_statement: boolean;
  is_agree_personal_info_cross_border_notice: boolean;
  isSubmitLoading: boolean;
  isOnlyView: boolean;
}

class TermsConditions extends Component<Props, State> {
  public readonly state = {
    is_agree_privacy_policy: false,
    is_agree_business_information_statement: false,
    is_agree_personal_info_cross_border_notice: false,
    isSubmitLoading: false,
    isOnlyView: false
  };

  componentDidMount() {
    document.title = "使用条款";
    if (window.location.href.indexOf("type=view") !== -1) {
      this.setState({ isOnlyView: true });
    } else {
      this.setState({ isOnlyView: false });
    }
  }

  onCheckChange = (type: number) => (obj: any) => {
    if (type && type === 1) {
      this.setState({ is_agree_privacy_policy: obj.target.checked });
    } else if (type && type === 2) {
      this.setState({ is_agree_business_information_statement: obj.target.checked });
    } else if (type && type === 3) {
      this.setState({ is_agree_personal_info_cross_border_notice: obj.target.checked });
    }
  };

  // 更新用户同意隐私政策的操作
  doUpdateUserAgreePrivacyPolicy = () => {
    const { is_agree_privacy_policy, is_agree_business_information_statement, is_agree_personal_info_cross_border_notice } = this.state;
    if (is_agree_privacy_policy && is_agree_business_information_statement && is_agree_personal_info_cross_border_notice) {
      this.setState({ isSubmitLoading: true });
      doTermsConditionApi()
        .then((responseJSON: any) => {
          if (responseJSON.code && responseJSON.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(responseJSON.errorMsg);
          } else if (responseJSON?.data) {
            //跳转Home首页
            this.props.history.push("/main/home");
          }
        })
        .catch((exception: any) => {
          message.error("操作失败，请稍后重试。");
          console.log(exception);
        })
        .finally(() => {
          this.setState({ isSubmitLoading: false });
        });
    }
  };

  goBack = () => {
    //返回上一个链接
    this.props.history.goBack();
  };

  public render() {
    const {
      isOnlyView,
      is_agree_privacy_policy,
      is_agree_business_information_statement,
      is_agree_personal_info_cross_border_notice,
      isSubmitLoading
    } = this.state;
    return (
      <div className="hdp-uf hdp-uf-hc hdp-uf-vc terms-conditions-page-container">
        <div className="hdp-uf hdp-uf-dc page-content">
          <div className="hdp-uf hdp-uf-dc">
            <div className="page-title">{termsAndConditionsConfig.data.labels.heading}</div>
            <p
              className="rich-text-info"
              dangerouslySetInnerHTML={{ __html: termsAndConditionsConfig.data.pageContent.richText }}
            />
          </div>
        </div>
        <div className="page-footer">
          <div className="hdp-uf hdp-uf-hc footer-content">
            <div className="hdp-uf hdp-uf-dc footer-actions">
              {!isOnlyView && (
                <div className="hdp-uf hdp-uf-vc hdp-uf-dc actions-new-policy">
                  <div className="hdp-uf">
                    <div className="hdp-uf hdp-uf-vc policy-item">
                      <Checkbox data-testid="privacyPolicy" onChange={this.onCheckChange(1)} />
                      <a href={termsAndConditionsConfig.data.labels.privacyPolicyUrl} target="_blank" rel="noreferrer">
                        隐私政策
                      </a>
                    </div>
                    <div className="hdp-uf hdp-uf-vc policy-item">
                      <Checkbox data-testid="businessPolicy" onChange={this.onCheckChange(2)} />
                      <a href={termsAndConditionsConfig.data.labels.businessPolicyUrl} target="_blank" rel="noreferrer">
                        业务信息声明
                      </a>
                    </div>
                    <div className="hdp-uf hdp-uf-vc policy-item">
                      <Checkbox data-testid="personalInfoCrossBorderNotice" onChange={this.onCheckChange(3)} />
                      <span className="cross-border-transfer-notice-notice" onClick={() => { this.props.history.push("/crossbordertransfernotice") }}>个人信息出境告知书</span>
                    </div>
                  </div>
                  <div className="hdp-uf new-policy-btn">
                    <Button type="primary" className="btn-item btn-item-reject" onClick={doLogout}>
                      不同意
                    </Button>
                    <Button
                      data-testid="do-update"
                      type="primary"
                      className="btn-item"
                      loading={isSubmitLoading}
                      disabled={!is_agree_privacy_policy || !is_agree_business_information_statement || !is_agree_personal_info_cross_border_notice}
                      onClick={this.doUpdateUserAgreePrivacyPolicy}
                    >
                      同意
                    </Button>
                  </div>
                </div>
              )}
              {isOnlyView && (
                <div className="hdp-uf hdp-uf-hfe action-go-back">
                  <Button data-testid="btn-go-back" type="primary" onClick={this.goBack}>
                    {termsAndConditionsConfig.data.labels.goBackButtonText}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsConditions;
