import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Button, List } from "antd";
import { notificationList, notificationRead } from "@/api/constant";
import { BellFilled, CloseOutlined } from "@ant-design/icons";
import * as notificationFlagActions from "@/store/actions/notificationFlag";

const NotificationTips = (props: any) => {
  const history = useHistory();
  const [notificationListData, setNotificationListData] = useState<any[]>([]);
  const [unReadCount, setUnReadCount] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const { userPermissionsData, switcher } = props;

  useEffect(() => {
    const getNotificationList = () => {
      notificationList({
        type: "INPORTAL",
        commodityCode: !switcher?.divisionCode || switcher?.divisionCode === "All" ? "" : switcher.divisionCode,
        siteCode: !switcher?.orgCode || switcher?.orgCode === "All" ? "" : switcher.orgCode,
        firmName: userPermissionsData?.CNExternalUsers ? switcher.customerName : null
      })
        .then(res => {
          if (res?.data?.metadata) {
            setNotificationListData(res.data.notifications || []);
            setUnReadCount(res.data.metadata.unReadCount ?? 0);
          }
        })
        .catch((err: any) => {
          console.log(`notificationList Failure: ${err}`);
        });
    };

    getNotificationList();
  }, [
    switcher.customerName,
    switcher.divisionCode,
    switcher.orgCode,
    userPermissionsData?.CNExternalUsers,
    props.notificationFlag
  ]);

  const handleClose = (alertId: any) => (event?: any) => {
    notificationRead({
      id: alertId.toString(),
      state: "READ"
    })
      .then(() => {
        props.updateNotificationFlag();
      })
      .catch((err: any) => {
        console.log(`notificationRead Failure: ${err}`);
      });
  };

  const handleLoadMore = useCallback(() => {
    setLoadMore(!loadMore);
  }, [loadMore]);

  const handleRedirect = (item: any) => (event?: any) => {
    notificationRead({
      id: item.alertId,
      state: "READ"
    })
      .then(() => {
        props.updateNotificationFlag();
        history.push({ pathname: handlePathName(item), state: { activeKey: handleActiveKey(item) } });
      })
      .catch((err: any) => {
        console.log(`notificationRead Failure: ${err}`);
      });
  };
  const handleJumpPageStatus = (url: any, item: any) => (item.doStatus ? `${url}?status=${item.doStatus}` : url);

  const handlePathName = (item: any) => {
    let type;
    const alertCodesActionDelivery = [
      "101",
      "102",
      "201",
      "202",
      "301",
      "302",
      "501",
      "103",
      "601",
      "602",
      "603",
      "604",
      "605",
      "606"
    ];
    if (alertCodesActionDelivery.includes(item.alertCode)) {
      type = "deliveryorder";
    } else {
      type = "contract";
    }
    let redirectUrl;

    // 如果是外部用户，且type === "deliveryorder"，统一跳转到提货单列表(除slotbooking)
    if (userPermissionsData["CNExternalUsers"] && type === "deliveryorder") {
      // slotbooking为6开头
      if (item.redirect === "APPOINTMENT_WAIT_PAGE") {
        redirectUrl = handleJumpPageStatus("/main/slot-booking/booking", item);
      } else if (item.redirect === "APPOINTMENT_SUC_PAGE") {
        redirectUrl = handleJumpPageStatus("/main/slot-booking/booking", item);
      } else {
        redirectUrl = handleJumpPageStatus("/main/delivery/deliverylist", item);
      }
    } else {
      switch (item.redirect) {
        case "DO_ALL_CANCEL": {
          redirectUrl = handleJumpPageStatus("/main/delivery/deliveryletterlist", item);
          break;
        }
        case "DO_EDIT":
          redirectUrl = handleJumpPageStatus("/main/delivery/deliveryletterlist", item);
          break;
        case "DO_REJECT":
          redirectUrl = handleJumpPageStatus("/main/delivery/deliveryletterlist", item);
          break;
        // 增加点击被拒绝消息跳转的已拒绝页面
        case "DO_REPORT_LIST":
          redirectUrl = handleJumpPageStatus("/main/delivery/deliveryletterlist", item);
          break;
        // 增加点击消息的立即处理跳转到doreport页面
        case "IC_CARD_CANCEL_DO_CALLBACK":
          redirectUrl = handleJumpPageStatus("/main/delivery/deliverylist", item);
          break;
        case "DO_DELIVERY_TYPE_PS":
          redirectUrl = handleJumpPageStatus("/main/delivery/deliveryletterlist", item);
          break;
        default:
          redirectUrl = handleJumpPageStatus("/main/delivery/deliveryletterlist", item);
          break;
      }
    }
    return redirectUrl;
  };

  const handleActiveKey = (item: any) => {
    let type;
    const alertCodesActionDelivery = [
      "101",
      "102",
      "201",
      "202",
      "301",
      "302",
      "501",
      "103",
      "601",
      "602",
      "603",
      "604",
      "605",
      "606"
    ];
    if (alertCodesActionDelivery.includes(item.alertCode)) {
      type = "deliveryorder";
    } else {
      type = "contract";
    }
    let redirectUrl;

    // 如果是外部用户，且type === "deliveryorder"，统一跳转到提货单列表(除slotbooking)
    if (userPermissionsData["CNExternalUsers"] && type === "deliveryorder") {
      // slotbooking为6开头
      if (item.redirect === "APPOINTMENT_WAIT_PAGE") {
        redirectUrl = "pending-list";
      } else if (item.redirect === "APPOINTMENT_SUC_PAGE") {
        redirectUrl = "scheduled-list";
      } else {
        redirectUrl = null;
      }
    } else {
      switch (item.redirect) {
        case "DO_ALL_CANCEL": {
          redirectUrl = "1";
          break;
        }
        case "DO_EDIT":
          redirectUrl = "3";
          break;
        case "DO_REJECT":
          redirectUrl = "5";
          break;
        // 增加点击被拒绝消息跳转的已拒绝页面
        case "DO_REPORT_LIST":
          redirectUrl = "5";
          break;
        // 增加点击消息的立即处理跳转到doreport页面
        case "IC_CARD_CANCEL_DO_CALLBACK":
          redirectUrl = null;
          break;
        case "DO_DELIVERY_TYPE_PS":
          redirectUrl = "2";
          break;
        default:
          redirectUrl = "4";
          break;
      }
    }
    return redirectUrl;
  };

  const getRenderItem = useCallback((item: any, index?: number) => {
    return (
      <List.Item
        actions={[
          <Button
            data-testid="close-btn"
            onClick={handleClose(item.alertId)}
            type="link"
            key="list-loadmore-edit"
            style={{ width: "fit-content" }}
          >
            <CloseOutlined />
          </Button>
        ]}
        style={{ paddingRight: 10 }}
      >
        <List.Item.Meta
          description={
            <>
              <span style={{ fontWeight: 600, fontSize: 12 }}>{item.description}</span>{" "}
              <span style={{ fontSize: 12 }}>{item.message}</span>
              <Button
                data-testid="handle-btn"
                type="link"
                style={{ fontSize: 12, width: "fit-content", marginLeft: 10, padding: 0 }}
                onClick={handleRedirect(item)}
              >
                <u>{item.gotToLinkText}</u>
              </Button>
              <div style={{ fontSize: 12 }}>通知时间 {item.receivedDate}</div>
            </>
          }
        />
      </List.Item>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {notificationListData.length > 0 && unReadCount > 0 && (
        <>
          <h3>
            <BellFilled /> 您有 {unReadCount} 条消息需要处理
          </h3>
          <List
            dataSource={loadMore ? notificationListData : notificationListData.slice(0, 3)}
            style={{ background: "#f4f4f4", paddingLeft: 15 }}
            renderItem={getRenderItem}
          />
          {notificationListData.length > 3 && (
            <div
              style={{
                textAlign: "center",
                marginTop: 12
              }}
            >
              <Button
                data-testid="loadmore"
                type="text"
                onClick={handleLoadMore}
                style={{ fontSize: 12, color: "#191919" }}
              >
                {loadMore ? "隐藏通知" : "查看所有通知"}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  switcher: state.switcher,
  userPermissionsData: state.userPermissions,
  notificationFlag: state.notificationFlag
});

const mapDispatchToProps = (dispatch: (arg0: { type: string }) => void) => ({
  updateNotificationFlag: () => {
    dispatch(notificationFlagActions.updateNotificationFlag());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTips);
