import { useEffect, useState, useImperativeHandle, useCallback } from "react";
import { Button, Col, Form, Input, Row, Divider, Modal, message, InputNumber } from "antd";
import { entryPricing } from "@/api/directSys";
import "./index.scss";

const PricePintInput = (props: any) => {
  const [formInput] = Form.useForm();
  const [priceInfoDetail, setPriceInfoDetail] = useState<any>({});
  const [isInputAble, setIsInputAble] = useState(false);
  const [isDiffModalVisible, setIsDiffModalVisible] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    const selectedRow = props.selectedRow;
    if (selectedRow && Object.keys(selectedRow)?.length > 0) {
      setPriceInfoDetail({
        appliedType: selectedRow.appliedType || "-",
        price: getPrice(selectedRow),
        appliedTotalQuantity: getAppliedTotalQuantity(selectedRow),
        contractMonth: selectedRow.contractMonth ? selectedRow.contractMonth : "-",
        operateType: selectedRow.operateType ? selectedRow.operateType : "-"
      });
      setIsInputAble(true);
      formInput.resetFields();
    }
  }, [formInput, props.selectedRow]);

  const getAppliedTotalQuantity = (data: any) => {
    if (data.operateType === "明细点价") {
      return data.lineNumber ? data.lineNumber.toFixed(3) : "-";
    } else if (data.operateType === "汇总点价") {
      return data.appliedTotalQuantity ? data.appliedTotalQuantity.toFixed(3) : "-";
    }
    return "-";
  };

  const getPrice = (data: any) => {
    if (data.operateType === "明细点价") {
      return data.linePrice ? data.linePrice.toFixed(2) : "-";
    } else if (data.operateType === "汇总点价") {
      return data.price ? data.price.toFixed(2) : "-";
    }
    return "-";
  };

  // 输入完成
  const onHandleFormFinish = useCallback(
    (values: any) => {
      const confirmPrice = parseFloat(formInput.getFieldValue("confirmPrice"));
      const confirmNumber = parseFloat(formInput.getFieldValue("confirmNumber"));
      if (
        getPrice(props.selectedRow) === confirmPrice.toFixed(2) &&
        getAppliedTotalQuantity(props.selectedRow) === confirmNumber.toFixed(3)
      ) {
        setIsConfirmVisible(true);
      } else {
        setIsDiffModalVisible(true);
      }
    },
    [formInput, props.selectedRow]
  );

  // 最终确认提交
  const onHandleConfirmOk = useCallback(() => {
    const postObj = {
      id: props.selectedRow.id,
      confirmNumber: formInput.getFieldValue("confirmNumber"),
      confirmPrice: formInput.getFieldValue("confirmPrice"),
      remark: formInput.getFieldValue("remark")
    };
    setConfirmLoading(true);
    entryPricing(postObj, {})
      .then((res: any) => {
        if (res?.result) {
          props.setRefresh(!props.refresh);
          formInput.resetFields();
          setPriceInfoDetail({});
        } else {
          message.error("获取数据失败，请稍后重试。");
        }
      })
      .catch(err => {
        console.log(err);
        message.error("获取数据失败，请稍后重试。");
      })
      .finally(() => {
        setConfirmLoading(false);
        setIsConfirmVisible(false);
      });
  }, [formInput, props]);

  const handleConfirmCancel = useCallback(() => {
    setIsConfirmVisible(false);
  }, []);

  const handleDiffClose = useCallback(() => {
    setIsDiffModalVisible(false);
  }, []);

  useImperativeHandle(props.cRef, () => ({
    // 暴露给父组件的方法
    resetAllFields: () => {
      formInput.resetFields();
      setPriceInfoDetail({});
    }
  }));

  return (
    <div className="apply-form">
      {/* 当前选择的列表项数据 */}
      <div className="price-detail-container">
        <ul className="hdp-uf hdp-uf-vc hdp-uf-ww detail-list">
          <li className="hdp-uf detail-item">
            <div className="title">点价申请类型</div>
            <div className="value">{priceInfoDetail?.appliedType || "-"}</div>
          </li>
          <li className="hdp-uf detail-item">
            <div className="title">本次点价价格</div>
            <div className="value">{priceInfoDetail?.price || "-"}</div>
          </li>
          <li className="hdp-uf detail-item">
            <div className="title">本次点价数量</div>
            <div className="value">{priceInfoDetail?.appliedTotalQuantity || "-"}</div>
          </li>
          <li className="hdp-uf detail-item">
            <div className="title">合约月份</div>
            <div className="value">{priceInfoDetail?.contractMonth || "-"}</div>
          </li>
          <li className="hdp-uf detail-item">
            <div className="title">点价操作类型</div>
            <div className="value">{priceInfoDetail?.operateType || "-"}</div>
          </li>
        </ul>
      </div>
      <Divider />
      {/* Form表单输入 */}
      <Form form={formInput} layout="vertical" onFinish={onHandleFormFinish}>
        <Row justify="space-between">
          <Col md={5} xs={24}>
            <Form.Item label="点价单价" name="confirmPrice" rules={[{ required: true }]}>
              <InputNumber<string>
                data-testid="price-input"
                style={{ width: "100%" }}
                disabled={!isInputAble}
                placeholder="请输入"
                step="0.01"
                precision={2}
                stringMode
              />
            </Form.Item>
          </Col>
          <Col md={5} xs={24}>
            <Form.Item label="点价数量" name="confirmNumber" rules={[{ required: true }]}>
              <InputNumber<string>
                data-testid="number-input"
                style={{ width: "100%" }}
                disabled={!isInputAble}
                placeholder="请输入"
                step="0.001"
                precision={3}
                stringMode
              />
            </Form.Item>
          </Col>
          <Col md={5} xs={24}>
            <Form.Item label="备注（选填）" name="remark">
              <Input placeholder="请输入" disabled={!isInputAble} />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col md={7} xs={24} className="align-right">
            <Form.Item shouldUpdate>
              {() => (
                <div className="input-btn">
                  <Button
                    data-testid="submit-button"
                    type="primary"
                    disabled={!formInput.getFieldValue("confirmNumber") || !formInput.getFieldValue("confirmPrice")}
                    htmlType="submit"
                  >
                    确认
                  </Button>
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {/* Modal提示信息不一致*/}
      <Modal
        title="提示"
        maskClosable={false}
        onCancel={handleDiffClose}
        visible={isDiffModalVisible}
        footer={
          <Button type="primary" onClick={handleDiffClose}>
            确定
          </Button>
        }
      >
        您输入的点价单价/点价数量不相符，请再次确认！
      </Modal>

      {/* Modal再次确认已输入的点价信息*/}
      <Modal
        title="点价信息确认"
        maskClosable={false}
        onCancel={handleConfirmCancel}
        visible={isConfirmVisible}
        footer={
          <Button type="primary" loading={confirmLoading} onClick={onHandleConfirmOk}>
            确定
          </Button>
        }
        getContainer={false}
      >
        <Form layout="horizontal" colon={false}>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item label="本次点价价格" name="price" initialValue={formInput.getFieldValue("confirmPrice")}>
                <Input readOnly bordered={false} style={{ paddingLeft: 0 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="本次点价数量"
                name="appliedTotalQuantity"
                initialValue={formInput.getFieldValue("confirmNumber")}
              >
                <Input readOnly bordered={false} style={{ paddingLeft: 0 }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default PricePintInput;
