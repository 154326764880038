import { getGenerateAnnouncementApi } from "@/api/deliveryOrder";
import { Card, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import * as types from "../../constant";
import "./index.scss";

const GenerateAnnouncement = (props: any) => {
  const { permissions, switcherDetails, userDetails } = props;

  const [generateAnnouncementList, setGenerateAnnouncementList] = useState([]);

  // 获取生产公告
  const getGenerateAnnouncement = useCallback(
    (announcementParams: any = {}) => {
      const { permissions } = props;
      const { orgCode, divisionCode, customerCode } = announcementParams;
      if (orgCode === "" && divisionCode === "") {
        setGenerateAnnouncementList([]);
        return;
      }
      const params = {
        limit: types.LIMIT_MAX_CONTRACT,
        offset: types.OFFSET,
        divisionCode: divisionCode || "",
        orgCode: orgCode || "",
        customerCode: customerCode || ""
      };
      if (permissions && permissions.CNExternalUsers) {
        params.customerCode = customerCode || `${null}`;
      }
      getGenerateAnnouncementApi(params)
        .then((res: any) => {
          if (res.code && res.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(res.errorMsg);
          } else {
            setGenerateAnnouncementList((res.data.notices && res.data.notices.notifications) || []);
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          message.error("获取数据失败，请稍后重试。");
        });
    },
    [props]
  );

  useEffect(() => {
    if (permissions && Object.keys(permissions).length > 0) {
      const { orgCode, divisionCode, customerCode } = switcherDetails;
      // 数据获取
      // 获取公告信息并展示
      if (userDetails && Object.keys(userDetails).length > 0) {
        const announcementParams = { orgCode, divisionCode, customerCode };
        getGenerateAnnouncement(announcementParams);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, switcherDetails, userDetails]);

  return (
    <Card
      style={{ boxShadow: "0 5px 9px #dedbdb", marginBottom: 30 }}
      headStyle={{
        backgroundColor: "white",
        fontWeight: 100,
        borderBottom: "none"
      }}
    >
      <div className="production-container">
        <div className="generate-title">生产公告</div>
        {generateAnnouncementList.length > 0 ? (
          generateAnnouncementList.map((item: any) => (
            <div className="production-container" key={`${item.id}`}>
              <div className="production-content">{item.content}</div>
              <div className="production-bottom">
                <div className="production-bottom-left">
                  <div className="production-bottom-left-label">发布人</div>
                  <span>{item.commodityName}</span>
                  <span>{item.siteName}</span>
                </div>
                <div className="production-bottom-right">
                  {"发布时间： "}
                  {item.publishDate}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-production-container">
            {(permissions && permissions.CNExternalUsers && "暂无生产公告！") || "对不起，该买方暂无生产公告！"}
          </div>
        )}
      </div>
    </Card>
  );
};

export default GenerateAnnouncement;
