import React, { useCallback, useState } from "react";
import { Modal, Button } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { delNotification } from "@/api/systemSetting";

const BuyerModal = (props: any) => {
  const delId = props.delId;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = useCallback(() => {
    const noticeFrom = { noticeFrom: props.noticeFrom };

    // 处理自定义事件
    if (props.handleOk) {
      props.handleOk();
    } else {
      delNotification(noticeFrom, delId).then(res => {
        if (res.data.notices === 1) {
          props.getListData();
        }
      });
    }
    setIsModalVisible(false);
  }, [delId, props]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleVisible = useCallback(() => {
    setIsModalVisible(!isModalVisible);
  }, [isModalVisible]);

  return (
    <div className="setting-btn">
      <Button type="link" onClick={handleVisible} style={{ width: 40, padding: 0 }}>
        <DeleteFilled />
      </Button>
      <Modal
        title="确认删除？"
        maskClosable={false}
        visible={isModalVisible}
        footer={
          <Button type="primary" onClick={handleOk}>
            确定
          </Button>
        }
        onCancel={handleCancel}
      >
        您确定删除该条{props.notificationType}吗？
      </Modal>
    </div>
  );
};

export default BuyerModal;
