import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Card, PageHeader, Row, Col, message } from "antd";
import SearchFilter from "./searchFilter";
import TableList from "./tableList";
import { getSettlementList } from "@/api/directSys";
import DownloadReport from "@/pages/common/downloadReport";
import DownloadDetailReport from "./downloadDetailReport";
import DownloadLDC from "./downloadLDC";
import RefuseModal from "./refuseModal";
import ApproveButton from "./approvalButton";
import { isMobileDevice } from "@/utils/common";
import MobileTableOperation from "@/pages/common/mobileTableOperation";
import NotificationTips from "@/pages/common/notificationTips";
import { useHistory, useLocation } from "react-router-dom";
import { useActivate } from 'react-activation';
import "./index.scss";

const SettlementList = (props: any) => {
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState<any[]>([]);
  const [isEnabledAction, setIsEnableAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [requestListValue, setRequestListValue] = useState<any>({});
  const [resetContent, setResetContent] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const onPageChange = (page: number) => {
    setCurrent(page);
    const pager = JSON.parse(JSON.stringify(requestListValue));
    pager.offset = (page - 1) * 10;
    setRequestListValue(pager);
  };

  const paginationOpt: any = {
    showQuickJumper: true,
    total: total,
    pageSize: 10,
    showSizeChanger: false,
    onChange: onPageChange,
    current: current,
    size: "default"
  };

  const getListData = useCallback(() => {
    const values = requestListValue;
    if (values && Object.keys(values).length) {
      setLoading(true);
      getSettlementList(values)
        .then(res => {
          const tableData = res.data.settlementList;
          const total = res.data.metadata.totalCount;
          setData(tableData);
          setTotal(total);
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          message.error("获取数据失败，请稍后重试。");
          setData([]);
          setTotal(0);
        })
        .finally(() => {
          setLoading(false);
          setIsEnableAction(false);
        });
    }
  }, [requestListValue]);

  // 删除后的回调
  const onHandleDeleteSuccess = useCallback(() => {
    // 处理翻页的边界问题
    if (requestListValue.offset >= paginationOpt.total - 1 && requestListValue.offset > 0) {
      requestListValue.offset -= requestListValue.limit
    }
    // 重新获取列表数据
    getListData();
  }, [getListData, requestListValue, paginationOpt.total]);

  useActivate(() => {
    console.log('TestFunction: didActivate');
    const tempLocation: any = location;
    if (!tempLocation?.state?.referrerPage) {
      // 初始化合同筛选器及合同列表
      const tempResetContent = !resetContent;
      setData([]);
      setCurrent(1);
      setTotal(0);
      setResetContent(tempResetContent);
    }
    // 清除当前页面的history.state，限制如果从非详情页面跳转至当前页面则重置页面内容
    history.replace({
      pathname: "/main/direct-sys/settlementlist",
      state: null
    })
  });

  useEffect(() => {
    document.title = "客户结算列表";
  }, []);

  useEffect(() => {
    getListData();
  }, [getListData, requestListValue]);

  return (
    <>
      <PageHeader className="page-header" title="客户结算列表">
        <NotificationTips />
      </PageHeader>
      <div className="settlement-list">
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb" }}
          bodyStyle={{ paddingTop: 0 }}
          title="查询条件"
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
        >
          <SearchFilter setFields={setRequestListValue} setCurrent={setCurrent} resetContent={resetContent} />
        </Card>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
          bodyStyle={{ paddingTop: 0 }}
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
          title={
            <div>
              <span>客户结算列表</span>
              <div className="report-header">
                {isMobileDevice() ? (
                  <Row gutter={[16, 16]}>
                    <Col>
                      <span className="total">共 {total} 条</span>
                    </Col>
                    <Col>
                      <MobileTableOperation isRotate={true}>
                        <RefuseModal
                          isText={true}
                          fitContent={false}
                          isEnabledAction={isEnabledAction}
                          selectedRow={selectedRow[0] ? selectedRow : []}
                          getListData={getListData}
                        />
                        <ApproveButton
                          isText={true}
                          isEnabledAction={isEnabledAction}
                          selectedRow={selectedRow[0] ? selectedRow : []}
                          getListData={getListData}
                        />
                        <DownloadLDC
                          isText={true}
                          isEnabledAction={isEnabledAction}
                          selectedRow={selectedRow[0] ? selectedRow : []}
                          getListData={getListData}
                        />
                        <DownloadReport
                          text={true}
                          data={{}}
                          params={{
                            total: total,
                            ...requestListValue
                          }}
                          filename="SettlementReport.xls"
                          fileType="settlement"
                        />
                        <DownloadDetailReport
                          text={true}
                          data={{}}
                          params={{
                            total: total,
                            ...requestListValue
                          }}
                          filename="SettlementInfoDetailReport.xls"
                          fileType="settlement"
                        />
                      </MobileTableOperation>
                    </Col>{" "}
                  </Row>
                ) : (
                  <Row gutter={[8, 8]}>
                    <Col>
                      <span className="total">共{total}条</span>
                    </Col>
                    <Col>
                      <RefuseModal
                        fitContent={true}
                        isEnabledAction={isEnabledAction}
                        selectedRow={selectedRow[0] ? selectedRow : []}
                        getListData={getListData}
                      />
                    </Col>
                    <Col>
                      <ApproveButton
                        isEnabledAction={isEnabledAction}
                        selectedRow={selectedRow[0] ? selectedRow : []}
                        getListData={getListData}
                      />
                    </Col>
                    <Col>
                      <DownloadLDC
                        isEnabledAction={isEnabledAction}
                        selectedRow={selectedRow[0] ? selectedRow : []}
                        getListData={getListData}
                      />
                    </Col>
                    <Col>
                      <DownloadReport
                        data={{}}
                        params={{
                          total: total,
                          ...requestListValue
                        }}
                        filename="SettlementReport.xls"
                        fileType="settlement"
                      />
                    </Col>
                    <Col>
                      <DownloadDetailReport
                        data={{}}
                        params={{
                          total: total,
                          ...requestListValue
                        }}
                        filename="SettlementInfoDetailReport.xls"
                        fileType="settlement"
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          }
        >
          <TableList
            tableData={data}
            paginationOpt={paginationOpt}
            setIsEnableAction={setIsEnableAction}
            setSelectedRow={setSelectedRow}
            loading={loading}
            deleteSuccess={onHandleDeleteSuccess}
          />
        </Card>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  userDetails: state.userDetails,
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(SettlementList);
