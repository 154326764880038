import { useState, useEffect, useCallback } from "react";
import { Card, Form, Row, Col, TimePicker, Button, Table, PageHeader, Select, message, Modal, Spin } from "antd";
import { MinusCircleOutlined, PlusOutlined, DownloadOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import moment from "moment";
import { uniqBy } from "lodash";
import DeleteConfirm from "./deleteConfirm";
import { getBanTimeListApi, upDateBanTimeApi, banTimeDownloadApi, getProductQueryApi } from "@/api/slotbooking";
import "./index.scss";
import { bookingConfigType, supplierList } from "@/utils/constant";
import { downloadFile } from "@/utils";

const BookingConfig = () => {
  const [form] = Form.useForm();
  const onWatch_platform = Form.useWatch("platform", form);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showTips, setShowTips] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [tipsMessage, setTipsMessage] = useState("");
  const [crossTime, setCrossTime] = useState(false);
  const [isDownloadListLoading, setIsDownloadListLoading] = useState(false);
  const [recordData, setRecordData] = useState({
    banTime: "",
    createdDate: "",
    id: 0,
    loadingRule: "",
    loadingRuleName: "",
    modifiedBy: "",
    modifiedDate: "",
    siteCode: null,
    siteName: "",
    timeType: "",
    timeTypeName: ""
  });
  const [requestListValue, setRequestListValue] = useState({
    limit: 10,
    offset: 0,
    noticeType: 2,
    sort: "submitDate",
    isSubmit: 1,
    startDate: "",
    endDate: "",
    isAssignBuyer: 1,
    buyerName: ""
  });
  const [platformDataOptions, setPlatformDataOptions] = useState([]); // platform可选列表
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [specGroupOptions, setSpecGroupOptions] = useState<any[]>([]);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const [isActionLoading, setIsActionLoading] = useState<boolean>(false);
  const [isSelectFetching, setIsSelectFetching] = useState(false);

  const selectRow = (record: any) => {
    setSelectedRowKeys([record.id]);
    setIsEdit(true);
  };

  const onSelectChange = (selectedRowKeys: any, rows: any) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
    type: "radio" as "radio"
  };

  const onPageChange = (page: number) => {
    setCurrent(page);
    const pager = JSON.parse(JSON.stringify(requestListValue));
    pager.offset = (page - 1) * 10;
    setRequestListValue(pager);
  };

  const paginationOpt: any = {
    showQuickJumper: true,
    total: total,
    pageSize: 10,
    showSizeChanger: false,
    onChange: onPageChange,
    current: current,
    size: "default"
  };

  const getListData = useCallback(() => {
    setLoading(true);
    const urlParams = {
      limit: requestListValue.limit,
      offset: requestListValue.offset
    };
    getBanTimeListApi(urlParams)
      .then(res => {
        if (res.data?.resultList) {
          const tableData = res.data.resultList.map(mapTableData) || [];
          setData(tableData);
          setTotal(res.data.metadata?.totalCount ?? 0);
          setSelectedRowKeys([]);
        } else {
          setData([]);
          setTotal(0);
        }
      })
      .catch(err => {
        console.log("bookingConfig ===> getListData ===>", err);
        message.error("获取数据失败!");
        setData([]);
        setTotal(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [requestListValue.limit, requestListValue.offset]);

  // 格式化处理table的展示的信息
  const mapTableData = (data: any) => ({
    ...data,
    platform: data.platform || "-",
    category: data.category || "-",
    specGroup: data.specGroup || "-",
    vehicleType: data.vehicleType || "-"
  });

  const handleTimeRangeChange = useCallback(
    (currentTimeRangeValue: any) => {
      for (let banTimeRangeItem of form.getFieldValue("banTime")) {
        const banTimeRangeItemStart = banTimeRangeItem?.banTimeRange?.[0];
        const banTimeRangeItemEnd = banTimeRangeItem?.banTimeRange?.[1];
        if (banTimeRangeItemStart && banTimeRangeItemEnd) {
          // 检测是否存在时间段的交叉
          const startIsCross =
            banTimeRangeItemStart < currentTimeRangeValue?.[0] && currentTimeRangeValue?.[0] < banTimeRangeItemEnd;
          const endIsCross =
            banTimeRangeItemStart < currentTimeRangeValue?.[1] && currentTimeRangeValue?.[1] < banTimeRangeItemEnd;
          if (startIsCross || endIsCross) {
            setCrossTime(true);
            break;
          } else {
            setCrossTime(false);
          }
        }
      }
    },
    [form]
  );

  //判断移除某一时间段后，是否通过交叉范围校验
  const handleRemoveItem = () => {
    const banTimeArr = form.getFieldValue("banTime");
    if (banTimeArr?.length <= 1) {
      setCrossTime(false);
      return;
    }
    for (let banTimeItem1 of banTimeArr) {
      for (let banTimeItem2 of banTimeArr) {
        const banTimeRangeItemStart = banTimeItem2?.banTimeRange?.[0];
        const banTimeRangeItemEnd = banTimeItem2?.banTimeRange?.[1];
        // 检测是否存在时间段的交叉
        const startIsCross =
          banTimeRangeItemStart < banTimeItem1?.banTimeRange?.[0] &&
          banTimeItem1?.banTimeRange?.[0] < banTimeRangeItemEnd;
        const endIsCross =
          banTimeRangeItemStart < banTimeItem1?.banTimeRange?.[1] &&
          banTimeItem1?.banTimeRange?.[1] < banTimeRangeItemEnd;
        if (startIsCross || endIsCross) {
          setCrossTime(true);
          break;
        } else {
          setCrossTime(false);
        }
      }
    }
  };

  // 删除某条time的设置
  const onHandleRemoveTimePicker = (removeFunc: (index: number | number[]) => void, name: number) => (event?: any) => {
    removeFunc(name);
    // 重新触发时间是否交叉的验证
    handleRemoveItem();
  };

  const returnDayTime = (time: string) => {
    let s = time;
    s = s
      .replace(/T/g, " ")
      .replace(/-/g, "-")
      .replace(/\.\d+/, " ");
    return s;
  };

  const onTimeTypeChange = useCallback(() => {
    if (form.getFieldValue("timeType")) {
      setShowTips(true);
      if (form.getFieldValue("timeType") === "ban") {
        setTipsMessage("配置该类型后该时间段所有用户均不可预约");
      }
      if (form.getFieldValue("timeType") === "transport") {
        setTipsMessage("配置该类型后仅运输和配送车司机可使用该时间段进行预约");
      }
    }
  }, [form]);

  //处理卖方工厂 siteCode 的切换,确定 siteName的值
  const handleSiteCodeChange = useCallback(
    (value: string, option: any) => {
      form.setFieldsValue({
        siteName: option.label || "",
        platform: null,
        loadingRule: "" // 当前平台标识
      });
      // 如果siteCode有值则重新获取对应的platform的数据
      if (value) {
        getPlatformsDataOptions(value);
      }
    },
    [form]
  );

  // 获取平台可选数据
  const getPlatformsDataOptions = (orgCodeVal: string) => {
    const getParamsObj = {
      vague: "",
      orgCode: orgCodeVal,
      queryType: "platforms"
    };
    setIsActionLoading(true);
    getProductQueryApi(getParamsObj)
      .then((responseJSON: any) => {
        if (responseJSON?.data?.platforms) {
          const platformsData = responseJSON.data.platforms;
          const NewData = platformsData.map((item: any) => ({
            label: item.name,
            value: item.name,
            loading_rule: item.loadingRule,
            disabled: item.name === "反向预约"
          }));
          setPlatformDataOptions(NewData);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
      })
      .finally(() => {
        setIsActionLoading(false);
      });
  };

  //处理平台 platform 的切换: 确定platform 和 loadingRule的值
  const handlePlatformChange = useCallback(
    (value: string, option: any) => {
      // 如果siteName有值则重新获取对应的platform的数据
      if (value) {
        form.setFieldsValue({
          loadingRule: option.loading_rule, // 当前平台code标识
          category: null,
          specGroup: null, // MG粕类需要
          vehicleType: null // 日照 + MG粕类
        });

        // 日照工厂 || 黄骅工厂） + 粕类发货平台
        if (["1959","1254"].includes(form.getFieldValue("siteCode")) && option.loading_rule === "MG") {
          getVehiclesOptions();
        }
      } else {
        form.setFieldsValue({
          platform: null,
          loadingRule: "", // 当前平台标识
          category: null,
          specGroup: null, // MG粕类需要
          vehicleType: null // 日照 + MG粕类
        });
      }
      // 重置选项值
      setCategoryOptions([]);
      setSpecGroupOptions([]);
      setVehicleTypeOptions([]);
    },
    [form]
  );

  // 获取相关字段的可选数据
  const getSuggestions = useCallback(
    (inputValue: any, id: string) => {
      const idsObj: any = {
        category: {
          queryType: "categorys",
          setFunc: setCategoryOptions
        },
        specGroup: {
          queryType: "specGroups",
          setFunc: setSpecGroupOptions
        }
      };

      const paramsObj = {
        vague: inputValue,
        orgCode: form.getFieldValue("siteCode"),
        platform: form.getFieldValue("platform"),
        queryType: idsObj[id].queryType
      };
      setIsSelectFetching(true);
      getProductQueryApi(paramsObj)
        .then((responseJSON: any) => {
          if (responseJSON.code && responseJSON.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(responseJSON.errorMsg);
            idsObj[id].setFunc([]);
          } else if (responseJSON?.data) {
            const resData = responseJSON.data?.[idsObj[id].queryType] || [];
            const newData = resData.map((item: any) => {
              return { label: item.name, value: item.name };
            });
            // 自定义增加 “所有” 选项
            if (newData?.length > 0) {
              newData.unshift({ label: "所有", value: "所有" });
            }
            idsObj[id].setFunc(uniqBy(newData, "value"));
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          idsObj[id].setFunc([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    [form]
  );

  // 获取产品类型可选列表
  const handleOptionsSearch = (field: string) => (eventValue?: any) => {
    getSuggestions(eventValue || "", field);
  };

  const onCategoryFocusSearch = useCallback(() => {
    getSuggestions("", "category");
  }, [getSuggestions]);

  const onSpecGroupFocusSearch = useCallback(() => {
    getSuggestions("", "specGroup");
  }, [getSuggestions]);

  // 条件下初始化获取车型可选项
  const getVehiclesOptions = () => {
    const paramsObj = {
      queryType: "vehicles"
    };
    getProductQueryApi(paramsObj)
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
          setVehicleTypeOptions([]);
        } else if (responseJSON?.data) {
          const vehiclesData = responseJSON.data.vehicles || [];
          const newData = vehiclesData.map((item: any) => {
            return { label: item.name, value: item.name };
          });
          // 自定义增加 “所有” 选项
          if (newData?.length > 0) {
            newData.unshift({ label: "所有", value: "所有" });
          }
          setVehicleTypeOptions(newData);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        setVehicleTypeOptions([]);
      });
  };

  const onFinish = useCallback(
    (values?: any) => {
      const myValues = { ...form.getFieldsValue(true) };
      Modal.confirm({
        title: "信息确认",
        okText: "确认",
        content: "点击确认按钮后配置立即生效",
        onOk() {
          setIsActionLoading(true);
          const banTimeArr: any[] = [];
          myValues.banTime.forEach((item: any) => {
            banTimeArr.push(
              moment(item?.banTimeRange?.[0]).format("HH:mm:ss") +
                "-" +
                moment(item?.banTimeRange?.[1]).format("HH:mm:ss")
            );
          });
          const requestValue = {
            id: isEdit ? recordData.id : null,
            timeType: myValues.timeType,
            siteCode: myValues.siteCode,
            platform: myValues.platform,
            loadingRule: myValues.loadingRule,
            category: myValues.category,
            specGroup: myValues.specGroup,
            vehicleType: myValues.vehicleType,
            banTime: banTimeArr.toString()
          };
          upDateBanTimeApi({}, requestValue)
            .then((res: any) => {
              if (res.code && res.code === "99999") {
                // 99999 提示后端的错误信息
                message.error("同工厂，同业务线同配置类型仅可以存在一条数据");
              }
              if (res.data) {
                getListData();
                form.resetFields();
                setShowTips(false);
                setIsEdit(false);
              }
            })
            .catch((exception: any) => {
              console.log(`Failure: ${exception}`);
              message.error("保存失败，请稍后重试。");
            })
            .finally(() => {
              setIsActionLoading(false);
              form.resetFields();
              setIsEdit(false);
            });
          setCurrent(1);
        }
      });
    },
    [form, getListData, isEdit, recordData.id]
  );

  const onResetForm = useCallback(() => {
    form.resetFields();
    setIsEdit(false);
    setCrossTime(false);
    setSelectedRowKeys([]);
  }, [form]);

  const columns: any = [
    {
      title: "序号",
      render: (_text: any, _record: any, index: number) => {
        return <>{`${index + 1}`} </>;
      }
    },
    { title: "工厂", dataIndex: "siteName", key: "siteName" },
    { title: "发货平台", dataIndex: "platform", key: "platform" },
    { title: "产品类型", dataIndex: "category", key: "category" },
    { title: "规格组", dataIndex: "specGroup", key: "specGroup" },
    { title: "发货车型", dataIndex: "vehicleType", key: "vehicleType" },
    { title: "配置类型", dataIndex: "timeTypeName", key: "timeTypeName" },
    { title: "时间段", dataIndex: "banTime", key: "banTime" },
    { title: "创建时间", render: (record: any) => <>{record.createdDate ? returnDayTime(record.createdDate) : "-"}</> },
    { title: "创建人", render: (record: any) => <>{record.createdBy || "-"}</> },
    { title: "操作人", render: (record: any) => <>{record.modifiedBy || "-"}</> },
    { title: "操作信息", render: (record: any) => <>{record.optRemark || "-"}</> },
    {
      title: "失效时间",
      render: (record: any) => <>{record.status === "1" ? returnDayTime(record.modifiedDate) : "-"}</>
    },
    {
      title: "操作",
      fixed: "right",
      render: (record: any) => (
        <>
          <Button
            type="link"
            onClick={handleEdit(record)}
            disabled={record.status === "1"}
            style={{
              padding: 0,
              width: 28,
              height: 22,
              marginRight: 10,
              color: `${record.status === "1" ? "rgba(0, 0, 0, 0.25)" : "#1880FF"}`
            }}
          >
            编辑
          </Button>
          <DeleteConfirm getListData={getListData} delId={record.id} itemStatus={record.status} />
        </>
      )
    }
  ];

  const handleEdit = useCallback(
    (record: any) => (event?: any) => {
      const setEditFields = (record: any) => {
        setRecordData(record);
        const timeRangeArr = record.banTime.split(",");
        const banTimeArr: any[] = [];
        timeRangeArr.forEach((item: any) => {
          banTimeArr.push({
            banTimeRange: [moment(item.split("-")[0], "HH:mm:ss"), moment(item.split("-")[1], "HH:mm:ss")]
          });
        });
        form.setFieldsValue({
          timeType: record.timeType,
          siteCode: record.siteCode,
          siteName: record.siteName,
          platform: record.platform,
          loadingRule: record.loadingRule,
          category: record.category,
          specGroup: record.specGroup,
          vehicleType: record.vehicleType,
          banTime: banTimeArr
        });
        // 初始化"发货平台"可选择的数据源
        if (record.siteCode) {
          getPlatformsDataOptions(record.siteCode);
        }
        // 初始化"发货车型"可选择的数据源
        // （日照工厂 || 黄骅工厂） && 谷物油籽业务 && 未设置车型--->需要的打开设置车型弹窗
        if (["1959", "1254"].includes(record.siteCode) && record.loadingRule === "MG") {
          getVehiclesOptions();
        }
      };

      setEditFields(record);
      selectRow(record);
    },
    [form]
  );

  //下载报表
  const handleDownloadReports = useCallback(() => {
    setIsDownloadListLoading(true);
    banTimeDownloadApi({})
      .then((response: any) => {
        if (response) {
          downloadFile(response, `预约信息配置(${moment().format("YYYY-MM-DD")}).xls`);
        } else {
          message.error("下载失败，请稍后重试。");
        }
      })
      .catch((exception: any) => {
        message.error("下载失败，请稍后重试。");
        console.log(exception);
      })
      .finally(() => {
        setIsDownloadListLoading(false);
      });
  }, []);

  useEffect(() => {
    document.title = "预约信息配置";
    getListData();
  }, [getListData]);

  // Card组件的extra信息展示
  const cardExtra = () => {
    return (
      <div className="hdp-uf hdp-uf-vc card-extra">
        <div className="extra-total extra-item">
          <span>共 </span>
          <span>{total}</span>
          <span> 条</span>
        </div>
        <div className="extra-item">
          {/* 跟着下次的Small-work一起发布-display: "none" */}
          <Button
            data-testid="test-download-reports"
            type="primary"
            loading={isDownloadListLoading}
            icon={<DownloadOutlined />}
            disabled={total === 0}
            onClick={handleDownloadReports}
            style={{ display: "none" }}
          >
            下载报表
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <PageHeader className="page-header" title="预约配置信息" />
      <div className="booking-config-page">
        <Spin spinning={isActionLoading}>
          <Card
            style={{ boxShadow: "0 5px 9px #dedbdb" }}
            bodyStyle={{ paddingTop: 0 }}
            title=" "
            headStyle={{
              backgroundColor: "white",
              fontWeight: 100,
              borderBottom: "none"
            }}
          >
            <Form
              form={form}
              name="bookingConfigForm"
              layout="horizontal"
              onFinish={onFinish}
              labelAlign="left"
              initialValues={{
                timeType: null,
                siteCode: null,
                platform: null,
                loadingRule: "",
                category: null,
                specGroup: null, // MG粕类需要
                vehicleType: null // 日照 + MG粕类
              }}
            >
              <Row justify="start" gutter={[32, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                <Col xs={24} md={6}>
                  <Form.Item rules={[{ required: true, message: "请选择配置类型!" }]} name="timeType" label="配置类型">
                    <Select placeholder="请选择配置类型" options={bookingConfigType} onChange={onTimeTypeChange} />
                  </Form.Item>
                  {showTips ? (
                    <div
                      style={{
                        fontWeight: 400,
                        fontSize: 12,
                        color: "#0000006D",
                        marginTop: -20,
                        marginBottom: 5,
                        marginLeft: 65
                      }}
                    >
                      {tipsMessage}
                    </div>
                  ) : null}
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item rules={[{ required: true, message: "请选择卖方工厂!" }]} name="siteCode" label="嘉吉工厂">
                    <Select placeholder="请选择卖方工厂" options={supplierList} onChange={handleSiteCodeChange} />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="start" gutter={[32, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                <Col xs={24} md={6}>
                  <Form.Item
                    label="发货平台"
                    name="platform"
                    className="form-item"
                    rules={[{ required: true, message: "请选择发货平台!" }]}
                  >
                    <Select
                      data-testid="test-platform-select"
                      placeholder="请选择发货平台"
                      options={platformDataOptions}
                      onChange={handlePlatformChange}
                      disabled={!form.getFieldValue("siteCode")}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                  <Form.Item
                    label="选择产品类型"
                    name="category"
                    className="form-item"
                    rules={[{ required: true, message: "请选择产品类型" }]}
                  >
                    <Select
                      data-testid="test-category-select"
                      showSearch
                      allowClear
                      placeholder="请输入产品类型查询"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      options={categoryOptions}
                      onSearch={handleOptionsSearch("category")}
                      onFocus={onCategoryFocusSearch}
                      notFoundContent={isSelectFetching ? <Spin size="small" /> : null}
                      disabled={!onWatch_platform}
                    ></Select>
                  </Form.Item>
                </Col>

                {form.getFieldValue("loadingRule") === "MG" && (
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="选择规格组"
                      name="specGroup"
                      className="form-item"
                      rules={[{ required: true, message: "请选择规格组" }]}
                    >
                      <Select
                        data-testid="test-specGroup-select"
                        showSearch
                        allowClear
                        placeholder="请输入规格查询"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        options={specGroupOptions}
                        onSearch={handleOptionsSearch("specGroup")}
                        onFocus={onSpecGroupFocusSearch}
                        notFoundContent={isSelectFetching ? <Spin size="small" /> : null}
                        disabled={!onWatch_platform}
                      ></Select>
                    </Form.Item>
                  </Col>
                )}

                {form.getFieldValue("loadingRule") === "MG" && ["1959", "1254"].includes(form.getFieldValue("siteCode")) && (
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="发货车型"
                      name="vehicleType"
                      className="form-item"
                      rules={[{ required: true, message: "请选择发货车型" }]}
                    >
                      <Select placeholder="请选择发货车型" options={vehicleTypeOptions} disabled={false} />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Form.List name="banTime" initialValue={[{}]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row gutter={[16, 0]} key={`banTime_${key}`}>
                        <Col xs={24} md={6}>
                          <Form.Item
                            {...restField}
                            name={[name, "banTimeRange"]}
                            label="选择时间"
                            rules={[{ required: true }]}
                          >
                            <TimePicker.RangePicker hideDisabledOptions onChange={handleTimeRangeChange} />
                          </Form.Item>
                        </Col>
                        <Col>
                          {fields.length > 1 && (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={onHandleRemoveTimePicker(remove, name)}
                            />
                          )}
                        </Col>
                      </Row>
                    ))}

                    <Row>
                      <Col md={10} xs={24}>
                        <Form.Item>
                          <Button
                            data-testid="add-row"
                            disabled={crossTime}
                            type="dashed"
                            onClick={add}
                            block
                            icon={<PlusOutlined />}
                          >
                            添加
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                    {crossTime && (
                      <span style={{ fontWeight: 600, fontSize: 12, marginLeft: 10 }}>
                        <ExclamationCircleFilled style={{ color: "#cd0d15", marginRight: 8 }} />
                        时间段不能出现交叉
                      </span>
                    )}
                  </>
                )}
              </Form.List>
              <Row>
                <Col span={24} className="align-right">
                  <Button data-testid="reset-btn" className="form-btn-reset" onClick={onResetForm}>
                    清空
                  </Button>
                  <Button
                    data-testid="save-btn"
                    disabled={crossTime}
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: 10 }}
                  >
                    保存
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Spin>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 10 }}
          bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
          title="已配置时间"
          headStyle={{
            backgroundColor: "white",
            borderBottom: "none"
          }}
          extra={cardExtra()}
        >
          <Table
            columns={columns}
            pagination={paginationOpt}
            rowSelection={rowSelection}
            dataSource={data}
            scroll={{ x: "max-content" }}
            loading={loading}
            rowKey="id"
            size="small"
          />
        </Card>
      </div>
    </>
  );
};

export default BookingConfig;
