import { Button, Popover } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
import "./index.scss";

const MobileTableOperation = (props: any) => {
  const { children, left } = props;
  const [visible, setVisible] = useState(false);

  const onSetVisible = useCallback((value: boolean) => {
    setVisible(value);
  }, []);

  const onHandleSetVisible = useCallback(
    (value: boolean) => (event?: any) => {
      setVisible(value);
    },
    []
  );

  return (
    <div>
      <Popover
        trigger="click"
        placement={left ? "left" : "bottomRight"}
        visible={visible}
        overlayClassName="mobile-apply-popover"
        onVisibleChange={onSetVisible}
        content={
          <div className="mobile-table-operation">
            {Array.isArray(children) ? (
              children.map((val: any) => {
                return (
                  <div key={val} className="mobile-table-operation-child" onClick={onHandleSetVisible(false)}>
                    {val}
                  </div>
                );
              })
            ) : (
              <div className="mobile-table-operation-child" onClick={onHandleSetVisible(false)}>
                {children}
              </div>
            )}
            <Button id="mobile-table-operation-cancel" onClick={onHandleSetVisible(false)}>
              取消
            </Button>
          </div>
        }
      >
        <EllipsisOutlined
          id="mobile-table-operation-icon"
          rotate={props.isRotate ? 0 : 90}
          onClick={onHandleSetVisible(true)}
        />
      </Popover>
    </div>
  );
};

export default MobileTableOperation;
