import { useCallback } from "react";
import { connect } from "react-redux";
import { Card, Form, Input, Select, Button, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { allSupplier } from "@/utils/constant";

// 定义函数组件的props相关参数
type myProps = {
  doSearch: (arg0: any) => void;
  switcherData: any;
};

const SearchFilters = (props: myProps) => {
  const [searchForm] = Form.useForm();

  const onHandleSearchFilterSubmit = useCallback(
    (values?: any) => {
      const currentValues = { ...searchForm.getFieldsValue(true) };
      props.doSearch(currentValues);
    },
    [props, searchForm]
  );

  const handleReset = useCallback(() => {
    searchForm.resetFields();
    onHandleSearchFilterSubmit();
  }, [onHandleSearchFilterSubmit, searchForm]);

  return (
    <Card
      style={{ boxShadow: "0 5px 9px #dedbdb" }}
      bodyStyle={{ paddingTop: 0 }}
      title="查询条件"
      headStyle={{
        backgroundColor: "white",
        fontWeight: 100,
        borderBottom: "none"
      }}
    >
      <div className="search-filter">
        <div className="base-filter">
          <Form
            name="contract-confirmation-form"
            layout="vertical"
            autoComplete="off"
            form={searchForm}
            onFinish={onHandleSearchFilterSubmit}
            initialValues={{
              appliedNo: "",
              basisContractNo: "",
              seller: props.switcherData.orgCode || null
            }}
          >
            <Row justify="space-between">
              <Col md={5} xs={24}>
                <Form.Item name="appliedNo">
                  <Input placeholder="请输入申请单号" />
                </Form.Item>
              </Col>
              <Col md={5} xs={24}>
                <Form.Item name="basisContractNo">
                  <Input placeholder="请输入基差合同号" />
                </Form.Item>
              </Col>
              <Col md={5} xs={24}>
                <Form.Item name="seller">
                  <Select placeholder="请选择卖方" options={allSupplier()} />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col md={7} xs={24} className="align-right">
                <div className="advance-btn">
                  <Button
                    data-testid="reset-btn"
                    style={{ marginRight: 10, backgroundColor: "#5c5c5c" }}
                    type="primary"
                    onClick={handleReset}
                  >
                    清空
                  </Button>
                  <Button data-testid="submit-btn" type="primary" htmlType="submit">
                    <SearchOutlined />
                    查询
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  switcherData: state.switcher
});

export default connect(mapStateToProps, null)(SearchFilters);
