import { getLocalStorageItem, setLocalStorageItem } from "./myLocalStorage";
import { getUseEnumInfoApi } from "@/api/user";
import moment from "moment";
import { allDivisionCode, allOrgCode } from "./constant";

const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const deviceWidth = () => document.documentElement.clientWidth;

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

// 处理返回的文件流，执行浏览器默认行为下载
const downloadFile = (data: any, filename = "report") => {
  const blob = new Blob([data], { type: data.type || "application/octet-stream" });
  if (typeof navigator.msSaveBlob !== "undefined") {
    navigator.msSaveBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof link.download !== "undefined") {
      link.setAttribute("download", filename);
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

// url参数合并绑定（tips：参数为string或者json对象的两种处理方式）
const buildUrlWithEncoding = (
  url: any,
  params: {
    [key: string]: any;
  }
) => {
  const isServiceObject = !!(typeof url === "object" && url.url);
  let query = isServiceObject ? url.url : url;
  query += query.indexOf("?") < 1 ? "?" : "&";
  query += Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join("&");
  query = encodeURI(query);

  return isServiceObject ? { ...url, ...{ url: query } } : query;
};

const deleteCookies = (cookies: any, domain: string) => {
  cookies.forEach((name: string) => {
    document.cookie = `${name}=;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  });
};

// 获取登录用户所需枚举信息
const getEnumInfo = () => {
  const requestParams = {
    type: "permissions"
  };
  return getUseEnumInfoApi(requestParams)
    .then((responseJSON: any) => {
      if (
        responseJSON &&
        Object.keys(responseJSON).length > 0 &&
        Object.keys(responseJSON).indexOf("responseError") < 0
      ) {
        setLocalStorageItem("localEnumInfo", JSON.stringify(responseJSON.data));
        return responseJSON.data;
      }
    })
    .catch((exception: any) => {
      console.log(`Failure: ${exception}`);
      return {};
    });
};

// 判断是否具有该权限--权限数据来源于DSC保存的菜单访问权限信息，与对应的菜单权限保持一致
const isHaveMenuPageView = (pageName: string) => {
  let localEnumInfo = getLocalStorageItem("localEnumInfo");
  let localUserInfo = getLocalStorageItem("localUserDetailsData");
  if (localEnumInfo && localUserInfo) {
    localEnumInfo = JSON.parse(localEnumInfo);
    localUserInfo = JSON.parse(localUserInfo);
    const myMenusList =
      Object.keys(localEnumInfo).length > 0 && Object.keys(localUserInfo).length > 0
        ? localEnumInfo[localUserInfo.groups[0]].permissions
        : [];
    if (myMenusList.length > 0 && myMenusList.includes(pageName)) {
      return true;
    }
    console.log("myMenusList", myMenusList);
  }
  return false;
};

// 判断是否具有该权限--权限来源于user-services接口
const isHaveThisPermission = (permissionName: string) => {
  let localUserPermissions = getLocalStorageItem("localUserPermissions");
  if (localUserPermissions) {
    localUserPermissions = JSON.parse(localUserPermissions);
    if (
      Object.keys(localUserPermissions).length > 0 &&
      Object.keys(localUserPermissions).indexOf(permissionName) !== -1 &&
      localUserPermissions[permissionName]
    ) {
      return true;
    }
  }
  return false;
};

// 判断当前角色为饲料厂角色
// 当前判断仅判断饲料厂，如果需要区分是集团账户下的饲料厂还是非集团账户下的饲料厂需要额外加判断
const isHaveRolePermissionFeedFactory = () => {
  let localEnumInfo = getLocalStorageItem("localEnumInfo");
  if (localEnumInfo) {
    localEnumInfo = JSON.parse(localEnumInfo);
    if (localEnumInfo["FEED_TAG"] === "Y") {
      return true;
    }
  }
  return false;
};

// 判断是否为南方三厂（嘉吉粮油（阳江）有限公司｜东莞嘉吉粮油有限公司｜东莞嘉吉饲料蛋白科技有限公司）
const isSpecialFactory = (data: any) =>
  data &&
  (data.orgCode === "1142" || data.orgCode === "1140" || data.orgCode === "1721") &&
  data.businessLine === "谷物油籽业务";

// 判断是否为南方三厂（嘉吉粮油（阳江）有限公司｜东莞嘉吉粮油有限公司｜东莞嘉吉饲料蛋白科技有限公司）
const isSouthFactory = (orgCode: any) => orgCode && ["1142", "1140", "1721"].includes(orgCode);

// 判断是否为北方工厂
const isNorthFactory = (orgCode: any) => orgCode && ["1006", "1254", "1959"].includes(orgCode);

// 千分位格式化
const formatNumber = (num: number) => {
  if (num) {
    return num.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
  }
  return num;
};

const getCode = (value: any) => {
  if (value) {
    const reg = /\((.+?)\)/gi;
    var strsArr = value.toString().match(reg);
    if (strsArr) {
      const str = strsArr[strsArr.length - 1];
      var strs = str?.substring(1, str.length - 1);
      return strs;
    }
  }
  return value;
};

const getOptionsValue = (options: any, value: any) => {
  let code = "";
  options.forEach((val: any) => {
    if (val.value === value) {
      code = val.key;
    }
  });
  if (code) {
    return code;
  }
  return value;
};

const getOptionsDes= (options: any, value: any) => {
  let code = "";
  options.forEach((val: any) => {
    if (val.value === value) {
      code = val.des;
    }
  });
  if (code) {
    return code;
  }
  return value;
};

// 买方options，没有对应选项，不选择
const isNoReceiveAddress = (options: any, value: any) => {
  if (options) {
    const option = options.filter((val: any) => val.label !== "请选择");
    const keys = option.map((val: any) => val.value);
    const flag = keys.findIndex((val: any) => val === value);
    if (flag === -1) {
      return true;
    }
  }
  return false;
};

const getBusinessLine = (code: string) => {
  if (code) {
    switch (code) {
      case "38":
        return "基础精炼业务";
      case "43":
        return "增值油业务";
      case "40":
        return "谷物油籽业务";
      case "39":
        return "包装油业务";
      case allDivisionCode:
        return "";
      default:
        return "";
    }
  }
  return "";
};

const getOldBusinessLine = (code: any) => {
  switch (code) {
    case "38":
      return "基础精炼业务";
    case "43":
      return "增值油业务";
    case "40":
      return "谷物油籽业务";
    case "39":
      return "包装油业务";
    case "All":
      return "All";
    default:
      return "";
  }
};

const getPlant = (code: string) => {
  if (code) {
    switch (code) {
      case "1142":
        return "嘉吉粮油（阳江）有限公司";
      case "1006":
        return "嘉吉粮油（南通）有限公司";
      case "1140":
        return "东莞嘉吉粮油有限公司";
      case "1254":
        return "河北嘉好粮油有限公司";
      case "1721":
        return "东莞嘉吉饲料蛋白科技有限公司";
      case "1959":
        return "嘉吉粮油（日照）有限公司";
      case allOrgCode:
        return "";
      default:
        return "";
    }
  }
  return "";
};

// 针对code (中文)的类型，截取字符串最外层的括号 -- 案例： 1DLA (嘉好 黄骅 (BRMP)中国 1254) 取 “嘉好 黄骅 (BRMP)中国 1254”
const cutOutCurvesStr = (data: string) => {
  if (data) {
    const reg = new RegExp("(?<=\\().*(?=\\))", "g");
    const curvesStr = data.match(reg);
    return curvesStr ? curvesStr[0] : data;
  }
  return data;
};

// 截取字符串最后一个英文括号结尾中的值 -- 案例： 东食贸易（上海）有限公司(PC3CHINA) 取 “PC3CHINA”
const cutOutRightCodeStr = (data: string) => {
  if (data) {
    // 先确保取出的是最后一个括号结尾的字符串
    const reg1 = /\(([^)]+)\)$/;
    // 再取出里面的值
    const reg2 = new RegExp("(?<=\\()([^)]+)(?=\\))", "g");
    const resStr = data.match(reg1);
    return resStr?.[0]?.match(reg2)?.[0] || null;
  }
  return null;
};

// 元素为对象的数组去重
const noRepeat = (sourceOptions: any) => {
  const optionsArr = [...sourceOptions];
  const hash: any = {};
  return optionsArr.reduce((item, next) => {
    if (!hash[next.label]) {
      hash[next.label] = true;
      item.push(next);
    }
    return item;
  }, []);
};

// 量转换箱
const getDoNumber = (doQTy: any, conversionFactor: any) => {
  if (!doQTy) {
    return "-";
  }
  // 有转换因子表示需要从吨转成件数
  if (doQTy && conversionFactor) {
    const doQtyNumber = parseFloat(doQTy);
    const doNumber: any = doQtyNumber / conversionFactor;
    // Mark：向下取整
    return parseInt(doNumber);
  }
  // 其他直接返回数量即为件数
  return doQTy;
};

const nullTruckLoadingLimit = (data: any) => {
  if (data.entrustNo) {
    if (["ACM配送车", "VA配送车", "ACM外江码头"].indexOf(data.dscTransportType) !== -1) {
      if (data.truckLoadingLimit === 999 || !data.truckLoadingLimit) {
        return "-";
      }
      return data.truckLoadingLimit;
    }
  }
  return data.truckLoadingLimit ? data.truckLoadingLimit : "-";
};

// 用于提交提单时的必填信息校验
const doVerification = (data: any, contractDetails: any) => {
  if (contractDetails) {
    const { buyerSellerOption } = contractDetails;
    const {
      entrustNo,
      orgCode,
      shipAddress,
      transportType,
      dscTransportType,
      cycleId,
      nominationNumber,
      driverName,
      driverContactNumber,
      truckLoadingLimit,
      truckPlateNumber,
      transportTool
    } = data;
    if (isHaveThisPermission("CNInternalUsers")) {
      return !transportTool;
    }
    if (buyerSellerOption === "S" && orgCode === "1006") {
      if (["11", "12", "22", "23"].indexOf(transportType) !== -1 && shipAddress.slice(0, 2) === "9B") {
        return !cycleId || !nominationNumber;
      }
    }
    if (entrustNo) {
      if (
        ["ACM配送车", "VA配送车"].indexOf(dscTransportType) !== -1 ||
        (dscTransportType === "ACM外江码头" && orgCode !== "1006")
      ) {
        return !driverName || !driverContactNumber || !truckLoadingLimit || !truckPlateNumber;
      }
      if (
        ["ACM自提车", "VA自提车", "ACM外库自提车", "ACM外库配送船", "VA外库自提车", "VA外库配送车"].indexOf(
          dscTransportType
        ) !== -1
      ) {
        return false;
      }
      return false;
    }
    if (
      [
        "ACM配送车",
        "VA配送车",
        "ACM自提车",
        "VA自提车",
        "ACM外库自提车",
        "ACM外库配送船",
        "VA外库自提车",
        "VA外库配送车"
      ].indexOf(dscTransportType) !== -1 ||
      (dscTransportType === "外库配送船" && orgCode !== "1006")
    ) {
      return !driverName || !driverContactNumber || !truckLoadingLimit || !truckPlateNumber;
    }
  }
  return false;
};

// 提交提单时的预提延期判断
const expandDateSelect = (data: any, natureDate: any) => {
  if (data) {
    const { withholdingDate, executionStartDate, executionEndDate, delayDate } = data;
    if (natureDate === "start") {
      return withholdingDate
        ? moment(withholdingDate).format("YYYY-MM-DD")
        : moment(executionStartDate).format("YYYY-MM-DD");
    } else if (natureDate === "end") {
      return delayDate ? moment(delayDate).format("YYYY-MM-DD") : moment(executionEndDate).format("YYYY-MM-DD");
    }
  }
  return false;
};

// 千分位格式化
const formatNumberToThousands = (num: number) => {
  if (num) {
    const numStr = num.toString();
    if (numStr.indexOf(".") !== -1) {
      return `${numStr.split(".")[0].replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")}.${numStr.split(".")[1]}`;
    } else {
      return numStr.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
    }
  }
  return num;
};

// 千分位格式化，并保留小数点后几位
const formatNumberToFixedThousands = (num: number, fixedNum: number) => {
  if (num) {
    const numStr = fixedNum ? num.toFixed(fixedNum) : num.toString();
    if (numStr.indexOf(".") !== -1) {
      return `${numStr.split(".")[0].replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")}.${numStr.split(".")[1]}`;
    } else {
      return numStr.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
    }
  }
  return num;
};

// 正负反转，千分位格式化，并保留小数点后几位
const formatReverseNumberToFixedThousands = (num: number, fixedNum: number) => {
  if (num) {
    const numStr = fixedNum ? (-num).toFixed(fixedNum) : (-num).toString();
    if (numStr.indexOf(".") !== -1) {
      return `${numStr.split(".")[0].replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")}.${numStr.split(".")[1]}`;
    } else {
      return numStr.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
    }
  }
  return num;
};

const getOrganization = (entitlements: any[]) => {
  if (entitlements && entitlements.length) {
    const entitle = entitlements.find((item: any) => item.otherControls && item.otherControls.organizationName);
    const localEnumInfo = JSON.parse(getLocalStorageItem("localEnumInfo"));
    if (entitle) {
      if (isHaveRolePermissionFeedFactory()) {
        return `${entitle.otherControls.organizationName} - ${localEnumInfo.FEED_MODEL.feedName}目的地` || "";
      }
      return `${entitle.otherControls.organizationName} ` || "";
    }
  }
  return "";
};

// 过滤显示自定义字段信息，且本地预设有的才返回显示
const filterColumnsData = (columnsData: any[], fieldsDesc: any[]) => {
  let showColumnsData: any = [];
  fieldsDesc.forEach((val: any) => {
    const findItem = columnsData.find((item: any) => item.key === val.value);
    if (findItem) {
      showColumnsData.push(findItem);
    }
  });
  return showColumnsData;
};

// 校验车牌号
const isVehicleNum = (vehicleNumStr: string) => {
  // 合并的正则表达式 -- 燃油车7位 | 新能源8位(新能源降低检测标准)
  const vehicleNumReg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼]{1}(([A-HJ-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1})|([A-HJ-Z]{1}[A-HJ-NP-Z0-9]{6}))$/;
  if (vehicleNumStr) {
    return vehicleNumReg.test(vehicleNumStr.toString());
  }
  return false;
};

const getObjProp = (path = "", obj = {}) =>
  path.split(".").reduce((obj: any, key) => (obj && obj[key] ? obj[key] : null), obj);

// 计算两个日期的差值 
const daysBetween = (date1: string, date2: string): number => {
  const startDate = date1 ? moment(date1) : moment();
  const endDate = moment(date2);
  console.log("startDate=", startDate);
  console.log("endDate=", endDate);
  const duration = startDate.diff(endDate, 'days');
  return Math.abs(duration);
};

// 获取拼单编号中的总数
const extractNumber = (remark: string): number | null => {
  const match = remark.match(/(\d+)-(\d+)/);
  if (match && match[2]) {
    return parseInt(match[2], 10);
  }
  return null;
};

export {
  buildUrlWithEncoding,
  deleteCookies,
  getEnumInfo,
  isMobileDevice,
  deviceWidth,
  isHaveMenuPageView,
  isHaveThisPermission,
  isHaveRolePermissionFeedFactory,
  downloadFile,
  isSpecialFactory,
  formatNumber,
  formatNumberToThousands,
  formatNumberToFixedThousands,
  formatReverseNumberToFixedThousands,
  getOrganization,
  getCode,
  getOptionsValue,
  getOptionsDes,
  doVerification,
  expandDateSelect,
  noRepeat,
  cutOutCurvesStr,
  cutOutRightCodeStr,
  getDoNumber,
  nullTruckLoadingLimit,
  isNoReceiveAddress,
  getBusinessLine,
  getPlant,
  getOldBusinessLine,
  filterColumnsData,
  isVehicleNum,
  getObjProp,
  daysBetween,
  extractNumber,
  isSouthFactory,
  isNorthFactory
};
