import { useState, useEffect, useCallback } from "react";
import { Modal, Radio, Space, message, Spin } from "antd";
import { connect } from "react-redux";
import "./index.scss";

import { vehicleRecordUpdateApi, doBookingDateTimeApi, getBookingDateListApi } from "@/api/slotbooking";

// 定义函数组件的props相关参数
type myProps = {
  modalTitle: string;
  basicInfo: any;
  userDetailsData: any;
  userPermissionsData: any;
  bookingType?: string;
  changeReason?: string;
  doClose: (arg0: any, arg_isRedirect?: boolean) => void;
};

const DoBookingModal = (props: myProps) => {
  const [dateDataLoading, setDateDataLoading] = useState(false);
  const [dateBookingList, setDateBookingList] = useState([] as any[]);
  const [currentDateItem, setCurrentDateItem] = useState<any>({});
  const [currentTime, setCurrentTime] = useState("");
  const [confirmDateAndTime, setConfirmDateAndTime] = useState({ dateStr: "", timeStr: "", ruleId: "" });
  const [modalType, setModalType] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [isNeedRefreshList, setIsNeedRefreshList] = useState(false);
  const [isBookingConfirmLoading, setIsBookingConfirmLoading] = useState(false);

  const setCurrentDateData = useCallback(
    (sourceData: any) => {
      const { basicInfo } = props;
      let currentDateItemInitData = sourceData[0];
      // 如果是变更，默认选中指定项
      if (basicInfo.dateStr) {
        const currentDateItemInitDataArr = sourceData.filter((item: any) => item.dateStr === basicInfo.dateStr);
        if (currentDateItemInitDataArr.length > 0) {
          currentDateItemInitData = currentDateItemInitDataArr[0];
        }
      }
      setCurrentDateItem(mapCurrentDateItem(currentDateItemInitData));
      setConfirmDateAndTime({ dateStr: currentDateItemInitData.dateStr, timeStr: "", ruleId: "" });
    },
    [props]
  );

  // 获取可选择的日期列表
  const getBookingDateList = useCallback(() => {
    const { basicInfo } = props;
    const paramsObj = {
      appointmentId: basicInfo.id
    };
    setDateDataLoading(true);
    getBookingDateListApi(paramsObj)
      .then((responseJSON: any) => {
        if (responseJSON?.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
        } else if (responseJSON?.data) {
          const sourceData = responseJSON.data || [];
          setDateBookingList(sourceData);
          if (sourceData.length > 0) {
            setCurrentDateData(sourceData);
          }
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
      })
      .finally(() => {
        setDateDataLoading(false);
      });
  }, [props, setCurrentDateData]);

  const onVehicleTypeChange = useCallback((e: any) => {
    setVehicleType(e.target.value);
  }, []);

  const handleVehicleTypeSubmit = useCallback(() => {
    if (vehicleType) {
      //接口设置vehicleType的值，再进行后续的booking
      const dataObj = { plateNo: props.basicInfo.plateNo, vehicleType };
      setIsBookingConfirmLoading(true);
      vehicleRecordUpdateApi(dataObj)
        .then((responseJSON: any) => {
          if (responseJSON.code && responseJSON.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(responseJSON.errorMsg);
          } else if (responseJSON?.data) {
            // 通知更新待预约列表数据
            setIsNeedRefreshList(true);
            // 打开预约弹窗，进入预约
            setModalType("doBooking");
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
        })
        .finally(() => {
          setIsBookingConfirmLoading(false);
        });
    }
  }, [props.basicInfo.plateNo, vehicleType]);

  // 处理切换日期
  const handleDateChange = (dateStrVal: string, index: number) => {
    console.log("handleDateChange ======>>>>>", dateStrVal, index);
    setConfirmDateAndTime({ dateStr: dateStrVal, timeStr: "", ruleId: "" });
    setCurrentDateItem(mapCurrentDateItem(dateBookingList[index]));
  };

  // 处理切换时间
  const handleTimeChange = (timeData: any) => {
    console.log("handleTimeChange =======>>>", timeData);
    setCurrentTime(timeData.timeStr);
    setConfirmDateAndTime({ ...confirmDateAndTime, timeStr: timeData.timeStr, ruleId: timeData.ruleId });
  };

  // 拆分时间，区分凌晨/上午/下午/晚上
  const mapCurrentDateItem = (data: any) => {
    const newCurrentDateItem = {
      dateStr: data.dateStr,
      details: {}
    };
    const dataArr1 = [];
    const dataArr2 = [];
    const dataArr3 = [];
    const dataArr4 = [];
    for (let rowItem of data.details) {
      const item = rowItem || {};
      if (parseInt(item.timeStr.split(":")[0], 10) < 6) {
        dataArr1.push(item);
      } else if (parseInt(item.timeStr.split(":")[0], 10) >= 6 && parseInt(item.timeStr.split(":")[0], 10) < 12) {
        dataArr2.push(item);
      } else if (parseInt(item.timeStr.split(":")[0], 10) >= 12 && parseInt(item.timeStr.split(":")[0], 10) < 18) {
        dataArr3.push(item);
      } else {
        dataArr4.push(item);
      }
    }
    newCurrentDateItem.details = {
      dataArr1,
      dataArr2,
      dataArr3,
      dataArr4
    };
    return newCurrentDateItem;
  };

  // 动态生产时间小组件
  const timeDomSet = (itemData: any, index: number) => {
    const isDefaultDateTime =
      currentDateItem.dateStr === props.basicInfo?.dateStr && itemData.timeStr === props.basicInfo?.timeStr;
    const timeStrText = itemData.timeStr ? `${itemData.timeStr.split(":")[0]}:${itemData.timeStr.split(":")[1]}` : "-";
    const disabledItem = (
      <li
        key={`time_${index}`}
        className="hdp-uf hdp-uf-hc hdp-uf-vc datetime-item time datetime-item-disabled"
        role="presentation"
      >
        <div className="hdp-uf hdp-uf-vc hdp-uf-dc ">
          <div>{timeStrText}</div>
          <div>已约满</div>
        </div>
      </li>
    );

    if (isDefaultDateTime || itemData.status !== "OPEN") {
      return disabledItem;
    }

    if (props.userDetailsData?.groups?.includes("DXP_DSC_CN_Transport")) {
      if (isDefaultDateTime) {
        return disabledItem;
      }

      if (itemData.transportColorFlag) {
        return (
          <li
            key={`time_${index}`}
            className={`hdp-uf hdp-uf-hc hdp-uf-vc datetime-item time ${currentTime === itemData.timeStr ? "datetime-item-on" : "datetime-item-transport"
              }`}
            onClick={() => handleTimeChange(itemData)}
            role="presentation"
          >
            <div className="hdp-uf hdp-uf-vc hdp-uf-dc ">
              <div>{timeStrText}</div>
              <div>可预约</div>
            </div>
          </li>
        );
      }
    }

    return (
      <li
        key={`time_${index}_${new Date().getTime()}`}
        className={`hdp-uf hdp-uf-hc hdp-uf-vc datetime-item time ${currentTime === itemData.timeStr ? "datetime-item-on" : ""
          }`}
        onClick={() => handleTimeChange(itemData)}
        role="presentation"
      >
        <div className="hdp-uf hdp-uf-vc hdp-uf-dc ">
          <div>{timeStrText}</div>
          <div>可预约</div>
        </div>
      </li>
    );
  };

  // 仅取消或者关闭预约
  const handleCancel = useCallback(() => {
    setModalType("");
    if (props.doClose) {
      props.doClose(false);
    }
  }, [props]);

  // 关闭预约窗口并更新
  const handleClose = useCallback(
    (isNeedRefresh?: boolean) => {
      setModalType("");
      if (props.doClose) {
        props.doClose(isNeedRefresh || isNeedRefreshList);
      }
    },
    [isNeedRefreshList, props]
  );

  // 取消或者关闭预约--预约成功后
  const handleBookingSuccessClose = useCallback(
    (arg_isRedirect?: boolean) => {
      setModalType("");
      if (props.doClose) {
        props.doClose(isNeedRefreshList, arg_isRedirect || false);
      }
    },
    [isNeedRefreshList, props]
  );

  // 执行预约提交
  const doSubmitBooking = useCallback(() => {
    if (confirmDateAndTime && Object.keys(confirmDateAndTime).length > 0) {
      let dataObj = {};
      if (props?.bookingType === "change") {
        dataObj = {
          id: props.basicInfo.id,
          dateStr: confirmDateAndTime.dateStr,
          timeStr: confirmDateAndTime.timeStr,
          ruleId: confirmDateAndTime.ruleId,
          instruction: "doApmtChange", // doApmtApply:预约操作 / doApmtChange：变更操作 /doApmtCancel：取消操作
          remark: props.changeReason || ""
        };
      } else {
        dataObj = {
          id: props.basicInfo.id,
          dateStr: confirmDateAndTime.dateStr,
          timeStr: confirmDateAndTime.timeStr,
          ruleId: confirmDateAndTime.ruleId,
          instruction: "doApmtApply", // doApmtApply:预约操作 / doApmtChange：变更操作 /doApmtCancel：取消操作
          vipTag: "N",
          remark: ""
        };
      }
      setIsBookingConfirmLoading(true);
      doBookingDateTimeApi(dataObj)
        .then((responseJSON: any) => {
          if (responseJSON?.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(responseJSON.errorMsg);
          } else if (responseJSON?.data) {
            if (props?.bookingType === "change") {
              message.success("预约变更成功。");
              // 关闭窗口并刷新已预约列表
              setIsNeedRefreshList(true);
              handleClose(true);
            } else {
              // 跳转到已预约列表去
              message.success("预约成功。");
              handleBookingSuccessClose(true);
            }
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          message.error("预约失败，请重新选择时间。");
        })
        .finally(() => {
          setIsBookingConfirmLoading(false);
        });
    }
  }, [
    confirmDateAndTime,
    handleBookingSuccessClose,
    handleClose,
    props.basicInfo.id,
    props?.bookingType,
    props.changeReason
  ]);

  // 提交预约
  const handleOk = useCallback(() => {
    const { basicInfo, userPermissionsData } = props;
    if (basicInfo.appointmentMode === "DRIVER" && userPermissionsData["CNExternalUsers"]) {
      message.error("预约方式为司机预约，如需预约请修改预约方式或联系司机预约");
      return;
    }
    if (confirmDateAndTime.dateStr && confirmDateAndTime.timeStr) {
      Modal.confirm({
        title: "信息确认",
        okText: "确认",
        content: (
          <div>
            <div>
              <span>请确认预约时间：</span>
              <span style={{ fontWeight: 600 }}>
                {confirmDateAndTime.dateStr} {confirmDateAndTime.timeStr}
              </span>
            </div>
            <div style={{ paddingTop: "10px", color: "#c27c00" }}>请提前30分钟到场。</div>
          </div>
        ),
        onOk() {
          doSubmitBooking();
        }
      });
    }
  }, [props, confirmDateAndTime.dateStr, confirmDateAndTime.timeStr, doSubmitBooking]);

  useEffect(() => {
    const initData = () => {
      if (["1959", "1254"].includes(props.basicInfo.siteCode) && props.basicInfo.loadingRule === "MG" && !props.basicInfo.vehicleType) {
        // 日照工厂、黄骅工厂 && 谷物油籽业务 && 未设置车型--->需要的打开设置车型弹窗
        setModalType("vehicleType");
      } else {
        setModalType("doBooking");
      }
    };

    initData();
  }, [props]);

  useEffect(() => {
    if (modalType === "doBooking") {
      getBookingDateList();
    }
  }, [getBookingDateList, modalType]);

  return (
    <>
      {/* 根据条件选择车型 */}
      <Modal
        title="请选择车型"
        className="do-select-vertical-type-modal"
        centered
        confirmLoading={isBookingConfirmLoading}
        maskClosable={false}
        visible={modalType === "vehicleType"}
        okText="继续"
        onOk={handleVehicleTypeSubmit}
        onCancel={handleCancel}
        okButtonProps={{ disabled: !vehicleType }}
      >
        <Radio.Group onChange={onVehicleTypeChange} value={vehicleType}>
          <Space direction="vertical">
            <Radio value="非集装箱">非集装箱（板车、低栏车、高栏车）</Radio>
            <Radio value="集装箱">集装箱（集装箱车）</Radio>
          </Space>
        </Radio.Group>
      </Modal>
      {/* 开始操作预约 */}
      <Modal
        title={props.modalTitle}
        className="do-booking-modal"
        confirmLoading={isBookingConfirmLoading}
        width="60%"
        okText={props.bookingType && props.bookingType === "change" ? "确认变更预约" : "确认预约"}
        visible={modalType === "doBooking"}
        okButtonProps={{
          disabled: !confirmDateAndTime.dateStr || !confirmDateAndTime.timeStr
        }}
        maskClosable={false}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="hdp-uf hdp-uf-dc do-booking-content">
          {/* 当前预约的基础信息 */}
          <div className="booking-basic-info">
            <ul className="hdp-uf hdp-uf-ww detail-list">
              <li className="hdp-uf detail-item" style={{ width: "250px" }} key="buyer">
                <div className="title">客户名称</div>
                <div className="value">{props.basicInfo.buyer || "-"}</div>
              </li>
              <li className="hdp-uf detail-item" style={{ width: "250px" }} key="productName">
                <div className="title">产品信息</div>
                <div className="value">{props.basicInfo.productName || "-"}</div>
              </li>
              <li className="hdp-uf detail-item" style={{ width: "250px" }} key="packageSpecificationCn">
                <div className="title">包装规格</div>
                <div className="value">{props.basicInfo.packageSpecificationCn || "-"}</div>
              </li>
              <li className="hdp-uf detail-item" style={{ width: "250px" }} key="plateNo">
                <div className="title">车号</div>
                <div className="value">{props.basicInfo.plateNo || "-"}</div>
              </li>
              <li className="hdp-uf detail-item" style={{ width: "250px" }} key="siteName">
                <div className="title">提货工厂</div>
                <div className="value">{props.basicInfo.siteName || "-"}</div>
              </li>
              {props.basicInfo && props.basicInfo.loadingRule === "SY" && (
                <li className="hdp-uf detail-item" style={{ width: "250px" }} key="totalQuantity">
                  <div className="title">提货总量</div>
                  <div className="value">{`${props.basicInfo.totalQuantity} 吨` || "-"}</div>
                </li>
              )}
            </ul>
          </div>

          <Spin spinning={dateDataLoading}>
            {/* 可预定的时间展示 */}
            <div className="hdp-uf hdp-uf-dc booking-datetime-container">
              <div className="hdp-uf hdp-uf-dc">
                <span style={{ fontSize: "14px" }}>选择预约提货日期</span>
                <ul className="hdp-uf hdp-uf-ww ul-list">
                  {dateBookingList?.map((item, index) => (
                    <li
                      key={`booking_date_${item.dateStr}`}
                      className={`hdp-uf hdp-uf-hc hdp-uf-vc datetime-item ${currentDateItem.dateStr === item.dateStr &&
                        "datetime-item-on"}`}
                      onClick={() => handleDateChange(item.dateStr, index)}
                      role="presentation"
                    >
                      <div>{item.dateStr}</div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="hdp-dash-line"></div>
              {/* 改日期下的具体可预约的时间展示 */}
              {confirmDateAndTime.dateStr && (
                <div className="hdp-uf hdp-uf-dc">
                  <div style={{ fontSize: "14px" }}>选择预约提货时间</div>
                  <div className="hdp-uf date-time-list" style={{ marginTop: "20px" }}>
                    {currentDateItem?.details?.dataArr1.length > 0 && (
                      <div className="hdp-uf hdp-uf-dc time-list-item" style={{ width: "25%" }}>
                        <div style={{ fontSize: "14px" }}>凌晨</div>
                        <ul className="hdp-uf hdp-uf-ww ul-list date-time-morning">
                          {currentDateItem.details.dataArr1.map((item: any, index: number) => timeDomSet(item, index))}
                        </ul>
                      </div>
                    )}
                    {currentDateItem?.details?.dataArr2.length > 0 && (
                      <div className="hdp-uf hdp-uf-dc time-list-item" style={{ width: "25%" }}>
                        <div style={{ fontSize: "14px" }}>上午</div>
                        <ul className="hdp-uf hdp-uf-ww ul-list">
                          {currentDateItem.details.dataArr2.map((item: any, index: number) => timeDomSet(item, index))}
                        </ul>
                      </div>
                    )}
                    {currentDateItem?.details?.dataArr3.length > 0 && (
                      <div className="hdp-uf hdp-uf-dc time-list-item" style={{ width: "25%" }}>
                        <div style={{ fontSize: "14px" }}>下午</div>
                        <ul className="hdp-uf hdp-uf-ww ul-list">
                          {currentDateItem.details.dataArr3.map((item: any, index: number) => timeDomSet(item, index))}
                        </ul>
                      </div>
                    )}
                    {currentDateItem?.details?.dataArr4.length > 0 && (
                      <div className="hdp-uf hdp-uf-dc time-list-item" style={{ width: "25%" }}>
                        <div style={{ fontSize: "14px" }}>夜间</div>
                        <ul className="hdp-uf hdp-uf-ww ul-list">
                          {currentDateItem.details.dataArr4.map((item: any, index: number) => timeDomSet(item, index))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!confirmDateAndTime.dateStr && !dateDataLoading && (
                <div style={{ textAlign: "center", minHeight: 100, lineHeight: "100px", color: "#999" }}>
                  暂未发布时间窗！
                </div>
              )}
            </div>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userDetailsData: state.userDetails,
  userPermissionsData: state.userPermissions
});

export default connect(mapStateToProps, null)(DoBookingModal);
