import { useState, useEffect, useCallback } from "react";
import { Card, PageHeader, Row, Col, message, Modal, Button } from "antd";
import SearchFilter from "./searchFilter";
import TableList from "./tableList";
import BuyerModal from "./buyerModal";
import ResendModal from "./resendModal";
import RefuseModal from "./refuseModal";
import DownloadReport from "@/pages/common/downloadReport";
import { getContractConfirmationList } from "@/api/directSys";
import { notificationList, notificationRead } from "@/api/constant";
import MobileTableOperation from "@/pages/common/mobileTableOperation";
import { isMobileDevice } from "@/utils/common";
import { connect } from "react-redux";
import NotificationTips from "@/pages/common/notificationTips";
import "./index.scss";
import { contractNoDisplay } from "@/utils/formatUtils";
const ContractConfirmation = (props: any) => {
  const { switcher } = props;
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [notificationListData, setNotificationListData] = useState<any[]>([]);
  const [rejectMetadata, setRejectMetadata] = useState({} as any);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(5);
  const [isSelected, setIsSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isNoticeModalVisible, setIsNoticeModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [requestListValue, setRequestListValue] = useState({});

  const getNotificationList = () => {
    const commodityCode = "";
    const siteCode = "";
    const request = {
      limit: 10,
      offset: 0,
      type: "CONTRACT_REJECT_POP",
      commodityCode: commodityCode,
      siteCode: siteCode
    };
    notificationList(request).then(res => {
      const { notifications, metadata } = res.data;
      setNotificationListData((Array.isArray(notifications) ? notifications : []) as any);
      setRejectMetadata(metadata ? metadata : {});
      if (metadata && metadata.unReadCount && metadata.unReadCount > 0) {
        setIsNoticeModalVisible(true);
      }
    });
  };

  const handleOk = useCallback(() => {
    const { businessLine, orgName, customerName } = switcher;
    if (end > rejectMetadata.unReadCount || end === rejectMetadata.unReadCount) {
      const temp = notificationListData;
      temp.forEach((val: any) => {
        const readParam: any = {
          id: val.alertId,
          state: "READ"
        };
        notNeedResApiRequest(notificationRead, readParam);
      });
      const params = {
        type: "NOTIFICATIONCENTER",
        commodityCode: businessLine,
        siteCode: orgName,
        firmName: customerName
      };
      setTimeout(() => {
        notNeedResApiRequest(notificationList, params);
      }, 2000);
      setIsNoticeModalVisible(false);
    } else {
      setStart(start + 5);
      setEnd(end + 5);
    }
  }, [end, notificationListData, rejectMetadata.unReadCount, start, switcher]);

  const notNeedResApiRequest = (ApiName: any, params: any) => {
    ApiName(params)
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      });
  };

  const handleCancel = useCallback(() => {
    setIsNoticeModalVisible(false);
  }, []);

  const onPageChange = (page: number) => {
    setCurrent(page);
    const pager = JSON.parse(JSON.stringify(requestListValue));
    pager.offset = (page - 1) * 10;
    setRequestListValue(pager);
  };

  const paginationOpt: any = {
    showQuickJumper: true,
    total: total,
    pageSize: 10,
    showSizeChanger: false,
    onChange: onPageChange,
    current: current,
    size: "default"
  };

  const getListData = useCallback(() => {
    const values = requestListValue;
    if (values && Object.keys(values).length) {
      setLoading(true);
      getContractConfirmationList(values)
        .then(res => {
          const tableData = res.data.contractList;
          const total = res.data.metadata.totalCount;
          setData(tableData);
          setTotal(total);
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          message.error("获取数据失败，请稍后重试。");
          setData([]);
          setTotal(0);
        })
        .finally(() => {
          setLoading(false);
          setIsSelected(false);
        });
    }
  }, [requestListValue]);

  useEffect(() => {
    document.title = "合同确认";
    getNotificationList();
  }, []);

  useEffect(() => {
    getListData();
  }, [getListData, requestListValue]);

  return (
    <>
      <PageHeader className="page-header" title="合同确认">
        <NotificationTips />
      </PageHeader>
      <Modal
        title="提示"
        width={350}
        maskClosable={false}
        visible={isNoticeModalVisible}
        footer={
          <Button type="primary" onClick={handleOk}>
            确定
          </Button>
        }
        onCancel={handleCancel}
      >
        <div>
          {notificationListData.slice(start, end).map(item => (
            <div key={item.alertId}>{contractNoDisplay(item.alertMsg.slice(0, -5))}</div>
          ))}
        </div>
        <div>请尽快处理</div>
      </Modal>
      <div className="contract-confirmation">
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb" }}
          bodyStyle={{ paddingTop: 0 }}
          title="查询条件"
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
        >
          <SearchFilter setFields={setRequestListValue} setCurrent={setCurrent} setIsSelected={setIsSelected} />
        </Card>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
          bodyStyle={{ paddingTop: 0 }}
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
          title={
            <div>
              <span>合同申请列表</span>
              <div className="report-header">
                {isMobileDevice() ? (
                  <Row gutter={[16, 16]}>
                    <Col>
                      <span className="total">共 {total} 条</span>
                    </Col>
                    <Col>
                      <MobileTableOperation isRotate={true}>
                        <RefuseModal
                          isText={true}
                          fitContent={false}
                          isSelected={isSelected}
                          selectedRow={selectedRow[0] ? selectedRow : []}
                          getListData={getListData}
                        />
                        <ResendModal
                          isText={true}
                          selectedRow={selectedRow[0] ? selectedRow : []}
                          getListData={getListData}
                          isSelected={isSelected}
                        />
                        <BuyerModal
                          isText={true}
                          isSelected={isSelected}
                          selectedRow={selectedRow[0] ? selectedRow : []}
                          getListData={getListData}
                        />
                        <DownloadReport
                          data={{}}
                          text={true}
                          params={{
                            type: "01",
                            total: total,
                            ...requestListValue
                          }}
                          filename="B2BContractReport.xls"
                          fileType="contractsConfirmation"
                        />
                      </MobileTableOperation>
                    </Col>{" "}
                  </Row>
                ) : (
                  <>
                    <span className="total">共 {total} 条</span>
                    <RefuseModal
                      fitContent={true}
                      isSelected={isSelected}
                      selectedRow={selectedRow[0] ? selectedRow : []}
                      getListData={getListData}
                    />
                    <ResendModal
                      selectedRow={selectedRow[0] ? selectedRow : []}
                      isSelected={isSelected}
                      getListData={getListData}
                    />
                    <BuyerModal
                      isSelected={isSelected}
                      selectedRow={selectedRow[0] ? selectedRow : []}
                      getListData={getListData}
                    />
                    <DownloadReport
                      data={{}}
                      params={{
                        type: "01",
                        total: total,
                        ...requestListValue
                      }}
                      filename="B2BContractReport.xls"
                      fileType="contractsConfirmation"
                    />
                  </>
                )}
              </div>
            </div>
          }
        >
          <TableList
            tableData={data}
            total={total}
            paginationOpt={paginationOpt}
            getListData={getListData}
            setIsSelected={setIsSelected}
            setSelectedRow={setSelectedRow}
            loading={loading}
          />
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  userDetails: state.userDetails,
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(ContractConfirmation);
