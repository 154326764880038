import { cutHeaderZero, filterColumnsData } from "@/utils";
import { formatNumberToFixedThousands } from "@/utils/common";
import { Table } from "antd";
import { useCallback, useEffect, useState } from "react";

interface Props {
  tableData: any;
  pagination: object;
  fieldsDesc: any;
  loading: boolean;
}


type NumberTypes = number | null | undefined;

const TableList = (props: Props) => {
  const [colums, setColumns] = useState([]);

  useEffect(() => {
    const sapTableColumns: any = [
      {
        title: "买方",
        dataIndex: "accountLongName",
        key: "accountLongName"
      },
      {
        title: "卖方",
        dataIndex: "orgName",
        key: "orgName"
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status"
      },
      {
        title: "提单头编号",
        dataIndex: "movementOrderNumber",
        key: "movementOrderNumber",
        render: (_: any, record: any) => {
          return record.movementOrderNumber ? cutHeaderZero(record.movementOrderNumber) : ""
        }
      },
      {
        title: "提单行项目编号",
        dataIndex: "movementOrderLoadNumber",
        key: "movementOrderLoadNumber"
      },
      {
        title: "金额（元）",
        dataIndex: "amount",
        key: "amount",
        render: (amount: NumberTypes) => {
          if (amount) {
            return formatNumberToFixedThousands(amount, 2);
          } else {
            return "-";
          }
        }
      },
      {
        title: "计划提货数量",
        dataIndex: "scheduledQuantity",
        key: "scheduledQuantity",
        render: (_:any, record: any) => {
          return record.scheduledQuantity ? formatNumberToFixedThousands(record.scheduledQuantity, 3) : "-";
        }
      },
      {
        title: "实际提货数量",
        dataIndex: "actualQuantity",
        key: "actualQuantity",
        render: (_:any, record: any) => {
          return record.actualQuantity ? formatNumberToFixedThousands(record.actualQuantity, 3) : "-";
        }
      },
      {
        title: "单位",
        dataIndex: "uomCode",
        key: "uomCode"
      },
      {
        title: "单价",
        dataIndex: "price",
        key: "price",
        render: (_: any, record: any) => {
          return record.price || record.price === 0 ? formatNumberToFixedThousands(record.price, 2) : "";
        }
      },
      {
        title: "提单审核日期",
        dataIndex: "movementOrderCreationDate",
        key: "movementOrderCreationDate"
      },
      {
        title: "合同编号",
        dataIndex: "contractNumber",
        key: "contractNumber"
      },
      {
        title: "合同行编号",
        dataIndex: "contractLineItemNumber",
        key: "contractLineItemNumber",
        render: (_:any, record: any) => {
          return record.contractLineItemNumber ? cutHeaderZero(record.contractLineItemNumber) : ""
        }
      },
      {
        title: "车/船号",
        dataIndex: "vehicleNumber",
        key: "vehicleNumber"
      },
      {
        title: "产品名称",
        dataIndex: "commodityName",
        key: "commodityName"
      },
      {
        title: "利润中心",
        dataIndex: "profitCenter",
        key: "profitCenter"
      }
    ];
    // 更新获取table可展示的字段信息 -- 获取可显示的字段
    let data: any = filterColumnsData(sapTableColumns, props.fieldsDesc);
    setColumns(data);
  }, [props]);

  const getRowKey = useCallback((recordItem: any) => {
    return `${recordItem.accountCode}-${recordItem.contractNumber}-${recordItem.contractLineItemNumber}-${recordItem.iguid}`;
  }, []);

  return (
    <div className="bill-usage-detail-table">
      <Table
        columns={[...colums]}
        pagination={props.pagination}
        scroll={{ x: "max-content" }}
        dataSource={props.tableData}
        rowKey={getRowKey}
        loading={props.loading}
        size="small" />
    </div>
  );
};

export default TableList;