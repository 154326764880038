import axios from "axios";
import RequestService from "@/api/request";

const getDateStrRuleConfigApi = (dataObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/getRuleConfig",
    method: "post",
    data: dataObj
  });
};

const getProductQueryApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/sap/productQuery",
    method: "get",
    params: paramsObj
  });
};

const postReverseDownloadExcelApi = (dataObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/reverseAppointment/downloadExcel",
    method: "post",
    data: dataObj,
    responseType: "blob"
  });
};

const uploadFileApi = (dataObj: any, urlStr: string = "", isNeedIdToken = false) => {
  return RequestService({
    url: urlStr,
    method: "post",
    data: dataObj,
    headers: {
      accept: "application/json",
      "content-type": "multipart/form-data",
      idtoken: isNeedIdToken
    }
  });
};

const getBookedDataListApi = (paramsObj: object = {}, dataObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/doAppointmentReportListing",
    method: "post",
    params: paramsObj,
    data: dataObj
  });
};

const downloadDoAppointmentReportApi = (dataObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/doAppointmentReportExcel",
    method: "post",
    data: dataObj,
    responseType: "blob"
  });
};

const downloadDoTimeWindowReportApi = (dataObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/doBookingRulesReportExcel",
    method: "post",
    data: dataObj,
    responseType: "blob"
  });
};

const getSlotBookingListApi = (paramsObj: object = {}, dataObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/doAppointments",
    method: "post",
    params: paramsObj,
    data: dataObj
  });
};

const doBookingDateTimeApi = (dataObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/doAppointments/operation",
    method: "post",
    data: dataObj
  });
};

const vehicleRecordUpdateApi = (dataObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/vehicleRecordUpdate",
    method: "post",
    data: dataObj
  });
};

const getBookingDateListApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/bookingRules/getDetails",
    method: "get",
    params: paramsObj
  });
};

const getAppointmentModeApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/getAppointmentMode",
    method: "get",
    params: paramsObj
  });
};

const getBookingRulesListApi = (paramsObj: object = {}, dataObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/bookingRulesList",
    method: "post",
    params: paramsObj,
    data: dataObj
  });
};

const deleteBookingTimeWindowApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/bookingRules/delete",
    method: "get",
    params: paramsObj
  });
};

const getBookingDateDetailApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/bookingRules/showDetail",
    method: "get",
    params: paramsObj
  });
};

const copyDateRuleApi = (dataObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/bookingRules/copy",
    method: "post",
    data: dataObj
  });
};

const updateDateRuleConfigApi = (dataObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/updateRuleConfig",
    method: "post",
    data: dataObj
  });
};

const timeWindowSaveOrUpdateApi = (dataObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/bookingRules/saveOrUpdate",
    method: "post",
    data: dataObj
  });
};

const bookingRulesCheckApi = (paramsObj: object = {}, dataObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/bookingRules/check",
    method: "post",
    params: paramsObj,
    data: dataObj
  });
};

const getProductsApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/contractsap/sap/products",
    method: "get",
    params: paramsObj,
    cancelToken: axios.CancelToken.source().token
  });
};

const getFirmDetailListApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/contractsap/sap/vagueListCustomer",
    method: "get",
    params: paramsObj,
    cancelToken: axios.CancelToken.source().token
  });
};

const getDeliveryOrdersByGroupNoApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/commodity-movementsap/deliveryOrdersByGroupNo",
    method: "get",
    params: paramsObj
  });
};

const getBanTimeListApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/banTime",
    method: "get",
    params: paramsObj
  });
};

const upDateBanTimeApi = (paramsObj: object = {}, dataObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/banTime/saveOrUpdate",
    method: "post",
    params: paramsObj,
    data: dataObj
  });
};

const deleteBanTimeApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/banTime/delete",
    method: "get",
    params: paramsObj
  });
};

const banTimeDownloadApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/slotbookingsap/banTimeDownload",
    method: "get",
    params: paramsObj,
    responseType: "blob"
  });
};

export {
  getDateStrRuleConfigApi,
  getProductQueryApi,
  getProductsApi,
  postReverseDownloadExcelApi,
  uploadFileApi,
  getBookedDataListApi,
  getSlotBookingListApi,
  downloadDoAppointmentReportApi,
  downloadDoTimeWindowReportApi,
  getFirmDetailListApi,
  getDeliveryOrdersByGroupNoApi,
  doBookingDateTimeApi,
  vehicleRecordUpdateApi,
  getBookingDateListApi,
  getAppointmentModeApi,
  getBookingRulesListApi,
  deleteBookingTimeWindowApi,
  getBookingDateDetailApi,
  copyDateRuleApi,
  updateDateRuleConfigApi,
  timeWindowSaveOrUpdateApi,
  bookingRulesCheckApi,
  getBanTimeListApi,
  upDateBanTimeApi,
  deleteBanTimeApi,
  banTimeDownloadApi
};
