const httpErrorStatus = [
  {
    code: 400,
    message: "Request error.",
    messageCN: "请求错误"
  },
  {
    code: 401,
    message: "Unauthorized, please login.",
    messageCN: "未授权，请登录"
  },
  {
    code: 403,
    message: "Access denied.",
    messageCN: "拒绝访问"
  },
  {
    code: 404,
    message: "Request address error",
    messageCN: "请求地址错误"
  },
  {
    code: 408,
    message: "Request timeout.",
    messageCN: "请求超时"
  },
  {
    code: 500,
    message: "Server internal error.",
    messageCN: "服务器内部错误"
  },
  {
    code: 501,
    message: "Service not implemented.",
    messageCN: "服务未实现"
  },
  {
    code: 502,
    message: "Bad Gateway.",
    messageCN: "网关错误"
  },
  {
    code: 503,
    message: "Service not available.",
    messageCN: "服务不可用"
  },
  {
    code: 504,
    message: "Gateway timeout.",
    messageCN: "网关超时"
  },
  {
    code: 505,
    message: "The HTTP version is not supported.",
    messageCN: "HTTP版本不受支持"
  }
];

export { httpErrorStatus };
