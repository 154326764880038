import { useCallback, useState } from "react";
import { Button, Tooltip, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { downloadFile, isMobileDevice } from "@/utils";
import { documentBatchDownload } from "@/api/deliveryList";
import { getFilename, removeStrQuotes } from "@/utils/formatUtils";

interface Props {
  data: any;
  type: any;
}

const DownloadButton = (props: Props) => {
  const { data, type } = props;
  const [loading, setLoading] = useState(false);

  const isFileUpdated = useCallback(() => {
    let fileUpdated = false;
    switch (type) {
      case "qualityReport":
        fileUpdated = data.qualityDownloadFlag === 4 && !data.qualitySevenDays;
        break;
      case "electronicWeighsheet":
        fileUpdated = data.poundDownloadFlag === 4 && !data.poundSevenDays;
        break;
      default:
        break;
    }
    return fileUpdated;
  }, [data, type]);

  const getActionButton = useCallback(() => {
    if (isMobileDevice()) {
      let actionBtnText = "";
      switch (type) {
        case "qualityReport":
          actionBtnText = "下载质量报告";
          break;
        case "electronicWeighsheet":
          actionBtnText = "下载电子磅单";
          break;
        default:
          break;
      }
      return (
        <>
          <span>{actionBtnText}</span>
          {isFileUpdated() && <span style={{ position: "absolute", top: 0, width: 5, height: 5, backgroundColor: "#b55560", borderRadius: 4 }} />}
        </>
      )
    }
    return (
      <>
        <DownloadOutlined />
        {isFileUpdated() && <span style={{ position: "absolute", top: 0, width: 5, height: 5, backgroundColor: "#b55560", borderRadius: 4 }} />}
      </>
    )
  }, [type, isFileUpdated])

  const enableDownload = useCallback(() => {
    let enableDownload = false;
    switch (type) {
      case "qualityReport":
        enableDownload = [3, 4].includes(data.qualityDownloadFlag);
        break;
      case "electronicWeighsheet":
        enableDownload = [3, 4].includes(data.poundDownloadFlag);
        break;
      default:
        break;
    }
    return enableDownload;
  }, [data, type]);

  const handleDownload = useCallback(() => {
    setLoading(true);
    let reportType = "";
    switch (type) {
      case "qualityReport":
        reportType = "COA";
        break;
      case "electronicWeighsheet":
        reportType = "POUND"
        break;
      default:
        break;
    }
    documentBatchDownload({ doIds: [data.doNumber], type: reportType })
      .then((response: any) => {
        if (response) {
          const filename = getFilename(response.headers);
          downloadFile(response.data, removeStrQuotes(filename));
        } else {
          message.error("下载失败，请稍后重试。");
        }
      })
      .catch((exception: any) => {
        message.error("下载失败，请稍后重试。");
        console.log(exception);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [data, type]);

  return (
    <>
      {
        isMobileDevice() ?
          <Button data-testid="download-btn" key={type} type="text" className="delivery-list-operation-btn" style={{ display: "block" }} disabled={!enableDownload()} onClick={handleDownload}>{getActionButton()}</Button> :
          <Tooltip placement="topLeft" title={isFileUpdated() ? "7日内文件有更新" : null}>
            <Button data-testid="download-btn" key={type} type="text" className="delivery-list-operation-btn" style={{ display: "block" }} disabled={!enableDownload()} onClick={handleDownload} loading={loading} icon={getActionButton()} />
          </Tooltip>
      }
    </>
  );
};

export default DownloadButton;