import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Card, message } from "antd";
import { getPointPriceConfirmationList } from "@/api/directSys";
import DownloadReport from "@/pages/common/downloadReport";
import TableListConfirmed from "./components/tableListConfirmed";
import SearchFilters from "../searchFilters";

const PricePointConfirmed = (props: any) => {
  const { switcher } = props;
  const [dataConfirmed, setDataConfirmed] = useState<any[]>([]);
  const [totalConfirmed, setTotalConfirmed] = useState(0);
  const [current, setCurrent] = useState(1);
  const [loadingConfirmed, setLoadingConfirmed] = useState(false);
  const [requestConfirmedListValue, setRequestConfirmedListValue] = useState({
    limit: 10,
    offset: 0,
    pointStateList: "01,03",
    divisionCode: switcher.divisionCode,
    orgCode: switcher.orgCode,
    basisContractNo: "",
    appliedNo: ""
  });

  useEffect(() => {
    const getConfirmedListData = () => {
      const values = requestConfirmedListValue;
      setLoadingConfirmed(true);
      getPointPriceConfirmationList(values)
        .then(res => {
          if (res?.data?.metadata) {
            const tableData = res.data.pointPriceList || [];
            const total = res.data.metadata.totalCount ?? 0;
            setDataConfirmed(tableData);
            setTotalConfirmed(total);
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          message.error("获取数据失败，请稍后重试。");
          setDataConfirmed([]);
          setTotalConfirmed(0);
        })
        .finally(() => {
          setLoadingConfirmed(false);
        });
    };

    getConfirmedListData();
  }, [requestConfirmedListValue]);

  const onConfirmedPageChange = (page: number) => {
    setCurrent(page);
    const pager = JSON.parse(JSON.stringify(requestConfirmedListValue));
    pager.offset = (page - 1) * 10;
    setRequestConfirmedListValue(pager);
  };

  const paginationOptConfirmed: any = {
    showQuickJumper: true,
    total: totalConfirmed,
    pageSize: 10,
    showSizeChanger: false,
    onChange: onConfirmedPageChange,
    current: current,
    size: "default"
  };

  const onHandleSearch = useCallback(
    (values: any) => {
      const temp = JSON.parse(JSON.stringify(requestConfirmedListValue));
      temp.offset = 0;
      temp.limit = 10;
      temp.divisionCode = switcher.divisionCode;
      temp.orgCode = values.seller;
      temp.basisContractNo = values.basisContractNo;
      temp.appliedNo = values.appliedNo;
      setRequestConfirmedListValue(temp);
      setCurrent(1);
    },
    [requestConfirmedListValue, switcher.divisionCode]
  );

  return (
    <>
      <SearchFilters doSearch={onHandleSearch} />
      <Card
        style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
        bodyStyle={{ paddingTop: 0 }}
        headStyle={{
          backgroundColor: "white",
          fontWeight: 100,
          borderBottom: "none"
        }}
        title={
          <div>
            <div className="report-header">
              <span className="total">共 {totalConfirmed} 条</span>
              <DownloadReport
                data={{}}
                params={{
                  type: "01",
                  total: totalConfirmed,
                  ...requestConfirmedListValue
                }}
                filename="B2BPricingReport.xls"
                fileType="pointPrice"
              />
            </div>
          </div>
        }
      >
        <TableListConfirmed
          tableData={dataConfirmed}
          paginationOpt={paginationOptConfirmed}
          loadingConfirmed={loadingConfirmed}
        />
      </Card>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  userDetails: state.userDetails,
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(PricePointConfirmed);
