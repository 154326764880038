import { Select, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { EditFilled } from "@ant-design/icons";
import { getProductNameInitValue } from "@/utils/formatUtils";

interface Props {
  data: any;
  initValue: string;
  width?: string | number;
  onChange?: Function;
}

const ProductNameSelect = (props: Props) => {
  const { data, width, initValue, onChange } = props;
  const [value, setValue] = useState(initValue);
  const [code, setCode] = useState(data.productCode);
  const [options, setOptions] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    if (data.productCode === code) {
      const product = data.productList?.filter((val: any) => val.productCode === data.productCode);
      const productValue = product.length && getProductNameInitValue(product[0]);
      setValue(productValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.productName]);

  useEffect(() => {
    if (data?.productList?.length > 1) {
      setShowEdit(true);
    } else {
      setShowEdit(false);
    }
  }, [data.productList]);

  const editClick = useCallback(() => {
    setShowInput(true);
  }, []);

  const onfocus = useCallback(() => {
    const newOptions: any = data.productList.map((val: any) => {
      return { label: getProductNameInitValue(val), value: getProductNameInitValue(val), key: val.productCode };
    });
    setOptions(newOptions);
  }, [data.productList]);

  const onblur = useCallback(() => {
    setShowInput(false);
  }, []);

  const handleChange = useCallback(
    (newVal: any) => {
      const item: any = options.find((val: any) => val.value === newVal);
      setValue(item.value);
      setCode(item.key);
      onChange && onChange(item.value, data);
    },
    [data, onChange, options]
  );

  const getInputElement = () => {
    return (
      <Select
        data-testid="product-change-select"
        options={options}
        value={value}
        style={{ width: width ? width : "100%" }}
        autoFocus={true}
        onFocus={onfocus}
        onBlur={onblur}
        onChange={handleChange}
        dropdownMatchSelectWidth={false}
      />
    );
  };

  const getTextElement = () => {
    return !showEdit ? (
      <span>{value || "-"}</span>
    ) : (
      <div className="disabled-input-component" onClick={editClick} role="button" data-testid="edit" tabIndex={0}>
        <span>{value || "-"}</span>
        <Tooltip title="此合同存在备选物料可供选择" className="edit-icon">
          <EditFilled onClick={editClick} style={{ cursor: "pointer" }} />
        </Tooltip>
      </div>
    );
  };

  return <>{!showInput ? getTextElement() : getInputElement()}</>;
};

export default ProductNameSelect;
