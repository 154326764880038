const initialState = { changeFlag: false };

function notificationFlag(state = initialState, action: any) {
  switch (action.type) {
    case "GET_NOTIFICATION_FLAG":
      return {
        ...state
      };
    case "UPDATE_NOTIFICATION_FLAG":
      return {
        ...state,
        ...action.Update(state.changeFlag)
      };
    default:
      return state;
  }
}

export { notificationFlag, initialState };
