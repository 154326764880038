import { useCallback, useState } from "react";
import { Form, Row, Col, Select, Button, Collapse, Divider, Input, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const SearchFilter = (props: any) => {
  const [isClick, setIsClick] = useState(false);
  const [expandCollapseText, setExpandCollapseText] = useState("展开高级筛选");
  const [form] = Form.useForm();
  const [requestListValue] = useState({
    limit: 10,
    offset: 0,
    status: "all",
    name: null,
    group: "DXP_DSC_CN_ExternalUsers",
    controlidentifier: null,
    fromdate: null,
    todate: null,
    sortby: "date",
    sort: "desc"
  });

  const handleReset = useCallback(() => {
    form.resetFields();
    props.setFields(requestListValue);
    props.setCurrent(1);
  }, [form, props, requestListValue]);

  const handelExpandCollapse = useCallback(() => {
    const isExpandClick = () => {
      setIsClick(!isClick);
      setExpandCollapseText(isClick ? "展开高级筛选" : "收起高级筛选");
    };

    isExpandClick();
  }, [isClick]);

  const onFinish = useCallback(
    (values: any) => {
      const temp = JSON.parse(JSON.stringify(requestListValue));
      temp.offset = 0;
      temp.limit = 10;
      temp.status = values.status;
      temp.group = values.group;
      temp.name = values.name;
      temp.customerCode = values.customerCode;
      temp.fromdate = values.createDate ? values.createDate[0].format("YYYY-MM-DD") : null;
      temp.todate = values.createDate ? values.createDate[1].format("YYYY-MM-DD") : null;
      props.setFields(temp);
      props.setCurrent(1);
    },
    [props, requestListValue]
  );

  return (
    <div className="search-filter">
      <div className="base-filter">
        <Form
          form={form}
          name="contract-confirmation-form"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          initialValues={{ status: "all", group: "DXP_DSC_CN_ExternalUsers" }}
        >
          <Row justify="space-between">
            <Col md={5} xs={24}>
              <Form.Item name="status">
                <Select placeholder="请选择用户状态">
                  <Option value="all">全部用户状态</Option>
                  <Option value="ACTIVE">已激活</Option>
                  <Option value="pending">待激活</Option>
                  <Option value="inactive">已失效</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="group">
                <Select placeholder="请选择用户类型">
                  <Option value="DXP_DSC_CN_ExternalUsers">全部用户类型</Option>
                  <Option value="DXP_DSC_Role_CN_Manager">采购经理</Option>
                  <Option value="DXP_DSC_Role_CN_Staff">采购内勤</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="name">
                <Input allowClear style={{ width: "100%" }} placeholder="请输入姓名" />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="customerCode">
                <Input allowClear style={{ width: "100%" }} placeholder="请输入客户代码" />
              </Form.Item>
            </Col>
            <Col md={3} xs={24}>
              <Button type="primary" htmlType="submit" disabled={isClick}>
                <SearchOutlined />
                查询
              </Button>
            </Col>
          </Row>

          <Collapse
            style={{ marginTop: 0 }}
            ghost
            expandIconPosition="right"
            onChange={handelExpandCollapse}
            collapsible="header"
          >
            <Panel header={<span style={{ color: "#007ed9" }}>{expandCollapseText}</span>} key="1">
              <Divider dashed={true} style={{ width: "101%", margin: "-10px 0 13px -14px" }} />
              <div className="expand-collapse">
                <Row gutter={[16, 16]}>
                  <Col md={5} xs={24}>
                    <Form.Item label="创建日期" name="createDate">
                      <RangePicker
                        dropdownClassName="range-picker-drop-down-mobile"
                        style={{ width: "100%" }}
                        placeholder={["请选择", "请选择"]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={5} xs={0}></Col>
                  <Col md={5} xs={0}></Col>
                  <Col style={{ marginLeft: "auto" }} md={5} xs={24}>
                    <Form.Item>
                      <div style={{ marginTop: 30 }} className="align-right advance-btn">
                        <Button
                          data-testid="reset-btn"
                          style={{ marginRight: 10, backgroundColor: "#5c5c5c", width: "fit-content" }}
                          type="primary"
                          onClick={handleReset}
                        >
                          清空
                        </Button>
                        <Button data-testid="submit-btn" type="primary" htmlType="submit">
                          立即查询
                        </Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </div>
  );
};

export default SearchFilter;
