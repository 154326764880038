import { Table } from "antd";
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from "react";

interface Props {
  tableData: any;
  pagination: object;
  loading: boolean;
  onRowSelect: any;
}

export interface TableListHandle {
  selectRowKeys: (selectedRowKeys: any[]) => void;
}
const TableList:ForwardRefRenderFunction<TableListHandle, Props> = (props, ref) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);

    // 使父组件可以调用子组件的方法
    useImperativeHandle(ref, () => {
      return {
        selectRowKeys: (selectedRowKeys: any[]) => {
          setSelectedRowKeys(selectedRowKeys);
        }
      }
    });

    const onSelectChange = (selectedRowKeys: any, rows: any) => {
      setSelectedRowKeys(selectedRowKeys);
      props.onRowSelect(selectedRowKeys, rows);
    };

  const rowSelection = {
    type: "radio" as "radio",
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    fixed: true
  };
  const columns: any = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "卖方",
      dataIndex: "orgName",
      key: "orgName"
    },
    {
      title: "业务类型",
      dataIndex: "businessLine",
      key: "businessLine"
    },
    {
      title: "自动审单开关",
      dataIndex: "active",
      key: "active",
      render: (_:any, record: any) => {
        return record.active ? "开" : "关";
      }
    },
    {
      title: "日期判断依据",
      dataIndex: "accordingTo",
      key: "accordingTo"
    },
  ];
  return (
    <div className="table-list">
      <Table
        columns={columns}
        pagination={props.pagination}
        rowSelection={rowSelection}
        scroll={{ x: "max-content" }}
        dataSource={props.tableData}
        rowKey="id"
        loading={props.loading}
        size="small"
      />
    </div>
  );
};

export default forwardRef(TableList);