import { useState, useCallback } from "react";
import { message, Modal, Select } from "antd";
import "./index.scss";

import { doBookingDateTimeApi } from "@/api/slotbooking";

const { Option } = Select;

// 定义函数组件的props相关参数
type myProps = {
  basicInfo: any;
  doClose: (arg0: any, reasonInfo: any) => void;
  reasonTypeInfo: any;
};

const ReasonModal = (props: myProps) => {
  const [reasonText, setReasonText] = useState("");
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const reasonOptions = [
    { value: "前车未卸完货", label: "前车未卸完货" },
    { value: "货主要求更改计划", label: "货主要求更改计划" },
    { value: "调度计划变化/换车", label: "调度计划变化/换车" },
    { value: "交通拥堵/车辆故障", label: "交通拥堵/车辆故障" },
    { value: "系统操作失误", label: "系统操作失误" },
    { value: "其他原因", label: "其他原因" }
  ];

  const handleReasonChange = useCallback((val: string) => {
    setReasonText(val);
  }, []);

  const handleClose = useCallback(() => {
    setReasonText("");
    props.doClose(false, { ...props.reasonTypeInfo });
  }, [props]);

  const handleOk = useCallback(() => {
    const doHandleCancel = () => {
      const { basicInfo } = props;
      if (reasonText) {
        const dataObjVal = {
          id: basicInfo.id,
          ruleId: basicInfo.ruleId,
          remark: reasonText,
          instruction: "doApmtCancel" // doApmtApply:预约操作 / doApmtChange：变更操作 /doApmtCancel：取消操作
        };
        setIsConfirmLoading(true);
        doBookingDateTimeApi(dataObjVal)
          .then((responseJSON: any) => {
            if (responseJSON.code && responseJSON.code === "99999") {
              // 99999 提示后端的错误信息
              message.error(responseJSON.errorMsg);
            } else if (responseJSON?.data) {
              setReasonText("");
              props.doClose(true, { ...props.reasonTypeInfo });
            }
          })
          .catch((exception: any) => {
            console.log(`Failure: ${exception}`);
          })
          .finally(() => {
            setIsConfirmLoading(false);
          });
      }
    };

    if (props.reasonTypeInfo.type === "change") {
      props.doClose(false, { ...props.reasonTypeInfo, reasonText });
      setReasonText("");
    } else if (props.reasonTypeInfo.type === "cancel") {
      doHandleCancel();
    }
  }, [props, reasonText]);

  return (
    <Modal
      confirmLoading={isConfirmLoading}
      title={`${props.reasonTypeInfo.keyName}确认`}
      className="reason-select-modal"
      okText="确认"
      visible={true}
      maskClosable={false}
      onOk={handleOk}
      onCancel={handleClose}
      okButtonProps={{ disabled: !reasonText }}
    >
      <div className="hdp-uf hdp-uf-dc cancel-booked-content">
        <Select
          data-testid="test-reason-select"
          dropdownClassName="reason-select-modal-select"
          allowClear
          placeholder={`请选择${props.reasonTypeInfo.keyName}原因`}
          style={{ width: 300 }}
          onChange={handleReasonChange}
        >
          {reasonOptions.map(item => (
            <Option value={item.value} key={`reason_${item.value}`}>
              {item.label}
            </Option>
          ))}
        </Select>
        <label>{props.reasonTypeInfo.tips}</label>
      </div>
    </Modal>
  );
};

export default ReasonModal;
