import { useState, useEffect, useCallback } from "react";
import { Table } from "antd";

const TableList = (props: any) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
    setSelectedRowKeys(selectedRowKeys);
    props.setHasSelected(selectedRowKeys.length > 0);
    props.setSelectedRows(selectedRows);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
    getCheckboxProps: (record: any) => {
      return {
        disabled: !(record.eventStatus === null || record.eventStatus === "OF Execution Successful")
      };
    }
  };

  const onPageChange = useCallback(
    (_page: any, _filter: any, sorter: any, extra: any) => {
      console.log(extra);
      if (extra?.action === "sort") {
        props.setSortFlag(sorter);
      }
    },
    [props]
  );

  const returnType = (num: boolean) => {
    if (num === false) {
      return "否";
    }
    if (num === true) {
      return "是";
    }
  };
  const returnTime = (time: string) => {
    let s = time;
    s = s
      .replace(/T/g, " ")
      .replace(/-/g, "-")
      .replace(/\.\d+/, " ");
    return s;
  };

  const columns = [
    {
      title: "LDC单号",
      dataIndex: "dispatchNo",
      key: "dispatchNo"
    },
    {
      title: "LDC类型",
      dataIndex: "ldcType",
      key: "ldcType"
    },
    {
      title: "重量（吨）",
      dataIndex: "dispatchWeigh",
      key: "dispatchWeigh",
      render: (text: any) => <>{text === null ? "-" : text.toFixed(3)}</>
    },
    {
      title: "买方",
      dataIndex: "buyer",
      key: "buyer"
    },
    // {
    //   title: "物料名称",
    //   dataIndex: "materialDesc",
    //   key: "materialDesc"
    // },
    {
      title: "卖方",
      dataIndex: "plant",
      key: "plant"
    },
    {
      title: "合同号",
      dataIndex: "crglContractNo",
      key: "crglContractNo",
      render: (text: any) => (
        <>{text === null ? "-" : text.split("_")[0] + "_" + text.split("_")[1]?.replace(/\b(0+)/gi, "")}</>
      ),
      sorter: true
    },
    {
      title: "提货单号",
      dataIndex: "doId",
      key: "doId"
    },
    {
      title: "产品名称",
      dataIndex: "productName",
      key: "productName",
      render: (text: any) => <>{text === null || text === "" ? "-" : text}</>
    },
    {
      title: "提单重量（吨）",
      dataIndex: "doQuantity",
      key: "doQuantity",
      render: (text: any) => <>{text === null ? "-" : text.toFixed(4)}</>
    },
    {
      title: "同步LDC时间",
      dataIndex: "createTime",
      key: "createTime",
      render: (text: any) => <>{text ? returnTime(text) : "-"}</>
    },
    {
      title: "是否超过提单收货时间48小时",
      dataIndex: "overtimeFlag",
      key: "overtimeFlag",
      render: (text: any) => <>{returnType(text)}</>
    },
    {
      title: "LDC状态",
      dataIndex: "dispatchState",
      key: "dispatchState"
    },
    {
      title: "LDC删除状态",
      dataIndex: "eventStatus",
      key: "eventStatus",
      render: (text: any) => <>{text === null || text === "OF Execution Successful" ? "否" : "是"}</>
    },
    {
      title: "是否直连提单",
      dataIndex: "directOrder",
      key: "directOrder"
    }
  ];

  useEffect(() => setSelectedRowKeys([]), [props.tableData]);

  return (
    <div className="contract-confirmation-table">
      <Table
        columns={columns}
        pagination={props.paginationOpt}
        rowSelection={rowSelection}
        scroll={{ x: "max-content" }}
        dataSource={props.tableData}
        rowKey="id"
        loading={props.loading}
        size="small"
        onChange={onPageChange}
      />
    </div>
  );
};

export default TableList;
