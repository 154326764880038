import { Delete } from "@customerexperience/cgl-dxo-fe-platform";
import { oktaAuth } from "@customerexperience/cgl-dxo-dxp-fe-okta-libs";
import { setLocalStorageItem, getLocalStorageItem } from "./myLocalStorage";
import { deleteCookies } from "./common";

const getToken = () => {
  return oktaAuth.getAccessToken().catch((err: any) => {
    console.log("error", "==> oktaAuth.getAccessToken", `==> there was a problem getAccessToken: ${err}`);
    // 已经无法获取accessToken,就需要手动清理并退出到login页面
    doHandleClearAndLogout();
  });
};

const buildConfig = (host: string | undefined, path = "", interval = 0, retry = 0, timeout = 2000) => {
  return {
    interval,
    retry,
    url: `${host}/${path}`,
    timeout
  };
};

const doLogout = async () => {
  const logOutApiConfig = buildConfig(process.env.REACT_APP_USER_SERVICES, "/users/sessions");
  await Delete(logOutApiConfig, oktaAuth);
  await oktaAuth
    .signOut({
      revokeAccessToken: false
    })
    .catch((err: any) => {
      console.log("error", "==> oktaAuth.signOut", `==> there was a problem logout: ${err}`);
    });
  //清理其他存储数据
  localStorage.clear();
  sessionStorage.clear();
  // 清除刷新session和token的计时器
  clearOktaAuthInterval();
  // 本地 localhost 需要手动清理并退出到login页面
  if (process.env.REACT_APP_ENV_FLG === "local") {
    doHandleClearAndLogout();
  }
};

//  手动清理并退出
const doHandleClearAndLogout = () => {
  const { location } = window;
  const { hostname } = location;
  const oktaCookies = ["okta-token-storage", "dxp-token-storage_idToken", "dxp-token-storage_accessToken"];
  deleteCookies(oktaCookies, hostname);
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = `${window.location.origin}/login`;
};

// 设置cookie
const setCookie = (name: string, value: any, exDays = 1, pathUrl = "/", sameSite = "") => {
  //  expires默认1天，默认path为 '/'
  const d = new Date();
  d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
  const expiresVal = name === "access-flag" ? "expires=null" : `expires=${d.toUTCString()}`;
  const pathVal = `path=${pathUrl}`;
  const sameSiteVal = `SameSite=${sameSite}`;
  // 获取当前协议，得到http或者https
  const protocolVal = window.location.protocol.split(":")[0];
  if (protocolVal === "https") {
    document.cookie = `${name}=${value}; ${pathVal}; ${expiresVal}; ${sameSiteVal}; secure;`;
  } else {
    document.cookie = `${name}=${value}; ${pathVal}; ${expiresVal}; ${sameSiteVal};`;
  }
};

// 更新 accessToken|idToken
const tokensRefresh = () => {
  return oktaAuth.token
    .renewTokens(["accessToken", "idToken"])
    .then((newTokens: any) => {
      console.log("existing === AccessToken|idToken === is now refreshed: ", newTokens);
      if (newTokens && newTokens.accessToken && newTokens.idToken) {
        const newAccessTokenCookie = {
          accessToken: newTokens.accessToken.accessToken,
          scopes: newTokens.accessToken.scopes,
          expiresAt: newTokens.accessToken.expiresAt
        };
        const newIdTokenCookie = {
          idToken: newTokens.idToken.idToken,
          scopes: newTokens.idToken.scopes,
          expiresAt: newTokens.idToken.expiresAt,
          claims: newTokens.idToken.claims
        };
        setLocalStorageItem("localCurrentOktaTokenInfo", JSON.stringify(newTokens));
        const newAccessTokenCookieStr = encodeURI(JSON.stringify(newAccessTokenCookie));
        const newIdTokenCookieStr = encodeURI(JSON.stringify(newIdTokenCookie));
        setCookie("dxp-token-storage_accessToken", newAccessTokenCookieStr, 1, "/", "Lax");
        setCookie("dxp-token-storage_idToken", newIdTokenCookieStr, 1, "/", "Lax");
      }
    })
    .catch((exception: any) => {
      console.log(
        "error",
        "==> oktaAuth.token.renewTokens",
        `==> Auth.token.renewTokens === refresh error: ${exception}`
      );
    });
};

// 刷新okta的第三方session,避免session过期
const sessionRefreshing = async () => {
  console.log("existing session is about to be refreshed: ", oktaAuth.session);
  if (getToken()) {
    return oktaAuth.session
      .refresh()
      .then((session: any) => {
        console.log("existing session is now refreshed: ", session);
        tokensRefresh();
      })
      .catch((err: any) => {
        console.log(
          "error",
          "==> oktaAuth.session.refresh",
          `==> there was a problem refreshing (the user may not have an existing session): ${err}`
        );
      });
  }
  return false;
};

// 自动刷新计时器，当进入页面开始计时，每30(1000*60*30=1800000)分钟刷新一次token
const handleRefreshSession = () => {
  console.log("========== handleRefreshSession, Timer already exists ============ >>", oktaAuth.timer);
  // 页面刷新时默认刷新一次token
  const oktaAuthTimerFlag = getLocalStorageItem("oktaAuthTimerFlag");
  if (oktaAuthTimerFlag) {
    sessionRefreshing();
  }
  // 每30分钟刷新一次token
  if (!oktaAuth.timer) {
    console.log("========== Set tokenTimer setInterval init ============");
    oktaAuth.timer = setInterval(() => {
      console.log("========== tokenTimer setInterval loop ============");
      const preLocalCurrentOktaTokenInfo = getLocalStorageItem("localCurrentOktaTokenInfo");
      if (preLocalCurrentOktaTokenInfo) {
        // 根据上次记录的过期时间来判断是否需要刷新
        const oktaTokenInfoObj = JSON.parse(preLocalCurrentOktaTokenInfo);
        const dateTimeNow = new Date().getTime();
        // 距离过期时间小于5分钟则需要主动更新token
        if (oktaTokenInfoObj.accessToken.expiresAt && oktaTokenInfoObj.accessToken.expiresAt - dateTimeNow < 300000) {
          sessionRefreshing();
        }
      } else {
        // 第一次直接默认登录后27分钟后刷新token
        sessionRefreshing();
      }
    }, 1620000);
    // 存放上次设置timer的时间，防止手动浏览器刷新页面重启timer时间需要重新计算时间
    setLocalStorageItem("oktaAuthTimerFlag", new Date().getTime().toString());
  }
  console.log("========== handleRefreshSession ============ start, Now Timer already exists ===>", oktaAuth.timer);
};

const clearOktaAuthInterval = () => {
  if (oktaAuth.timer) {
    console.log("========== tokenTimer clearInterval ============");
    clearInterval(oktaAuth.timer);
  }
};

export { getToken, doLogout, handleRefreshSession, clearOktaAuthInterval };
