import { useCallback, useState } from "react";
import { Button, Input, message, Modal } from "antd";
import * as type from "../../../../../constant";
import { getDeliveryOrderById } from "@/api/deliveryLetterList";
import { updateDos } from "@/api/deliveryOrder";
interface Props {
  data: any;
  getTable?: any;
  text?: boolean;
  keys?: string;
}

const RefuseButton = (props: Props) => {
  const { data, getTable, text, keys } = props;

  const [visible, setVisible] = useState(false);
  const [approveVisible, setApproveVisible] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [TWVisible, setTWVisible] = useState(false);
  const [doType, setDoType] = useState("");

  const onReasonChange = useCallback((e: any) => {
    setRejectReason(e.target.value);
  }, []);

  // 判断是否勾选通威&其他单
  // kaClient==="TW"为通威单，"WS"为温氏单
  const isShowSelectNotice = (data: any) => {
    if (data.length > 1) {
      let i = 0;
      data.forEach((item: any) => {
        if (item.kaClient === "TW" || item.kaClient === "WS") {
          i += 1;
        }
      });
      if (i === data.length || i === 0) {
        return false;
      }
      return true;
    }
    return false;
  };

  // 判断是否通威/温氏提单拼单=》是否弹出新的提示还是走之前弹窗流程
  // kaClient === "TW"为通威单,"WS"为温氏单
  const isTWWSCan = (data: any, key: string) => {
    let res = false;
    if (data.length) {
      data.forEach((item: any) => {
        if (item.kaClient === key && item.doMerge) {
          res = true;
        }
      });
    }
    return res;
  };

  const isTWReceive = (data: any) => {
    let res = true;
    if (data.length) {
      data.forEach((item: any) => {
        if (item.fullCarpool === "N") {
          res = false;
        }
      });
    }
    return res;
  };

  // 判断是否全部选择
  const isTWIncludeAll = useCallback((data: any, userType: any = "") => {
    if (data.length) {
      const idsStr = data.map((obj: { doId: any }) => obj.doId).join(",");
      const idsStrArr = idsStr.split(",");
      const idsStrAllDoInCarpool = data.map((item: { allDoInCarpool: any }) => item.allDoInCarpool).join(",");
      const idsStrAllDoInCarpoolArr = Array.from(new Set(idsStrAllDoInCarpool.split(",")));
      const isEqual = JSON.stringify(idsStrArr.sort()) === JSON.stringify([...idsStrAllDoInCarpoolArr].sort());
      if (isEqual) {
        return true;
      }
      return userType === "ws" && checkWsCtsAllow(data);
    }
    return false;
  }, []);

  // 拆分 handleReject 函数，降低复杂度 -01
  const rejectScene_T_TW = useCallback(() => {
    if (isTWReceive(data)) {
      const isAll = isTWIncludeAll(data);
      if (isAll) {
        rejectDO(data);
      } else {
        setTWVisible(true);
        setDoType("通威");
      }
    } else {
      message.error("请在获取所拼全部提单后再进行拒绝");
    }
  }, [data, isTWIncludeAll]);
  // 拆分 handleReject 函数，降低复杂度 -02
  const rejectScene_T_WS = useCallback(() => {
    if (isTWReceive(data)) {
      const isAll = isTWIncludeAll(data, "ws");
      if (isAll) {
        rejectDO(data);
      } else {
        setTWVisible(true);
        setDoType("温氏");
      }
    } else {
      message.error("请在获取所拼全部提单后再进行拒绝");
    }
  }, [data, isTWIncludeAll]);
  // 拆分 handleReject 函数，降低复杂度 -02
  const rejectScene_F_TW = useCallback(() => {
    if (isTWReceive(data)) {
      const isAll = isTWIncludeAll(data);
      if (isAll) {
        if (keys === type.APPROVED_DO) {
          setApproveVisible(true);
        } else {
          rejectDO(data);
        }
      } else {
        setTWVisible(true);
        setDoType("通威");
      }
    } else {
      message.error("该提单为通威拼单,请在获取所拼全部提单后再进行拒绝");
    }
  }, [data, isTWIncludeAll, keys]);
  // 拆分 handleReject 函数，降低复杂度 -04
  const rejectScene_F_WS = useCallback(() => {
    if (isTWReceive(data)) {
      const isAll = isTWIncludeAll(data, "ws");
      if (isAll) {
        if (keys === type.APPROVED_DO) {
          setApproveVisible(true);
        } else {
          rejectDO(data);
        }
      } else {
        setTWVisible(true);
        setDoType("温氏");
      }
    } else {
      message.error("该提单为温氏拼单,请在获取所拼全部提单后再进行拒绝");
    }
  }, [data, isTWIncludeAll, keys]);

  // 点击拒绝按钮
  const handleReject = useCallback(() => {
    if (text) {
      if (isTWWSCan(data, "TW")) {
        rejectScene_T_TW();
      } else if (isTWWSCan(data, "WS")) {
        rejectScene_T_WS();
      } else {
        rejectDO(data);
      }
    } else {
      if (isShowSelectNotice(data)) {
        message.error("请勾选同一类型的单，比如：均为通威提单或者均为其它单");
      } else if (isTWWSCan(data, "TW")) {
        rejectScene_F_TW();
      } else if (isTWWSCan(data, "WS")) {
        rejectScene_F_WS();
      } else {
        if (keys === type.APPROVED_DO) {
          setApproveVisible(true);
        } else {
          rejectDO(data);
        }
      }
    }
  }, [data, keys, rejectScene_F_TW, rejectScene_F_WS, rejectScene_T_TW, rejectScene_T_WS, text]);

  // 温氏需要单独逻辑 -- 拒绝｜提交｜审核
  // 嘉吉CTS部分开单成功，部分拒绝时，可进入如下操作---没有全选才进入的逻辑判断
  // 1、允许CE将已拒绝的提单单独进行提交操作
  // 2、允许温氏对已开单的提单发起取消，且CE可进行单独审核
  const checkWsCtsAllow = (data: any) => {
    const isNotAllowArr = data.filter((item: { statusConsistent: boolean }) => item.statusConsistent === false);
    if (isNotAllowArr.length === 0) {
      return true;
    }
    return false;
  };

  // TWSubmit 函数逻辑拆分，减少复杂度 -01
  const doGetDeliveryOrderById = useCallback((doIdVal: string | number) => {
    return getDeliveryOrderById({ doId: doIdVal })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else if (res.data?.deliveryOrder) {
          return res.data.deliveryOrder;
        }
        return false;
      })
      .catch((error: any) => {
        console.log(error);
        return false;
      });
  }, []);

  // TWSubmit 函数逻辑拆分，减少复杂度 -02
  const doUpdateDos = useCallback(
    (tranData: any[]) => {
      setIsLoading(true);
      updateDos(Array.isArray(tranData) ? tranData : [tranData], {
        type: "rejectDo"
      })
        .then((response: any) => {
          if (response.code && response.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(response.errorMsg);
          } else {
            getTable();
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setTWVisible(false);
          setIsLoading(false);
        });
    },
    [getTable]
  );

  // TWSubmit 函数逻辑拆分，减少复杂度 -03
  const doGetUpdateDosParamsData = useCallback(
    (dataArr: any[]) => {
      let resDataArr: any = [];
      dataArr.forEach(async (item: { doId: any }) => {
        const resData = item.doId ? await doGetDeliveryOrderById(item.doId) : false;
        if (resData) {
          resDataArr.push(resData);
        }
      });
      return resDataArr;
    },
    [doGetDeliveryOrderById]
  );

  const TWSubmit = useCallback(() => {
    if (data.length) {
      const idsStrAllDoInCarpool = data.map((item: { allDoInCarpool: any }) => item.allDoInCarpool).join(",");
      const idsStrAllDoInCarpoolArr = Array.from(new Set(idsStrAllDoInCarpool?.split(",")));
      const dataArr: any = [];
      idsStrAllDoInCarpoolArr?.forEach(obj => {
        if (obj) {
          dataArr.push({ doId: obj });
        }
      });

      let tranData: any = [];
      const len = dataArr.length;
      if (len > 0) {
        tranData = doGetUpdateDosParamsData(dataArr);
      }
      if (tranData.length > 0 && tranData.length === len) {
        doUpdateDos(tranData);
      }
    }
  }, [data, doUpdateDos, doGetUpdateDosParamsData]);

  const rejectDO = (data: any) => {
    let doData = [];
    if (Object.prototype.toString.call(data) === "[object Object]") doData = [data];
    if (Object.prototype.toString.call(data) === "[object Array]") doData = data;
    const isExistWrongData = doData.some((item: any) => item.doStatus === "待取消" && data.kaClient === "TW");
    if (isExistWrongData) {
      message.error("通威发起撤回提单，请联系通威业务");
      return;
    }
    setVisible(true);
  };

  const onOk = useCallback(() => {
    const doType = data.doStatus === type.CANCELLING ? type.REJECT_CANCEL_DO : type.REJECT_DO;
    const temp = data.map((val: any) => {
      val.rejectReason = rejectReason;
      val.rejectReasonCode = "CE01";
      return val;
    });
    setIsLoading(true);
    updateDos(temp, {
      type: doType
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          getTable();
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setRejectReason("");
        setVisible(false);
        setApproveVisible(false);
        setIsLoading(false);
      });
  }, [data, getTable, rejectReason]);

  const onApproveOk = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setRejectReason("");
    setVisible(false);
    setApproveVisible(false);
  }, []);

  const onHandleTWVisibleClose = useCallback(() => {
    setTWVisible(false);
  }, []);

  const onHandleApproveVisibleClose = useCallback(() => {
    setApproveVisible(false);
  }, []);

  return (
    <>
      <Button type={text ? "text" : "default"} onClick={handleReject} disabled={data.length === 0}>
        拒绝
      </Button>
      {/* 拒绝modal */}
      <Modal
        title="拒绝原因"
        visible={visible}
        onCancel={handleCancel}
        onOk={onOk}
        footer={
          <>
            <Button onClick={handleCancel}>取消</Button>
            <Button data-testid="submit-btn" loading={loading} type="primary" onClick={onOk}>
              确定
            </Button>
          </>
        }
        centered
        maskClosable={false}
      >
        <div className="title">拒绝原因（选填）</div>
        <Input.TextArea value={rejectReason} onChange={onReasonChange} />
      </Modal>
      {/* 通威拒绝modal */}
      <Modal
        visible={TWVisible}
        onCancel={onHandleTWVisibleClose}
        onOk={onOk}
        footer={
          <>
            <Button onClick={onHandleTWVisibleClose}>放弃</Button>
            <Button data-testid="submit-btn" loading={loading} type="primary" onClick={TWSubmit}>
              拒绝全部提单
            </Button>
          </>
        }
        centered
        maskClosable={false}
      >
        <div className="title">本{`${doType}`}提单为拼单，是否要拒绝所拼全部提单</div>
      </Modal>
      {/* 待审批的modal */}
      <Modal
        title="确认批量拒绝?"
        visible={approveVisible}
        onCancel={onHandleApproveVisibleClose}
        onOk={onApproveOk}
        centered
        maskClosable={false}
      >
        如需录入拒绝原因，请逐条编辑拒绝原因。
      </Modal>
    </>
  );
};

export default RefuseButton;
