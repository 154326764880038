import { useCallback, useEffect, useState, useRef } from "react";
import {
  Select,
  Input,
  Button,
  Form,
  Row,
  Col,
  Collapse,
  Divider,
  DatePicker,
  AutoComplete,
  Spin,
  message
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  allBusinessLine,
  allCloseStatus,
  allSupplier,
  // contractType,
  customerContractType,
  allOrgCode,
  allDivisionCode,
  allCustomerPricingStatus
} from "@/utils/constant";
import "./index.scss";
import { getProductDetailList, getSapFirmDetailsList } from "@/api/contractReport";
import { getCode, noRepeat } from "@/utils/common";
import RemoteSelect from "@/pages/common/remoteSelect";

const { Panel } = Collapse;

const SearchFilter = (props: any) => {
  const { getSearchValue, switcher, permission, isGroupUser, showBuyer, buyers, resetContent } = props;

  const [form] = Form.useForm();
  const [isClick, setIsClick] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [newOptions, setNewOptions] = useState([]);
  const [customerCodes, setCustomerCodes] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [expandCollapseText, setExpandCollapseText] = useState("展开高级筛选");
  const [searchValue, setSearchValue] = useState({
    contractCompletionFlag: "N",
    orgCode: "",
    divisionCode: "",
    customerCode: "",
    productCode: ""
  });
  // 上一个resetContent的值
  const preRestContent = useRef(false);

  const getNewOptions = useCallback(() => {
    let buyer = searchValue.customerCode;
    if (buyer) {
      const firmNamesList = searchValue.customerCode.trim().split(",");
      if (firmNamesList.length > 1) {
        buyer = "";
      }
    }
    setIsSearchLoading(true);
    setNewOptions([]);
    if (permission.CNExternalUsers) {
      if (searchValue.customerCode !== switcher.customerCode) {
        if (searchValue.customerCode === "全部买方") {
          setNewOptions([]);
        } else {
          const newData = buyers.filter((val: any) => {
            return val.label.includes(buyer);
          });
          setNewOptions(newData);
        }
      } else setNewOptions(buyers);
    }
    setIsSearchLoading(false);
  }, [buyers, permission.CNExternalUsers, searchValue.customerCode, switcher.customerCode]);

  useEffect(() => {
    getNewOptions();
  }, [getNewOptions]);

  useEffect(() => {
    let value: any = searchValue;
    if (resetContent !== preRestContent.current) {
      form.resetFields();
      value = { contractCompletionFlag: "N", orgCode: "", divisionCode: "", customerCode: "" };
      preRestContent.current = resetContent;
    }
    if (permission.CNInternalUsers) {
      value.orgCode = switcher.orgCode === "All" ? "" : switcher.orgCode;
      value.divisionCode = switcher.divisionCode;
      value.customerCode = "";
      form.setFieldsValue({
        customerCode: switcher.customerCode,
        orgCode: switcher.orgCode,
        divisionCode: switcher.divisionCode
      });
    } else if (permission.CNExternalUsers) {
      // 外部用户
      value.orgCode = switcher.orgCode;
      value.divisionCode = switcher.divisionCode;
      const customerCodes = switcher.customerCode?.split(",") || [];
      setCustomerCodes(customerCodes);
      value.customerCode = switcher.customerCode;
      form.setFieldsValue({
        customerCode: customerCodes.length > 1 ? "" : `${switcher.customerName}(${switcher.customerCode})`,
        orgCode: switcher.orgCode === allOrgCode ? "All" : switcher.orgCode,
        divisionCode: switcher.divisionCode === allDivisionCode ? "All" : switcher.divisionCode
      });
    }

    getSearchValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, switcher.orgCode, switcher.divisionCode, switcher.customerCode, resetContent]);

  const handelExpandCollapse = useCallback(() => {
    setIsClick(!isClick);
    setIsExpand(!isExpand);
    setExpandCollapseText(isExpand ? "展开高级筛选" : "收起高级筛选");
  }, [isClick, isExpand]);

  // 内部用户--获取买方选项(防抖输入查询)
  const fetchCustomerOptions_Internal = useCallback(
    (value: string, callback: Function, currentValue: string | undefined) => {
      setIsSearchLoading(true);
      getSapFirmDetailsList({
        orgCode: searchValue.orgCode || "",
        divisionCode: searchValue.divisionCode || "",
        customerName: value || ""
      })
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            callback([]);
            message.error(res.errorMsg);
          } else if (res.data?.customerList?.length > 0 && currentValue === value) {
            let data: any[] = res.data?.customerList?.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName + "(" + val.customerCode + ")",
                key: val.customerCode
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSearchLoading(false);
        });
    },
    [searchValue.divisionCode, searchValue.orgCode]
  );

  // 获取产品名称选项
  const fetchProductNameOptions = useCallback(
    (value: string, callback: Function, currentValue: string | undefined) => {
      setIsSearchLoading(true);
      getProductDetailList({
        salesOrgs: searchValue.orgCode || "",
        productName: value || ""
      })
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(res.errorMsg);
            callback([]);
          } else if (res.data?.products && currentValue === value) {
            let products: any = [];
            if (res.data.products?.length === 0) {
              message.error("No Results Found");
            }
            if (permission.CNExternalUsers) {
              products = res.data.products.map((val: any) => {
                return {
                  label: val.productName,
                  value: val.productName,
                  key: val.productCode
                };
              });
              products = noRepeat(products);
            } else if (permission.CNInternalUsers) {
              products = res.data.products.map((item: any) => {
                return {
                  label: `${item.productName}(${item.productCode.replace(/\b(0+)/gi, "")})`,
                  value: `${item.productName}(${item.productCode.replace(/\b(0+)/gi, "")})`,
                  key: item.productCode.replace(/\b(0+)/gi, "")
                };
              });
            }
            callback(products);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSearchLoading(false);
        });
    },
    [permission.CNExternalUsers, permission.CNInternalUsers, searchValue.orgCode]
  );

  // 查询
  const onFinish = useCallback(
    (values: any) => {
      let temp = { ...values };
      if (permission.CNInternalUsers) {
        if (temp.divisionCode === "All") {
          temp.divisionCode = "";
        }
        if (temp.orgCode === "All") {
          temp.orgCode = "";
        }
        if (temp.productCode) {
          temp.productCode = getCode(temp.productCode);
        }
      }
      temp.executionStartDate = temp.executionStartDate?.format("YYYY-MM-DD") ?? "";
      temp.executionEndDate = temp.executionEndDate?.format("YYYY-MM-DD") ?? "";
      temp.signedDate = temp.signedDate?.format("YYYY-MM-DD") ?? "";

      temp.customerContractType = "";
      if (values?.customerContractType?.length > 0) {
        temp.customerContractType = values.customerContractType.join(",");
      }

      if (temp.customerCode && temp.customerCode !== "全部买方") {
        temp.customerCode = getCode(temp.customerCode);
      } else {
        temp.customerCode = switcher.customerCode || "";
      }

      setSearchValue(temp);
      getSearchValue({ ...temp });
    },
    [getSearchValue, permission.CNInternalUsers, switcher.customerCode]
  );

  const formValueChange = useCallback(
    (changeValue: any, allValues: any) => {
      if (changeValue.dce !== undefined) {
        const value = changeValue.dce.replace(/[^\-?\d.]/g, "");
        form.setFieldsValue({ dce: value });
        allValues.dce = value;
      }
      setSearchValue(allValues);
    },
    [form]
  );

  // 清空
  const resetSearch = useCallback(() => {
    form.resetFields();
    let value = { contractCompletionFlag: "N", orgCode: "", divisionCode: "", customerCode: "" };
    value.orgCode = switcher.orgCode;
    value.divisionCode = switcher.divisionCode;
    value.customerCode = switcher.customerCode || null;
    onFinish(value);
    if (permission.CNExternalUsers) {
      form.setFieldsValue({
        orgCode: switcher.orgCode === allOrgCode ? "All" : switcher.orgCode,
        divisionCode: switcher.divisionCode === allDivisionCode ? "All" : switcher.divisionCode,
        customerCode: customerCodes.length > 1 ? null : `${switcher.customerName}(${switcher.customerCode})`
      });
    } else {
      form.setFieldsValue(Object.assign(value, { customerCode: null }));
    }
  }, [
    customerCodes.length,
    form,
    onFinish,
    permission.CNExternalUsers,
    switcher.customerCode,
    switcher.customerName,
    switcher.divisionCode,
    switcher.orgCode
  ]);

  return (
    <div className="search-filter">
      <div className="base-filter">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          onValuesChange={formValueChange}
          initialValues={{
            divisionCode: "",
            orgCode: "",
            contractCompletionFlag: "N"
          }}
        >
          <Row className="flex-row-class" gutter={{ xs: 8, md: 16 }}>
            <Col xs={24}>
              <Form.Item name="divisionCode">
                <Select style={{ width: "100%" }} placeholder="请选择业务线" options={allBusinessLine()} />
              </Form.Item>
            </Col>
            {/* 非集团外部用户买方输入框 */}
            {!!buyers.length && !isGroupUser && showBuyer && (
              <Col xs={24}>
                <Form.Item name="customerCode">
                  <Select options={buyers} disabled={customerCodes.length <= 1} />
                </Form.Item>
              </Col>
            )}
            {/* 外部用户且为集团账户的买方输入框,当前不用判断是否为饲料厂用户 */}
            {permission.CNExternalUsers && isGroupUser && (
              <Col xs={24}>
                <Form.Item name="customerCode">
                  <AutoComplete
                    onFocus={getNewOptions}
                    notFoundContent={isSearchLoading ? <Spin size="small" /> : null}
                    options={
                      customerCodes.length <= 1
                        ? [
                          {
                            label: `${switcher.customerName}(${switcher.customerCode})`,
                            value: `${switcher.customerName}(${switcher.customerCode})`
                          }
                        ]
                        : newOptions
                    }
                    placeholder="请输入买方"
                    value={searchValue.customerCode}
                    disabled={customerCodes.length <= 1}
                  />
                </Form.Item>
              </Col>
            )}
            {/* 内部用户买方输入框 */}
            {permission.CNInternalUsers && showBuyer && (
              <Col xs={24}>
                <Form.Item name="customerCode">
                  <RemoteSelect
                    dataTestId="customerCode"
                    placeholder="请输入公司名称查询"
                    fetchOptions={fetchCustomerOptions_Internal}
                    loading={isSearchLoading}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={24}>
              <Form.Item name="orgCode">
                <Select style={{ width: "100%" }} placeholder="请选择卖方" options={allSupplier()} />
              </Form.Item>
            </Col>
            {/* <Col xs={24}>
              <Form.Item name="contractType">
                <Select
                  data-testid="contractType"
                  placeholder="请选择合同类型"
                  mode="multiple"
                  maxTagCount={"responsive" as const}
                  options={contractType}
                />
              </Form.Item>
            </Col> */}
            <Col xs={24}>
              <Form.Item name="customerContractType">
                <Select
                  data-testid="customerContractType"
                  placeholder="请选择合同类型"
                  mode="multiple"
                  maxTagCount={"responsive" as const}
                  options={customerContractType}
                />
              </Form.Item>
            </Col>
            <Col className="flex-row-class-btn" xs={24}>
              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  className="base-submit-btn"
                  data-testid="base-submit"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                  disabled={isClick}
                >
                  查询
                </Button>
              </Form.Item>
            </Col>
          </Row>
          {/* 展开筛选 */}
          <Collapse
            style={{ marginTop: -25 }}
            ghost
            expandIconPosition="right"
            onChange={handelExpandCollapse}
            collapsible="header"
          >
            <Panel header={<span style={{ color: "#007ed9" }}>{expandCollapseText}</span>} key="1" forceRender={true}>
              <Divider dashed={true} style={{ width: "101%", margin: "-10px 0 13px -14px" }} />
              <div className="expand-collapse">
                <Row gutter={{ xs: 8, md: 16 }}>
                  <Col md={6} xs={24}>
                    <Form.Item label="合同编号" name="contractNo">
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item label="产品名称" name="productCode">
                      <RemoteSelect
                        dataTestId="productCode"
                        placeholder="请输入产品名称查询"
                        fetchOptions={fetchProductNameOptions}
                        loading={isSearchLoading}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item label="签订日期" name="signedDate">
                      <DatePicker style={{ width: "100%" }} placeholder="请选择" />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="合约月份"
                      name="dce"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (value && value?.length !== 6) {
                              return Promise.reject(new Error("合约月份必须为六位数字！"));
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, md: 16 }}>
                  <Col md={5} xs={24}>
                    <Form.Item label="提货开始日期" name="executionStartDate">
                      <DatePicker style={{ width: "100%" }} placeholder="请选择" />
                    </Form.Item>
                  </Col>
                  <Col md={5} xs={24}>
                    <Form.Item label="提货截止日期" name="executionEndDate">
                      <DatePicker style={{ width: "100%" }} placeholder="请选择" />
                    </Form.Item>
                  </Col>
                  <Col md={5} xs={24}>
                    <Form.Item label="合同关闭状态" name="contractCompletionFlag">
                      <Select placeholder="请选择关闭状态" options={allCloseStatus} />
                    </Form.Item>
                  </Col>
                  <Col md={5} xs={24}>
                    <Form.Item label="结价状态" name="customerPricingStatus">
                      <Select placeholder="请选择结价状态" options={allCustomerPricingStatus} />
                    </Form.Item>
                  </Col>
                  <Col className="advance-btn-col">
                    <Form.Item>
                      <div className="advance-btn">
                        <Button
                          data-testid="reset-btn"
                          style={{ marginRight: 10, backgroundColor: "#5c5c5c" }}
                          type="primary"
                          onClick={resetSearch}
                        >
                          清空
                        </Button>
                        <Button type="primary" htmlType="submit">
                          立即查询
                        </Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </div>
  );
};

export default SearchFilter;
