import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Select, Input, Button, PageHeader, Spin, message } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { allSupplier, allBusinessLine } from "@/utils/constant";
import { vagueListCustomer } from "@/api/directSys";
import { upDateContract } from "@/api/systemSetting";
import NotificationTips from "@/pages/common/notificationTips";
import RemoteSelect from "@/pages/common/remoteSelect";
import "./index.scss";

const ContractUpdate = (props: any) => {
  const { switcher } = props;
  const [form] = Form.useForm();
  const [isShowResult, setIsShowResult] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [isSelectLoading, setIsSelectLoading] = useState(false);

  // 内部用户--获取买方可选项 (节流输入查询)
  const fetchCustomerOptions = useCallback(
    (value: string, callback: Function, currentValue: string | undefined) => {
      setIsSelectLoading(true);
      vagueListCustomer({
        orgCode: form.getFieldValue("orgCode") || "",
        divisionCode: form.getFieldValue("divisionCode") || "",
        customerName: value || ""
      })
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            callback([]);
            message.error(res.errorMsg);
          } else if (res.data?.customerList?.length > 0 && currentValue === value) {
            let data: any[] = res.data?.customerList?.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerCode,
                key: val.customerCode
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSelectLoading(false);
        });
    },
    [form]
  );

  // 查询
  const onFinish = useCallback((values: any) => {
    setSpinning(true);
    const request = {
      divisionCode: values.divisionCode,
      orgCode: values.orgCode,
      customerCode: values.customerCode,
      contractNo: values.contractNo,
      typeFlag: false
    };
    upDateContract(request)
      .then(res => {
        setIsShowResult(true);
        if (res?.data?.length > 0) {
          setIsSucceed(true);
        } else {
          setIsSucceed(false);
          message.error("操作失败，请检查输入信息是否匹配！");
        }
      })
      .catch(err => {
        message.error("操作失败，请检查输入信息是否匹配！");
        setIsShowResult(true);
        setIsSucceed(false);
      })
      .finally(() => setSpinning(false));
  }, []);

  useEffect(() => {
    document.title = "合同缓存量刷新";
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      orgCode: switcher?.orgCode && switcher?.orgCode !== "All" ? switcher.orgCode : null,
      divisionCode: switcher?.divisionCode && switcher?.divisionCode !== "All" ? switcher.divisionCode : null
    });
  }, [form, switcher.divisionCode, switcher.orgCode]);

  return (
    <>
      <div className="contract-update">
        <Spin spinning={spinning}>
          <PageHeader className="page-header-cyrus" title="合同缓存量刷新" style={{ padding: "16px 0px" }}>
            <NotificationTips />
          </PageHeader>
          <Form
            name="contractUpdate"
            layout="vertical"
            autoComplete="off"
            form={form}
            onFinish={onFinish}
            initialValues={{
              orgCode: switcher?.orgCode && switcher?.orgCode !== "All" ? switcher.orgCode : null,
              divisionCode: switcher?.divisionCode && switcher?.divisionCode !== "All" ? switcher.divisionCode : null,
              customerCode: null,
              contractNo: ""
            }}
          >
            <Row justify="space-between">
              <Col md={5} xs={24}>
                <Form.Item name="orgCode" label="卖方" rules={[{ required: true }]}>
                  <Select data-testid="test-orgCode" placeholder="请选择卖方" options={allSupplier(["All"])} />
                </Form.Item>
              </Col>
              <Col md={5} xs={24}>
                <Form.Item name="divisionCode" label="业务线" rules={[{ required: true }]}>
                  <Select data-testid="test-divisionCode" placeholder="请选择业务线" options={allBusinessLine(["All"])} />
                </Form.Item>
              </Col>
              <Col md={5} xs={24}>
                <Form.Item label="买方" name="customerCode" rules={[{ required: true }]}>
                  <RemoteSelect
                    dataTestId="test-customerCode"
                    placeholder="请输入买方查询并选择"
                    fetchOptions={fetchCustomerOptions}
                    loading={isSelectLoading}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col md={5} xs={24}>
                <Form.Item name="contractNo" label="合同编号" rules={[{ required: true }]}>
                  <Input data-testid="test-input" allowClear placeholder="请输入合同编号" />
                </Form.Item>
              </Col>
              <Col md={3} xs={24}>
                <Form.Item>
                  <Button type="primary" data-testid="submit-btn" htmlType="submit" style={{ marginTop: 30 }}>
                    刷新
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <br />

          <div className="content">
            {isShowResult && isSucceed && (
              <div className="result" style={{ fontSize: 12 }}>
                <ExclamationCircleFilled style={{ color: "#007681", marginRight: 8 }} />
                操作成功
              </div>
            )}
            {isShowResult && !isSucceed && (
              <div className="result" style={{ fontSize: 12 }}>
                <ExclamationCircleFilled style={{ color: "#cd0d15", marginRight: 8 }} />
                操作失败
              </div>
            )}
            {!isShowResult && (
              <div className="update-tips">
                <span>请输入以上信息后操作。</span>
              </div>
            )}
          </div>
        </Spin>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(ContractUpdate);
