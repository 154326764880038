import React, { Component } from "react";
import { Modal } from "antd";
import { doLogout, getLocalStorageItem } from "@/utils";
import { MenuFoldOutlined, MenuUnfoldOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { MdExitToApp } from "react-icons/md";
import "./cg-bread-crumb.scss";
import ChangeBusinessLine from "./changeBusinessLine";
import Notification from "./notification";
import { isMobileDevice } from "@/utils/common";

const { confirm } = Modal;
interface Props {
  collapsedStatus: boolean;
  toggleCollapsed: Function;
}
interface State {
  hasScrolled: boolean;
  isShowSwitcher: boolean;
  isShowNoticeCenter: boolean;
}
class CgBreadCrumb extends Component<Props, State> {
  public state = {
    hasScrolled: false,
    isShowSwitcher: false,
    isShowNoticeCenter: false
  };

  toggle = () => {
    const { toggleCollapsed } = this.props;
    toggleCollapsed();
  };

  componentDidMount() {
    this.isShowNoticeCenterAndSwitcher();
  }

  isShowNoticeCenterAndSwitcher = () => {
    // 内部 Admin 无权限显示通知和switcher
    let localUserProfile = getLocalStorageItem("localUserDetailsData");
    if (localUserProfile) {
      localUserProfile = JSON.parse(localUserProfile);
      if (!localUserProfile.groups?.includes("DXP_DSC_Role_CN_Cargill_Admin")) {
        this.setState({
          isShowNoticeCenter: true,
          isShowSwitcher: true
        });
      }
    }
  };

  showConfirm = () => {
    confirm({
      title: "确认退出当前登录账户?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      cancelText: "取消",
      okText: "确认",
      onOk: () => {
        doLogout();
      }
    });
  };

  public render() {
    const { collapsedStatus } = this.props;
    const { isShowNoticeCenter, isShowSwitcher } = this.state;

    return (
      <div className={`cg-bread-crumb ${collapsedStatus ? "cg-bread-crumb-collapsedstatus-f" : ""}`}>
        <div className="hdp-uf hdp-uf-vc hdp-uf-hsb cg-bread-crumb-content">
          {!isMobileDevice() && (
            <div className="crumb-left hdp-uf hdp-uf-vc">
              <div data-testid="toggle-collapsed" className="hdp-uf hdp-uf-vc">
                {React.createElement(collapsedStatus ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: "trigger",
                  onClick: this.toggle
                })}
              </div>
            </div>
          )}
          <div className="crumb-right hdp-uf hdp-uf-hsb">
            {isShowSwitcher && (
              <div className="hdp-uf hdp-uf-vc hdp-uf-hc option-box">
                <div className="crumb-text">
                  <ChangeBusinessLine />
                </div>
              </div>
            )}
            {isShowNoticeCenter && <Notification />}
            <div
              data-testid="logout-action"
              className="hdp-uf hdp-uf-vc hdp-uf-hc option-box logout-action"
              onClick={this.showConfirm}
            >
              <div className="crumb-text">退出</div>
              <MdExitToApp size="20" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CgBreadCrumb;
