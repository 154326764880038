import { useState, useEffect, useCallback } from "react";
import { Card, Form, Row, Col, DatePicker, Input, Button, Table, ConfigProvider, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import BuyerModal from "../buyerModal";
import RevertNotificationButton from "../revertNotificationButton";
import { getProductionSettingList } from "@/api/systemSetting";
import DownloadNotice from "../../downloadNotice";
const { RangePicker } = DatePicker;

const ContractRecord = () => {
  const [form] = Form.useForm();
  const [parent, setParent] = useState(false);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState<any[]>([]);
  const [isSearch, setIsSearch] = useState(false);
  const [isInput, setIsInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestListValue, setRequestListValue] = useState({
    limit: 10,
    offset: 0,
    noticeType: 3,
    sort: "submitDate",
    isSubmit: 1,
    startDate: "",
    endDate: "",
    isAssignBuyer: 1,
    buyerName: ""
  });
  const onPageChange = (page: number) => {
    setCurrent(page);
    const pager = JSON.parse(JSON.stringify(requestListValue));
    pager.offset = (page - 1) * 10;
    setRequestListValue(pager);
  };
  const paginationOpt: any = {
    showQuickJumper: true,
    total: total,
    pageSize: 10,
    showSizeChanger: false,
    onChange: onPageChange,
    current: current,
    size: "default"
  };

  const getListData = useCallback(() => {
    const values = requestListValue;
    getProductionSettingList(values).then(res => {
      if (res.data.notices.notifications) {
        const tableData = res.data.notices.notifications;
        const total = res.data.notices.metaData.totalCount;
        setData(tableData);
        setTotal(total);
        setLoading(false);
      } else {
        setData([]);
        setTotal(0);
        setLoading(false);
      }
    });
  }, [requestListValue]);

  const onFinish = useCallback(
    (values: any) => {
      setIsSearch(true);
      setLoading(true);
      const temp = JSON.parse(JSON.stringify(requestListValue));
      temp.startDate = values.timeRange ? values.timeRange[0].format("YYYY-MM-DD") : "";
      temp.endDate = values.timeRange ? values.timeRange[1].format("YYYY-MM-DD") : "";
      temp.offset = 0;
      temp.limit = 10;
      temp.buyerName = values.buyerName;
      setRequestListValue(temp);
      setCurrent(1);
    },
    [requestListValue]
  );

  const returnNotificationType = (num: any) => {
    switch (num) {
      case "401":
        return "过期未付款";
      case "402":
        return "账款即将到期";
      case "403":
        return "未付履约保证金/定金";
      case "404":
        return "追加保证金";
      default:
        break;
    }
  };

  const onInputChange = useCallback(() => {
    if (form.getFieldValue("buyerName")) {
      setIsInput(true);
    } else {
      setIsInput(false);
    }
  }, [form]);

  //自定义的空状态
  const customizeRenderEmpty = useCallback(
    () => (
      <div style={{ textAlign: "center" }}>
        <br />
        <p>{isSearch ? "没有符合条件的通知！" : "请输入指定买方以查看公告及通知记录"}</p>
        <br />
      </div>
    ),
    [isSearch]
  );

  useEffect(() => {
    if (isSearch) {
      getListData();
    }
  }, [requestListValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const returnType = (num: string) => {
    if (num === "0") {
      return "-";
    }
    if (num === "1") {
      return "已撤回";
    }
  };

  const columns: any = [
    {
      title: "提交时间",
      dataIndex: "submitDate",
      key: "submitDate"
    },
    {
      title: "通知类型",
      dataIndex: "notificationType",
      key: "notificationType",
      render: (text: any) => <> {returnNotificationType(text)}</>
    },
    {
      title: "卖方",
      dataIndex: "siteName",
      key: "siteName"
    },
    {
      title: "公司代码",
      dataIndex: "receiveCustomerCode",
      key: "receiveCustomerCode"
    },
    {
      title: "买方",
      dataIndex: "receiveBuyer",
      key: "receiveBuyer"
    },
    {
      title: "发布开始时间",
      dataIndex: "publishDate",
      key: "publishDate"
    },
    {
      title: "发布截止时间",
      dataIndex: "publishEndDate",
      key: "publishEndDate"
    },
    {
      title: "通知内容",
      onCell: () => {
        return {
          style: {
            maxWidth: 150,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            cursor: "pointer"
          }
        };
      },
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.content ? record.content : null}>
          {record.content ? record.content : null}
        </Tooltip>
      )
    },
    {
      title: "附件",
      render: (record: any) =>
        record.link !== null ? <DownloadNotice data={record.id} filename="appendix.xls" /> : <span>-</span>
    },
    {
      title: "撤回状态",
      dataIndex: "revertStatus",
      key: "revertStatus",
      render: (text: any) => <> {returnType(text)}</>
    },
    {
      title: "撤回人",
      dataIndex: "revertBy",
      key: "revertBy"
    },
    {
      title: "撤回",
      key: "x",
      render: (record: any) => (
        <RevertNotificationButton id={record.id} revertFlag={record.revertFlag} getListData={getListData} />
      ),
      fixed: "right" as "right"
    }
  ];

  return (
    <div className="production-record">
      <BuyerModal parent={parent} setParent={setParent} />
      <Card
        style={{ boxShadow: "0 5px 9px #dedbdb" }}
        bodyStyle={{ paddingTop: 0 }}
        title="查询条件"
        headStyle={{
          backgroundColor: "white",
          fontWeight: 100,
          borderBottom: "none"
        }}
      >
        <Form name="productionSearchForm" layout="vertical" autoComplete="off" onFinish={onFinish} form={form}>
          <Row justify="space-between">
            <Col md={5} xs={24}>
              <Form.Item name="timeRange">
                <RangePicker
                  placeholder={["提交时间", "提交时间"]}
                  separator={<div style={{ fontSize: 5 }}>至</div>}
                  dropdownClassName="range-picker-drop-down-mobile"
                />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="buyerName">
                <Input placeholder="请输入买方" onChange={onInputChange} data-testid="test-input" />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item>
                <Button type="primary" icon={<SearchOutlined />} disabled={!isInput} htmlType="submit">
                  查询
                </Button>
              </Form.Item>
            </Col>
            <Col span={8} />
          </Row>
        </Form>
      </Card>
      <Card
        style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 10 }}
        bodyStyle={{ paddingTop: 0 }}
        title={
          <div>
            <span>通知记录列表</span>
            <div className="total-item" style={{ float: "right" }}>
              共 {total} 条
            </div>
          </div>
        }
      >
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table
            columns={columns}
            pagination={paginationOpt}
            dataSource={data}
            scroll={{ x: "max-content" }}
            loading={loading}
            size="small"
          />
        </ConfigProvider>
      </Card>
    </div>
  );
};
export default ContractRecord;
