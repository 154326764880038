import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Tabs, PageHeader } from 'antd';
import "./index.scss"
import ProductionRecord from "./productionRecord"
import FinanceRecord from "./financeRecord";
import ContractRecord from "./contractRecord";
import NotificationTips from "@/pages/common/notificationTips";
import { connect } from "react-redux";
import MessageRecord from "./messageRecord";

const { TabPane } = Tabs;

interface Props extends RouteComponentProps { permissions: any, userDetailsData: any; }
interface State { activeKey: string }

class Record extends Component<Props, State> {
  readonly state = {
    activeKey: this.props.userDetailsData.groups?.includes("DXP_DSC_Role_CN_Cargill_Planner")?"1":"2"
  }
  onChange = (key: any) => {
    this.setState({ activeKey: key })
  }
  componentDidMount() {
    document.title="历史通知记录"
  }
  public render() {
    const { permissions, userDetailsData } = this.props
    return (
      <>
        <div className="record-tab-container">
          <PageHeader className="page-header" title="公告及通知记录"><NotificationTips /></PageHeader>
          <Tabs defaultActiveKey="1" className="record-tab" onChange={this.onChange}>
          {userDetailsData.groups?.includes("DXP_DSC_Role_CN_Cargill_Planner") && <TabPane tab="短信通知" key="1">
              {this.state.activeKey === "1" && <MessageRecord />}
            </TabPane>}
            <TabPane tab="生产公告" key="2">
              {this.state.activeKey === "2" && <ProductionRecord />}
            </TabPane>
            {permissions.NotificationLogView && <TabPane tab="财务类" key="3">
              {this.state.activeKey === "3" && <FinanceRecord />}
            </TabPane>}
            {permissions.NotificationLogView && <TabPane tab="合同类" key="4">
              {this.state.activeKey === "4" && <ContractRecord />}
            </TabPane>}
          </Tabs>
        </div>
      </>
    )
  }
}
const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  userDetailsData: state.userDetails
});

export default connect(mapStateToProps, null)(Record);