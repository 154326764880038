import { Modal, Table } from "antd";
import { useCallback } from "react";

interface Props {
  doClose: () => void;
  dataSource: any[];
}
const WarehouseDetailModal: React.FC<Props> = (props) => {
  const columnsData = [
    {
      title: "仓库代码",
      dataIndex: "warehouseCode",
      key: "warehouseCode"
    },
    {
      title: "产品余量",
      dataIndex: "remainQty",
      key: "remainQty"
    },
    {
      title: "买方名称",
      dataIndex: "customerName",
      key: "customerName"
    }
  ];

  const handleClose = useCallback(() => {
    props.doClose();
  }, [props]);

  return (
    <Modal
      centered
      title="仓库限量详情"
      width="60%"
      visible={true}
      maskClosable={false}
      onCancel={handleClose}
      footer={null}
    >
      <div className="hdp-uf hdp-uf-dc">
        <Table
          size="small"
          dataSource={props.dataSource}
          columns={columnsData}
          pagination={false}
          rowKey="doId"
          scroll={{ x: "max-content" }}
          style={{ minHeight: "200px" }}
        />
      </div>
    </Modal>
  );
};

export default WarehouseDetailModal;