import { useState, useEffect, useCallback } from "react";
import { Pagination, Table, Tooltip } from "antd";
import { isMobileDevice, filterColumnsData } from "@/utils/common";
import {
  cutBatchNoZero,
  expandDelayDateDisplay,
  getNotNullValue,
  getOldContractNumber,
  getProductNameInitValue,
  hasCrushContract
} from "@/utils/formatUtils";
import ProductNameSelect from "@/pages/common/productNameSelect";
import { useHistory } from "react-router-dom";

interface Props {
  tableData: any;
  totalCount: number;
  fieldsDesc: any;
  getOffset: any;
  current: number;
  loading: boolean;
  permission: any;
  getTable?: any;
  rowSelection?: any;
  searchValue?: any;
  getProductName: any;
}

const TableList = (props: Props) => {
  const history = useHistory();
  const [columns, setColumns] = useState([]);

  const jumpToContractDetails = (data: any) => {
    history.push({
      pathname: "/main/contractmanagement/sapcontractdetail",
      state: { contractNo: data.actualContractNo }
    });
  };

  const sapTableColumns = [
    {
      title: "合同编号",
      dataIndex: "contractNo",
      key: "contractNo",
      render: (_: any, record: any) => {
        return (
          <span
            onClick={() => {
              jumpToContractDetails(record);
            }}
            style={{ color: "#07c", cursor: "pointer" }}
          >
            {record.contractNo}
          </span>
        );
      }
    },
    {
      title: "合同行项目编号",
      dataIndex: "batch",
      key: "batch",
      render: (_: any, record: any) => {
        return cutBatchNoZero(record.batch);
      }
    },
    {
      title: "买方",
      dataIndex: "buyer",
      key: "buyer"
    },
    // {
    //   title: "合同类型",
    //   dataIndex: "contractType",
    //   key: "contractType"
    // },
    {
      title: "合同类型",
      dataIndex: "customerContractType",
      key: "customerContractType"
    },
    {
      title: "结价状态",
      dataIndex: "customerPricingStatus",
      key: "customerPricingStatus"
    },
    {
      title: "合同数量",
      dataIndex: "batchVolume",
      key: "batchVolume",
      render: (_: any, record: any) => {
        return getNotNullValue(record.batchVolume) ? record.batchVolume.toFixed(3) : "-";
      }
    },
    {
      title: "已开单量",
      dataIndex: "settledQuantity",
      key: "settledQuantity",
      render: (_: any, record: any) => {
        return getNotNullValue(record.settledQuantity) ? record.settledQuantity.toFixed(3) : "-";
      }
    },
    {
      title: "未开单量",
      dataIndex: "remainingAvailableDoQty",
      key: "remainingAvailableDOQty",
      render: (_: any, record: any) => {
        return getNotNullValue(record.remainingAvailableDoQty) ? record.remainingAvailableDoQty.toFixed(3) : "-";
      }
    },
    {
      title: "未开单量（件）",
      dataIndex: "remainingAvailableDoNumber",
      key: "remainingAvailableDoNumber",
      render: (_: any, record: any) => {
        return record.remainingAvailableDoNumber || "-";
      }
    },
    {
      title: "产品名称",
      dataIndex: "productName",
      key: "productName",
      render: (_: any, record: any) => {
        return (
          <ProductNameSelect
            data={record}
            onChange={props.getProductName}
            initValue={getProductNameInitValue(record.productList[0])}
          />
        );
      }
    },
    {
      title: "包装规格",
      dataIndex: "packageSpecificationCn",
      key: "packageSpecification"
    },
    {
      title: "结算单价",
      dataIndex: "unbilledAmount",
      key: "batchSettlementPrice", // 字段设置中固定了
      render: (_: any, record: any) => {
        return record.contractType === "暂定价合同" || !record.unbilledAmount ? "-" : record.unbilledAmount.toFixed(2);
      }
    },
    {
      title: "结算单价单位",
      dataIndex: "settlementPriceUnitCn",
      key: "settlementPriceUnitCn"
    },
    {
      title: "提货开始日期",
      dataIndex: "executionStartDate",
      key: "startDateExecution"
    },
    {
      title: "提货截止日期",
      dataIndex: "executionEndDate",
      key: "endDateExecution"
    },
    {
      title: "合约月份",
      dataIndex: "dce",
      key: "dce",
      render: (_: any, record: any) => {
        return record.dce ? record.dce : "-";
      }
    },
    {
      title: "合同签订日期",
      dataIndex: "contractDate",
      key: "contractDate"
    },
    {
      title: "客户自有合同号",
      dataIndex: "customerContractNo",
      key: "customerContractNo",
      render: (_: any, record: any) => {
        return record.customerContractNo ? record.customerContractNo : "-";
      }
    },
    {
      title: "卖方",
      dataIndex: "plant",
      key: "siteCode"
    },
    {
      title: "业务类型代码",
      dataIndex: "divisionCode",
      key: "divisionCode"
    },
    {
      title: "业务类型",
      dataIndex: "businessLine",
      key: "businessLine"
    },
    {
      title: "是否关闭",
      dataIndex: "completionFlag",
      key: "completionFlag"
    },
    {
      title:
        props.permission.CNInternalUsers && hasCrushContract(props.tableData) ? (
          <Tooltip title="此价格已包含蛋白价差">
            <span style={{ cursor: "pointer" }}>基差价格</span>
          </Tooltip>
        ) : (
          "基差价格"
        ),
      dataIndex: "basis",
      key: "basisPrice", // 字段设置中固定了
      render: (_: any, record: any) => {
        return record.basis ? parseFloat(record.basis)?.toFixed(2) : "-";
      }
    },
    {
      title: "基差价格单位",
      dataIndex: "settlementPriceUnitCn",
      key: "basisPriceUnit" // 字段设置中固定了
    },
    {
      title: "计数单位",
      dataIndex: "packingUnitDesc",
      key: "packingUnitDesc"
    },
    {
      title: "预提",
      dataIndex: "withholdingDate",
      key: "withholdingDate",
      render: (_: any, record: any) => {
        return expandDelayDateDisplay(record.withholdingDate);
      }
    },
    {
      title: "延期",
      dataIndex: "delayDate",
      key: "delayDate",
      render: (_: any, record: any) => {
        return expandDelayDateDisplay(record.delayDate);
      }
    },
    {
      title: "有效延期",
      dataIndex: "validDelayDate",
      key: "validDelayDate",
      render: (_: any, record: any) => {
        return expandDelayDateDisplay(record.validDelayDate);
      }
    },
    {
      title: "嘉吉原合同号",
      dataIndex: "oldContractNumber",
      key: "oldContractNumber",
      render: (_: any, record: any) => {
        return record.oldContractNumber ? getOldContractNumber(record.oldContractNumber) : "-";
      }
    },
    {
      title: "交货地点",
      dataIndex: "incotermsLocationName",
      key: "incotermsLocationName"
    },
    {
      title: "交/提货方式",
      dataIndex: "shipmentMethod",
      key: "shipmentMethod",
      render: (_: any, record: any) => {
        return record.shipmentMethod || "-";
      }
    },
    {
      title: "目的地",
      dataIndex: "destination",
      key: "destination",
      render: (_: any, record: any) => {
        return record.destination || "-"
      }
    }
  ];

  useEffect(() => {
    // 更新获取table可展示的字段信息 -- 获取可显示的字段
    let data: any = filterColumnsData(sapTableColumns, props.fieldsDesc);

    setColumns(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fieldsDesc]);

  // 分页
  const onChange = (page: number, pageSize: number) => {
    props.getOffset((page - 1) * pageSize, page);
  };

  const getRowKey = useCallback((recordItem: any) => {
    return `${recordItem.batch}-${recordItem.contractNo}-${recordItem.productCode}`;
  }, []);

  return (
    <div className="table-list">
      <Table
        columns={[...columns]}
        dataSource={props.tableData}
        size="small"
        scroll={{ x: "max-content" }}
        rowSelection={props.permission.CNInternalUsers && props.rowSelection}
        rowKey={getRowKey}
        loading={props.loading}
        pagination={false}
      />
      <Pagination
        data-testid="pagination"
        current={props.current}
        style={{ marginTop: 20, float: "right" }}
        showQuickJumper
        size={isMobileDevice() ? "small" : "default"}
        total={props.totalCount}
        onChange={onChange as any}
        showSizeChanger={false}
      />
    </div>
  );
};

export default TableList;
