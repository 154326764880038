import { getUserPermissions } from "./apiData/userPermissions";

const getUserPermissionsData = () => ({
  type: "GET_USER_PERMISSION_DATA",
  Get: getUserPermissions
});

const getUserPermissionsSuccess = (data = {}) => ({
  type: "GET_USER_PERMISSION_DATA_SUCCESS",
  payload: data
});

const getUserPermissionsError = (error: any) => ({
  type: "GET_USER_PERMISSION_DATA_ERROR",
  payload: { error }
});

export { getUserPermissionsData, getUserPermissionsSuccess, getUserPermissionsError };
