import { PageHeader, Tabs, Tooltip } from "antd";
import { Component } from "react";
import AllApply from "./allApply";
import FulfillmentOrder from "./fulfillmentOrder";
import PendingEdit from "./pendingEdit";
import Pending from "./pending";
import RejectedOrder from "./rejectedOrder";
import "./index.scss";
import SpecialApproval from "./specialApproval";
import NotificationTips from "@/pages/common/notificationTips";

const { TabPane } = Tabs;

interface Props {
  location: any;
}

interface State {
  activeKey: string;
}

class DeliveryLetterList extends Component<Props, State> {
  readonly state = {
    activeKey: "1"
  };

  componentDidMount() {
    document.title = "提货委托函列表";
    if (this.props.location.state) {
      this.setState({ activeKey: this.props.location.state.activeKey });
    }
  }

  onChange = (key: any) => {
    this.setState({ activeKey: key });
  };

  public render() {
    return (
      <div className="delivery-letter-list">
        <PageHeader className="page-header" title="提货委托函列表">
          <NotificationTips />
        </PageHeader>
        <Tabs onChange={this.onChange} defaultActiveKey="1" activeKey={this.state.activeKey}>
          <TabPane key="1" tab="全部申请">
            {this.state.activeKey === "1" && <AllApply />}
          </TabPane>
          <TabPane
            key="2"
            tab={
              <Tooltip placement="bottom" title="内外部CE及B2B提交">
                配送待编辑
              </Tooltip>
            }
          >
            {this.state.activeKey === "2" && <FulfillmentOrder />}
          </TabPane>
          <TabPane
            key="3"
            tab={
              <Tooltip placement="bottom" title="BR/VA/BRMP">
                自提待编辑
              </Tooltip>
            }
          >
            {this.state.activeKey === "3" && <PendingEdit />}
          </TabPane>
          <TabPane
            key="4"
            tab={
              <Tooltip placement="bottom" title="待开单员处理">
                待审批
              </Tooltip>
            }
          >
            {this.state.activeKey === "4" && <Pending />}
          </TabPane>
          <TabPane
            key="5"
            tab={
              <Tooltip placement="bottom" title="申请特批或修改再提交">
                已拒绝
              </Tooltip>
            }
          >
            {this.state.activeKey === "5" && <RejectedOrder />}
          </TabPane>
          <TabPane
            key="6"
            tab={
              <Tooltip placement="bottom" title="待财务处理">
                特批
              </Tooltip>
            }
          >
            {this.state.activeKey === "6" && <SpecialApproval />}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default DeliveryLetterList;
