import React, { useCallback, useEffect, useState } from "react";
import { Select } from "antd";
import { uniqBy } from "lodash";

type ValueType = string | undefined | null;

// 卖方发货地址
const ShipAddressItem: React.FC<{
  isCNInternalUsers: boolean | undefined;
  shipAddrData: any;
  value?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: (value: ValueType, option: any) => void;
}> = props => {
  const [value, setValue] = useState<ValueType>();
  const [shipAddressList, setShipAddressList] = useState([]); // 发货地址

  // 解析发货地址
  const doSetShipAddressOptions = (arrList: any[]) => {
    // 重新组装卖方发货地址和产品列表
    let alterDeliveryList: any = [];
    if (arrList?.length > 0) {
      alterDeliveryList = arrList?.map((item: any) => {
        const labelVal = props.isCNInternalUsers
          ? `${item.deliveryLocationCode} (${item.deliveryLocation})`
          : item.deliveryLocation;
        const newItem = {
          label: labelVal,
          value: item.deliveryLocationCode,
          key: item.deliveryLocationCode
        };
        return newItem;
      });

      alterDeliveryList = uniqBy(alterDeliveryList, "value");
      setShipAddressList(alterDeliveryList);
      const initOption = alterDeliveryList?.filter((item: any) => item.value === props.value);
      // 如果买方地址、运输方式、嘉吉发货地址value不在选项之中，显示“请选择”
      if (initOption?.length > 0) {
        onHandleChange(props.value, initOption[0]);
      } else {
        onHandleChange(null, {});
      }
    } else {
      setShipAddressList([]);
    }
  };

  const onHandleChange = useCallback(
    (newValue: ValueType, currentOption: any) => {
      setValue(newValue);
      props.onChange && props.onChange(newValue, currentOption);
    },
    [props]
  );

  useEffect(() => {
    doSetShipAddressOptions(props.shipAddrData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shipAddrData]);

  return (
    <Select
      data-testid="shipAddress"
      value={value}
      options={shipAddressList}
      placeholder="请选择"
      disabled={!props.isCNInternalUsers} // 外部用户不可选
      dropdownMatchSelectWidth={false}
      onChange={onHandleChange}
      style={{ width: "80%" }}
    />
  );
};

export default ShipAddressItem;
