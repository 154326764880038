/**
 * RequestService用于与 Server Api 服务进行数据交换
 * 利用axios的请求拦截和响应拦截可以对登录和权限方面进行控制，具体需求可以自己进行配置。
 */

import { oktaAuth as Auth } from "@customerexperience/cgl-dxo-dxp-fe-okta-libs";
import axios from "axios";
import { getToken } from "@/utils";
import { httpErrorStatus } from "./httpErrorStatus";

// 不需要添加countryCode的URL列表
const noNeedCountryCodeList: string[] = [
  "notificationsap/document/download/notices",
  "notificationsap/document/upload"
];
// 创建axios 实例
const RequestService = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, //  默认地址，可自行传入会替换
  timeout: 60000, // 请求超时时间 60s
  headers: {
    Accept: "application/json",
    "content-type": "application/json"
  }
});

// 筛选处于Pending状态的Request请求，可根据条件进行取消
const pendingRequestList: any = [];
const cancelCurrentPendingRequest = (config: any) => {
  for (let i in pendingRequestList) {
    //在当前请求在数组中存在时执行取消函数, 执行取消操作
    if (pendingRequestList[i].url === `${config.baseURL}${config.url}`) {
      pendingRequestList[i].cancel();
      // 取消后从pending记录列表中删除
      removePendingRequestItem(config);
    }
  }
};

// 请求成功或者失败删除pending列表中的请求记录
const removePendingRequestItem = (config: any) => {
  const removeIndex = pendingRequestList.findIndex((item: any) => item.url === config.url);
  if (removeIndex !== -1) {
    pendingRequestList.splice(removeIndex, 1);
  }
};

// request interceptor -- 拦截请求 request，并做一些自定义处理
RequestService.interceptors.request.use(
  async config => {
    // do something before request is sent
    const TokenVal = await getToken();
    if (TokenVal) {
      config.headers.Authorization = `Bearer ${TokenVal}`;
      if (config.headers.idtoken) {
        const idToken = await Auth.getIdToken();
        config.headers.idtoken = idToken;
      }
    }

    let isNeedAddCountryCode = true;
    for (let i = 0; i < noNeedCountryCodeList.length; i++) {
      if (config.url?.indexOf(noNeedCountryCodeList[i]) !== -1) {
        isNeedAddCountryCode = false;
        break;
      }
    }
    if (isNeedAddCountryCode && config.url?.indexOf("countryCode=CN") === -1) {
      if (config.url?.indexOf("?") === -1) {
        config.url = `${config.url}?countryCode=CN`;
      } else {
        config.url = `${config.url}&countryCode=CN`;
      }
    }

    // 判断是否进行同样的 Request 的地址后续的请求覆盖之前pending中的请求
    if (config.cancelToken) {
      cancelCurrentPendingRequest(config);
      // 将最新的请求加入到pending列表中
      const requestUrl = `${config.baseURL}${config.url}`;
      config.cancelToken = new axios.CancelToken(function executor(cancelFunc: any) {
        pendingRequestList.push({
          url: requestUrl,
          cancel: cancelFunc
        });
      });
    }

    return Promise.resolve(config);
  },
  error => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor -- 拦截响应 response，并做一些错误的自定义处理
RequestService.interceptors.response.use(
  response => {
    // 这里可以自定义处理状态码为200的response -- 200
    // 正常返回
    // 判断是否需要存在并从pending的请求列表中删除
    if (response?.config?.cancelToken) {
      removePendingRequestItem(response.config);
    }
    // 下载文件时，返回response
    if (response && response.config) {
      if (response.config.url && ["arsap/downLdcTemplateData?countryCode=CN", "/commodity-movementsap/batchDownloadByDoIds?countryCode=CN"].includes(response.config.url)) {
        return Promise.resolve(response);
      }
    }
    return Promise.resolve(response.data);
  },
  // 这里是返回状态码非200时候的错误处理 -- 处理一些response出错时的逻辑 -- 非200
  err => {
    // 判断是否需要存在并从pending的请求列表中删除
    if (err?.response?.config?.cancelToken) {
      removePendingRequestItem(err.response.config);
    }

    // 匹配常见网络服务错误码信息
    if (err && err.response?.status) {
      const errorItem = httpErrorStatus.filter(item => item.code === err.response.status);
      if (errorItem && errorItem.length > 0) {
        err.response.data.message = errorItem[0].messageCN;
      }
    }

    const errorData = err?.response?.data || "";
    // 使用后端服务返回的错误信息进行提示--针对特殊定制化错误提示，后端返回error字段，并指定errorCode:"99999"
    if (errorData?.error?.errorCode === "99999") {
      const errorMsg = errorData.error.message;
      const errorMsgVal = errorMsg.replace("Error detail:", "");
      const errorCodeVal = errorData.error.errorCode;
      const responseErrorVal = errorData.error;
      return Promise.resolve({
        code: errorCodeVal,
        errorMsg: errorMsgVal,
        responseError: responseErrorVal
      });
    } else if (errorData?.message) {
      let errorMsgVal = errorData.message || "";
      if (typeof errorMsgVal === "string") {
        errorMsgVal = errorMsgVal.replace("Error detail:", "").trim() || "";
        errorMsgVal = errorMsgVal.replace("Error code: 99999", "").trim() || "";
      }
      let errorMsgObj = {
        code: err?.response?.status,
        errorMsg: errorMsgVal,
        msg: "接口请求失败。",
        data: errorData
      };
      return Promise.reject(errorMsgObj);
    } else if (axios.isCancel(err)) {
      // 处理请求被取消的情况
      let errorMsgObj = {
        code: "cancel",
        errorMsg: "Request canceled",
        msg: "",
        data: null
      };
      return Promise.reject(errorMsgObj);
    } else {
      let errorMsgVal = errorData?.error?.message || "";
      if (typeof errorMsgVal === "string") {
        errorMsgVal = errorMsgVal.replace("Error detail:", "").trim() || "";
      }
      let errorMsgObj = {
        code: err?.response?.status,
        errorMsg: errorMsgVal,
        msg: "接口请求失败。",
        data: errorData
      };
      return Promise.reject(errorMsgObj);
    }
  }
);

export default RequestService;
