import { useState, useCallback } from "react";
import { Modal, Button, message } from "antd";
import { terminateContract, confirmTerminateContract, terminateZDContract } from "@/api/directSys";

const BuyerModal = (props: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOk = useCallback(() => {
    const handleRes = (res: any) => {
      if (res?.data?.result) {
        props.getListData();
      } else {
        message.error(res?.data?.description);
      }
    };

    const matchWSContracts = props.selectedRow.find((item: any) => item.systemSource === "温氏");
    const matchZDContracts = props.selectedRow.find((item: any) => item.systemSource === "正大");
    setLoading(true);
    if (matchWSContracts) {
      // 温氏
      const request = {
        terminateContractNo: props.selectedRow[0].crglContractNo
      };
      terminateContract({}, request)
        .then(res => handleRes(res))
        .finally(() => {
          setLoading(false);
          setIsModalVisible(false);
        });
    } else if (matchZDContracts) {
      // 正大
      const request = {
        terminateContractNo: props.selectedRow[0].crglContractNo
      };
      terminateZDContract({}, request)
        .then(res => handleRes(res))
        .finally(() => {
          setLoading(false);
          setIsModalVisible(false);
        });
    } else {
      // 通威
      const request: any = [];
      props.selectedRow.forEach((e: any) => {
        request.push({ id: e.id });
      });
      confirmTerminateContract(request, {})
        .then(res => handleRes(res))
        .finally(() => {
          setIsModalVisible(false);
        });
    }
  }, [props]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleVisible = useCallback(() => {
    const notMatchContracts = props.selectedRow.find((item: any) => item.contractType !== "一口价合同");
    if (notMatchContracts) {
      message.error("该操作只针对 “一口价合同”");
      return;
    }
    const matchWSContracts = props.selectedRow.find((item: any) => item.systemSource === "温氏");
    const notMatchWSConTracts = props.selectedRow.find((item: any) => item.systemSource !== "温氏");
    if (matchWSContracts && notMatchWSConTracts) {
      message.error("“温氏合同”不能和其他合同同时提交");
      return;
    }
    if (matchWSContracts && props.selectedRow.length !== 1) {
      message.error("“温氏合同”只能单个提交");
      return;
    }
    const matchZDContracts = props.selectedRow.find((item: any) => item.systemSource === "正大");
    const notMatchZDConTracts = props.selectedRow.find((item: any) => item.systemSource !== "正大");
    if (matchZDContracts && notMatchZDConTracts) {
      message.error("“正大合同”不能和其他合同同时提交");
      return;
    }
    if (matchZDContracts && props.selectedRow.length !== 1) {
      message.error("“正大合同”只能单个提交");
      return;
    }
    setIsModalVisible(true);
  }, [props.selectedRow]);

  // systemSource === "牧原" && item.contractState === "终止中" disabled确认终止按钮
  const buttonDisabled = () => {
    for (const item of props.selectedRow) {
      if (item.systemSource === "牧原" && item.contractState !== "终止中") {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <Button type={props.isText ? "text" : "primary"} disabled={buttonDisabled() || !props.isSelected} onClick={handleVisible}>
        确认终止
      </Button>
      <Modal
        title="终止合同提示"
        maskClosable={false}
        visible={isModalVisible}
        footer={
          <Button data-testid="test-ok" loading={loading} type="primary" onClick={handleOk}>
            确定
          </Button>
        }
        onCancel={handleCancel}
      >
        您确定终止该合同吗？
      </Modal>
    </>
  );
};
export default BuyerModal;
