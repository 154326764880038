import { Button, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";

const BankErrorAlert = (props: any) => {
  const { description, alertMetadata, closeSuccessAlertModal } = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (alertMetadata.unReadCount && alertMetadata.unReadCount > 0) {
      setVisible(true);
    }
  }, [alertMetadata, description]);

  const onConfirm = useCallback(() => {
    closeSuccessAlertModal();
    setVisible(false);
  }, [closeSuccessAlertModal]);

  return (
    <Modal
      title={false}
      visible={visible}
      centered
      closable={false}
      maskClosable={false}
      width={450}
      footer={
        <Button data-testid="cancel-tips-btn" type="primary" onClick={onConfirm}>
          确定
        </Button>
      }
    >
      <div className="content">
        {description.map((item: any) => (
          <p key={item.alertId}> &lt;提单号:{item.alertMsg.slice(0, 9)}&gt;</p>
        ))}
        <p>在一卡通系统内分组错误，请联系一卡通尽快处理。</p>
      </div>
    </Modal>
  );
};

export default BankErrorAlert;
