import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Input, Select, Button, Collapse, Divider, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { allSupplierMutiple, depositTypeOptions, documentItemStatusOptions, dueOptions } from "@/utils/constant";
import { getFirmDetailListApi } from "@/api/slotbooking";

import RemoteSelect from "@/pages/common/remoteSelect";
import { getCode } from "@/utils/common";

const { Panel } = Collapse;

type myProps = {
  doSearch?: (arg0: any) => void;
  doRest: (arg0: any) => void;
  userPermissionsData: any;
  userDetailsData: any;
  switcherData: any;
  buyers: any;
  defaultSearchValue?: any;
};

const SearchFilter = (props: myProps) => {
  const { switcherData, userPermissionsData, userDetailsData, buyers, defaultSearchValue } = props;
  const [form] = Form.useForm();
  const [isShowAdvanceFilter, setIsShowAdvanceFilter] = useState(false);
  const [isShowAdvanceFilterText, setIsShowAdvanceFilterText] = useState("展开高级筛选");
  const [isSelectFetching, setIsSelectFetching] = useState(false);
  const [currentCollapseActiveKey, setCurrentCollapseActiveKey] = useState("");

  // 处理切换高级选项的状态
  const changeAdvanceFilterStatus = () => {
    if (currentCollapseActiveKey) {
      setIsShowAdvanceFilter(false);
      setIsShowAdvanceFilterText("展开高级筛选");
      setCurrentCollapseActiveKey("");
    } else {
      setIsShowAdvanceFilter(true);
      setIsShowAdvanceFilterText("收起高级筛选");
      setCurrentCollapseActiveKey("search-filters-01");
    }
  };

  const isDisabledAction = (key: string) => {
    if (userPermissionsData["CNExternalUsers"]) {
      if (key === "businessLine" || key === "buyer") {
        return false;
      }
      return switcherData[key];
    } else if (userPermissionsData["CNInternalUsers"]) {
      return false;
    }
    return true;
  };

  // 清空重置buyer或productName的数据
  const resetBuyerValue = useCallback(() => {
    if (props.userPermissionsData["CNInternalUsers"]) {
      form.setFieldsValue({ customerCode: null });
    }
  }, [form, props.userPermissionsData]);

  const handleMySubmit = useCallback(
    (values?: any) => {
      let temp = { ...values };
      // 将条件所有值传递给父组件
      if (props.doSearch) {
        // 此模块只传code，去掉中文
        if (temp.customerCode) {
          temp.customerCode = getCode(temp.customerCode);
        }
        temp.orgCode = "";
        if (values?.orgCode?.length > 0) {
          temp.orgCode = values.orgCode.join(",");
        }

        // 传递筛选的最终结果给父级组件
        props.doSearch(temp);
      }
    },
    [props]
  );

  const resetSearch = useCallback(() => {
		const {
			switcherData,
			userPermissionsData,
			userDetailsData
		} = props;
		form.resetFields();
    form.setFieldsValue({
      orgCode: switcherData.orgCode && switcherData.orgCode !== "All" ? [switcherData.orgCode] : []
    });
		if (userPermissionsData.CNExternalUsers && !userDetailsData?.isGroup) {
			form.setFieldsValue({
				customerCode: switcherData.customerName + "(" + switcherData.customerCode + ")"
			});
		}
		const values = { ...form.getFieldsValue(true) };
		props.doRest(values);
	}, [form, props]);

  // 外部Group用户筛选公司
  const onFilterOption = useCallback((inputValue: any, option: any) => {
    return ((option?.label as unknown) as string).toLowerCase().includes(inputValue?.toLowerCase());
  }, []);

  // 内部用户--获取买方选项(防抖输入查询)
  const fetchCustomerOptions_Internal = useCallback(
    (value: string | undefined, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      getFirmDetailListApi({
        customerName: value || "",
        virtualFlag: "1"
      })
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            callback([]);
            message.error(res.errorMsg);
          } else if (res.data?.customerList?.length > 0 && currentValue === value) {
            let data: any[] = res.data?.customerList?.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName + "(" + val.customerCode + ")",
                key: val.customerCode
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    []
  );

  // 如果switcher有设置值，进行初始化相关数据
  // 只要switcher中的卖方 orgCode 的值发生变化需要处理的其他相关项
  useEffect(() => {
    resetBuyerValue();
  }, [form, userPermissionsData, switcherData.orgCode, resetBuyerValue]);

  // 只要switcher中的buyer的值发生变化需要处理的其他相关项
  useEffect(() => {
    if (switcherData) {
      if (userPermissionsData.CNInternalUsers) {
        form.setFieldsValue({
          orgCode: switcherData.orgCode && switcherData.orgCode !== "All" ? [switcherData.orgCode] : []
        });
      } else if (userPermissionsData.CNExternalUsers) {
        // 外部用户
        let buyerName = null;
        if (!userDetailsData?.isGroup) {
          buyerName = switcherData.customerName + "(" + switcherData.customerCode + ")";
        }
        form.setFieldsValue({
          orgCode: switcherData.orgCode && switcherData.orgCode !== "All" ? [switcherData.orgCode] : [],
          customerCode: buyerName
        });
      }
    }
  }, [form, switcherData, userPermissionsData, userDetailsData]);

  useEffect(() => {
    if (defaultSearchValue && Object.keys(defaultSearchValue).length) {
      form.resetFields();
      form.setFieldsValue({
        customerCode: defaultSearchValue.customerCode,
        orgCode: defaultSearchValue.orgCode,
      });
    }
  }, [form, defaultSearchValue]);

  return (
    <div className="search-filter">
      <div className="base-filter">
        <Form
          form={form}
          name="search_filter_form"
          layout="vertical"
          onFinish={handleMySubmit}
          initialValues={{
            customerCode: null,
            orgCode: [],
            type: null,
            remark: "",
            due: null,
            profitCenter: ""
          }}
        >
          {/* 基础筛选条件 */}
          <Row gutter={{ xs: 8, md: 16 }}>
            <Col md={5} xs={24}>
              {props.userPermissionsData["CNInternalUsers"] && (
                <Form.Item name="customerCode">
                  <RemoteSelect
                    dataTestId="test-internalUser-customerCode-select-02"
                    placeholder="请输入公司名称"
                    fetchOptions={fetchCustomerOptions_Internal}
                    loading={isSelectFetching}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              )}
              {props.userPermissionsData["CNExternalUsers"] && !props.userDetailsData?.isGroup && (
                <Form.Item name="customerCode">
                  <Input disabled />
                </Form.Item>
              )}
              {/* 外部用户且为集团账户的买方输入框,当前不用判断是否为饲料厂用户 */}
              {props.userPermissionsData["CNExternalUsers"] && props.userDetailsData?.isGroup && (
                <Form.Item shouldUpdate>
                  {() => {
                    return (
                      <Form.Item name="customerCode">
                        <Select
                          showSearch
                          placeholder="请选择公司名称"
                          disabled={isDisabledAction("buyer")}
                          options={buyers}
                          optionFilterProp="label"
                          filterOption={onFilterOption}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              )}
            </Col>
            <Col md={4} xs={24}>
              <Form.Item name="customerGlIndicator">
                <Select
                  allowClear
                  data-testid="test-customerGlIndicator-select"
                  placeholder="请选择款项类型"
                  options={depositTypeOptions}
                />
              </Form.Item>
            </Col>
            <Col md={3} xs={24}>
              <Form.Item shouldUpdate >
                {() => (
                  <Button
                    data-testid="test-search-basic-filters-02"
                    type="primary"
                    htmlType="submit"
                    icon={<SearchOutlined />}
                    disabled={isShowAdvanceFilter || !form.getFieldValue("customerCode") || !form.getFieldValue("customerGlIndicator")}
                  >
                    查询
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Collapse
            style={{ marginTop: -25 }}
            ghost collapsible="header"
            activeKey={currentCollapseActiveKey}
            expandIconPosition="right"
          >
            <Panel
              className="advance-filters-container"
              key={"search-filters-01"}
              header={
                <div
                  style={{ color: "#007ed9" }}
                  data-testid="test-open-advance-filters"
                  className="collapse-active-btn"
                  onClick={changeAdvanceFilterStatus}
                >
                  {isShowAdvanceFilterText}
                </div>
              }
            >
              {/* 高级筛选条件 */}
              <Divider dashed={true} style={{ width: "100%", borderColor: "#eee", margin: "-12px 0 12px 0" }} />
              <Row className="row-space-arround" gutter={{ xs: 8, md: 16 }}>
                <Col md={4} xs={24}>
                  <Form.Item name="orgCode" label="卖方公司">
                    <Select
                      data-testid="test-orgCode-select"
                      placeholder="请选择"
                      mode="multiple"
                      maxTagCount={"responsive" as const}
                      options={allSupplierMutiple()}
                    />
                  </Form.Item>
                </Col>
                <Col md={4} xs={24}>
                  <Form.Item name="documentItemStatus" label="款项状态">
                    <Select
                      allowClear
                      data-testid="test-documentItemStatus-select"
                      placeholder="请选择"
                      options={documentItemStatusOptions}
                    />
                  </Form.Item>
                </Col>
                <Col md={4} xs={24}>
                  <Form.Item name="remark" label="备注（合同号或其他）">
                    <Input data-testid="test-remark-input" placeholder="请输入" />
                  </Form.Item>
                </Col>
                {props.userPermissionsData["CNInternalUsers"] && (
                  <Col md={5} xs={24}>
                    <Form.Item name="due" label="是否超期">
                      <Select
                        allowClear
                        data-testid="test-due-select"
                        placeholder="请选择"
                        options={dueOptions}
                      />
                    </Form.Item>
                  </Col>
                )}
                {props.userPermissionsData["CNInternalUsers"] && (
                  <Col md={5} xs={24}>
                    <Form.Item name="profitCenter" label="利润中心">
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                )

                }
                <Col className="advance-btn-col">
                  <Form.Item shouldUpdate>
                    {() => (
                      <div className="advance-btn">
                        <Button
                          data-testid="test-search-advance-filters-reset"
                          type="primary"
                          style={{ marginRight: 10, backgroundColor: "#5c5c5c" }}
                          onClick={resetSearch}
                        >
                          清空
                        </Button>
                        <Button
                          data-testid="test-search-advance-filters"
                          type="primary"
                          htmlType="submit"
                          disabled={!isShowAdvanceFilter || !form.getFieldValue("customerCode") || !form.getFieldValue("customerGlIndicator")}
                        >
                          立即查询
                        </Button>
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userPermissionsData: state.userPermissions,
  switcherData: state.switcher,
  userDetailsData: state.userDetails
});

export default connect(mapStateToProps, null)(SearchFilter);