const initialState = {};

function userDetails(state = initialState, action: any) {
  switch (action.type) {
    case "GET_USER_DATA":
    case "GET_USER_DATA_ERROR":
      return {
        ...state
      };
    case "GET_USER_DATA_SUCCESS":
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

export { userDetails, initialState };
