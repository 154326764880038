import { combineReducers } from "redux";
import { userDetails } from "./userDetails";
import { userPermissions } from "./userPermissions";
import { switcher } from "./switcher";
import { notificationFlag } from "./notificationFlag";

const reducers = () => {
  return combineReducers({
    userDetails,
    userPermissions,
    switcher,
    notificationFlag
  });
};

export default reducers;
