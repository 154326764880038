import React, { useCallback, useEffect, useState } from "react";
import { Input } from "antd";

// 计划提货件数
const DoQuantityByPackageItem: React.FC<{
  doData: any;
  value?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: (value: string, affectedFields: any) => void;
}> = props => {
  const [value, setValue] = useState<string | undefined>(props.value);

  // 根据计划数量吨获取计划提货数量--光标移出后再计算
  const onBlur = useCallback(
    (event: any) => {
      let doQuantityByPackage: number | string = event.target.value;
      let affectedFields = {};

      doQuantityByPackage = doQuantityByPackage ? parseInt(event.target.value) : "";
      if (Number(doQuantityByPackage) === Number(props.value)) {
        return;
      }

      if (props.doData?.conversionFactor && doQuantityByPackage && props.doData?.packingUnitDesc.trim() === "吨") {
        // 计算计划提货数量（件）的值
        const calValue = parseFloat((Number(doQuantityByPackage) * props.doData?.conversionFactor).toString()).toFixed(
          3
        );
        // 件数不一致重新赋值，并再次进入反算吨数的逻辑
        if (props.doData?.doQuantity !== calValue.toString()) {
          affectedFields = { doQuantity: calValue };
        }
      } else if (
        props.doData?.conversionFactor &&
        doQuantityByPackage &&
        props.doData?.packingUnitDesc.trim() === "千克"
      ) {
        const calValue = parseFloat(
          ((Number(doQuantityByPackage) * props.doData?.conversionFactor) / 0.001).toString()
        ).toFixed(3);
        if (props.doData?.doQuantity !== calValue.toString()) {
          // 件数不一致重新赋值
          affectedFields = { doQuantity: calValue };
        }
      } else if (props.doData?.conversionFactor && doQuantityByPackage === 0) {
        affectedFields = { doQuantity: "" };
      }

      props.onChange && props.onChange(doQuantityByPackage.toString(), affectedFields);
    },
    [props]
  );

  // 处理计划提货数量的改变--实时输入时并校验
  const onHandleChange = useCallback((event: any) => {
    let value = event.target.value;
    // 验证只能输入大于0的正整
    const reg = /^\+?[1-9]\d*$/;
    if (value && !reg.test(value)) {
      if (value.toString().length === 1) {
        value = value.replace(/[^1-9]/g, "");
      } else {
        value = value.replace(/\D/g, "");
      }
    }
    setValue(value);
  }, []);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Input
      data-testid="test-doQuantityByPackage"
      value={value}
      onBlur={onBlur}
      onChange={onHandleChange}
      disabled={!props.doData.conversionFactor}
      placeholder="请输入"
    />
  );
};

export default DoQuantityByPackageItem;
