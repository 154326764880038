import RequestService from "@/api/request";

// 字段设置
const saveContractFieldsOrder = (dataObj: object) =>
  RequestService({
    url: "/contractsap/saveFieldsOrder",
    method: "post",
    data: dataObj
  });

// 字段设置
const saveMovementFieldsOrder = (dataObj: object) =>
  RequestService({
    url: "/commodity-movementsap/saveFieldsOrder",
    method: "post",
    data: dataObj
  });

// 获取买方
const getFirmDetailList = (dataObj: object) =>
  RequestService({
    url: "/contracts/firms",
    method: "get",
    params: dataObj
  });

// switcher子公司列表
const getFirmDetailsList = (dataObj: object) =>
  RequestService({
    url: "/contractsap/sap/listCustomer",
    method: "get",
    params: dataObj
  });

// 获取产品名称
const getProductDetailList = (dataObj: object) =>
  RequestService({
    url: "/contracts/products",
    method: "get",
    params: dataObj
  });

// 获取运输方式列表
const getTransportTypes = (dataObj: object) =>
  RequestService({
    url: "/commodity-movementsap/transportType",
    method: "get",
    params: dataObj
  });

// 获取买方收货地址List
const getDestinationLocations = (dataObj: object) =>
  RequestService({
    url: "/contractsap/sap/shipToParty",
    method: "get",
    params: dataObj
  });

// 获取提货委托函申请信息
const history = (dataObj: object) =>
  RequestService({
    url: "/commodity-movementsap/history",
    method: "get",
    params: dataObj
  });

// 拼单
const mergeDo = (dataObj: object) =>
  RequestService({
    url: "/commodity-movementsap/mergeDo",
    method: "PATCH",
    data: dataObj
  });

// 取消拼单
const cancelMerge = (dataObj: object) =>
  RequestService({
    url: "/commodity-movementsap/cancelMerge",
    method: "PATCH",
    data: dataObj
  });

// 下载报表
const downloadContractsReport = (dataObj: object, params: object) =>
  RequestService({
    url: "/contractsap/sap/downloadReport",
    method: "post",
    data: dataObj,
    params: params,
    responseType: "blob"
  });

// 下载报表
const downloadOldContractsReport = (dataObj: object, params: object) =>
  RequestService({
    url: "/contractsap/downloadReport",
    method: "post",
    data: dataObj,
    params: params,
    responseType: "blob"
  });

// 下载报表
const downloadDoReport = (dataObj: object, params: object) =>
  RequestService({
    url: "/commodity-movementsap/downloadDoReport",
    method: "post",
    data: dataObj,
    params: params,
    responseType: "blob"
  });

// 下载报表
const downloadDeliveryOrder = (dataObj: object, params: object) =>
  RequestService({
    url: "/commodity-movementsap/downloadDeliveryOrder",
    method: "post",
    data: dataObj,
    params: params,
    responseType: "blob"
  });
const downloadContract = (dataObj: object, params: object) =>
  RequestService({
    url: "/contractsap/downloadContract",
    method: "post",
    data: dataObj,
    params: params,
    responseType: "blob"
  });
const downloadPointPrice = (dataObj: object, params: object) =>
  RequestService({
    url: "/contractsap/downloadPointPrice",
    method: "post",
    data: dataObj,
    params: params,
    responseType: "blob"
  });
const downloadSettlementInfoDetail = (dataObj: object, params: object) =>
  RequestService({
    url: "/arsap/downloadSettlementInfoDetail",
    method: "post",
    data: dataObj,
    params: params,
    responseType: "blob"
  });
const downloadSettlement = (dataObj: object, params: object) =>
  RequestService({
    url: "/arsap/downloadSettlement",
    method: "post",
    data: dataObj,
    params: params,
    responseType: "blob"
  });
const notificationList = (dataObj: object) =>
  RequestService({
    url: "notificationsap/v2",
    method: "get",
    params: dataObj
  });
const notificationRead = (dataObj: object) =>
  RequestService({
    url: "notificationsap",
    method: "PATCH",
    data: dataObj
  });
export {
  getFirmDetailList,
  getProductDetailList,
  saveContractFieldsOrder,
  history,
  cancelMerge,
  mergeDo,
  downloadContractsReport,
  downloadDoReport,
  downloadDeliveryOrder,
  getFirmDetailsList,
  saveMovementFieldsOrder,
  downloadContract,
  downloadPointPrice,
  notificationList,
  notificationRead,
  downloadSettlement,
  downloadSettlementInfoDetail,
  getTransportTypes,
  getDestinationLocations,
  downloadOldContractsReport
};
