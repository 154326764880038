import RequestService from "@/api/request";
import axios from "axios";

// 获取开单额度
const getNetAccountBalance = (dataObj: object) => {
  return RequestService({
    url: "/arsap/accountsReceivable",
    method: "get",
    params: dataObj
  });
};

// 获取现金余额
const getCrashBalance = (dataObj: object) => {
  return RequestService({
    url: "/arsap/getCustomerAccountBalance",
    method: "get",
    params: dataObj
  });
}

// 获取提单占用明细
const getBillUsageDetailsApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/arsap/getExposureDetails",
    method: "get",
    params: paramsObj
  });
};

// 获取发票列表 -- data中需要 sapFlag:true｜false 区分sap还是cts
const getInvoiceDataListApi = (paramsObj: object = {}, dataObj: object = {}) => {
  return RequestService({
    url: "/arsap/invoiceList",
    method: "post",
    params: paramsObj,
    data: dataObj
  });
};

// 下载发票报表数据 -- data中需要 sapFlag:true｜false 区分sap还是cts
const downloadInvoiceDataReportApi = (dataObj: object = {}) => {
  return RequestService({
    url: "/arsap/downLoadInvoiceList",
    method: "post",
    data: dataObj,
    responseType: "blob"
  });
};

// 下载附件数据
const downloadAttachmentApi = (paramsObj: object = {}, dataObj: object = {}) => {
  return RequestService({
    url: "/arsap/batchDownloadExtends",
    method: "post",
    params: paramsObj,
    data: dataObj,
    responseType: "blob",
    headers: { idtoken: true }
  });
};

// 获取发票查询多个条件的options
const getConditionsForQueryListApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/arsap/getConditionsForQueryList",
    method: "get",
    params: paramsObj
  });
};

// 发票列表字段设置
const saveInvoiceDataListFieldsOrderApi = (dataObj: object) =>
  RequestService({
    url: "/arsap/saveFieldsOrder",
    method: "post",
    data: dataObj
  });

// 获取买方 -- CTS
const getCtsFirmDetailListApi = (dataObj: object) =>
  RequestService({
    url: "/contracts/firms",
    method: "get",
    params: dataObj
  });

// 获取账户总览数据
const getDepositOverviewDataApi = (paramsObj: object) => {
  return RequestService({
    url: "/arsap/depositCount",
    method: "get",
    params: paramsObj
  });
};

// 获取对账明细列表
const getDepositDetailsDataApi = (paramsObj: object) => {
  return RequestService({
    url: "/arsap/depositList",
    method: "get",
    params: paramsObj
  });
};

// 获取清账明细列表
const getAccountSettlementDataApi = (paramsObj: object) => {
  return RequestService({
    url: "/arsap/squareAccountList",
    method: "get",
    params: paramsObj
  });
};

// 对账列表字段设置
const saveDepositDetailsFieldsOrderApi = (dataObj: object) =>
  RequestService({
    url: "/arsap/saveFieldsOrder",
    method: "post",
    data: dataObj
  });

// 清账明细列表字段设置
const saveAccountSettlementFieldsOrderApi = (dataObj: object) =>
  RequestService({
    url: "/arsap/saveFieldsOrder",
    method: "post",
    data: dataObj
  });

// 下载对账明细
const downDepositDetailsApi = (dataObj: object = {}) => {
  return RequestService({
    url: "/arsap/downloadDeposit",
    method: "post",
    data: dataObj,
    responseType: "blob"
  });
};

// 下载清账明细
const downAccountSettlementApi = (dataObj: object = {}) => {
  return RequestService({
    url: "/arsap/downloadSquareAccountList",
    method: "post",
    data: dataObj,
    responseType: "blob"
  });
};

// 获取数据定金明细数据截止时间
const getDepositLastUpdateTimeApi = (paramsObj: object) => {
  return RequestService({
    url: "/arsap/getDepositLastUpdateTime",
    method: "get",
    params: paramsObj
  })
};

const getFirmDetailListApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/contractsap/sap/vagueListCustomer",
    method: "get",
    params: paramsObj,
    cancelToken: axios.CancelToken.source().token
  });
};

// 设置应付贷款明细字段
const savePayableDetailsFieldsOrderApi = (dataObj: any) => {
  return RequestService({
    url: "/arsap/saveFieldsOrder",
    method: "post",
    data: dataObj
  });
};

// 设置提单占用明细列表字段
const saveBillUsageDetailsFieldsOrderApi = (dataObj: any) => {
  return RequestService({
    url: "/arsap/saveFieldsOrder",
    method: "post",
    data: dataObj
  });
};

// 下载提单占用明细报表
const downloadExposureDetailsApi = (dataObj: any) => {
  return RequestService({
    url: "/arsap/downloadExposureDetails",
    method: "post",
    data: dataObj,
    responseType: "blob"
  });
};
// 下载应付货款明细报表
const downloadCustomerAccountItemList = (dataObj: any) => {
  return RequestService({
    url: "/arsap/downloadCustomerAccountItemList",
    method: "post",
    data: dataObj,
    responseType: "blob"
  });
};


// 获取对账单总览
const getAccountStatementApi = (dataObj: any) => {
  return RequestService({
    url: "/arsap/customerAccountItemSummary",
    method: "get",
    params: dataObj
  })
};

// 下载对账单
const downloadAccountStatementApi = (dataObj: any) => {
  return RequestService({
    url: "/arsap/downloadCustomerAccountItemRecon",
    method: "post",
    data: dataObj,
    responseType: "blob"
  });
};

// 查询应付货款明细
const getCustomerAccountItemListApi = (paramsObj: any) => {
  return RequestService({
    url: "/arsap/customerAccountItemList",
    method: "get",
    params: paramsObj
  });
};

// 获取数据截止日期
const getCustomerAccountItemTimeApi = (paramsObj: any) => {
  return RequestService({
    url: "/arsap/customerAccountItemTime",
    method: "get",
    params: paramsObj
  });
};

export {
  getNetAccountBalance,
  getCrashBalance,
  getBillUsageDetailsApi,
  getInvoiceDataListApi,
  downloadInvoiceDataReportApi,
  downloadAttachmentApi,
  getConditionsForQueryListApi,
  saveInvoiceDataListFieldsOrderApi,
  saveAccountSettlementFieldsOrderApi,
  saveDepositDetailsFieldsOrderApi,
  savePayableDetailsFieldsOrderApi,
  saveBillUsageDetailsFieldsOrderApi,
  getCtsFirmDetailListApi,
  getDepositOverviewDataApi,
  getDepositDetailsDataApi,
  getAccountSettlementDataApi,
  downDepositDetailsApi,
  downAccountSettlementApi,
  getDepositLastUpdateTimeApi,
  getFirmDetailListApi,
  downloadExposureDetailsApi,
  getAccountStatementApi,
  downloadAccountStatementApi,
  getCustomerAccountItemListApi,
  downloadCustomerAccountItemList,
  getCustomerAccountItemTimeApi
};