import { getContractDetailsById } from "@/api/contractReport";
import { getCIDOSContracts, updateDos } from "@/api/deliveryOrder";
import { doVerification, expandDateSelect } from "@/utils/common";
import { addZeroToBatch, isDeliveryByShip, isExternalDeliveryByShip } from "@/utils/formatUtils";
import { Button, message, Modal } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import * as type from "../../../../../constant";

let itemData: any = {};
const ConfirmSubmit = (props: any) => {
  const { data, getTable, text, keys, setLoadingStatus } = props;
  const [visible, setVisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);
  const [requestSapfailVisible, setRequestSapFailVisible] = useState(false);
  const [isSingleSubmit, setIsSingleSubmit] = useState(false);
  const [isNotSameDos, setIsNotSameDos] = useState(false);
  const [loading, setLoading] = useState(false);
  const [overDoQutityerrorContent, setOverDoQutityerrorContent] = useState({
    doQuantityTotalValue: 0,
    contractNoAndBatch: "",
    overDoQutityValue: 0
  });
  // const [missingMergedDosVisible, setMissingMergedDosVisible] = useState(false);

  useEffect(() => {
    if (props.text) {
      setLoadingStatus && setLoadingStatus(loading);
    }
  }, [loading, props.text, setLoadingStatus]);

  // 判断是否为B2B之前的提单数据
  const isOldData = (data: any) => {
    // 如果kaClient === "TW"存在，且deliveryLocationCode为DELIVERYLOCATIONCODES中的数据，则为最新的B2B数据，否则为B2B之前的数据
    if (data.kaClient === "TW") {
      if (type.DELIVERYBASISALL.indexOf(data.deliveryBasis) > -1) {
        return false;
      }
    }
    return true;
  };

  // 判断是否通威/温氏提单拼单=》是否弹出新的提示还是走之前弹窗流程
  // kaClient === "TW"为通威单,"WS"为温氏单
  const isTwWsCan = (data: any, key: string) => {
    let res = false;
    if (data.length) {
      data.forEach((item: any) => {
        if (item.kaClient === key && item.doMerge) {
          res = true;
        }
      });
    }
    return res;
  };

  const isTwWsReceive = (data: any) => {
    let res = true;
    if (data.length) {
      data.forEach((item: any) => {
        if (item.fullCarpool === "N") {
          res = false;
        }
      });
    }
    return res;
  };

  // 判断是否全部选择 -- 通威｜温氏判断
  const isTwWsIncludeAll = (data: any, userType: any = "") => {
    const userTypeAllowArr = ["WS", "TW"];
    if (data.length) {
      const idsStr = data.map((obj: { doId: any }) => obj.doId).join(",");
      const idsStrArr = idsStr.split(",");
      const idsStrAllDoInCarpool = data.map((item: { allDoInCarpool: any }) => item.allDoInCarpool).join(",");
      const idsStrAllDoInCarpoolArr = Array.from(new Set(idsStrAllDoInCarpool.split(",")));
      const isEqual = JSON.stringify(idsStrArr.sort()) === JSON.stringify([...idsStrAllDoInCarpoolArr].sort());
      if (isEqual) {
        return true;
      }
      return userTypeAllowArr.includes(userType) && checkWsCtsAllow(data);
    }
    return false;
  };

  // 温氏需要单独逻辑 -- 拒绝｜提交｜审核
  // 嘉吉CTS部分开单成功，部分拒绝时，可进入如下操作---没有全选才进入的逻辑判断
  // 1、允许CE将已拒绝的提单单独进行提交操作
  // 2、允许温氏对已开单的提单发起取消，且CE可进行单独审核
  const checkWsCtsAllow = (data: any) => {
    const isNotAllowArr = data.filter((item: any) => item.statusConsistent === false);
    return isNotAllowArr.length === 0;
  };

  const shoApproveNotice = (data: any[], key: string) => {
    if (data.length) {
      const keyName: any = {
        WS: "温氏",
        TW: "通威"
      };
      let str = "";
      const lable = `为${keyName[key]}拼单，请在获取所拼全部提单后再进行审核`;
      data.forEach((item: { doId: any }) => {
        if (item.doId) {
          str += `${item.doId},`;
        }
      });
      if (str.length > 0) {
        str = str.substring(0, str.length - 1);
      }
      const notice = `<${str}>${lable}`;
      message.error(notice);
    }
  };

  // 拆分 approveBatchHandler 函数，降低复杂度 -01
  const approveBatchScene = useCallback(
    (fieldType: string = "") => {
      const isAll = isTwWsIncludeAll(data, fieldType);
      if (isTwWsReceive(data) && isAll) {
        if (text && keys !== type.DELIVERY_DO && keys !== type.APPROVED_DO) {
          approveDO(data[0]);
        } else {
          handleSubmit(data);
        }
      } else {
        setLoading(false);
        shoApproveNotice(data, fieldType);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, isTwWsIncludeAll, keys, text]
  );

  const approveBatchHandler = useCallback(async () => {
    // 提交前关闭提交前的提示窗口
    setRequestSapFailVisible(false);
    setLoading(true);
    if (!text && keys === type.REJECT_DO) {
      handleSubmit(data);
      return;
    }

    if (text) {
      if (keys === type.REJECT_DO) {
        approveDO(data[0]);
        setLoading(false);
        return;
      } else if (keys === type.DELIVERY_DO || keys === type.APPROVED_DO) {
        itemData = data[0];
        if (await isRefuseSubmit()) {
          setLoading(false);
          return;
        }
        data[0] = itemData;
      }
    }

    if (isTwWsCan(data, "TW")) {
      approveBatchScene("TW");
    } else if (isTwWsCan(data, "WS")) {
      approveBatchScene("WS");
    } else if (text && keys !== type.DELIVERY_DO && keys !== type.APPROVED_DO) {
      approveDO(data[0]);
    } else {
      handleSubmit(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isTwWsIncludeAll, keys, text]);

  // 确认提交前检查提交数据，判断是否弹出确认提交的相关提示
  const approveBatchBeforeCheckHandler = useCallback(async () => {
    // // 同一组拼车的提单一起提交，否则会提交失败
    // const mergedDos = data.filter((row: any) => {
    //   return (row.doMerge || row.doInternalMerge) && row.mergeGroup && row.mergeOrder;
    // });
    // if (mergedDos) {
    //   if (hasMissingMergedDos(mergedDos)) {
    //     // 打开拼单提交提示
    //     setMissingMergedDosVisible(true);
    //     return;
    //   }
    // }
    const rejectReasonList = ["调用 SAP 接口失败"];
    const hasRequestSapFailItem = data.some((item: any) => {
      return item.doStatus === "已拒绝" && rejectReasonList.includes(item.rejectReason)
    });
    // 用户对有SAP接口调用失败的提单重新提交审核时系统会弹窗一个二次确认弹窗
    if (hasRequestSapFailItem) {
      setRequestSapFailVisible(true);
    } else {
      approveBatchHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isTwWsIncludeAll, keys, text]);

  // const hasMissingMergedDos = (mergedDos: any) => {
  //   const mergeDosGroup: any = {};
  //   let hasMissingMergedDosTemp = false;
  //   mergedDos.forEach((item: any) => {
  //     const groupKey = `${item.mergeGroup}_${item.mergeOrder}`
  //     if (Object.keys(mergeDosGroup).includes(groupKey)) {
  //       mergeDosGroup[groupKey].push(item)
  //     } else {
  //       mergeDosGroup[groupKey] = [item]
  //     }
  //   });
  //   if (Object.keys(mergeDosGroup).length) {
  //     hasMissingMergedDosTemp = Object.keys(mergeDosGroup).some((key: any) => {
  //       // 获取拼单组总的拼单数量
  //       const totalMergeDos = extractNumber(mergeDosGroup[key][0].mergeRemark);
  //       return totalMergeDos !== null && totalMergeDos > mergeDosGroup[key].length
  //     })
  //   }
  //   return hasMissingMergedDosTemp;
  // };

  const updateDosApiRequest = async (param: any) => {
    itemData = param[0];
    if (await isRefuseSubmit()) {
      setLoading(false);
      return;
    }
    setLoading(false);
    setLoadingStatus && setLoadingStatus(true);
    updateDos(param, {
      type: "approveDo"
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          setVisible(true);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        if (exception && exception.errorMsg) {
          message.error(exception.errorMsg);
        } else {
          message.error("抱歉，系统连接出现问题，请稍后再试。");
        }
      })
      .finally(() => {
        setLoadingStatus && setLoadingStatus(false);
      });
  };

  const updateBySingleApproved = (data: any) => {
    const twWsCheckBySingleApproved = (filedType: string) => {
      const isAll = isTwWsIncludeAll(data, filedType);
      if (isTwWsReceive(data) && isAll) {
        updateDosApiRequest(data);
      } else {
        setLoading(false);
        shoApproveNotice(data, filedType);
      }
    };

    if (isTwWsCan(data, "TW")) {
      twWsCheckBySingleApproved("TW");
    } else if (isTwWsCan(data, "WS")) {
      twWsCheckBySingleApproved("WS");
    } else {
      updateDosApiRequest(data);
    }
  };

  // 抽取batchRefuseSubmit方法中的赋值
  const setValueByParam = (
    isNullData: boolean,
    isCheckFail: boolean,
    isFalseDate: boolean,
    checkFailList: any,
    falseDateList: any
  ) => {
    if (isNullData) {
      message.error("提货委托函中缺少关键必填信息，请检查并重新提交");
      return true;
    }
    if (isCheckFail && keys === type.ALL_DO) {
      message.error(`平台序列号（${checkFailList}）的提单已提交过，禁止再次提交`);
      return true;
    }
    if (isFalseDate) {
      message.warning(`平台序列号（${falseDateList}）的提单不在合同期内，禁止提交`);
      return true;
    }
  };

  const batchRefuseSubmit = async (data: any) => {
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    let isFalseDate = false;
    let isNullData = false;
    // 状态是否为 ·信用审核失败·
    let isCheckFail = false;
    const checkFailList: any = [];
    const falseDateList = [];
    const promises = data.map((val: any) => {
      const { contractNo, customerCode, contractItem } = val;
      return getContractDetails(contractNo, customerCode, contractItem);
    });
    const results = await Promise.all(promises);
    for (let i = 0; i < data.length; i += 1) {
      const item = data[i];
      const { doId } = item;
      if (results.length > i) {
        const cDetail = results[i];
        const startDate = expandDateSelect(cDetail, "start");
        const endDate = expandDateSelect(cDetail, "end");
        if ((currentDate < startDate || currentDate > endDate) && item.doStatus !== "待取消") {
          falseDateList.push(doId);
          isFalseDate = true;
        }
        // 判断状态是否为信用审核失败即存在提单头编号，信用审核失败的不能再提交 mark: rafu
        if (item.salesOrderNumber) {
          checkFailList.push(doId);
          isCheckFail = true;
        }
        isNullData = doVerification(item, cDetail);
        const dscTransportTypes = [
          "ACM配送车",
          "VA配送车",
          "ACM自提车",
          "VA自提车",
          "ACM外库自提车",
          "ACM外库配送船",
          "VA外库自提车",
          "VA外库配送车",
          "ACM外江码头"
        ];
        if (dscTransportTypes.indexOf(item.dscTransportType) !== -1 && !isNullData) {
          data[i].haveEntry = "true";
        }
      }
    }
    return setValueByParam(isNullData, isCheckFail, isFalseDate, checkFailList, falseDateList);
  };

  // handleSubmit 函数拆分，降低复杂度 - 01
  const submitCheck_allDo = async (data: any) => {
    if (await batchRefuseSubmit(data)) {
      setLoading(false);
      return false;
    }
    let isError = false;
    for (let dataItem of data) {
      if (dataItem.action !== type.DO_CANCELLED_BY_EXTERNAL_USER) {
        if (
          dataItem.doStatus !== type.CANCELLING &&
          (!dataItem.contractNo || (!isOldData(dataItem) && dataItem.haveEntry === "未录入"))
        ) {
          isError = true;
        }
      }
    }
    if (isError) {
      message.error("提货委托函中缺少关键必填信息，请检查并重新提交");
      setLoading(false);
      return false;
    }
    return true;
  };

  // handleSubmit 函数拆分，降低复杂度 - 02
  const submitCheck_deliveryDo = async (data: any) => {
    const noMatchData = data.find(
      (item: any) =>
        [
          "ACM配送车",
          "VA配送车",
          "ACM外江码头",
          "ACM外库自提车",
          "ACM外库配送船",
          "VA外库自提车",
          "VA外库配送车"
        ].indexOf(item.dscTransportType) === -1
    );
    if (noMatchData) {
      message.error("该操作只针对“配送船”和“外库提货");
      setLoading(false);
      return false;
    }
    if (await batchRefuseSubmit(data)) {
      setLoading(false);
      return false;
    }
    const errorList = data.find((item: any) => !item.contractNo || (!isOldData(item) && item.haveEntry === "未录入"));

    if (errorList) {
      message.error("提货委托函中缺少关键必填信息，请检查并重新提交");
      setLoading(false);
      return false;
    }
    return true;
  };

  // handleSubmit 函数拆分，降低复杂度 - 03
  const submitCheck_other = async (data: any) => {
    if (await batchRefuseSubmit(data)) {
      setLoading(false);
      return false;
    }
    const errorList = data.find((item: any) => !item.contractNo || (!isOldData(item) && item.haveEntry === "未录入"));
    if (errorList) {
      message.error("提货委托函中缺少关键必填信息，请检查并重新提交");
      setLoading(false);
      return false;
    }
    if (keys === type.REJECT_DO) {
      submitByBatchHandler();
      setLoading(false);
      return false;
    }
    return true;
  };

  const handleSubmit = async (data: any) => {
    let checkStatus: boolean = false;
    if (keys === type.ALL_DO) {
      checkStatus = await submitCheck_allDo(data);
    } else if (keys === type.DELIVERY_DO) {
      checkStatus = await submitCheck_deliveryDo(data);
    } else if (keys === type.EDITED_DO || keys === type.APPROVED_DO || keys === type.REJECT_DO) {
      checkStatus = await submitCheck_other(data);
    }
    if (!checkStatus) {
      return;
    }
    updateDosApiRequest(data);
  };

  // 批量审批通过
  const updateByBatchApproved = () => {
    const twWsCheckByBatchApproved = (filedType: string) => {
      const isAll = isTwWsIncludeAll(data, filedType);
      if (isTwWsReceive(data) && isAll) {
        updateDosApiRequest(data);
      } else {
        shoApproveNotice(data, filedType);
      }
    };

    if (isTwWsCan(data, "TW")) {
      twWsCheckByBatchApproved("TW");
    } else if (isTwWsCan(data, "WS")) {
      twWsCheckByBatchApproved("WS");
    } else {
      updateDosApiRequest(data);
    }
  };

  // 判断勾选的提单是否是同一个合同编号+批次+业务线
  const isSameGroupDos = () => {
    const ckDataList = data.map((item: any) => `${item.contractNo}-${item.batch}-${item.commodity}`);
    const temp = ckDataList.find((item: any) => item !== ckDataList[0]);
    setIsNotSameDos(temp);
    return temp;
  };

  // 已拒绝审批通过
  const submitByBatchHandler = () => {
    if (isSameGroupDos()) {
      const msg = isNotSameDos
        ? "请选择同一合同/批次的“已拒绝”提单进行批量提交"
        : "批量编辑仅适用于处理同一产品的提货委托函，请检查并重新选择。";
      message.error(msg);
      setLoading(false);
      return;
    }
    let doQuantityTotal: number = 0;
    for (let dataItem of data) {
      doQuantityTotal += dataItem.doQuantity;
    }
    // 请求cidosContracts的参数
    const options = {
      buyer: `${data[0].buyer} (${data[0].customerCode})`,
      plant: data[0].plantCode,
      businessLine: data[0].businessLine,
      // 新增买方、卖方、业务线的Code
      customerCode: data[0].customerCode,
      orgCode: data[0].orgCode,
      divisionCode: data[0].divisionCode,
      contractNo: data[0].contractNo,
      batch: data[0].batch
    };
    getCIDOSContracts(options)
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          const contractListData = Array.isArray(res.data.contractList) ? res.data.contractList : [];
          if (doQuantityTotal > contractListData[0].actualRemainingDoQty) {
            setOverDoQutityerrorContent({
              doQuantityTotalValue: doQuantityTotal,
              contractNoAndBatch: `${contractListData[0].contractNo}-${contractListData[0].batch}`,
              overDoQutityValue: parseFloat((doQuantityTotal - contractListData[0].actualRemainingDoQty).toFixed(4))
            });
            setLoading(false);
            setRejectVisible(true);
          } else {
            updateBySingleApproved(data);
          }
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      });
  };

  // 点击超量提示确认按钮，确认提交
  const handleSubmitConfirm = useCallback(() => {
    if (isSingleSubmit) {
      updateBySingleApproved(data);
    } else {
      updateByBatchApproved();
    }
    if (isSingleSubmit) {
      setIsSingleSubmit(false);
    }
    setRejectVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSingleSubmit]);

  const getContractDetails = (contractNo: any, customerCode: string, contractItem: string) => {
    return getContractDetailsById({
      contractNo: contractNo,
      customerCode,
      batch: addZeroToBatch(contractItem)
    })
      .then((res: any) => {
        if (res && res.data) {
          const { contract } = res.data;
          if (contract) {
            const { batch, contractDetailInfo } = contract;
            return contractDetailInfo && batch ? { ...contractDetailInfo, ...batch[0] } : {};
          }
        }
      })
      .catch(exception => {
        console.error(`Failure: ${exception}`);
      });
  };

  const isRefuseSubmit = async () => {
    const { contractNo, customerCode, contractItem } = itemData;
    const contractDetails = await getContractDetails(contractNo, customerCode, contractItem);
    if (doVerification(itemData, contractDetails)) {
      message.error("提货委托函中缺少关键必填信息，请检查并重新提交");
      return true;
    }
    const dscTransportTypes = [
      "ACM配送车",
      "VA配送车",
      "ACM自提车",
      "VA自提车",
      "ACM外库自提车",
      "ACM外库配送船",
      "VA外库自提车",
      "VA外库配送车",
      "ACM外江码头"
    ];
    if (dscTransportTypes.indexOf(itemData.dscTransportType) !== -1) {
      itemData.haveEntry = "true";
    }
    const startDate = expandDateSelect(contractDetails, "start");
    const endDate = expandDateSelect(contractDetails, "end");
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    if ((currentDate < startDate || currentDate > endDate) && itemData.doStatus !== "待取消") {
      message.warning("该提单不在合同期内，禁止提交");
      return true;
    }
    return false;
  };

  // 判断cycleId和nominationNumber是否为必填
  const isRequireCN = (item: any) => item && isExternalDeliveryByShip(item) && isDeliveryByShip(item);
  // 判断cycleId和nominationNumber是否填写
  const isExitCN = (item: any = {}) => {
    if (item && Object.keys(item).length > 0) {
      const { cycleId, nominationNumber } = item;
      if (cycleId && nominationNumber) {
        return true;
      }
      return false;
    }
    return false;
  };

  // approveDO 函数逻辑拆分，减少复杂度 -01
  const doApproveDOCheck_allDo = async (itemData: any) => {
    if (await isRefuseSubmit()) {
      setLoading(false);
      return false;
    }
    if (itemData.action !== type.DO_CANCELLED_BY_EXTERNAL_USER) {
      // 暂时屏蔽提交校验逻辑 1405 - 1428
      if (
        itemData.doStatus !== type.CANCELLING &&
        (!itemData.contractNo || (!isOldData(itemData) && itemData.haveEntry === "未录入"))
      ) {
        message.error("提货委托函中缺少关键必填信息，请检查并重新提交");
        setLoading(false);
        return false;
      }
    }
    const doType = itemData.doStatus === type.CANCELLING ? type.APPROVE_CANCEL_DO : type.APPROVED_DO;
    return doType;
  };

  // approveDO 函数逻辑拆分，减少复杂度 -02
  const doApproveDOCheck_editDo = async (itemData: any) => {
    if (await isRefuseSubmit()) {
      setLoading(false);
      return false;
    }
    if (!itemData.contractNo || (!isOldData(itemData) && itemData.haveEntry === "未录入")) {
      message.error("提货委托函中缺少关键必填信息，请检查并重新提交");
      setLoading(false);
      return false;
    }
    return true;
  };

  // approveDO 函数逻辑拆分，减少复杂度 -03
  const doApproveDOCheck_rejectDo = (itemData: any) => {
    if (
      !itemData.contractNo ||
      (isRequireCN(itemData) && !isExitCN(itemData)) ||
      (!isOldData(itemData) && itemData.haveEntry === "未录入")
    ) {
      message.error("提货委托函中缺少关键必填信息，请检查并重新提交");
      setLoading(false);
      return;
    }

    // 请求cidosContracts的参数
    const options = {
      buyer: `${itemData.buyer} (${itemData.customerCode})`,
      plant: itemData.plantCode,
      businessLine: itemData.businessLine,
      // 新增买方、卖方、业务线的Code
      customerCode: itemData.customerCode,
      orgCode: itemData.orgCode,
      divisionCode: itemData.divisionCode,
      contractNo: itemData.contractNo,
      batch: itemData.batch
    };
    getCIDOSContracts(options)
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
          return;
        }
        const contractListData = Array.isArray(res?.data?.contractList) ? res?.data?.contractList : [];
        if (itemData.doQuantity > contractListData?.[0]?.actualRemainingDoQty) {
          setLoading(false);
          setRejectVisible(true);
        } else {
          updateBySingleApproved([itemData]);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      });
  };

  // approveDO 函数逻辑拆分，减少复杂度 -04
  const doApproveDOCheck_doUpdateDo = (itemData: any, doType: string) => {
    setLoading(false);
    setLoadingStatus && setLoadingStatus(true);
    updateDos([itemData], {
      type: doType
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else if (doType === type.APPROVED_DO) {
          // 审批成功后弹窗提示
          setVisible(true);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        if (exception && exception.errorMsg) {
          message.error(exception.errorMsg);
        } else {
          message.error("抱歉，系统连接出现问题，请稍后再试。");
        }
      })
      .finally(() => {
        setLoadingStatus && setLoadingStatus(false);
      });
  };

  // 审批、批准
  const approveDO = async (data: any) => {
    let doType = type.APPROVED_DO;
    itemData = data;
    if (keys === type.ALL_DO) {
      const res = await doApproveDOCheck_allDo(itemData);
      if (res) {
        doType = res;
      } else {
        return;
      }
    } else if (keys === type.EDITED_DO) {
      const res = await doApproveDOCheck_editDo(itemData);
      if (!res) {
        return;
      }
    }

    if (keys === type.REJECT_DO) {
      doApproveDOCheck_rejectDo(itemData);
    } else {
      doApproveDOCheck_doUpdateDo(itemData, doType);
    }
  };

  const onHandleVisibleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const onHandleVisibleCloseAndGetData = useCallback(() => {
    setVisible(false);
    if (getTable) {
      getTable();
    }
  }, [getTable]);

  const onHandleRejectVisibleClose = useCallback(() => {
    setRejectVisible(false);
  }, []);

  const onHandleRequestSapfailVisibleClose = useCallback(() => {
    setRequestSapFailVisible(false);
  }, []);

  // const onHandleMissingMergedDosVisibleClose = useCallback(() => {
  //   setMissingMergedDosVisible(false)
  // }, []);

  return (
    <>
      <Button
        type={props.text ? "text" : "primary"}
        onClick={approveBatchBeforeCheckHandler}
        disabled={props.data.length === 0}
        loading={props.text ? false : loading}
      >
        {props.children}
      </Button>
      <Modal
        title="提交成功"
        width={300}
        visible={visible}
        centered
        maskClosable={false}
        onCancel={onHandleVisibleClose}
        footer={
          <Button data-testid="modal-close" type="primary" onClick={onHandleVisibleCloseAndGetData}>
            确认
          </Button>
        }
      >
        <span>提交成功，等待系统处理</span>
      </Modal>
      <Modal
        title="提示！"
        width={500}
        visible={rejectVisible}
        centered
        maskClosable={false}
        onCancel={onHandleRejectVisibleClose}
        footer={
          <Button data-testid="tips-close" type="primary" onClick={handleSubmitConfirm}>
            确认
          </Button>
        }
      >
        {`所选提单总量为 ${overDoQutityerrorContent.doQuantityTotalValue} 
        吨，超出本合同 ${overDoQutityerrorContent.contractNoAndBatch} 
        剩余可预制开单量 ${overDoQutityerrorContent.overDoQutityValue} 
        吨，如需继续提交请点击“确认”。`}
      </Modal>
      <Modal
        title="提示！"
        width={500}
        visible={requestSapfailVisible}
        centered
        maskClosable={false}
        onCancel={onHandleRequestSapfailVisibleClose}
        footer={
          <>
            <Button data-testid="tips-close" onClick={onHandleRequestSapfailVisibleClose}>
              取消
            </Button>
            <Button data-testid="submit-btn" type="primary" onClick={approveBatchHandler}>
              确认
            </Button>
          </>
        }
      >
        提交的提单存在拒绝原因为“调用SAP接口失败”的提单，请检查提单在SAP系统里的状态。<br></br>点击“确认”按钮该提单将重新提交到SAP，点击“取消”按钮取消该提单提交。
      </Modal>
      {/* <Modal
        title="提示"
        visible={missingMergedDosVisible}
        centered
        maskClosable={false}
        onCancel={onHandleMissingMergedDosVisibleClose}
        footer={
          <Button type="primary" onClick={onHandleMissingMergedDosVisibleClose}>
            确认
          </Button>
        }
      >
        同一拼车的所有提单必须同时提交
      </Modal> */}
    </>
  );
};

export default ConfirmSubmit;
