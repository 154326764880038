import { Table, Tooltip } from "antd";

const TableListConfirmed = (props: any) => {
  const cellStyle = () => {
    return {
      style: {
        maxWidth: 150,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        cursor: "pointer"
      }
    };
  };
  const returnDayTime = (time: string) => {
    let s = time;
    s = s
      .replace(/T/g, " ")
      .replace(/-/g, "-")
      .replace(/\.\d+/, " ");
    return s;
  };
  const getAppliedTotalQuantity = (data: any) => {
    if (data.operateType === "明细点价") {
      return data.lineNumber ? data.lineNumber.toFixed(3) : "-";
    } else if (data.operateType === "汇总点价") {
      return data.appliedTotalQuantity ? data.appliedTotalQuantity.toFixed(3) : "-";
    }
    return "-";
  };
  const getPrice = (data: any) => {
    if (data.operateType === "明细点价") {
      return data.linePrice ? data.linePrice.toFixed(2) : "-";
    } else if (data.operateType === "汇总点价") {
      return data.price ? data.price.toFixed(2) : "-";
    }
    return "-";
  };
  const columns: any = [
    {
      title: "序号",
      dataIndex: "cid",
      key: "cid"
    },
    {
      title: "点价申请时间",
      render: (record: any) => (record.appliedTime !== null ? returnDayTime(record.appliedTime) : null)
    },
    {
      title: "申请单号",
      dataIndex: "appliedNo",
      key: "appliedNo"
    },
    {
      title: "点价申请类型",
      dataIndex: "appliedType",
      key: "appliedType"
    },
    {
      title: "物料描述",
      dataIndex: "materialDesc",
      key: "materialDesc"
    },
    {
      title: "本次点价申请总量",
      render: (record: any) => <>{getAppliedTotalQuantity(record)}</>
    },
    {
      title: "本次点价价格",
      render: (record: any) => <>{getPrice(record)}</>
    },
    {
      title: "合约月份",
      dataIndex: "contractMonth",
      key: "contractMonth",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "基差合同号",
      dataIndex: "basisContractNo",
      key: "basisContractNo",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "买方公司",
      dataIndex: "buyer",
      key: "buyer"
    },
    {
      title: "卖方公司",
      dataIndex: "seller",
      key: "seller"
    },
    {
      title: "点价状态",
      dataIndex: "pointState",
      key: "pointState"
    },
    {
      title: "确认点价数量",
      dataIndex: "confirmNumber",
      key: "confirmNumber",
      render: (text: any) => <>{text?.toFixed(3) || "-"}</>
    },
    {
      title: "确认点价单价",
      dataIndex: "confirmPrice",
      key: "confirmPrice",
      render: (text: any) => <>{text?.toFixed(2) || "-"}</>
    },
    {
      title: "点价操作类型",
      dataIndex: "operateType",
      key: "operateType"
    },
    {
      title: "申请接收时间",
      render: (record: any) => (record.appliedAcceptTime ? returnDayTime(record.appliedAcceptTime) : null)
    },
    {
      title: "申请回传时间",
      render: (record: any) => (record.updateTime ? returnDayTime(record.updateTime) : null)
    },
    {
      title: "嘉吉合同号",
      dataIndex: "crglContractNo",
      key: "crglContractNo",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "包装规格",
      dataIndex: "specification",
      key: "specification",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "计数单位",
      dataIndex: "unitCode",
      key: "unitCode"
    },
    {
      title: "客户备注",
      onCell: cellStyle,
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.thirdRemark || null}>
          {record.thirdRemark || "-"}
        </Tooltip>
      )
    },
    {
      title: "嘉吉备注",
      onCell: cellStyle,
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.remark || null}>
          {record.remark || "-"}
        </Tooltip>
      )
    }
  ];

  return (
    <div className="contract-confirmation-table">
      <Table
        columns={columns}
        pagination={props.paginationOpt}
        scroll={{ x: "max-content" }}
        dataSource={props.tableData}
        loading={props.loadingConfirmed}
        rowKey="id"
        size="small"
      />
    </div>
  );
};

export default TableListConfirmed;
