import { getSwitcherSetting, updateSwitcherSetting } from "./apiData/switcher";

const getSwitcherData = () => ({
  type: "GET_SWITCHER_DATA",
  Get: getSwitcherSetting
});

const getSwitcherSuccess = (data = {}) => ({
  type: "GET_SWITCHER_DATA_SUCCESS",
  payload: data
});

const getSwitcherError = (error: any) => ({
  type: "GET_SWITCHER_DATA_ERROR",
  payload: { error }
});

const updateSwitcherData = (data: any, setLoading: any, setVisible: any) => ({
  type: "UPDATE_SWITCHER_DATA",
  Patch: updateSwitcherSetting,
  payload: { data, setLoading, setVisible }
});

const updateSwitcherSuccess = (data = {}) => ({
  type: "UPDATE_SWITCHER_DATA_SUCCESS",
  payload: data
});

const updateSwitcherError = (error: any) => ({
  type: "UPDATE_SWITCHER_DATA_ERROR",
  payload: { error }
});

const updateSwitcherErrorStatus = () => ({
  type: "UPDATE_SWITCHER_DATA_ERROR_STATUS",
  payload: {}
});

const updateSwitcherBuyer = (buyer: any) => ({
  type: "UPDATE_SWITCHER_DATA_BUYER",
  payload: { buyer }
});

export {
  updateSwitcherData,
  updateSwitcherSuccess,
  updateSwitcherError,
  getSwitcherData,
  getSwitcherSuccess,
  getSwitcherError,
  updateSwitcherBuyer,
  updateSwitcherErrorStatus
};
