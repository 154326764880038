import { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Table, message } from "antd";
import moment from "moment";
import "./index.scss";

import { getDeliveryOrdersByGroupNoApi } from "@/api/slotbooking";
import { convertUTCDateToChinaDateAR, cutHeaderZero, cutBatchNoZero, isHaveThisPermission, formatValue } from "@/utils";

// 定义函数组件的props相关参数
type myProps = { doClose: () => void; groupNo: string; groupCustomer: string };

const GroupDetailModal = (props: myProps) => {
  const [groupDataSource, setGroupDataSource] = useState([] as any[]);
  const [isGroupTableLoading, setIsGroupTableLoading] = useState(false);

  const urlParamsRef = useRef({
    limit: 100,
    offset: 0
  });

  const columnsData = [
    { title: "提单头编号", dataIndex: "salesOrderNumber", key: "salesOrderNumber" },
    { title: "提单行项目编号", dataIndex: "salesOrderItem", key: "salesOrderItem" },
    { title: "合同编号", dataIndex: "contractNo", key: "contractNo" },
    { title: "合同行项目编号", dataIndex: "batch", key: "batch" },
    { title: "产品名称", dataIndex: "productName", key: "productName" },
    { title: "包装规格", dataIndex: "packageSpecificationCn", key: "packageSpecificationCn" },
    { title: "计划提货数量", dataIndex: "doQuantity", key: "doQuantity" },
    { title: "提单提交日期", dataIndex: "submitDate", key: "submitDate" },
    { title: "买方", dataIndex: "buyer", key: "buyer" },
    { title: "卖方", dataIndex: "orgName", key: "orgName" },
    { title: "车/船号", dataIndex: "truckPlateNumber", key: "truckPlateNumber" },
    { title: "司机姓名", dataIndex: "driverName", key: "driverName" },
    { title: "司机联系方式", dataIndex: "driverContactNumber", key: "driverContactNumber" }
  ];

  useEffect(() => {
    const getTableDataList = () => {
      const { groupNo, groupCustomer } = props;
      const searchParams = { ...urlParamsRef.current, groupNo: groupNo, customerCode: groupCustomer };
      setIsGroupTableLoading(true);
      getDeliveryOrdersByGroupNoApi(searchParams)
        .then((responseJSON: any) => {
          if (responseJSON?.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(responseJSON.errorMsg);
          } else if (responseJSON?.data) {
            const tableRecordData = responseJSON.data || [];
            const groupDataSourceTemp = tableRecordData.map(mapTableData);
            setGroupDataSource(groupDataSourceTemp);
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          setGroupDataSource([]);
        })
        .finally(() => {
          setIsGroupTableLoading(false);
        });
    };

    // 格式化列表数据
    const mapTableData = (data: any) => ({
      ...data,
      salesOrderNumber: cutHeaderZero(data.salesOrderNumber) || "-",
      salesOrderItem: cutHeaderZero(data.salesOrderItem) || "-",
      contractNo: formatValue(data.contractNo, "-"),
      batch: cutBatchNoZero(data.batch) || "-",
      productName: isHaveThisPermission("CNExternalUsers") ? data.materialSalesText : data.productName,
      packageSpecificationCn: formatValue(data.packageSpecificationCn, "-"),
      doQuantity: formatValue(data.doQuantity, "-"),
      submitDate: data.submitDate ? mapDateTime(data.submitDate) : "-",
      buyer: formatValue(data.buyer, "-"),
      orgName: formatValue(data.orgName, "-"),
      truckPlateNumber: formatValue(data.truckPlateNumber, "-"),
      driverName: formatValue(data.driverName, "-"),
      driverContactNumber: formatValue(data.driverContactNumber, "-")
    });

    const mapDateTime = (date: string) => {
      const chinaDateTime = convertUTCDateToChinaDateAR(date);
      return `${moment(chinaDateTime.date).format("YYYY-MM-DD")} ${chinaDateTime.time}`;
    };

    getTableDataList();
  }, [props]);

  const handleClose = useCallback(() => {
    props.doClose();
  }, [props]);

  return (
    <Modal
      centered
      title="提单详情"
      width="60%"
      visible={true}
      maskClosable={false}
      onCancel={handleClose}
      footer={null}
    >
      <div className="hdp-uf hdp-uf-dc group-detail-content">
        <Table
          size="small"
          loading={isGroupTableLoading}
          dataSource={groupDataSource}
          columns={columnsData}
          pagination={false}
          rowKey="doId"
          scroll={{ x: "max-content" }}
          style={{ minHeight: "200px" }}
        />
      </div>
    </Modal>
  );
};

export default GroupDetailModal;
