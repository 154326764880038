import RequestService from "@/api/request";

// 更换预约方式
const updateAppointmentMode = (dataObj: object) =>
  RequestService({
    url: "/slotbookingsap/updateAppointmentMode",
    method: "post",
    data: dataObj
  });

const getWeChatBindingInfo = (dataObj: object) =>
  RequestService({
    url: "/slotbookingsap/wechat/bindingInfo",
    method: "post",
    data: dataObj
  });

// 生成二维码
const getWeChatApplyQRCodeUrl = (dataObj: object) =>
  RequestService({
    url: "/slotbookingsap/wechat/applyQrcode",
    method: "post",
    data: dataObj
  });

// 修改密码
const postUpdatePassword = (dataObj: object) =>
  RequestService({
    baseURL: process.env.REACT_APP_USER_SERVICES,
    url: "/users/changepassword",
    method: "post",
    data: dataObj
  });

const setWeChatMessageReceiveStatus = (dataObj: object) =>
  RequestService({
    url: "/slotbookingsap/wechat/messageSwitch",
    method: "post",
    data: dataObj,
  });

const setUnboundWeChat = (dataObj: object) =>
  RequestService({
    url: "/slotbookingsap/wechat/unbound",
    method: "post",
    data: dataObj
  });

export { updateAppointmentMode, getWeChatApplyQRCodeUrl, postUpdatePassword, getWeChatBindingInfo, setWeChatMessageReceiveStatus, setUnboundWeChat };
