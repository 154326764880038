// slot-booking 查询条件
import { useState, useEffect, forwardRef, useCallback, useImperativeHandle } from "react";
import { Card, Form, Select, message, Spin } from "antd";
import "./index.scss";

import { allSupplier } from "@/utils/constant";
import { getDateStrRuleConfigApi, getProductQueryApi, updateDateRuleConfigApi } from "@/api/slotbooking";
import MaximumSettingModal from "../maximumSettingModal";

// 定义函数组件的props相关参数
type myProps = {
  get_reservation_filters?: () => void;
  switcherData?: any;
  userDetailsData?: any;
};

const ReservationFiltersFunc = (props: myProps, ref: any) => {
  const [form] = Form.useForm(); // form表单实例
  const [platformDataOptions, setPlatformDataOptions] = useState([]); // platform可选列表
  const [dateStrsVal, setDateStrsVal] = useState<string[]>([]); // 预设中的所有可选日期：年-月-日
  const [dateStrArrInit, setDateStrArrInit] = useState<object[]>([]); // 可选日期--初始化配置信息
  const [dateStrArr, setDateStrArr] = useState<object[]>([]); // 可选日期--详细配置信息
  const [currentMaximumSettingDateObj, setCurrentMaximumSettingDateObj] = useState<any>(); // 当前选中的日期
  const [isShowMaximumSettingModal, setIsShowMaximumSettingModal] = useState<boolean>(false); // 当前选中的日期
  const [isActionLoading, setIsActionLoading] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    getFormFields,
    updateDateRuleConfig,
    settingMaximum
  }));

  const getFormFields = useCallback(() => {
    return { ...form.getFieldsValue(true) };
  }, [form]);

  // 设置可查看和配置的时间
  const setDateStrsArrInit = () => {
    const dateStrsValTemp = [];
    const dateStrArrTemp = [];
    const currentDateTime = new Date();
    const currentDateYear = currentDateTime.getFullYear();
    const currentDateMonth =
      currentDateTime.getMonth() + 1 < 10 ? `0${currentDateTime.getMonth() + 1}` : currentDateTime.getMonth() + 1;
    const currentDateDay = currentDateTime.getDate() < 10 ? `0${currentDateTime.getDate()}` : currentDateTime.getDate();
    // 加入当天日期
    dateStrArrTemp.push({
      dateStr: [currentDateYear, currentDateMonth, currentDateDay].join("-"),
      dateStrView: "今日",
      status: ""
    });
    dateStrsValTemp.push([currentDateYear, currentDateMonth, currentDateDay].join("-"));

    // 设置未来6天的日期
    const oneDay = 24 * 3600 * 1000;
    for (let i = 1; i < 7; i += 1) {
      const nowDateTime = new Date(currentDateTime.getTime() + oneDay * i);
      const nowDateTimeYear = nowDateTime.getFullYear();
      const nowDateTimeMonth =
        nowDateTime.getMonth() + 1 < 10 ? `0${nowDateTime.getMonth() + 1}` : nowDateTime.getMonth() + 1;
      const nowDateTimeDay = nowDateTime.getDate() < 10 ? `0${nowDateTime.getDate()}` : nowDateTime.getDate();
      dateStrArrTemp.push({
        dateStr: [nowDateTimeYear, nowDateTimeMonth, nowDateTimeDay].join("-"),
        dateStrView: [nowDateTimeMonth, nowDateTimeDay].join("/"),
        status: ""
      });
      dateStrsValTemp.push([nowDateTimeYear, nowDateTimeMonth, nowDateTimeDay].join("-"));
    }

    // 设置时间数组
    setDateStrsVal(dateStrsValTemp);
    setDateStrArrInit(dateStrArrTemp);
    setDateStrArr(dateStrArrTemp);
  };

  const notify_current_change_data = useCallback(() => {
    if (props.get_reservation_filters) {
      props.get_reservation_filters();
    }
  }, [props]);

  // 获取平台可选数据
  const getPlatformsDataOptions = useCallback(
    (orgCodeVal: string) => {
      const getParamsObj = {
        vague: "",
        orgCode: orgCodeVal,
        queryType: "platforms"
      };
      setIsActionLoading(true);
      getProductQueryApi(getParamsObj)
        .then((responseJSON: any) => {
          if (responseJSON?.data?.platforms) {
            const platformsData = responseJSON.data.platforms;
            const NewData = platformsData.map((item: any) => ({
              label: item.name,
              value: item.name,
              loading_rule: item.loadingRule,
              disabled:
                !(
                  props.userDetailsData.groups?.includes("DXP_DSC_Role_CN_Cargill_CE") ||
                  props.userDetailsData.groups?.includes("DXP_DSC_Role_CN_Cargill_CM")
                ) && item.name === "反向预约"
            }));
            setPlatformDataOptions(NewData);
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
        })
        .finally(() => {
          setIsActionLoading(false);
        });
    },
    [props.userDetailsData.groups]
  );

  const checkShouldSettingInitData = useCallback(
    (currentOnDateValue: string | undefined, dateConfigInfoArr: any[]) => {
      if (currentOnDateValue) {
        dateConfigInfoArr.forEach((dateRuleItem: any) => {
          if (dateRuleItem.dateStr === currentOnDateValue) {
            form.setFieldsValue({
              dateStr: dateRuleItem.dateStr,
              status: dateRuleItem.status,
              dateStrExtra: dateRuleItem
            });
            // 通知父级组件可以获取更新后的信息
            notify_current_change_data();
          }
        });
      }
    },
    [form, notify_current_change_data]
  );

  // 获取当前日期的配置的详细信息
  const getDateStrRuleConfig = useCallback(
    (orgCodeVal?: string, platformVal?: string, currentOnDateValue?: string) => {
      // 获取当前日期数组的相关配置信息
      const postDataObj = {
        orgCode: orgCodeVal || form.getFieldValue("orgCode"),
        platform: platformVal || form.getFieldValue("platform"),
        dateStrs: dateStrsVal
      };
      setIsActionLoading(true);
      getDateStrRuleConfigApi(postDataObj)
        .then((responseJSON: any) => {
          if (responseJSON?.code === "99999") {
            // 99999 提示后端错误信息提示
            message.error(responseJSON.errorMsg);
          } else {
            // EMPTY:未创建|INIT:已创建|OPEN:已发布
            let newDateStrArr: any = [];
            if (responseJSON?.data?.length > 0) {
              const dateConfigInfoArr = responseJSON.data;
              newDateStrArr = dateStrArr.map((item: any) => {
                const filterItem = dateConfigInfoArr.filter((item2: any) => item2.dateStr === item.dateStr);
                if (filterItem?.length > 0) {
                  return {
                    ...item,
                    ...filterItem[0],
                    orgCode: filterItem[0].siteCode,
                    orgName: filterItem[0].siteName
                  };
                }
                return item;
              });
              // 如果是VA设置或者修改最大发货量
              // 如果是父级组件主动要求更新日期的配置信息，需要默认设置当前已选择的日期配置信息，如状态的变更、复制时间窗、发布时间窗
              checkShouldSettingInitData(currentOnDateValue, dateConfigInfoArr);
            }
            // 更新可选日期的配置信息
            setDateStrArr(newDateStrArr);
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
        })
        .finally(() => {
          setIsActionLoading(false);
        });
    },
    [checkShouldSettingInitData, dateStrArr, dateStrsVal, form]
  );

  const updateDateRuleConfig = (dateVal: string = "") => {
    getDateStrRuleConfig("", "", dateVal);
  };

  //处理卖方工厂 orgCode 的切换,确定 orgCode 的值
  const handleOrgCodeChange = useCallback(
    (value: any) => {
      let orgCodeVal = null;
      if (value && value !== "All") orgCodeVal = value;

      form.setFieldsValue({
        orgCode: orgCodeVal || null,
        orgName: getOrgName(orgCodeVal),
        platform: null,
        dateStr: null, // 当前日期
        loadingRule: "", // 当前平台标识
        status: "", // 当前时间窗状态
        dateStrExtra: null // 该日期的其他额外配置信息
      });
      // 重置可选日期的详细配置信息
      setDateStrsArrInit();
      // 如果orgCode有值则重新获取对应的platform的数据
      if (orgCodeVal) {
        getPlatformsDataOptions(orgCodeVal);
      }

      // 通知父级组件可以获取更新后的信息
      notify_current_change_data();
    },
    [form, getPlatformsDataOptions, notify_current_change_data]
  );

  const getOrgName = (orgCodeVal: string) => {
    let orgName = "";
    if (orgCodeVal) {
      const filterArr = allSupplier(["All"]).filter(item => item.value === orgCodeVal);
      if (filterArr?.length > 0) orgName = filterArr[0].label;
    }
    return orgName;
  };

  //处理平台 platform 的切换: 确定platform 和 loadingRule的值
  const handlePlatformChange = useCallback(
    (value: any) => {
      // 如果orgCode有值则重新获取对应的platform的数据
      // 重置可选日期的详细配置信息
      setDateStrArr(dateStrArrInit);
      if (value) {
        // 设置 loadingRule
        let loadingRuleVal = "";
        const dataItem: any[] = platformDataOptions.filter((item: any) => item.value === value);
        if (dataItem.length > 0) {
          loadingRuleVal = dataItem[0].loading_rule;
        }
        form.setFieldsValue({
          platform: value,
          dateStr: null, // 当前日期
          loadingRule: loadingRuleVal, // 当前平台标识
          status: "", // 当前时间窗状态
          dateStrExtra: null // 该日期的其他额外配置信息
        });
        if (value !== "反向预约") {
          // 获取可选时间的详细配置规则信息
          getDateStrRuleConfig(form.getFieldValue("orgCode"), value);
        }
      } else {
        form.setFieldsValue({
          platform: null,
          dateStr: null, // 当前日期
          loadingRule: "", // 当前平台标识
          status: "", // 当前时间窗状态
          dateStrExtra: null // 该日期的其他额外配置信息
        });
      }

      // 通知父级组件可以获取更新后的信息
      notify_current_change_data();
    },
    [dateStrArrInit, form, getDateStrRuleConfig, notify_current_change_data, platformDataOptions]
  );

  // 处理点击选择时间的逻辑，确定dateStr、status和dateStrExtra的值
  const handleDateStrClick = (dateObj: any) => () => {
    const loadingRuleVal = form.getFieldValue("loadingRule");
    if (loadingRuleVal && loadingRuleVal === "VA" && !dateObj.maximum) {
      // 如果VA情况下没有设置过当前日期的阀值，则先去设置
      settingMaximum(dateObj);
    } else if (
      (form.getFieldValue("dateStr") !== dateObj.dateStr && dateObj.dateStr) ||
      form.getFieldValue("status") !== dateObj.status
    ) {
      form.setFieldsValue({ dateStr: dateObj.dateStr, status: dateObj.status, dateStrExtra: dateObj });

      // 通知父级组件可以获取更新后的信息
      notify_current_change_data();
    }
  };

  // VA时设置当日最大发货数量
  const settingMaximum = (currentDateObj?: any, vaResetMaximum: boolean = false) => {
    // 重置日期选择
    if (vaResetMaximum) {
      form.setFieldsValue({
        dateStr: null, // 当前日期
        status: "", // 当前时间窗状态
        dateStrExtra: null // 该日期的其他额外配置信息 });
      });
      updateDateRuleConfig();
    }

    if (currentDateObj && Object.keys(currentDateObj).length > 0) {
      // 添加
      setCurrentMaximumSettingDateObj(currentDateObj);
      setIsShowMaximumSettingModal(true);
    } else {
      // 修改
      setCurrentMaximumSettingDateObj({ ...form.getFieldsValue(true) });
      setIsShowMaximumSettingModal(true);
    }
  };

  // VA情况--执行设置当前日期最大发货数量
  const doSettingCurrentDateMaximum = useCallback(
    (maximumValue?: any, currentDateObj?: any) => {
      if (maximumValue) {
        // 设置当日最大发货量，并重新设置日期的配置信息
        const dataObj = {
          siteCode: form.getFieldValue("orgCode"),
          platform: form.getFieldValue("platform"),
          dateStr: currentDateObj.dateStr,
          maximum: maximumValue
        };
        setIsActionLoading(true);
        updateDateRuleConfigApi(dataObj)
          .then((responseJSON: any) => {
            if (responseJSON?.code === "99999") {
              // 99999 提示后端错误信息提示
              message.error(responseJSON.errorMsg);
            } else if (responseJSON?.data) {
              form.setFieldsValue({
                dateStr: currentDateObj.dateStr,
                status: currentDateObj.status,
                dateStrExtra: responseJSON.data
              });
              getDateStrRuleConfig("", "", currentDateObj.dateStr);
            }
          })
          .catch((exception: any) => {
            console.log(`Failure: ${exception}`);
            message.error("当日最大提货件数设置失败");
          })
          .finally(() => {
            setIsActionLoading(false);
          });
      }
      setCurrentMaximumSettingDateObj({});
      setIsShowMaximumSettingModal(false);
    },

    [form, getDateStrRuleConfig]
  );

  // Similar to componentDidMount and componentDidUpdate
  // Just run the first time rendering
  useEffect(() => {
    // 设置当前天往后推7天的日期
    setDateStrsArrInit();
  }, []);

  useEffect(() => {
    handleOrgCodeChange(props.switcherData?.orgCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.switcherData?.orgCode]);

  return (
    <div className="reservation-filters">
      <Spin spinning={isActionLoading}>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb" }}
          bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
          title="预设条件"
          headStyle={{
            backgroundColor: "white",
            borderBottom: "none",
            textAlign: "left"
          }}
        >
          <Form
            form={form}
            name="reservation_filters_form"
            layout="vertical"
            labelAlign="left"
            initialValues={{
              orgCode:
                props.switcherData?.orgCode && props.switcherData?.orgCode !== "All"
                  ? props.switcherData?.orgCode
                  : null,
              orgName: "",
              platform: null,
              dateStr: null, // 当前日期
              loadingRule: "", // 当前平台标识
              status: "", // 当前时间窗状态
              dateStrExtra: null // 该日期的其他额外配置信息
            }}
          >
            <div className="hdp-uf hdp-uf-dc" style={{ width: "100%", paddingBottom: "16px" }}>
              <div className="hdp-uf hdp-uf-ww">
                <Form.Item
                  label="卖方工厂"
                  name="orgCode"
                  className="form-item"
                  rules={[{ required: true, message: "请选择卖方工厂!" }]}
                >
                  <Select
                    data-testid="test-orgCode-select"
                    placeholder="请选择卖方工厂"
                    options={allSupplier(["All"])}
                    onChange={handleOrgCodeChange}
                    style={{ width: 245 }}
                  />
                </Form.Item>
                <Form.Item
                  label="发货平台"
                  name="platform"
                  className="form-item"
                  rules={[{ required: true, message: "请选择发货平台!" }]}
                >
                  <Select
                    data-testid="test-platform-select"
                    placeholder="请选择发货平台"
                    options={platformDataOptions}
                    onChange={handlePlatformChange}
                    disabled={!form.getFieldValue("orgCode")}
                    style={{ width: 200 }}
                  />
                </Form.Item>
                {/* SY为反向预约，不需要选择日期 */}
                {form.getFieldValue("orgCode") &&
                  form.getFieldValue("platform") &&
                  form.getFieldValue("loadingRule") !== "SY" && (
                    <Form.Item
                      label="发货日期"
                      name="dateStr"
                      className="form-item"
                      rules={[{ required: true, message: "请选择发货日期！" }]}
                    >
                      <div>
                        <ul className="hdp-uf hdp-uf-ww date-str-list">
                          {dateStrArr.map((item: any) => (
                            <li
                              className={`hdp-uf hdp-uf-hc hdp-uf-vc list-item ${
                                item.dateStr === form.getFieldValue("dateStr") ? "list-item-focus" : ""
                              } ${item.status === "OPEN" ? "list-item-on" : ""}`}
                              key={`${item.dateStr}`}
                              onClick={handleDateStrClick(item)}
                            >
                              {item.dateStrView}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Form.Item>
                  )}
              </div>
            </div>
          </Form>
        </Card>
      </Spin>
      {isShowMaximumSettingModal && (
        <MaximumSettingModal
          modalTitle="请输入当前发货平台当日最大提货件数"
          basicInfo={currentMaximumSettingDateObj}
          doClose={doSettingCurrentDateMaximum}
          doActionOK={doSettingCurrentDateMaximum}
        />
      )}
    </div>
  );
};

const ReservationFilters = forwardRef(ReservationFiltersFunc);

export default ReservationFilters;
