import { useState, useEffect, useCallback } from "react";
import { message, Modal, Radio } from "antd";
import { updateAppointmentMode } from "@/api/userCenter";
import { getLocalStorageItem } from "@/utils";

const SlotBookingType = (props: any) => {
  const [reserveModalVisible, setReserveModalVisible] = useState(false);
  const [reserveMethod, setReserveMethod] = useState("CE");

  useEffect(() => {
    setReserveMethod(getLocalStorageItem("localSlotBookingType") || "CE");
  }, []);

  const RadioChange = useCallback(
    (e: any) => {
      if (e.target.value !== reserveMethod) {
        setReserveModalVisible(true);
      }
    },
    [reserveMethod]
  );

  // 更换预约方式
  const handReserveOk = useCallback(() => {
    updateAppointmentMode({
      customerCode: props.recordData.entitlements[0].controlIdentifier,
      appointmentMode: reserveMethod === "CE" ? "DRIVER" : "CE"
    })
      .then(() => {
        setReserveModalVisible(false);
        setReserveMethod(reserveMethod === "CE" ? "DRIVER" : "CE");
        Modal.success({ content: "变更成功" });
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      });
  }, [props.recordData.entitlements, reserveMethod]);

  const onHandleModalClose = useCallback(() => {
    setReserveModalVisible(false);
  }, []);

  return (
    <div className="user-info-item">
      <span style={{ fontSize: 16, fontWeight: "bolder", paddingBottom: 10 }}>请选择预约方式</span>
      <div className="radio-choose">
        <Radio.Group value={reserveMethod} onChange={RadioChange}>
          <Radio value="CE">采购专员/经理预约</Radio>
          <Radio value="DRIVER">
            司机预约
            <span style={{ marginLeft: 20, fontSize: 12, fontWeight: 400 }}>
              请司机关注“嘉吉粮油服务平台服务号”，按引导进行操作
            </span>
          </Radio>
        </Radio.Group>
        <Modal
          visible={reserveModalVisible}
          title={null}
          onCancel={onHandleModalClose}
          onOk={handReserveOk}
          centered
          maskClosable={false}
        >
          确认预约类型更改为：{reserveMethod === "CE" ? "司机预约" : "采购专员/经理预约"}？
        </Modal>
      </div>
    </div>
  );
};

export default SlotBookingType;
