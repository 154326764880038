import { useCallback, useState } from "react";
import { Modal, Button, Input, Form, message, Row, Col } from "antd";
import { rejectSettlement } from "@/api/directSys";
const { TextArea } = Input;

const RefuseModal = (props: any) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reasonInput, setReasonInput] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOk = useCallback(
    (values: any) => {
      setLoading(true);
      const requestArr: any = [];
      props.selectedRow.forEach((e: any) => {
        requestArr.push({ id: e.id, cargillRemark: values.reason });
      });
      rejectSettlement(requestArr, {})
        .then((res: any) => {
          if (res?.result) {
            props.getListData();
          } else {
            message.error(res?.data?.description);
          }
        })
        .finally(() => {
          setLoading(false);
          setIsModalVisible(false);
          form.resetFields();
          setReasonInput(false);
        });
    },
    [form, props]
  );

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    form.resetFields();
    setReasonInput(false);
  }, [form]);

  const handleVisible = useCallback(() => {
    setIsModalVisible(!isModalVisible);
  }, [isModalVisible]);

  const handleReasonInput = useCallback(() => {
    if (form.getFieldValue("reason")) {
      setReasonInput(true);
    } else {
      setReasonInput(false);
    }
  }, [form]);

  return (
    <>
      {" "}
      {props.fitContent ? (
        <Button
          type="default"
          onClick={handleVisible}
          disabled={!props.isEnabledAction}
          style={{ width: "fit-content" }}
        >
          拒绝
        </Button>
      ) : (
        <Button type={props.isText ? "text" : "default"} onClick={handleVisible} disabled={!props.isEnabledAction}>
          拒绝
        </Button>
      )}
      <Modal title="拒绝原因" maskClosable={false} visible={isModalVisible} footer={null} onCancel={handleCancel}>
        <Form form={form} onFinish={handleOk}>
          <Form.Item name="reason">
            <TextArea data-testid="test-textarea" placeholder="请填写拒绝原因" onChange={handleReasonInput} />
          </Form.Item>
          <Row gutter={[16, 16]}>
            <Col style={{ marginLeft: "auto", marginRight: 0 }}>
              <Form.Item>
                <div style={{ marginTop: 0 }}>
                  <Button type="primary" style={{ backgroundColor: "#5c5c5c" }} onClick={handleCancel}>
                    取消
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={!reasonInput}
                    data-testid="test-submit"
                  >
                    提交
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default RefuseModal;
