import { getCustomerAccountItemTimeApi, getDepositOverviewDataApi, getFirmDetailListApi } from "@/api/availableBalance";
import { getFirmDetailsList } from "@/api/constant";
import RemoteSelect from "@/pages/common/remoteSelect";
import { formatNumberToFixedThousands, formatReverseNumberToFixedThousands, getCode } from "@/utils/common";
import { allSupplierMutiple } from "@/utils/constant";
import { Button, Col, Form, Input, Row, Select, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./index.scss";
import moment from "moment";

interface Props {
  switcher: any,
  userDetails: any,
  permissions: any,
  defaultSearchValue?: any
  goToTab: Function
}

const PaymentDetailsOverview = (props: Props) => {
  const { switcher, userDetails, permissions, goToTab } = props;
  const [loading, setLoading] = useState(false);
  const [isSelectFetching, setIsSelectFetching] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [paymentOverviewData, setPaymentOverviewData] = useState({} as any);
  const [isShowResult, setIsShowResult] = useState(false);
  const [searchValue, setSearchValue] = useState({} as any);
  const [customerAccountItemTime, setCustomerAccountItemTime] = useState("");
  const [depositDetailsTimeStamp, setDepositDetailsTimeStamp] = useState("");
  const [form] = Form.useForm();

  // 外部Group用户筛选公司
  const onFilterOption = useCallback((inputValue: any, option: any) => {
    return ((option?.label as unknown) as string).toLowerCase().includes(inputValue?.toLowerCase());
  }, []);

  // 外部 group 用户查询公司（集团公司）
  const fetchCustomerOptions_External = useCallback((controlIdentifier: any) => {
    getFirmDetailsList({ accountNumber: controlIdentifier })
      .then((res: any) => {
        if (res.data && res.data.customerInfoEntitlement?.length > 0) {
          let options = res.data.customerInfoEntitlement.map((val: any) => {
            return {
              label: `${val.accountLongName}(${val.accountNumber})`,
              value: `${val.accountLongName}(${val.accountNumber})`,
              key: val.accountNumber,
              name: val.accountLongName
            };
          });
          const currentCustomerOption = options.find((val: any) => val.key === switcher.customerCode);
          setCustomerOptions(options);
          form.setFieldsValue({
            customerCode: currentCustomerOption?.value
          });
        } else {
          setCustomerOptions([]);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      });
  }, [form, switcher]);

  // 内部用户--获取买方选项(防抖输入查询)
  const fetchCustomerOptions_Internal = useCallback(
    (value: string | undefined, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      getFirmDetailListApi({
        customerName: value || ""
      })
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            callback([]);
            message.error(res.errorMsg);
          } else if (res.data?.customerList?.length > 0 && currentValue === value) {
            let data: any[] = res.data?.customerList?.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName + "(" + val.customerCode + ")",
                key: val.customerCode
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    []
  );

  const formatAccountStatementNumber = useCallback((val: any) => {
    return val === null ? "-" : formatNumberToFixedThousands(val, 2);
  }, [])

  const formatReverseAccountStatementNumber = useCallback((val: any) => {
    return val === null ? "-" : formatReverseNumberToFixedThousands(val, 2);
  }, [])

  // 跳转查看保证金明细
  const goToDepositDetails = useCallback(() => {
    goToTab("3", searchValue);
  }, [goToTab, searchValue]);

  // 跳转应付货款明细
  const goToPayableDetails = useCallback(() => {
    goToTab("2", searchValue);
  }, [goToTab, searchValue]);

  // 获取保证金数据截止时间&货款数据截止时间
  const getCustomerAccountItemTime = useCallback((params: object) => {
    getCustomerAccountItemTimeApi(params)
      .then((res: any) => {
        if (res?.data) {
          // 获取现金余额数据截止时间
          const customerdepositdetailsData: any = res?.data.find((val: any) => {
            return val.entityName === "customerdepositdetails";
          });
          const customeraccountitemdetailsData: any = res?.data.find((val: any) => {
            return val.entityName === "customeraccountitemdetails";
          });
          setDepositDetailsTimeStamp(customerdepositdetailsData?.updatedDateTime || "");
          setCustomerAccountItemTime(customeraccountitemdetailsData?.updatedDateTime || "");
        }
      })
      .catch((exception: any) => {
        setCustomerAccountItemTime("");
        console.log(`Failure: ${exception}`);
      })
  }, [setCustomerAccountItemTime]);

  // 查询款项明细总览信息
  const onFinish = useCallback((values: any) => {
    setLoading(true);
    setSearchValue(values);
    const valuesTemp = { ...values };
    valuesTemp.customerCode = getCode(valuesTemp.customerCode);
    valuesTemp.orgCode = valuesTemp.orgCode?.length > 0 ? valuesTemp.orgCode.join(",") : "";
    getDepositOverviewDataApi(valuesTemp)
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else if (res?.data) {
          setPaymentOverviewData({
            depositReceived: res.data.depositReceived,
            marginCallReceived: res.data.marginCallReceived,
            depositReceivable: res.data.depositReceivable,
            marginCallReceivable: res.data.marginCallReceivable,
            shouldPayTotal: res.data.shouldPayTotal
          });
          setIsShowResult(true);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        setPaymentOverviewData({});
        setIsShowResult(false);
      })
      .finally(() => {
        setLoading(false);
      })
    getCustomerAccountItemTime({ customerCode: valuesTemp.customerCode });
  }, [getCustomerAccountItemTime]);

  useEffect(() => {
    if (switcher) {
      form.setFieldsValue({ orgCode: switcher.orgCode && switcher.orgCode !== "All" ? [switcher.orgCode] : [] });
      if (permissions.CNExternalUsers) {
        // 判断是否为集团用户
        const userDataCheck = userDetails && Object.keys(userDetails).length && userDetails.entitlements;
        if (userDataCheck) {
          const { entitlements } = userDetails;
          const {
            otherControls: { companyAccountType },
            controlIdentifier
          } = entitlements[0];
          if (companyAccountType === "GROUP") {
            fetchCustomerOptions_External(controlIdentifier);
          } else {
            form.setFieldsValue({ customerCode: switcher.customerName + "(" + switcher.customerCode + ")" });
          }
        }
      }
    }
  }, [form, switcher, permissions, userDetails, fetchCustomerOptions_External]);

  return (
    <div className="payment-overview-wrapper">
      <div className="search-filter">
        {permissions.CNInternalUsers && <div className="title">查询总览</div>}
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={{ md: 16, xs: 8 }}>
            {permissions.CNInternalUsers && (
              <Col md={6} xs={24}>
                <Form.Item
                  label="公司名称"
                  name="customerCode"
                  rules={[{ required: true, message: "请输入公司名称" }]}
                >
                  <RemoteSelect
                    placeholder="请输入名称查询"
                    fetchOptions={fetchCustomerOptions_Internal}
                    loading={isSelectFetching}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}
            {permissions.CNExternalUsers && userDetails?.isGroup && (
              <Col md={6} xs={24}>
                <Form.Item label="公司名称" name="customerCode">
                  <Select
                    showSearch
                    placeholder="请选择公司名称"
                    options={customerOptions}
                    optionFilterProp="label"
                    filterOption={onFilterOption}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}
            {permissions.CNExternalUsers && !userDetails?.isGroup && (
              <Col md={6} xs={24}>
                <Form.Item label="公司名称" name="customerCode">
                  <Input disabled />
                </Form.Item>
              </Col>
            )}
            <Col md={6} xs={24}>
              <Form.Item label="卖方" name="orgCode">
                <Select
                  placeholder="请选择卖方公司"
                  mode="multiple"
                  maxTagCount={"responsive" as const}
                  options={allSupplierMutiple()}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col md={2} xs={24}>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-btn"
                    loading={loading}
                    disabled={!form.getFieldValue("customerCode")}
                  >
                    查询
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="content">
        {isShowResult ? (<div className="search-result">
          <div className="money-info-container">
            <Row className="title-container">
              <Col md={8} xs={12}>
                <div className="title">保证金</div>
              </Col>
              <Col md={8} xs={12}>
                <Button type="link" onClick={goToDepositDetails}>查看保证金明细</Button>
              </Col>
            </Row>
            <Row className="result-row">
              <Col md={8} xs={24}>
                <div className="result-col-item">
                  <span className="label">保证金余额（元）：</span>
                  <span className="value">{paymentOverviewData.depositReceived == null && paymentOverviewData.marginCallReceived == null ? "-" : formatReverseNumberToFixedThousands(paymentOverviewData.depositReceived + paymentOverviewData.marginCallReceived, 2)}</span>
                </div>
                <div className="result-col-item">
                  <span className="label">履约保证金余额（元）：</span>
                  <span className="value">{formatReverseAccountStatementNumber(paymentOverviewData.depositReceived)}</span>

                </div>
                <div className="result-col-item">
                  <span className="label">追加保证金余额（元）：</span>
                  <span className="value">{formatReverseAccountStatementNumber(paymentOverviewData.marginCallReceived)}</span>
                </div>
              </Col>
              <Col md={8} xs={24}>
                <div className="result-col-item">
                  <span className="label">应付保证金总额（元）：</span>
                  <span className="value">{paymentOverviewData.depositReceivable == null && paymentOverviewData.marginCallReceivable == null ? "-" : formatNumberToFixedThousands(paymentOverviewData.depositReceivable + paymentOverviewData.marginCallReceivable, 2)}</span>
                </div>
                <div className="result-col-item">
                  <span className="label">应付履约保证金总额（元）：</span>
                  <span className="value">{formatAccountStatementNumber(paymentOverviewData.depositReceivable)}</span>
                </div>
                <div className="result-col-item">
                  <span className="label">应付追加保证金总额（元）：</span>
                  <span className="value">{formatAccountStatementNumber(paymentOverviewData.marginCallReceivable)}</span>
                </div>
              </Col>
            </Row>
            {depositDetailsTimeStamp &&
              <div className="tips time-stamp">
                <ExclamationCircleOutlined />
                <span className="label">以上数据系截止至【{moment(depositDetailsTimeStamp).format("YYYY年MM月DD日 HH:mm")}】，请以实际为准。</span>
              </div>}
            <Row className="title-container">
              <Col md={8} xs={12}>
                <div className="title">货款</div>
              </Col>
              <Col md={8} xs={12}>
                <Button type="link" onClick={goToPayableDetails}>查看应付货款明细</Button>
              </Col>
            </Row >
            <Row className="result-row">
              <Col md={8} xs={24}>
                <div className="result-col-item">
                  <span className="label">应付货款总额（元）：</span>
                  <span className="value">{formatAccountStatementNumber(paymentOverviewData.shouldPayTotal)}</span>
                </div>
              </Col>
            </Row>
            {customerAccountItemTime &&
              <div className="tips time-stamp">
                <ExclamationCircleOutlined />
                <span className="label">以上数据系截止至【{moment(customerAccountItemTime).format("YYYY年MM月DD日 HH:mm")}】，请以实际为准。</span>
              </div>}
          </div>
        </div>) : ((<div className="tips">
          <ExclamationCircleOutlined />
          <span className="label">请输入以上信息后查看。</span>
        </div>))}
      </div>
    </div>
  )
};

const mapStateToProps = (state: any) => ({
  switcher: state.switcher,
  userDetails: state.userDetails,
  permissions: state.userPermissions
});

export default connect(mapStateToProps, null)(PaymentDetailsOverview);