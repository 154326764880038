import { downloadSettlementInfoDetail } from "@/api/constant";
import { Button, message } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";

interface Props {
  data: any;
  params: any;
  type?: string;
  filename?: string;
  fileType: string;
  text?: boolean;
}

const DownloadDetailReport = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const downloadReport = useCallback(() => {
    const getDownLoad = (apiName: any) => {
      setLoading(true);
      apiName(props.data, props.params)
        .then((res: any) => {
          const blob = new Blob([res], { type: props.type || "application/octet-stream" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          if (typeof link.download !== "undefined")
            link.setAttribute("download", props.filename ? props.filename : "report.xls");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err: any) => {
          console.log(err);
          message.error("下载失败");
        })
        .finally(() => setLoading(false));
    };

    if (props.params.total > 1000) {
      message.error("下载的总条数不能超过1000");
    } else {
      getDownLoad(downloadSettlementInfoDetail);
    }
  }, [props.data, props.filename, props.params, props.type]);

  return (
    <>
      <Button
        type={props.text ? "text" : "primary"}
        icon={<VerticalAlignBottomOutlined />}
        onClick={downloadReport}
        disabled={props.params.total === 0}
        loading={loading}
      >
        下载详情报表
      </Button>
    </>
  );
};

export default DownloadDetailReport;
