import React, { useCallback, useEffect, useState } from "react";
import { Select, Spin, SelectProps } from "antd";

// 场景: 该组件封装用于需要进行输入后进行远程请求可选结果列表，以选择为主，输入为辅
// 赋值：必须选择某条列表项后才会赋值
const RemoteSelect: React.FC<{
  placeholder: string;
  style: React.CSSProperties;
  loading?: boolean;
  value?: string;
  fetchOptions: (value: string, callback: Function, currentValue: string | undefined) => void;
  onChange?: (value: string) => void;
  dataTestId?: string;
  disabled?: boolean;
  id?: string;
}> = props => {
  const [data, setData] = useState<SelectProps["options"]>([]);
  const [value, setValue] = useState<string | undefined>(props.value);

  let timeout: ReturnType<typeof setTimeout> | null | undefined; // 自建防抖查询策略
  let currentValue: string | undefined;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDoFetchData = (value: string, callback: Function) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;

    timeout = setTimeout(() => props.fetchOptions(value, callback, currentValue), 800);
  };

  const onHandleSearch = useCallback(
    (newValue: string) => {
      if (newValue) {
        onDoFetchData(newValue, setData);
      } else {
        setData([]);
      }
    },
    [onDoFetchData]
  );

  const onHandleChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      // 触发change事件更新到父级
      props.onChange && props.onChange(newValue);
    },
    [props]
  );

  useEffect(() => {
    // 表单主动清空值则重置信息
    if (!props.value) {
      setValue(props.value);
      setData([]);
    }
  }, [props.value]);

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });

  return (
    <Select
      id={props.id || undefined}
      data-testid={props.dataTestId || null}
      showSearch
      allowClear
      value={value}
      disabled={props.disabled || false}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={onHandleSearch}
      onChange={onHandleChange}
      notFoundContent={props.loading ? <Spin size="small" /> : null}
      options={data || []}
    />
  );
};

export default RemoteSelect;
