import ReactDOM from "react-dom";
import Route from "./router";
import { ConfigProvider } from "antd";
import "./index.scss";
import "moment/locale/zh-cn";
import zhCN from "antd/lib/locale/zh_CN";
import reportWebVitals from "./reportWebVitals";

import { createStore, applyMiddleware } from "redux";
import { logger } from "redux-logger";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { rootUserDetailsSaga, rootUserPermissionsSaga, rootSwitcherSaga } from "@/store/sagas";
import combinedReducer from "@/store/reducers";
import { setDatadogRum } from "@/monitor/datadog";
import { AliveScope } from 'react-activation'

// 应用启动开始 Datadog 监控
setDatadogRum();

const sagaMiddleWare = createSagaMiddleware();
const store = createStore(
  combinedReducer(),
  process.env.REACT_APP_ENV === "production" ? applyMiddleware(sagaMiddleWare) : applyMiddleware(sagaMiddleWare, logger)
);
sagaMiddleWare.run(rootUserDetailsSaga);
sagaMiddleWare.run(rootUserPermissionsSaga);
sagaMiddleWare.run(rootSwitcherSaga);

const render = (Component: any) => {
  ReactDOM.render(
    <Provider store={store}>
      <ConfigProvider locale={zhCN} componentSize="middle">
      <AliveScope>
        <div className="myApp">
          <Component />
        </div>
      </AliveScope>
      </ConfigProvider>
    </Provider>,
    document.getElementById("root")
  );
};

render(Route);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
