import RequestService from "@/api/request";

// 获取提货单列表
const getDoReportListing = (dataObj: object, param: any) => {
  return RequestService({
    url: `/commodity-movementsap/doReportListing?limit=10`,
    method: "post",
    data: dataObj,
    params: param
  });
};

// 编辑
const updateReportEditDo = (dataObj: object) => {
  return RequestService({
    url: `/commodity-movementsap/reportEditDo`,
    method: "PATCH",
    data: dataObj,
  });
};

// 撤回
const getCancelDo = (dataObj: object, param: any) => {
  return RequestService({
    url: `/commodity-movementsap/cancelDo`,
    method: "PATCH",
    data: dataObj,
    params: param
  });
};

// 下载质量报告 | 电子磅单
const documentBatchDownload = (dataObj: any) => {
  return RequestService({
    url: "/commodity-movementsap/batchDownloadByDoIds",
    method: "post",
    data: dataObj,
    responseType: "blob"
  });
}

export { getDoReportListing, updateReportEditDo, getCancelDo, documentBatchDownload }