import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Select, DatePicker, Button, Collapse, Divider, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  allSupplier,
  contractState,
  executeWay,
  systemSource,
  remainedQtyLimit,
  directConnectionApproval,
  businessScenario
} from "@/utils/constant";
import { isMobileDevice, cutOutRightCodeStr } from "@/utils/common";
import { vagueListCustomer, getProductList } from "@/api/directSys";
import RemoteSelect from "@/pages/common/remoteSelect";

const { Option } = Select;
const { Panel } = Collapse;

const SearchFilter = (props: any) => {
  const { switcher } = props;
  const [form] = Form.useForm();
  const [isClick, setIsClick] = useState(false);
  const [expandCollapseText, setExpandCollapseText] = useState("展开高级筛选");
  const [isSelectFetching, setIsSelectFetching] = useState(false);
  const [requestListValue] = useState({
    limit: 10,
    offset: 0,
    contractState: "",
    systemSource: "",
    divisionCode: switcher.divisionCode,
    orgCode: switcher.orgCode,
    appliedAcceptStartTime: "",
    appliedAcceptEndTime: "",
    contractType: "",
    contractNo: "",
    appliedNo: "",
    crglContractNo: "",
    productName: "",
    productCode: "",
    buyer: "",
    contractMonth: "",
    executeWay: "",
    remainedQtyLimit: "",
    directConnectionApproval: "",
    businessScenario: ""
  });

  useEffect(() => {
    if (switcher) {
      const { orgCode } = switcher;
      let value = requestListValue;
      value.orgCode = orgCode || "";
      form.setFieldsValue({
        supplier: orgCode || null
      });
      props.setFields(value);
    }
  }, [form, props, requestListValue, switcher]);

  const handelExpandCollapse = useCallback(() => {
    setIsClick(!isClick);
    setExpandCollapseText(isClick ? "展开高级筛选" : "收起高级筛选");
  }, [isClick]);

  const handleReset = useCallback(() => {
    form.resetFields();
    form.setFieldsValue({
      supplier: switcher.orgCode || null
    });
    props.setFields({
      limit: 10,
      offset: 0,
      contractState: "",
      systemSource: "",
      divisionCode: switcher.divisionCode,
      orgCode: switcher.orgCode,
      appliedAcceptStartTime: "",
      appliedAcceptEndTime: "",
      contractType: "",
      contractNo: "",
      appliedNo: "",
      crglContractNo: "",
      productName: "",
      buyer: "",
      contractMonth: "",
      executeWay: "",
      remainedQtyLimit: "",
      directConnectionApproval: "",
      businessScenario: ""
    });
    props.setCurrent(1);
    props.setIsSelected(false);
  }, [form, props, switcher.divisionCode, switcher.orgCode]);

  const onHandleSupplierChange = useCallback(
    value => {
      form.setFieldsValue({ buyer: null });
    },
    [form]
  );

  // 查询客户名
  const getCostumerOptions = useCallback(
    (value: string, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      vagueListCustomer({
        customerName: value || "",
        orgCode: form.getFieldValue("supplier") || "",
        divisionCode: switcher.divisionCode
      })
        .then((res: any) => {
          if (res.data?.customerList?.length > 0 && currentValue === value) {
            const data = res.data.customerList.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName,
                key: `${val.customerName}_${val.customerCode}`
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch((err: any) => {
          console.log(err);
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    [form, switcher.divisionCode]
  );

  // 查询产品名称
  const getProductOptions = useCallback((value: string, callback: Function, currentValue: string | undefined) => {
    setIsSelectFetching(true);
    getProductList({
      productName: value || "",
      salesOrgs: ""
    })
      .then((res: any) => {
        if (res.data?.products?.length > 0 && currentValue === value) {
          const data = res.data.products.map((val: any) => {
            return {
              label: val.productName + "(" + val.productCode?.replace(/\b(000000000+)/gi, "") + ")",
              value: val.productName + "(" + val.productCode?.replace(/\b(000000000+)/gi, "") + ")"
            };
          });

          callback(data);
        } else {
          callback([]);
        }
      })
      .catch((err: any) => {
        console.log(err);
        callback([]);
      })
      .finally(() => {
        setIsSelectFetching(false);
      });
  }, []);

  const onFinish = useCallback(
    (values: any) => {
      const temp = JSON.parse(JSON.stringify(requestListValue));
      temp.offset = 0;
      temp.limit = 10;
      temp.contractState = values.contractState;
      temp.systemSource = values.systemSource;
      temp.divisionCode = switcher.divisionCode;
      temp.orgCode = values.supplier;
      temp.appliedAcceptStartTime = values.appliedAcceptStartTime
        ? values.appliedAcceptStartTime.format("YYYY-MM-DDT00:00:00")
        : null;
      temp.appliedAcceptEndTime = values.appliedAcceptEndTime
        ? values.appliedAcceptEndTime.format("YYYY-MM-DDT23:59:59")
        : null;
      temp.contractType = values.contractType;
      temp.contractNo = values.contractNo;
      temp.appliedNo = values.appliedNo;
      temp.crglContractNo = values.crglContractNo;
      temp.productCode = values.productName ? cutOutRightCodeStr(values.productName) : null;
      temp.buyer = values.buyer;
      temp.contractMonth = values.contractMonth;
      temp.executeWay = values.executeWay;
      temp.remainedQtyLimit = values.remainedQtyLimit;
      temp.directConnectionApproval = values.directConnectionApproval;
      temp.businessScenario = values.businessScenario;
      props.setFields(temp);
      props.setCurrent(1);
      props.setIsSelected(false);
    },
    [props, requestListValue, switcher.divisionCode]
  );

  return (
    <div className="search-filter">
      <div className="base-filter">
        <Form
          form={form}
          name="contract-confirmation-form"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          initialValues={{
            systemSource: "",
            supplier: switcher.orgCode || null,
            contractType: "",
            contractState: "",
            executeWay: "",
            remainedQtyLimit: "",
            directConnectionApproval: "",
            businessScenario: "",
          }}
        >
          <Row justify="space-between">
            <Col md={5} xs={24}>
              <Form.Item name="systemSource">
                <Select options={systemSource} />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="appliedAcceptStartTime">
                <DatePicker style={{ width: "100%" }} placeholder="申请接收开始时间" />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="appliedAcceptEndTime">
                <DatePicker style={{ width: "100%" }} placeholder="申请接收结束时间" />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="supplier">
                <Select placeholder="请选择卖方" options={allSupplier()} onChange={onHandleSupplierChange} />
              </Form.Item>
            </Col>
            {isMobileDevice() ? (
              <Col span={24}>
                <Form.Item name="contractType">
                  <Select>
                    <Option value="">全部合同</Option>
                    <Option value="YKJ_CT">一口价合同</Option>
                    <Option value="JC_CT">基差合同</Option>
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              <Col span={3}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={isClick}>
                    <SearchOutlined />
                    查询
                  </Button>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            {isMobileDevice() ? (
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={isClick}>
                    <SearchOutlined />
                    查询
                  </Button>
                </Form.Item>
              </Col>
            ) : (
              <Col span={5}>
                <Form.Item name="contractType">
                  <Select>
                    <Option value="">全部合同</Option>
                    <Option value="Flat">一口价合同</Option>
                    <Option value="BWP">基差未结价合同</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Collapse
            style={{ marginTop: -25 }}
            ghost
            expandIconPosition="right"
            onChange={handelExpandCollapse}
            collapsible="header"
          >
            <Panel header={<span style={{ color: "#007ed9" }}>{expandCollapseText}</span>} key="1">
              <Divider dashed={true} style={{ width: "101%", margin: "-10px 0 13px -14px" }} />
              <div className="expand-collapse">
                <Row gutter={[16, 16]}>
                  <Col md={4} xs={24}>
                    <Form.Item label="客户合同编号" name="contractNo">
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="点价申请号" name="appliedNo">
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="嘉吉合同号" name="crglContractNo">
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="产品名称" name="productName">
                      <RemoteSelect
                        dataTestId="productName-input"
                        placeholder="请输入查询并选择"
                        fetchOptions={getProductOptions}
                        loading={isSelectFetching}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="客户名称" name="buyer">
                      <RemoteSelect
                        dataTestId="buyer-input"
                        placeholder="请输入公司名称查询并选择"
                        fetchOptions={getCostumerOptions}
                        loading={isSelectFetching}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item
                      label="合约月份"
                      name="contractMonth"
                      rules={[
                        { len: 6, message: "合约月份应为6位数字", validateTrigger: "onblur" },
                        () => ({
                          validator(_, value) {
                            if (value) {
                              const reg = /^[1-9]\d{5}$/;
                              if (!reg.test(value)) {
                                if (value.toString().length === 1) {
                                  value = value?.replace(/[^1-9]/g, "");
                                } else {
                                  value = value?.replace(/\D/g, "");
                                }
                                if (value.length > 6) {
                                  value = value.substring(0, 6);
                                }
                                form.setFieldsValue({ contractMonth: value });
                                return Promise.reject("合约月份应为6位数字");
                              }
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <Input placeholder="请输入" data-testid="contractMonth-input" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col md={4} xs={24}>
                    <Form.Item label="直连请求状态" name="contractState">
                      <Select options={contractState} />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="合同执行方式" name="executeWay">
                      <Select options={executeWay} />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="合同待执行量<2吨" name="remainedQtyLimit">
                      <Select options={remainedQtyLimit} />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="审批结果" name="directConnectionApproval">
                      <Select options={directConnectionApproval} />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="业务场景" name="businessScenario">
                      <Select options={businessScenario} />
                    </Form.Item>
                  </Col>
                  <Col style={{ marginLeft: "auto", marginRight: 0 }} className="advance-btn-col">
                    <Form.Item>
                      <div style={{ marginTop: 30 }} className="advance-btn">
                        <Button
                          style={{ marginRight: 10, backgroundColor: "#5c5c5c" }}
                          type="primary"
                          onClick={handleReset}
                          data-testid="reset-btn"
                        >
                          清空
                        </Button>
                        <Button type="primary" htmlType="submit" data-testid="submit-btn">
                          立即查询
                        </Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  userDetails: state.userDetails,
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(SearchFilter);
