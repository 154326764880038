import { Component } from "react";
import { connect } from "react-redux";
import { Card, Col, Collapse, Divider, PageHeader, Row, Spin, message, Button } from "antd";
import { formatDateTimeToStr } from "@/utils/formatUtils";
import { getSettlementDetailById } from "@/api/directSys";
import "./index.scss";

const { Panel } = Collapse;

interface Props {
  location: any;
  history: any;
  permission: any;
}

interface State {
  settItemInfoDetailList: any;
  settlementInfoDetail: any;
  loading: boolean;
}

class SettlementDetail extends Component<Props, State> {
  public readonly state = {
    settItemInfoDetailList: [
      {
        contractNo: "",
        crglContractNo: "",
        deliveryEntrustWeight: 0,
        deliveryNo: "",
        dispatch: null,
        entrustNo: "",
        inboundTime: "",
        materialName: "",
        noAssessment: 0,
        receiveTime: "",
        receiverWeigh: 0,
        settAmount: 0,
        settTaxPrice: 0,
        settWeight: 0,
        specification: "",
        supplierWeigh: 0,
        talkAmount: 0,
        talkDesc: "",
        talkTime: "",
        taxRate: null,
        thirdInboundWeight: null,
        wagonNo: ""
      }
    ],
    settlementInfoDetail: {
      buyer: "",
      id: 0,
      matcher: "",
      seller: "",
      settAmount: 0,
      settNo: "",
      settTotalAmount: 0,
      settTotalWeight: 0
    },
    loading: false
  };

  componentDidMount() {
    document.title = "通威结算单详情";
    if (this.props.location.state) {
      this.setState({ loading: true });
      getSettlementDetailById({ settNo: this.props.location.state })
        .then(res => {
          const { settItemInfoDetailList, settlementInfoDetail } = res.data.settlement;
          this.setState({
            settItemInfoDetailList: settItemInfoDetailList,
            settlementInfoDetail: settlementInfoDetail,
            loading: false
          });
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          message.error("获取数据失败，请稍后重试。");
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
    console.log(this.props);
  }

  returnCrglContractNo = (crglContractNo: any) => {
    if (crglContractNo !== null) {
      let s = crglContractNo;
      s = s.split("_");
      if (s.length === 2) {
        return s[0] + "_" + s[1]?.replace(/\b(0+)/gi, "");
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };

  jumpToSettlementList = () => {
    this.props.history.push({
      pathname: "/main/direct-sys/settlementlist",
      state: { referrerPage: "settlementlist" }
    });
  };

  public render() {
    const { settItemInfoDetailList, settlementInfoDetail } = this.state;
    // 抽取公共方法
    const revertDisplayDom = (item: any, key: string) => {
      if (item[key] === null) {
        return "-";
      }
      return item[key]?.toFixed(3) + " 吨";
    };

    return (
      <div className="contract-detail" style={{ height: "100%" }}>
        <Spin spinning={this.state.loading}>
          <PageHeader className="page-header" title="结算单详情" />
          <Card style={{ height: "100%" }}>
            <div className="title">
              <span>结算单号：</span>
              <span>{settlementInfoDetail.settNo}</span>
            </div>

            <Row gutter={{ md: 0, xs: 16 }} className="base-info-row">
              <Col md={2} xs={9}>
                <span>买方</span>
              </Col>
              <Col md={4} xs={15}>
                <span className="row-content">{settlementInfoDetail.buyer}</span>
              </Col>
              <Col md={2} xs={9}>
                <span>卖方</span>
              </Col>
              <Col md={4} xs={15}>
                <span className="row-content">{settlementInfoDetail.seller}</span>
              </Col>
              <Col md={2} xs={9}></Col>
              <Col md={4} xs={15}></Col>
              <Col md={2} xs={9}></Col>
              <Col md={4} xs={15}></Col>
            </Row>
            <Row gutter={{ md: 0, xs: 16 }} className="base-info-row">
              <Col md={2} xs={9}>
                <span>结算含税金额</span>
              </Col>
              <Col md={4} xs={15}>
                <span className="row-content">
                  {settlementInfoDetail.settAmount === null ? "-" : settlementInfoDetail.settAmount.toFixed(2)} 元
                </span>
              </Col>
              {this.props.permission.CNInternalUsers && (
                <>
                  <Col md={2} xs={9}>
                    <span>结算单总重量</span>
                  </Col>
                  <Col md={4} xs={15}>
                    <span className="row-content">
                      {settlementInfoDetail.settTotalWeight === null
                        ? "-"
                        : settlementInfoDetail.settTotalWeight.toFixed(3)}{" "}
                      吨
                    </span>
                  </Col>
                </>
              )}
              <Col md={2} xs={9}>
                <span>匹配状态</span>
              </Col>
              <Col md={4} xs={15}>
                <span className="row-content">{settlementInfoDetail.matcher}</span>
              </Col>
              <Col md={2} xs={9}></Col>
              <Col md={4} xs={15}></Col>
            </Row>

            <div className="contract-card">
              <div className="title">嘉吉提货单号</div>
              {settItemInfoDetailList.length > 0 &&
                settItemInfoDetailList.map((item, index) => {
                  return (
                    <Collapse
                      ghost
                      key={item.deliveryNo}
                      expandIconPosition="right"
                      style={{ marginBottom: 20 }}
                      defaultActiveKey={item.deliveryNo}
                    >
                      <Panel header={item.deliveryNo} key={item.deliveryNo}>
                        <Divider style={{ color: "#d9d9d9", marginTop: "-10px" }} />
                        <Row gutter={[16, 16]}>
                          <Col md={6} xs={12}>
                            <span>嘉吉合同号</span>
                            <span className="row-content">
                              {item.crglContractNo === null ? "-" : this.returnCrglContractNo(item.crglContractNo)}
                            </span>
                          </Col>
                          <Col md={6} xs={12}>
                            <span>物料名称</span>
                            <span className="row-content">{item.materialName || "-"}</span>
                          </Col>
                          <Col md={6} xs={12}>
                            <span>包装规格</span>
                            <span className="row-content">{item.specification || "-"}</span>
                          </Col>
                          <Col md={6} xs={12}>
                            <span>结算含税单价</span>
                            <span className="row-content">
                              {item.settTaxPrice === null ? "-" : item.settTaxPrice.toFixed(2) + " 元"}
                            </span>
                          </Col>
                        </Row>
                        <Row gutter={[16, 16]} style={{ marginTop: 20, marginBottom: 20 }}>
                          <Col md={6} xs={12}>
                            <span>客户委托函号</span>
                            <span className="row-content">{item.entrustNo || "-"}</span>
                          </Col>
                          <Col md={6} xs={12}>
                            <span>客户收货日期</span>
                            <span className="row-content">
                              {formatDateTimeToStr(item.receiveTime, "YYYY-MM-DD", "-")}
                            </span>
                          </Col>
                          <Col md={6} xs={12}>
                            <span>入库日期</span>
                            <span className="row-content">
                              {formatDateTimeToStr(item.inboundTime, "YYYY-MM-DD", "-")}
                            </span>
                          </Col>
                          <Col md={6} xs={12}>
                            <span>车号</span>
                            <span className="row-content">{item.wagonNo || "-"}</span>
                          </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                          <Col md={6} xs={12}>
                            <span>提货委托函重量</span>
                            <span className="row-content">
                              {item.deliveryEntrustWeight === null
                                ? "-"
                                : item.deliveryEntrustWeight.toFixed(3) + " 吨"}
                            </span>
                          </Col>
                          <Col md={6} xs={12}>
                            <span>嘉吉发货重量</span>
                            <span className="row-content">{revertDisplayDom(item, "supplierWeigh")}</span>
                          </Col>
                          <Col md={6} xs={12}>
                            <span>客户入库重量</span>
                            <span className="row-content">{revertDisplayDom(item, "receiverWeigh")}</span>
                          </Col>
                          <Col md={6} xs={12}>
                            <span>嘉吉结算重量</span>
                            <span className="row-content">{revertDisplayDom(item, "settWeight")}</span>
                          </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                          <Col md={6} xs={12}>
                            <span>客户结算重量</span>
                            <span className="row-content">{revertDisplayDom(item, "custSettlementWeight")}</span>
                          </Col>
                          <Col md={6} xs={12}>
                            <span>折扣金额</span>
                            <span className="row-content">
                              {item.noAssessment ? item.noAssessment + " 元" : "-"}
                            </span>
                          </Col>
                          <Col md={6} xs={12}>
                            <span>运输损耗折扣金额</span>
                            <span className="row-content">{item.talkAmount ? item.talkAmount.toFixed(2) + " 元" : "-"}</span>
                          </Col>
                          <Col md={6} xs={12}>
                            <span>运输损耗处理时间</span>
                            <span className="row-content">
                              {formatDateTimeToStr(item.talkTime, "YYYY-MM-DD HH:mm:ss", "-")}
                            </span>
                          </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                          <Col md={6} xs={12}>
                            <span>运输损耗处理原因</span>
                            <span className="row-content">{item.talkDesc || "-"}</span>
                          </Col>
                          <Col md={6} xs={12}>
                            <span>磅单匹配列表</span>
                            <span className="row-content">{item.dispatch || "-"}</span>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  );
                })}
            </div>
            {/* <Link style={{ float: "right" }} to="/main/direct-sys/settlementlist">
              <Button type="primary">返回列表页</Button>
            </Link> */}
            <Button type="primary" style={{ float: "right" }} onClick={() => this.jumpToSettlementList()}>返回列表页</Button>
          </Card>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  permission: state.userPermissions
});
export default connect(mapStateToProps, null)(SettlementDetail);
