import { getUsePermissionsApi } from "@/api/user";
import { setLocalStorageItem, getEnumInfo } from "@/utils";

const doGetUserPermissions = () => {
  return getUsePermissionsApi()
    .then((responseJSON: any) => {
      const modifiedObj: any = {};
      if (responseJSON?.data?.permissions) {
        Object.keys(responseJSON.data.permissions).forEach(item => {
          modifiedObj[
            item
              .split("_")
              .slice(2)
              .join("")
          ] = responseJSON.data.permissions[item];
        });
        console.log(JSON.stringify(modifiedObj));
        setLocalStorageItem("localUserPermissions", JSON.stringify(modifiedObj));
      }
      return modifiedObj;
    })
    .catch((exception: any) => {
      console.log(`error in doGetUserPermissions ${exception}`);
      return null;
    });
};

const getUserPermissions = async () => {
  const permissions = await doGetUserPermissions();
  await getEnumInfo();
  if (permissions) {
    return permissions;
  }
  return null;
};

export { getUserPermissions, doGetUserPermissions };
