import { useCallback, useEffect, useState } from "react";
import { Table, Button, Tooltip } from "antd";
import { EditFilled } from "@ant-design/icons";
import moment from "moment";
import { allBusinessLine, allSupplier } from "@/utils/constant";
import { getByOktaIds } from "@/api/userManagement";
import EditUser from "./editUser";

type myProps = {
  doUpdateList: () => void;
  tableData: any[];
  paginationOpt: any;
  loading: boolean;
};

const TableList = (props: myProps) => {
  const [feedLoading, setFeedLoading] = useState(false);
  const [editData, setEditData] = useState<any>({});
  const [viewType, setViewType] = useState("");
  const [feedMillUsers, setFeedMillUsers] = useState<any[]>([]);

  useEffect(() => {
    const req: any = [];
    props.tableData?.forEach((item: any) => {
      req.push(item?.profile?.email);
    });
    // 为了确认哪些用户属于饲料厂用户，并获得其目的地代码和目的地
    setFeedLoading(true);
    getByOktaIds({ oktaIds: req }, {})
      .then(res => {
        setFeedMillUsers(res.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setFeedLoading(false);
      });
  }, [props.tableData]);

  // 返回激活状态中文
  const returnStatus = (status: any) => {
    if (status === "ACTIVE" || status === "RECOVERY") {
      return "已激活";
    } else if (status === "PROVISIONED") {
      return "待激活";
    }
    return status;
  };

  // 返回用户类型中文名称
  const returnGroup = (group: any) => {
    if (group === "DXP_DSC_Role_CN_Manager") {
      return "采购经理";
    }
    if (group === "DXP_DSC_Role_CN_Staff") {
      return "采购内勤";
    }
  };

  // 返回用户认证标识--客户代码
  const returnIdentifierArr = (arr: any) => {
    let arrnew: any[] = [];
    if (arr && arr.length > 1) {
      const companyTypeList = arr.map((item: any) => item.otherControls.companyAccountType);
      const keyIndex = companyTypeList.indexOf("GROUP");
      const keyItem = arr.splice(keyIndex, 1);
      arr.unshift(keyItem[0]);
    }
    arr.forEach((e: any) => {
      arrnew.push(e.controlIdentifier);
    });
    return arrnew.join(",");
  };

  // 业务线编码 ==> 业务线名称
  const returnBusinessLine = (divisionCode: string) => {
    let lineCode = "";
    const chooseBusinessLineArr = allBusinessLine();
    for (let businessLineItem of chooseBusinessLineArr) {
      if (businessLineItem.value === divisionCode) {
        lineCode = businessLineItem.label;
        break;
      }
    }
    return lineCode;
  };

  // 卖方编码 ==> 卖方名称
  const returnOrgName = (orgCode: string) => {
    let plant = "";
    const chooseAllSupplierArr = allSupplier();
    for (let supplierItem of chooseAllSupplierArr) {
      if (supplierItem.value === orgCode) {
        plant = supplierItem.label;
        break;
      }
    }
    return plant;
  };

  // 获得饲料厂-用户目的地代码
  const returnFeedCode = (recordData: any) => {
    let feedCode = "-";
    for (let item of feedMillUsers) {
      if (item?.oktaId === recordData.profile.email) {
        feedCode = item.feedCode;
        break;
      }
    }
    return feedCode;
  };

  // 获得饲料厂-用户目的地
  const returnFeedName = (recordData: any) => {
    let feedName = "-";
    for (let item of feedMillUsers) {
      if (item?.oktaId === recordData.profile.email) {
        feedName = item.feedName;
        break;
      }
    }
    return feedName;
  };

  // 查看详情
  const onHandleViewOrEdit = (record: any, type: string = "") => (event?: any) => {
    setEditData(record);
    setViewType(type);
  };

  const onDoCloseModal = useCallback(() => {
    setEditData({});
    setViewType("");
  }, []);

  const columns: any = [
    {
      title: "用户编号",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "姓名",
      render: (_: any, record: any) => {
        return (
          <span onClick={onHandleViewOrEdit(record, "read")} style={{ color: "#07c", cursor: "pointer" }}>
            {record.profile.lastName}
            {record.profile.firstName}
          </span>
        );
      }
    },
    {
      title: "客户代码",
      onCell: () => {
        return {
          style: {
            maxWidth: 150,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            cursor: "pointer"
          }
        };
      },
      render: (record: any) => (
        <Tooltip
          placement="topLeft"
          title={(record.entitlements?.[0]?.otherControls ? returnIdentifierArr(record.entitlements) : null) || null}
        >
          {(record.entitlements?.[0]?.otherControls ? returnIdentifierArr(record.entitlements) : null) || null}
        </Tooltip>
      )
    },
    {
      title: "卖方",
      render: (record: any) => <>{record.plant === "All" ? "全部卖方" : returnOrgName(record.plant)}</>
    },
    {
      title: "业务线",
      render: (record: any) => (
        <>{record.businessLine === "All" ? "全部业务线" : returnBusinessLine(record.businessLine)}</>
      )
    },
    {
      title: "买方",
      render: (record: any) => <>{record.entitlements?.[0]?.otherControls?.organizationName || null}</>
    },
    {
      title: "用户性质",
      render: (record: any) => (
        <>{(record.entitlements?.[0]?.controlType === "Account" ? "现金用户" : "信用用户") || null}</>
      )
    },
    {
      title: "创建日期",
      render: (record: any) => <>{moment(record.created).format("YYYY-MM-DD HH:mm:ss")}</>
    },
    {
      title: "用户状态",
      render: (record: any) => <>{record.isDeactivated ? "已失效" : returnStatus(record.status)}</>
    },
    {
      title: "用户类型",
      render: (record: any) => <>{record.entitlements ? returnGroup(record.entitlements[0].requestorGroup) : null} </>
    },
    {
      title: "联系方式",
      render: (record: any) => <>{record.profile.primaryPhone} </>
    },
    {
      title: "邮箱",
      render: (record: any) => <>{record.profile.email} </>
    },
    {
      title: "用户目的地代码",
      render: (record: any) => <>{returnFeedCode(record)}</>
    },
    {
      title: "用户目的地",
      render: (record: any) => <>{returnFeedName(record)}</>
    },
    {
      title: "操作",
      key: "action",
      fixed: "right" as "right",
      render: (record: any) => (
        <Button
          onClick={onHandleViewOrEdit(record, "edit")}
          disabled={record.isDeactivated}
          style={{ width: "fit-content" }}
          type="link"
        >
          <EditFilled />
        </Button>
      )
    }
  ];

  return (
    <div className="contract-confirmation-table">
      {!!viewType && (
        <EditUser
          viewType={viewType}
          dataSource={editData}
          doUpdateList={props.doUpdateList}
          doCloseModal={onDoCloseModal}
          feedMillUsers={feedMillUsers}
        />
      )}

      <Table
        columns={columns}
        pagination={props.paginationOpt}
        scroll={{ x: "max-content" }}
        dataSource={props.tableData}
        rowKey="id"
        loading={props.loading || feedLoading}
        size="small"
      />
    </div>
  );
};

export default TableList;
