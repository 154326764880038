import { oktaAuth } from "@customerexperience/cgl-dxo-dxp-fe-okta-libs";
import { analytics } from "@customerexperience/cgl-dxo-dxp-fe-analytics";
const { REACT_APP_ENV } = process.env;

const AA_LoginEvent = async (event: any) => {
  console.log("********* AA_LoginEvent =======>>>>>");
  console.log(event);
  if (event === "SUCCESS" && (REACT_APP_ENV === "stage" || REACT_APP_ENV === "production")) {
    const userGroupsInfo = await oktaAuth.getAnalyticsObj();
    oktaAuth
      .getUser()
      .then((user: any) => {
        console.log("oktaAuth.getUser() =========>>>>>>");
        console.log(JSON.stringify(user));

        analytics.capture("Login", {
          experience: {
            experienceCategory: "DXP DSC China",
            capability: "Login"
          },
          page: {
            pageName: "mycargill:dsc-cn:login"
          },
          user: {
            oktaID: user.preferred_username || "",
            groups: userGroupsInfo?.groups || []
          },
          description: "User successfully authenticates."
        });
      })
      .catch((err: any) => {
        console.log("error", "==> oktaAuth.getUser", `==> there was a problem getUser: ${err}`);
      });
  }
};

const AA_PageLoadedEvent = async (pageName: string) => {
  console.log("********* AA_PageLoadedEvent =======>>>>>");
  console.log(pageName);
  if (pageName && (REACT_APP_ENV === "stage" || REACT_APP_ENV === "production")) {
    const userGroupsInfo = await oktaAuth.getAnalyticsObj();
    oktaAuth
      .getUser()
      .then((user: any) => {
        analytics.capture("Page Loaded", {
          experience: {
            experienceCategory: "DXP DSC China",
            capability: "Landing"
          },
          page: {
            pageName: `mycargill:dsc-cn:${pageName}`
          },
          user: {
            oktaID: user.preferred_username || "",
            groups: userGroupsInfo?.groups || []
          },
          description: `${pageName} page loaded successfully.`
        });
      })
      .catch((err: any) => {
        console.log("error", "==> oktaAuth.getUser", `==> there was a problem getUser: ${err}`);
      });
  }
};

export { AA_LoginEvent, AA_PageLoadedEvent };
