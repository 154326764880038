import React, { useCallback, useEffect, useState } from "react";
import { AutoComplete, message } from "antd";
import { history } from "@/api/constant";

// 车/船号输入或者选择
const TruckPlateNumberItem: React.FC<{
  value?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: (value: string, option: any) => void;
}> = props => {
  const [value, setValue] = useState<string | undefined>(props.value);
  const [truckPlateNumberOptions, setTruckPlateNumberOptions] = useState<any>([]); // 车｜船号可选列表

  const onHandleChange = useCallback(
    (newValue: string, currentOption: any) => {
      setValue(newValue);
      props.onChange && props.onChange(newValue, currentOption);
    },
    [props]
  );

  const onFilterOption = useCallback((inputValue: any, option: any) => {
    return option?.value.indexOf(inputValue) !== -1;
  }, []);

  // 车船号自动将小写字符转换为大写字母
  const handleTruckPlateNumberBlur = useCallback(
    (event?: any) => {
      let truckPlateNumberVal: string = value || "";
      // 英文小写字母需要自动转换成大写
      const reg = /^[a-z]+$/g;
      if (reg.test(truckPlateNumberVal)) {
        truckPlateNumberVal = truckPlateNumberVal?.toUpperCase();
        // 设置成转换后的值
        setValue(truckPlateNumberVal);
      }
    },
    [value]
  );

  // 获取输入的历史参考数据
  const getTruckPlateNumber = useCallback(
    (event?: any) => {
      // 只在每次渲染后，第一次focus是获取一次
      if (truckPlateNumberOptions && truckPlateNumberOptions.length > 0) {
        return;
      }
      history({ type: "truckMessage", searchTerm: "" })
        .then((res: any) => {
          if (res.code && res.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(res.errorMsg);
          } else if (res?.data?.history) {
            const data = res.data.history.map((val: any) => {
              return { label: val.entryKey, value: val.entryKey, entryvalue: val.entryValue };
            });
            setTruckPlateNumberOptions(data);
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          message.error("获取数据失败，请稍后重试。");
        });
    },
    [truckPlateNumberOptions]
  );

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <AutoComplete
      data-testid="truckPlateNumber"
      placeholder="请输入"
      value={value}
      options={truckPlateNumberOptions}
      onBlur={handleTruckPlateNumberBlur}
      onFocus={getTruckPlateNumber}
      onChange={onHandleChange}
      filterOption={onFilterOption}
    />
  );
};

export default TruckPlateNumberItem;
