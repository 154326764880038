import { useState, useEffect, useCallback } from "react";
import { Card, PageHeader } from "antd";
import SearchFilter from "./searchFilter";
import TableList from "./tableList";
import { getErrorList } from "@/api/userManagement";
import "./index.scss";

const ErrorHandle = () => {
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [requestListValue, setRequestListValue] = useState({
    limit: 10,
    offset: 0,
    documentNo: "",
    taskState: "",
    calledSystem: ""
  });

  const onPageChange = (page: number, pageSize: number) => {
    if (pageSize === requestListValue.limit) {
      setPageSize(requestListValue.limit);
      setCurrent(page);
      const pager = JSON.parse(JSON.stringify(requestListValue));
      pager.offset = (page - 1) * pageSize;
      setRequestListValue(pager);
    } else {
      setCurrent(1);
      const pager = JSON.parse(JSON.stringify(requestListValue));
      pager.offset = 0;
      pager.limit = pageSize;
      setRequestListValue(pager);
      setPageSize(pager.limit);
    }
  };

  const paginationOpt: any = {
    showQuickJumper: true,
    total: total,
    showSizeChanger: true,
    onChange: onPageChange,
    current: current,
    size: "default",
    pageSize: pageSize
  };

  const getListData = useCallback(() => {
    const values = requestListValue;
    setPageSize(values.limit);
    setLoading(true);
    getErrorList(values)
      .then(res => {
        if (res?.data?.metadata) {
          const tableData = res.data.errorTaskList || [];
          const total = res.data.metadata.totalCount ?? 0;
          setData(tableData);
          setTotal(total);
        } else {
          setData([]);
          setTotal(0);
        }
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [requestListValue]);

  useEffect(() => {
    document.title = "错误处理";
  }, []);

  useEffect(() => {
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestListValue]);

  return (
    <>
      <PageHeader className="page-header" title="错误处理" />
      <div className="settlement-list">
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb" }}
          bodyStyle={{ paddingTop: 0 }}
          title="查询条件"
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
        >
          <SearchFilter setFields={setRequestListValue} setCurrent={setCurrent} />
        </Card>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
          bodyStyle={{ paddingTop: 0 }}
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
          title={
            <div>
              <span>错误列表</span>
              <div className="report-header">
                <span className="total">共{total}条</span>
              </div>
            </div>
          }
        >
          <TableList tableData={data} paginationOpt={paginationOpt} getListData={getListData} loading={loading} />
        </Card>
      </div>
    </>
  );
};
export default ErrorHandle;
