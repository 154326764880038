import { Component } from "react";
import { PageHeader, Card, Table, Tooltip, Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import "./index.scss";

import SearchFilters from "../components/searchFilters";
import DownloadTimeWindow from "./components/downloadTimeWindow";

import { getBookedDataListApi, downloadDoAppointmentReportApi } from "@/api/slotbooking";
import {
  convertUTCDateToChinaDateAR,
  downloadFile,
  cutHeaderZero,
  cutBatchNoZero,
  deviceWidth,
  formatValue
} from "@/utils";
import NotificationTips from "@/pages/common/notificationTips";

interface State {
  isTableLoading: boolean;
  isReportDownloadLoading: boolean;
  columnsData: object;
  tableDataSource: object;
  pagination: object;
}

type StringTypes = string | null | undefined;

class Booked extends Component<{}, State> {
  public readonly state = {
    isTableLoading: false,
    isReportDownloadLoading: false,
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showQuickJumper: true,
      size: deviceWidth() > 576 ? ("default" as any) : ("small" as any)
    },
    tableDataSource: [],
    columnsData: [
      { title: "提单组号", dataIndex: "groupNo", key: "groupNo", ellipsis: true, width: 180 },
      { title: "提单类型", dataIndex: "deliveryType", key: "deliveryType", width: 100 },
      { title: "提单头编号", dataIndex: "salesOrderNumber", key: "salesOrderNumber", width: 150 },
      { title: "提单行项目编号", dataIndex: "salesOrderItem", key: "salesOrderItem", width: 150 },
      { title: "买方", dataIndex: "buyer", key: "buyer", ellipsis: true, width: 200 },
      { title: "卖方", dataIndex: "orgName", key: "orgName", ellipsis: true, width: 200 },
      { title: "业务类型代码", dataIndex: "divisionCode", key: "divisionCode", width: 120 },
      { title: "业务类型", dataIndex: "business", key: "business", width: 120 },
      { title: "合同编号", dataIndex: "contractNo", key: "contractNo", width: 100 },
      { title: "批次", dataIndex: "batchNo", key: "batchNo", width: 80 },
      { title: "产品名称", dataIndex: "productName", key: "productName", ellipsis: true, width: 200 },
      { title: "产品代码", dataIndex: "productCode", key: "productCode", ellipsis: true, width: 150 },
      { title: "包装规格", dataIndex: "specifications", key: "specifications", width: 150 },
      { title: "计数单位", dataIndex: "packingUnitDesc", key: "packingUnitDesc", width: 120 },
      { title: "车/船号", dataIndex: "plateNo", key: "plateNo", width: 150 },
      { title: "司机姓名", dataIndex: "driverName", key: "driverName", ellipsis: true, width: 150 },
      { title: "司机联系方式", dataIndex: "driverPhone", key: "driverPhone", ellipsis: true, width: 150 },
      { title: "计划提货数量", dataIndex: "doPlanQrt", key: "doPlanQrt", width: 150 },
      { title: "实际提货数量", dataIndex: "doActualQrt", key: "doActualQrt", width: 150 },
      { title: "提单提交时间", dataIndex: "submitTime", key: "submitTime", width: 150 },
      { title: "预计提货日期", dataIndex: "deliveryEndTime", key: "deliveryEndTime", width: 150 },
      { title: "提单分组时间", dataIndex: "groupTime", key: "groupTime", width: 150 },
      { title: "提单开卡时间", dataIndex: "openCardTime", key: "openCardTime", width: 150 },
      { title: "第一次称重时间", dataIndex: "weighTime1", key: "weighTime1", width: 150 },
      { title: "第二次称重时间", dataIndex: "weighTime2", key: "weighTime2", width: 150 },
      { title: "离厂时间", dataIndex: "leaveTime", key: "leaveTime", width: 150 },
      { title: "预约状态", dataIndex: "appointmentStatus", key: "appointmentStatus", width: 150 },
      { title: "预约时间", dataIndex: "appointmentTime", key: "appointmentTime", width: 150 },
      { title: "操作人", dataIndex: "operator", key: "operator", ellipsis: true, width: 200 },
      { title: "操作时间", dataIndex: "appointmentCreateTime", key: "appointmentCreateTime", width: 150 },
      {
        title: "第一次变更取消原因",
        dataIndex: "cancelReason1",
        key: "cancelReason1",
        ellipsis: true,
        width: 200,
        render: (cancelReason1: StringTypes) => {
          if (cancelReason1 && cancelReason1.length > 10) {
            return <span>{`${cancelReason1?.substring(0, 10)}...`}</span>;
          } else {
            return <span>{cancelReason1}</span>;
          }
        }
      },
      {
        title: "第二次变更取消原因",
        dataIndex: "cancelReason2",
        key: "cancelReason2",
        ellipsis: true,
        width: 200,
        render: (cancelReason2: StringTypes) => {
          if (cancelReason2 && cancelReason2.length > 10) {
            return <span>{`${cancelReason2?.substring(0, 10)}...`}</span>;
          } else {
            return <span>{cancelReason2}</span>;
          }
        }
      },
      {
        title: "备注",
        dataIndex: "sysRemark",
        key: "sysRemark",
        ellipsis: {
          showTitle: false
        },
        width: 260,
        render: (sysRemark: StringTypes) => {
          if (sysRemark && sysRemark.length > 15) {
            return <Tooltip placement="topLeft" title={sysRemark}>{`${sysRemark?.substring(0, 15)}...`}</Tooltip>;
          } else {
            return <span>{sysRemark}</span>;
          }
        }
      }
    ]
  };

  searchFiltersData = {};
  urlParams = {
    limit: 10,
    offset: 0
    // sort: "id:desc"
  };

  componentDidMount() {
    document.title = "预约执行列表";
  }

  // 获取已预约列表数据
  getBookedDataList = () => {
    const { pagination } = this.state;

    let paginationTemp = pagination;
    if (this.urlParams.offset === 0 && pagination.current !== 1) {
      // 重置页码
      paginationTemp = { ...pagination, current: 1 };
      this.setState({ pagination: paginationTemp });
    }
    // 筛选条件
    const postDataObj = { ...this.searchFiltersData };
    this.setState({ isTableLoading: true });
    getBookedDataListApi(this.urlParams, postDataObj)
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
        } else if (responseJSON?.data) {
          const tableRecordData = Array.isArray(responseJSON.data.resultList) ? responseJSON.data.resultList : [];
          this.setState({
            tableDataSource: tableRecordData.map(this.mapTableData) || [],
            pagination: {
              ...paginationTemp,
              total: responseJSON.data.metadata?.totalCount ?? 0
            }
          });
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        this.setState({
          tableDataSource: [],
          pagination: {
            ...paginationTemp,
            total: 0
          }
        });
      })
      .finally(() => {
        this.setState({ isTableLoading: false });
      });
  };

  // 处理table相关参数变化
  handleTableDataChange = (value: any) => {
    const { pagination } = this.state;

    if (value.pageSize !== pagination.pageSize) {
      // 每页显示的条数改变
      this.urlParams = {
        ...this.urlParams,
        offset: 0,
        limit: value.pageSize
      };
      this.setState(
        {
          pagination: {
            ...pagination,
            current: 1,
            pageSize: value.pageSize
          }
        },
        () => {
          // 获取新的数据
          this.getBookedDataList();
        }
      );
    } else if (value.current !== pagination.current) {
      // 当前页码改变
      this.urlParams = {
        ...this.urlParams,
        offset: value.pageSize * (value.current - 1),
        limit: value.pageSize
      };
      this.setState(
        {
          pagination: {
            ...pagination,
            current: value.current,
            pageSize: value.pageSize
          }
        },
        () => {
          // 获取新的数据
          this.getBookedDataList();
        }
      );
    }
  };

  // 格式化处理table的展示的信息
  mapTableData = (data: any) => ({
    doId: data.doId,
    groupNo: formatValue(data.groupNo, "-"),
    deliveryType: formatValue(data.deliveryType, "-"),
    salesOrderNumber: cutHeaderZero(data.salesOrderNumber) || "-",
    salesOrderItem: cutHeaderZero(data.salesOrderItem) || "-",
    buyer: formatValue(data.buyer, "-"),
    orgName: formatValue(data.orgName, "-"),
    business: formatValue(data.business, "-"),
    divisionCode: formatValue(data.divisionCode, "-"),
    contractNo: formatValue(data.contractNo, "-"),
    batchNo: cutBatchNoZero(data.batchNo) || "-",
    productName: formatValue(data.productName, "-"),
    productCode: cutHeaderZero(data.productCode) || "-",
    specifications: formatValue(data.specifications, "-"),
    packingUnitDesc: formatValue(data.packingUnitDesc, "-"),
    plateNo: formatValue(data.plateNo, "-"),
    driverName: formatValue(data.driverName, "-"),
    driverPhone: formatValue(data.driverPhone, "-"),
    doPlanQrt: formatValue(data.doPlanQrt, "-"),
    doActualQrt: formatValue(data.doActualQrt, "-"),
    submitTime: data.submitTime ? this.mapDateTime(data.submitTime) : "-",
    deliveryEndTime: data.deliveryEndTime ? moment(data.deliveryEndTime).format("YYYY-MM-DD") : "-",
    groupTime: data.groupTime ? this.mapDateTime(data.groupTime) : "-",
    openCardTime: data.openCardTime ? this.mapDateTime(data.openCardTime) : "-",
    weighTime1: data.weighTime1 ? this.mapDateTime(data.weighTime1) : "-",
    weighTime2: data.weighTime2 ? this.mapDateTime(data.weighTime2) : "-",
    leaveTime: data.leaveTime ? this.mapDateTime(data.leaveTime) : "-",
    appointmentStatus: formatValue(data.appointmentStatusCn, "-"),
    appointmentTime: data.appointmentTime ? this.mapDateTime(data.appointmentTime) : "-",
    operator: formatValue(data.operator, "-"),
    appointmentCreateTime: data.appointmentCreateTime ? this.mapDateTime(data.appointmentCreateTime) : "-",
    cancelReason1: formatValue(data.cancelReason1, "-"),
    cancelReason2: formatValue(data.cancelReason2, "-"),
    sysRemark: formatValue(data.sysRemark, "-")
  });

  mapDateTime = (date: Date) => {
    const chinaDateTime = convertUTCDateToChinaDateAR(date);
    return `${moment(chinaDateTime.date).format("YYYY-MM-DD")} ${chinaDateTime.time}`;
  };

  // Card组件的extra信息展示
  cardExtra = () => {
    const { pagination, isReportDownloadLoading } = this.state;
    return (
      <div className="hdp-uf hdp-uf-vc card-extra">
        <div className="extra-total extra-item">
          <span>共 </span>
          <span>{pagination.total}</span>
          <span> 条</span>
        </div>
        <div className="extra-item">
          <Button
            data-testid="test-download-reports"
            type="primary"
            loading={isReportDownloadLoading}
            icon={<DownloadOutlined />}
            disabled={pagination.total === 0}
            onClick={this.handleDownloadReports}
          >
            下载报表
          </Button>
        </div>
        <div className="extra-item">
          <DownloadTimeWindow />
        </div>
      </div>
    );
  };

  //下载报表
  handleDownloadReports = () => {
    const { pagination } = this.state;
    if (pagination.total > 3000) {
      message.warning("超过最大下载3000条数据范围，请重新选择数据下载。");
      return;
    }

    const downloadParams = {
      ...this.searchFiltersData
    };
    this.setState({ isReportDownloadLoading: true });
    downloadDoAppointmentReportApi(downloadParams)
      .then((response: any) => {
        if (response) {
          downloadFile(response, `预约执行列表(${moment().format("YYYY-MM-DD")}).xls`);
        } else {
          message.error("下载失败，请稍后重试。");
        }
      })
      .catch((exception: any) => {
        message.error("下载失败，请稍后重试。");
        console.log(exception);
      })
      .finally(() => {
        this.setState({ isReportDownloadLoading: false });
      });
  };

  // 处理查询 -- 默认条件加载初始化时会自动触发获取一次
  handleSearch = (value: any) => {
    this.searchFiltersData = { ...this.searchFiltersData, ...value };
    this.urlParams = { ...this.urlParams, offset: 0 };
    this.getBookedDataList();
  };

  public render() {
    const { tableDataSource, columnsData, pagination, isTableLoading } = this.state;

    return (
      <div className="booked-page">
        <div className="hdp-uf hdp-uf-dc">
          <PageHeader className="page-header" title="预约执行列表">
            <NotificationTips />
          </PageHeader>
          {/* 查询条件区域 */}
          <SearchFilters doSearch={this.handleSearch} />
          {/* table数据展示区域 */}
          <div className="booked-table-container">
            <Card
              style={{ boxShadow: "0 5px 9px #dedbdb" }}
              bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
              title="预约执行列表"
              headStyle={{
                backgroundColor: "white",
                borderBottom: "none"
              }}
              extra={this.cardExtra()}
            >
              <Table
                size="small"
                loading={isTableLoading}
                dataSource={tableDataSource}
                columns={columnsData}
                pagination={pagination}
                onChange={this.handleTableDataChange}
                rowKey="doId"
                scroll={{ x: "max-content" }}
              />
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default Booked;
