import { put, takeEvery, all, call } from "redux-saga/effects";
import * as userPermissions from "../actions/userPermissions";

export function* getPermissionData(action: any): any {
  try {
    const response = yield call(action.Get);
    let payloadObj = null;
    if (response) {
      payloadObj = { ...response };
    }
    yield put(userPermissions.getUserPermissionsSuccess(payloadObj));
  } catch (error) {
    yield put(userPermissions.getUserPermissionsError(error));
  }
}

export function* getUserPermissionsDataSaga() {
  yield takeEvery("GET_USER_PERMISSION_DATA", getPermissionData);
}

export function* rootUserPermissionsSaga() {
  yield all([getUserPermissionsDataSaga()]);
}
