import { useState, useCallback } from "react";
import { Modal, Button, DatePicker, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import "./index.scss";

import { downloadDoTimeWindowReportApi } from "@/api/slotbooking";
import { downloadFile } from "@/utils";

const { RangePicker } = DatePicker;

const DownloadTimeWindow = () => {
  const [dateRange, setDateRange] = useState<string[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showChooseModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  //下载已发布时间窗信息
  const handleOk = useCallback(() => {
    const downloadParams = { startTime: dateRange[0], endTime: dateRange[1] };
    setConfirmLoading(true);
    downloadDoTimeWindowReportApi(downloadParams)
      .then((response: any) => {
        if (response) {
          downloadFile(
            response,
            `已发布时间窗信息(${moment(dateRange[0]).format("YYYY-MM-DD")}_${moment(dateRange[1]).format(
              "YYYY-MM-DD"
            )}).xls`
          );
        } else {
          message.error("下载失败，请稍后重试。");
        }
      })
      .catch((exception: any) => {
        console.log(exception);
        message.error("下载失败，请稍后重试。");
      })
      .finally(() => {
        setModalVisible(false);
        setConfirmLoading(false);
      });
  }, [dateRange]);

  const handleCancel = useCallback(() => {
    setModalVisible(false);
    setDateRange([]);
  }, []);

  const dataChange = useCallback((dates: any) => {
    if (dates) {
      setDateRange([moment(dates[0]).format("YYYY-MM-DD"), moment(dates[1]).format("YYYY-MM-DD")]);
    } else {
      setDateRange([]);
    }
  }, []);

  const disabledDate = useCallback((current: any) => {
    // Can not select days
    // 只允许下载当前月到当前天，以及往前3个月的数据
    const currentDayNum = new Date().getDate();
    const tooLate = moment()
      .endOf("day")
      .subtract(currentDayNum - 1, "days")
      .subtract(3, "months")
      .subtract(1, "days");
    const tooEarly = new Date().setDate(new Date().getDate());
    return current && (current < tooLate || current > tooEarly);
  }, []);

  return (
    <>
      <Button
        data-testid="test-show-download-modal"
        type="primary"
        icon={<DownloadOutlined />}
        onClick={showChooseModal}
      >
        下载时间窗
      </Button>
      {modalVisible && (
        <Modal
          title="下载时间窗"
          className="download-time-window"
          visible={modalVisible}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          okButtonProps={{ disabled: dateRange.length !== 2 }}
          cancelButtonProps={{ className: "btn-cancel" }}
        >
          <div className="hdp-uf hdp-uf-dc download-time-window-content">
            <label className="required">时间窗提货日期开始时间和截止时间</label>
            <RangePicker
              data-testid="test-download-time-window"
              placeholder={["起始日期", "截止日期"]}
              disabledDate={disabledDate}
              onChange={dataChange}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default DownloadTimeWindow;
