import React, { useCallback, useEffect, useState } from "react";
import { Input } from "antd";
import { isSpecialFactory } from "@/utils/common";

// 计划提货数量
const DoQuantityItem: React.FC<{
  doData: any;
  transportTool: string;
  value?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: (value: string, affectedFields: any) => void;
}> = props => {
  const [value, setValue] = useState<string | undefined>(props.value);

  const bigOrderCheck = useCallback(
    (doQuantityVal: string | number) => {
      if (props.transportTool === "火车" || props.transportTool === "船") {
        return true;
      } else {
        return (
          (props.doData.packingUnit === "TO" || props.doData.packingUnit === "MT") &&
          props.doData.businessDivision === "ACM" &&
          props.transportTool === "车" &&
          doQuantityVal &&
          Number(doQuantityVal) > 50
        );
      }
    },
    [props.doData.businessDivision, props.doData.packingUnit, props.transportTool]
  );

  // 反算doQuantity
  const backCalculationDoQuantity = useCallback(
    (doQuantity: number | string, packageVal: number) => {
      // 南方三厂在谷物油籽情况下，不再反算吨数了，其他情况需要计算
      if (!isSpecialFactory({ orgCode: props.doData.orgCode, businessLine: props.doData.businessLine })) {
        // 反算doQuantity的值
        const doQuantityByPackage = packageVal;
        if (doQuantityByPackage) {
          const calValue = parseFloat((Number(doQuantityByPackage) * props.doData.conversionFactor).toString()).toFixed(
            3
          );
          if (doQuantity.toString() !== calValue) {
            setValue(calValue);
            return calValue;
          }
        } else {
          setValue("");
        }
      }
      return doQuantity;
    },
    [props.doData.businessLine, props.doData.conversionFactor, props.doData.orgCode]
  );

  // 根据计划数量吨获取计划提货数量--光标移出后再计算
  const onDoQuantityBlur = useCallback(
    (event: any) => {
      let doQuantity: number | string = event.target.value;
      let affectedFields = {};
      doQuantity = doQuantity ? parseFloat(event.target.value) : "";

      if (Number(doQuantity) === Number(props.value)) {
        return;
      }

      if (props.doData?.conversionFactor && doQuantity && props.doData?.packingUnitDesc.trim() === "吨") {
        // 计算计划提货数量（件）的值
        let packageVal: string | number = parseFloat(
          (Number(doQuantity) / props.doData.conversionFactor).toString()
        ).toFixed(3);
        packageVal = parseInt(packageVal); // 箱/件要向下取整
        // 件数不一致重新赋值，并再次进入反算吨数的逻辑
        if (props.doData?.doQuantityByPackage !== packageVal.toString()) {
          affectedFields = { doQuantityByPackage: packageVal };
          // 反算doQuantity的值
          doQuantity = backCalculationDoQuantity(doQuantity, packageVal);
        }
      } else if (props.doData?.conversionFactor && doQuantity && props.doData?.packingUnitDesc.trim() === "千克") {
        let packageVal = parseFloat(((Number(doQuantity) * 0.001) / props.doData.conversionFactor).toString()).toFixed(
          3
        );
        if (props.doData?.doQuantityByPackage !== packageVal.toString()) {
          // 件数不一致重新赋值
          affectedFields = { doQuantityByPackage: packageVal };
        }
      } else if (props.doData?.conversionFactor && doQuantity === 0) {
        affectedFields = { doQuantityByPackage: "" };
      }

      // 大提单判断--ACM && 车 && (packingUnit===TO||MT)时，需要自动根据doQuantity的数量来判断是否为大提单
      const bigOrderVal = bigOrderCheck(doQuantity) ? "Y" : "N";
      affectedFields = { ...affectedFields, bigOrder: bigOrderVal };

      props.onChange && props.onChange(doQuantity.toString(), affectedFields);
    },
    [backCalculationDoQuantity, bigOrderCheck, props]
  );

  // 处理计划提货数量的改变--实时输入时并校验
  const onHandleDoQuantityChange = useCallback(
    (event: any) => {
      let value = event.target.value;

      if (props.doData.packingUnit === "BAG") {
        // 验证只能输入大于0的正整数
        const reg = /^[1-9]\d*$/;
        if (value && !reg.test(value)) {
          if (value.toString().length === 1) {
            value = value.replace(/[^1-9]/g, "");
          } else {
            value = value.replace(/\D/g, "");
          }
        }
      } else {
        const reg = /^([1-9]\d*\.?|0\.)\d{0,3}$/;
        if (value && !reg.test(value)) {
          value = value.replace(/[^0-9\\.]/g, "");
          const doQuantityValueArr = value.split(".");
          if (doQuantityValueArr.length > 1) {
            value = `${doQuantityValueArr[0]}.${doQuantityValueArr[1].substring(0, 3)}`;
          }
        }
      }
      setValue(value);
    },
    [props.doData.packingUnit]
  );

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Input
      data-testid="test-doQuantity"
      value={value}
      onBlur={onDoQuantityBlur}
      onChange={onHandleDoQuantityChange}
      placeholder="请输入"
    />
  );
};

export default DoQuantityItem;
