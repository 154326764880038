import { downloadPdf } from "@/api/directSys";
import { Button, message } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { downloadFile } from "@/utils";
import { useCallback, useState } from "react";

interface Props {
  params: any;
  type?: string;
  filename?: string;
  text?: boolean;
  pdfDownloadFlag: boolean;
}

const DownloadPdf = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const handleDownloadReports = useCallback(() => {
    setLoading(true);
    downloadPdf({ invoiceNo: props.params })
      .then((response: any) => {
        if (response) {
          downloadFile(response, `SettlementInvoice.pdf`);
        } else {
          message.error("下载失败，请稍后重试。");
        }
      })
      .catch((exception: any) => {
        message.error("下载失败，请稍后重试。");
        console.log(exception);
      })
      .finally(() => setLoading(false));
  }, [props.params]);

  return (
    <div className="download-pdf">
      <Button
        type="link"
        loading={loading}
        style={{ width: 40, padding: 0 }}
        icon={<VerticalAlignBottomOutlined />}
        onClick={handleDownloadReports}
        disabled={!props.pdfDownloadFlag}
      />
    </div>
  );
};

export default DownloadPdf;
