// slot-booking 查询条件
import { useState, useImperativeHandle } from "react";
import { Button, Upload, message, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { uploadFileApi } from "@/api/slotbooking";

/** UploadBtn
 * myProps 定义函数组件的props相关参数类型
 * cb_upload:(void) 可选，组件上传成功后的回调函数，返回上传接口返回的信息，可用一个JSON对象变量接收
 * cb_remove:(void) 可选，上传的文件删除后的回调函数，返回上传的文件数组，可用一个数组对象变量接收
 * upload_config:(必传，相关配置的可用参数)
 *   actionUrl<string>: 必传，上传的接口API地址
 *   accept<string>: 不传默认全部允许。可选 "image/*, video/*, audio/*, .pdf, .doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
 *   multiple<boolean>: 是否支持选择多个文件，默认 false
 *   maxCount<number>: 设置最大上传数量，默认为1
 *   sizeLimit<number>: 限制最大上传的文件大小，默认 10M
 *   showUploadList<boolean>: 是否展示已上传的文件名字，默认 false
 *   btnType<string>: 根据antd的Button的type的可传格式来，默认是 primary
 *   btnText<string>: 按钮上展示的文字 默认值为 “上传”
 */

type myProps = { cb_upload?: (arg0: any) => void; cb_remove?: (arg0: any) => void; upload_config: any; cRef?: any };

const UploadBtn = (props: myProps) => {
  const [currentFileList, setCurrentFileList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const generateUploadParams = (isUpdate = false, uploadFileName = "financeSetting") => {
    const dateVal = new Date().getTime();
    const metaData = {
      docType: "financeSetting",
      doc_id: dateVal,
      doc_number: dateVal,
      publish_date: moment(dateVal).format("YYYY-MM-DD"),
      vendor_name: uploadFileName
    };

    let JSONObj = {};
    const json = {
      langCode: "en",
      type: "",
      metadata: metaData
    };
    JSONObj = isUpdate ? { existing: json, newValues: json } : { ...json };
    const jsonse = JSON.stringify(JSONObj);
    const blob = new Blob([jsonse], { type: "application/json" });
    return {
      blob
    };
  };

  // 上传提货计划
  const handleFileUpload = (values: any) => {
    if (values) {
      const isIdToken = props.upload_config.idtoken || false;
      const currentFormData = setFormData(values);

      // 执行上传
      setIsLoading(true);
      uploadFileApi(currentFormData, props.upload_config.actionUrl, isIdToken)
        .then((responseJSON: any) => {
          if (responseJSON.status === "success" || responseJSON.data || responseJSON.metadata) {
            Modal.success({ content: "上传成功。" });
            if (props.cb_upload) {
              props.cb_upload(responseJSON);
            }
          } else if (responseJSON.code === "99999" && responseJSON.responseError?.message) {
            message.error(responseJSON.responseError.message);
          }
        })
        .catch((exception: any) => {
          Modal.error({ content: `上传失败 ${exception.errorMsg}。` });
        })
        .finally(() => {
          if (!props.upload_config.showUploadList) {
            setCurrentFileList([]);
          }
          setIsLoading(false);
        });
    }
  };

  const setFormData = (values: any) => {
    const formData = new FormData();
    values.forEach((file: any) => {
      const currentFileName = file.name; // 可自定义格式化名字
      formData.append("file", file);
      if (props.upload_config.idtoken) {
        const { blob } = generateUploadParams(false, currentFileName);
        formData.append("payload", blob);
      }
    });
    return formData;
  };

  const upload_props = {
    maxCount: props.upload_config.multiple ? props.upload_config.maxCount : 1,
    showUploadList: props.upload_config.showUploadList || false,
    onRemove: (file: any) => {
      const removeIndex = currentFileList.findIndex(item => item.uid === file.uid);
      const newFileList = [...currentFileList];
      if (removeIndex !== -1) {
        newFileList.splice(removeIndex, 1);
      }
      setCurrentFileList([...newFileList]);
      if (props.cb_remove) {
        props.cb_remove(newFileList);
      }
    },
    beforeUpload: (file: any) => {
      if (!file) return false;

      const isFileType = props.upload_config.accept ? props.upload_config.accept?.indexOf(file.type) !== -1 : true;
      if (!isFileType) {
        message.warning("只能上传指定格式的文件");
      }
      const isLimitSize = file.size / 1024 / 1024 < (props.upload_config.sizeLimit || 10);
      if (!isLimitSize) {
        message.warning(`文件大小不能超过${props.upload_config.sizeLimit || 10}M`);
      }

      setCurrentFileList([file]);
      handleFileUpload([file]);
      return isFileType && isLimitSize;
    },
    fileList: currentFileList
  };

  useImperativeHandle(props.cRef, () => ({
    // 暴露给父组件的方法
    resetFileList: () => {
      setCurrentFileList([]);
    }
  }));

  return (
    <>
      <Upload data-testid="test-upload-btn" {...upload_props}>
        <Button type={props.upload_config.btnType || "primary"} icon={<UploadOutlined />} loading={isLoading}>
          {props.upload_config.btnText || "上传"}
        </Button>
      </Upload>
    </>
  );
};

export default UploadBtn;
