import { useState, useEffect } from "react";
import { Table, Tooltip } from "antd";
import moment from "moment";
import UpdateModal from "../updateModal";

const TableList = (props: any) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const cellStyle = () => {
    return {
      style: {
        maxWidth: 150,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        cursor: "pointer"
      }
    };
  };
  const onSelectChange = (selectedRowKeys: any, rows: any) => {
    setSelectedRowKeys(selectedRowKeys);
    props.setIsSelected(selectedRowKeys.length > 0);
    props.setSelectedRow(rows);
  };
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    fixed: true
  };

  const showAppliedNo = (data: any) => {
    if (data.appliedNo) {
      if (data.contractType === "一口价合同" && (data.executeWay === "新增" || data.executeWay === "变更")) {
        return "-";
      }
      return data.appliedNo;
    }
    return "-";
  };

  const sourceIsTW = (item: any) => {
    const allowSystemSource: string[] = ["通威", "牧原"];
    if (allowSystemSource.includes(item.systemSource) && item.basisContractNo) {
      return item.basisContractNo?.replace(/GOCCHN-/g, "");
    }
    return "-";
  };

  const columns: any = [
    {
      title: "客户合同编号",
      dataIndex: "contractNo",
      key: "contractNo",
      render: (text: any) => <>{text === null || text === "" ? "-" : text}</>
    },
    {
      title: "客户原合同号",
      dataIndex: "originContractNo",
      key: "originContractNo",
      render: (text: any) => <>{text === null || text === "" ? "-" : text}</>
    },
    {
      title: "点价申请号",
      render: (record: any) => <>{showAppliedNo(record)}</>
    },
    {
      title: "嘉吉合同号",
      dataIndex: "crglContractNo",
      key: "crglContractNo",
      render: (text: any) => (
        <>{text === null ? "-" : text.split("_")[0] + "_" + text.split("_")[1]?.replace(/\b(0+)/gi, "")}</>
      )
    },
    {
      title: "嘉吉原合同号",
      render: (record: any) =>
        record.systemSource === "温氏" || record.systemSource === "正大" ? (
          <UpdateModal
            crglContractNo={record.crglContractNo}
            basisContractNo={record.basisContractNo}
            getListData={props.getListData}
          />
        ) : (
          sourceIsTW(record)
        )
    },
    {
      title: "合同类型",
      dataIndex: "contractType",
      key: "contractType"
    },
    {
      title: "合同执行方式",
      dataIndex: "executeWay",
      key: "executeWay"
    },
    {
      title: "业务场景",
      dataIndex: "businessScenario",
      key: "businessScenario",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "合同变更类型",
      dataIndex: "changeType",
      key: "changeType",
      render: (text: any) => <>{text === null || text === "" ? "-" : text}</>
    },
    {
      title: "业务处理状态",
      dataIndex: "directStatus",
      key: "directStatus",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "直连请求状态",
      dataIndex: "contractState",
      key: "contractState"
    },
    {
      title: "买方公司",
      dataIndex: "buyer",
      key: "buyer"
    },
    {
      title: "供应商",
      dataIndex: "supplier",
      key: "supplier"
    },
    {
      title: "产品名称",
      dataIndex: "productName",
      key: "productName"
    },
    {
      title: "包装规格",
      dataIndex: "specification",
      key: "specification"
    },
    {
      title: "合同数量",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: any) => <>{!text ? "-" : text.toFixed(3)}</>
    },
    {
      title: "客户直连出厂价",
      dataIndex: "exPrice",
      key: "exPrice",
      render: (text: any) => <>{!text ? "-" : text.toFixed(2)}</>
    },
    {
      title: "待执行合同量",
      dataIndex: "toBeExecutedQty",
      key: "toBeExecutedQty",
      render: (text: any) => <>{!text ? "-" : text.toFixed(3)}</>
    },
    {
      title: "基差价格",
      dataIndex: "basisPrice",
      key: "basisPrice",
      render: (text: any) => <>{!text ? "-" : text.toFixed(2)}</>
    },
    {
      title: "期货合约",
      dataIndex: "futuresContract",
      key: "futuresContract",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "运费",
      dataIndex: "freight",
      key: "freight",
      render: (text: any) => <>{!text ? "-" : text.toFixed(2)}</>
    },
    {
      title: "交/提货方式",
      dataIndex: "deliveryType",
      key: "deliveryType",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "运输方式",
      dataIndex: "transportType",
      key: "transportType",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "交货地点",
      dataIndex: "deliveryLocation",
      key: "deliveryLocation",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "饲料厂名称",
      dataIndex: "feedPlant",
      key: "feedPlant",
      render: (text: any) => <>{text === null || text === "" ? "-" : text}</>
    },
    {
      title: "过磅依据",
      dataIndex: "weighBasis",
      key: "weighBasis",
      render: (text: any) => <>{text === null || text === "" ? "-" : text}</>
    },
    {
      title: "结算单价",
      dataIndex: "price",
      key: "price",
      render: (text: any) => <>{!text ? "-" : text.toFixed(2)}</>
    },
    {
      title: "提货起始日期",
      dataIndex: "ctStartDate",
      key: "ctStartDate",
      render: (text: any) => <>{!text ? "-" : moment(text).format("YYYY-MM-DD")}</>
    },
    {
      title: "提货结束日期",
      dataIndex: "ctEndDate",
      key: "ctEndDate",
      render: (text: any) => <>{!text ? "-" : moment(text).format("YYYY-MM-DD")}</>
    },
    {
      title: "合同总金额（含税）",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text: any) => <>{!text ? "-" : text.toFixed(2)}</>
    },
    {
      title: "合同签订日期",
      dataIndex: "signDate",
      key: "signDate",
      render: (text: any) => <>{!text ? "-" : moment(text).format("YYYY-MM-DD")}</>
    },
    {
      title: "经办人",
      dataIndex: "agent",
      key: "agent",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "经办时间",
      dataIndex: "agentTime",
      key: "agentTime",
      render: (text: any) => <>{!text ? "-" : moment(text).format("YYYY-MM-DD HH:mm:ss")}</>
    },
    {
      title: "嘉吉备注",
      onCell: cellStyle,
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.remark || null}>
          {record.remark || "-"}
        </Tooltip>
      )
    },
    {
      title: "客户备注",
      onCell: cellStyle,
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.thirdRemark || null}>
          {record.thirdRemark || "-"}
        </Tooltip>
      )
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
      render: (text: any) => <>{!text ? "-" : moment(text).format("YYYY-MM-DD HH:mm:ss")}</>
    },
    {
      title: "申请回传时间",
      dataIndex: "appliedReturnTime",
      key: "appliedReturnTime",
      render: (text: any) => <>{!text ? "-" : moment(text).format("YYYY-MM-DD HH:mm:ss")}</>
    },
    {
      title: "审批回传时间",
      dataIndex: "approvalReturnTime",
      key: "approvalReturnTime",
      render: (text: any) => <>{!text ? "-" : moment(text).format("YYYY-MM-DD HH:mm:ss")}</>
    },
    {
      title: "审批结果",
      dataIndex: "directConnectionApproval",
      key: "directConnectionApproval",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "客户审批备注",
      dataIndex: "directConnectionApprovalReason",
      key: "directConnectionApprovalReason",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "合同来源",
      dataIndex: "systemSource",
      key: "systemSource",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "嘉吉合同状态",
      dataIndex: "sapContractStatus",
      key: "sapContractStatus",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "嘉吉结价状态",
      dataIndex: "sapPriceStatus",
      key: "sapPriceStatus",
      render: (text: any) => <>{text || "-"}</>
    },
    {
      title: "嘉吉合同类型",
      dataIndex: "sapContractType",
      key: "sapContractType",
      render: (text: any) => <>{text || "-"}</>
    }
  ];

  useEffect(() => setSelectedRowKeys([]), [props.tableData]);

  return (
    <div className="contract-confirmation-table">
      <Table
        columns={columns}
        pagination={props.paginationOpt}
        rowSelection={rowSelection}
        scroll={{ x: "max-content" }}
        dataSource={props.tableData}
        rowKey="id"
        loading={props.loading}
        size="small"
      />
    </div>
  );
};

export default TableList;
