import { Button, Col, DatePicker, Form, Input, PageHeader, Row, Select, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react"
import { DownloadOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import NotificationTips from "@/pages/common/notificationTips";
import RemoteSelect from "@/pages/common/remoteSelect";
import { downloadAccountStatementApi, getAccountStatementApi, getCustomerAccountItemTimeApi, getFirmDetailListApi } from "@/api/availableBalance";
import { getFirmDetailsList } from "@/api/constant";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import { allSupplierMutiple } from "@/utils/constant";

import "./index.scss";
import { downloadFile, formatNumberToFixedThousands, getCode } from "@/utils/common";

interface Props {
  switcher: any,
  userDetails: any,
  permissions: any
}

const AccountStatement = (props: Props) => {
  const { switcher, userDetails, permissions } = props;
  const [searchloading, setSearchLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [isSelectFetching, setIsSelectFetching] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [accountStatementData, setAccountStatementData] = useState({} as any);
  const [isShowResult, setIsShowResult] = useState(false);
  const [searchValue, setSearchValue] = useState({} as any);
  const [isCurrentYearAndMonth, setIsCurrentYearAndMonth] = useState(false);
  const [customerAccountItemTime, setCustomerAccountItemTime] = useState("");

  const [form] = Form.useForm();

  // 外部Group用户筛选公司
  const onFilterOption = useCallback((inputValue: any, option: any) => {
    return ((option?.label as unknown) as string).toLowerCase().includes(inputValue?.toLowerCase());
  }, []);

  // 外部 group 用户查询公司（集团公司）
  const fetchCustomerOptions_External = useCallback((controlIdentifier: any) => {
    getFirmDetailsList({ accountNumber: controlIdentifier })
      .then((res: any) => {
        if (res.data && res.data.customerInfoEntitlement?.length > 0) {
          let options = res.data.customerInfoEntitlement.map((val: any) => {
            return {
              label: `${val.accountLongName}(${val.accountNumber})`,
              value: `${val.accountLongName}(${val.accountNumber})`,
              key: val.accountNumber,
              name: val.accountLongName
            };
          });
          const currentCustomerOption = options.find((val: any) => val.key === switcher.customerCode);
          setCustomerOptions(options);
          form.setFieldsValue({
            customerCode: currentCustomerOption?.value
          });
        } else {
          setCustomerOptions([]);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      });
  }, [form, switcher]);

  // 内部用户--获取买方选项(防抖输入查询)
  const fetchCustomerOptions_Internal = useCallback(
    (value: string | undefined, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      getFirmDetailListApi({
        customerName: value || ""
      })
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            callback([]);
            message.error(res.errorMsg);
          } else if (res.data?.customerList?.length > 0 && currentValue === value) {
            let data: any[] = res.data?.customerList?.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName + "(" + val.customerCode + ")",
                key: val.customerCode
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    []
  );

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = useCallback((current) => {
    return current && (current < moment().startOf("month").subtract(6, "months") || current > moment().endOf("month"));
  }, []);

  // 下载报表
  const handleDownloadReports = useCallback(() => {
    setDownloadLoading(true);
    setDownloadSuccess(false);
    downloadAccountStatementApi({ ...searchValue, virtualFlag: "1" })
      .then((response: any) => {
        if (response) {
          downloadFile(response, `对账单(${moment().format("YYYY-MM-DD")}).xlsx`);
          setDownloadSuccess(true);
        } else {
          message.error("下载失败，请稍后重试。");
        }
      })
      .catch((exception: any) => {
        message.error("下载失败，请稍后重试。");
        console.log(exception);
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  }, [searchValue]);

  const formataccountStatementNumber = useCallback((val: any) => {
    return val === null ? "-" : formatNumberToFixedThousands(val, 2)
  }, [])

  // 获取数据截止时间
  const getCustomerAccountItemTime = useCallback((params: object) => {
    getCustomerAccountItemTimeApi(params)
      .then((res: any) => {
        if (res?.data) {
          // 获取现金余额数据截止时间
          const customeraccountitemdetailsData: any = res?.data.find((val: any) => {
            return val.entityName === "customeraccountitemdetails";
          });
          setCustomerAccountItemTime(customeraccountitemdetailsData?.updatedDateTime || "");
        }
      })
      .catch((exception: any) => {
        setCustomerAccountItemTime("");
        console.log(`Failure: ${exception}`);
      })
  }, [setCustomerAccountItemTime]);


  // 查询对账单
  const onFinish = useCallback((values: any) => {
    setSearchLoading(true);
    setDownloadSuccess(false);
    const valuesTemp = { ...values };
    valuesTemp.customerCode = getCode(valuesTemp.customerCode);
    valuesTemp.postingDate = valuesTemp.postingDate ? `${valuesTemp.postingDate.format("YYYY-MM")}-01` : "";
    valuesTemp.virtualFlag = "1";
    setSearchValue(valuesTemp);
    getAccountStatementApi(valuesTemp)
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else if (res?.data) {
          setAccountStatementData(res.data);
          // 获取当前时间
          const currentTime = moment();
          // 获取选中的时间
          const selectedTime = moment(valuesTemp.postingDate);
          setIsCurrentYearAndMonth(currentTime.year() === selectedTime.year() && currentTime.month() === selectedTime.month());
          setIsShowResult(true);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        setAccountStatementData({});
        setIsShowResult(false);
      })
      .finally(() => {
        setSearchLoading(false);
      })
    // 获取数据截止时间
    getCustomerAccountItemTime({ customerCode: valuesTemp.customerCode });
  }, [getCustomerAccountItemTime]);

  useEffect(() => {
    document.title = "对账单";
  });

  useEffect(() => {
    if (switcher) {
      form.setFieldsValue({ orgCode: switcher.orgCode && switcher.orgCode !== "All" ? switcher.orgCode : null });
      if (permissions.CNExternalUsers) {
        // 判断是否为集团用户
        const userDataCheck = userDetails && Object.keys(userDetails).length && userDetails.entitlements;
        if (userDataCheck) {
          const { entitlements } = userDetails;
          const {
            otherControls: { companyAccountType },
            controlIdentifier
          } = entitlements[0];
          if (companyAccountType === "GROUP") {
            fetchCustomerOptions_External(controlIdentifier);
          } else {
            form.setFieldsValue({ customerCode: switcher.customerName + "(" + switcher.customerCode + ")" });
          }
        }
      }
    }
  }, [form, switcher, permissions, userDetails, fetchCustomerOptions_External]);

  return (
    <div className="account-statement-wrapper">
      <PageHeader className="page-header" title="对账单">
        <NotificationTips />
      </PageHeader>
      <div className="account-statement-container">
        <div className="search-filter">
          <div className="title">对账单总览</div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Row gutter={{ md: 16, xs: 8 }}>
              {permissions.CNInternalUsers && (
                <Col md={6} xs={24}>
                  <Form.Item
                    label="公司名称"
                    name="customerCode"
                    rules={[{ required: true, message: "请输入公司名称" }]}
                  >
                    <RemoteSelect
                      dataTestId="test-internalUser-customerCode-select-02"
                      placeholder="请输入名称查询"
                      fetchOptions={fetchCustomerOptions_Internal}
                      loading={isSelectFetching}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              )}
              {permissions.CNExternalUsers && userDetails?.isGroup && (
                <Col md={6} xs={24}>
                  <Form.Item label="公司名称" name="customerCode">
                    <Select
                      showSearch
                      placeholder="请选择公司名称"
                      options={customerOptions}
                      optionFilterProp="label"
                      filterOption={onFilterOption}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              )}
              {permissions.CNExternalUsers && !userDetails?.isGroup && (
                <Col md={6} xs={24}>
                  <Form.Item label="公司名称" name="customerCode">
                    <Input disabled />
                  </Form.Item>
                </Col>
              )}
              <Col md={6} xs={24}>
                <Form.Item label="账单月份" name="postingDate" rules={[{ required: true, message: "请选择账单月份" }]}>
                  <DatePicker style={{ width: "100%" }} placeholder="请选择" picker="month" disabledDate={disabledDate} format="YYYYMM" />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item label="卖方" name="orgCode" rules={[{ required: true, message: "请选择卖方公司" }]}>
                  <Select
                    placeholder="请选择卖方公司"
                    options={allSupplierMutiple()}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col md={2} xs={24}>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      data-testid="test-submit-btn-02"
                      type="primary"
                      htmlType="submit"
                      className="submit-btn"
                      loading={searchloading}
                      disabled={!form.getFieldValue("customerCode") || !form.getFieldValue("postingDate") || !form.getFieldValue("orgCode")}
                    >
                      查询
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="content">
          {
            isShowResult ? (<div className="search-result">
              <Row>
                <Col md={8} xs={12}>
                  <div className="title">货款</div>
                </Col>
              </Row>
              <Row className="result-row">
                <Col md={8} xs={24}>
                  <div className="result-col-item">
                    <span className="label">{isCurrentYearAndMonth ? "当前余额（元）：" : "期末余额（元）："}</span>
                    <span className="value">{
                      accountStatementData.periodStartBalanceLoan === null &&
                        accountStatementData.occuredAmountReceiveLoan === null &&
                        accountStatementData.occuredAmountDeliveryLoan === null &&
                        accountStatementData.occuredAmountDiscountLoan === null &&
                        accountStatementData.occuredAmountRefundLoan === null ? "-" :
                        formatNumberToFixedThousands(accountStatementData.periodStartBalanceLoan + accountStatementData.occuredAmountReceiveLoan + accountStatementData.occuredAmountDeliveryLoan + accountStatementData.occuredAmountDiscountLoan + accountStatementData.occuredAmountRefundLoan, 2)
                    }</span>
                  </div>
                  <div className="result-col-item">
                    <span className="label">期初余额（元）：</span>
                    <span className="value">{formataccountStatementNumber(accountStatementData.periodStartBalanceLoan)}</span>
                  </div>
                  <div className="result-col-item">
                    <span className="label">收款金额（元）：</span>
                    <span className="value">{formataccountStatementNumber(accountStatementData.occuredAmountReceiveLoan)}</span>
                  </div>
                  <div className="result-col-item">
                    <span className="label">发货金额（元）：</span>
                    <span className="value">{formataccountStatementNumber(accountStatementData.occuredAmountDeliveryLoan)}</span>
                  </div>
                  <div className="result-col-item">
                    <span className="label">折扣金额（元）：</span>
                    <span className="value">{formataccountStatementNumber(accountStatementData.occuredAmountDiscountLoan)}</span>
                  </div>
                  <div className="result-col-item">
                    <span className="label">退款金额（元）：</span>
                    <span className="value">{formataccountStatementNumber(accountStatementData.occuredAmountRefundLoan)}</span>
                  </div>
                </Col>
              </Row>
              <Row className="download-group">
                <Col md={12} xs={24} style={{ color: '#007681' }}>
                  <Button
                    className="download-btn"
                    type="primary"
                    icon={<DownloadOutlined />}
                    disabled={false}
                    loading={downloadLoading}
                    onClick={handleDownloadReports}
                  >
                    下载对账单
                  </Button>
                  {
                    downloadLoading && <span className="donwload-notice">对账单文件正在准备中，请稍等...</span>
                  }
                  {
                    downloadSuccess && <span className="download-notice">下载完成</span>
                  }
                </Col>
              </Row>
              {downloadLoading && (
                <div className="tips tips_download">
                  <ExclamationCircleOutlined />
                  <span className="label">
                    文件准备好后将自动下载，在此期间，您可以在系统中进行其他操作，但为了确保文件顺利下载，请勿关闭浏览器窗口或使用浏览器的刷新功能，感谢您的配合。
                  </span>
                </div>
              )}
              {customerAccountItemTime &&
                <div className="tips tips_time">
                  <ExclamationCircleOutlined />
                  <span className="label">以上数据系截止至【{moment(customerAccountItemTime).format("YYYY年MM月DD日 HH:mm")}】，请以实际为准。<br />
                    总览数据及下载的对账单数据仅供参考，最终金额以实际财务结算为准。<br />
                    每月第二个工作日前为财务关账时间，本月账单以及关账前的上月对账单仍然可能存在调整。
                  </span>
                </div>
              }

            </div>) : (<div className="tips">
              <ExclamationCircleOutlined />
              <span className="label">请输入以上信息后查看。</span>
            </div>)
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  switcher: state.switcher,
  userDetails: state.userDetails,
  permissions: state.userPermissions
});


export default connect(mapStateToProps, null)(AccountStatement);