import { useCallback, useEffect, useState } from "react";

import { PageHeader, Tabs } from "antd";
import NotificationTips from "@/pages/common/notificationTips";
import PaymentDetailsOverview from "./paymentOverview";
import DepositDetails from "./depositDetails";
import "./index.scss";
import PayableDetails from "./payableDetails";
import { useHistory, useLocation } from "react-router-dom";

const { TabPane } = Tabs;

const PaymentDetails = (props: any) => {
  const [activeKey, setActiveKey] = useState("1");
  const [payableDetailsSearchValue, setPayableDetailsSearchValue] = useState({});
  const [depositDetailsSearchValue, setDepositDetails] = useState({});
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    document.title = "开单额度";
    const tempLocation: any = location;
    if (tempLocation?.state?.activeKey) {
      setActiveKey(tempLocation.state.activeKey);
      history.replace({
        pathname: "/main/financialmanagement/paymentdetails",
        state: null
      });
    }
  }, [history, location, setActiveKey]);

  const onTabChange = useCallback((key: any) => {
    setActiveKey(key);
  }, []);

  // 激活指定标签页
  const goToTab = useCallback((key: any, params: any) => {
    setActiveKey(key);
    if (key === "2") {
      setPayableDetailsSearchValue({ ...params });
    } else if (key === "3") {
      setDepositDetails({ ...params });
    }
  }, [setActiveKey]);

  return (
    <>
      <PageHeader className="page-header" title="款项明细">
        <NotificationTips />
      </PageHeader>
      <div className="payment-details">
        <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onTabChange}>
          <TabPane tab="总览" key="1">
            <PaymentDetailsOverview goToTab={goToTab} />
          </TabPane>
          <TabPane tab="应付货款明细" key="2">
            <PayableDetails defaultSearchValue={payableDetailsSearchValue} />
          </TabPane>
          <TabPane tab="保证金明细" key="3">
            <DepositDetails defaultSearchValue={depositDetailsSearchValue} goToTab={goToTab} />
          </TabPane>
        </Tabs>
      </div>
    </>
  )
};

export default PaymentDetails;