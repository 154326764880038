// slot-booking 查询条件
import { useState, useEffect, forwardRef, useCallback, useImperativeHandle } from "react";
import { Form, Select, message, Spin, Row, Col } from "antd";
import "./index.scss";

import { allSupplier } from "@/utils/constant";
import { getProductQueryApi } from "@/api/slotbooking";

// 定义函数组件的props相关参数
type myProps = {
  get_message_filters?: () => void;
  switcherData?: any;
  userDetailsData?: any;
};

const MessageFilters = forwardRef((props: myProps, ref: any) => {
  const [form] = Form.useForm(); // form表单实例
  const [platformDataOptions, setPlatformDataOptions] = useState([]); // platform可选列表
  const [isActionLoading, setIsActionLoading] = useState<boolean>(false);
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [specGroupOptions, setSpecGroupOptions] = useState<any[]>([]);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<any[]>([]);

  useImperativeHandle(ref, () => ({
    getFormFields
  }));

  const getFormFields = useCallback(() => {
    return { ...form.getFieldsValue(true) };
  }, [form]);

  // 获取平台可选数据
  const getPlatformsDataOptions = (siteNameVal: string) => {
    const getParamsObj = {
      vague: "",
      orgCode: siteNameVal,
      queryType: "platforms"
    };
    setIsActionLoading(true);
    getProductQueryApi(getParamsObj)
      .then((responseJSON: any) => {
        if (responseJSON?.data?.platforms) {
          const platformsData = responseJSON.data.platforms;
          const NewData = platformsData.map((item: any) => ({
            label: item.name,
            value: item.name,
            loading_rule: item.loadingRule
          }));
          setPlatformDataOptions(NewData);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
      })
      .finally(() => {
        setIsActionLoading(false);
      });
  };

  // 获取车型可选数据
  const getVehiclesDataOptions = (inputValue: any) => {
    setIsActionLoading(true);
    const paramsObj = {
      vague: "",
      orgCode: form.getFieldValue("siteCode"),
      platform: inputValue,
      queryType: "vehicles"
    };

    getProductQueryApi(paramsObj)
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
          setVehicleTypeOptions([]);
        } else if (responseJSON?.data) {
          const vehiclesData = responseJSON.data.vehicles || [];
          const newData = vehiclesData.map((item: any) => {
            return { label: item.name, value: item.name };
          });
          setVehicleTypeOptions(newData);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        setVehicleTypeOptions([]);
      })
      .finally(() => {
        setIsActionLoading(false);
      });
  };

  // 获取Category段的可选数据
  const getCategorys = (inputValue: any) => {
    setIsActionLoading(true);
    const paramsObj = {
      vague: inputValue,
      orgCode: form.getFieldValue("siteCode"),
      platform: form.getFieldValue("platform"),
      queryType: "categorys"
    };
    getProductQueryApi(paramsObj)
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
          setCategoryOptions([]);
        } else if (responseJSON?.data) {
          const categorysData = responseJSON.data.categorys || [];
          const newData = categorysData.map((item: any) => {
            return { label: item.name, value: item.name, efficiency: item.efficiency };
          });
          setCategoryOptions(newData);
          if (["1959", "1254"].includes(form.getFieldValue("siteCode")) && form.getFieldValue("platform") === "粕类发货平台") {
            getVehiclesDataOptions(form.getFieldValue("platform"));
          }
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        setCategoryOptions([]);
      })
      .finally(() => {
        setIsActionLoading(false);
      });
  };

  // 获取规格
  const getSpecGroups = (inputValue: any) => {
    setIsActionLoading(true);
    const paramsObj = {
      vague: inputValue,
      orgCode: form.getFieldValue("siteCode"),
      platform: form.getFieldValue("platform"),
      queryType: "specGroups"
    };

    getProductQueryApi(paramsObj)
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
          setSpecGroupOptions([]);
        } else if (responseJSON?.data) {
          const specGroupsData = responseJSON.data.specGroups || [];
          const newData = specGroupsData.map((item: any) => {
            return { label: item.name, value: item.name };
          });
          setSpecGroupOptions(newData);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        setSpecGroupOptions([]);
      })
      .finally(() => {
        setIsActionLoading(false);
      });
  };

  //处理卖方工厂 siteCode 的切换,确定 siteName的值
  const handleSiteNameChange = useCallback(
    (value: any) => {
      form.setFieldsValue({
        siteCode: value,
        platform: null,
        categoryName: null,
        specGroup: null,
        vehicleType: null
      });
      // 如果siteName有值则重新获取对应的platform的数据
      if (value) {
        getPlatformsDataOptions(value);
      }

      // 通知父级组件可以获取更新后的信息
      notify_current_change_data();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  );

  //处理平台 platform 的切换: 确定vehicles的值
  const handlePlatformTovehiclesChange = useCallback((value: string) => {
    // 通知父级组件可以获取更新后的信息
    notify_current_change_data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //处理平台 platform 的切换: 确定platform 和 loadingRule的值
  const handlePlatformChange = useCallback(
    (value: any) => {
      if (value) {
        form.setFieldsValue({
          platform: value,
          categoryName: null,
          specGroup: null,
          vehicleType: null
        });
        getCategorys(value);
      } else {
        form.setFieldsValue({
          platform: null,
          categoryName: null,
          specGroup: null,
          vehicleType: null
        });
      }

      // 通知父级组件可以获取更新后的信息
      notify_current_change_data();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, getCategorys]
  );

  // 处理 categoryName 的切换 确定categoryName的值
  const onHandleChange = (field: string) => (selectionObj: any, currentOption: any) => {
    if (field === "categoryName") {
      if (selectionObj) {
        form.setFieldsValue({
          categoryName: selectionObj,
          specGroup: null
        });
        getSpecGroups(selectionObj);
      }
    }
    // 通知父级组件可以获取更新后的信息
    notify_current_change_data();
  };

  const notify_current_change_data = () => {
    if (props.get_message_filters) {
      props.get_message_filters();
    }
  };

  useEffect(() => {
    handleSiteNameChange(props.switcherData?.plant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.switcherData.plant]);

  return (
    <div className="message-filters">
      <Spin spinning={isActionLoading}>
        <div className="form-container">
          <Form
            form={form}
            name="reservation_filters_form"
            layout="vertical"
            labelAlign="left"
            initialValues={{
              siteCode: "",
              platform: null
            }}
          >
            <div className="form-title">通知发送条件</div>
            <Row gutter={80} justify="start">
              <Col md={6} xs={24}>
                <Form.Item
                  label={
                    <>
                      <span className="required-mark">*</span>工厂
                    </>
                  }
                  name="siteCode"
                >
                  <Select
                    data-testid="test-siteName-select"
                    placeholder="请选择工厂"
                    options={allSupplier(["All"])}
                    onChange={handleSiteNameChange}
                  />
                </Form.Item>
              </Col>

              {form.getFieldValue("siteCode") && (
                <Col md={6} xs={24}>
                  <Form.Item label="发货平台" name="platform" rules={[{ required: true, message: "请选择发货平台!" }]}>
                    <Select
                      data-testid="test-platform-select"
                      placeholder="请选择发货平台"
                      options={platformDataOptions}
                      onChange={handlePlatformChange}
                      disabled={!form.getFieldValue("siteCode")}
                    />
                  </Form.Item>
                </Col>
              )}

              {form.getFieldValue("siteCode") && (
                <Col md={6} xs={24}>
                  <Form.Item
                    shouldUpdate
                    label="选择产品类型"
                    name="categoryName"
                    rules={[{ required: true, message: "请选择产品类型" }]}
                  >
                    <Select
                      data-testid="test-category-select"
                      placeholder="请选择产品类型"
                      options={categoryOptions}
                      onChange={onHandleChange("categoryName")}
                      disabled={!form.getFieldValue("siteCode") || !form.getFieldValue("platform")}
                    ></Select>
                  </Form.Item>
                </Col>
              )}

              {form.getFieldValue("siteCode") && form.getFieldValue("platform") === "粕类发货平台" && (
                <Col md={6} xs={24}>
                  <Form.Item label="选择规格组" name="specGroup" rules={[{ required: true, message: "请选择规格组" }]}>
                    <Select
                      data-testid="test-specGroup-select"
                      placeholder="请选择规格组"
                      options={specGroupOptions}
                      onChange={onHandleChange("specGroup")}
                      disabled={
                        !form.getFieldValue("siteCode") ||
                        !form.getFieldValue("platform") ||
                        !form.getFieldValue("categoryName")
                      }
                    ></Select>
                  </Form.Item>
                </Col>
              )}

              {["1959", "1254"].includes(form.getFieldValue("siteCode")) && form.getFieldValue("platform") === "粕类发货平台" && (
                <Col md={6} xs={24}>
                  <Form.Item label="选择车型" name="vehicleType" rules={[{ required: true, message: "请选择车型" }]}>
                    <Select
                      data-testid="test-vehicles-select"
                      placeholder="请选择车型"
                      options={vehicleTypeOptions}
                      onChange={handlePlatformTovehiclesChange}
                      disabled={!form.getFieldValue("siteCode") || !form.getFieldValue("platform")}
                    ></Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        </div>
      </Spin>
    </div>
  );
});

export default MessageFilters;
