import { Component } from "react";
import { connect } from "react-redux";
import { Collapse, Card, PageHeader } from "antd";
import { AA_PageLoadedEvent } from "@/monitor/adobe_analytics";
import "./index.scss";
import ContractSearchFilter from "./contractSearchFilter";
import ContractList from "./contractList";
import ApplyForm from "./applyForm";
import DoCreatedList from "./doCreatedList";
import GenerateAnnouncement from "./generateAnnouncement";

const { Panel } = Collapse;

interface Props {
  permissions: any;
  userDetails: any;
  switcher: any;
}

interface State {
  doIdRow: any;
  doDataSource: any;
  searchValue: any;
  isSecondActive: boolean;
  isThirdActive: boolean;
}

class ConsignApply extends Component<Props, State> {
  public readonly state = {
    doIdRow: {} as any, // 已建委托函选中数据--编辑的项
    doDataSource: {} as any,
    isSecondActive: false, // 第二个collapse是否展开
    isThirdActive: false, // 第三个collapse是否展开
    searchValue: {} as any
  };
  //自定义RefGo操作
  // 第-步：合同列表操作
  private refGo_ContractList: any = {};
  private refGo_ContractList_bind = (refObj: any) => {
    this.refGo_ContractList = refObj;
  };
  // 第三步：已建提货委托函提交-自定义ref暴露的属性进行限定属性操作
  private refGo_DoCreatedList: any = {};
  private refGo_doCreatedList_bind = (refObj: any) => {
    this.refGo_DoCreatedList = refObj;
  };

  // 自定义ReGo操作
  private refGo_ContractSearchFilter_bind = (refObj: any) => {
    this.refGo_contractSearchFilter = refObj;
  };

  // 用于调用contractSearchFilter的方法
  private refGo_contractSearchFilter: any = {};

  componentDidMount() {
    document.title = "提货委托函申请";
    AA_PageLoadedEvent("提货委托函申请");
  }

  // 获取更新待执行合同列表数据
  updateContractList = (searchVal?: any, actionData?: any) => {
    if (searchVal) {
      // 查询点击获取待执行合同列表数据 -- 切换最新查询条件,待执行合同会自动监测查询条件变更，执行查询
      this.setState({ searchValue: searchVal });
    } else if (actionData && Object.keys(actionData).length > 0) {
      // 编辑、创建Do单后，根据DO单的合同号是否在合同列表中来进行判断是否更新列表
      this.refGo_ContractList.updateContractList(actionData);
    } else {
      // 其他操作主动要求更新列表数据 -- 使用原查询条件
      this.refGo_ContractList.updateContractList();
    }
  };

  // 待执行合同选择后赋值给form表单数据源
  // 多物料切换等于切换了合同操作
  // 如果当前有正在编辑则直接清空编辑的数据
  doSetContractCheckedRow = (contractRow: any) => {
    this.setState({ doIdRow: {}, doDataSource: contractRow });
    if (contractRow && Object.keys(contractRow).length > 0) {
      this.setState({ isSecondActive: true });
    }
  };

  // 更新已建提货委托函列表
  onDoUpdateCreatedListDataFun = (actionType: string = "", newDoId?: string | number) => {
    this.setThirdActive(true);
    this.refGo_DoCreatedList?.getDoTableList(actionType, newDoId);
  };

  // 获取删除后的数据 -- form编辑和新增表单
  doDoActionCbForEditCheck = (actionDataArr: any[], actionType: string) => {
    const { doDataSource } = this.state;
    // 如果删除或者提交的是正在编辑的DO数据，则需要清空表单
    if (doDataSource && Object.keys(doDataSource).length > 0) {
      let exitItem: any = [];
      if (actionType === "delete") {
        exitItem = actionDataArr?.filter((item: any) => item.contractNo === doDataSource.contractNo);
        if (exitItem.length > 0) {
          this.setState({ doIdRow: {}, doDataSource: {} });
        }
      } else {
        this.setState({ doIdRow: {}, doDataSource: {} });
      }
    }
  };

  // 平台序列号点击获取行信息
  doSetDoIdRow = (row: any) => {
    const { doIdRow } = this.state;
    if (doIdRow?.doId && doIdRow?.doId === row.doId) {
      // 同一条点击需要重新赋值
      this.setState({ doIdRow: {}, doDataSource: {} }, () => {
        this.setState({
          doIdRow: row,
          doDataSource: row,
          isSecondActive: true
        });
      });
    } else {
      this.setState({
        doIdRow: row,
        doDataSource: row,
        isSecondActive: true
      });
    }
  };

  // 切换第二步展开状
  updateIsSecondActive = () => {
    const { isSecondActive } = this.state;
    this.setState({ isSecondActive: !isSecondActive });
  };

  // 切换第三步展开状态
  updateIsThirdActive = () => {
    const { isThirdActive } = this.state;
    this.setState({ isThirdActive: !isThirdActive });
  };

  setThirdActive = (flag: boolean) => {
    this.setState({ isThirdActive: flag });
  };

  public render() {
    const { permissions, userDetails, switcher } = this.props;
    const { isSecondActive, isThirdActive, doDataSource, searchValue } = this.state;

    return (
      <div className="consign-apply">
        <PageHeader className="page-header" title="提货委托函申请" />
        <div className="consign-apply-content">
          {permissions.CNExternalUsers && (
            <GenerateAnnouncement permissions={permissions} userDetails={userDetails} switcherDetails={switcher} />
          )}
          {/* 选择合同 */}
          <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
            <Panel header={<span data-testid="first-step">第一步：选择合同</span>} key="1">
              {/* 查询条件 */}
              <Card
                style={{ boxShadow: "0 5px 9px #dedbdb" }}
                bodyStyle={{ paddingTop: 0 }}
                title="查询条件"
                headStyle={{
                  backgroundColor: "white",
                  fontWeight: 100,
                  borderBottom: "none"
                }}
              >
                <ContractSearchFilter refGo={this.refGo_ContractSearchFilter_bind} doSearch={this.updateContractList} />
              </Card>
              {/* 第-步：待执行合同 */}
              <ContractList
                refGo={this.refGo_ContractList_bind}
                searchParams={searchValue}
                doDataSource={doDataSource}
                setContractCheckedRow={this.doSetContractCheckedRow}
                resetAdvanceSearch={this.refGo_contractSearchFilter.resetAdvanceSearch}
              />
            </Panel>
          </Collapse>

          {/* 第二步：提交委托函申请 */}
          <Collapse
            style={{ marginTop: 20 }}
            activeKey={isSecondActive ? "2" : "0"}
            onChange={this.updateIsSecondActive}
            expandIconPosition="right"
          >
            <Panel header={<span data-testid="second">第二步：提货委托函申请</span>} key="2">
              <ApplyForm
                doDataSource={doDataSource}
                updateContractListData={this.updateContractList}
                updateDoCreatedListData={this.onDoUpdateCreatedListDataFun}
              />
            </Panel>
          </Collapse>

          {/* 第三步：已建提货委托函提交 */}
          <Collapse
            style={{ marginTop: 20 }}
            activeKey={isThirdActive ? "3" : "0"}
            onChange={this.updateIsThirdActive}
            expandIconPosition="right"
          >
            <Panel header={<span data-testid="third">第三步：已建提货委托函提交</span>} key="3">
              <DoCreatedList
                refGo={this.refGo_doCreatedList_bind}
                updateContractListData={this.updateContractList}
                setDoIdRow={this.doSetDoIdRow}
                doActionCbForEditCheck={this.doDoActionCbForEditCheck}
              />
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  userDetails: state.userDetails,
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(ConsignApply);
