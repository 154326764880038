// invoice 查询条件
import { useState, useEffect, useCallback } from "react";
import { Card, Form, Input, Select, Button, Collapse, Divider, DatePicker, message } from "antd";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { formatStrTrim } from "@/utils";
import { allSupplierCN, allInvoiceBusinessOptions, supplierList } from "@/utils/constant";
import { getFirmDetailListApi } from "@/api/slotbooking";
import { getConditionsForQueryListApi } from "@/api/availableBalance";
import "./index.scss";

import RemoteAutoComplete from "@/pages/common/remoteAutoComplete";

const { Panel } = Collapse;

// 定义函数组件的props相关参数
type myProps = {
  doSearch?: (arg0: any) => void;
  excludeFields?: string[];
  userPermissionsData: any;
  userDetailsData: any;
  switcherData: any;
  buyers: any;
};

const SearchFilters = (props: myProps) => {
  const { switcherData, userPermissionsData, buyers } = props;
  const [form] = Form.useForm();
  const [isShowAdvanceFilter, setIsShowAdvanceFilter] = useState(false);
  const [isShowAdvanceFilterText, setIsShowAdvanceFilterText] = useState("展开高级筛选");
  const [hasAttachmentOptions] = useState([
    { value: "Y", label: "有" },
    { value: "N", label: "无" }
  ]);
  const [hasB2bTagOptions] = useState([
    { value: "Y", label: "是" },
    { value: "N", label: "否" }
  ]);
  const [invoiceStatusOptions, setInvoiceStatusOptions] = useState([]);
  const [invoiceTypeOptions, setInvoiceTypeOptions] = useState([]);
  const [isSelectFetching, setIsSelectFetching] = useState(false);
  const [currentCollapseActiveKey, setCurrentCollapseActiveKey] = useState("");
  const [endTimeDisabled, setEndTimeDisabled] = useState(null);

  // 处理切换高级选项的状态
  const changeAdvanceFilterStatus = () => {
    if (currentCollapseActiveKey) {
      setIsShowAdvanceFilter(false);
      setIsShowAdvanceFilterText("展开高级筛选");
      setCurrentCollapseActiveKey("");
    } else {
      setIsShowAdvanceFilter(true);
      setIsShowAdvanceFilterText("收起高级筛选");
      setCurrentCollapseActiveKey("search-filters-01");
    }
  };

  // 清空重置buyer或productName的数据
  const resetBuyerValue = useCallback(() => {
    if (props.userPermissionsData["CNInternalUsers"]) {
      form.setFieldsValue({ buyerName: null });
    }
  }, [form, props.userPermissionsData]);

  //处理卖方工厂的切换
  const handleSupplierChange = useCallback(
    (value: any) => {
      // sellerName的value更新，重新请求可选平台的数据
      resetBuyerValue();
    },
    [resetBuyerValue]
  );

  // 根据 orgCode 获取 对应的 sellName value
  const filterOrgNameCN = (orgCodeValue: string) => {
    if (orgCodeValue === "All" || orgCodeValue === "全部卖方") {
      return "All";
    }
    const dataSource = supplierList;
    const filterOrgData = dataSource.filter(item => item.value === orgCodeValue);
    if (filterOrgData.length > 0) return filterOrgData[0].label;
    return "";
  };

  // 根据卖方名称获取 orgCode
  const filterOrgCode = (sellerName: string) => {
    if (sellerName === "All" || sellerName === "全部卖方") {
      return "All";
    }
    const dataSource = supplierList;
    const filterOrgData = dataSource.filter(item => item.label === sellerName);
    if (filterOrgData.length > 0) return filterOrgData[0].value;
    return "";
  };

  const isDisabledAction = (key: string) => {
    if (props.userPermissionsData["CNExternalUsers"]) {
      if (key === "businessLine" || key === "buyer") {
        return false;
      }
      return props.switcherData[key];
    } else if (props.userPermissionsData["CNInternalUsers"]) {
      return false;
    }
    return true;
  };

  const handleBeginDateChange = useCallback(() => {
    const beginTimeVal = form.getFieldValue("beginTime") || "";
    if (beginTimeVal) {
      // 设置结束日期的可选开始时间
      setEndTimeDisabled(beginTimeVal);
      // 如果已经选择的结束时间在可选时间范围外，则需要重置结束时间来重新选择
      if (form.getFieldValue("endTime") && form.getFieldValue("endTime") < form.getFieldValue("beginTime")) {
        form.setFieldsValue({ endTime: null });
      }
    } else {
      setEndTimeDisabled(null);
    }
  }, [form]);

  const handleMySubmit = useCallback(
    (formValues?: any) => {
      const values = { ...form.getFieldsValue(true) };
      // 将条件所有值传递给父组件
      if (props.doSearch) {
        values.beginTime = values.beginTime ? values["beginTime"].format("YYYY-MM-DD") : "";
        values.endTime = values.endTime ? values["endTime"].format("YYYY-MM-DD") : "";

        // 此模块只传中文，去掉code
        if (values.buyerName) {
          values.buyerName = formatStrTrim(values.buyerName.split("(")[0]);
        }

        // 传递筛选的最终结果给到父级组件
        props.doSearch(values);
      }
    },
    [form, props]
  );

  const onResetForm = useCallback(() => {
    form.resetFields();
    if (props.userPermissionsData["CNInternalUsers"]) {
      form.setFieldsValue({ salesOrgNo: null });
    }
    // 更新与sellerName相关数据
    handleSupplierChange(filterOrgNameCN(props.switcherData.orgCode));
    // 自动重新获取数据
    handleMySubmit();
  }, [form, handleMySubmit, handleSupplierChange, props.switcherData.orgCode, props.userPermissionsData]);

  const onFilterOption = useCallback((inputValue: any, option: any) => {
    return ((option?.label as unknown) as string).toLowerCase().includes(inputValue?.toLowerCase());
  }, []);

  const onFilterEndTime = useCallback(
    (current: moment.Moment) => {
      return current && current < moment(endTimeDisabled);
    },
    [endTimeDisabled]
  );

  // 内部用户--获取买方选项(防抖输入查询)
  const fetchCustomerOptions_Internal = useCallback(
    (value: string | undefined, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      getFirmDetailListApi({
        orgCode: filterOrgCode(form.getFieldValue("sellerName")) || "",
        customerName: value || ""
      })
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            callback([]);
            message.error(res.errorMsg);
          } else if (res.data?.customerList?.length > 0 && currentValue === value) {
            let data: any[] = res.data?.customerList?.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName + "(" + val.customerCode + ")",
                key: val.customerCode
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    [form]
  );

  useEffect(() => {
    const getSearchOptions = () => {
      getConditionsForQueryListApi()
        .then((responseJSON: any) => {
          // 设置发票状态Options
          if (responseJSON.data?.invoiceStatus?.length > 0) {
            const NewData = responseJSON.data.invoiceStatus.map((item: any) => ({
              label: item,
              value: item
            }));
            setInvoiceStatusOptions(NewData);
          } else {
            setInvoiceStatusOptions([]);
          }
          // 设置发票类型Options
          if (responseJSON.data?.invoiceType?.length > 0) {
            const NewData = responseJSON.data.invoiceType.map((item: any) => ({
              label: item.name,
              value: item.code
            }));
            setInvoiceTypeOptions(NewData);
          } else {
            setInvoiceTypeOptions([]);
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          setInvoiceStatusOptions([]);
          setInvoiceTypeOptions([]);
        });
    };

    getSearchOptions();
  }, []);

  // 如果switcher有设置值，进行初始化相关数据
  // 只要switcher中的卖方 orgCode 的值发生变化需要处理的其他相关项
  useEffect(() => {
    resetBuyerValue();
  }, [form, props.userPermissionsData, props.switcherData.orgCode, resetBuyerValue]);

  // 只要switcher中的buyer的值发生变化需要处理的其他相关项
  useEffect(() => {
    if (switcherData) {
      if (userPermissionsData.CNInternalUsers) {
        const sellerName = filterOrgNameCN(switcherData.orgCode); // 卖方
        form.setFieldsValue({
          // 业务线不与switcher关联
          sellerName: sellerName
        });
      } else if (userPermissionsData.CNExternalUsers) {
        // 外部用户
        const sellerName = filterOrgNameCN(switcherData.orgCode); // 卖方
        let buyerName = null;
        if (!props.userDetailsData?.isGroup) {
          buyerName = switcherData.customerName + "(" + switcherData.customerCode + ")";
        }
        form.setFieldsValue({
          sellerName: sellerName,
          buyerName: buyerName
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, props.switcherData.customerName, props.switcherData.customerCode, props.userPermissionsData]);

  // 只要switcher中设置的值发生改变则需要重新赋初始值
  useEffect(() => {
    if (filterOrgNameCN(props.switcherData.orgCode) !== form.getFieldValue("sellerName")) {
      form.setFieldsValue({
        sellerName: filterOrgNameCN(props.switcherData.orgCode) || ""
      });
    }
  }, [form, props.switcherData]);

  // 只要form的值或者switcher中设置的值发生改变则需要重新通知父级组件重新获取新值
  useEffect(() => {
    handleMySubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, props.switcherData.orgCode, props.switcherData.customerCode]);

  return (
    <div className="invoice-page-search search-filters">
      <Card
        style={{ boxShadow: "0 5px 9px #dedbdb" }}
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        title="查询条件"
        headStyle={{
          backgroundColor: "white",
          borderBottom: "none"
        }}
      >
        <Form
          form={form}
          name="search_filter_form"
          layout="vertical"
          onFinish={handleMySubmit}
          initialValues={{
            sellerName: "All",
            businessLine: "",
            buyerName: null,
            contractNo: "",
            plateNo: "",
            beginTime: "",
            endTime: "",
            hasAttachment: null,
            invoiceStatus: null,
            invoiceType: null,
            b2bTag: null
          }}
        >
          {/* 基础筛选条件 */}
          <div className="hdp-uf hdp-uf-dc" style={{ width: "100%" }}>
            <div className="hdp-uf hdp-uf-ww">
              <Form.Item name="sellerName" className="form-item">
                <Select
                  data-testid="test-sellerName-select"
                  placeholder="请选择卖方工厂"
                  disabled={isDisabledAction("plant")}
                  options={allSupplierCN()}
                  onChange={handleSupplierChange}
                  style={{ width: 260 }}
                />
              </Form.Item>
              {props.userPermissionsData["CNInternalUsers"] && (
                <Form.Item name="businessLine" className="form-item">
                  <Select
                    data-testid="test-businessLine-select"
                    placeholder="请选择发票业务"
                    disabled={isDisabledAction("businessLine")}
                    options={allInvoiceBusinessOptions}
                    style={{ width: 200 }}
                  />
                </Form.Item>
              )}

              {props.userPermissionsData["CNInternalUsers"] && (
                <Form.Item name="buyerName" className="form-item">
                  <RemoteAutoComplete
                    dataTestId="test-internalUser-buyerName-select"
                    placeholder="请输入公司名称"
                    fetchOptions={fetchCustomerOptions_Internal}
                    loading={isSelectFetching}
                    style={{ minWidth: 245 }}
                  />
                </Form.Item>
              )}

              {props.userPermissionsData["CNExternalUsers"] && !props.userDetailsData?.isGroup && (
                <Form.Item name="buyerName" className="form-item">
                  <Input disabled style={{ minWidth: 245 }} />
                </Form.Item>
              )}

              {/* 外部用户且为集团账户的买方输入框,当前不用判断是否为饲料厂用户 */}
              {props.userPermissionsData["CNExternalUsers"] && props.userDetailsData?.isGroup && (
                <Form.Item shouldUpdate>
                  {() => {
                    return (
                      <Form.Item name="buyerName" className="form-item">
                        <Select
                          showSearch
                          placeholder="请选择公司名称"
                          disabled={isDisabledAction("buyer")}
                          options={buyers}
                          optionFilterProp="label"
                          filterOption={onFilterOption}
                          style={{ minWidth: 245 }}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              )}

              <Form.Item name="contractNo" className="form-item">
                <Input allowClear placeholder="请输入合同编号" />
              </Form.Item>

              <Form.Item shouldUpdate className="form-item">
                {() => (
                  <Button
                    data-testid="test-search-basic-filters"
                    type="primary"
                    htmlType="submit"
                    icon={<SearchOutlined />}
                    disabled={isShowAdvanceFilter}
                  >
                    查询
                  </Button>
                )}
              </Form.Item>
            </div>
            {/* 点击查看高级筛选 */}
            <div className="advance-filters">
              <Collapse ghost collapsible="header" activeKey={currentCollapseActiveKey} expandIconPosition="right">
                <Panel
                  header=""
                  extra={
                    <div
                      data-testid="test-open-advance-filters"
                      className="collapse-active-btn"
                      onClick={changeAdvanceFilterStatus}
                    >
                      {isShowAdvanceFilterText}
                    </div>
                  }
                  key="search-filters-01"
                  className="advance-filters-container"
                >
                  {/* 高级筛选条件 */}
                  <Divider dashed={true} style={{ width: "100%", borderColor: "#eee", margin: "-12px 0 12px 0" }} />
                  <div className="hdp-uf hdp-uf-dc">
                    <div className="hdp-uf hdp-uf-ww">
                      <Form.Item name="plateNo" label="发票号码" className="form-item">
                        <Input allowClear placeholder="请输入发票号码" />
                      </Form.Item>
                      <Form.Item name="beginTime" label="发票起始日期" className="form-item">
                        <DatePicker
                          data-testid="test-beginTime"
                          placeholder="选择开票开始日期"
                          style={{ width: 140 }}
                          onChange={handleBeginDateChange}
                        />
                      </Form.Item>
                      <Form.Item name="endTime" label="发票截止日期" className="form-item">
                        <DatePicker
                          data-testid="test-endTime"
                          placeholder="选择开票截止日期"
                          disabledDate={onFilterEndTime}
                          style={{ width: 140 }}
                        />
                      </Form.Item>
                      <Form.Item name="hasAttachment" label="有无附件" className="form-item">
                        <Select
                          allowClear
                          data-testid="test-hasAttachment-select"
                          placeholder="请选择"
                          options={hasAttachmentOptions}
                          style={{ width: 150 }}
                        />
                      </Form.Item>
                      <Form.Item name="invoiceStatus" label="发票状态" className="form-item">
                        <Select
                          allowClear
                          data-testid="test-invoiceStatus-select"
                          placeholder="请选择发票状态"
                          options={invoiceStatusOptions}
                          style={{ width: 180 }}
                        />
                      </Form.Item>
                      <Form.Item name="invoiceType" label="发票类型" className="form-item">
                        <Select
                          allowClear
                          data-testid="test-invoiceType-select"
                          placeholder="请选择发票类型"
                          options={invoiceTypeOptions}
                          style={{ width: 180 }}
                        />
                      </Form.Item>
                      <Form.Item name="b2bTag" label="是否为直连合同" className="form-item">
                        <Select
                          allowClear
                          data-testid="test-b2bTag-select"
                          placeholder="请选择"
                          options={hasB2bTagOptions}
                          style={{ width: 180 }}
                        />
                      </Form.Item>
                    </div>
                    <div className="hdp-uf hdp-uf-hfe advance-filter-actions">
                      <Form.Item shouldUpdate className="form-item">
                        <Button
                          data-testid="test-search-advance-filters-reset"
                          className="form-btn-reset"
                          disabled={!isShowAdvanceFilter}
                          onClick={onResetForm}
                        >
                          清空
                        </Button>
                      </Form.Item>
                      <Form.Item shouldUpdate className="form-item">
                        {() => (
                          <Button
                            data-testid="test-search-advance-filters"
                            type="primary"
                            htmlType="submit"
                            disabled={!isShowAdvanceFilter}
                          >
                            立即查询
                          </Button>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
        </Form>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userPermissionsData: state.userPermissions,
  switcherData: state.switcher,
  userDetailsData: state.userDetails
});

export default connect(mapStateToProps, null)(SearchFilters);
