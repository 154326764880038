import RequestService from "@/api/request";
import axios from "axios";

const getContractConfirmationList = (dataObj: object) =>
  RequestService({
    url: "contractsap/listDscTwContract",
    method: "get",
    params: dataObj
  });

const getPointPriceConfirmationList = (dataObj: object) =>
  RequestService({
    url: "contractsap/listPointPrice",
    method: "get",
    params: dataObj
  });

const getSettlementList = (dataObj: object) =>
  RequestService({
    url: "arsap/listSettlement",
    method: "get",
    params: dataObj
  });

const getDispatchList = (dataObj: object) =>
  RequestService({
    url: "arsap/listDispatch",
    method: "get",
    params: dataObj
  });

const entryPricing = (dataObj: object, param: any) =>
  RequestService({
    url: "contractsap/entryPricing",
    method: "post",
    data: dataObj,
    params: param
  });

const updateOriginContractNo = (dataObj: object, param: any) =>
RequestService({
  url: "contractsap/ws/updateOriginContractNo",
  method: "patch",
  data: dataObj,
  params: param
});

const terminateContract = (dataObj: object, param: any) =>
RequestService({
  url: "contractsap/ws/terminateContract",
  method: "patch",
  data: dataObj,
  params: param
});

const terminateZDContract = (dataObj: object, param: any) =>
RequestService({
  url: "contractsap/cp/terminateContract",
  method: "patch",
  data: dataObj,
  params: param
});

const confirmTerminateContract = (dataObj: object, param: any) =>
RequestService({
  url: "contractsap/confirmTerminateContract",
  method: "patch",
  data: dataObj,
  params: param
});
const resendContract = ( param: any) =>
RequestService({
  url: "contractsap/ws/resendContract",
  method: "get",
  params: param
});

const rejectPointPrice = (dataObj: object, param: any) =>
RequestService({
  url: "contractsap/rejectPointPrice",
  method: "patch",
  data: dataObj,
  params: param
});
const rejectContract = (dataObj: object, param: any) =>
RequestService({
  url: "contractsap/rejectContract",
  method: "patch",
  data: dataObj,
  params: param
});

const forceInvoice = (dataObj: object, param: any) =>
RequestService({
  url: "arsap/forceInvoice",
  method: "patch",
  data: dataObj,
  params: param
});

const getSettlementDetailById = (dataObj: object) =>
  RequestService({
    url: "arsap/listSettlementInfoDetail",
    method: "get",
    params: dataObj
  });

const rejectSettlement = (dataObj: object, param: any) =>
  RequestService({
    url: "arsap/rejectSettlement",
    method: "patch",
    data: dataObj,
    params: param
  });

const approveSettlement = (dataObj: object, param: any) =>
  RequestService({
    url: "arsap/approveSettlement",
    method: "patch",
    data: dataObj,
    params: param
  });

const productQuery = (dataObj: object) =>
RequestService({
  url: "slotbookingsap/sap/productQuery",
  method: "get",
  params: dataObj,
  cancelToken: axios.CancelToken.source().token
});

const downloadPdf = (param: any) => {
  return RequestService({
    url: "arsap/downloadPdf",
    method: "post",
    params: param,
    data: {},
    responseType: "blob",
  });
}

const downloadLDC = (param: any) => {
  return RequestService({
    url: "arsap/downLdcTemplateData",
    method: "post",
    params: param,
    data: {},
    responseType: "blob",
  });
}

const vagueListCustomer = (dataObj: object) =>
RequestService({
  url: "contractsap/sap/vagueListCustomer",
  method: "get",
  params: dataObj,
  cancelToken: axios.CancelToken.source().token
});

const getProductList = (dataObj: object) =>
RequestService({
  url: "contractsap/sap/products",
  method: "get",
  params: dataObj,
  cancelToken: axios.CancelToken.source().token
});

const deleteSett = (param: object) => 
RequestService({
  url: "arsap/deleteSett",
  method: "DELETE",
  params: param
});

export { getContractConfirmationList,
  getPointPriceConfirmationList,
  getSettlementList,
  getDispatchList,
  entryPricing,
  updateOriginContractNo,
  terminateContract,
  rejectPointPrice,
  forceInvoice,
  confirmTerminateContract,
  resendContract,
  rejectContract,
  getSettlementDetailById,
  rejectSettlement,
  approveSettlement,
  productQuery,
  downloadPdf,
  downloadLDC,
  vagueListCustomer,
  getProductList,
  terminateZDContract,
  deleteSett
};
