import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Select, Button, Collapse, Divider, Input, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { allBusinessLine, allSupplier, kaClientOptions, b2bTagOptions } from "@/utils/constant";
import { cutOutRightCodeStr } from "@/utils/common";
import { vagueListCustomer, getProductList } from "@/api/directSys";
import { uniqBy } from "lodash";
import RemoteSelect from "@/pages/common/remoteSelect";

const { Panel } = Collapse;

const SearchFilter = (props: any) => {
  const { switcher } = props;
  const [form] = Form.useForm();
  const [isClick, setIsClick] = useState(false);
  const [expandCollapseText, setExpandCollapseText] = useState("展开高级筛选");
  const [isSelectFetching, setIsSelectFetching] = useState(false);
  const [requestListValue] = useState({
    limit: 10,
    offset: 0,
    divisionCode: switcher.divisionCode,
    orgCode: switcher.orgCode,
    buyer: null,
    deliveryNo: null,
    crglContractNo: null,
    acceptStartTime: null,
    acceptEndTime: null,
    productName: null,
    kaClient: null
  });

  useEffect(() => {
    if (switcher) {
      const { orgCode, divisionCode } = switcher;
      let value = requestListValue;
      value.orgCode = orgCode || "";
      value.divisionCode = divisionCode || "";
      form.setFieldsValue({
        plant: orgCode || "",
        businessLine: divisionCode || ""
      });
      props.setFields(value);
    }
  }, [form, props, requestListValue, switcher]);

  // 查询客户公司
  const getCostumerOptions = useCallback(
    (value: string, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      vagueListCustomer({
        customerName: value || "",
        orgCode: form.getFieldValue("plant") || "",
        divisionCode: switcher.divisionCode
      })
        .then((res: any) => {
          if (res.data?.customerList?.length > 0 && currentValue === value) {
            const data = res.data.customerList.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch((err: any) => {
          callback([]);
          console.log(err);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    [form, switcher.divisionCode]
  );

  // 获取产品或者物料名称可选项
  const fetchProductOptions = useCallback(
    (value: string, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      getProductList({
        productName: value || "",
        salesOrgs: form.getFieldValue("plant") || ""
      })
        .then((res: any) => {
          if (res.data?.products?.length > 0 && currentValue === value) {
            let itemText = "";
            const data = res.data.products.map((val: any) => {
              itemText =
                val.productName +
                getCtsCode(val.productName, val.ctsCode) +
                "(" +
                val.productCode?.replace(/\b(0+)/gi, "") +
                ")";
              return {
                label: itemText,
                value: itemText
              };
            });
            //去重后再赋值
            let newSetData: any = [];
            newSetData = uniqBy(data, "value");
            callback(newSetData);
          } else {
            callback([]);
          }
        })
        .catch((err: any) => {
          console.log(err);
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    [form]
  );

  const getCtsCode = (productName: string, ctsCode: string) => {
    let index = productName.indexOf("-");
    if (index !== -1 || ctsCode === null) {
      return "";
    } else {
      return "-" + ctsCode;
    }
  };

  const handelExpandCollapse = useCallback(() => {
    setIsClick(!isClick);
    setExpandCollapseText(isClick ? "展开高级筛选" : "收起高级筛选");
  }, [isClick]);

  const handleReset = useCallback(() => {
    form.resetFields();
    form.setFieldsValue({
      plant: switcher.orgCode || "",
      businessLine: switcher.divisionCode || ""
    });
    props.setFields({
      limit: 10,
      offset: 0,
      orgCode: switcher.orgCode,
      divisionCode: switcher.divisionCode,
      buyer: "",
      deliveryNo: "",
      crglContractNo: "",
      // acceptStartTime: "",
      // acceptEndTime: "",
      productName: "",
      kaClient: null
    });
    props.setCurrent(1);
  }, [form, props, switcher.divisionCode, switcher.orgCode]);

  const onFinish = useCallback(
    (values: any) => {
      const temp = JSON.parse(JSON.stringify(requestListValue));
      temp.offset = 0;
      temp.limit = 10;
      temp.divisionCode = values.businessLine;
      temp.orgCode = values.plant;
      temp.deliveryNo = values.deliveryNo;
      temp.crglContractNo = values.crglContractNo;
      temp.acceptStartTime = values.createTime ? values.createTime[0].format("YYYY-MM-DDT00:00:00") : null;
      temp.acceptEndTime = values.createTime ? values.createTime[1].format("YYYY-MM-DDT23:59:59") : null;
      temp.productCode = values.productName ? cutOutRightCodeStr(values.productName) : null;
      temp.buyer = values.buyer || null; // 此功能特殊传中文名称
      temp.kaClient = values.kaClient || null;
      temp.directOrder = values.directOrder;
      props.setFields(temp);
      props.setCurrent(1);
    },
    [props, requestListValue]
  );

  return (
    <div className="search-filter">
      <div className="base-filter">
        <Form form={form} name="contract-confirmation-form" layout="vertical" autoComplete="off" onFinish={onFinish}>
          <Row justify="space-between">
            <Col md={5} xs={24}>
              <Form.Item name="plant">
                <Select placeholder="请选择卖方" options={allSupplier()} />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="buyer">
                <RemoteSelect
                  dataTestId="buyer-input"
                  placeholder="请输入公司名称查询并选择"
                  fetchOptions={getCostumerOptions}
                  loading={isSelectFetching}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="businessLine">
                <Select placeholder="请选择业务线" options={allBusinessLine()} />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="deliveryNo">
                <Input placeholder="请输入提货单号" />
              </Form.Item>
            </Col>
            <Col md={3} xs={24}>
              <Button type="primary" htmlType="submit" disabled={isClick}>
                <SearchOutlined />
                查询
              </Button>
            </Col>
          </Row>

          <Collapse
            style={{ marginTop: 0 }}
            ghost
            expandIconPosition="right"
            onChange={handelExpandCollapse}
            collapsible="header"
          >
            <Panel header={<span style={{ color: "#007ed9" }}>{expandCollapseText}</span>} key="1">
              <Divider dashed={true} style={{ width: "101%", margin: "-10px 0 13px -14px" }} />
              <div className="expand-collapse">
                <Row gutter={[16, 16]}>
                  {/* <Col md={4} xs={24}>
                    <Form.Item label="物料名称" name="materialName">
                      <RemoteSelect
                        dataTestId="materialName-input"
                        placeholder="请输入查询并选择"
                        fetchOptions={fetchProductOptions}
                        loading={isSelectFetching}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col> */}
                  {/* <Col md={4} xs={24}>
                    <Form.Item label="LDC接收起始日期" name="acceptStartTime">
                      <DatePicker style={{ width: "100%" }} placeholder="请选择" />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="LDC接收截止日期" name="acceptEndTime">
                      <DatePicker style={{ width: "100%" }} placeholder="请选择" />
                    </Form.Item>
                  </Col> */}
                  <Col md={4} xs={24}>
                    <Form.Item label="LDC创建时间" name="createTime">
                      <DatePicker.RangePicker
                        dropdownClassName="range-picker-drop-down-mobile"
                        style={{ width: "100%"}}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="合同号" name="crglContractNo">
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="产品名称" name="productName">
                      <RemoteSelect
                        dataTestId="productName-input"
                        placeholder="请输入查询并选择"
                        fetchOptions={fetchProductOptions}
                        loading={isSelectFetching}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="客户标识" name="kaClient">
                      <Select placeholder="请选择客户标识" options={kaClientOptions} />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="是否直连提单" name="directOrder">
                      <Select placeholder="请选择" options={b2bTagOptions} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col style={{ marginLeft: "auto" }} md={4} xs={24}>
                    <Form.Item>
                      <div style={{ marginTop: 30 }} className="align-right advance-btn">
                        <Button
                          style={{ marginRight: 10, backgroundColor: "#5c5c5c", width: "fit-content" }}
                          type="primary"
                          onClick={handleReset}
                          data-testid="reset-btn"
                        >
                          清空
                        </Button>
                        <Button type="primary" htmlType="submit" data-testid="submit-btn">
                          立即查询
                        </Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  userDetails: state.userDetails,
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(SearchFilter);
