import RequestService from "@/api/request";

// 获取待执行合同列表
const getCIDOSContracts = (dataObj: object) =>
  RequestService({
    url: "/contractsap/sap/cidosContracts?limit=200&offset=0",
    method: "get",
    params: dataObj
  });

// 待执行合同列表锁定解锁
const setLockUnlockContract = (dataObj: object) =>
  RequestService({
    url: "/contractsap/sap/lockUnlock",
    method: "post",
    data: dataObj
  });

// 已建提货委托函列表
const getDeliveryOrders = (dataObj: object) =>
  RequestService({
    url: "commodity-movementsap/deliveryOrders",
    method: "get",
    params: dataObj
  });

// 已预制提货委托函列表
const getSavedDeliveryOrders = (dataObj: object) =>
  RequestService({
    url: "commodity-movementsap/savedDeliveryOrders",
    method: "get",
    params: dataObj
  });

// 保存提货委托函申请
const createDo = (dataObj: object) =>
  RequestService({
    url: "/commodity-movementsap/createDo/new",
    method: "post",
    data: dataObj
  });

// 获取买方收货地址List
const getDestinationLocations = (dataObj: object) =>
  RequestService({
    url: "/contractsap/sap/shipToParty",
    method: "get",
    params: dataObj
  });

// 更新已建委托函
const updateDos = (dataObj: object, params: object) =>
  RequestService({
    url: "/commodity-movementsap/updateDos",
    method: "PATCH",
    data: dataObj,
    params: params
  });

// 排序
const sortDo = (dataObj: object) =>
  RequestService({
    url: "/commodity-movementsap/sortDo",
    method: "PATCH",
    data: dataObj,
  });

const getGenerateAnnouncementApi = (dataObj: object) =>
  RequestService({
    url: "/notificationsap/announcements",
    method: "get",
    params: dataObj
  });

export {
  getCIDOSContracts,
  setLockUnlockContract,
  getDeliveryOrders,
  getSavedDeliveryOrders,
  createDo,
  updateDos,
  sortDo,
  getGenerateAnnouncementApi,
  getDestinationLocations
};
