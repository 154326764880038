const FooterComponent = (props: any) => {
	const { REACT_APP_OKTA_SAMLURI, REACT_APP_OKTA_SAMLFROMURI } = process.env;
	return (
		<>
			<div>
				<a href={`${REACT_APP_OKTA_SAMLURI}?fromURI=${REACT_APP_OKTA_SAMLFROMURI ? encodeURIComponent(REACT_APP_OKTA_SAMLFROMURI) : REACT_APP_OKTA_SAMLFROMURI}`} className="link js-custom footer-btn" rel="noopener noreferrer">嘉吉员工请点击此处登录</a>
			</div>
		</>
	)
}

export default FooterComponent;