import { isHaveThisPermission } from "@/utils";
import { Button, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";

interface Props {
  data: any;
}

const RejectReason = (props: Props) => {
  const { data } = props;
  const [visible, setVisible] = useState(false);
  const [rejectReason, setRejectReason] = useState([]);
  const [rejectData, setRejectData] = useState("" as any);

  useEffect(() => {
    if (isHaveThisPermission("CNInternalUsers")) {
      setRejectData(data.rejectReason);
    } else {
      setRejectData(data.commonRejectReason);
    }
  }, [data]);

  const openRejectReasonModal = () => {
    const rejectReasonTextArr = rejectData ? rejectData.split("&&") : [];
    const newRejectReasonTextArr = rejectReasonTextArr
      .map((item: any, index: number) => ({
        num: index + 1,
        value: item
      }))
      .filter((item: any) => item.value && item.value.length > 0);
    setVisible(true);
    setRejectReason(newRejectReasonTextArr);
  };

  const onHandleVisibleClose = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <>
      {rejectData ? (
        <div data-testid="reject-btn" style={{ cursor: "pointer" }} onClick={openRejectReasonModal}>
          {rejectData.length > 20 ? `${rejectData.substring(0, 20)}...` : rejectData}
        </div>
      ) : (
        "-"
      )}
      <Modal
        title="拒绝原因"
        width={800}
        visible={visible}
        centered
        maskClosable={false}
        closable={false}
        footer={
          <Button data-testid="close-btn" onClick={onHandleVisibleClose} type="primary">
            关闭
          </Button>
        }
      >
        {rejectReason?.length > 0 &&
          rejectReason?.map((item: any) => (
            <div key={`slotbooking_tips_${item.num}`} className="hdp-uf list-item">
              <div>{`${item.num}. `}</div>
              <div className="list-item-content">{item.value}</div>
            </div>
          ))}
      </Modal>
    </>
  );
};

export default RejectReason;
