import { useState, useEffect } from "react";
import { Table, Tooltip, Button, message } from "antd";
import moment from "moment";
import { triggerErrorTask } from "@/api/userManagement";

const TableList = (props: any) => {
  const [actionLoading, setActionLoading] = useState(false);
  const [currentTableData, setCurrentTableData] = useState([]);

  useEffect(() => {
    setCurrentTableData(props.tableData.map(mapTableData));
  }, [props.tableData]);

  const cellStyle = () => {
    return {
      style: {
        maxWidth: 150,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        cursor: "pointer"
      }
    };
  };

  // 重新触发
  const onHandleTriggerErrorTask = (item: any) => (event?: any) => {
    setActionLoading(true);
    triggerErrorTask([{ id: item.id }])
      .then((res: any) => {
        if (res.result === true) {
          message.success("重新触发成功。");
          props.getListData();
        } else {
          message.error("重新触发失败。");
        }
      })
      .catch(err => {
        console.log(err);
        message.error("重新触发失败。");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const columns: any = [
    { title: "单号", dataIndex: "documentNo", key: "documentNo" },
    { title: "单号类型", dataIndex: "numberType", key: "numberType" },
    { title: "被调用方", dataIndex: "calledSystem", key: "calledSystem" },
    { title: "所属模块", dataIndex: "systemModule", key: "systemModule" },
    {
      title: "场景描述",
      onCell: cellStyle,
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.description}>
          {record.description}
        </Tooltip>
      )
    },
    { title: "API编号", dataIndex: "interfaceNo", key: "interfaceNo" },
    { title: "系统触发时间", dataIndex: "createTime", key: "createTime" },
    { title: "状态", dataIndex: "taskState", key: "taskState" },
    { title: "错误编码", dataIndex: "errorCode", key: "errorCode" },
    {
      title: "错误信息",
      onCell: cellStyle,
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.errorMsg}>
          {record.errorMsg}
        </Tooltip>
      )
    },
    {
      title: "请求链接",
      onCell: cellStyle,
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.requestUrl}>
          {record.requestUrl}
        </Tooltip>
      )
    },
    { title: "请求方式", dataIndex: "requestMethod", key: "requestMethod" },
    {
      title: "请求报文",
      onCell: cellStyle,
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.bodyStr}>
          {record.bodyStr}
        </Tooltip>
      )
    },
    { title: "请求结果状态", dataIndex: "requestState", key: "requestState" },
    {
      title: "请求结果描述",
      onCell: cellStyle,
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.requestDesc}>
          {record.requestDesc}
        </Tooltip>
      )
    },
    { title: "重试次数", dataIndex: "retryNumber", key: "retryNumber" },
    { title: "解决时间", dataIndex: "successTime", key: "successTime" },
    {
      title: "操作",
      key: "action",
      fixed: "right" as "right",
      render: (record: any) => (
        <span className="trigger-btn">
          <Button type="primary" disabled={props.requestState === "成功"} onClick={onHandleTriggerErrorTask(record)}>
            重新触发
          </Button>
        </span>
      )
    }
  ];

  const mapTableData = (data: any) => ({
    ...data,
    // 格式化展示需要的信息
    description: data.description || null,
    createTime: data.createTime ? moment(data.createTime).format("YYYY-MM-DD HH:mm:ss") : "-",
    errorMsg: data.errorMsg || null,
    requestUrl: data.requestUrl || null,
    bodyStr: data.bodyStr || null,
    requestDesc: data.requestDesc || null,
    successTime: data.successTime ? moment(data.successTime).format("YYYY-MM-DD HH:mm:ss") : "-"
  });

  return (
    <div className="contract-confirmation-table">
      <Table
        columns={columns}
        pagination={props.paginationOpt}
        scroll={{ x: "max-content" }}
        dataSource={currentTableData}
        rowKey="id"
        loading={props.loading || actionLoading}
        size="small"
      />
    </div>
  );
};

export default TableList;
