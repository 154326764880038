import RequestService from "@/api/request";

const doUserLogin = (dataObj: object = {}) => {
  return RequestService({
    url: "/user/login",
    method: "post",
    data: dataObj
  });
};

const getUserProfileApi = (emailId: string) => {
  return RequestService({
    url: `/contractsap/loginUser?email=${emailId}`,
    method: "get"
  });
};

const getUsePermissionsApi = () => {
  return RequestService({
    baseURL: process.env.REACT_APP_USER_SERVICES,
    url: "/apps/cn/permissions",
    method: "get"
  });
};

const userLogoutApi = (dataObj: object = {}) => {
  return RequestService({
    baseURL: process.env.REACT_APP_USER_SERVICES,
    url: "/users/sessions",
    method: "delete",
    data: dataObj
  });
};

const getUseEnumInfoApi = (paramsObj: object = {}) => {
  return RequestService({
    url: "/contractsap/dscconfig",
    method: "get",
    params: paramsObj
  });
};

// check weather user has accepted T&C or not
const getTermsConditionApi = (emailId: string) => {
  return RequestService({
    baseURL: process.env.REACT_APP_USER_SERVICES,
    url: `/users/${emailId}/termsandconditions`,
    method: "get"
  });
};

// Save user T&C acceptance
const doTermsConditionApi = (dataObj: object = {}) => {
  return RequestService({
    baseURL: process.env.REACT_APP_USER_SERVICES,
    url: "/users/termsandconditions",
    method: "post",
    data: dataObj
  });
};

const downloadUserManualApi = (paramsObj: object = {}, fileKey: string) =>
  RequestService({
    url: `/notificationsap/document/${fileKey}/url`,
    method: "get",
    params: paramsObj,
    headers: {
      idtoken: true
    }
  });

export {
  doUserLogin,
  getUserProfileApi,
  getUsePermissionsApi,
  userLogoutApi,
  getUseEnumInfoApi,
  getTermsConditionApi,
  doTermsConditionApi,
  downloadUserManualApi
};
