import { Component } from "react";
import { Spin, Button, PageHeader, message } from "antd";
import ReactQuill from "react-quill";
import { getNoticeInfo, saveNoticeInfo } from "@/api/userManagement";

import "react-quill/dist/quill.snow.css";
import "./index.scss";

interface State {
  noticeContent: string;
  isPageLoading: boolean;
}
class HomeNotice extends Component<{}, State> {
  public readonly state = {
    noticeContent: "",
    isPageLoading: false
  };

  componentDidMount() {
    document.title = "首页通知";
    this.getNoticeContent();
  }

  getNoticeContent = () => {
    this.setState({ isPageLoading: true });
    getNoticeInfo({})
      .then(res => {
        if (res.data) {
          let decode = atob(res.data.content);
          let str = decodeURI(decode);
          this.setState({ noticeContent: str });
        } else {
          this.setState({ noticeContent: "" });
        }
      })
      .catch(() => {
        message.error("获取公告失败，请稍后重试。");
        this.setState({ noticeContent: "" });
      })
      .finally(() => {
        this.setState({ isPageLoading: false });
      });
  };

  onFinish = () => {
    this.setState({ isPageLoading: true });
    const { noticeContent } = this.state;
    let encode = encodeURI(noticeContent);
    let base64 = btoa(encode);
    saveNoticeInfo({}, { content: base64 })
      .then(res => {
        if (res.data) {
          message.success("保存成功");
        }
      })
      .catch(() => {
        message.error("保存，请稍后重试。");
      })
      .finally(() => {
        this.setState({ isPageLoading: false });
      });
  };

  onSetNoticeContent = (content: string) => {
    this.setState({ noticeContent: content });
  };

  public render() {
    const { noticeContent, isPageLoading } = this.state;

    return (
      <div className="home-notice-page">
        <div className="hdp-uf hdp-uf-dc">
          <PageHeader className="page-header" title="首页通知" />
          <Spin spinning={isPageLoading}>
            <div className="notice-page-container">
              <ReactQuill theme="snow" className="ql-editor" value={noticeContent} onChange={this.onSetNoticeContent} />
              <div className="hdp-uf hdp-uf-hfe">
                <Button type="primary" data-testid="save-btn" onClick={this.onFinish} style={{ marginTop: 20 }}>
                  保存
                </Button>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}

export default HomeNotice;
