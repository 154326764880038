import { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Row, message } from "antd";
import { DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import FieldSetting from "@/pages/common/fieldSetting";
import DepositOverview from "./components/depositOverview";
import DepositDetailsSearchFilter from "./components/searchFilter";
import DepositDetailsTableList from "./components/tableList";
import { isMobileDevice, deviceWidth, downloadFile } from "@/utils";
import { getFirmDetailsList } from "@/api/constant";
import "./index.scss";
import { getDepositDetailsDataApi, saveDepositDetailsFieldsOrderApi, downDepositDetailsApi, downAccountSettlementApi, getDepositOverviewDataApi, getAccountSettlementDataApi, saveAccountSettlementFieldsOrderApi, getCustomerAccountItemTimeApi } from "@/api/availableBalance";
import { formatNumberToFixedThousands, formatReverseNumberToFixedThousands } from "@/utils/common";
import AccountSettlementModal from "./components/accountSettlementModal";

interface Props {
  switcher: any;
  permissions: any;
  userDetail: any;
  defaultSearchValue?: any
  goToTab?: Function
}

interface State {
  isDepositOverviewLoading: boolean;
  isDepositDetailsTableLoading: boolean;
  isAccountSettlementTableLoading: boolean;
  isDepositDetailsReportDownloadLoading: boolean;
  isAccountSettlementReportDownloadLoading: boolean;
  accountSettlementModalVisible: boolean;
  buyers: any;
  columnsData: object;
  depositDetailsTableData: object;
  accountSettlementTableData: object;
  depositDetailsFieldsDesc: any;
  depositDetailsAllFields: any;
  accountSettlementFieldsDesc: any;
  accountSettlementAllFields: any;
  depositDetailsPagination: object;
  accountSettlementPagination: object;
  depositOverviewData: object;
  depositDetailsTotalAmountDetailData: object;
  depositOverviewTimeStamp: any;
  depositDetailsTimeStamp: any;
  accountSettlementSearchResultRecordList: object;
  currentacccountSettlementSearchResultRecordIndex: any;
}

class DepositDetails extends Component<Props, State> {
  public readonly state = {
    isDepositOverviewLoading: false,
    isDepositDetailsTableLoading: false,
    isAccountSettlementTableLoading: false,
    isDepositDetailsReportDownloadLoading: false,
    isAccountSettlementReportDownloadLoading: false,
    accountSettlementModalVisible: false,
    buyers: [],
    columnsData: [],
    depositDetailsTableData: [],
    accountSettlementTableData: [],
    depositOverviewData: {},
    depositDetailsTotalAmountDetailData: {} as any,
    depositDetailsFieldsDesc: [],
    depositDetailsAllFields: [],
    accountSettlementFieldsDesc: [],
    accountSettlementAllFields: [],
    depositDetailsPagination: {
      current: 1,
      total: 0,
      showSizeChanger: true,
      showQuickJumper: true,
      size: deviceWidth() > 576 ? ("default" as any) : ("small" as any),
    },
    accountSettlementPagination: {
      current: 1,
      total: 0,
      showSizeChanger: true,
      showQuickJumper: true,
      size: deviceWidth() > 576 ? ("default" as any) : ("small" as any),
    },
    depositOverviewTimeStamp: "",
    depositDetailsTimeStamp: "",
    accountSettlementSearchResultRecordList: [],
    currentacccountSettlementSearchResultRecordIndex: 0
  };
  depositOverviewSearchFiltersData: any = {};
  depositDetailsSearchFiltersData: any = {};
  accountSettlementSearchFiltersData: any = {};
  componentDidMount() {
    document.title = "在线对账";
    const { userDetail } = this.props;
    const userDataCheck = userDetail && Object.keys(userDetail).length && userDetail.entitlements;
    if (userDataCheck) {
      const { entitlements } = userDetail;
      const {
        otherControls: { companyAccountType },
        controlIdentifier
      } = entitlements[0];
      if (companyAccountType === "GROUP") {
        getFirmDetailsList({ accountNumber: controlIdentifier })
          .then((res: any) => {
            if (res.data && res.data.customerInfoEntitlement?.length > 0) {
              let options = res.data.customerInfoEntitlement.map((val: any) => {
                return {
                  label: `${val.accountLongName}(${val.accountNumber})`,
                  value: `${val.accountLongName}(${val.accountNumber})`,
                  key: val.accountNumber,
                  name: val.accountLongName
                };
              });
              this.setState({ buyers: options })
            }
          })
          .catch((exception: any) => {
            console.log(`Failure: ${exception}`);
            message.error("获取数据失败，请稍后重试");
          })
      }
    } else {
      this.setState({ buyers: [] });
    }
  };

  // table翻页回调函数
  onDepositDetailsPageChange = (page: number) => {
    const { depositDetailsPagination } = this.state;
    const depositDetailsPaginationTemp = depositDetailsPagination;
    depositDetailsPaginationTemp.current = page;
    this.setState({ depositDetailsPagination: depositDetailsPaginationTemp });
  };
  // table翻页回调函数
  onAccountSettlementPageChange = (page: number) => {
    const { accountSettlementPagination } = this.state;
    const accountSettlementPaginationTemp = accountSettlementPagination;
    accountSettlementPaginationTemp.current = page;
    this.setState({ accountSettlementPagination: accountSettlementPaginationTemp });
  };

  // 获取账户总览数据
  getDepositOverviewData = () => {
    this.setState({ isDepositOverviewLoading: true });
    getDepositOverviewDataApi(this.depositOverviewSearchFiltersData)
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
        } else if (responseJSON?.data) {
          const depositOverviewData = responseJSON.data || {};
          this.setState({
            depositOverviewData
          });
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        this.setState({
          depositOverviewData: {}
        });
      }).finally(() => {
        this.setState({ isDepositOverviewLoading: false });
      })
  };

  // 获取对账明细数据
  getDepositDetailsData = () => {
    const { depositDetailsPagination } = this.state;

    // 重新获取数据时，重置页码
    let depositDetailsPaginationTemp = depositDetailsPagination;
    if (depositDetailsPagination.current !== 1) {
      // 重置页码
      depositDetailsPaginationTemp = { ...depositDetailsPagination, current: 1 };
      this.setState({ depositDetailsPagination: depositDetailsPaginationTemp });
    }
    this.setState({ isDepositDetailsTableLoading: true });
    getDepositDetailsDataApi(this.depositDetailsSearchFiltersData)
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
        } else if (responseJSON?.data) {
          const tableRecordData = Array.isArray(responseJSON.data.depositReportList)
            ? responseJSON.data.depositReportList
            : [];
          const fieldsDescArr = this.getDefaultFieldDesc(JSON.parse(responseJSON.data.fieldsOrder.fieldDesc));
          this.setState({
            depositDetailsTableData: tableRecordData.map(this.mapTableData) || [],
            depositDetailsPagination: {
              ...depositDetailsPaginationTemp,
              total: responseJSON.data.metadata?.totalCount ?? 0
            },
            depositDetailsFieldsDesc: this.filterFieldsList(fieldsDescArr),
            depositDetailsAllFields: this.filterFieldsList(JSON.parse(responseJSON.data.fieldsOrder.allFields))
          });
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        this.setState({
          depositDetailsTableData: [],
          depositDetailsPagination: {
            ...depositDetailsPaginationTemp,
            total: 0
          }
        });
      }).finally(() => {
        this.setState({ isDepositDetailsTableLoading: false });
      });
  };

  // 获取对账明细总额详情
  getDepositDetailsTotalAmountDetailData() {
    // 重新获取数据时，重置页码
    getDepositOverviewDataApi(this.depositDetailsSearchFiltersData)
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
        } else if (responseJSON?.data) {
          const depositDetailsTotalAmountDetailData = responseJSON.data || {};
          this.setState({
            depositDetailsTotalAmountDetailData
          });
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        this.setState({
          depositDetailsTotalAmountDetailData: {}
        });
      }).finally(() => {
        this.setState({ isDepositOverviewLoading: false });
      })
  }

  // 获取清账明细数据
  getAccountSettlementData = () => {
    const { accountSettlementPagination } = this.state;
    // 重新获取数据时，重置页码
    let accountSettlementPaginationTemp = accountSettlementPagination;
    if (accountSettlementPagination.current !== 1) {
      // 重置页码
      accountSettlementPaginationTemp = { ...accountSettlementPagination, current: 1 };
      this.setState({ accountSettlementPagination: accountSettlementPaginationTemp });
    }
    this.setState({ isAccountSettlementTableLoading: true });
    getAccountSettlementDataApi(this.accountSettlementSearchFiltersData)
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
        } else if (responseJSON?.data) {
          const tableRecordData = Array.isArray(responseJSON.data.depositReportList)
            ? responseJSON.data.depositReportList
            : [];
          const fieldsDescArr = this.getDefaultFieldDesc(JSON.parse(responseJSON.data.fieldsOrder.fieldDesc));
          this.setState({
            accountSettlementTableData: tableRecordData.map(this.mapTableData) || [],
            accountSettlementPagination: {
              ...accountSettlementPaginationTemp,
              total: responseJSON.data.metadata?.totalCount ?? 0
            },
            accountSettlementFieldsDesc: this.filterFieldsList(fieldsDescArr),
            accountSettlementAllFields: this.filterFieldsList(JSON.parse(responseJSON.data.fieldsOrder.allFields))
          });
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        this.setState({
          accountSettlementTableData: [],
          accountSettlementPagination: {
            ...accountSettlementPagination,
            total: 0
          }
        });
      }).finally(() => {
        this.setState({ isAccountSettlementTableLoading: false });
      });
  };

  // 获取数据截止时间
  getDepositLastUpdateTime = (filterParams: any, type: string) => {
    const stateKeyMap: { [key: string]: string } = {
      depositOverview: "depositOverviewTimeStamp",
      depositDetails: "depositDetailsTimeStamp"
    };
    const stateKey = stateKeyMap[type];
    getCustomerAccountItemTimeApi({customerCode: filterParams.customerCode})
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          console.log(`Failure: ${responseJSON.errorMsg}`);
        } else if (responseJSON?.data) {
          // 获取保证明细数据截止时间
          const customerdepositdetailsData: any = responseJSON?.data.find((val: any) => {
            return val.entityName === "customerdepositdetails";
          });
          const timeStamp = customerdepositdetailsData?.updatedDateTime || "";
          if (stateKey) {
            this.setState({ [stateKey]: timeStamp } as Pick<State, keyof State>);
          }
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        if (stateKey) {
          this.setState({ [stateKey]: "" } as unknown as Pick<State, keyof State>);
        }
      })
  };

  // 处理账户总览查询
  handleDepositOverviewSearch = (value: any) => {
    this.depositOverviewSearchFiltersData = { ...this.depositOverviewSearchFiltersData, ...value };
    this.getDepositOverviewData();
    this.getDepositLastUpdateTime(this.depositOverviewSearchFiltersData, "depositOverview");
  };

  // 处理对账明细查询
  handleDepositDetailsSearch = (value: any) => {
    this.depositDetailsSearchFiltersData = { ...this.depositDetailsSearchFiltersData, ...value };
    this.getDepositDetailsData();
    this.getDepositLastUpdateTime(this.depositDetailsSearchFiltersData, "depositDetails");
    this.getDepositDetailsTotalAmountDetailData();
  };

  // 处理清空账户总览
  handleDepositOverviewRest = (value: any) => {
    this.depositOverviewSearchFiltersData = { ...this.depositOverviewSearchFiltersData, ...value };
    this.setState({
      depositOverviewData: {},
      depositOverviewTimeStamp: ""
    })
  };

  // 处理清空对账明细表单、table列表
  handleDepositDetailsRest = (value: any) => {
    this.depositDetailsSearchFiltersData = { ...this.depositDetailsSearchFiltersData, ...value };
    const { depositDetailsPagination } = this.state;
    // 清空数据是，重置页码
    const depositDetailsPaginationTemp = { ...depositDetailsPagination, current: 1, total: 0 };
    this.setState({
      depositDetailsTableData: [],
      depositDetailsTimeStamp: "",
      depositDetailsTotalAmountDetailData: {},
      depositDetailsPagination: {
        ...depositDetailsPaginationTemp
      }
    });
  };

  //下载对账明细报表
  handleDownloadDepositDetailsReports = () => {
    const downloadParams = {
      ...this.depositDetailsSearchFiltersData
    };
    this.setState({ isDepositDetailsReportDownloadLoading: true });
    downDepositDetailsApi(downloadParams)
      .then((response: any) => {
        if (response) {
          downloadFile(response, `保证金明细(${moment().format("YYYY-MM-DD")}).xlsx`);
        } else {
          message.error("下载失败，请稍后重试。");
        }
      })
      .catch((exception: any) => {
        message.error("下载失败，请稍后重试。");
        console.log(exception);
      })
      .finally(() => {
        this.setState({ isDepositDetailsReportDownloadLoading: false });
      });
  };

  //下载清账明细报表
  handleDownloadAccountSettlementReports = () => {
    const downloadParams = {
      ...this.accountSettlementSearchFiltersData
    };
    this.setState({ isAccountSettlementReportDownloadLoading: true });
    downAccountSettlementApi(downloadParams)
      .then((response: any) => {
        if (response) {
          downloadFile(response, `保证金明细(${moment().format("YYYY-MM-DD")}).xlsx`);
        } else {
          message.error("下载失败，请稍后重试。");
        }
      })
      .catch((exception: any) => {
        message.error("下载失败，请稍后重试。");
        console.log(exception);
      })
      .finally(() => {
        this.setState({ isAccountSettlementReportDownloadLoading: false });
      });
  };

  // 处理对账明细的凭证编号的点击和清账明细的凭证编号的点击
  handleClickDocumentNumber = (data: any, type: string) => {
    const { accountSettlementPagination, accountSettlementSearchResultRecordList, currentacccountSettlementSearchResultRecordIndex } = this.state;
    if (type === "reconciliation") {
      this.setState({
        accountSettlementModalVisible: true,
        accountSettlementTableData: [],
        accountSettlementPagination: {
          ...accountSettlementPagination,
          current: 1,
          total: 0
        }
      });
    }
    this.accountSettlementSearchFiltersData = {
      ...this.accountSettlementSearchFiltersData,
      clearingDocumentNumber: data.documentNumber,
      customerCode: data.accountNumber,
      orgCode: data.companyCode
    }
    const accountSettlementSearchResultRecordListTemp: any = accountSettlementSearchResultRecordList;
    accountSettlementSearchResultRecordListTemp.splice(currentacccountSettlementSearchResultRecordIndex + 1); // 将当前index后面的元素全部删掉，使最新点击的数据作为最后一个元素
    accountSettlementSearchResultRecordListTemp.push(this.accountSettlementSearchFiltersData);
    this.setState({
      accountSettlementSearchResultRecordList: accountSettlementSearchResultRecordListTemp,
      currentacccountSettlementSearchResultRecordIndex: accountSettlementSearchResultRecordListTemp.length - 1 // 点击后将当前index的值设置为最后一个元素的index的值
    });
    this.getAccountSettlementData();
  };

  // 处理清账模态框的关闭
  handleCloseAccountSettlementModal = () => {
    this.setState({
      accountSettlementModalVisible: false,
      accountSettlementSearchResultRecordList: [],
      currentacccountSettlementSearchResultRecordIndex: 0
    });
  };

  // 处理清账明细后退
  handlePreviousQueryAccountSettlement = () => {
    const { accountSettlementSearchResultRecordList, currentacccountSettlementSearchResultRecordIndex } = this.state;
    const accountSettlementSearchResultRecordListTemp: any = accountSettlementSearchResultRecordList;
    // 获取上一次的查询记录
    const accountSettlementSearchFiltersDataTemp = accountSettlementSearchResultRecordListTemp[currentacccountSettlementSearchResultRecordIndex - 1];
    this.accountSettlementSearchFiltersData = accountSettlementSearchFiltersDataTemp;
    this.setState({
      accountSettlementSearchResultRecordList: accountSettlementSearchResultRecordListTemp,
      currentacccountSettlementSearchResultRecordIndex: currentacccountSettlementSearchResultRecordIndex - 1
    });
    this.getAccountSettlementData();
  };

  // 处理清账明细前进
  handleNextQueryAccountSettlement = () => {
    const { accountSettlementSearchResultRecordList, currentacccountSettlementSearchResultRecordIndex } = this.state;
    const accountSettlementSearchResultRecordListTemp: any = accountSettlementSearchResultRecordList;
    // 获取下一次的查询记录
    const accountSettlementSearchFiltersDataTemp = accountSettlementSearchResultRecordListTemp[currentacccountSettlementSearchResultRecordIndex + 1];
    this.accountSettlementSearchFiltersData = accountSettlementSearchFiltersDataTemp;
    this.setState({
      accountSettlementSearchResultRecordList: accountSettlementSearchResultRecordListTemp,
      currentacccountSettlementSearchResultRecordIndex: currentacccountSettlementSearchResultRecordIndex + 1
    });
    this.getAccountSettlementData();
  };

  // 格式化处理table的展示信息
  mapTableData = (data: any) => {
    // 抽取公共方法替换原来的||运算符
    const revertValue = (value: any) => {
      if (!value) {
        value = "-";
      }
      return value;
    };
    return {
      ...data,
      documentNumber: revertValue(data.documentNumber),
      documentLineItem: revertValue(data.documentLineItem),
      accountLongName: revertValue(data.accountLongName),
      orgName: revertValue(data.orgName),
      customerGlIndicator: revertValue(data.customerGlIndicator),
      // specialGlIndicator: revertValue(data.specialGlIndicator),
      // targetSpecialGlIndicator: revertValue(data.targetSpecialGlIndicator),
      assignment: revertValue(data.assignment),
      amountAbsolute: data.amountAbsolute ? data.amountAbsolute.toFixed(2) : "0.00",
      postingDate: data.postingDate ? moment(data.postingDate).format("YYYY-MM-DD") : "-",
      netDueDate: data.netDueDate ? moment(data.netDueDate).format("YYYY-MM-DD") : "-",
      due: data.due ? "是" : "否",
      dueDays: revertValue(data.dueDays),
      accountNumber: revertValue(data.accountNumber),
      companyCode: revertValue(data.companyCode),
      localCurrency: revertValue(data.localCurrency),
      profitCenter: revertValue(data.profitCenter)
    };
  };

  // 获取对账明细字段设置后的columns
  getDepositDetailsColumns = (data: any, dataSource: any, setVisibleFun: any) => {
    let temp = data.map((item: any) => {
      return dataSource.find((val: any) => val.value === item);
    });
    temp = temp.filter((val: any) => val);
    saveDepositDetailsFieldsOrderApi({
      type: "deposit",
      fieldDesc: JSON.stringify(temp)
    })
      .then(Response => {
        if (Response) {
          this.getDepositDetailsData();
        }
      })
      .catch(err => {
        message.error("字段设置失败，请稍后重试！");
        console.log(err);
      })
      .finally(() => {
        setVisibleFun(false);
      });
  };

  // 获取清账明细字段设置后的colums
  getAccountSettlementColumns = (data: any, dataSource: any, setVisibleFun: any) => {
    let temp = data.map((item: any) => {
      return dataSource.find((val: any) => val.value === item);
    });
    temp = temp.filter((val: any) => val);
    saveAccountSettlementFieldsOrderApi({
      type: "square",
      fieldDesc: JSON.stringify(temp)
    })
      .then(Response => {
        if (Response) {
          this.getAccountSettlementData();
        }
      })
      .catch(err => {
        message.error("字段设置失败，请稍后重试！");
        console.log(err);
      })
      .finally(() => {
        setVisibleFun(false);
      });
  };

  // 确保字段不重复
  getDefaultFieldDesc = (data: any) => {
    // 清除重复数据
    let newData: any = [];
    let dataValue: any = [];
    data.forEach((val: any) => {
      if (dataValue.indexOf(val.value) === -1) {
        dataValue.push(val.value);
        newData.push(val);
      }
    });
    return newData;
  };

  // 可根据角色不同，给到不同的字段信息
  filterFieldsList = (dataSource: any) => {
    const { permissions } = this.props;
    if (dataSource) {
      const externalUsersIgnoreFields: any = ["profitCenter", "netDueDate", "due", "dueDays", "accountNumber", "companyCode"];
      if (permissions.CNExternalUsers) {
        dataSource = dataSource.filter((val: any) => !externalUsersIgnoreFields.includes(val.value));
      }
      return dataSource;
    }
  };

  // 对账明细Card组件的extra信心展示
  depositDetailsCardExtra = () => {
    const { depositDetailsPagination, depositDetailsFieldsDesc, depositDetailsAllFields, isDepositDetailsReportDownloadLoading } = this.state;
    return (
      <div className="hdp-uf hdp-uf-vc hdp-uf-ww card-extra">
        <div className="extra-total extra-item">
          <span>共 </span>
          <span>{depositDetailsPagination.total}</span>
          <span> 条</span>
        </div>
        {!isMobileDevice() && (
          <div className="extra-item">
            <FieldSetting fieldDesc={depositDetailsFieldsDesc} allFields={depositDetailsAllFields} getColumns={this.getDepositDetailsColumns} />
          </div>
        )}
        <div className="extra-item">
          <Button
            data-testid="test-download-reports"
            type="primary"
            loading={isDepositDetailsReportDownloadLoading}
            icon={<DownloadOutlined />}
            disabled={depositDetailsPagination.total === 0}
            onClick={this.handleDownloadDepositDetailsReports}>
            下载报表
          </Button>
        </div>
      </div>
    );
  };

  // 清账明细Card组件的extra信心展示
  accountSettlementCardExtra = () => {
    const { accountSettlementPagination, accountSettlementFieldsDesc, accountSettlementAllFields, isAccountSettlementReportDownloadLoading } = this.state;
    return (
      <div className="hdp-uf hdp-uf-vc hdp-uf-ww card-extra">
        <div className="extra-total extra-item">
          <span>共 </span>
          <span>{accountSettlementPagination.total}</span>
          <span> 条</span>
        </div>
        {!isMobileDevice() && (
          <div className="extra-item">
            <FieldSetting fieldDesc={accountSettlementFieldsDesc} allFields={accountSettlementAllFields} getColumns={this.getAccountSettlementColumns} />
          </div>
        )}
        <div className="extra-item">
          <Button
            data-testid="test-download-reports"
            type="primary"
            loading={isAccountSettlementReportDownloadLoading}
            icon={<DownloadOutlined />}
            disabled={accountSettlementPagination.total === 0}
            onClick={this.handleDownloadAccountSettlementReports}>
            下载报表
          </Button>
        </div>
      </div>
    );
  };

  public render() {
    const {
      buyers,
      depositDetailsTotalAmountDetailData,
      depositDetailsTableData,
      depositDetailsPagination,
      depositDetailsFieldsDesc,
      accountSettlementFieldsDesc,
      isDepositOverviewLoading,
      isDepositDetailsTableLoading,
      isAccountSettlementTableLoading,
      depositOverviewData,
      accountSettlementModalVisible,
      accountSettlementTableData,
      accountSettlementPagination,
      depositOverviewTimeStamp,
      depositDetailsTimeStamp,
      accountSettlementSearchResultRecordList,
      currentacccountSettlementSearchResultRecordIndex
    } = this.state;

    return (
      <div className="deposit-details-wrapper">
        {/* 账户总览 */}
        <DepositOverview defaultSearchValue={this.props.defaultSearchValue} doSearch={this.handleDepositOverviewSearch} doRest={this.handleDepositOverviewRest} buyers={buyers} depositOverviewData={depositOverviewData} timeStamp={depositOverviewTimeStamp} loading={isDepositOverviewLoading} />
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb" }}
          bodyStyle={{ padding: "0" }}
          headStyle={{
            backgroundColor: "white",
            borderBottom: "none"
          }}>
          {/* 对账明细筛选器 */}
          <div className="deposit-details-search-filter-container">
            <Card
              style={{ border: "none", borderRadius: "0" }}
              bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
              title="查询保证金明细"
              headStyle={{
                backgroundColor: "white",
                borderBottom: "none"
              }}
            >
              <DepositDetailsSearchFilter defaultSearchValue={this.props.defaultSearchValue} doSearch={this.handleDepositDetailsSearch} doRest={this.handleDepositDetailsRest} buyers={buyers} />
            </Card>
          </div>
          {/* 对账明细表单 */}
          <div className="deposit-details-table-container">
            <Card
              style={{ border: "none" }}
              bodyStyle={{ paddingTop: 0 }}
              title="保证金明细（总额为列表中显示记录的所有金额的汇总）"
              headStyle={{
                backgroundColor: "white",
                borderBottom: "none"
              }}
              extra={this.depositDetailsCardExtra()}>
              {/* 查询结果数据汇总 */}
              <Row gutter={[16, 16]} justify="start" style={{ marginTop: 0, marginBottom: 20, padding: "0 13px" }}>
                <Col md={6} xs={12}>
                  <span>已支付履约保证金余额（元）: </span>
                  <span className="row-content">{depositDetailsTotalAmountDetailData.depositReceived == null ? "-" : formatReverseNumberToFixedThousands(depositDetailsTotalAmountDetailData.depositReceived, 2)}</span>
                </Col>
                <Col md={6} xs={12}>
                  <span>已支付追加保证金余额（元）: </span>
                  <span className="row-content">{depositDetailsTotalAmountDetailData.marginCallReceived == null ? "-" : formatReverseNumberToFixedThousands(depositDetailsTotalAmountDetailData.marginCallReceived, 2)}</span>
                </Col>
                <Col md={6} xs={12}>
                  <span>应支付履约保证金总额（元）: </span>
                  <span className="row-content">{depositDetailsTotalAmountDetailData.depositReceivable == null ? "-" : formatNumberToFixedThousands(depositDetailsTotalAmountDetailData.depositReceivable, 2)}</span>
                </Col>
                <Col md={6} xs={12}>
                  <span>应支付追加保证金总额（元）: </span>
                  <span className="row-content">{depositDetailsTotalAmountDetailData.marginCallReceivable == null ? "-" : formatNumberToFixedThousands(depositDetailsTotalAmountDetailData.marginCallReceivable, 2)}</span>
                </Col>
              </Row>
              {/* 对账明细table数据 */}
              <DepositDetailsTableList
                tableData={depositDetailsTableData}
                pagination={{
                  ...depositDetailsPagination,
                  onChange: this.onDepositDetailsPageChange
                }}
                fieldsDesc={depositDetailsFieldsDesc}
                loading={isDepositDetailsTableLoading}
                handleClickDocumentNumber={this.handleClickDocumentNumber}
              />
              {
                depositDetailsPagination.total > 0 && depositDetailsTimeStamp && (
                  <div className="tips">
                    <ExclamationCircleOutlined />
                    <span className="label">以上数据系截止至【{moment(depositDetailsTimeStamp).format("YYYY年MM月DD日 HH:mm")}】，请以实际为准。</span>
                  </div>
                )
              }
            </Card>
          </div>
          <div className="account-settlement-modal-container">
            <AccountSettlementModal
              visible={accountSettlementModalVisible}
              handleCancel={this.handleCloseAccountSettlementModal}
              tableData={accountSettlementTableData}
              pagination={{
                ...accountSettlementPagination,
                onChange: this.onAccountSettlementPageChange
              }}
              fieldsDesc={accountSettlementFieldsDesc}
              loading={isAccountSettlementTableLoading}
              handleClickDocumentNumber={this.handleClickDocumentNumber}
              cardExtra={this.accountSettlementCardExtra}
              handlePreviousQueryAccountSettlement={this.handlePreviousQueryAccountSettlement}
              handleNextQueryAccountSettlement={this.handleNextQueryAccountSettlement}
              accountSettlementSearchResultRecordList={accountSettlementSearchResultRecordList}
              currentacccountSettlementSearchResultRecordIndex={currentacccountSettlementSearchResultRecordIndex}
            />
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  switcher: state.switcher,
  userDetail: state.userDetails
});

export default connect(mapStateToProps, null)(DepositDetails);