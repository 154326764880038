import { useCallback, useEffect, useState } from "react";
import "./index.scss";

import { PageHeader, Tabs } from "antd";
import NotificationTips from "@/pages/common/notificationTips";
import CreditBalanceOverview from "./creditBalanceOverview";
import CashBalance from "./cashBalance";
import BillUsageDetails from "./billUsageDetails";

const { TabPane } = Tabs;

const CheckAvailableBalance = (props: any) => {
  const [activeKey, setActiveKey] = useState("1");
  const [cashBalancSearchValue, setCashBalancSearchValue] = useState({});
  const [billUsageDetailsSearchValue, setBillUsageDetailsSearchValue] = useState({});

  useEffect(() => {
    document.title = "开单额度";
  });

  const onTabChange = useCallback((key: any) => {
    setActiveKey(key);
  }, []);

  // 激活指定标签页
  const goToTab = useCallback((key: any, params: any) => {
    setActiveKey(key);
    if (key === "2") {
      setCashBalancSearchValue({ ...params });
    } else if (key === "3") {
      setBillUsageDetailsSearchValue({ ...params });
    }
  }, []);

  return (
    <>
      <PageHeader className="page-header" title="开单额度">
        <NotificationTips />
      </PageHeader>
      <div className="check-balance">
        <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onTabChange}>
          <TabPane tab="额度总览" key="1">
            <CreditBalanceOverview goToTab={goToTab} />
          </TabPane>
          <TabPane tab="现金余额" key="2">
            <CashBalance defaultSearchValue={cashBalancSearchValue} goToTab={goToTab} />
          </TabPane>
          <TabPane tab="提单占用明细" key="3">
            <BillUsageDetails defaultSearchValue={billUsageDetailsSearchValue} goToTab={goToTab} />
          </TabPane>
        </Tabs>
      </div>
    </>
  )
};

export default CheckAvailableBalance;