import { getFirmDetailListApi } from "@/api/availableBalance";
import { getFirmDetailsList } from "@/api/constant";
import RemoteSelect from "@/pages/common/remoteSelect";
import { getCode } from "@/utils/common";
import { allSupplierMutiple, dueOptions } from "@/utils/constant";
import { Button, Col, Collapse, Divider, Form, Input, Row, Select, message } from "antd";
import { useCallback, useEffect, useState } from "react";

interface Props {
  doSearch?: (arg0: any) => void;
  permissions: any;
  userDetails: any;
  switcher: any;
  defaultSearchValue?: any;
};

const SearchFilter = (props: Props) => {
  const { permissions, userDetails, switcher, doSearch, defaultSearchValue } = props;
  const [customerOptions, setCustomerOptions] = useState([]);
  const [form] = Form.useForm();
  const [isExpand, setIsExpand] = useState(false);
  const [expandCollapseText, setExpandCollapseText] = useState("展开高级筛选");
  const [isSelectFetching, setIsSelectFetching] = useState(false);


  const { Panel } = Collapse;

  const handelExpandCollapse = useCallback(() => {
    setIsExpand(!isExpand);
    setExpandCollapseText(isExpand ? "展开高级筛选" : "收起高级筛选");
  }, [isExpand]);

  // 外部Group用户筛选公司
  const onFilterOption = useCallback((inputValue: any, option: any) => {
    return ((option?.label as unknown) as string).toLowerCase().includes(inputValue?.toLowerCase());
  }, []);

  // 内部用户--获取买方选项(防抖输入查询)
  const fetchCustomerOptions_Internal = useCallback(
    (value: string | undefined, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      getFirmDetailListApi({
        customerName: value || "",
        virtualFlag: "1"
      })
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            callback([]);
            message.error(res.errorMsg);
          } else if (res.data?.customerList?.length > 0 && currentValue === value) {
            let data: any[] = res.data?.customerList?.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName + "(" + val.customerCode + ")",
                key: val.customerCode
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    []
  );

  // 外部 group 用户查询公司（集团公司）
  const fetchCustomerOptions_External = useCallback((controlIdentifier: any) => {
    getFirmDetailsList({ accountNumber: controlIdentifier })
      .then((res: any) => {
        if (res.data && res.data.customerInfoEntitlement?.length > 0) {
          let options = res.data.customerInfoEntitlement.map((val: any) => {
            return {
              label: `${val.accountLongName}(${val.accountNumber})`,
              value: `${val.accountLongName}(${val.accountNumber})`,
              key: val.accountNumber,
              name: val.accountLongName
            };
          });
          const currentCustomerOption = options.find((val: any) => val.key === switcher.customerCode);
          setCustomerOptions(options);
          form.setFieldsValue({
            customerCode: currentCustomerOption?.value
          });
        } else {
          setCustomerOptions([]);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      });
  }, [form, switcher]);

  const onFinish = useCallback((values?: any) => {
    let temp = { ...values };
    // 将条件所有值传递给父组件
    if (doSearch) {
      // 此模块只传code，去掉中文
      if (temp.customerCode) {
        temp.customerCode = getCode(temp.customerCode);
      }
      temp.orgCode = temp.orgCode?.length ? temp.orgCode.join(",") : "";
      // 传递筛选的最终结果给父级组件
      doSearch(temp);
    }
  }, [doSearch]);

  const resetSearch = useCallback(() => {
    const {
      switcher,
      permissions,
      userDetails
    } = props;
    form.resetFields();
    form.setFieldsValue({
      orgCode: switcher.orgCode && switcher.orgCode !== "All" ? [switcher.orgCode] : []
    });
    if (permissions.CNExternalUsers && !userDetails?.isGroup) {
      form.setFieldsValue({
        customerCode: switcher.customerName + "(" + switcher.customerCode + ")"
      });
    }
  }, [form, props]);

  useEffect(() => {
    if (switcher) {
      form.setFieldsValue({ orgCode: switcher.orgCode && switcher.orgCode !== "All" ? [switcher.orgCode] : [] });
      if (permissions.CNExternalUsers) {
        // 判断是否为集团用户
        const userDataCheck = userDetails && Object.keys(userDetails).length && userDetails.entitlements;
        if (userDataCheck) {
          const { entitlements } = userDetails;
          const {
            otherControls: { companyAccountType },
            controlIdentifier
          } = entitlements[0];
          if (companyAccountType === "GROUP") {
            fetchCustomerOptions_External(controlIdentifier);
          } else {
            form.setFieldsValue({ customerCode: switcher.customerName + "(" + switcher.customerCode + ")" });
          }
        }
      }
    }
  }, [form, switcher, permissions, userDetails, fetchCustomerOptions_External]);

  useEffect(() => {
    if (defaultSearchValue && Object.keys(defaultSearchValue).length) {
      form.resetFields();
      form.setFieldsValue({
        orgCode: defaultSearchValue.orgCode,
        customerCode: defaultSearchValue.customerCode
      });
    }
  }, [form, defaultSearchValue, switcher.orgCode]);

  return (
    <div className="base-filter">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={{ md: 16, xs: 8 }}>
          {permissions.CNInternalUsers && (
            <Col md={6} xs={24}>
              <Form.Item
                name="customerCode"
                rules={[{ required: true, message: "请输入公司名称" }]}
              >
                <RemoteSelect
                  placeholder="请输入名称查询"
                  fetchOptions={fetchCustomerOptions_Internal}
                  loading={isSelectFetching}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          )}
          {permissions.CNExternalUsers && userDetails?.isGroup && (
            <Col md={6} xs={24}>
              <Form.Item name="customerCode">
                <Select
                  showSearch
                  placeholder="请选择公司名称"
                  options={customerOptions}
                  optionFilterProp="label"
                  filterOption={onFilterOption}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          )}
          {permissions.CNExternalUsers && !userDetails?.isGroup && (
            <Col md={6} xs={24}>
              <Form.Item name="customerCode">
                <Input disabled />
              </Form.Item>
            </Col>
          )}
          <Col md={6} xs={24}>
            <Form.Item name="orgCode">
              <Select
                data-testid="test-orgCode-select"
                placeholder="请选择卖方公司"
                mode="multiple"
                maxTagCount={"responsive" as const}
                options={allSupplierMutiple()}
              />
            </Form.Item>
          </Col>
          <Col md={2} xs={24}>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn"
                  disabled={isExpand || !form.getFieldValue("customerCode")}
                >
                  查询
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Collapse
          style={{ marginTop: -25 }}
          ghost
          collapsible="header"
          onChange={handelExpandCollapse}
          expandIconPosition="right"
        >
          <Panel header={<span style={{ color: "#007ed9" }}>{expandCollapseText}</span>} key="1" forceRender={true}>
            <Divider dashed={true} style={{ width: "101%", margin: "-10px 0 13px -14px" }} />
            <Row gutter={{ md: 16, xs: 8 }}>
              <Col md={6} xs={24}>
                <Form.Item name="remark" label="备注（合同号或其他）">
                  <Input data-testid="test-remark-input" placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item name="due" label="是否超期">
                  <Select
                    allowClear
                    data-testid="test-due-select"
                    placeholder="请选择"
                    options={dueOptions}
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item name="profitCenter" label="利润中心">
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col className="advance-btn-col">
                <Form.Item shouldUpdate>
                  {() => (
                    <div className="advance-btn">
                      <Button
                        data-testid="test-search-advance-filters-reset"
                        type="primary"
                        style={{ marginRight: 10, backgroundColor: "#5c5c5c" }}
                        onClick={resetSearch}
                      >
                        清空
                      </Button>
                      <Button
                        data-testid="test-search-advance-filters"
                        type="primary"
                        htmlType="submit"
                        disabled={!isExpand || !form.getFieldValue("customerCode")}
                      >
                        立即查询
                      </Button>
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Form>
    </div>
  )
};

export default SearchFilter;