
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Form, Input, Row, Select, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getCrashBalance, getCustomerAccountItemTimeApi, getFirmDetailListApi } from "@/api/availableBalance";
import RemoteSelect from "@/pages/common/remoteSelect";
import { formatNumberToFixedThousands, formatReverseNumberToFixedThousands, getCode } from "@/utils/common";
import { getFirmDetailsList } from "@/api/constant";
import { allSupplierMutiple } from "@/utils/constant";
import "./index.scss";
import moment from "moment";
import { useHistory } from "react-router-dom";

interface Props {
  switcher: any,
  userDetails: any,
  permissions: any,
  defaultSearchValue?: any
  goToTab: Function
}

const CashBalance = (props: Props) => {
  const { switcher, userDetails, permissions, defaultSearchValue, goToTab } = props;
  const [loading, setLoading] = useState(false);
  const [isSelectFetching, setIsSelectFetching] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [isShowResult, setIsShowResult] = useState(false);
  const [cashBalanceData, setCashBalanceData] = useState({} as any);
  const [searchValue, setSearchValue] = useState({} as any);
  const [customerAccountItemTime, setCustomerAccountItemTime] = useState("");
  const [form] = Form.useForm();

  const history = useHistory();

  // 外部Group用户筛选公司
  const onFilterOption = useCallback((inputValue: any, option: any) => {
    return ((option?.label as unknown) as string).toLowerCase().includes(inputValue?.toLowerCase());
  }, []);

  // 外部 group 用户查询公司（集团公司）
  const fetchCustomerOptions_External = useCallback((controlIdentifier: any) => {
    getFirmDetailsList({ accountNumber: controlIdentifier })
      .then((res: any) => {
        if (res.data && res.data.customerInfoEntitlement?.length > 0) {
          let options = res.data.customerInfoEntitlement.map((val: any) => {
            return {
              label: `${val.accountLongName}(${val.accountNumber})`,
              value: `${val.accountLongName}(${val.accountNumber})`,
              key: val.accountNumber,
              name: val.accountLongName
            };
          });
          const currentCustomerOption = options.find((val: any) => val.key === switcher.customerCode);
          setCustomerOptions(options);
          form.setFieldsValue({
            customerCode: currentCustomerOption?.value
          });
        } else {
          setCustomerOptions([]);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      });
  }, [form, switcher]);

  // 内部用户--获取买方选项(防抖输入查询)
  const fetchCustomerOptions_Internal = useCallback(
    (value: string | undefined, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      getFirmDetailListApi({
        customerName: value || ""
      })
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            callback([]);
            message.error(res.errorMsg);
          } else if (res.data?.customerList?.length > 0 && currentValue === value) {
            let data: any[] = res.data?.customerList?.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName + "(" + val.customerCode + ")",
                key: val.customerCode
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    []
  );

  // 切换到提单占用明细
  const goToBillUsageDetails = useCallback(() => {
    goToTab("3", searchValue);
  }, [goToTab, searchValue]);

  // 跳转到对账单页面
  const goToAccountStatement = useCallback(() => {
    history.push({ pathname: "/main/financialmanagement/accountstatement" })
  }, [history]);

  // 跳转到查看保证金明细
  const goToDepositDetails = useCallback(() => {
    history.push({ pathname: "/main/financialmanagement/paymentdetails", state: { activeKey: "3" } })
  }, [history]);

  // 获取数据截止时间
  const getCustomerAccountItemTime = useCallback((params: object) => {
    getCustomerAccountItemTimeApi(params)
      .then((res: any) => {
        if (res?.data) {
          // 获取现金余额数据截止时间
          const customeraccountbalanceData: any = res?.data.find((val: any) => {
            return val.entityName === "customeraccountbalance";
          });
          setCustomerAccountItemTime(customeraccountbalanceData?.updatedDateTime || "");
        }
      })
      .catch((exception: any) => {
        setCustomerAccountItemTime("");
        console.log(`Failure: ${exception}`);
      })
  }, [setCustomerAccountItemTime]);

  // 查询现金余额
  const onFinish = useCallback((values: any) => {
    setLoading(true);
    setSearchValue(values);
    const valuesTemp = { ...values };
    valuesTemp.customerCode = getCode(valuesTemp.customerCode);
    valuesTemp.orgCode = valuesTemp.orgCode?.length > 0 ? valuesTemp.orgCode.join(",") : "";
    getCrashBalance(valuesTemp)
      .then((res: any) => {
        if (res?.data?.customerAccountBalance && Object.keys(res.data.customerAccountBalance).length) {
          // to do 处理成功响应
          setCashBalanceData(res.data.customerAccountBalance);
          setIsShowResult(true);
        } else {
          message.error("No Results Found");
          setIsShowResult(false);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        setIsShowResult(false);
      })
      .finally(() => {
        setLoading(false);
      })
    // 获取数据截止时间
    getCustomerAccountItemTime({ customerCode: valuesTemp.customerCode });
  }, [getCustomerAccountItemTime]);

  useEffect(() => {
    if (switcher) {
      form.setFieldsValue({ orgCode: switcher.orgCode && switcher.orgCode !== "All" ? [switcher.orgCode] : [] });
      if (permissions.CNExternalUsers) {
        // 判断是否为集团用户
        const userDataCheck = userDetails && Object.keys(userDetails).length && userDetails.entitlements;
        if (userDataCheck) {
          const { entitlements } = userDetails;
          const {
            otherControls: { companyAccountType },
            controlIdentifier
          } = entitlements[0];
          if (companyAccountType === "GROUP") {
            fetchCustomerOptions_External(controlIdentifier);
          } else {
            form.setFieldsValue({ customerCode: switcher.customerName + "(" + switcher.customerCode + ")" });
          }
        }
      }
    }
  }, [form, switcher, permissions, userDetails, fetchCustomerOptions_External]);

  useEffect(() => {
    if (defaultSearchValue && Object.keys(defaultSearchValue).length) {
      form.resetFields();
      form.setFieldsValue({
        orgCode: switcher.orgCode && switcher.orgCode !== "All" ? [switcher.orgCode] : [],
        customerCode: defaultSearchValue.customerCode
      });
    }
  }, [form, defaultSearchValue, switcher.orgCode]);

  return (
    <div className="cash-balance-wrapper">
      <div className="search-filter">
        {permissions.CNInternalUsers && <div className="title">查询现金余额</div>}
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            customerCode: defaultSearchValue.customerCode,
          }}
        >
          <Row gutter={{ md: 16, xs: 8 }}>
            {permissions.CNInternalUsers && (
              <Col md={6} xs={24}>
                <Form.Item
                  label="公司名称"
                  name="customerCode"
                  rules={[{ required: true, message: "请输入公司名称" }]}
                >
                  <RemoteSelect
                    dataTestId="test-internalUser-customerCode-select-02"
                    placeholder="请输入名称查询"
                    fetchOptions={fetchCustomerOptions_Internal}
                    loading={isSelectFetching}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}
            {permissions.CNExternalUsers && userDetails?.isGroup && (
              <Col md={6} xs={24}>
                <Form.Item label="公司名称" name="customerCode">
                  <Select
                    showSearch
                    placeholder="请选择公司名称"
                    options={customerOptions}
                    optionFilterProp="label"
                    filterOption={onFilterOption}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}
            {permissions.CNExternalUsers && !userDetails?.isGroup && (
              <Col md={6} xs={24}>
                <Form.Item label="公司名称" name="customerCode">
                  <Input disabled />
                </Form.Item>
              </Col>
            )}
            <Col md={6} xs={24}>
              <Form.Item label="卖方" name="orgCode">
                <Select
                  placeholder="请选择卖方公司"
                  mode="multiple"
                  maxTagCount={"responsive" as const}
                  options={allSupplierMutiple()}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            {permissions.CNInternalUsers && (
              <Col md={6} xs={24}>
                <Form.Item label="利润中心" name="profitCenter">
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
            )}
            <Col md={2} xs={24}>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    data-testid="test-submit-btn-02"
                    type="primary"
                    htmlType="submit"
                    className="submit-btn"
                    loading={loading}
                    disabled={!form.getFieldValue("customerCode")}
                  >
                    查询
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="content">
        {isShowResult ? (<div className="search-result">
          <div className="money-info-container">
            <Row>
              <Col>
                <div className="result-col-item-container">
                  <div className="result-col-item">
                    <span className="label">现金余额（元）：</span>
                    <span className="value">{(cashBalanceData.balanceTotal == null && cashBalanceData.balanceDeposit == null && cashBalanceData.exposureOrderTotal == null) ? "-" : formatReverseNumberToFixedThousands((cashBalanceData.balanceTotal - cashBalanceData.balanceDeposit + cashBalanceData.exposureOrderTotal), 2)}</span>
                  </div>
                  <div className="tips">
                    <ExclamationCircleOutlined />
                    <span className="label">现金余额 = 账面余额 - 保证金余额 - 提单占用总额</span>
                  </div>
                </div>
                <div className="result-col-item-container">
                  <div className="result-col-item">
                    <span className="label">账面余额（元）：</span>
                    <span className="value">{cashBalanceData.balanceTotal == null ? "-" : formatReverseNumberToFixedThousands(cashBalanceData.balanceTotal, 2)}</span>
                  </div>
                  <Button type="link" onClick={goToAccountStatement}>查看对账单</Button>
                  <div className="tips">
                    <ExclamationCircleOutlined />
                    <span className="label">账面余额含保证金，未扣除提单占用总额</span>
                  </div>
                </div>
                <div className="result-col-item-container">
                  <div className="result-col-item">
                    <span className="label">保证金余额（元）：</span>
                    <span className="value">{cashBalanceData.balanceDeposit == null ? "-" : formatReverseNumberToFixedThousands(cashBalanceData.balanceDeposit, 2)}</span>
                  </div>
                  <Button type="link" onClick={goToDepositDetails}>查看保证金余额明细</Button>
                </div>
                <div className="result-col-item-container">
                  <div className="result-col-item">
                    <span className="label">提单占用总额（元）：</span>
                    <span className="value">{cashBalanceData.exposureOrderTotal == null ? "-" : formatNumberToFixedThousands(cashBalanceData.exposureOrderTotal, 2)}</span>
                  </div>
                  <Button data-testid="test-view-bill-usage-details-btn" type="link" onClick={goToBillUsageDetails}>查看提单占用明细</Button>
                  <div className="tips">
                    <ExclamationCircleOutlined />
                    <span className="label">所有已审核且未入账的提单所占用的金额</span>
                  </div>
                </div>
                {customerAccountItemTime &&
                  <div className="tips">
                    <ExclamationCircleOutlined />
                    <span className="label">以上数据系截止至【{moment(customerAccountItemTime).format("YYYY年MM月DD日 HH:mm")}】，请以实际为准。</span>
                  </div>}
              </Col>
            </Row>
          </div>
        </div>) : (<div className="tips">
          <ExclamationCircleOutlined />
          <span className="label">请输入以上信息后查看。</span>
        </div>)}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  switcher: state.switcher,
  userDetails: state.userDetails,
  permissions: state.userPermissions
});

export default connect(mapStateToProps, null)(CashBalance);