import { useState, useCallback } from "react";
import { Modal, Button, Input, Form, message, Row, Col } from "antd";
import { rejectPointPrice } from "@/api/directSys";
const { TextArea } = Input;

const RefuseModal = (props: any) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reasonInput, setReasonInput] = useState(false);

  const handleOk = useCallback(
    (values: any) => {
      setLoading(true);
      const request = {
        reason: values.reason,
        id: props.selectedRow.id
      };
      rejectPointPrice(request, {})
        .then((res: any) => {
          if (res?.result) {
            props.getListData();
          } else {
            message.error(res?.data?.description);
          }
        })
        .finally(() => {
          setIsModalVisible(false);
          setLoading(false);
          form.resetFields();
          setReasonInput(false);
        });
    },
    [form, props]
  );

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    form.resetFields();
    setReasonInput(false);
  }, [form]);

  const handleReasonInput = useCallback(() => {
    if (form.getFieldValue("reason")) {
      setReasonInput(true);
    } else {
      setReasonInput(false);
    }
  }, [form]);

  const handleVisible = useCallback(() => {
    setIsModalVisible(!isModalVisible);
  }, [isModalVisible]);

  return (
    <>
      <Button type="primary" onClick={handleVisible} disabled={!props.hasSelected}>
        拒绝
      </Button>
      <Modal title="拒绝原因" maskClosable={false} visible={isModalVisible} footer={null} onCancel={handleCancel}>
        <Form form={form} onFinish={handleOk}>
          <Form.Item name="reason">
            <TextArea data-testid="test-textarea" placeholder="请填写拒绝原因" onChange={handleReasonInput} />
          </Form.Item>
          <Row gutter={[16, 16]}>
            <Col style={{ marginLeft: "auto", marginRight: 0 }}>
              <Form.Item>
                <div style={{ marginTop: 0 }}>
                  <Button type="primary" style={{ backgroundColor: "#5c5c5c" }} onClick={handleCancel}>
                    取消
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={!reasonInput}
                    data-testid="test-submit"
                  >
                    提交
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default RefuseModal;
