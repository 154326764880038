import { Component } from "react";
import { Button } from "antd";
import { RouteComponentProps } from "react-router-dom";
import "./error.scss";

interface State {
  errorMsg: string;
}

interface Props extends RouteComponentProps<any> {}

class Error extends Component<Props, State> {
  public state = {
    errorMsg: "页面不存在或无权限访问。"
  };

  goBack = () => {
    //返回上一个链接
    this.props.history.goBack();
  };

  public render() {
    const { errorMsg } = this.state;
    return (
      <div className="hdp-uf hdp-uf-hc hdp-uf-vc error">
        <div className="hdp-uf hdp-uf-dc">
          <p className="hdp-uf hdp-uf-hc error-code">404</p>
          <div className="hdp-uf hdp-uf-vc hdp-uf-dc error-tips">
            <h2>Ooops!</h2>
            <div>{errorMsg}</div>
            <Button data-testid="btn-go-back" type="primary" className="action-btn" onClick={this.goBack}>
              返回
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Error;
