import RequestService from "@/api/request";

const getSwitcherPreferencesApi = () => {
  return RequestService({
    url: "/commodity-movementsap/retrieveGlobalSwitcher",
    method: "get"
  });
};

const updateSwitcherPreferencesApi = (dataObj: object) => {
  return RequestService({
    url: "/commodity-movementsap/saveGlobalSwitcher",
    method: "PATCH",
    data: dataObj,
  });
};

export { getSwitcherPreferencesApi, updateSwitcherPreferencesApi };
