import { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Security } from "@okta/okta-react";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import {
  LoginComponent,
  oktaAuth,
  oktaOnAuthRequired,
  restoreOriginalUri,
  oktaConfig,
  errorConfig
} from "@customerexperience/cgl-dxo-dxp-fe-okta-libs";
import { clearOktaAuthInterval } from "@/utils";
import { AA_LoginEvent } from "@/monitor/adobe_analytics";
// 加载自定义国际化翻译以覆盖默认内置翻译
import * as i18n_login from "@/config/login.json";

import "./login.scss";
import FooterComponent from "./footerComponent";

interface State {
  text: string;
  loginLoading: boolean;
}

class Login extends Component<{}, State> {
  public readonly state = {
    text: "Login",
    loginLoading: false,
    footerInfo: {
      items: [
        { linkUrl: "", linkOpenCriteria: "", linkName: "© 2023 Cargill, Incorporated. 保留所有权利" },
        { linkUrl: "https://beian.miit.gov.cn/", linkOpenCriteria: "_blank", linkName: "沪ICP备15006728号-4" },
        { linkUrl: "https://www.cargill.com/privacy", linkOpenCriteria: "_blank", linkName: "隐私政策" },
        {
          linkUrl: "https://www.cargill.com/page/business-notice-zh",
          linkOpenCriteria: "_blank",
          linkName: "业务信息声明"
        }
      ],
      items_right: [
        { linkUrl: "", linkOpenCriteria: "", linkName: `当前版本 V ${process.env.REACT_APP_VERSION}` }
      ]
    }
  };

  componentDidMount() {
    document.title = "嘉吉供应链系统登录";
    // 登录页面强制清除浏览器缓存数据
    localStorage.clear();
    sessionStorage.clear();
    // 清除刷新session和token的计时器
    clearOktaAuthInterval();
  }

  // 相关web端分析插件初始化: AA-分析
  loginCallBackEvents = (event: any) => {
    // Adobe Analytics
    AA_LoginEvent(event);
  };

  public render() {
    const { footerInfo } = this.state;

    return (
      <>
        <div className="hdp-uf hdp-uf-hc hdp-uf-vc login-page-content">
          <div className="bg-shadow"></div>
          <div className="login-container">
            <div className="login-form">
              <Router basename="">
                <Security
                  oktaAuth={oktaAuth}
                  onAuthRequired={oktaOnAuthRequired}
                  restoreOriginalUri={restoreOriginalUri}
                >
                  <Switch>
                    <Route
                      path="/login"
                      component={() => (
                        <LoginComponent
                          i18n={{ ...(i18n_login as any).default }}
                          logo="/logo.png"
                          language="zh-CN"
                          oktaConfig={oktaConfig}
                          errorConfig={errorConfig}
                          statusCallback={this.loginCallBackEvents}
                          footerComponent={<FooterComponent />}
                        />
                      )}
                    />
                  </Switch>
                </Security>
              </Router>
            </div>
          </div>
          <div className="footer-container">
            <div className="hdp-uf hdp-uf-vc hdp-uf-hsb footer-content">
              <ul className="hdp-uf footer-left">
                {footerInfo.items.map((item: any) => {
                  return (
                    <li className="link-item" key={`footer-link-item-${item.linkName}`}>
                      {item.linkUrl ? (
                        <a href={item.linkUrl} target={item.linkOpenCriteria || "_blank"}>
                          {item.linkName}
                        </a>
                      ) : (
                        <span>{item.linkName}</span>
                      )}
                    </li>
                  );
                })}
                {/* TRUST ARC TAG */}
                <li className="link-item">
                  <div id="teconsent" className="footer-consent-link"></div>
                </li>
                <li className="link-item">
                  <div id="trustarc-irm-placeholder" className="footer-consent-link"></div>
                </li>
                <li className="link-item">
                  <div id="consent_blackbar" className="cookie-consent-banner"></div>
                </li>
              </ul>
              <div className="footer-right">
                <ul className="hdp-uf">
                  {footerInfo.items_right.map((item: any) => {
                    return (
                      <li className="link-item" key={`footer-link-item-${item.linkName}`}>
                        <span>{item.linkName}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
