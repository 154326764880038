import { useState, useEffect, useCallback } from "react";
import { Modal, message, Spin, Button } from "antd";
import { connect } from "react-redux";
import TimeDomSet from "./components/timeDomSet";
import "./index.scss";

import { getBookingDateDetailApi } from "@/api/slotbooking";

// 定义函数组件的props相关参数
type myProps = {
  modalTitle: string;
  basicInfo: any;
  userDetailsData: any;
  doClose: () => void;
};

const PreViewBookingModal = (props: myProps) => {
  const [dateDataLoading, setDateDataLoading] = useState(false);
  const [currentDateDetail, setCurrentDateDetail] = useState<any>({});
  const [currentDateItem, setCurrentDateItem] = useState<any>({});
  const [confirmDateAndTime, setConfirmDateAndTime] = useState({ dateStr: "", timeStr: "", ruleId: "" });

  useEffect(() => {
    // 获取可选择的日期列表
    const getBookingDateDetail = () => {
      const { basicInfo } = props;
      const paramsObj = {
        ruleId: basicInfo.id
      };
      setDateDataLoading(true);
      getBookingDateDetailApi(paramsObj)
        .then((responseJSON: any) => {
          if (responseJSON.code && responseJSON.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(responseJSON.errorMsg);
          } else if (responseJSON?.data) {
            const sourceData = responseJSON.data;
            if (sourceData && Object.keys(sourceData).length > 0) {
              let currentDateItemInitData = sourceData;
              setCurrentDateDetail(currentDateItemInitData);
              if (basicInfo.dateStr === currentDateItemInitData.dateStr) {
                setCurrentDateItem(mapCurrentDateItem(currentDateItemInitData));
                setConfirmDateAndTime({ dateStr: currentDateItemInitData.dateStr, timeStr: "", ruleId: "" });
              }
            }
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
        })
        .finally(() => {
          setDateDataLoading(false);
        });
    };
    getBookingDateDetail();
  }, [props]);

  // 拆分时间，区分凌晨/上午/下午/晚上
  const mapCurrentDateItem = (data: any) => {
    const newCurrentDateItem = {
      dateStr: data.dateStr,
      details: {}
    };
    const dataArr1 = [];
    const dataArr2 = [];
    const dataArr3 = [];
    const dataArr4 = [];
    for (let rowItem of data.details) {
      const item = rowItem || {};
      if (parseInt(item.timeStr.split(":")[0], 10) < 6) {
        dataArr1.push(item);
      } else if (parseInt(item.timeStr.split(":")[0], 10) >= 6 && parseInt(item.timeStr.split(":")[0], 10) < 12) {
        dataArr2.push(item);
      } else if (parseInt(item.timeStr.split(":")[0], 10) >= 12 && parseInt(item.timeStr.split(":")[0], 10) < 18) {
        dataArr3.push(item);
      } else {
        dataArr4.push(item);
      }
    }
    newCurrentDateItem.details = {
      dataArr1,
      dataArr2,
      dataArr3,
      dataArr4
    };
    return newCurrentDateItem;
  };

  // 取消或者关闭预约
  const handleClose = useCallback(() => {
    if (props.doClose) {
      props.doClose();
    }
  }, [props]);

  return (
    <>
      {/* 预约预览信息展示 */}
      <Modal
        title={props.modalTitle}
        width="75%"
        visible={true}
        maskClosable={false}
        onOk={handleClose}
        onCancel={handleClose}
        footer={[
          <Button data-testid="test-closePreview" key="submit" type="primary" onClick={handleClose}>
            关闭预览
          </Button>
        ]}
      >
        <div className="hdp-uf hdp-uf-dc booking-preview-content">
          <Spin spinning={dateDataLoading}>
            {/* 可预定的时间展示 */}
            <div className="hdp-uf hdp-uf-dc booking-datetime-container">
              <div className="hdp-uf hdp-uf-vc">
                <ul className="hdp-uf hdp-uf-ww ul-list">
                  {currentDateDetail && currentDateDetail.dateStr && (
                    <li
                      key={`booking_date_0`}
                      className="hdp-uf hdp-uf-hc hdp-uf-vc datetime-item datetime-item-on"
                      onClick={() => {}}
                      role="presentation"
                    >
                      <div>{currentDateDetail.dateStr}</div>
                    </li>
                  )}
                </ul>
                {props.basicInfo.loadingRule === "VA" ? (
                  <div className="total-book-num-new">
                    总共已预约数量<span className="span-book-num">{currentDateDetail.totalNumPackage || "-"}</span>
                    件,剩余可预约数量<span className="span-book-num"> {currentDateDetail.remainingBookNum || "-"}</span>
                    件,已预约<span className="span-book-num"> {currentDateDetail.totalBookNum || "-"}</span>车
                  </div>
                ) : (
                  <div className="total-book-num-new">
                    总计<span className="span-book-num">{currentDateDetail.totalNum || "-"}</span>车,已预约
                    <span className="span-book-num">{currentDateDetail.totalBookNum || "-"}</span>车
                  </div>
                )}
              </div>
              <div className="hdp-dash-line"></div>
              {/* 改日期下的具体可预约的时间展示 */}
              {confirmDateAndTime.dateStr && (
                <div className="hdp-uf hdp-uf-dc">
                  <div style={{ fontSize: "14px" }}>预约提货时间</div>
                  <div className="hdp-uf date-time-list" style={{ marginTop: "20px" }}>
                    {currentDateItem?.details?.dataArr1.length > 0 && (
                      <div className="hdp-uf hdp-uf-dc time-list-item" style={{ width: "25%" }}>
                        <div style={{ fontSize: "14px" }}>凌晨</div>
                        <ul className="hdp-uf hdp-uf-ww ul-list">
                          {currentDateItem.details.dataArr1.map((item: any, index: number) => (
                            <TimeDomSet
                              key={`time1_${item.timeStr}`}
                              loadingRule={props.basicInfo.loadingRule}
                              itemData={item}
                              index={index}
                            />
                          ))}
                        </ul>
                      </div>
                    )}
                    {currentDateItem?.details?.dataArr2.length > 0 && (
                      <div className="hdp-uf hdp-uf-dc time-list-item" style={{ width: "25%" }}>
                        <div style={{ fontSize: "14px" }}>上午</div>
                        <ul className="hdp-uf hdp-uf-ww ul-list">
                          {currentDateItem.details.dataArr2.map((item: any, index: number) => (
                            <TimeDomSet
                              key={`time2_${item.timeStr}`}
                              loadingRule={props.basicInfo.loadingRule}
                              itemData={item}
                              index={index}
                            />
                          ))}
                        </ul>
                      </div>
                    )}
                    {currentDateItem?.details?.dataArr3.length > 0 && (
                      <div className="hdp-uf hdp-uf-dc time-list-item" style={{ width: "25%" }}>
                        <div style={{ fontSize: "14px" }}>下午</div>
                        <ul className="hdp-uf hdp-uf-ww ul-list">
                          {currentDateItem.details.dataArr3.map((item: any, index: number) => (
                            <TimeDomSet
                              key={`time3_${item.timeStr}`}
                              loadingRule={props.basicInfo.loadingRule}
                              itemData={item}
                              index={index}
                            />
                          ))}
                        </ul>
                      </div>
                    )}
                    {currentDateItem?.details?.dataArr4.length > 0 && (
                      <div className="hdp-uf hdp-uf-dc time-list-item" style={{ width: "25%" }}>
                        <div style={{ fontSize: "14px" }}>夜间</div>
                        <ul className="hdp-uf hdp-uf-ww ul-list">
                          {currentDateItem.details.dataArr4.map((item: any, index: number) => (
                            <TimeDomSet
                              key={`time4_${item.timeStr}`}
                              loadingRule={props.basicInfo.loadingRule}
                              itemData={item}
                              index={index}
                            />
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userDetailsData: state.userDetails
});

export default connect(mapStateToProps, null)(PreViewBookingModal);
