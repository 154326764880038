import { Component } from "react";
import { Card, Table, Button, message, Modal } from "antd";
import { ArrowRightOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import "./index.scss";

import SearchFilters from "../../../components/searchFilters";
import GroupDetailModal from "../groupDetailModal";
import DoBookingModal from "../doBookingModal";

import { deviceWidth } from "@/utils";
import { getSlotBookingListApi, doBookingDateTimeApi } from "@/api/slotbooking";

const { confirm } = Modal;

interface Props {
  userPermissionsData: any;
  userDetails: any;
  onChangeTabName: (tabName: string) => void;
}

interface State {
  tableDataSource: object;
  pagination: object;
  selectedRowKeys: object;
  showGroupDetailModal: boolean;
  currentGroupNo: string;
  currentGroupCustomer: string;
  showDoBookingModal: boolean;
  currentBookingItemInfo: object;
  isTableLoading: boolean;
}

class PendingList extends Component<Props, State> {
  public readonly state = {
    tableDataSource: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showQuickJumper: true,
      size: deviceWidth() > 576 ? ("default" as any) : ("small" as any)
    },
    selectedRowKeys: [],
    showGroupDetailModal: false,
    currentGroupNo: "",
    currentGroupCustomer: "",
    showDoBookingModal: false,
    currentBookingItemInfo: {},
    isTableLoading: false
  };

  searchFiltersData: any = { status: "waiting" };
  urlParams = { limit: 10, offset: 0 };

  columnsData = () => {
    return [
      { title: "车/船号", dataIndex: "plateNo", key: "plateNo" },
      { title: "买方", dataIndex: "buyer", key: "buyer" },
      { title: "卖方", dataIndex: "siteName", key: "siteName" },
      { title: "产品名称", dataIndex: "productName", key: "productName" },
      { title: "包装规格", dataIndex: "packageSpecificationCn", key: "packageSpecificationCn" },
      {
        title: "提单组号",
        key: "groupNo",
        render: (rowData: any) => (
          <div
            className="active-field"
            onClick={() => this.doShowGroupDetailModal(rowData.groupNo, rowData.customerCode)}
          >
            {rowData.groupNo}
          </div>
        )
      },
      { title: "预约状态", dataIndex: "statusCn", key: "statusCn" },
      {
        title: "查看可预约时间",
        key: "action",
        align: "center" as "center",
        render: (rowData: any) => this.isEnableAction(rowData) ? (
          <div className="action-item do-booking-item" onClick={() => this.doBookingAction(rowData)}>
            <ArrowRightOutlined style={{ fontSize: "16px", color: "#005f86" }} />
          </div>
        ) : (
          <div className="action-item action-item-disabled">
            <ArrowRightOutlined style={{ fontSize: "16px", color: "#b2b2b2" }} />
          </div>
        )
      }
    ];
  };

  // 判断是否有预约权限
  isEnableAction = (rowData: any) => {
    const { userPermissionsData, userDetails } = this.props;
    if (rowData.appointmentMode !== "DISABLE") {
      if (rowData.orderType === "配送") {
        if (userDetails?.groups?.length > 0 && userPermissionsData["CNInternalUsers"]) {
          return (
            (userDetails.groups.indexOf("DXP_DSC_Role_CN_Cargill_CE") > -1 || userDetails.groups.indexOf("DXP_DSC_Role_CN_Cargill_CM") > -1) ||
            (userDetails.groups.indexOf("DXP_DSC_CN_Transport") > -1 && (rowData.appointmentMode === "ALL" || rowData.appointmentMode === "TRANSPORT"))
          );
        }
      } else {
        return (userPermissionsData["CNExternalUsers"] && ["CE", "DRIVER"].includes(rowData.appointmentMode)) || (userPermissionsData["CNInternalUsers"]);
      }
    }
    return false;
  };

  // 筛选条件查询处理 -- 默认条件加载初始化时会自动触发获取一次
  handleSearch = (value: any) => {
    console.log("PendingList-->handleSearch:", value);
    this.searchFiltersData = { ...this.searchFiltersData, ...value, status: "waiting" };
    this.urlParams = { ...this.urlParams, offset: 0 };
    this.getPendingDataList();
  };

  // 获取已预约列表数据
  getPendingDataList = () => {
    const { pagination } = this.state;

    let paginationTemp = pagination;
    if (this.urlParams.offset === 0 && pagination.current !== 1) {
      // 重置页码
      paginationTemp = { ...pagination, current: 1 };
      this.setState({ pagination: paginationTemp });
    }
    // 筛选条件
    const postDataObj = { ...this.searchFiltersData, status: "waiting" };

    this.setState({ isTableLoading: true });
    getSlotBookingListApi(this.urlParams, postDataObj)
      .then((responseJSON: any) => {
        if (responseJSON?.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
        } else if (responseJSON?.data) {
          const tableRecordData = Array.isArray(responseJSON.data.resultList) ? responseJSON.data.resultList : [];
          this.setState({
            tableDataSource: tableRecordData.map(this.mapTableData) || [],
            pagination: {
              ...paginationTemp,
              total: responseJSON.data.metadata?.totalCount ?? 0
            }
          });
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        this.setState({ tableDataSource: [] });
        this.setState({ pagination: { ...pagination, total: 0 } });
      })
      .finally(() => {
        this.setState({ isTableLoading: false });
      });
  };

  // 格式化列表数据
  mapTableData = (data: any) => ({
    ...data,
    // 展示需要的信息
    plateNo: data.plateNo || "-",
    buyer: data.buyer || "-",
    siteName: data.siteName || "-",
    productName: (this.props.userPermissionsData.CNExternalUsers ? data.materialSalesText : data.productName) || "-",
    packageSpecificationCn: data.packageSpecificationCn || "-",
    groupNo: data.groupNo ? data.groupNo : "-",
    statusCn: data.statusCn || "-"
  });

  // 处理table相关参数变化
  handleTableDataChange = (value: any) => {
    const { pagination } = this.state;
    if (value.pageSize !== pagination.pageSize) {
      // 每页显示的条数改变
      this.urlParams = { ...this.urlParams, offset: 0, limit: value.pageSize };
      this.setState(
        {
          pagination: { ...pagination, current: 1, pageSize: value.pageSize }
        },
        () => {
          // 获取新的数据
          this.getPendingDataList();
        }
      );
    } else if (value.current !== pagination.current) {
      // 当前页码改变
      this.urlParams = { ...this.urlParams, offset: value.pageSize * (value.current - 1), limit: value.pageSize };
      this.setState(
        {
          pagination: { ...pagination, current: value.current, pageSize: value.pageSize },
          selectedRowKeys: []
        },
        () => {
          // 获取新的数据
          this.getPendingDataList();
        }
      );
    }
  };

  // 选中的列表
  onSelectChange = (selectedRowKeys: any) => {
    this.setState({ selectedRowKeys });
  };

  // VIP预约，需选中某一条
  VIPBooking = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: "确认后车辆将停止预约，进入VIP通道。",
      onOk: () => {
        return this.handleVIPBooking();
      }
    });
  };

  // 执行VIP预约（内部 CE/CM）
  handleVIPBooking = () => {
    const { selectedRowKeys } = this.state;
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      const postDataObj = {
        ids: selectedRowKeys,
        dateStr: "",
        timeStr: "",
        ruleId: -1,
        instruction: "doApmtApply",
        remark: "vip",
        vipTag: "Y"
      };
      return doBookingDateTimeApi(postDataObj)
        .then((responseJSON: any) => {
          if (responseJSON.code && responseJSON.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(responseJSON.errorMsg);
          } else if (responseJSON?.data) {
            this.setState({ selectedRowKeys: [] }, () => {
              message.success("VIP预约成功。");
              // 更新table新的数据
              this.getPendingDataList();
            });
          }
        })
        .catch((exception: any) => {
          console.log(`Failure:${exception}`);
          message.error("VIP预约失败。");
        });
    }
  };

  // 查看提单组号详细信息
  doShowGroupDetailModal = (groupNoVal: string, groupCustomerVal: string) => {
    this.setState({
      currentGroupNo: groupNoVal,
      currentGroupCustomer: groupCustomerVal,
      showGroupDetailModal: true
    });
  };

  // 提单组号详细信息modal关闭
  doGroupDetailModalClose = () => {
    this.setState({
      currentGroupNo: "",
      currentGroupCustomer: "",
      showGroupDetailModal: false
    });
  };

  // 展示当前选择的预约
  doBookingAction = (currentItemData: any) => {
    this.setState({
      currentBookingItemInfo: currentItemData,
      showDoBookingModal: true
    });
  };

  // 处理关闭预约窗口
  doBookingModalClose = (isNeedRefreshList: boolean, arg_isRedirect?: boolean) => {
    this.setState({
      currentBookingItemInfo: {},
      showDoBookingModal: false
    });

    // 是否需要更新列表数据--设置了车型需要刷新｜...
    if (isNeedRefreshList) {
      this.getPendingDataList();
    }

    // 是否需要跳转到已预约列表
    if (arg_isRedirect) {
      this.props.onChangeTabName("scheduled-list");
    }
  };

  // Card组件的extra信息展示
  cardExtra = () => {
    const { pagination, selectedRowKeys } = this.state;
    const { userPermissionsData } = this.props;

    return (
      <div className="hdp-uf hdp-uf-vc card-extra">
        <div className="extra-total extra-item">
          <span>共 </span>
          <span>{pagination.total}</span>
          <span> 条</span>
        </div>
        {userPermissionsData["CNInternalUsers"] && (
          <div className="extra-item">
            <Button
              data-testid="test-doVipBooking"
              type="primary"
              onClick={this.VIPBooking}
              disabled={!selectedRowKeys.length}
            >
              VIP预约{selectedRowKeys.length > 0 && `(${selectedRowKeys.length})`}
            </Button>
          </div>
        )}
      </div>
    );
  };

  public render() {
    const {
      isTableLoading,
      selectedRowKeys,
      tableDataSource,
      pagination,
      showGroupDetailModal,
      showDoBookingModal,
      currentGroupNo,
      currentGroupCustomer,
      currentBookingItemInfo
    } = this.state;

    return (
      <div className="pending-list-container">
        <div className="hdp-uf hdp-uf-dc">
          {/* 查询条件区域 */}
          <SearchFilters doSearch={this.handleSearch} excludeFields={["status"]} />
          {/* table数据展示区域 */}
          <div className="pending-list-table-container">
            <Card
              style={{ boxShadow: "0 5px 9px #dedbdb" }}
              bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
              title="待预约列表"
              headStyle={{
                backgroundColor: "white",
                borderBottom: "none"
              }}
              extra={this.cardExtra()}
            >
              <Table
                size="small"
                loading={isTableLoading}
                rowSelection={{
                  type: "checkbox",
                  fixed: true,
                  selectedRowKeys,
                  getCheckboxProps: (record: any) => ({
                    disabled: record.loadingRule !== "SY"
                  }),
                  onChange: this.onSelectChange
                }}
                dataSource={tableDataSource}
                columns={this.columnsData()}
                pagination={pagination}
                onChange={this.handleTableDataChange}
                rowKey="id"
                scroll={{ x: "max-content" }}
              />
            </Card>
          </div>
          {/* Modals */}
          {showGroupDetailModal && (
            <GroupDetailModal
              groupNo={currentGroupNo}
              groupCustomer={currentGroupCustomer}
              doClose={this.doGroupDetailModalClose}
            />
          )}
          {showDoBookingModal && (
            <DoBookingModal
              modalTitle="立即预约"
              doClose={this.doBookingModalClose}
              basicInfo={currentBookingItemInfo}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  userPermissionsData: state.userPermissions,
  userDetails: state.userDetails
});

export default connect(mapStateToProps, null)(PendingList);
