import { useCallback, useEffect, useState } from "react";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import "./index.scss";

interface Props {
  data: any
}

const PrivacyGuard: React.FC<Props> = (props) => {
  const { data } = props;
  // 明文
  const [value, setValue] = useState<string>("");
  // 显示小眼睛
  const [show, setShow] = useState<boolean>(false);

  /**
   * 解密
   * @param data 
   */
  const decrypt = (data: any) => {
    if (data?.driverCard && data.driverCardSign) {
      // 获取尾部4位
      const lastFour = data.driverCard.slice(-4);
      // 计算*部分的明文
      const hiddenText = (data.driverCardSign / parseInt(lastFour));
      // 计算完整明文
      const allText = data.driverCard.replace(/\*+/, hiddenText);
      // const allText = data.driverCard.slice(0,6) + hiddenText + data.driverCard.slice(14);
      setValue(allText);
    }
  };

  /**
   * 处理明文与密文的切换
   */
  const handleShow = useCallback(() => {
    setShow(!show);
  }, [show]);

  useEffect(() => {
    decrypt(data);
  }, [data]);

  return <>
    <span className="privay-guard" onClick={handleShow}>
      {show ? value : data.driverCard }
      {show ? <EyeOutlined className="icon-eye" /> : <EyeInvisibleOutlined className="icon-eye" />}
    </span>
  </>
}

export default PrivacyGuard;