import { datadogRum } from "@datadog/browser-rum";
const { REACT_APP_ENV } = process.env;

// datadog监控数据信息 -- Sap数据源版
const setDatadogRum = () => {
  let applicationIdValue = "";
  let clientTokenValue = "";
  let serviceVal = "";
  if (REACT_APP_ENV === "stage") {
    applicationIdValue = "f97d342f-16f8-482d-bf1f-d20b70eb1a80";
    clientTokenValue = "pubca0a848bb53d97fb5b40bc9823f22e1c";
    serviceVal = "dsc-cn-portal-sap-stage";
  }
  // 启动 Datadog 监控
  if (applicationIdValue && clientTokenValue && serviceVal) {
    datadogRum.init({
      applicationId: applicationIdValue,
      clientToken: clientTokenValue,
      site: "datadoghq.com",
      service: serviceVal,
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: "mask-user-input"
    });
    datadogRum.startSessionReplayRecording();
  }
};

export { setDatadogRum };
