import { useState, useEffect, useCallback } from "react";
import { Modal, Button, message } from "antd";
import { resendContract } from "@/api/directSys";

const ResendModal = (props: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    if (props.selectedRow[0]) {
      if (props.selectedRow[0].resend && props.selectedRow.length === 1 && props.isSelected) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    } else {
      setButtonDisabled(true);
    }
  }, [props.selectedRow, props.isSelected]);

  const handleOk = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleResend = useCallback(() => {
    setLoading(true);
    const resendContracts = props.selectedRow[0].resend;
    if (resendContracts) {
      const request = { id: props.selectedRow[0].id };
      resendContract(request)
        .then(res => {
          if (res?.data?.result) {
            props.getListData();
            setIsModalVisible(true);
          } else {
            message.error(res?.data?.description);
          }
        })
        .catch(() => {
          message.error("重新发送失败，请稍后重试。");
        })
        .finally(() => setLoading(false));
    }
  }, [props]);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return (
    <>
      <Button
        data-testid="test-resend-btn"
        loading={loading}
        type={props.isText ? "text" : "primary"}
        disabled={buttonDisabled}
        onClick={handleResend}
      >
        重新发送
      </Button>
      <Modal
        title="提交成功"
        maskClosable={false}
        visible={isModalVisible}
        footer={
          <Button data-testid="test-ok" type="primary" onClick={handleOk}>
            确定
          </Button>
        }
        onCancel={closeModal}
      >
        提交成功，等待系统处理。
      </Modal>
    </>
  );
};
export default ResendModal;
