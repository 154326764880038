// slot-booking 查询条件
import { useState, useEffect, forwardRef, useCallback, useImperativeHandle } from "react";
import { Card, Form, Select, InputNumber, DatePicker, Button, message, Spin, Checkbox } from "antd";
import moment from "moment";
import "./index.scss";

import { getProductQueryApi } from "@/api/slotbooking";
import { convertUTCDateToChinaDateARBySeconds, formatValue } from "@/utils";

// 定义函数组件的props相关参数
type myProps = {
  get_time_window_config?: () => void;
  initData: any;
  reservationFiltersData: any;
  userDetailsData?: any;
  currentTotalMaximum: number;
  doResetEditData: () => void;
  doTimeWindowSubmit: (ruleConfigData: any) => void;
};

const TimeWindowConfigFunc = (props: myProps, ref: any) => {
  const [form] = Form.useForm(); // form表单实例
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(true);
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [specGroupOptions, setSpecGroupOptions] = useState<any[]>([]);
  const [isSelectFetching, setIsSelectFetching] = useState(false);
  const [efficiencyOptions, setEfficiencyOptions] = useState([
    { label: "30", value: 30 },
    { label: "45", value: 45 },
    { label: "60", value: 60 },
    { label: "90", value: 90 },
    { label: "120", value: 120 },
    { label: "180", value: 180 }
  ]);
  const [startTimeOptions, setStartTimeOptions] = useState<any[]>([]);
  const [endTimeOptions, setEndTimeOptions] = useState<any[]>([]);
  const [releaseTimeOptions, setReleaseTimeOptions] = useState<any[]>([]);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const [isDisableMaximum, setIsDisableMaximum] = useState<boolean>(true);

  useImperativeHandle(ref, () => ({
    getFormFields,
    handleResetForm
  }));

  const getFormFields = useCallback(() => {
    return { ...form.getFieldsValue(true) };
  }, [form]);

  // 只截取时间部分
  const mapTime = (dateTime: any) => {
    if (dateTime) {
      const chinaDateTime = convertUTCDateToChinaDateARBySeconds(dateTime);
      return `${chinaDateTime.time}`;
    }
    return dateTime;
  };

  // 获取相关字段的可选数据
  const getSuggestions = useCallback(
    (inputValue: any, id: string) => {
      const { reservationFiltersData } = props;
      const idsObj: any = {
        category: {
          queryType: "categorys",
          setFunc: setCategoryOptions
        },
        specGroup: {
          queryType: "specGroups",
          setFunc: setSpecGroupOptions
        }
      };

      const paramsObj = {
        vague: inputValue,
        orgCode: reservationFiltersData.orgCode,
        platform: reservationFiltersData.platform,
        queryType: idsObj[id].queryType
      };
      setIsSelectFetching(true);
      getProductQueryApi(paramsObj)
        .then((responseJSON: any) => {
          if (responseJSON.code && responseJSON.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(responseJSON.errorMsg);
            idsObj[id].setFunc([]);
          } else if (responseJSON?.data) {
            const resData = responseJSON.data?.[idsObj[id].queryType] || [];
            const newData = resData.map((item: any) => {
              return idsObj[id].queryType === "categorys"
                ? {
                  label: item.name,
                  value: item.name,
                  efficiency: item.efficiency,
                  key: `${item.name}_${item.efficiency}`
                }
                : { label: item.name, value: item.name };
            });
            idsObj[id].setFunc(newData);
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          idsObj[id].setFunc([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    [props]
  );

  // 获取产品类型可选列表
  const handleOptionsSearch = (field: string) => (eventValue?: string) => {
    getSuggestions(eventValue || "", field);
  };

  const onCategoryFocusSearch = useCallback(() => {
    getSuggestions("", "category");
  }, [getSuggestions]);

  const onSpecGroupFocusSearch = useCallback(() => {
    getSuggestions("", "specGroup");
  }, [getSuggestions]);

  const isMaximumDisabled = useCallback(() => {
    if (
      form.getFieldValue("category") &&
      form.getFieldValue("lineNumber") &&
      form.getFieldValue("time1") &&
      form.getFieldValue("time2")
    ) {
      setIsDisableMaximum(false);
    } else {
      setIsDisableMaximum(true);
    }
  }, [form]);

  // 动态计算和设置VA条件下今日最大发货件数的值
  const reSetMaximumField = useCallback(
    (type: number) => {
      const { currentTotalMaximum, reservationFiltersData, initData } = props;
      let newTotal = currentTotalMaximum;
      if (initData.maximum) {
        newTotal = currentTotalMaximum - parseInt(initData.maximum, 10);
      }
      if (type === 0 && form.getFieldValue("maximum")) {
        if (form.getFieldValue("category") !== "全部产品") {
          form.setFieldsValue({ maximum: "" });
        }
      } else if (form.getFieldValue("category") === "全部产品" || form.getFieldValue("category") === "普通产品") {
        if (
          reservationFiltersData &&
          Object.keys(reservationFiltersData).length > 0 &&
          reservationFiltersData.dateStrExtra.maximum
        ) {
          if (reservationFiltersData.dateStrExtra.maximum > newTotal) {
            const maximumValue =
              parseInt(reservationFiltersData.dateStrExtra.maximum, 10) - parseInt(newTotal.toString(), 10);
            form.setFieldsValue({ maximum: maximumValue });
          } else {
            form.setFieldsValue({ maximum: "" });
          }
        }
      } else {
        // 【全部商品和普通商品除外】计算公式：小时*效率*发货线数量
        const time1Arr = form.getFieldValue("time1").split(":");
        const time2Arr = form.getFieldValue("time2").split(":");
        const hourVal =
          parseInt(time2Arr[0], 10) -
          parseInt(time1Arr[0], 10) +
          (parseFloat(time2Arr[1]) - parseFloat(time1Arr[1])) / 60;
        const maximumValue =
          parseInt(form.getFieldValue("efficiency"), 10) * parseInt(form.getFieldValue("lineNumber"), 10) * hourVal;
        form.setFieldsValue({ maximum: maximumValue });
      }

      // 设置是否允许操作maximum的值
      isMaximumDisabled();
    },
    [form, isMaximumDisabled, props]
  );

  const doSetMaximumValue_VA = useCallback(() => {
    // 如果是VA需要自动计算该产品类今日最大发货件数的初始值，计算公式：小时*效率*发货线数量
    if (props.reservationFiltersData.loadingRule === "VA") {
      if (
        form.getFieldValue("category") &&
        form.getFieldValue("efficiency") &&
        form.getFieldValue("lineNumber") &&
        form.getFieldValue("time1") &&
        form.getFieldValue("time2")
      ) {
        reSetMaximumField(1);
      } else if (form.getFieldValue("maximum")) {
        reSetMaximumField(0);
      }
    }
  }, [form, props.reservationFiltersData.loadingRule, reSetMaximumField]);

  const onHandleChange = (field: string) => (selectionObj: any, currentOption: any) => {
    const { reservationFiltersData } = props;

    if (field === "category") {
      // 如果是VA需要自动提取 efficiency 并赋值
      if (reservationFiltersData.loadingRule === "VA" && currentOption) {
        form.setFieldsValue({ efficiency: currentOption.efficiency });
      }
      // VA 需要check 是否需要更新 maximum
      doSetMaximumValue_VA();
    }
  };

  // 判断是不是最近当前天和明天，只要日期为当前天或者明天的，并且是open的状态，就不能编辑
  const isCurrentEditOpenTowDays = () => {
    const { reservationFiltersData } = props;
    const toDayStr = moment().format("YYYY-MM-DD");
    const tomorrowStr = moment(new Date().getTime() + 1000 * 60 * 60 * 24).format("YYYY-MM-DD");
    if (reservationFiltersData.dateStr === toDayStr || reservationFiltersData.dateStr === tomorrowStr) {
      return reservationFiltersData.status === "OPEN" && isEdit;
    }
    return false;
  };

  //  提货开始时间和结束时间选择器 -- Time1
  const onSelectTime1Change = useCallback(
    (value: any) => {
      const { reservationFiltersData } = props;
      // 设置开始时间
      if (value && reservationFiltersData.dateStr) {
        form.setFieldsValue({ startTime: `${reservationFiltersData.dateStr} ${value}` });
        const currentIndex = startTimeOptions.findIndex(item => item.value === value);
        // 根据 time1，限制 time2 的可选项
        const endTimeOptionsArr = startTimeOptions.slice(currentIndex + 1, startTimeOptions.length);
        // time2 需要加入24最后的节点
        endTimeOptionsArr.push({ value: "24:00:00", label: "24:00" });
        setEndTimeOptions(endTimeOptionsArr);
        const currentValue = form.getFieldValue("time2")?.split(":");
        if (form.getFieldValue("time2") && checkNeedResetTime2(currentValue, value)) {
          form.setFieldsValue({ endTime: "", time2: null });
        }
      } else {
        form.setFieldsValue({ startTime: "" });
      }
      // VA 需要check 是否需要更新 maximum
      doSetMaximumValue_VA();
    },
    [doSetMaximumValue_VA, form, props, startTimeOptions]
  );

  const checkNeedResetTime2 = (currentValue: string[], value: string) => {
    return (
      parseInt(value?.split(":")?.[0], 10) > parseInt(currentValue?.[0], 10) ||
      (parseInt(value?.split(":")?.[0], 10) === parseInt(currentValue?.[0], 10) &&
        parseInt(value?.split(":")?.[1], 10) >= parseInt(currentValue?.[1], 10))
    );
  };

  //  提货开始时间和结束时间选择器 -- Time2
  const onSelectTime2Change = useCallback(
    (value: any) => {
      const { reservationFiltersData } = props;
      // 设置结束时间
      if (value && reservationFiltersData.dateStr) {
        form.setFieldsValue({ endTime: `${reservationFiltersData.dateStr} ${value}` });
      } else {
        form.setFieldsValue({ endTime: "" });
      }
      // VA 需要check 是否需要更新 maximum
      doSetMaximumValue_VA();
    },
    [doSetMaximumValue_VA, form, props]
  );

  // 默认生成时间选项
  const setTimeOptions = useCallback(
    (type?: string) => {
      const { reservationFiltersData } = props;

      const newTimeOptionsArr = [];
      let timeItem2 = {};
      let timeItem1 = {};
      for (let i = 0; i < 24; i += 1) {
        timeItem1 = { value: `${i < 10 ? "0" + i : i}:00:00`, label: `${i}:00` };
        timeItem2 = { value: `${i < 10 ? "0" + i : i}:30:00`, label: `${i}:30` };
        newTimeOptionsArr.push(timeItem1);
        newTimeOptionsArr.push(timeItem2);
      }
      if (type === "init") {
        setReleaseTimeOptions(newTimeOptionsArr);
        // 如果预设条件中选择的是当前天日期，则需要过滤掉开始时间中已经过去的时间
        const todayDateStr = moment().format("YYYY-MM-DD");
        if (todayDateStr === reservationFiltersData.dateStr) {
          const filterStartTimeOptions = newTimeOptionsArr.filter((item: any) => {
            const itemDateTime = new Date(`${moment().format("YYYY-MM-DD")} ${item.value}`).getTime();
            const currentDateTime = new Date().getTime();
            return itemDateTime > currentDateTime;
          });
          setStartTimeOptions(filterStartTimeOptions);
        } else {
          setStartTimeOptions(newTimeOptionsArr);
        }
      } else if (type === "reSetReleaseTimeOptions") {
        setReleaseTimeOptions(newTimeOptionsArr);
      }
    },
    [props]
  );

  // 处理预计发布日期和时间点  releaseDate 如果选择的当前天，需要过滤当前天已经过去的时间不可选择
  const releaseDateChange = useCallback(
    (date: any, dateString: string) => {
      const todayDateStr = moment().format("YYYY-MM-DD");
      if (todayDateStr === dateString) {
        const filterReleaseTimeOptions = releaseTimeOptions.filter((item: any) => {
          const itemDateTime = new Date(`${moment().format("YYYY-MM-DD")} ${item.value}`).getTime();
          const currentDateTime = new Date().getTime();
          return itemDateTime > currentDateTime;
        });
        setReleaseTimeOptions(filterReleaseTimeOptions);
      } else {
        // 当上一次选择的是当前日，切换其他日期时需要重新设置可选时间
        setTimeOptions("reSetReleaseTimeOptions");
      }
    },
    [releaseTimeOptions, setTimeOptions]
  );

  // 筛选可选时间
  const onCheckReleaseDateAble = useCallback((eventValue: moment.Moment) => {
    return eventValue && eventValue < moment().subtract(1, "days");
  }, []);

  // 条件下初始化获取车型可选项
  const getVehiclesOptions = () => {
    const paramsObj = {
      queryType: "vehicles"
    };
    getProductQueryApi(paramsObj)
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
          setVehicleTypeOptions([]);
        } else if (responseJSON?.data) {
          const vehiclesData = responseJSON.data.vehicles || [];
          const newData = vehiclesData.map((item: any) => {
            return { label: item.name, value: item.name };
          });
          setVehicleTypeOptions(newData);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        setVehicleTypeOptions([]);
      });
  };

  const handleMySubmit = useCallback(
    (value?: any) => {
      const myValues = { ...form.getFieldsValue(true) };
      // 将条件所有值传递给父组件
      if (props.doTimeWindowSubmit) {
        // 传递筛选的最终结果给到父级组件
        if (myValues.releaseDate) {
          myValues.releaseDate = moment(myValues.releaseDate).format("YYYY-MM-DD");
        }
        if (props.reservationFiltersData.loadingRule === "BR" && myValues.categories.length > 0) {
          myValues.category = myValues.categories.join(",");
          delete myValues.categories;
        }
        console.log("handleMySubmit:", myValues);
        props.doTimeWindowSubmit(myValues);
      }
    },
    [form, props]
  );

  const handleResetForm = useCallback(() => {
    form.resetFields();
    setTimeOptions("init");
    if (props.initData?.id && props.doResetEditData) {
      props.doResetEditData();
    }
  }, [form, props, setTimeOptions]);

  // 确定是否打开 category 选择
  const categorySelectDisabled = () => {
    return (
      isFormDisabled ||
      !props.reservationFiltersData.platform ||
      !props.reservationFiltersData.orgCode ||
      (isEdit && props.reservationFiltersData.status === "OPEN")
    );
  };

  // Similar to componentDidMount and componentDidUpdate
  // Just run the first time rendering
  useEffect(() => {
    setTimeOptions("init");
  }, [setTimeOptions]);

  useEffect(() => {
    const setDefaultTime2Options = (time1: string) => {
      const currentIndex = startTimeOptions.findIndex(item => item.value === time1);
      const endTimeOptionsArr = startTimeOptions.slice(currentIndex + 1, startTimeOptions.length);
      endTimeOptionsArr.push({ value: "24:00:00", label: "24:00" });
      setEndTimeOptions(endTimeOptionsArr);
    };

    if (props?.initData?.id) {
      setIsEdit(true);
      // 初始化待编辑的数据
      const isReleaseTime = props.initData.releaseTime && props.initData.releaseTime !== "-";
      form.setFieldsValue({
        id: formatValue(props.initData.id, ""),
        category: formatValue(props.initData.category, null),
        categories:
          props.reservationFiltersData.loadingRule === "BR" && props.initData.category
            ? props.initData.category.split(",")
            : [],
        specGroup: formatValue(props.initData.specGroup, null), // MG需要
        efficiency: formatValue(props.initData.efficiency, null),
        lineNumber: formatValue(props.initData.lineNumber, null),
        startTime: formatValue(props.initData.startTime, ""),
        endTime: formatValue(props.initData.endTime, ""),
        time1: formatValue(mapTime(props.initData?.startTime), null),
        time2: formatValue(mapTime(props.initData?.endTime), null),
        maximum: formatValue(props.initData.maximum, ""),
        vehicleType: formatValue(props.initData.vehicleType, null),
        releaseDate: isReleaseTime ? moment(props.initData.releaseTime.split(" ")[0], "YYYY-MM-DD") : null, // 发布时日期
        releaseTime: isReleaseTime ? props.initData.releaseTime.split(" ")[1] : null, // 发布时间
        noticeExt: props.initData?.noticeExt
      });

      // 设置time2的可选时间列表
      setDefaultTime2Options(mapTime(props.initData.startTime));
      // VA-需要设置是否允许操作maximum的值
      if (props.reservationFiltersData.loadingRule === "VA") {
        isMaximumDisabled();
      }
    } else {
      form.resetFields();
      setIsEdit(false);
    }

    if (
      props.reservationFiltersData.loadingRule === "MG" &&
      ["嘉吉粮油（日照）有限公司", "河北嘉好粮油有限公司"].includes(props.reservationFiltersData.orgName)
    ) {
      getVehiclesOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form,
    props.initData,
    props.reservationFiltersData,
    props.reservationFiltersData.loadingRule,
    props.reservationFiltersData.orgName
  ]);

  useEffect(() => {
    setCategoryOptions([]);
    // 如果是 “粕类发货平台”--MG 需要在 efficiencyOptions 添加75分钟的选项
    if (props.reservationFiltersData.platform === "粕类发货平台") {
      setEfficiencyOptions([
        { label: "30", value: 30 },
        { label: "45", value: 45 },
        { label: "60", value: 60 },
        { label: "75", value: 75 },
        { label: "90", value: 90 },
        { label: "120", value: 120 },
        { label: "180", value: 180 }
      ]);
    } else {
      setEfficiencyOptions([
        { label: "30", value: 30 },
        { label: "45", value: 45 },
        { label: "60", value: 60 },
        { label: "90", value: 90 },
        { label: "120", value: 120 },
        { label: "180", value: 180 }
      ]);
    }
  }, [form, props.reservationFiltersData.orgCode, props.reservationFiltersData.platform]);

  useEffect(() => {
    handleResetForm(); // Dom中也在调用此函数--待优化
    if (
      props.reservationFiltersData.orgCode &&
      props.reservationFiltersData.platform &&
      props.reservationFiltersData.dateStr
    ) {
      setIsFormDisabled(false);
    } else {
      setIsFormDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.reservationFiltersData.orgCode,
    props.reservationFiltersData.platform,
    props.reservationFiltersData.dateStr
  ]);

  return (
    <div className="time-window-config">
      <Card
        style={{ boxShadow: "0 5px 9px #dedbdb" }}
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        title="预约时间窗配置"
        headStyle={{
          backgroundColor: "white",
          borderBottom: "none",
          textAlign: "left"
        }}
      >
        <Form
          form={form}
          onFinish={handleMySubmit}
          name="timeWindowConfig_filters_form"
          layout="vertical"
          labelAlign="left"
          initialValues={{
            id: "",
            category: null,
            categories: [],
            specGroup: null, // MG需要
            efficiency: null,
            lineNumber: null,
            startTime: "",
            endTime: "",
            time1: null,
            time2: null,
            maximum: "",
            vehicleType: null,
            releaseDate: null, // 发布时日期
            releaseTime: null, // 发布时间
            noticeExt: true
          }}
        >
          <div className="hdp-uf hdp-uf-dc" style={{ width: "100%", paddingBottom: "16px" }}>
            <div className="hdp-uf hdp-uf-ww">
              {props.reservationFiltersData.loadingRule !== "BR" && (
                <Form.Item
                  shouldUpdate
                  label="选择产品类型"
                  name="category"
                  className="form-item"
                  rules={[{ required: true, message: "请选择产品类型" }]}
                >
                  <Select
                    data-testid="test-category-select"
                    showSearch
                    allowClear
                    placeholder="请输入产品类型查询"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    options={categoryOptions}
                    onSearch={handleOptionsSearch("category")}
                    onFocus={onCategoryFocusSearch}
                    onChange={onHandleChange("category")}
                    notFoundContent={isSelectFetching ? <Spin size="small" /> : null}
                    style={{ width: 200 }}
                    disabled={categorySelectDisabled()}
                  ></Select>
                </Form.Item>
              )}

              {props.reservationFiltersData.loadingRule === "BR" && (
                <Form.Item
                  shouldUpdate
                  label="选择产品类型"
                  name="categories"
                  className="form-item"
                  rules={[{ required: true, message: "请选择产品类型" }]}
                >
                  <Select
                    data-testid="test-category-select"
                    showSearch
                    allowClear
                    placeholder="请输入产品类型查询"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    mode="multiple"
                    maxTagCount="responsive"
                    options={categoryOptions}
                    onSearch={handleOptionsSearch("category")}
                    onFocus={onCategoryFocusSearch}
                    onChange={onHandleChange("category")}
                    notFoundContent={isSelectFetching ? <Spin size="small" /> : null}
                    style={{ width: 200 }}
                    disabled={categorySelectDisabled()}
                  ></Select>
                </Form.Item>
              )}

              {props.reservationFiltersData.loadingRule === "MG" && (
                <Form.Item
                  label="选择规格组"
                  name="specGroup"
                  className="form-item"
                  rules={[{ required: true, message: "请选择规格组" }]}
                >
                  <Select
                    data-testid="test-specGroup-select"
                    showSearch
                    allowClear
                    placeholder="请输入规格查询"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    options={specGroupOptions}
                    onSearch={handleOptionsSearch("specGroup")}
                    onFocus={onSpecGroupFocusSearch}
                    notFoundContent={isSelectFetching ? <Spin size="small" /> : null}
                    style={{ width: 200 }}
                    disabled={isFormDisabled || (isEdit && props.reservationFiltersData.status === "OPEN")}
                  ></Select>
                </Form.Item>
              )}

              {(props.reservationFiltersData.loadingRule === "MG" ||
                props.reservationFiltersData.loadingRule === "BR"
              ) && (
                  <Form.Item
                    label="提货效率（分钟/车）"
                    name="efficiency"
                    className="form-item"
                    rules={[{ required: true, message: "请选择提货效率" }]}
                  >
                    <Select
                      data-testid="test-efficiency-select"
                      placeholder="请选择提货效率"
                      options={efficiencyOptions}
                      style={{ width: 150 }}
                      disabled={isFormDisabled || (isEdit && props.reservationFiltersData.status === "OPEN")}
                    />
                  </Form.Item>
                )}

              <Form.Item
                label="发货线数量"
                name="lineNumber"
                className="form-item"
                rules={[{ required: true, message: "请输入发货线数量" }]}
              >
                <InputNumber
                  data-testid="test-lineNumber-input"
                  placeholder="请输入发货线数量"
                  min="1"
                  max="99"
                  precision={0}
                  onChange={doSetMaximumValue_VA}
                  style={{ width: 150 }}
                  disabled={
                    isFormDisabled || (isCurrentEditOpenTowDays() && props.reservationFiltersData.loadingRule !== "VA")
                  }
                />
              </Form.Item>

              <Form.Item
                label="提货开始时间"
                name="time1"
                className="form-item"
                rules={[{ required: true, message: "请选择提货开始时间" }]}
              >
                <Select
                  data-testid="test-time1-select"
                  allowClear
                  placeholder="开始时间"
                  options={startTimeOptions}
                  onChange={onSelectTime1Change}
                  style={{ width: 150 }}
                  disabled={isFormDisabled || !props.reservationFiltersData.dateStr}
                />
              </Form.Item>

              <Form.Item
                label="提货结束时间"
                name="time2"
                className="form-item"
                rules={[{ required: true, message: "请选择提货结束时间" }]}
              >
                <Select
                  data-testid="test-time2-select"
                  allowClear
                  placeholder="结束时间"
                  options={endTimeOptions}
                  onChange={onSelectTime2Change}
                  style={{ width: 150 }}
                  disabled={isFormDisabled || !form.getFieldValue("time1") || !props.reservationFiltersData.dateStr}
                />
              </Form.Item>

              {props.reservationFiltersData.loadingRule === "VA" && (
                <Form.Item
                  label="今日最大发货件数"
                  name="maximum"
                  className="form-item"
                  rules={[{ required: true, message: "请输入最大发货件数" }]}
                >
                  <InputNumber
                    placeholder="请输入最大发货件数"
                    min="1"
                    precision={0}
                    style={{ width: 150 }}
                    disabled={isFormDisabled || isDisableMaximum}
                  />
                </Form.Item>
              )}

              {props.reservationFiltersData.loadingRule === "MG" &&
                ["嘉吉粮油（日照）有限公司", "河北嘉好粮油有限公司"].includes(props.reservationFiltersData.orgName) && (
                  <Form.Item
                    label="发货车型"
                    name="vehicleType"
                    className="form-item"
                    rules={[{ required: true, message: "请选择发货车型" }]}
                  >
                    <Select
                      placeholder="请选择发货车型"
                      options={vehicleTypeOptions}
                      style={{ width: 150 }}
                      disabled={isFormDisabled}
                    />
                  </Form.Item>
                )}

              <Form.Item label="预计发布时间">
                <div className="hdp-uf">
                  <Form.Item name="releaseDate" className="form-item">
                    <DatePicker
                      placeholder="请选择日期"
                      style={{ width: 150 }}
                      disabledDate={onCheckReleaseDateAble}
                      disabled={isFormDisabled || (isEdit && props.reservationFiltersData.status === "OPEN")}
                      onChange={releaseDateChange}
                    />
                  </Form.Item>
                  <Form.Item name="releaseTime" className="form-item" style={{ marginLeft: "-12px" }}>
                    <Select
                      className="hdp-uf hdp-uf-hfe"
                      placeholder="请选择时间"
                      options={releaseTimeOptions}
                      style={{ width: 120 }}
                      disabled={isFormDisabled || (isEdit && props.reservationFiltersData.status === "OPEN")}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item name="noticeExt" valuePropName="checked" label=" ">
                <Checkbox>对外通知</Checkbox>
              </Form.Item>
            </div>
            <div className="hdp-uf hdp-uf-hfe window-config-actions">
              <Form.Item shouldUpdate className="form-item">
                <Button data-testid="test-window-config-reset" className="form-btn-reset" onClick={handleResetForm}>
                  重置
                </Button>
              </Form.Item>
              <Form.Item shouldUpdate className="form-item">
                {() => (
                  <Button
                    data-testid="test-window-config-submit"
                    type="primary"
                    htmlType="submit"
                    disabled={!props.userDetailsData.groups?.includes("DXP_DSC_Role_CN_Cargill_Planner")}
                  >
                    保存配置
                  </Button>
                )}
              </Form.Item>
            </div>
          </div>
        </Form>
      </Card>
    </div>
  );
};

const TimeWindowConfig = forwardRef(TimeWindowConfigFunc);

export default TimeWindowConfig;
