import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Drawer, List, Badge, Button, message } from "antd";
import { BellFilled, MailFilled } from "@ant-design/icons";
import { notificationList, notificationRead } from "@/api/constant";
import DownloadNotification from "./downloadNotification";
import * as notificationFlagActions from "@/store/actions/notificationFlag";
import "./index.scss";

const Notification = (props: any) => {
  const history = useHistory();
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationListData, setNotificationListData] = useState<any[]>([]);
  const [unReadCount, setUnReadCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [offsetCount, setOffsetCount] = useState(0);
  const { userPermissionsData, switcher } = props;

  const getNotificationList = useCallback(() => {
    const request = {
      limit: 10,
      offset: 0,
      type: "NOTIFICATIONCENTER",
      commodityCode: switcher.divisionCode === null || switcher.divisionCode === "All" ? "" : switcher.divisionCode,
      siteCode: switcher.orgCode === null || switcher.orgCode === "All" ? "" : switcher.orgCode,
      firmName:
        userPermissionsData["CNExternalUsers"] && switcher?.customerName?.split(",").length === 1
          ? switcher.customerName
          : null
    };
    notificationList(request).then(res => {
      setNotificationListData(res.data.notifications);
      setUnReadCount(res.data.metadata.unReadCount);
      setTotalCount(res.data.metadata.totalCount);
    });
  }, [switcher.customerName, switcher.divisionCode, switcher.orgCode, userPermissionsData]);

  const showNotification = () => {
    setNotificationVisible(true);
  };

  const closeNotification = useCallback(() => {
    setNotificationVisible(false);
  }, []);

  const handleNotificationRead = () => {
    const request = {
      flag: "ALL",
      state: "READ"
    };
    notificationRead(request)
      .then(res => {
        if (res.data.notifications) {
          getNotificationList();
        }
      })
      .catch((err: any) => {
        console.log(`notificationRead Failure: ${err}`);
      });
  };
  const backToTopClick = (e: any) => {
    e.preventDefault();
    const notificationList = document.querySelectorAll(".ant-drawer-body")[0];
    if (notificationList) {
      notificationList.scrollTop = 0;
    }
    e.stopPropagation();
  };
  const handleRedirect = (item: any) => (event?: any) => {
    notificationRead({
      id: item.alertId,
      state: "READ"
    })
      .then(() => {
        props.updateNotificationFlag();
        history.push({ pathname: handlePathName(item), state: { activeKey: handleActiveKey(item) } });
      })
      .catch((err: any) => {
        console.log(`notificationRead Failure: ${err}`);
      })
      .finally(() => setNotificationVisible(false));
  };

  const handleJumpPageStatus = (url: any, item: any) => (item.doStatus ? `${url}?status=${item.doStatus}` : `${url}`);

  const handlePathName = (item: any) => {
    let type;
    const alertCodesActionDelivery = [
      "101",
      "102",
      "201",
      "202",
      "301",
      "302",
      "501",
      "103",
      "601",
      "602",
      "603",
      "604",
      "605",
      "606"
    ];
    if (alertCodesActionDelivery.includes(item.alertCode)) {
      type = "deliveryorder";
    } else {
      type = "contract";
    }
    let redirectUrl;

    // 如果是外部用户，且type === "deliveryorder"，统一跳转到提货单列表(除slotbooking)
    if (userPermissionsData["CNExternalUsers"] && type === "deliveryorder") {
      // slotbooking为6开头
      if (item.redirect === "APPOINTMENT_WAIT_PAGE") {
        redirectUrl = handleJumpPageStatus("/main/slot-booking/booking", item);
      } else if (item.redirect === "APPOINTMENT_SUC_PAGE") {
        redirectUrl = handleJumpPageStatus("/main/slot-booking/booking", item);
      } else {
        redirectUrl = handleJumpPageStatus("/main/delivery/deliverylist", item);
      }
    } else {
      switch (item.redirect) {
        case "DO_ALL_CANCEL": {
          redirectUrl = handleJumpPageStatus("/main/delivery/deliveryletterlist", item);
          break;
        }
        case "DO_EDIT":
          redirectUrl = handleJumpPageStatus("/main/delivery/deliveryletterlist", item);
          break;
        case "DO_REJECT":
          redirectUrl = handleJumpPageStatus("/main/delivery/deliveryletterlist", item);
          break;
        // 增加点击被拒绝消息跳转的已拒绝页面
        case "DO_REPORT_LIST":
          redirectUrl = handleJumpPageStatus("/main/delivery/deliveryletterlist", item);
          break;
        // 增加点击消息的立即处理跳转到doreport页面
        case "IC_CARD_CANCEL_DO_CALLBACK":
          redirectUrl = handleJumpPageStatus("/main/delivery/deliverylist", item);
          break;
        case "DO_DELIVERY_TYPE_PS":
          redirectUrl = handleJumpPageStatus("/main/delivery/deliveryletterlist", item);
          break;
        default:
          redirectUrl = handleJumpPageStatus("/main/delivery/deliveryletterlist", item);
          break;
      }
    }
    return redirectUrl;
  };

  const handleAppendList = useCallback(() => {
    setLoading(true);
    const request = {
      limit: 10,
      offset: offsetCount + 10,
      type: "NOTIFICATIONCENTER",
      commodityCode: switcher.divisionCode === null || switcher.divisionCode === "All" ? "" : switcher.divisionCode,
      siteCode: switcher.orgCode === null || switcher.orgCode === "All" ? "" : switcher.orgCode,
      firmName:
        userPermissionsData["CNExternalUsers"] && switcher?.customerName?.split(",").length === 1
          ? switcher.customerName
          : null
    };
    notificationList(request)
      .then(res => {
        if (res.data.notifications) {
          setNotificationListData(notificationListData.concat(res.data.notifications));
          setOffsetCount(offsetCount + 10);
        } else {
          message.success("没有更多提醒");
        }
      })
      .catch((err: any) => {
        console.log(`notificationList Failure: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    notificationListData,
    offsetCount,
    switcher.customerName,
    switcher.divisionCode,
    switcher.orgCode,
    userPermissionsData
  ]);

  const handleActiveKey = (item: any) => {
    let type;
    const alertCodesActionDelivery = [
      "101",
      "102",
      "201",
      "202",
      "301",
      "302",
      "501",
      "103",
      "601",
      "602",
      "603",
      "604",
      "605",
      "606"
    ];
    if (alertCodesActionDelivery.includes(item.alertCode)) {
      type = "deliveryorder";
    } else {
      type = "contract";
    }
    let redirectUrl;

    // 如果是外部用户，且type === "deliveryorder"，统一跳转到提货单列表(除slotbooking)
    if (userPermissionsData["CNExternalUsers"] && type === "deliveryorder") {
      // slotbooking为6开头
      if (item.redirect === "APPOINTMENT_WAIT_PAGE") {
        redirectUrl = "pending-list";
      } else if (item.redirect === "APPOINTMENT_SUC_PAGE") {
        redirectUrl = "scheduled-list";
      } else {
        redirectUrl = null;
      }
    } else {
      switch (item.redirect) {
        case "DO_ALL_CANCEL": {
          redirectUrl = "1";
          break;
        }
        case "DO_EDIT":
          redirectUrl = "3";
          break;
        case "DO_REJECT":
          redirectUrl = "5";
          break;
        // 增加点击被拒绝消息跳转的已拒绝页面
        case "DO_REPORT_LIST":
          redirectUrl = "5";
          break;
        // 增加点击消息的立即处理跳转到doreport页面
        case "IC_CARD_CANCEL_DO_CALLBACK":
          redirectUrl = null;
          break;
        case "DO_DELIVERY_TYPE_PS":
          redirectUrl = "2";
          break;
        default:
          redirectUrl = "4";
          break;
      }
    }
    return redirectUrl;
  };

  useEffect(() => {
    getNotificationList();
  }, [switcher.divisionCode, switcher.orgCode, switcher.customerName, props.notificationFlag, getNotificationList]);

  const getRenderItem = useCallback((item: any, index?: number) => {
    const isIncludeAlertCode = [
      "101",
      "102",
      "201",
      "202",
      "301",
      "302",
      "501",
      "103",
      "601",
      "602",
      "603",
      "604",
      "605",
      "606"
    ].includes(item.alertCode);

    return (
      <List.Item
        extra={
          item.statusWeb !== "READ" && (
            <span style={{ top: 7, left: 4, width: 8, height: 8, backgroundColor: "#b55560", borderRadius: 4 }} />
          )
        }
      >
        <List.Item.Meta
          title={<span style={{ fontWeight: 600, fontSize: 12 }}>{item.description}</span>}
          description={
            <>
              <span style={{ fontSize: 12 }}>{item.message}</span>

              {isIncludeAlertCode && item.statusWeb === "READ" && (
                <Button
                  type="link"
                  style={{
                    fontSize: 12,
                    padding: 0,
                    marginLeft: 10,
                    width: "fit-content",
                    textDecoration: "underline"
                  }}
                >
                  <u>{item.gotToLinkText}</u>
                </Button>
              )}

              {isIncludeAlertCode && item.statusWeb !== "READ" && (
                <Button
                  data-testid="single-info"
                  type="link"
                  style={{ fontSize: 12, width: "fit-content", marginLeft: 10, padding: 0 }}
                  onClick={handleRedirect(item)}
                >
                  <u>{item.gotToLinkText}</u>
                </Button>
              )}

              {!isIncludeAlertCode && (
                <>
                  <span data-testid="download" onClick={handleRedirect(item)} style={{ marginLeft: 10 }}>
                    <DownloadNotification text={item.gotToLinkText} data={item.alertId} filename="appendix.xls" />
                  </span>
                  <div style={{ fontSize: 12 }}>发布人 {item.siteName === null ? "全部卖方" : item.siteName}</div>
                </>
              )}
              <div style={{ fontSize: 12 }}>通知时间 {item.receivedDate}</div>
            </>
          }
        />
      </List.Item>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="drawer-container">
      <div data-testid="open-btn" className="hdp-uf hdp-uf-vc hdp-uf-hc option-box" onClick={showNotification}>
        <div className="logout-text">通知</div>
        <div className="notify-icon">
          <Badge count={unReadCount} size="small">
            <BellFilled />
          </Badge>
        </div>
      </div>
      <div>
        <Drawer
          title={
            <>
              <h2 style={{ fontWeight: "bold" }}>通知</h2>
              <div
                data-testid="all-read"
                className="notification-header-status"
                style={{ fontSize: 16, fontWeight: 400, marginTop: 10 }}
                onClick={handleNotificationRead}
              >
                <MailFilled /> 全部标记为已读
              </div>
            </>
          }
          placement="right"
          onClose={closeNotification}
          visible={notificationVisible}
          maskStyle={{ backgroundColor: "rgba(1,1,1,0)" }}
          width={360}
          footer={
            <div
              data-testid="back-to-top"
              className="notifications-back-to-top"
              style={{ fontSize: 14, fontWeight: "bold" }}
              onClick={backToTopClick}
            >
              返回顶部
            </div>
          }
        >
          <List dataSource={notificationListData} renderItem={getRenderItem}></List>
          {offsetCount + 10 <= totalCount && userPermissionsData["CNExternalUsers"] && (
            <div style={{ textAlign: "center" }}>
              <Button type="link" style={{ fontSize: 12 }} onClick={handleAppendList} loading={loading}>
                加载更多
              </Button>
            </div>
          )}
        </Drawer>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  userPermissionsData: state.userPermissions,
  switcher: state.switcher,
  notificationFlag: state.notificationFlag
});
const mapDispatchToProps = (dispatch: (arg0: { type: string }) => void) => ({
  updateNotificationFlag: () => {
    dispatch(notificationFlagActions.updateNotificationFlag());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
