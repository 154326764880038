import { useCallback, useState } from "react";
import { Modal, InputNumber } from "antd";
import "./index.scss";

// 定义函数组件的props相关参数
type myProps = {
  basicInfo: any;
  modalTitle?: string;
  doClose: () => void;
  doActionOK: (value?: any, currentDateObj?: any) => void;
};

const MaximumSettingModal = (props: myProps) => {
  const [newValue, setNewValue] = useState<any>(); // 用于当前弹窗设置的值ß

  const onNewValueChange = useCallback((value: number) => {
    setNewValue(value);
  }, []);

  const handleOk = useCallback(() => {
    props.doActionOK(newValue, props.basicInfo);
    setNewValue("");
  }, [newValue, props]);

  const handleClose = useCallback(() => {
    setNewValue("");
    props.doClose();
  }, [props]);

  return (
    <Modal
      title={`${props.modalTitle || ""}`}
      className="setting-maximum"
      okText="确认"
      visible={true}
      maskClosable={false}
      onOk={handleOk}
      onCancel={handleClose}
      okButtonProps={{ disabled: !newValue }}
    >
      <div className="hdp-uf hdp-uf-dc setting-maximum-container">
        <InputNumber
          data-testid="test-maximum-input"
          defaultValue={props.basicInfo.dateStrExtra?.maximum || null}
          className="maximum-input"
          min={1}
          precision={0}
          onChange={onNewValueChange}
          placeholder="请输入当日最大提货件数"
          style={{ width: "200px" }}
        />
        <label className="tips">* 当前配置日期: {props.basicInfo.dateStr}</label>
      </div>
    </Modal>
  );
};

export default MaximumSettingModal;
