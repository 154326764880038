import { downloadLDC } from "@/api/directSys";
import { Button, message, Modal } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { downloadFile } from "@/utils";
import { useCallback, useState } from "react";
import { getFilename } from "@/utils/formatUtils";

interface Props {
  type?: string;
  filename?: string;
  text?: boolean;
  isEnabledAction: boolean;
  selectedRow: any;
  isText?: boolean;
  getListData: any;
}

const DownloadLDC = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const doDownloadLDC = useCallback(() => {
    setLoading(true);
    const requestArr: any = [];
    props.selectedRow.forEach((e: any) => {
      requestArr.push(e.settNo);
    });
    const requestStr = requestArr.join(",");
    downloadLDC({ settNo: requestStr })
      .then((response: any) => {
        if (response) {
          const filename = getFilename(response.headers);
          downloadFile(response.data, filename || "LDCReport.xls");
        } else {
          message.error("下载失败，请稍后重试。");
        }
      })
      .catch((exception: any) => {
        message.error("下载失败，请稍后重试。");
        console.log(exception);
      })
      .finally(() => {
        setLoading(false);
        setModalVisible(false);
        props.getListData();
      });
  }, [props]);

  const handleDownloadLDC = useCallback(() => {
    let arr: any = [];
    let downLoadFlag = true;
    const stateListTemp = ["待导出", "已导出", "已开票"];
    for (let rowItem of props.selectedRow) {
      if (!stateListTemp.includes(rowItem.settState)) {
        arr.push(rowItem.settNo);
        downLoadFlag = false;
      }
    }
    if (!downLoadFlag) {
      message.error(`结算单号为${arr}不符合导出条件`);
    } else {
      if (props.selectedRow[0].settState === "已导出" && props.selectedRow.length === 1) {
        setModalVisible(true);
      } else {
        doDownloadLDC();
      }
    }
  }, [doDownloadLDC, props.selectedRow]);

  const handleCloseModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  return (
    <div className="download-pdf">
      <Modal
        title="提示！"
        maskClosable={false}
        visible={modalVisible}
        onOk={doDownloadLDC}
        onCancel={handleCloseModal}
      >
        此结算单相关LDC模板文件系统检测为已导出，确认是否需再次导出此结算单的LDC文件？
      </Modal>
      <Button
        data-testid="downloadldc-btn"
        type={props.isText ? "text" : "primary"}
        loading={loading}
        icon={<VerticalAlignBottomOutlined />}
        onClick={handleDownloadLDC}
        disabled={!props.isEnabledAction}
      >
        导出为SAP LDC
      </Button>
    </div>
  );
};

export default DownloadLDC;
