// 提货单列表排序
export const DO_ASC = "modifiedDate;ASC";
export const DO_DESC = "modifiedDate;DESC";
export const DO_SORT_BY_ID_ASC = "doId;ASC";
export const DO_SORT_BY_ID_DESC = "doId;DESC";

export const DELIVERYBASISALL = [
  "ACM配送车",
  "VA配送车",
  "ACM自提车",
  "VA自提车",
  "ACM外库自提车",
  "ACM外库配送船",
  "VA外库自提车",
  "VA外库配送车",
  "ACM外江码头"
];
// 运输类型：配送
export const DELIVERYBASISDELIVERY = ["MFPS", "TRAIN", "CIF", "WHOUSE"];
// 运输类型：自提
export const DELIVERYBASISZT = ["EX-PLT", "CB", "FOBS", "CHUAN"];

// 下载文件名
export const CONTRACT_FILENAME = "ContractReport.xls";
export const DELIVERYLIST_FILENAME = "DoReport.xls";
export const ALLDOREPORT = "AllDoReport.xls";
export const ORDERSREPORT = "OrdersReport.xls";
export const EDITEDDOREPORT = "EditedDoReport.xls";
export const APPROVEDDOREPORT = "ApprovedDoReport.xls";
export const REJECTEDDOREPORT = "RejectedDoReport.xls";

// ws特殊物料('GOC-CLM01'：199975664、'GOC-SFM'、'GOC-SFM1'：199975863)
export const WSPRODUCTCODE = ["199975664", "199975863"];

// 类型
export const CONTRACT_TYPE = "report";
export const OLD_CONTRACT_TYPE = "oldreport";
export const DELIVERYLIST_TYPE = "doReport";
export const DELIVERYLETTERLIST_TYPE = "deliveryOrder";

export const LIMIT_CDP_CONTRACT = 100;
export const LIMIT_MAX_CONTRACT = 100000;

export const LIMIT = 10;
export const OFFSET = 0;

export const IS_FEED_FACTORY = "是否为饲料厂用户";
export const FEED_NAME = "用户目的地";
export const FEED_TAG = "FEED_TAG";
export const FEED_MODE = "FEED_MODE";

export const DO_EDIT_BY_USER = "do_editByUser";
export const DO_APPROVED_BY_USER = "do_approveByUser";
export const DO_CANCELLED_BY_EXTERNAL_USER = "do_cancelledByExternalUser";

export const ALERT_CODE_DO_REPORT_LIST_DO = "501";
export const NOTICE_LOCATION = "以卖方指定地点为准";
export const DO_APPLICATION_CREATED_DATE = "提货单提交日期";

export const SITE_CODE_NAN = "嘉吉粮油（南通）有限公司";
export const COMMODITY_CODE_BR = "基础精炼业务";
export const COMMODITY_CODE_VA = "增值油业务";
export const COMMODITY_CODE_MG = "谷物油籽业务";

export const ALL_DO = "allDo";
export const DELIVERY_DO = "deliveryDo";
export const APPROVED_DO = "approveDo";
export const REJECT_DO = "rejectDo";
export const EDITED_DO = "editDo";
export const OVERRIDE_DO = "overrideDo";
export const UPDATE_BY_USER = "updateByUser";
export const SUBMIT_DO = "submitPreview";
export const DELETE_DO = "deleteDo";
export const CANCEL_DO = "cancelDo";
export const WITHDRAW_DO = "withdrawDo";
export const CANCELLING = "待取消";
export const APPROVE_CANCEL_DO = "approveCancelDo";
export const REJECT_CANCEL_DO = "rejectCancelDo";

export const GROUP_USER = "GROUP";
export const INDIVIDUAL_USER = "INDIVIDUAL";
