import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button } from "antd";
import { crossBorderTransferNoticeConfig } from "./constant";

import "./index.scss";

interface Props extends RouteComponentProps { }
interface State {}

class CrossBorderTransferNotice extends Component<Props, State> {

  componentDidMount() {
    document.title = "个人信息出境告知书";
  }

  goBack = () => {
    //返回上一个链接
    this.props.history.goBack();
  };

  public render() {
    return (
      <div className="hdp-uf hdp-uf-hc hdp-uf-vc cross-border-transfer-notice-page-container">
        <div className="hdp-uf hdp-uf-dc page-content">
          <div className="hdp-uf hdp-uf-dc">
            <div className="page-title">{crossBorderTransferNoticeConfig.data.labels.heading}</div>
            <p
              className="rich-text-info"
              dangerouslySetInnerHTML={{ __html: crossBorderTransferNoticeConfig.data.pageContent.richText }}
            />
          </div>
        </div>
        <div className="page-footer">
          <div className="hdp-uf hdp-uf-hc footer-content">
            <div className="hdp-uf hdp-uf-dc footer-actions">
              <div className="hdp-uf hdp-uf-hfe action-go-back">
                <Button data-testid="btn-go-back" type="primary" onClick={this.goBack}>
                  {crossBorderTransferNoticeConfig.data.labels.goBackButtonText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CrossBorderTransferNotice;
