import RequestService from "@/api/request";
import axios from "axios";

// 获取TankId
const getTankIds = (dataObj: object) =>
  RequestService({
    url: `/contracts/tankIds`,
    method: "get",
    params: dataObj
  });

// 获取Unilab
const getUnilab = (dataObj: object) =>
  RequestService({
    url: `/contractsap/sap/unilab`,
    method: "get",
    params: dataObj
  });

// 获取合同详情
const getContractDetailsByTypeVague = (dataObj: object) =>
  RequestService({
    url: "/contractsap/sap/contractDetails?type=vagueContractDetail",
    method: "get",
    params: dataObj
  });

// 获取批次
const getBatchNosByContract = (dataObj: object) =>
  RequestService({
    url: "/contracts/batchs?type=contractDetail",
    method: "get",
    params: dataObj
  });

// 获取车船号
const getTruckMessage = (dataObj?: object) => {
  return RequestService({
    url: `commodity-movements/history?searchTerm=&type=truckMessage`,
    method: "get",
    data: dataObj
  });
};

// 获取司机姓名
const getDriverMessage = (dataObj?: object) => {
  return RequestService({
    url: `commodity-movements/history?searchTerm=&type=driverMessage`,
    method: "get",
    data: dataObj
  });
};

// 获取备注
const getUserRemark = (dataObj: object) => {
  return RequestService({
    url: `commodity-movements/history?type=userRemark`,
    method: "get",
    params: dataObj
  });
};

const getCIDOSContracts = (dataObj: object) => {
  return RequestService({
    url: `/contracts/cidosContracts/`,
    method: "get",
    params: dataObj
  });
};

const getDeliveryOrderById = (dataObj: object) => {
  return RequestService({
    url: `/commodity-movementsap/getDeliveryOrderById/`,
    method: "get",
    params: dataObj
  });
};

const queryDetail = (dataObj: object) => {
  return RequestService({
    url: `/commodity-movementsap/autoExamine/queryDetail`,
    method: "get",
    params: dataObj,
    cancelToken: axios.CancelToken.source().token
  });
};

const queryReamin = (dataObj: object) => {
  return RequestService({
    url: `/commodity-movementsap/autoExamine/queryRemain`,
    method: "post",
    data: dataObj,
    cancelToken: axios.CancelToken.source().token
  });
}

const forceLock = (dataObj: object) => {
  return RequestService({
    url: `/commodity-movementsap/autoExamine/forceLock`,
    method: "post",
    data: dataObj
  });
}

export {
  getTankIds,
  getUnilab,
  getDeliveryOrderById,
  getContractDetailsByTypeVague,
  getBatchNosByContract,
  getTruckMessage,
  getDriverMessage,
  getUserRemark,
  getCIDOSContracts,
  queryDetail,
  queryReamin,
  forceLock
};
