import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Col, Row, Select, Input, Button, Collapse, Divider, message } from "antd";
import { FormInstance } from "antd/es/form";
import { SearchOutlined } from "@ant-design/icons";
import { allSupplier, allBusinessLine, pickUpMethodOptions } from "@/utils/constant";
import RemoteSelect from "@/pages/common/remoteSelect";
import { getLocalStorageItem } from "@/utils";
import { getCode, isHaveRolePermissionFeedFactory, noRepeat } from "@/utils/common";
import { getProductDetailList, getSapFirmDetailsList } from "@/api/contractReport";
import { getFirmDetailsList } from "@/api/constant";

const { Panel } = Collapse;

interface MyProps {
  refGo: (availableProperties: any) => void;
  permission: any;
  userDetails: any;
  switcher: any;
  doSearch: (searchVal?: any) => void;
}

interface MyState {
  expandCollapseText: string; // 更多条件文字
  isExpand: boolean; // 是否展开更多条件
  searchBtnAction: boolean; // 查询的按钮是否激活
  isFetchLoading: boolean; // 是否正在查询数据
  groupUserCustomerOptions: any[]; // 需要测试下（group用户和非group用户的筛选条件场景是否OK)--外部Group用户可选公司数据
  customerCodes: any[]; // 外部用户-switcher中公司的选择数据
  customers: any[]; // 外部Group用户请求回的客户公司数据--getFirmDetailsList
}

class ContractSearchFilter extends Component<MyProps, MyState> {
  public readonly state = {
    expandCollapseText: "展开高级筛选",
    isExpand: false,
    searchBtnAction: false,
    isFetchLoading: false,
    groupUserCustomerOptions: [],
    customerCodes: [],
    customers: []
  };
  formRef = React.createRef<FormInstance>();

  componentDidUpdate(preProps: any) {
    const { switcher } = this.props;
    if (
      switcher?.customerCode !== preProps.switcher.customerCode ||
      switcher?.divisionCode !== preProps.switcher.divisionCode ||
      switcher?.orgCode !== preProps.switcher.orgCode
    ) {
      this.doUpdatePropsToState();
    }
  }

  componentDidMount() {
    const { permission } = this.props;
    const { isGroupUser } = this.returnUserInfoValue();
    if (permission.CNExternalUsers) {
      if (isGroupUser && !isHaveRolePermissionFeedFactory()) {
        this.getGroupCustomerOptions();
      }
    }
    this.doUpdatePropsToState();
    // 绑定自定义的 RefGo，可将要暴露给父级组件的相关属性汇总在此处
    this.props.refGo({
      resetAdvanceSearch: this.resetAdvanceSearch
    });
  }

  doUpdatePropsToState = () => {
    const { switcher, permission } = this.props;
    let newSearchValue: any = {};
    if (permission.CNExternalUsers) {
      // 外部用户特殊处理逻辑
      this.switcherChangeExternalUser(newSearchValue);
      this.setState({ searchBtnAction: true });
    } else {
      // 内部用户
      newSearchValue.orgCode = switcher?.orgCode === "All" || !switcher?.orgCode ? null : switcher?.orgCode;
      newSearchValue.divisionCode =
        switcher?.divisionCode === "All" || !switcher?.divisionCode ? null : switcher?.divisionCode;
      newSearchValue.customerCode = switcher?.customerCode;

      // switcher 数据更新后需要重新设置form表单的字段默认值
      this.formRef.current?.setFieldsValue({
        orgCode: newSearchValue.orgCode,
        divisionCode: newSearchValue.divisionCode,
        customerCode: newSearchValue.customerCode
      });
    }
    // 更新查询数据
    this.doSubmitSearch();
  };

  switcherChangeExternalUser = (value: any) => {
    const {
      switcher,
      userDetails: { entitlements }
    } = this.props;
    const { FEED_MODEL } = this.returnUserInfoValue();

    value.shipmentMethod = "自提";
    const customerCodes = switcher?.customerCode?.split(",") || [];
    this.setState({ customerCodes: customerCodes });
    // 外部用户--卖方和业务线此处默认情况显示“All”
    this.formRef.current?.setFieldsValue({
      orgCode: switcher.orgCode || "All",
      divisionCode: switcher.divisionCode || "All",
      customerCode: customerCodes.length > 1 ? "全部买方" : `${switcher.customerName}(${switcher.customerCode})`
    });
    value.orgCode = switcher.orgCode || "All";
    value.divisionCode = switcher.divisionCode || "All";

    const {
      otherControls: { companyAccountType }
    } = entitlements[0];

    let buyerList = switcher.customerCode;
    const firmNamesList = switcher.customerCode?.trim().split(",");
    if (firmNamesList?.length > 1 && entitlements?.length > 1 && companyAccountType === "GROUP") {
      const tempEntitlements = entitlements.slice(1);
      const entitlementsList = tempEntitlements.map(
        (item: any) => `${item.otherControls.organizationName}(${item.controlIdentifier})`
      );
      const temp = [];
      for (let listItem of firmNamesList) {
        if (entitlementsList.indexOf(listItem) === -1) {
          temp.push(listItem);
        }
      }
      buyerList = temp.toString();
    }
    value.customerCode = buyerList;

    if (isHaveRolePermissionFeedFactory()) {
      this.formRef.current?.setFieldsValue({ customerCode: FEED_MODEL?.feedName });
    }

    return value;
  };

  // 获取全局信息数据
  returnUserInfoValue = () => {
    const { userDetails } = this.props;
    let isGroupUser: boolean = false;
    let accountNumberVal: string = "";

    const localEnumInfo: string = getLocalStorageItem("localEnumInfo");
    const { FEED_MODEL } = localEnumInfo && JSON.parse(localEnumInfo);

    const userDataCheck = userDetails && Object.keys(userDetails)?.length && userDetails.entitlements;
    if (userDataCheck) {
      const {
        entitlements: [entitlements]
      } = userDetails;
      const {
        otherControls: { companyAccountType },
        controlIdentifier
      } = entitlements;
      if (companyAccountType === "GROUP") {
        isGroupUser = true;
        accountNumberVal = controlIdentifier;
      }
    }
    return { FEED_MODEL, isGroupUser, accountNumberVal };
  };

  // 高级筛选展开收起
  handelExpandCollapse = () => {
    const { isExpand } = this.state;
    this.setState({
      isExpand: !isExpand,
      expandCollapseText: isExpand ? "展开高级筛选" : "收起高级筛选"
    });
  };

  // 外部Group用户直接默认还在可选客户公司数据
  getGroupCustomerOptions = async () => {
    const { switcher } = this.props;
    const { customerCode } = this.formRef.current?.getFieldsValue(true);

    let buyer = customerCode;
    if (buyer) {
      const firmNamesList = customerCode
        ?.toString()
        ?.trim()
        .split(",");
      if (firmNamesList?.length > 1) {
        buyer = "";
      }
    }
    this.setState({ isFetchLoading: true, groupUserCustomerOptions: [] }, async () => {
      let newOptionsVal: any = [];
      await this.doGetGroupFirmDetailsList();
      const { customers } = this.state;
      if (customerCode && customerCode !== switcher.customerCode && customerCode !== "全部买方") {
        newOptionsVal = customers.filter((val: any) => {
          return val.label.includes(buyer);
        });
      } else {
        newOptionsVal = customers;
      }
      this.setState({ groupUserCustomerOptions: newOptionsVal, isFetchLoading: false });
    });
  };

  // 外部-Group用户请求公司可选列表
  doGetGroupFirmDetailsList = async () => {
    const { accountNumberVal } = this.returnUserInfoValue();
    // 外部GROUP用户特有逻辑
    await getFirmDetailsList({ accountNumber: accountNumberVal })
      .then((res: any) => {
        if (res.data?.customerInfoEntitlement?.length > 0) {
          let options = res.data.customerInfoEntitlement.map((val: any) => {
            return {
              label: `${val.accountLongName}(${val.accountNumber})`,
              value: `${val.accountLongName}(${val.accountNumber})`,
              key: val.accountNumber,
              name: val.accountLongName
            };
          });
          options.unshift({ label: "全部买方", value: "全部买方" });
          this.setState({ customers: options });
        }
      })
      .catch((exception: any) => {
        console.log(`doGetGroupFirmDetailsList Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      });
  };

  onCustomerFilterOption = (inputValue: any, option: any) => {
    return option?.value?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1;
  };

  // 内部用户--获取买方选项(防抖输入查询)
  fetchCustomerOptions_Internal = (value: string, callback: Function, currentValue: string | undefined) => {
    const { orgCode, divisionCode } = this.formRef.current?.getFieldsValue(true);

    this.setState({ isFetchLoading: true });
    getSapFirmDetailsList({
      customerName: value || "",
      orgCode: orgCode || "",
      divisionCode: divisionCode || ""
    })
      .then((res: any) => {
        if (res?.code === "99999") {
          // 99999 提示后端的错误信息
          callback([]);
          message.error(res.errorMsg);
        } else if (res.data?.customerList?.length > 0 && currentValue === value) {
          let data: any[] = res.data?.customerList?.map((val: any) => {
            return {
              label: val.customerName + "(" + val.customerCode + ")",
              value: val.customerName + "(" + val.customerCode + ")",
              key: val.customerCode
            };
          });
          callback(data);
        } else {
          callback([]);
        }
      })
      .catch(() => {
        callback([]);
      })
      .finally(() => {
        this.setState({ isFetchLoading: false });
      });
  };

  // 获取产品名称选项
  fetchProductNameOptions = (value: string, callback: Function, currentValue: string | undefined) => {
    const { permission } = this.props;
    const { orgCode } = this.formRef.current?.getFieldsValue(true);

    this.setState({ isFetchLoading: true });
    getProductDetailList({
      salesOrgs: orgCode || "",
      productName: value || ""
    })
      .then((res: any) => {
        if (res?.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else if (res.data?.products && currentValue === value) {
          let products: any = [];
          if (res.data.products?.length === 0) {
            message.error("No Results Found");
          }
          if (permission.CNExternalUsers) {
            products = res.data.products.map((val: any) => {
              return {
                label: val.productName,
                value: val.productName,
                key: val.productCode
              };
            });
            products = noRepeat(products);
          } else if (permission.CNInternalUsers) {
            products = res.data.products.map((item: any) => {
              return {
                label: `${item.productName}(${item.productCode.replace(/\b(0+)/gi, "")})`,
                value: `${item.productName}(${item.productCode.replace(/\b(0+)/gi, "")})`,
                key: item.productCode.replace(/\b(0+)/gi, "")
              };
            });
          }
          callback(products);
        }
      })
      .catch(() => {
        callback([]);
      })
      .finally(() => {
        this.setState({ isFetchLoading: false });
      });
  };

  // 获取 Form changeValue 的相关数据
  formValueChange = (changeValue: any, allValues: any) => {
    const { permission, switcher } = this.props;

    if (permission.CNExternalUsers) {
      if (allValues.customerCode && allValues.customerCode !== "全部买方") {
        allValues.customerCode = getCode(allValues.customerCode);
      } else {
        allValues.customerCode = switcher.customerCode;
      }
    }

    this.doSetSearchBtnAction(allValues);
  };

  // 设置查询按钮激活状态
  doSetSearchBtnAction = (values: any) => {
    // 更新查询按钮是否可点击的状态
    const commonConditions = values.divisionCode && values.customerCode && values.orgCode;
    this.setState({ searchBtnAction: commonConditions ?? false });
  };

  // 清空查询条件
  resetSearch = () => {
    const {
      switcher: { customerCode, divisionCode, orgCode },
      permission
    } = this.props;

    this.formRef.current?.resetFields();

    if (permission.CNExternalUsers) {
      this.setCNExternalUsers();
    } else {
      const orgCodeVal = !orgCode || orgCode === "All" ? null : orgCode;
      const divisionCodeVal = !divisionCode || divisionCode === "All" ? null : divisionCode;
      this.formRef.current?.setFieldsValue({
        orgCode: orgCodeVal,
        divisionCode: divisionCodeVal,
        customerCode: customerCode || null
      });
    }

    this.doSetSearchBtnAction(this.formRef.current?.getFieldsValue(true));
    this.doSubmitSearch();
  };

  // 清空高级筛选条件
  resetAdvanceSearch = () => {
    this.formRef.current?.resetFields(["contractNo", "batch", "packageSpecification"]);
    this.formRef.current?.setFieldsValue({shipmentMethod: ""});
  };

  // 抽取resetSearch方法功能块--外部用户处理逻辑
  setCNExternalUsers = () => {
    const { customerCode, divisionCode, orgCode } = this.props.switcher;
    const { customerCodes } = this.state;

    this.formRef.current?.setFieldsValue({
      shipmentMethod: "自提",
      orgCode: orgCode || null,
      divisionCode: divisionCode || null,
      customerCode: customerCodes?.length > 1 ? "" : customerCode
    });
  };

  // 执行查询--submit
  doSubmitSearch = (formValues?: any) => {
    const { permission, switcher, doSearch } = this.props;

    const values = { ...this.formRef.current?.getFieldsValue(true) };
    if (permission.CNInternalUsers) {
      // 内部用户处理买方和产品名称
      values.customerCode = getCode(values.customerCode);
      values.productCode = getCode(values.productCode);
      if (values.divisionCode === "All") {
        values.divisionCode = "";
      }
    } else if (permission.CNExternalUsers) {
      if (values.customerCode && values.customerCode !== "全部买方") {
        values.customerCode = getCode(values.customerCode);
      } else {
        values.customerCode = switcher.customerCode;
      }
    }

    if (doSearch) {
      doSearch(values);
    }
  };

  public render() {
    const { permission, switcher } = this.props;
    const {
      isFetchLoading,
      groupUserCustomerOptions,
      customerCodes,
      searchBtnAction,
      isExpand,
      expandCollapseText
    } = this.state;
    const { FEED_MODEL, isGroupUser } = this.returnUserInfoValue();

    return (
      <div className="report-filter">
        <Form
          ref={this.formRef}
          layout="vertical"
          autoComplete="off"
          onFinish={this.doSubmitSearch}
          onValuesChange={this.formValueChange}
          initialValues={{
            divisionCode: null,
            orgCode: null,
            customerCode: null,
            shipmentMethod: permission.CNExternalUsers ? "自提" : "",
            location: isHaveRolePermissionFeedFactory() ? FEED_MODEL?.feedName : ""
          }}
        >
          <Row className="flex-row-class" gutter={{ xs: 8, md: 16 }}>
            <Col xs={24}>
              <Form.Item name="orgCode">
                <Select
                  data-testid="orgCode"
                  placeholder="请选择卖方"
                  style={{ width: "100%" }}
                  disabled={permission.CNExternalUsers && switcher.orgCode && switcher.orgCode !== "All"}
                  options={permission.CNExternalUsers ? allSupplier() : allSupplier(["All"])}
                />
              </Form.Item>
            </Col>
            {/* 内部用户--选择买方 */}
            {permission && permission.CNInternalUsers && (
              <Col xs={24}>
                <Form.Item name="customerCode">
                  {/* 必须选择条目进行查询，所以此处用select而不是AutoComplete */}
                  <RemoteSelect
                    dataTestId="customerCode"
                    placeholder="请输入公司名称查询"
                    fetchOptions={this.fetchCustomerOptions_Internal}
                    loading={isFetchLoading}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}
            {/* 外部用户--且为集团账户的买方输入框,当前不用判断是否为饲料厂用户 */}
            {permission?.CNExternalUsers && isGroupUser && !isHaveRolePermissionFeedFactory() && (
              <Col xs={24}>
                <Form.Item name="customerCode">
                  <Select
                    data-testid="customerCode"
                    showSearch
                    placeholder="请选择公司"
                    optionFilterProp="children"
                    options={groupUserCustomerOptions}
                    filterOption={this.onCustomerFilterOption}
                    disabled={customerCodes.length <= 1}
                  />
                </Form.Item>
              </Col>
            )}
            {/* 外部用户--非Group用户*/}
            {permission?.CNExternalUsers && !isGroupUser && !!groupUserCustomerOptions.length && (
              <Col xs={24}>
                <Form.Item name="customerCode">
                  <Select data-testid="customerCode" options={groupUserCustomerOptions} />
                </Form.Item>
              </Col>
            )}
            <Col xs={24}>
              <Form.Item name="divisionCode">
                <Select
                  data-testid="divisionCode"
                  placeholder="请选择业务线"
                  style={{ width: "100%" }}
                  options={allBusinessLine()}
                  disabled={
                    permission.CNExternalUsers && switcher.divisionCode !== null && switcher.divisionCode !== "All"
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item name="productCode">
                <RemoteSelect
                  dataTestId="productCode"
                  placeholder="请输入产品名称查询"
                  fetchOptions={this.fetchProductNameOptions}
                  loading={isFetchLoading}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col className="flex-row-class-btn" xs={24}>
              <Form.Item>
                <Button
                  data-testid="submit"
                  style={{ width: "100%" }}
                  className="base-submit-btn"
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                  disabled={!searchBtnAction || isExpand}
                >
                  查询
                </Button>
              </Form.Item>
            </Col>
          </Row>
          {/* 展开更多查询条件 */}
          <Collapse
            ghost
            expandIconPosition="right"
            onChange={this.handelExpandCollapse}
            className="collapse-padding"
            collapsible="header"
          >
            <Panel header={<span style={{ color: "#007ed9" }}>{expandCollapseText}</span>} key="1" forceRender={true}>
              <Divider dashed={true} style={{ width: "101%", margin: "-10px 0 13px -14px" }} />
              <div className="expand-collapse">
                <Row gutter={{ xs: 8, md: 16 }}>
                  <Col md={3} xs={24}>
                    <Form.Item
                      label="合同编号"
                      name="contractNo"
                      rules={[
                        () => ({
                          validator(_, value) {
                            const reg = /^\d+$/;
                            if (value && !reg.test(value)) {
                              return Promise.reject(new Error("请输入合同号"));
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <Input data-testid="contractNo" placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col md={3} xs={24}>
                    <Form.Item label="合同行项目编号" name="batch">
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col md={3} xs={24}>
                    <Form.Item label="包装规格" name="packageSpecification">
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  {isHaveRolePermissionFeedFactory() && (
                    <Col md={3} xs={24}>
                      <Form.Item label="目的地" name="location">
                        <Input placeholder="请输入" disabled={isHaveRolePermissionFeedFactory()} />
                      </Form.Item>
                    </Col>
                  )}
                  <Col md={3} xs={24}>
                    <Form.Item label="配送方式" name="shipmentMethod">
                      <Select data-testid="shipmentMethod" options={pickUpMethodOptions} />
                    </Form.Item>
                  </Col>
                  <Col className="advance-btn-col">
                    <Form.Item>
                      <div className="advance-btn">
                        <Button
                          data-testid="reset-btn"
                          style={{ marginRight: 10, backgroundColor: "#5c5c5c" }}
                          type="primary"
                          onClick={this.resetSearch}
                        >
                          清空
                        </Button>
                        <Button
                          data-testid="submit-btn"
                          type="primary"
                          htmlType="submit"
                          disabled={!searchBtnAction || !isExpand}
                        >
                          立即查询
                        </Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Collapse>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  permission: state.userPermissions,
  userDetails: state.userDetails,
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(ContractSearchFilter);
