const initialState = {};

function userPermissions(state = initialState, action: any) {
  switch (action.type) {
    case "GET_USER_PERMISSION_DATA":
    case "GET_USER_PERMISSION_DATA_ERROR":
      return {
        ...state
      };
    case "GET_USER_PERMISSION_DATA_SUCCESS":
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

export { userPermissions, initialState };
