import { updateAppointmentMode } from "@/api/userCenter";
import { getLocalStorageItem, setLocalStorageItem } from "@/utils";
import { Button, message, Modal, Radio } from "antd";
import { useCallback, useEffect, useState } from "react";

const SlotBookingType = (props: any) => {
  const { switcherDetails, buyerName, firmCodeValue, userDetailsData } = props;
  const [reserveModalVisible, setReserveModalVisible] = useState(false);
  const [reserveMethod, setReserveMethod] = useState("CE");
  const [visible, setVisible] = useState(false);
  const slotBookingActionRoleCM = "DXP_DSC_Role_CN_Manager";
  const slotBookingActionRoleCE = "DXP_DSC_Role_CN_Staff";
  const adminActionRole = "DXP_DSC_Role_CN_Cargill_Admin";

  useEffect(() => {
    // 公司切换，需要更新默认值
    setReserveMethod(getLocalStorageItem("localSlotBookingType") || "CE");
  }, [switcherDetails, switcherDetails.customerCode]);

  const RadioChange = useCallback(
    (event: any) => {
      const { groups } = userDetailsData;
      const { customerCode } = switcherDetails;
      const { firmCodeValue } = props;
      if (
        (groups.includes(adminActionRole) && !firmCodeValue) ||
        (!groups.includes(adminActionRole) && !customerCode)
      ) {
        setVisible(true);
        return;
      }
      if (
        reserveMethod &&
        event.target.value !== reserveMethod &&
        (groups.includes(slotBookingActionRoleCM) ||
          groups.includes(slotBookingActionRoleCE) ||
          groups.includes(adminActionRole))
      ) {
        setReserveModalVisible(true);
      }
    },
    [props, reserveMethod, switcherDetails, userDetailsData]
  );

  // 更换预约方式
  const handReserveOk = useCallback(() => {
    const { customerCode } = switcherDetails;
    updateAppointmentMode({
      customerCode: customerCode,
      appointmentMode: reserveMethod === "CE" ? "DRIVER" : "CE"
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          setReserveModalVisible(false);
          setReserveMethod(reserveMethod === "CE" ? "DRIVER" : "CE");
          setLocalStorageItem("localSlotBookingType", reserveMethod === "CE" ? "DRIVER" : "CE");
          message.success("变更成功");
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      });
  }, [reserveMethod, switcherDetails]);

  const onHandleReserveModalCancel = useCallback(() => {
    setReserveModalVisible(false);
  }, []);

  const onHandleTipsModalClose = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <div className="user-info-item">
      <span className="info-title">请选择预约方式</span>
      {switcherDetails.buyer && (
        <span className="msg">
          【{buyerName} ({firmCodeValue})】
        </span>
      )}
      <div className="radio-choose">
        <Radio.Group value={reserveMethod} onChange={RadioChange}>
          <Radio value="CE">采购专员/经理预约</Radio>
          <Radio value="DRIVER">
            司机预约
            <span style={{ marginLeft: 20 }} className="tips">
              请司机关注“嘉吉粮油服务平台服务号”，按引导进行操作
            </span>
          </Radio>
        </Radio.Group>
        <Modal
          visible={reserveModalVisible}
          title={null}
          onCancel={onHandleReserveModalCancel}
          onOk={handReserveOk}
          centered
          maskClosable={false}
        >
          确认预约类型更改为：{reserveMethod === "CE" ? "司机预约" : "采购专员/经理预约"}？
        </Modal>
        <Modal
          visible={visible}
          closable={false}
          footer={
            <Button type="primary" onClick={onHandleTipsModalClose}>
              确认
            </Button>
          }
          centered
          width={500}
          maskClosable={false}
        >
          <div style={{ padding: 10 }}>切换预约类型，需要先要设置默认公司，请前往‘切换卖方/业务线’进行设置。</div>
        </Modal>
      </div>
    </div>
  );
};

export default SlotBookingType;
