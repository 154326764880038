import { useCallback, useRef, useState, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { Table } from "antd";

interface Props {
  dataSource: any;
  columns: any;
  rowSelection: any;
  getChangedColumns: any;
  direction: any;
}

const type = "DraggableBodyRow";

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}: {
  index: number;
  moveRow: any;
  className: string;
  style: any;
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor: any) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (index === dragIndex) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? "drop-over-downward" : "drop-over-upward"
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    }
  });

  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor: any) => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref as any}
      className={`${className} ${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

const DragSortingTable = (props: Props) => {
  const { dataSource, columns, rowSelection } = props;
  const [data, setData] = useState(dataSource);

  useEffect(() => {
    let temp = dataSource.filter((item: any) => {
      if (item.remove === false && item.fixed !== false) return item;
      return null;
    });
    let restTemp = dataSource.filter((item: any) => {
      return item.remove !== false || (item.remove === false && item.fixed === false);
    });
    let data = [...temp, ...restTemp];
    setData(data);
  }, [dataSource]);

  const components = {
    body: {
      row: DraggableBodyRow
    }
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      if (dragRow && props.direction === "right") {
        const newData = update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        });
        if (dragRow.remove !== false) {
          setData(newData);
        }
        props.getChangedColumns(newData);
      }
    },
    [data, props]
  );

  const getRowClass = useCallback((record: any) => {
    return record.remove === false ? "fixed-tr-filled" : "";
  }, []);

  const onRowMove = useCallback(
    (record: any, index: number | undefined): any => ({
      index,
      moveRow
    }),
    [moveRow]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        dataSource={data}
        columns={columns}
        components={components}
        onRow={onRowMove}
        rowClassName={getRowClass}
        rowSelection={rowSelection}
        pagination={false}
        scroll={{ y: 400 }}
        size="small"
      />
    </DndProvider>
  );
};

export default DragSortingTable;
