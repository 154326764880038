import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { PlusOutlined, MinusCircleOutlined, EditFilled } from "@ant-design/icons";
import "./index.scss";
import { useForm } from 'antd/lib/form/Form';

const { TextArea } = Input;

interface Props {
  placeholer: string;
  value?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}
const ListItemInput: React.FC<Props> = (props) => {
  const [value, setValue] = useState<string | undefined>(props.value);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [form] = useForm();

  /**
   * 监听TextArea value的改变，并将改变后的value同步给对应name的Form.Item
   */
  const onHandleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
      props.onChange && props.onChange(e.target.value);
    },
    [props]
  );

  /**
   * 点击自动审单仓库添加按钮的回调函数
   */
  const handleClickAddBtn = useCallback(() => {
    setIsVisible(true);
  }, []);

  /**
   * 触发保存自动审单仓库
   */
  const handleSave = useCallback(() => {
    form.submit();
  }, [form]);

  const handleCancel = useCallback(() => {
    setIsVisible(false);
  }, []);

  /**
 * 监听表单提交
 */
  const onFinish = useCallback((values: any) => {
    setValue(values?.names?.join(","));
    setIsVisible(false);
    props.onChange && props.onChange(values?.names?.join(","));
  }, [props]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (isVisible) {
      form.setFieldsValue({
        names: props.value?.split(",")
      })
    }
  }, [isVisible, form, props.value]);

  return (
    <>
      <TextArea
        placeholder={props.placeholer}
        value={value}
        onChange={onHandleChange}
        disabled
      />
      <Button style={{ marginTop: "4px" }} icon={<EditFilled />} onClick={handleClickAddBtn}>编辑</Button>
      <Modal
        visible={isVisible}
        title="添加自动审单仓库"
        width={750}
        wrapClassName="list-item-add-modal-wrapper"
        onOk={handleSave}
        onCancel={handleCancel}
        destroyOnClose
        forceRender
        maskClosable
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.List
            name="names"
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Form.Item
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="请输入自动审单仓库代码" style={{ width: "60%" }} />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    添加
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default ListItemInput;