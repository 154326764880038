import React, { Component } from "react";
import { PageHeader, Card, Table, message, Button, Modal, Spin, Tooltip } from "antd";
import { CloseCircleOutlined, EditOutlined, FileSearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import "./index.scss";
import ReservationFilters from "./components/reservationFilters";
import ReverseBooking from "./components/reverseBooking";
import TimeWindowConfig from "./components/timeWindowConfig";
import PreViewBookingModal from "./components/preViewBookingModal";
import NotificationTips from "@/pages/common/notificationTips";
import {
  getBookingRulesListApi,
  deleteBookingTimeWindowApi,
  copyDateRuleApi,
  updateDateRuleConfigApi,
  timeWindowSaveOrUpdateApi,
  bookingRulesCheckApi
} from "@/api/slotbooking";
import { formatNumber, deviceWidth } from "@/utils";

interface Props {
  switcherData: any;
  userDetailsData: any;
}
interface State {
  reservationFilters: any;
  isTableLoading: boolean;
  tableDataSource: object;
  pagination: object;
  currentDateTotalMaximum: number;
  bookingRuleDate: object;
  showPreviewTimeWindowModal: boolean;
  currentActionItemObj: object;
  isCopyTimeWindowLoading: boolean;
  isReleaseTimeWindowLoading: boolean;
  editData: object;
  isTimeWindowPosting: boolean;
  timeRangeLimit: number;
  lastDateStr: string;
}

class TimeWindow extends Component<Props, State> {
  public state = {
    reservationFilters: {
      orgCode: null,
      orgName: null,
      platform: null,
      dateStr: null, // 当前日期
      loadingRule: "",
      status: "", // 当前时间窗状态
      dateStrExtra: { maximum: 0 } // 该日期的其他额外配置信息
    },
    isTableLoading: false,
    tableDataSource: [], // 存放table展示数据（格式化后的）和接口返回的数据集合
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showQuickJumper: true,
      size: deviceWidth() > 576 ? ("default" as any) : ("small" as any)
    },
    currentDateTotalMaximum: 0, // 汇总当前日期已设置的最大发货量的总量-VA
    bookingRuleDate: { totalBookNum: 0, trucksNumber: 0, totalNumPackage: 0, remainingBookNum: 0 },
    showPreviewTimeWindowModal: false,
    currentActionItemObj: {},
    isCopyTimeWindowLoading: false,
    isReleaseTimeWindowLoading: false,
    editData: { id: "", startTime: null, endTime: null, maximum: 0 },
    isTimeWindowPosting: false,
    timeRangeLimit: 15, // 15分钟的间隔--可在此自定义自动进行计算
    lastDateStr: "" // 记录上次预设条件选中的日期
  };

  reservationFilters_Ref: any = React.createRef();
  timeWindowConfig_Ref: any = React.createRef();
  urlParams = { limit: 10, offset: 0 };

  componentDidMount() {
    document.title = "时间窗管理";
  }

  columnsData = () => {
    return [
      {
        title: "产品信息",
        key: "productInfo",
        align: "center" as "center",
        fixed: true,
        width: 160,
        render: (rowData: any) => (
          <div className="header-product-info">
            {rowData?.productInfo?.length > 18 ? (
              <Tooltip title={rowData.productInfo}>
                <span>{`${rowData.productInfo.substring(0, 15)}...`}</span>
              </Tooltip>
            ) : (
              <span>{rowData.productInfo}</span>
            )}
          </div>
        )
      },
      {
        title: "发货线数量",
        dataIndex: "lineNumber",
        key: "lineNumber",
        align: "center" as "center",
        fixed: deviceWidth() > 992,
        width: 90
      },
      {
        title: "配置情况",
        dataIndex: "bookedInfo",
        key: "bookedInfo",
        align: "center" as "center",
        fixed: deviceWidth() > 992,
        width: 160
      },
      {
        title: "发布时间",
        dataIndex: "releaseTime",
        key: "releaseTime",
        align: "center" as "center",
        fixed: deviceWidth() > 992,
        width: 140
      },
      {
        title: "对外通知",
        key: "noticeExt",
        align: "center" as "center",
        width: 80,
        render: (rowData: any) => <span>{rowData.noticeExt === true ? "是" : "否"}</span>
      },
      ...this.setTimeRangeHeader(),
      {
        title: "操作",
        key: "action",
        fixed: deviceWidth() > 768 ? ("right" as "right") : false,
        align: "center" as "center",
        width: 120,
        render: (rowData: any) => (
          <div className="hdp-uf hdp-uf-hc">
            {/* 删除 && 编辑 */}
            {this.isColumnsDataItemAction(rowData) ? (
              <>
                <div
                  className="action-item action-item-delete"
                  onClick={() => this.confirmDeleteTimeWindowItem(rowData)}
                >
                  <CloseCircleOutlined style={{ fontSize: "16px", color: "#005f86" }} />
                </div>
                <div className="action-item action-item-edit" onClick={() => this.doEditTimeWindowConfig(rowData)}>
                  <EditOutlined style={{ fontSize: "16px", color: "#005f86" }} />
                </div>
              </>
            ) : (
              <>
                <div className="action-item action-item-delete action-item-disabled">
                  <CloseCircleOutlined style={{ fontSize: "16px", color: "#b2b2b2" }} />
                </div>
                <div className="action-item action-item-edit action-item-disabled">
                  <EditOutlined style={{ fontSize: "16px", color: "#b2b2b2" }} />
                </div>
              </>
            )}
            {/* 预览 */}
            {rowData.active && rowData.id ? (
              <div className="action-item action-item-preview" onClick={() => this.doPreviewTimeWindow(rowData)}>
                <FileSearchOutlined style={{ fontSize: "16px", color: "#005f86" }} />
              </div>
            ) : (
              <div className="action-item action-item-preview action-item-disabled">
                <FileSearchOutlined style={{ fontSize: "16px", color: "#b2b2b2" }} />
              </div>
            )}
          </div>
        )
      }
    ];
  };

  // 只有Planner角色拥有创建、编辑、删除功能，其他角色只能查看预览
  isColumnsDataItemAction = (rowData: any) => {
    const { userDetailsData } = this.props;
    return rowData.active && rowData.id && userDetailsData.groups?.includes("DXP_DSC_Role_CN_Cargill_Planner");
  };

  setTimeRangeHeader = () => {
    let timeRangeHeaderArr = [];
    for (let i = 0; i < 24; i += 1) {
      const timeRangeHeader = {
        title: `${i < 10 ? "0" + i : i}:00`,
        dataIndex: `time_window_${i}`,
        key: `time_window_${i}`,
        align: "center" as "center",
        width: 80
      };
      timeRangeHeaderArr.push(timeRangeHeader);
    }
    return timeRangeHeaderArr;
  };

  // 预设条件数据获取
  handleReservationFiltersCallBack = () => {
    console.log("TimeWindow-->handleReservationFiltersCallBack =========>>>>>>>>");
    console.log(this.reservationFilters_Ref.current.getFormFields());

    const { reservationFilters, lastDateStr } = this.state;
    const currentReservationFilters = this.reservationFilters_Ref.current.getFormFields();
    // 切换日期，修改 offset 为 0, 重新获取table  Marks: Emily
    if (lastDateStr !== currentReservationFilters.dateStr) {
      this.setState({ lastDateStr: currentReservationFilters.dateStr });
      this.urlParams.offset = 0;
    }
    this.setState({ reservationFilters: { ...reservationFilters, ...currentReservationFilters } }, () => {
      if (
        currentReservationFilters &&
        Object.keys(currentReservationFilters).length > 0 &&
        currentReservationFilters.platform &&
        currentReservationFilters.dateStr &&
        currentReservationFilters.orgCode
      ) {
        // 更新table数据
        this.getTimeWindowDataList();
        // 如果有编辑数据，需要自动清空
        this.resetEditData();
      }
    });
  };

  // 获取已配置时间窗列表table数据
  getTimeWindowDataList = () => {
    const { pagination, reservationFilters } = this.state;

    let paginationTemp = pagination;
    if (this.urlParams.offset === 0 && pagination.current !== 1) {
      // 重置页码
      paginationTemp = { ...pagination, current: 1 };
      this.setState({ pagination: paginationTemp });
    }
    // 筛选条件
    const postDataObj = { ...reservationFilters };
    this.setState({ isTableLoading: true });
    getBookingRulesListApi(this.urlParams, postDataObj)
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
        } else if (responseJSON?.data) {
          const tableRecordData = Array.isArray(responseJSON.data.resultList) ? responseJSON.data.resultList : [];
          const bookingRuleDateObj = responseJSON.data.bookingRulesNum;
          this.setState({
            tableDataSource:
              tableRecordData.map((val: any) => {
                return this.mapTableData(val);
              }) || [],
            bookingRuleDate: bookingRuleDateObj,
            pagination: {
              ...paginationTemp,
              total: responseJSON.data.metadata?.totalCount ?? 0
            }
          });
          // 计算目前当日所有产品时间窗总量和记录当前已配置的日期列表
          this.setTotalCurrentDate(tableRecordData);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        this.setState({ tableDataSource: [], pagination: { ...pagination, total: 0 } });
      })
      .finally(() => {
        this.setState({ isTableLoading: false });
      });
  };

  // table展示字段格式化
  mapTableData = (data: any) => {
    const { reservationFilters } = this.state;
    const vehicleTypeText = data.loadingRule === "MG" && ["1959", "1254"].includes(data.siteCode) ? data.vehicleType || "" : "";

    return {
      ...data,
      productInfo: ["VA", "BR", "MG"].includes(reservationFilters.loadingRule)
        ? `${data.category} ${data.specGroup || ""}` || "-"
        : `${data.productName} ${data.packageSpec || ""}` || "-",
      bookedInfo: ["BR", "MG"].includes(reservationFilters.loadingRule)
        ? `已约${data.totalBookNum || "-"}/共${data.trucksNumber || "-"}车 ${vehicleTypeText}`
        : `已配置${data.maximum || "-"}`,
      releaseTime: data.releaseTime ? moment(data.releaseTime).format("YYYY-MM-DD HH:mm") : "-",
      ...this.setTimeRangeTable(data, data.banTimeStrs, data.transportTimeStrs)
    };
  };

  // 生成table下的时间节点dom
  setTimeRangeTable = (itemData: any, ban: any, transport: any) => {
    let timeRangeTableItemObj: any = {};
    const startTimeNode = new Date(itemData.startTime).getTime();
    const endTimeNode = new Date(itemData.endTime).getTime();
    const rangeTimeNodes = this.getRangeTimeNodes(startTimeNode, endTimeNode, itemData.efficiency);

    // 运输锁定时间
    const transportTimeArr = transport.map((val: any) => {
      const startDate = new Date(`${itemData.dateStr} ${val.split("-")[0]}`).getTime();
      const endDate = new Date(`${itemData.dateStr} ${val.split("-")[1]}`).getTime();
      const rangeTransportTimeNodes = this.getRangeTimeNodes(startDate, endDate, itemData.efficiency);
      return { data: rangeTransportTimeNodes, startDate: startDate, endDate: endDate };
    });
    // 运输锁定时间
    const banTimeArr = ban.map((val: any) => {
      const startDate = new Date(`${itemData.dateStr} ${val.split("-")[0]}`).getTime();
      const endDate = new Date(`${itemData.dateStr} ${val.split("-")[1]}`).getTime();
      const rangeBanTimeNodes = this.getRangeTimeNodes(startDate, endDate, itemData.efficiency);
      return { data: rangeBanTimeNodes, startDate: startDate, endDate: endDate };
    });

    for (let i = 0; i < 24; i += 1) {
      const hourText = `${i < 10 ? "0" + i : i}`;
      const timeItem1 = { value: `${hourText}:00:00`, label: `${hourText}:00` };
      const timeItem2 = { value: `${hourText}:15:00`, label: `${hourText}:15` };
      const timeItem3 = { value: `${hourText}:30:00`, label: `${hourText}:30` };
      const timeItem4 = { value: `${hourText}:45:00`, label: `${hourText}:45` };

      timeRangeTableItemObj[`time_window_${i}`] = (
        <div className="hdp-uf hdp-uf-hsb">
          <div
            className={`hdp-uf hdp-uf-hc hdp-uf-vc window-table-item ${this.getNodeClass(
              new Date(`${itemData.dateStr} ${timeItem1.value}`).getTime(),
              startTimeNode,
              endTimeNode,
              rangeTimeNodes,
              transportTimeArr,
              banTimeArr
            )}`}
          />
          <div
            className={`hdp-uf hdp-uf-hc hdp-uf-vc window-table-item ${this.getNodeClass(
              new Date(`${itemData.dateStr} ${timeItem2.value}`).getTime(),
              startTimeNode,
              endTimeNode,
              rangeTimeNodes,
              transportTimeArr,
              banTimeArr
            )}`}
          />
          <div
            className={`hdp-uf hdp-uf-hc hdp-uf-vc window-table-item ${this.getNodeClass(
              new Date(`${itemData.dateStr} ${timeItem3.value}`).getTime(),
              startTimeNode,
              endTimeNode,
              rangeTimeNodes,
              transportTimeArr,
              banTimeArr
            )}`}
          />
          <div
            className={`hdp-uf hdp-uf-hc hdp-uf-vc window-table-item ${this.getNodeClass(
              new Date(`${itemData.dateStr} ${timeItem4.value}`).getTime(),
              startTimeNode,
              endTimeNode,
              rangeTimeNodes,
              transportTimeArr,
              banTimeArr
            )}`}
          />
        </div>
      );
    }
    return timeRangeTableItemObj;
  };

  // 获取效率在时间范围内的节点
  getRangeTimeNodes = (start: number, end: number, efficiency: number) => {
    const { timeRangeLimit, reservationFilters } = this.state;
    const nodes = [];
    if (efficiency && reservationFilters.loadingRule !== "VA") {
      let currentNode = start + 1000 * 60 * (efficiency - timeRangeLimit);
      while (currentNode < end) {
        nodes.push(currentNode);
        currentNode += 1000 * 60 * efficiency;
      }
    }
    return nodes;
  };

  // 确认时间窗节点样式
  getNodeClass = (
    currentTime: number,
    start: number,
    end: number,
    rangeTimeNodes: any,
    transportTimeArr: any,
    banTimeArr: any
  ) => {
    const { timeRangeLimit } = this.state;
    if (currentTime >= start && currentTime < end) {
      // 运输锁定时间
      const checkTransport = this.checkTransportTime(currentTime, transportTimeArr);
      if (checkTransport) {
        return checkTransport;
      }
      // 休息时间
      const checkBan = this.checkBanTime(currentTime, banTimeArr);
      if (checkBan) {
        return checkBan;
      }
      // 正常可预约时间
      if (rangeTimeNodes.includes(currentTime) || end - currentTime === 1000 * 60 * timeRangeLimit) {
        return "window-table-item-on margin-r";
      }
      return "window-table-item-on";
    }
    return "margin-r";
  };

  checkTransportTime = (currentTime: number, transportTimeArr: any) => {
    const { timeRangeLimit } = this.state;
    let classText = "";
    for (let transportTimeItem of transportTimeArr) {
      if (currentTime >= transportTimeItem.startDate && currentTime < transportTimeItem.endDate) {
        classText = "window-table-item-transport";
        if (
          transportTimeItem.data.includes(currentTime) ||
          transportTimeItem.endDate - currentTime === 1000 * 60 * timeRangeLimit
        ) {
          classText = "window-table-item-transport margin-r";
        }
        break;
      }
    }
    return classText;
  };

  checkBanTime = (currentTime: number, banTimeArr: any) => {
    const { timeRangeLimit } = this.state;
    let classText = "";
    for (let banTimeItem of banTimeArr) {
      if (currentTime >= banTimeItem.startDate && currentTime < banTimeItem.endDate) {
        classText = "window-table-item-ban";
        if (
          banTimeItem.data.includes(currentTime) ||
          banTimeItem.endDate - currentTime === 1000 * 60 * timeRangeLimit
        ) {
          classText = "window-table-item-ban margin-r";
        }
        break;
      }
    }
    return classText;
  };

  mapStatus = (status: any) => {
    switch (status) {
      case "OPEN":
        return "已发布";
      case "INIT":
        return "已创建";
      case "EMPTY":
        return "待创建";
      default:
        return "-";
    }
  };

  setTotalCurrentDate = (data: any) => {
    let totalNum = 0;
    if (data && data.length > 0) {
      for (let dateItem of data) {
        if (dateItem.maximum) {
          totalNum += dateItem.maximum;
        }
      }
    }
    this.setState({ currentDateTotalMaximum: totalNum });
  };

  // 预约总览 -- VA
  openAllPreviewBooking = () => {
    const { reservationFilters, bookingRuleDate } = this.state;
    Modal.info({
      title: "提货预约总览",
      icon: "",
      width: "60%",
      content: (
        <div className="hdp-uf hdp-uf-vc preview-all-info">
          <div className="hdp-uf hdp-uf-hc datetime-item datetime-item-on">{reservationFilters.dateStr}</div>
          <label>
            总计已预约数量{bookingRuleDate.totalNumPackage || "-"}件,剩余可预约数量
            {bookingRuleDate.remainingBookNum || "-"}
            件,当日已预约{bookingRuleDate.totalBookNum || "-"}车
          </label>
        </div>
      ),
      okText: "关闭"
    });
  };

  // 打开是否复制前日配置
  doOpenCopyMessage = () => {
    const { reservationFilters } = this.state;
    const copyDateTime = reservationFilters.dateStr
      ? new Date(new Date(reservationFilters.dateStr).getTime() - 24 * 60 * 60 * 1000)
      : "";

    Modal.confirm({
      title: "提示",
      icon: <ExclamationCircleOutlined />,
      content: `将复制${moment(copyDateTime).format("YYYY年MM月DD日")}的时间窗，当前记录将被覆盖，点击确认继续。`,
      className: "confirm-copy-notice-modal",
      onOk: () => {
        this.doCopyTimeWindow();
      }
    });
  };

  // 执行复制时间窗
  doCopyTimeWindow = () => {
    const { reservationFilters } = this.state;
    const dataObj = {
      orgCode: reservationFilters.orgCode,
      platform: reservationFilters.platform,
      dateStr: reservationFilters.dateStr
    };
    this.setState({ isCopyTimeWindowLoading: true });
    copyDateRuleApi(dataObj)
      .then(async (responseJSON: any) => {
        if (responseJSON && !responseJSON.errorMsg) {
          //  更新日期配置信息数据--会自动触发更新列表数据
          await this.reservationFilters_Ref.current.updateDateRuleConfig(reservationFilters.dateStr);
        } else {
          // 99999 提示后端错误信息提示
          message.error(responseJSON.errorMsg);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("复制时间窗失败");
      })
      .finally(() => {
        this.setState({ isCopyTimeWindowLoading: false });
      });
  };

  // 打开是否确认发布
  doOpenDeployMessage = () => {
    Modal.confirm({
      title: "提示",
      icon: <ExclamationCircleOutlined />,
      content: "发布后，外部用户将使用本时间窗，点击确认继续。",
      className: "confirm-deploy-notice-modal",
      onOk: () => {
        this.deployTimeWindow();
      }
    });
  };

  // 执行发布时间窗
  deployTimeWindow = () => {
    const { reservationFilters } = this.state;
    const dataObj = {
      siteCode: reservationFilters.orgCode,
      platform: reservationFilters.platform,
      dateStr: reservationFilters.dateStr,
      status: "OPEN"
    };
    this.setState({ isReleaseTimeWindowLoading: true });
    updateDateRuleConfigApi(dataObj)
      .then(async (responseJSON: any) => {
        if (responseJSON && (!responseJSON.errorMsg || responseJSON.data)) {
          //  更新日期配置信息数据--会自动触发更新列表数据
          await this.reservationFilters_Ref.current.updateDateRuleConfig(reservationFilters.dateStr);
        } else {
          // 99999 提示后端错误信息提示
          message.error(responseJSON.errorMsg);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("发布时间窗失败");
      })
      .finally(() => {
        this.setState({ isReleaseTimeWindowLoading: false });
      });
  };

  openChangeMaximum = () => {
    this.reservationFilters_Ref.current.settingMaximum();
  };

  // 提示是否确认删除时间窗列表
  confirmDeleteTimeWindowItem = (itemData: any) => {
    Modal.confirm({
      title: "提示",
      icon: <ExclamationCircleOutlined />,
      content: "时间窗删除后会自动取消用户预约，是否继续删除？",
      className: "confirm-delete-notice-modal",
      onOk: () => {
        this.doDeleteTimeWindowItem(itemData.id);
      }
    });
  };

  // 执行删除是时间窗列表
  doDeleteTimeWindowItem = (id: number) => {
    const { pagination, reservationFilters, tableDataSource } = this.state;
    const paramsObj = { id };
    this.setState({ isTableLoading: true });
    deleteBookingTimeWindowApi(paramsObj)
      .then(async () => {
        if (!pagination.total || pagination.total === 0 || pagination.total - 1 === 0) {
          //  更新日期配置信息数据
          if (reservationFilters.loadingRule && reservationFilters.loadingRule === "VA") {
            this.setState(
              {
                reservationFilters: {
                  ...reservationFilters,
                  dateStr: null, // 当前日期
                  status: "", // 当前时间窗状态
                  dateStrExtra: { maximum: 0 } // 该日期的其他额外配置信息
                },
                currentDateTotalMaximum: 0
              },
              () => {
                this.reservationFilters_Ref.current.settingMaximum(reservationFilters.dateStrExtra, true);
              }
            );
          } else {
            await this.reservationFilters_Ref.current.updateDateRuleConfig(reservationFilters.dateStr);
          }
        }
        // 如果不是在第一页，且取消的是当前页码的最后一条，则需要自动切换到前一页
        if (pagination.current > 1 && tableDataSource.length === 1) {
          this.urlParams = { ...this.urlParams, offset: pagination.pageSize * (pagination.current - 2) };
          this.setState(
            {
              pagination: { ...pagination, current: pagination.current - 1 }
            },
            () => {
              // 获取新的数据
              this.getTimeWindowDataList();
            }
          );
        } else {
          this.getTimeWindowDataList();
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
      })
      .finally(() => {
        this.setState({ isTableLoading: false });
      });
  };

  doEditTimeWindowConfig = (itemData: any) => {
    if (itemData) {
      this.setState({ editData: { ...itemData } });
    }
  };

  // 预览时间窗信息
  doPreviewTimeWindow = (itemData: any) => {
    if (itemData) {
      this.setState({ showPreviewTimeWindowModal: true, currentActionItemObj: itemData });
    }
  };

  handlePreviewTimeWindowClose = () => {
    this.setState({ showPreviewTimeWindowModal: false, currentActionItemObj: {} });
  };

  // 清空编辑的数据
  resetEditData = () => {
    const { editData } = this.state;
    if (editData?.id) {
      this.setState({ editData: { id: "", startTime: null, endTime: null, maximum: 0 } });
    }
  };

  // 判断执行新增时间窗或者编辑更改时间窗
  doCheckSaveOrUpdate = (values: any) => {
    const { editData } = this.state;
    if (editData?.id) {
      // 编辑时，如果修改的是时间，需要先检测时间段是否有预约并给出提示
      if (values.startTime !== editData.startTime || values.endTime !== editData.endTime) {
        this.doEditCheck(values, "edit");
      } else {
        this.doSubmitSaveOrUpdate(values);
      }
    } else {
      //  新增处理
      this.doSubmitSaveOrUpdate(values);
    }
  };

  // 跟服务端确认是否有已经有预约，需要给出提示再保存
  doEditCheck = (values: any, actionType: string) => {
    const paramsObj = { action: actionType };
    const postParamsObj = { ...values };
    bookingRulesCheckApi(paramsObj, postParamsObj)
      .then((responseJSON: any) => {
        if (responseJSON.code && responseJSON.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
        } else if (responseJSON?.data) {
          if (responseJSON.data.status === "Y") {
            Modal.confirm({
              title: "提示",
              icon: <ExclamationCircleOutlined />,
              content: "当前变更的时间窗已有用户预约，点击确认后将会取消变更后时间窗以外的所有预约，是否继续保存？",
              onOk: () => {
                this.doSubmitSaveOrUpdate(values);
              }
            });
          } else if (responseJSON.data.status === "N") {
            // 直接执行
            this.doSubmitSaveOrUpdate(values);
          }
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
      });
  };

  // 执行时间窗口新增或者编辑
  doSubmitSaveOrUpdate = (dataObj: any) => {
    const { reservationFilters, pagination } = this.state;
    if (!this.checkMaxLimit(dataObj)) {
      const postDataObj = {
        ...dataObj,
        orgCode: reservationFilters.orgCode,
        platform: reservationFilters.platform,
        dateStr: reservationFilters.dateStr,
        startTime: moment(dataObj.startTime)?.format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment(dataObj.endTime)?.format("YYYY-MM-DD HH:mm:ss")
      };

      if (dataObj.releaseDate && dataObj.releaseTime) {
        const releaseTime = `${dataObj.releaseDate} ${dataObj.releaseTime}`;
        delete postDataObj.releaseDate;
        postDataObj.releaseTime = moment(releaseTime).format("YYYY-MM-DD HH:mm:ss");
      } else {
        delete postDataObj.releaseDate;
        postDataObj.releaseTime = "";
      }

      this.setState({ isTimeWindowPosting: true });
      timeWindowSaveOrUpdateApi(postDataObj)
        .then(async (responseJSON: any) => {
          if (responseJSON?.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(responseJSON.errorMsg);
          } else if (responseJSON?.data) {
            if (!pagination.total) {
              // 更新日期配置信息数据
              await this.reservationFilters_Ref.current.updateDateRuleConfig(reservationFilters.dateStr);
            }
            // 编辑或者新增成功，主动发起重置时间窗配置信息
            this.timeWindowConfig_Ref.current.handleResetForm();
            this.getTimeWindowDataList();
          }
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          message.error("抱歉，系统连接出现问题，请稍后再试。");
        })
        .finally(() => {
          this.setState({ isTimeWindowPosting: false });
        });
    }
  };

  // 如果是VA需要判断是否超过当日最大发货数量
  checkMaxLimit = (dataObj: any) => {
    const { reservationFilters, currentDateTotalMaximum, editData } = this.state;
    if (reservationFilters.loadingRule && reservationFilters.loadingRule === "VA") {
      if (editData?.id) {
        // 编辑时判断
        if (
          currentDateTotalMaximum - editData.maximum + parseFloat(dataObj.maximum) >
          reservationFilters.dateStrExtra.maximum
        ) {
          Modal.warning({ title: "提示", content: "已超出当日最大发货量，请调整！" });
          return true;
        }
      } else if (currentDateTotalMaximum + dataObj.maximum > reservationFilters.dateStrExtra.maximum) {
        // 新增时判断
        Modal.warning({ title: "提示", content: "已超出当日最大发货量，请调整！" });
        return true;
      }
    }
    return false;
  };

  // 页面table相关的处理
  handleTableDataChange = (value: any) => {
    const { pagination } = this.state;
    if (value.pageSize !== pagination.pageSize) {
      // 每页显示的条数改变
      this.urlParams = { ...this.urlParams, offset: 0, limit: value.pageSize };
      this.setState(
        {
          pagination: { ...pagination, current: 1, pageSize: value.pageSize }
        },
        () => {
          // 获取新的数据
          this.getTimeWindowDataList();
        }
      );
    } else if (value.current !== pagination.current) {
      // 当前页码改变
      this.urlParams = { ...this.urlParams, offset: value.pageSize * (value.current - 1), limit: value.pageSize };
      this.setState(
        {
          pagination: { ...pagination, current: value.current, pageSize: value.pageSize }
        },
        () => {
          // 获取新的数据
          this.getTimeWindowDataList();
        }
      );
    }
  };

  // Table-Card组件的Title信息展示
  tableCardTitle = () => {
    const { reservationFilters, bookingRuleDate } = this.state;
    return (
      <div className="hdp-uf hdp-uf-vc card-extra">
        {/* VA预约总览 */}
        {reservationFilters.loadingRule && reservationFilters.loadingRule === "VA" ? (
          <div className="hdp-uf hdp-uf-vc preview-item preview-item-action" onClick={this.openAllPreviewBooking}>
            <span className="table-card-title">时间窗配置总览</span>
            <FileSearchOutlined style={{ fontSize: "16px", color: "#005f86" }} />
          </div>
        ) : (
          <div className="hdp-uf hdp-uf-vc preview-item">
            <span className="table-card-title">时间窗配置总览</span>
            <span className="table-card-tips">
              总预约{bookingRuleDate.totalBookNum || "-"}/总配置
              {bookingRuleDate.trucksNumber || "-"}车
            </span>
          </div>
        )}
      </div>
    );
  };

  // Table-Card组件的extra信息展示
  tableCardExtra = () => {
    const { userDetailsData } = this.props;
    const { reservationFilters, isCopyTimeWindowLoading, isReleaseTimeWindowLoading } = this.state;
    return (
      <div className="hdp-uf hdp-uf-vc card-extra">
        {reservationFilters.status !== "OPEN" && (
          <>
            <div className="extra-item">
              <Button
                data-testid="copy-btn"
                type="primary"
                disabled={!userDetailsData.groups?.includes("DXP_DSC_Role_CN_Cargill_Planner")}
                loading={isCopyTimeWindowLoading}
                onClick={this.doOpenCopyMessage}
              >
                复制前日时间窗
              </Button>
            </div>
            <div className="extra-item">
              <Button
                data-testid="release-btn"
                type="primary"
                disabled={!userDetailsData.groups?.includes("DXP_DSC_Role_CN_Cargill_Planner")}
                loading={isReleaseTimeWindowLoading}
                onClick={this.doOpenDeployMessage}
              >
                发布时间窗
              </Button>
            </div>
          </>
        )}
      </div>
    );
  };

  public render() {
    const { switcherData, userDetailsData } = this.props;
    const {
      reservationFilters,
      isTableLoading,
      tableDataSource,
      pagination,
      currentDateTotalMaximum,
      showPreviewTimeWindowModal,
      currentActionItemObj,
      editData,
      isTimeWindowPosting
    } = this.state;

    return (
      <div className="time-window-page">
        <div className="hdp-uf hdp-uf-dc">
          <PageHeader className="page-header" title="时间窗管理">
            <NotificationTips />
          </PageHeader>
          {/* 预设条件 */}
          <ReservationFilters
            ref={this.reservationFilters_Ref}
            switcherData={switcherData || null}
            userDetailsData={userDetailsData || null}
            get_reservation_filters={this.handleReservationFiltersCallBack}
          />
          {/* 反向预约操作项 */}
          {reservationFilters.loadingRule === "SY" && <ReverseBooking reservation_filters={reservationFilters} />}

          {/* 其他预约操作项--新增 */}
          {reservationFilters.loadingRule !== "SY" && (
            <Spin spinning={isTimeWindowPosting}>
              <TimeWindowConfig
                ref={this.timeWindowConfig_Ref}
                initData={editData || {}}
                currentTotalMaximum={currentDateTotalMaximum}
                reservationFiltersData={reservationFilters || {}}
                userDetailsData={userDetailsData || null}
                doResetEditData={this.resetEditData}
                doTimeWindowSubmit={this.doCheckSaveOrUpdate}
              />
            </Spin>
          )}

          {/* 其他预约操作项--已新增列表展示 */}
          {reservationFilters.loadingRule !== "SY" &&
            reservationFilters.orgCode &&
            reservationFilters.platform &&
            reservationFilters.dateStr && (
              <div className="time-window-table-container">
                <Card
                  style={{ boxShadow: "0 5px 9px #dedbdb", paddingBottom: "24px" }}
                  bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
                  title={this.tableCardTitle()}
                  headStyle={{
                    backgroundColor: "white",
                    borderBottom: "none"
                  }}
                  extra={this.tableCardExtra()}
                >
                  <div className="hdp-uf hdp-uf-dc">
                    <div className="hdp-uf detail-form-container">
                      <div className="detail-container">
                        <ul className="hdp-uf hdp-uf-vc hdp-uf-ww detail-list">
                          <li className="hdp-uf detail-item">
                            <div className="title">卖方工厂</div>
                            <div className="value">{reservationFilters.orgName || "-"}</div>
                          </li>
                          <li className="hdp-uf detail-item">
                            <div className="title">发货平台</div>
                            <div className="value">{reservationFilters.platform || "-"}</div>
                          </li>
                          <li className="hdp-uf detail-item">
                            <div className="title">发货日期</div>
                            <div className="value">{reservationFilters.dateStr || "-"}</div>
                          </li>
                          <li className="hdp-uf detail-item">
                            <div className="title">当前状态</div>
                            <div className="value">
                              {reservationFilters && reservationFilters.status
                                ? this.mapStatus(reservationFilters.status) || "-"
                                : "-"}
                            </div>
                          </li>
                          {reservationFilters.loadingRule &&
                            reservationFilters.loadingRule === "VA" &&
                            reservationFilters.dateStrExtra.maximum >= 0 && (
                              <li className="hdp-uf hdp-uf-vc detail-item">
                                <div className="title">当日最大发货量</div>
                                <div className="value">
                                  {reservationFilters.dateStrExtra
                                    ? `${formatNumber(
                                        reservationFilters.dateStrExtra.maximum
                                      )}件 （已配置${formatNumber(currentDateTotalMaximum)}件）`
                                    : "-"}
                                </div>
                                <Button
                                  type="link"
                                  onClick={this.openChangeMaximum}
                                  style={{ width: "60px" }}
                                  disabled={!userDetailsData.groups?.includes("DXP_DSC_Role_CN_Cargill_Planner")}
                                >
                                  修改
                                </Button>
                              </li>
                            )}
                        </ul>
                      </div>
                    </div>
                    {/* 表格时间信息提示 */}
                    <div className="table-item-color-info">
                      <div className="color-info-item">
                        <span className="color color-1"></span>
                        <span className="info">可预约时间</span>
                      </div>
                      <div className="color-info-item">
                        <span className="color color-2"></span>
                        <span className="info">内部预留时间</span>
                      </div>
                      <div className="color-info-item">
                        <span className="color color-3"></span>
                        <span className="info">休息时间</span>
                      </div>
                    </div>
                    <Table
                      size="small"
                      loading={isTableLoading}
                      dataSource={tableDataSource}
                      columns={this.columnsData()}
                      pagination={pagination}
                      onChange={this.handleTableDataChange}
                      bordered
                      rowKey="id"
                      scroll={{ x: "max-content" }}
                    />
                  </div>
                </Card>
              </div>
            )}
          {showPreviewTimeWindowModal && (
            <PreViewBookingModal
              modalTitle="时间窗预览"
              basicInfo={currentActionItemObj}
              doClose={this.handlePreviewTimeWindowClose}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  switcherData: state.switcher,
  userDetailsData: state.userDetails
});

export default connect(mapStateToProps, null)(TimeWindow);
