import { oktaAuth } from "@customerexperience/cgl-dxo-dxp-fe-okta-libs";
import { getUserProfileApi } from "@/api/user";
import { getToken, setLocalStorageItem } from "@/utils";

const doGetUserProfile = (emailId: string) => {
  return getUserProfileApi(emailId)
    .then(async (response: any) => {
      const responseObj = response.data;
      if (response.data && Object.keys(response?.data)?.length > 0 && response.data.entitlements) {
        const { entitlements } = response.data;
        responseObj.isGroup = false;
        if (entitlements && entitlements.length > 0) {
          for (let i = 0; i < entitlements.length; i++) {
            const { otherControls } = entitlements[i];
            if (otherControls.companyAccountType && otherControls.companyAccountType === "GROUP") {
              responseObj.isGroup = true;
            }
          }
        }
      }
      return responseObj;
    })
    .catch(exception => {
      console.log(`error in doGetUserProfile ${exception}`);
      return null;
    });
};

const getUserDetails = async () => {
  try {
    const currentOktaToken = await getToken();
    console.log("currentOktaToken ======>>>>> ", currentOktaToken);

    const userInfo = await oktaAuth.getUser().catch((err: any) => {
      console.log("error", "==> oktaAuth.getUser", `==> there was a problem getUser: ${err}`);
    });
    const userDetailsInfo = await doGetUserProfile(userInfo?.email);
    if (userDetailsInfo && Object.keys(userDetailsInfo)?.length > 0) {
      setLocalStorageItem("localUserDetailsData", JSON.stringify(userDetailsInfo));
      return userDetailsInfo;
    }
    return null;
  } catch (error) {
    console.log("getToken() error =========>>>>");
    console.log(error);
    return null;
  }
};

export { getUserDetails, doGetUserProfile };
