import { getUserDetails } from "./apiData/userDetails";

const getUserDetailsData = () => ({
  type: "GET_USER_DATA",
  Get: getUserDetails
});

const getUserDetailsSuccess = (data = {}) => ({
  type: "GET_USER_DATA_SUCCESS",
  payload: data
});

const getUserDetailsError = (error: any) => ({
  type: "GET_USER_DATA_ERROR",
  payload: { error }
});

export { getUserDetailsData, getUserDetailsSuccess, getUserDetailsError };
