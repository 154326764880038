const initialState = {};

function switcher(state = initialState, action: any) {
  switch (action.type) {
    case "GET_SWITCHER_DATA_ERROR":
    case "GET_SWITCHER_DATA":
      return {
        ...state
      };
    case "UPDATE_SWITCHER_DATA_BUYER":
    case "GET_SWITCHER_DATA_SUCCESS":
      return {
        ...state,
        ...action.payload
      };
    case "UPDATE_SWITCHER_DATA":
      return {
        ...state,
        message: "",
        error: ""
      };

    case "UPDATE_SWITCHER_DATA_SUCCESS":
      return {
        ...state,
        ...action.payload,
        message: "",
        error: false
      };
    case "UPDATE_SWITCHER_DATA_ERROR":
      return {
        ...state,
        message: action.payload.error,
        error: true
      };

    case "UPDATE_SWITCHER_DATA_ERROR_STATUS":
      return {
        ...state,
        error: false,
        message: ""
      };

    default:
      return state;
  }
}

export { switcher, initialState };
