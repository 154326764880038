// slot-booking 查询条件
import { useState, useEffect, useCallback } from "react";
import { Card, Form, Input, Select, Button, Collapse, Divider, DatePicker, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { connect } from "react-redux";
import "./index.scss";
import { getLocalStorageItem, isHaveRolePermissionFeedFactory, cutHeaderZero } from "@/utils";
import { allSupplier, allBusinessLine, allBookingStatus } from "@/utils/constant";
import { getProductQueryApi, getFirmDetailListApi, getProductsApi } from "@/api/slotbooking";
import { noRepeat } from "@/utils/common";

import RemoteSelect from "@/pages/common/remoteSelect";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

// 定义函数组件的props相关参数
type myProps = {
  doSearch?: (arg0: any) => void;
  excludeFields?: string[];
  userPermissionsData: any;
  userDetailsData: any;
  switcherData: any;
};

const SearchFilters = (props: myProps) => {
  const [form] = Form.useForm();
  const [isShowAdvanceFilter, setIsShowAdvanceFilter] = useState(false);
  const [isShowAdvanceFilterText, setIsShowAdvanceFilterText] = useState("展开高级筛选");
  const [platformDataOptions, setPlatformDataOptions] = useState([]);
  const [isSelectFetching, setIsSelectFetching] = useState(false);
  const [currentCollapseActiveKey, setCurrentCollapseActiveKey] = useState("");
  const [allCurrentCustomerOptions, setAllCurrentCustomerOptions] = useState<any>([]);

  // 如果switcher有设置值，进行初始化相关数据
  // 只要switcher中的卖方orgCode的值发生变化需要处理的其他相关项
  useEffect(() => {
    if (props.switcherData.orgCode) {
      getPlatformsDataOptions(props.switcherData.orgCode);
    } else {
      // 重置平台的选择项数据
      setPlatformDataOptions([]);
    }

    if (props.userPermissionsData["CNExternalUsers"]) {
      form.setFieldsValue({ platform: null, productCode: null });
    } else {
      form.setFieldsValue({ platform: null, customerCode: null, productCode: null });
    }
  }, [form, props.userPermissionsData, props.switcherData.orgCode]);

  // 只要switcher中的customerCode的值发生变化需要处理的其他相关项
  useEffect(() => {
    if (props.userPermissionsData["CNExternalUsers"]) {
      const switcherCustomerCodeVal = props.switcherData.customerCode || null;
      if (props.switcherData?.customerName.split(",").length > 1) {
        const allCurrentCustomerOptionsTemp = props.switcherData.customerName
          .split(",")
          .map((item: string, customerIndex: number) => {
            return {
              label: `${item}(${switcherCustomerCodeVal.split(",")[customerIndex]})`,
              value: switcherCustomerCodeVal.split(",")[customerIndex]
            };
          });
        allCurrentCustomerOptionsTemp.unshift({ value: null, label: "全部买方" });
        setAllCurrentCustomerOptions(allCurrentCustomerOptionsTemp);
        form.setFieldsValue({ customerCode: null });
      } else if (isHaveRolePermissionFeedFactory()) {
        // 集团用户-customer-options-正大饲料处理
        const localEnumInfoObj = getLocalStorageItem("localEnumInfo")
          ? JSON.parse(getLocalStorageItem("localEnumInfo"))
          : {};
        if (localEnumInfoObj && localEnumInfoObj.FEED_TAG === "Y" && localEnumInfoObj.FEED_MODEL) {
          setAllCurrentCustomerOptions([
            {
              label: localEnumInfoObj.FEED_MODEL?.feedName,
              value: switcherCustomerCodeVal
            }
          ]);
          form.setFieldsValue({
            customerName: switcherCustomerCodeVal
          });
        }
      } else {
        const onlyOneValue = `${props.switcherData.customerName}(${switcherCustomerCodeVal})`;
        const onlyOneCustomerOption = [{ label: onlyOneValue, value: switcherCustomerCodeVal }];
        setAllCurrentCustomerOptions(onlyOneCustomerOption);
        form.setFieldsValue({ customerCode: switcherCustomerCodeVal });
      }
    }
  }, [form, props.switcherData.customerName, props.switcherData.customerCode, props.userPermissionsData]);

  // 只要switcher中设置的值发生改变则需要重新赋初始值
  useEffect(() => {
    if (
      props.switcherData.orgCode !== form.getFieldValue("orgCode") ||
      props.switcherData.divisionCode !== form.getFieldValue("divisionCode")
    ) {
      form.setFieldsValue({
        orgCode: props.switcherData.orgCode || null,
        divisionCode: props.switcherData.divisionCode || null
      });
    }
  }, [form, props.switcherData]);

  // 处理切换高级选项的状态
  const changeAdvanceFilterStatus = () => {
    if (currentCollapseActiveKey) {
      setIsShowAdvanceFilter(false);
      setIsShowAdvanceFilterText("展开高级筛选");
      setCurrentCollapseActiveKey("");
    } else {
      setIsShowAdvanceFilter(true);
      setIsShowAdvanceFilterText("收起高级筛选");
      setCurrentCollapseActiveKey("slot-booking-search-filters-01");
    }
  };

  //处理卖方工厂的切换
  const handleSupplierChange = useCallback(
    (value: any) => {
      // orgCode的value更新，重新请求可选平台的数据
      if (value) {
        getPlatformsDataOptions(value);
      } else {
        // 重置平台的选择项数据--只跟卖方工厂orgCode相关
        setPlatformDataOptions([]);
      }

      if (props.userPermissionsData["CNExternalUsers"]) {
        // 清空时，多个买方重置为全部
        const customerCodes = props.switcherData["customerCode"] ? props.switcherData["customerCode"].split(",") : [];
        if (customerCodes.length > 1) {
          form.setFieldsValue({ customerCode: null });
        }
        form.setFieldsValue({ platform: null, productCode: null });
      } else {
        form.setFieldsValue({ platform: null, customerCode: null, productCode: null });
      }
    },
    [form, props.switcherData, props.userPermissionsData]
  );

  // 清空重置customerCode的数据
  const resetCustomerValue = useCallback(() => {
    if (props.userPermissionsData["CNInternalUsers"]) {
      form.setFieldsValue({ customerCode: null });
    }
  }, [form, props.userPermissionsData]);

  //处理业务线的切换
  const handleBusinessLineChange = useCallback(
    (value: any) => {
      resetCustomerValue();
    },
    [resetCustomerValue]
  );

  // 获取平台可选数据--platformDataOptions
  const getPlatformsDataOptions = (orgCodeVal: string) => {
    const getParamsObj = {
      vague: "",
      orgCode: orgCodeVal,
      queryType: "platforms"
    };
    getProductQueryApi(getParamsObj)
      .then((responseJSON: any) => {
        if (responseJSON?.data?.platforms) {
          const platformsData = responseJSON.data.platforms;
          const NewData = platformsData.map((item: any) => ({
            label: item.name,
            value: item.name,
            loading_rule: item.loadingRule
          }));
          setPlatformDataOptions(NewData);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
      });
  };

  // 内部用户--获取买方选项(防抖输入查询)
  const fetchCustomerOptions_Internal = useCallback(
    (value: string, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      const getParamsObj = {
        customerName: value,
        orgCode: form.getFieldValue("orgCode"),
        divisionCode: form.getFieldValue("divisionCode")
      };
      getFirmDetailListApi(getParamsObj)
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            callback([]);
            message.error(res.errorMsg);
          } else if (res.data?.customerList?.length > 0 && currentValue === value) {
            let data: any[] = res.data?.customerList?.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerCode,
                key: val.customerCode
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    [form]
  );

  // 获取产品名称选项
  const fetchProductNameOptions = useCallback(
    (value: string, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      getProductsApi({
        productName: value,
        salesOrgs: form.getFieldValue("orgCode")
      })
        .then((responseJSON: any) => {
          if (responseJSON?.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(responseJSON.errorMsg);
            callback([]);
          } else if (responseJSON?.data?.products.length > 0 && currentValue === value) {
            let NewData = [];
            if (props.userPermissionsData.CNExternalUsers) {
              NewData = responseJSON.data.products.map((item: any) => ({
                label: item.productName,
                value: cutHeaderZero(item.productCode)
              }));
              NewData = noRepeat(NewData);
            } else {
              NewData = responseJSON.data.products.map((item: any) => ({
                label: `${item.productName} (${cutHeaderZero(item.productCode)})`,
                value: cutHeaderZero(item.productCode)
              }));
            }
            callback(NewData);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    [form, props.userPermissionsData.CNExternalUsers]
  );

  const isDisabledAction = (key: string) => {
    if (props.userPermissionsData["CNExternalUsers"]) {
      // 业务线和卖方 -- 应后端设计，选择全部时，传值是“All”，买方公司的还是逗号“,”分隔
      // 买方有多个时，不置灰
      if (key === "customerCode") {
        const customerCodes = props.switcherData[key] ? props.switcherData[key].split(",") : [];
        if (customerCodes.length > 1) {
          return false;
        }
      }
      return props.switcherData[key] && props.switcherData[key] !== "All";
    } else if (props.userPermissionsData["CNInternalUsers"]) {
      return false;
    }
    return true;
  };

  const handleMySubmit = useCallback(
    (formValues?: any) => {
      const values = { ...form.getFieldsValue(true) };
      // 将条件所有值传递给父组件
      if (props.doSearch) {
        // 将时间拆分成两个字段并转换为字符串
        values.startTime = "";
        values.endTime = "";
        if (values.bookingDateRange && values.bookingDateRange.length > 0) {
          values.startTime = moment(values.bookingDateRange[0]).format("YYYY-MM-DD");
          values.endTime = moment(values.bookingDateRange[1]).format("YYYY-MM-DD");
        } else {
          // 删除不需要传递的字段
          delete values.bookingDateRange;
        }

        // 有多个买方时, 买方传递全部值
        const customerCodes = props.switcherData["customerCode"] ? props.switcherData["customerCode"].split(",") : [];
        if (customerCodes.length > 1) {
          if (values.customerCode === null) {
            values.customerCode = props.switcherData["customerCode"];
          }
        }

        values.productCode = values.productCode || "";
        values.platform = values.platform || "";

        // 传递筛选的最终结果给到父级组件
        props.doSearch(values);
      }
    },
    [form, props]
  );

  const onResetForm = useCallback(() => {
    form.resetFields();
    // 更新与orgCode相关数据
    handleSupplierChange(props.switcherData.orgCode);
    // 自动重新获取数据
    handleMySubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, props.switcherData.orgCode]);

  // 只要form的值或者switcher中设置的值发生改变则需要重新通知父级组件重新获取新值
  useEffect(() => {
    handleMySubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 只要switcher中的业务线divisionCode的值发生变化需要处理的其他相关项
  useEffect(() => {
    resetCustomerValue();
  }, [resetCustomerValue]);

  return (
    <div className="search-filters">
      <Card
        style={{ boxShadow: "0 5px 9px #dedbdb" }}
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        title="查询条件"
        headStyle={{
          backgroundColor: "white",
          borderBottom: "none"
        }}
      >
        <Form
          form={form}
          name="search_filter_form"
          layout="vertical"
          onFinish={handleMySubmit}
          initialValues={{
            orgCode: props.switcherData.orgCode || null,
            divisionCode: props.switcherData.divisionCode || null,
            customerCode: props.switcherData.customerCode || null,
            productCode: null,
            platform: null,
            plateNo: "",
            bookingDateRange: [],
            status: ""
          }}
        >
          {/* 基础筛选条件 */}
          <div className="hdp-uf hdp-uf-dc" style={{ width: "100%" }}>
            <div className="hdp-uf hdp-uf-ww">
              <Form.Item name="orgCode" className="form-item">
                <Select
                  data-testid="test-orgCode-select"
                  placeholder="请选择卖方工厂"
                  disabled={isDisabledAction("orgCode")}
                  options={allSupplier()}
                  onChange={handleSupplierChange}
                  style={{ width: 240 }}
                />
              </Form.Item>
              <Form.Item name="divisionCode" className="form-item">
                <Select
                  data-testid="test-divisionCode-select"
                  placeholder="请选择业务线"
                  disabled={isDisabledAction("divisionCode")}
                  options={allBusinessLine()}
                  onChange={handleBusinessLineChange}
                  style={{ width: 170 }}
                />
              </Form.Item>

              {props.userPermissionsData["CNInternalUsers"] && (
                <Form.Item shouldUpdate>
                  {() => {
                    return (
                      <Form.Item name="customerCode" className="form-item">
                        <RemoteSelect
                          dataTestId="test-internalUser-customerCode-select"
                          placeholder="请输入公司名称查询"
                          disabled={!form.getFieldValue("orgCode")}
                          fetchOptions={fetchCustomerOptions_Internal}
                          loading={isSelectFetching}
                          style={{ minWidth: 245 }}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              )}

              {props.userPermissionsData["CNExternalUsers"] && (
                <Form.Item shouldUpdate>
                  {() => {
                    return (
                      <Form.Item name="customerCode" className="form-item">
                        <Select
                          placeholder="请选择公司名称"
                          disabled={isDisabledAction("customerCode")}
                          options={allCurrentCustomerOptions}
                          style={{ minWidth: 245 }}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              )}

              <Form.Item shouldUpdate>
                {() => {
                  return (
                    <Form.Item name="productCode" className="form-item">
                      <RemoteSelect
                        dataTestId="test-productCode-select"
                        placeholder="请输入产品名称查询"
                        disabled={!form.getFieldValue("orgCode")}
                        fetchOptions={fetchProductNameOptions}
                        loading={isSelectFetching}
                        style={{ minWidth: 245 }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>

              <Form.Item shouldUpdate className="form-item">
                {() => (
                  <Button
                    data-testid="test-search-basic-filters"
                    type="primary"
                    htmlType="submit"
                    icon={<SearchOutlined />}
                    disabled={isShowAdvanceFilter}
                  >
                    查询
                  </Button>
                )}
              </Form.Item>
            </div>
            {/* 点击查看高级筛选 */}
            <div className="advance-filters">
              <Collapse ghost collapsible="header" activeKey={currentCollapseActiveKey} expandIconPosition="right">
                <Panel
                  header=""
                  extra={
                    <div
                      data-testid="test-open-advance-filters"
                      className="collapse-active-btn"
                      onClick={changeAdvanceFilterStatus}
                    >
                      {isShowAdvanceFilterText}
                    </div>
                  }
                  key="slot-booking-search-filters-01"
                  className="advance-filters-container"
                >
                  {/* 高级筛选条件 */}
                  <Divider dashed={true} style={{ width: "100%", borderColor: "#eee", margin: "-12px 0 12px 0" }} />
                  <div className="hdp-uf hdp-uf-dc">
                    <div className="hdp-uf hdp-uf-ww">
                      <Form.Item shouldUpdate>
                        {() => {
                          return (
                            <Form.Item name="platform" label="发货平台" colon={false} className="form-item">
                              <Select
                                allowClear
                                placeholder="请选择发货平台"
                                disabled={!form.getFieldValue("orgCode")}
                                options={platformDataOptions}
                                style={{ width: 200 }}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                      <Form.Item name="plateNo" label="预约车号" className="form-item">
                        <Input allowClear placeholder="请输入预约车号" />
                      </Form.Item>
                      <Form.Item name="bookingDateRange" label="提单提交的起始日期和截止日期" className="form-item">
                        <RangePicker data-testid="test-booking-dateRange" placeholder={["起始日期", "截止日期"]} />
                      </Form.Item>
                      {(!props.excludeFields || !props.excludeFields.includes("status")) && (
                        <Form.Item name="status" label="预约状态" colon={false} className="form-item">
                          <Select placeholder="请选择预约状态" options={allBookingStatus} style={{ width: 200 }} />
                        </Form.Item>
                      )}
                    </div>
                    <div className="hdp-uf hdp-uf-hfe advance-filter-actions">
                      <Form.Item shouldUpdate className="form-item">
                        <Button
                          data-testid="test-search-advance-filters-reset"
                          className="form-btn-reset"
                          disabled={!isShowAdvanceFilter}
                          onClick={onResetForm}
                        >
                          清空
                        </Button>
                      </Form.Item>
                      <Form.Item shouldUpdate className="form-item">
                        {() => (
                          <Button
                            data-testid="test-search-advance-filters"
                            type="primary"
                            htmlType="submit"
                            disabled={!isShowAdvanceFilter}
                          >
                            立即查询
                          </Button>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
        </Form>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userPermissionsData: state.userPermissions,
  switcherData: state.switcher,
  userDetailsData: state.userDetails
});

export default connect(mapStateToProps, null)(SearchFilters);
