import RequestService from "@/api/request";

const getUserList = (dataObj: object) => {
  return RequestService({
    // baseURL: process.env.REACT_APP_USER_SERVICES,
    url: "contractsap/listUser",
    method: "get",
    params: dataObj
  });
};

const getErrorList = (dataObj: object) => {
  return RequestService({
    url: "contractsap/listErrorTask",
    method: "get",
    params: dataObj
  });
};

const deactivateUser = (emailId: string) => {
  return RequestService({
    baseURL: process.env.REACT_APP_USER_SERVICES,
    url: `/users/${emailId}/deactivate/`,
    method: "post",
    data: {},
    params: {}
  });
};

const reinviteUser = (emailId: string) => {
  return RequestService({
    baseURL: process.env.REACT_APP_USER_SERVICES,
    url: `/users/${emailId}/reinvite/`,
    method: "post",
    data: {},
    params: {}
  });
};
const resetPassword = (emailId: string) => {
  return RequestService({
    baseURL: process.env.REACT_APP_USER_SERVICES,
    url: `/users/${emailId}/resetpassword/`,
    method: "post",
    data: {},
    params: {}
  });
};
const triggerErrorTask = (dataObj: object) =>
  RequestService({
    url: "contractsap/triggerErrorTask",
    method: "patch",
    data: dataObj,
    params: {}
  });

const saveUser = (data: object) => {
  return RequestService({
    // baseURL: process.env.REACT_APP_USER_SERVICES,
    url: "contractsap/modifiedUser",
    method: "patch",
    data: data,
    params: {}
  });
};
const addUser = (data: object) => {
  return RequestService({
    // baseURL: process.env.REACT_APP_USER_SERVICES,
    url: "contractsap/addUser",
    method: "post",
    data: data,
    params: {}
  });
};

const getFeedOption = (dataObj: object) => {
  return RequestService({
    url: "contractsap/feed",
    method: "get",
    params: dataObj
  });
};
const getByOktaIds = (data: object, params: object) => {
  return RequestService({
    url: "contractsap/feed/getByOktaIds",
    method: "post",
    data: data,
    params: params
  });
};
const saveEmailId = (data: object, params: object) => {
  return RequestService({
    url: "contractsap/feed/saveEmailId",
    method: "post",
    data: data,
    params: params
  });
};

const getNoticeInfo = (dataObj: object) => {
  return RequestService({
    url: "notificationsap/getNoticeInfo",
    method: "get",
    params: dataObj
  });
};

const saveNoticeInfo = (paramsObj: object, dataObj: object) => {
  return RequestService({
    url: "notificationsap/notice/save",
    method: "post",
    params: paramsObj,
    data: dataObj
  });
};

export {
  getUserList,
  getErrorList,
  deactivateUser,
  reinviteUser,
  resetPassword,
  triggerErrorTask,
  saveUser,
  addUser,
  getFeedOption,
  getByOktaIds,
  saveEmailId,
  getNoticeInfo,
  saveNoticeInfo
};
