import { AES, enc } from "crypto-js";

const setLocalStorageItem = (key: string, value: any) => {
  const { REACT_APP_ENCRYPT_KEY } = process.env;
  let storeValue = value;
  if (typeof value === "object") {
    storeValue = JSON.stringify(value);
  }
  const enLocalStorageData = AES.encrypt(storeValue, REACT_APP_ENCRYPT_KEY).toString();
  const data = `${enLocalStorageData}Dsc`;
  localStorage.setItem(key, data);
};

const getLocalStorageItem = (key: string) => {
  const { REACT_APP_ENCRYPT_KEY } = process.env;
  const sourceData = localStorage.getItem(key);
  if (!sourceData || sourceData === null || sourceData.slice(-3) !== "Dsc") {
    return sourceData;
  }
  const data = sourceData.slice(0, -3);
  const deLocalStorageData = AES.decrypt(data, REACT_APP_ENCRYPT_KEY).toString(enc.Utf8);
  return deLocalStorageData;
};

export { setLocalStorageItem, getLocalStorageItem };
