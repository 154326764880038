import { useState, useEffect, useRef, useCallback } from "react";
import { Form, Select, Row, Col, Radio, Input, DatePicker, Button, Divider, Table, Spin, Tooltip } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import BuyerModal from "../buyerModal";
import "./index.scss";
import { getValidFirmCode, getProductionSettingList, submitNotification, saveNotification } from "@/api/systemSetting";
import { allSupplier, allBusinessLine } from "@/utils/constant";
import UploadBtn from "@/components/upload-btn";
import { connect } from "react-redux";
import moment from "moment";
import DownloadNotice from "../../downloadNotice";

const { TextArea } = Input;

const uploadConfig = {
  actionUrl: "/notificationsap/document/upload",
  sizeLimit: 10,
  showUploadList: true,
  btnType: "default",
  btnText: "添加附件",
  idtoken: true
};
const ContractSetting = (props: any) => {
  const { switcher } = props;
  const childRef = useRef<any>();
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showFirm, setShowFirm] = useState(false);
  const [isFirmId, setIsFirmId] = useState(false);
  const [isWrongDate, setIsWrongDate] = useState(false);
  const [notificationTypeInput, setNotificationTypeInput] = useState(false);
  const [companyIdInput, setCompanyIdInput] = useState(false);
  const [startTimeInput, setStartTimeInput] = useState(false);
  const [endTimeInput, setEndTimeInput] = useState(false);
  const [contentInput, setContentInput] = useState(false);
  const [selected, setSelected] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [firmSpining, setFirmSpinning] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [origRecord, setOrigRecord] = useState({});
  const [codeCache, setCodecCache] = useState({
    siteCode: "",
    orgCode: ""
  });
  const [siteCodeDis, setSiteCodeDis] = useState(false);
  const [link, setLink] = useState(null);
  const [searchValue, setSearchValue] = useState({
    plant: ""
  });
  const [requestData, setRequestData] = useState({
    noticeType: 3,
    noticeFrom: 0,
    isSubmit: 0,
    limit: 10,
    offset: 0
  });

  const onPageChange = (page: number) => {
    setCurrent(page);
    const pager = JSON.parse(JSON.stringify(requestData));
    pager.offset = (page - 1) * 10;
    setRequestData(pager);
  };
  const paginationOpt: any = {
    showQuickJumper: true,
    total: total,
    pageSize: 10,
    showSizeChanger: false,
    onChange: onPageChange,
    current: current,
    size: "default"
  };

  const returnType = (num: string) => {
    if (num === "404") {
      return "追加保证金";
    }
  };

  const columns: any = [
    {
      title: "通知类型",
      dataIndex: "notificationType",
      key: "notificationType",
      render: (text: any) => <>{returnType(text)}</>
    },
    {
      title: "卖方",
      dataIndex: "siteName",
      key: "siteName",
      render: (text: any) => <>{text === null ? "全部卖方" : text}</>
    },
    {
      title: "客户代码",
      dataIndex: "receiveCustomerCode",
      key: "receiveCustomerCode",
      render: (text: any) => <>{text === null ? "全部买方" : text}</>
    },
    {
      title: "公告接收方工厂",
      dataIndex: "receivePlant",
      key: "receivePlant",
      render: (text: any) => <>{text === null ? "全部卖方" : text}</>
    },
    {
      title: "公告接收方业务线",
      dataIndex: "receiveBusinessLine",
      key: "receiveBusinessLine",
      render: (text: any) => <>{text === null ? "全部业务" : text}</>
    },
    {
      title: "买方",
      dataIndex: "receiveBuyer",
      key: "receiveBuyer",
      render: (text: any) => <>{text === null ? "全部买方" : text}</>
    },
    { title: "发布开始时间", dataIndex: "publishDate", key: "publishDate" },
    { title: "发布截止时间", dataIndex: "publishEndDate", key: "publishEndDate" },
    {
      title: "通知内容",
      onCell: () => {
        return {
          style: {
            maxWidth: 150,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            cursor: "pointer"
          }
        };
      },
      render: (record: any) => (
        <Tooltip placement="topLeft" title={record.content ? record.content : null}>
          {record.content ? record.content : null}
        </Tooltip>
      )
    },
    {
      title: "附件",
      render: (record: any) =>
        record.link !== null ? <DownloadNotice data={record.id} filename="appendix.xls" /> : <span>-</span>
    },
    {
      title: "删除",
      render: (record: any) => (
        <BuyerModal delId={record.id} noticeFrom={0} getListData={getListData} notificationType="通知" />
      ),
      fixed: "right" as "right"
    }
  ];

  useEffect(() => {
    getListData();
  }, [requestData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (switcher) {
      const { orgName, orgCode } = switcher;
      let value = searchValue;
      value.plant = orgName || "";

      form.setFieldsValue({
        siteName: orgCode || ""
      });

      setSearchValue(value);
      if (orgCode !== codeCache.orgCode) {
        setSiteCodeDis(true);
      }

      if (orgCode === "" || orgCode === "All") {
        setSiteCodeDis(false);
      }
      setCodecCache({
        siteCode: orgName,
        orgCode: orgCode
      });
    }
  }, [codeCache.orgCode, form, searchValue, switcher]);

  useEffect(() => setSelectedRowKeys([]), [data]);

  const getListData = () => {
    const values = requestData;
    setLoading(true);
    getProductionSettingList(values)
      .then(res => {
        if (res.data.notices.notifications) {
          const tableData = res.data.notices.notifications;
          const total = res.data.notices.metaData.totalCount;
          setData(tableData);
          setTotal(total);
          setLoading(false);
        } else {
          setData([]);
          setTotal(0);
          setLoading(false);
        }
      })
      .finally(() => setCurrent(1));
  };

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
    if (selectedRowKeys.length !== 0) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    fixed: true
  };

  const returnBusinessLine = (divisionCode: string) => {
    let lineCode = "全部业务";
    const chooseBusinessLineArr = allBusinessLine();
    for (let businessLineItem of chooseBusinessLineArr) {
      if (businessLineItem.value === divisionCode) {
        lineCode = businessLineItem.label;
        break;
      }
    }
    return lineCode;
  };

  const returnPlant = (orgCode: string) => {
    let plant = "全部卖方";
    const allSupplierArr = allSupplier();
    for (let supplierItem of allSupplierArr) {
      if (supplierItem.value === orgCode) {
        plant = supplierItem.label;
        break;
      }
    }
    return plant;
  };

  const returnReceivePlant = (orgCode: string) => {
    let plant = "";
    const chooseAllSupplierArr = allSupplier();
    for (let allSupplierItem of chooseAllSupplierArr) {
      if (allSupplierItem.value === orgCode) {
        plant = allSupplierItem.label;
        break;
      }
    }
    return plant;
  };

  // 从onFinish提取出setValueOnFinish方法
  const setValueOnFinish = () => {
    if (switcher) {
      const { orgCode, customerName } = switcher;
      let value = searchValue;
      value.plant = orgCode || "";
      if (customerName) {
        value.plant = orgCode === null ? "All" : orgCode;
      }
      form.setFieldsValue({
        siteName: orgCode || ""
      });
      setSearchValue(value);
    }
  };

  const onFinish = useCallback(
    (values: any) => {
      setSpinning(true);
      const request = {
        receiveCustomerCode: values.companyId,
        firmCode: values.companyId,
        receiveBuyer: values.isAssignBuyer === "0" ? null : accountName,
        content: values.content,
        id: isEdit ? editId : null,
        isAssignBuyer: "1",
        isAssignEffectiveTime: values.isAssignEffectiveTime,
        isAssignPublishTime: values.isAssignPublishTime,
        isExistsFile: 0,
        isSubmit: 0,
        link: link,
        noticeFrom: 0,
        noticeType: 3,
        notificationType: values.notificationType,
        publishDate:
          values.isAssignPublishTime === "0"
            ? moment().format("YYYY-MM-DD HH:mm")
            : values.publishDate.format("YYYY-MM-DD HH:mm"),
        publishEndDate:
          values.isAssignEffectiveTime === "0"
            ? moment().format("YYYY-MM-DD 24:00")
            : values.publishEndDate.format("YYYY-MM-DD HH:mm"),
        siteCode: values.siteName || (switcher.orgCode === null ? "" : switcher.orgCode),
        siteName: values.siteName === "" ? returnPlant(switcher.orgCode) : returnPlant(values.siteName),
        receiveDivisionCode: values.receiveBusinessLine,
        receiveBusinessLine: returnBusinessLine(values.receiveBusinessLine),
        receiveOrgCode: values.receivePlant,
        receivePlant: returnReceivePlant(values.receivePlant),
        groupCode: null,
        groupName: null,
        params: isEdit ? origRecord : null
      };
      saveNotification(request, {}).then(res => {
        if (res.data) {
          getListData();
          setIsEdit(false);
          setEditId(null);
          setSpinning(false);
        }
      });
      form.resetFields();
      resetFileList();
      setAccountName("");
      setLink(null);
      setNotificationTypeInput(false);
      setCompanyIdInput(false);
      setStartTimeInput(false);
      setEndTimeInput(false);
      setContentInput(false);
      setAccountName("");
      setValueOnFinish();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountName, editId, form, isEdit, link, origRecord, setValueOnFinish, switcher.orgCode]
  );

  const handleFirmDetail = useCallback(() => {
    if (form.getFieldValue("companyId")) {
      setFirmSpinning(true);
      const request = {
        accountNumber: form.getFieldValue("companyId")
      };
      getValidFirmCode(request)
        .then(res => {
          if (res.data.customerInfoEntitlement.accountLongName) {
            setShowFirm(true);
            setIsFirmId(true);
            setAccountName(res.data.customerInfoEntitlement.accountLongName);
          } else {
            setShowFirm(true);
            setIsFirmId(false);
            form.setFieldsValue({ companyId: null });
          }
        })
        .finally(() => setFirmSpinning(false));
    }
  }, [form]);

  const handlePublishDateChange = useCallback(() => {
    if (form.getFieldValue("publishDate")) {
      form.setFieldsValue({ isAssignPublishTime: "1" });
      setStartTimeInput(true);
    }
    if (form.getFieldValue("publishDate") && form.getFieldValue("publishEndDate")) {
      if (form.getFieldValue("publishDate") >= form.getFieldValue("publishEndDate")) {
        setIsWrongDate(true);
      } else {
        setIsWrongDate(false);
      }
    }
  }, [form]);

  const handlePublishEndDateChange = useCallback(() => {
    if (form.getFieldValue("publishEndDate")) {
      form.setFieldsValue({ isAssignEffectiveTime: "1" });
      setEndTimeInput(true);
      if (form.getFieldValue("publishDate") && form.getFieldValue("publishEndDate")) {
        if (form.getFieldValue("publishDate") >= form.getFieldValue("publishEndDate")) {
          setIsWrongDate(true);
        } else {
          setIsWrongDate(false);
        }
      }
    }
  }, [form]);

  const handleSubmit = useCallback(() => {
    const request = {
      ids: selectedRowKeys,
      isSubmit: 1,
      noticeFrom: 0
    };
    setSpinning(true);
    submitNotification(request, {})
      .then(res => {
        if (res.data.notices) {
          getListData();
          setSpinning(false);
        }
      })
      .finally(() => setSelected(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowKeys]);

  const returnPickerTime = (dateStr: any) => {
    let date = dateStr + ":00.0";
    date = date.substring(0, 19);
    date = date.replace(/-/g, "/"); //必须把日期'-'转为'/'
    const timestamp = moment(new Date(date).getTime());
    return timestamp;
  };

  const handleNotificationTypeChange = useCallback(() => {
    setNotificationTypeInput(true);
  }, []);

  const handleFirmIdChange = useCallback(() => {
    if (form.getFieldValue("companyId")) {
      setCompanyIdInput(true);
    } else {
      setCompanyIdInput(false);
      setShowFirm(false);
    }
  }, [form]);

  const handleStartTimeChange = useCallback(
    (e: any) => {
      if (e.target.value === "0" || form.getFieldValue("publishDate")) {
        setStartTimeInput(true);
      } else {
        setStartTimeInput(false);
      }
    },
    [form]
  );

  const handleEndTimeChange = useCallback(
    (e: any) => {
      if (e.target.value === "0" || form.getFieldValue("publishEndDate")) {
        setEndTimeInput(true);
      } else {
        setEndTimeInput(false);
      }
    },
    [form]
  );

  const handleContentChange = useCallback(() => {
    if (form.getFieldValue("content")) {
      setContentInput(true);
    } else {
      setContentInput(false);
    }
  }, [form]);

  const handleUpload = useCallback((res: any) => {
    setLink(res.rendition.docUrl);
  }, []);

  const handleRemoveUpload = useCallback(() => {
    setLink(null);
  }, []);

  const resetFileList = () => {
    childRef.current?.resetFileList();
  };

  const onHandleRowClick = useCallback(
    (record: any) => {
      const setSaveNotificationValue = (record: any) => {
        setIsEdit(true);
        setEditId(record.id);
        form.setFieldsValue({
          siteName: record.siteName === "全部卖方" || record.siteName === null ? "" : record.siteCode,
          notificationType: parseInt(record.notificationType),
          companyId: record.receiveCustomerCode,
          isAssignPublishTime: record.isAssignPublishTime,
          publishDate: record.isAssignPublishTime === "1" ? returnPickerTime(record.publishDate) : null,
          isAssignEffectiveTime: record.isAssignEffectiveTime,
          publishEndDate: record.isAssignEffectiveTime === "1" ? returnPickerTime(record.publishEndDate) : null,
          content: record.content,
          receiveBusinessLine: record.receiveDivisionCode === null ? "" : record.receiveDivisionCode,
          receivePlant: record.receiveOrgCode === null ? "" : record.receiveOrgCode
        });
        handleFirmDetail();
        setNotificationTypeInput(true);
        setCompanyIdInput(true);
        setStartTimeInput(true);
        setEndTimeInput(true);
        setContentInput(true);
        setOrigRecord(record);
      };

      return {
        onClick: () => {
          setSaveNotificationValue(record);
        }
      };
    },
    [form, handleFirmDetail]
  );

  return (
    <div>
      <Spin spinning={spinning}>
        <div className="form-container">
          <Form name="production-setting-form" layout="vertical" autoComplete="off" onFinish={onFinish} form={form}>
            <div className="form-title">通知发送方</div>
            <Row justify="space-between">
              <Col md={5} xs={24}>
                <Form.Item
                  label={
                    <>
                      <span className="required-mark">*</span>卖方
                    </>
                  }
                  name="siteName"
                  initialValue=""
                >
                  <Select disabled={siteCodeDis} options={allSupplier()} data-testid="sitename" />
                </Form.Item>
              </Col>
              <Col span={5} />
              <Col span={5} />
              <Col span={5} />
            </Row>
            <div className="form-title">通知类型</div>
            <Row>
              <Col span={24}>
                <Form.Item label="通知类型" name="notificationType" rules={[{ required: true }]}>
                  <Radio.Group className="radio-group" onChange={handleNotificationTypeChange}>
                    <Radio value={404}>追加保证金</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div className="form-title">通知接收方</div>
            <Row justify="space-between">
              <Col md={5} xs={24}>
                <Form.Item label="公司代码" name="companyId" rules={[{ required: true }]}>
                  <Input
                    data-testid="firm-input"
                    onBlur={handleFirmDetail}
                    placeholder="请输入公司代码"
                    onChange={handleFirmIdChange}
                  />
                </Form.Item>
                <Spin spinning={firmSpining}>
                  {showFirm && isFirmId && (
                    <div style={{ fontWeight: 600, fontSize: 12, color: "#000", marginTop: -25 }}>{accountName}</div>
                  )}
                  {showFirm && !isFirmId && (
                    <div style={{ fontWeight: 600, fontSize: 12, marginTop: -25 }}>
                      <ExclamationCircleFilled style={{ color: "#cd0d15", marginRight: 8 }} />
                      请输入有效的公司代码！
                    </div>
                  )}
                </Spin>
              </Col>
              <Col span={5} />
              <Col span={5} />
              <Col span={5} />
            </Row>
            <Row justify="space-between">
              <Col md={5} xs={24}>
                <Form.Item label="业务线" name="receiveBusinessLine" initialValue="" rules={[{ required: true }]}>
                  <Select options={allBusinessLine()} />
                </Form.Item>
              </Col>
              <Col md={5} xs={24}>
                <Form.Item label="卖方" name="receivePlant" initialValue="" rules={[{ required: true }]}>
                  <Select options={allSupplier()} />
                </Form.Item>
              </Col>
              <Col span={5} />
              <Col span={5} />
            </Row>
            <div className="form-title">设置通知发布与失效时间</div>
            <Row justify="space-between">
              <Col md={5} xs={24}>
                <Form.Item label="发布开始时间" name="isAssignPublishTime" rules={[{ required: true }]}>
                  <Radio.Group className="radio-group" onChange={handleStartTimeChange}>
                    <Radio value={"0"}>立即发送</Radio>
                    <Radio value={"1"}>指定时间</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={5} xs={24}>
                <Form.Item label=" " name="publishDate">
                  <DatePicker showTime onChange={handlePublishDateChange} placeholder="请选择" />
                </Form.Item>
              </Col>
              <Col span={5} />
              <Col span={5} />
            </Row>
            <Row justify="space-between">
              <Col md={5} xs={24}>
                <Form.Item label="发布截止时间" name="isAssignEffectiveTime" rules={[{ required: true }]}>
                  <Radio.Group className="radio-group" onChange={handleEndTimeChange}>
                    <Radio value={"0"}>仅当日有效</Radio>
                    <Radio value={"1"}>指定时间</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={5} xs={24}>
                <Form.Item label=" " name="publishEndDate">
                  <DatePicker showTime onChange={handlePublishEndDateChange} placeholder="请选择" />
                </Form.Item>
                {isWrongDate ? (
                  <div style={{ fontWeight: 600, fontSize: 12, color: "#000" }}>
                    <ExclamationCircleFilled style={{ color: "#cd0d15", marginRight: 8 }} />
                    请输入有效的发布/失效时间！
                  </div>
                ) : null}
              </Col>
              <Col span={5} />
              <Col span={5} />
            </Row>
            <div className="form-title">编辑通知内容</div>
            <Form.Item label="通知内容" name="content" rules={[{ required: true }]}>
              <TextArea
                data-testid="test-textarea"
                placeholder="请输入"
                className="text-area"
                onChange={handleContentChange}
              />
            </Form.Item>
            <Row>
              <Col>
                <UploadBtn
                  upload_config={uploadConfig}
                  cb_upload={handleUpload}
                  cb_remove={handleRemoveUpload}
                  cRef={childRef}
                />
              </Col>
              <span className="upload-notice">支持扩展名:</span>
              <span className="upload-notice">.xls</span>
              <span className="upload-notice">.xlsx</span>
              <span className="upload-notice">.jpg</span>
              <span className="upload-notice">.jpeg</span>
              <span className="upload-notice">.png</span>
              <span className="upload-notice">.doc</span>
              <span className="upload-notice">.docx</span>
              <span className="upload-notice">.pdf...</span>
              <Col span={24} className="align-right">
                <Form.Item>
                  <Button
                    data-testid="save-btn"
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !notificationTypeInput || !companyIdInput || !startTimeInput || !endTimeInput || !contentInput
                    }
                  >
                    保存
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <Divider />
        <div className="table-container">
          <Row>
            <Col span={12} className="table-title">
              已建通知
            </Col>
            <Col span={12} className="align-right  total-item">
              共 {total} 条
            </Col>
          </Row>
          <Table
            columns={columns}
            pagination={paginationOpt}
            dataSource={data}
            scroll={{ x: "max-content" }}
            rowSelection={rowSelection}
            rowKey="id"
            loading={loading}
            size="small"
            onRow={onHandleRowClick}
          />
          <Row>
            <Col span={24} className="align-right">
              <Button
                data-testid="test-submit"
                type="primary"
                onClick={handleSubmit}
                disabled={!selected}
                style={{ marginTop: 10 }}
              >
                提交
              </Button>
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  userDetails: state.userDetails,
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(ContractSetting);
