import { useCallback, useState } from "react";
import { Button, Form, Input, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { postUpdatePassword } from "@/api/userCenter";
import * as type from "../constant";

const UpdatePassword = (props: any) => {
  const [isChangePwd, setIsChangePwd] = useState(false);
  const [form] = Form.useForm();

  // 修改密码
  const onChangePwdFinish = useCallback(
    (values: any) => {
      form.setFieldsValue({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
      });
      postUpdatePassword({
        oldPassword: { value: values.oldPassword },
        newPassword: { value: values.newPassword }
      })
        .then((res: any) => {
          if (res.code && res.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(res.errorMsg);
          } else {
            message.success(type.UP_SUCCESS_MSG);
          }
        })
        .catch((exception: any) => {
          console.error(`Failure: ${exception}`);
          if (exception.data.error.message[0]) {
            if (exception.data.error.message[0] === "Old Password is not correct") {
              message.error("原密码错误！");
            } else if (exception.data.error.message[0] === "Password cannot be your current password") {
              message.error("新密码不能与原密码相同！");
            } else {
              message.error(exception.data.error.message[0]);
            }
          }
        });
    },
    [form]
  );

  return (
    <div className="user-info-item">
      <div className="info-title">修改密码</div>
      {isChangePwd && (
        <div className="change-pwd">
          <div className="info">
            您的密码需满足以下需求：
            <ul>
              <li>密码必须由8个字符组成</li>
              <li>必须包含小写字母、大写字母和数字，并且不能和用户名一致</li>
            </ul>
          </div>
          <Form layout="vertical" onFinish={onChangePwdFinish} form={form}>
            <Form.Item
              label="原密码"
              name="oldPassword"
              rules={[{ required: true, message: "此项为必填" }]}
              validateTrigger="onBlur"
            >
              <Input className="input-width-mobile" type="password" placeholder="请输入" />
            </Form.Item>
            <Form.Item
              label="新密码"
              rules={[
                { required: true, message: "此项为必填" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      getFieldValue("newPassword").toString().length < 8 ||
                      getFieldValue("newPassword").toString().length > 16
                    ) {
                      return Promise.reject(new Error("密码必须由8个字符组成且不能大于16个字符！"));
                    }
                    if (
                      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}/.test(
                        getFieldValue("newPassword")
                      )
                    ) {
                      return Promise.reject(new Error("不符合密码规则,请重新设置密码！"));
                    }
                    return Promise.resolve();
                  }
                })
              ]}
              validateTrigger="onBlur"
              name="newPassword"
            >
              <Input className="input-width-mobile" type="password" placeholder="请输入" />
            </Form.Item>
            <Form.Item
              label="确认新密码"
              rules={[
                { required: true, message: "此项为必填" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue("newPassword") !== getFieldValue("confirmNewPassword")) {
                      return Promise.reject(new Error("两次输入的密码不一致！"));
                    }
                    return Promise.resolve();
                  }
                })
              ]}
              name="confirmNewPassword"
            >
              <Input className="input-width-mobile" type="password" placeholder="请输入" />
            </Form.Item>
            <Form.Item>
              <Button data-testid="save-btn" className="submit-btn" htmlType="submit" type="primary">
                保存并更新
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      <div
        data-testid="open-btn"
        onClick={() => {
          setIsChangePwd(!isChangePwd);
        }}
      >
        <span style={{ color: "#007ed9", marginRight: 5, cursor: "pointer" }}>{isChangePwd ? "收起" : "修改密码"}</span>
        <DownOutlined rotate={isChangePwd ? 180 : 0} />
      </div>
    </div>
  );
};

export default UpdatePassword;
