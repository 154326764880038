import React, { useCallback, useEffect, useState } from "react";
import { AutoComplete } from "antd";

// 承运人联系方式选择
const DriverContactNumberItem: React.FC<{
  driverContactNumberOptions: any[];
  value?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: (value: string, option: any) => void;
}> = props => {
  const [value, setValue] = useState<string | undefined>(props.value);

  const onHandleChange = useCallback(
    (newValue: string, currentOption: any) => {
      setValue(newValue);
      props.onChange && props.onChange(newValue, currentOption);
    },
    [props]
  );

  useEffect(() => {
    if (props.driverContactNumberOptions?.length > 0) {
      const newValue = props.driverContactNumberOptions?.[0]?.value;
      onHandleChange(newValue, props.driverContactNumberOptions?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.driverContactNumberOptions]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <AutoComplete
      placeholder="请输入"
      value={value}
      options={props.driverContactNumberOptions}
      onChange={onHandleChange}
    />
  );
};

export default DriverContactNumberItem;
