import { useState, useEffect } from "react";
import { Table } from "antd";
import { filterColumnsData, formatNumberToThousands } from "@/utils/common";

interface Props {
  tableData: any;
  pagination: object;
  fieldsDesc: any;
  loading: boolean;
  handleClickDocumentNumber: (value: any, type: string) => void;
}

type NumberTypes = number | null | undefined;

const TableList = (props: Props) => {
  const [colums, setColumns] = useState([]);

  const getRowKey = (record: any) => {
    return record.documentNumber + record.documentLineItem;
  };

  useEffect(() => {
    const sapTableColumns: any = [
      {
        title: "凭证号",
        dataIndex: "documentNumber",
        key: "documentNumber",
        render: (_: any, record: any) => {
          return (
            (!record.specialGlIndicator || ["履约保证金", "追加保证金"].includes(record.specialGlIndicator)) ?
              <span
                onClick={() => props.handleClickDocumentNumber(record, "settlement")} style={{ color: "#07c", cursor: "pointer" }}
              >{record.documentNumber}</span> : record.documentNumber
          )
        }
      },
      {
        title: "凭证行号",
        dataIndex: "documentLineItem",
        key: "documentLineItem"
      },
      {
        title: "清账凭证号",
        dataIndex: "clearingDocumentNumber",
        key: "clearingDocumentNumber"
      },
      {
        title: "买方",
        dataIndex: "accountLongName",
        key: "accountLongName"
      },
      {
        title: "卖方",
        dataIndex: "orgName",
        key: "orgName"
      },
      {
        title: "款项类型",
        dataIndex: "customerGlIndicator",
        key: "customerGlIndicator"
      },
      {
        title: "款项状态",
        dataIndex: "documentItemStatus",
        key: "documentItemStatus"
      },
      {
        title: "备注（合同号或其他）",
        dataIndex: "assignment",
        key: "assignment"
      },
      {
        title: "金额（元）",
        dataIndex: "amountAbsolute",
        key: "amountAbsolute",
        render: (amountAbsolute: NumberTypes) => {
          if (amountAbsolute) {
            return formatNumberToThousands(amountAbsolute);
          } else {
            return "-";
          }
        }
      },
      {
        title: "创建/释放日期",
        dataIndex: "postingDate",
        key: "postingDate"
      },
      {
        title: "系统到期日期",
        dataIndex: "netDueDate",
        key: "netDueDate",
        render: (text: any, record: any) => <>{record.specialGlIndicator === "应收保证金" ? text : "-"}</>
      },
      {
        title: "买方公司代码",
        dataIndex: "accountNumber",
        key: "accountNumber"
      },
      {
        title: "卖方代码",
        dataIndex: "companyCode",
        key: "companyCode"
      },
      {
        title: "货币",
        dataIndex: "localCurrency",
        key: "localCurrency"
      },
      {
        title: "利润中心",
        dataIndex: "profitCenter",
        key: "profitCenter"
      }
    ];
    // 更新获取table可展示的字段信息 -- 获取可显示的字段
    let data: any = filterColumnsData(sapTableColumns, props.fieldsDesc);
    setColumns(data);
  }, [props]);

  return (
    <div className="deposit-details-table">
      <Table
        columns={[...colums]}
        pagination={props.pagination}
        scroll={{ x: "max-content" }}
        dataSource={props.tableData}
        rowKey={getRowKey}
        loading={props.loading}
        size="small" />
    </div>
  );
};

export default TableList;