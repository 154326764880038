import { useState, useEffect, useCallback } from "react";
import { Card, PageHeader, message } from "antd";
import SearchFilter from "./searchFilter";
import TableList from "./tableList";
import BuyerModal from "./buyerModal";
import { getDispatchList } from "@/api/directSys";
import { connect } from "react-redux";
import NotificationTips from "@/pages/common/notificationTips";
import "./index.scss";

const DispatchList = (props: any) => {
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [hasSelected, setHasSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [requestData, setRequestData] = useState<any>({});

  const onPageChange = (page: number, _filter: any) => {
    setCurrent(page);
    const pager = JSON.parse(JSON.stringify(requestData));
    pager.offset = (page - 1) * 10;
    setRequestData(pager);
  };

  const paginationOpt: any = {
    showQuickJumper: true,
    total: total,
    pageSize: 10,
    showSizeChanger: false,
    onChange: onPageChange,
    current: current,
    size: "default"
  };

  // 排序标识变化请求后端
  const setSortFlag = (sorter: any) => {
    console.log(sorter);
    let sortFlag = null;
    if (sorter.order === "ascend") {
      sortFlag = "A";
    } else if (sorter.order === "descend") {
      sortFlag = "D";
    }
    setRequestData({ ...requestData, sortFlag: sortFlag });
  };

  const getListData = useCallback(() => {
    const values = requestData;
    if (values && Object.keys(values).length) {
      setLoading(true);
      getDispatchList(values)
        .then(res => {
          const tableData = res.data.dispatchList;
          const total = res.data.metadata.totalCount;
          setData(tableData);
          setTotal(total);
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          message.error("获取数据失败，请稍后重试。");
          setData([]);
          setTotal(0);
        })
        .finally(() => {
          setLoading(false);
          setHasSelected(false);
        });
    }
  }, [requestData]);


  // 强制结算后的回调
  const onHandleForceInvoiceSuccess = useCallback(() => {
    // 处理翻页的边界问题
    if (requestData.offset >= (paginationOpt.total - selectedRows.length) && requestData.offset > 0) {
      requestData.offset -= requestData.limit
    }
    getListData();
  }, [getListData, requestData, paginationOpt.total, selectedRows.length]);

  const returnArr = (arr: any) => {
    let arrnew: any[] = [];
    arr.forEach((e: any) => {
      arrnew.push(e.id);
    });
    return arrnew;
  };

  useEffect(() => {
    document.title = "嘉吉结算列表";
  }, []);

  useEffect(() => {
    getListData();
  }, [getListData, requestData]);

  return (
    <>
      <PageHeader className="page-header" title="嘉吉结算列表">
        <NotificationTips />
      </PageHeader>
      <div className="dispatch-list">
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb" }}
          bodyStyle={{ paddingTop: 0 }}
          title="查询条件"
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
        >
          <SearchFilter setFields={setRequestData} setCurrent={setCurrent} />
        </Card>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
          bodyStyle={{ paddingTop: 0 }}
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
          title={
            <div>
              <span>嘉吉结算列表</span>
              <div className="report-header">
                <span className="total">共 {total} 条</span>
                <BuyerModal
                  hasSelected={hasSelected}
                  ids={selectedRows[0] ? returnArr(selectedRows) : []}
                  getListData={getListData}
                  forceInvoiceSuccess={onHandleForceInvoiceSuccess}
                />
              </div>
            </div>
          }
        >
          <TableList
            tableData={data}
            paginationOpt={paginationOpt}
            setHasSelected={setHasSelected}
            setSelectedRows={setSelectedRows}
            loading={loading}
            setCurrent={setCurrent}
            setSortFlag={setSortFlag}
          />
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  userDetails: state.userDetails,
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(DispatchList);
