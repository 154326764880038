import { Card, message, PageHeader } from "antd";
import React, { Component } from "react";
import SearchFilter from "./searchFilter";
import "./index.scss";
import { getDoReportListing } from "@/api/deliveryList";
import FieldSetting from "@/pages/common/fieldSetting";
import TableList from "./TableList";
import * as type from "../constant";
import { notificationList, notificationRead, saveMovementFieldsOrder } from "@/api/constant";
import DownloadReport from "@/pages/common/downloadReport";
import ExternalSearchFilter from "./externalSearchFilter";
import { connect } from "react-redux";
import { isMobileDevice } from "../../../utils/common";
import MobileTableOperation from "@/pages/common/mobileTableOperation";
import NotificationTips from "@/pages/common/notificationTips";
import CancelSuccessAlert from "./components/cancelSuccessAlert";
import DownloadCOA from "./components/downloadCOA";

interface Props {
  permissions: any;
  switcher: any;
  location: any;
  userDetails: any;
  history: any;
}

interface State {
  dataSource: any;
  searchValues: any;
  totalCount: any;
  offset: any;
  fieldsDesc: any;
  allFields: any;
  sort: any;
  current: number; // 控制分页
  loading: boolean;
  contractNo: string;
  sortOrder: boolean | string;
  showSuccessAlertModal: boolean;
  notifications: any;
  start: number;
  end: number;
  alertMetadata: any;
  selectedRows: any;
}
class DeliveryList extends Component<Props, State> {
  public readonly state = {
    dataSource: [],
    searchValues: {} as any,
    totalCount: 0,
    offset: 0,
    fieldsDesc: [],
    allFields: [],
    current: 1,
    loading: false,
    sort: {
      sortDirection: type.DO_SORT_BY_ID_DESC,
      sort: type.DO_DESC
    },
    contractNo: "",
    showSuccessAlertModal: false,
    sortOrder: false,
    notifications: [],
    alertMetadata: {
      unReadCount: 0
    },
    selectedRows: [],
    start: 0,
    end: 5
  };

  componentDidMount() {
    document.title = "提货单列表";
    if (this.props.location.state) {
      if (this.props.location.state.contractNo) {
        this.setState({ contractNo: this.props.location.state.contractNo });
      }
    }
    this.getNotifications();
  }

  // 获取表格数据
  getTable = (searchValues: any) => {
    const { sort, offset } = this.state;
    const { switcher } = this.props;
    this.setState({ loading: true });
    getDoReportListing(searchValues, {
      offset: offset,
      sortDirection: sort.sortDirection,
      sort: sort.sort,
      divisionCode: switcher.divisionCode,
      orgCode: switcher.orgCode,
      productCode: switcher.productCode,
      customerCode: searchValues.customerCode
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          let allFields = this.filterFieldsList(JSON.parse(res.data.fieldsOrder.allFields));
          let fieldsDesc = this.filterFieldsList(JSON.parse(res.data.fieldsOrder.fieldDesc));
          this.setState({
            totalCount: res.data.metadata.totalCount,
            dataSource: Array.isArray(res.data.doReportData) ? res.data.doReportData : [],
            allFields: allFields,
            fieldsDesc: fieldsDesc
          });
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        this.setState({
          dataSource: [],
          totalCount: 0
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  filterFieldsList = (dataSource: any) => {
    if (dataSource) {
      const internalUsersIgnoreFields = ["rejectReason", "externalMemo"];
      const externalUsersIgnoreFields = [
        "doInternalMerge",
        "shippingInstruction",
        "transportTypeDesc",
        "price",
        "warehouseQty",
        "receiptTime",
        "ldcType",
        "entrustNo",
        "approvedBy",
        "approveTime",
        "autoExamine",
        "limitStatus",
        "occStatus",
        "delayExamineDate", 
        "customerMerge", 
        "personalReason", 
        "forceReserve", 
        "driverCard"
      ];
      if (this.props.permissions?.CNExternalUsers) {
        dataSource = dataSource.filter((val: any) => !externalUsersIgnoreFields.includes(val.value));
      } else {
        dataSource = dataSource.filter((val: any) => !internalUsersIgnoreFields.includes(val.value));
      }
    }
    return dataSource;
  };

  // 获取字段设置后的columns
  getColumns = (data: any, dataSource: any, setVisibleFun: any) => {
    const { searchValues } = this.state;
    let temp = data.map((item: any) => {
      return dataSource.find((val: any) => val.value === item);
    });
    temp = temp.filter((val: any) => val);
    saveMovementFieldsOrder({
      type: "doReportList",
      fieldDesc: JSON.stringify(temp)
    })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("设置失败，请稍后重试。");
      })
      .finally(() => {
        setVisibleFun(false);
        this.getTable(searchValues);
      });
  };

  // 获取搜索条件
  getSearchValue = (data: any) => {
    if (data.contractNumber === undefined) {
      // 清空
      if (this.props.location.state) {
        data.contractNumber = this.props.location.state.contractNo;
      }
    }
    this.setState({ searchValues: data });
  };

  // 获取分页
  getOffset = (data: any, current: number) => {
    this.setState(
      {
        offset: data,
        current
      },
      () => {
        this.getTable(this.state.searchValues);
      }
    );
  };

  // 获取排序信息
  getSort = (data: any, resetflag: boolean = false) => {
    this.setState(
      {
        sort: data
      },
      () => {
        if (!resetflag) {
          this.getTable(this.state.searchValues);
        }
      }
    );
  };

  setSortOrder = (data: any) => {
    this.setState({
      sortOrder: data
    });
  };

  // 设置选中的row
  setSelectedRows = (rows: any) => {
    this.setState({ selectedRows: rows });
  };

  getNotifications = () => {
    let { commodityCode, siteCode } = this.state.searchValues;
    if (commodityCode === "All") {
      commodityCode = "";
    }
    if (siteCode === "All") {
      siteCode = "";
    }
    notificationList({
      limit: 10,
      offset: 0,
      type: "CANCEL_DO",
      commodityCode,
      siteCode
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          const { notifications, metadata } = res.data;
          if (metadata && metadata.unReadCount && metadata.unReadCount > 0) {
            this.setState({ showSuccessAlertModal: true });
          }
          this.setState({
            notifications: Array.isArray(notifications) ? notifications : [],
            alertMetadata: metadata || {}
          });
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取信息失败，请稍后重试。");
        this.setState({
          notifications: [],
          alertMetadata: {}
        });
      });
  };

  closeSuccessAlertModal = () => {
    const { notifications, end, alertMetadata } = this.state;
    const { switcher } = this.props;
    const { businessLine, orgName, customerName } = switcher;
    if (end > alertMetadata.unReadCount || end === alertMetadata.unReadCount) {
      const temp = notifications;
      temp.forEach((val: any) => {
        const readParam: any = {
          id: val.alertId,
          state: "READ"
        };
        notificationRead(readParam)
          .then((response: any) => {
            if (response.code && response.code === "99999") {
              message.error(response.errorMsg);
            }
          })
          .catch((exception: any) => {
            console.log(`Failure: ${exception}`);
            message.error("获取数据失败，请稍后重试。");
          });
      });
      const params = {
        type: "NOTIFICATIONCENTER",
        commodityCode: businessLine,
        siteCode: orgName,
        firmName: customerName
      };
      setTimeout(() => {
        notificationList(params)
          .then((res: any) => {
            if (res.code && res.code === "99999") {
              message.error(res.errorMsg);
            }
          })
          .catch((exception: any) => {
            console.log(`Failure: ${exception}`);
            message.error("获取数据失败，请稍后重试。");
          });
      }, 2000);
    } else {
      this.setState({
        start: this.state.start + 5,
        end: this.state.end + 5
      });
    }
  };

  public render() {
    const {
      dataSource,
      totalCount,
      fieldsDesc,
      allFields,
      searchValues,
      start,
      end,
      alertMetadata,
      notifications,
      showSuccessAlertModal,
      offset,
      sort,
      current,
      loading,
      contractNo,
      sortOrder,
      selectedRows
    } = this.state;
    const { permissions, switcher, userDetails } = this.props;
    const { firmNames, ...restSearchValues } = searchValues;

    return (
      <div className="delivery-list">
        <PageHeader
          className="page-header"
          title="提货单列表"
          // extra={[
          //   <Button key="jumpToOldDoReport" type="primary" onClick={this.jumpToOldDoReport}>
          //     旧提货单页面链接
          //   </Button>
          // ]}
        >
          <NotificationTips />
        </PageHeader>
        <div className="delivery-list-content">
          <Card
            style={{ boxShadow: "0 5px 9px #dedbdb" }}
            bodyStyle={{ paddingTop: 0 }}
            title="提货单列表"
            headStyle={{
              backgroundColor: "white",
              fontWeight: 100,
              borderBottom: "none"
            }}
          >
            {permissions.CNInternalUsers ? (
              <SearchFilter
                getSearchValue={this.getSearchValue}
                switcher={switcher}
                contractNo={contractNo}
                getOffset={this.getOffset}
                getSort={this.getSort}
                setSortOrder={this.setSortOrder}
              />
            ) : (
              <ExternalSearchFilter
                getSearchValue={this.getSearchValue}
                switcher={switcher}
                contractNo={contractNo}
                getSort={this.getSort}
                setSortOrder={this.setSortOrder}
                getOffset={this.getOffset}
              />
            )}
          </Card>

          <Card
            style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
            bodyStyle={{ paddingTop: 0 }}
            title={
              <div>
                <span>提货单列表</span>
                {isMobileDevice() ? (
                  <div className="report-header" style={{ display: "flex" }}>
                    <span className="total">共 {totalCount} 条</span>
                    <MobileTableOperation isRotate={true}>
                    <DownloadCOA selectedRows={selectedRows} isEnabledAction={selectedRows.length > 0} isText />
                      <DownloadReport
                        data={searchValues}
                        text
                        params={{
                          limit: 10,
                          offset: offset,
                          sort: sort.sort,
                          total: totalCount,
                          sortDirection: sort.sortDirection,
                          ...restSearchValues
                        }}
                        cancelMaxLimit={true}
                        filename={type.DELIVERYLIST_FILENAME}
                        fileType={type.DELIVERYLIST_TYPE}
                      />
                      <FieldSetting text fieldDesc={fieldsDesc} allFields={allFields} getColumns={this.getColumns} />
                    </MobileTableOperation>
                  </div>
                ) : (
                  <div className="report-header">
                    <span className="total">共 {totalCount} 条</span>
                    <DownloadCOA selectedRows={selectedRows} isEnabledAction={selectedRows.length > 0} />
                    <DownloadReport
                      data={searchValues}
                      params={{
                        limit: 10,
                        offset: offset,
                        sort: sort.sort,
                        total: totalCount,
                        sortDirection: sort.sortDirection,
                        ...restSearchValues
                      }}
                      cancelMaxLimit={true}
                      filename={type.DELIVERYLIST_FILENAME}
                      fileType={type.DELIVERYLIST_TYPE}
                    />
                    <FieldSetting fieldDesc={fieldsDesc} allFields={allFields} getColumns={this.getColumns} />
                  </div>
                )}
              </div>
            }
            headStyle={{
              backgroundColor: "white",
              fontWeight: 100,
              borderBottom: "none"
            }}
          >
            <TableList
              tableData={dataSource}
              totalCount={totalCount}
              fieldsDesc={fieldsDesc}
              permissions={permissions}
              userDetails={userDetails}
              loading={loading}
              getTable={this.getTable}
              searchValues={searchValues}
              current={current}
              getOffset={this.getOffset}
              getSort={this.getSort}
              setSelectedRows={this.setSelectedRows}
              sortOrder={sortOrder}
              setSortOrder={this.setSortOrder}
            />
          </Card>
          {showSuccessAlertModal && (
            <CancelSuccessAlert
              description={[...notifications].slice(start, end)}
              alertMetadata={{ ...alertMetadata }}
              closeSuccessAlertModal={this.closeSuccessAlertModal}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  switcher: state.switcher,
  permissions: state.userPermissions,
  userDetails: state.userDetails
});

export default connect(mapStateToProps, null)(DeliveryList);
