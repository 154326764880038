import { message, Modal, Spin } from "antd";
import wxImg from "../../../assets/images/wxQRCode.png";
import QRCode from "qrcode.react"; // 生成二维码
import { useCallback, useEffect, useState } from "react";
import {
  getWeChatApplyQRCodeUrl,
  getWeChatBindingInfo,
  setUnboundWeChat,
  setWeChatMessageReceiveStatus
} from "@/api/userCenter";

const WechatDetails = (props: any) => {
  const [pageLoading, setPageLoading] = useState(false);
  const [QRCodeModalVisible, setQRCodeModalVisible] = useState(false);
  const [QRCodeUrl, setQRCodeUrl] = useState("");
  const [weChatBindingInfo, setWeChatBindingInfo] = useState<any>({});

  // 获取二维码地址
  const getQRCodeUrl = useCallback(() => {
    const { profileValues } = props;
    const payload = {
      ...profileValues,
      mobile: profileValues.phoneNumber || ""
    };
    getWeChatApplyQRCodeUrl(payload)
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else if (res.data.result === "success") {
          setQRCodeUrl(res.data.url);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      });
  }, [props]);

  const getBindingInfo = useCallback(() => {
    const { profileValues } = props;
    const payload = {
      ...profileValues,
      mobile: profileValues.phoneNumber || ""
    };
    setPageLoading(true);
    getWeChatBindingInfo(payload)
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else if (res.data) {
          setWeChatBindingInfo(res.data);
          if (!res.data.bound) {
            getQRCodeUrl();
          }
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      })
      .finally(() => {
        setPageLoading(false);
      });
  }, [getQRCodeUrl, props]);

  const setApi = useCallback(
    (ApiName: any, params: any, info: string) => {
      setPageLoading(true);
      ApiName(params)
        .then((res: any) => {
          if (res.code && res.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(res.errorMsg);
          } else {
            if (res.data.result === "fail") {
              message.error(res.data.error_message);
            } else {
              message.success(info);
              getBindingInfo();
            }
          }
        })
        .finally(() => {
          setPageLoading(false);
        });
    },
    [getBindingInfo]
  );

  // 微信号解除绑定
  const setUnbound = () => {
    const { profileValues } = props;
    const payload = {
      ...profileValues,
      mobile: profileValues.phoneNumber || ""
    };
    setApi(setUnboundWeChat, payload, "解除绑定成功");
  };

  const onHandleQRCodeModalOpen = useCallback(() => {
    setQRCodeModalVisible(true);
  }, []);

  const onHandleQRCodeModalClose = useCallback(() => {
    setQRCodeModalVisible(false);
  }, []);

  const onHandleSetWeChatStatus = useCallback(
    (value: number) => (eventValue?: any) => {
      const setWeChatStatus = (changeStatus: number) => {
        const { profileValues } = props;
        const payload = {
          userProfile: { ...profileValues, mobile: profileValues.phoneNumber || "" },
          messageSwitch: changeStatus || weChatBindingInfo?.receive_wechat_message === 1 ? 0 : 1
        };
        setApi(setWeChatMessageReceiveStatus, payload, "更改成功");
      };

      setWeChatStatus(value);
    },
    [props, setApi, weChatBindingInfo?.receive_wechat_message]
  );

  useEffect(() => {
    if (props.profileValues && Object.keys(props.profileValues).length > 0) {
      getBindingInfo();
    }
  }, [getBindingInfo, props.profileValues]);

  return (
    <Spin spinning={pageLoading}>
      <div className="user-info-item">
        <span className="info-title">采购专员/经理绑定个人微信</span>
        <div className="tips">绑定个人微信后可在微信端接收预约提货通知，仅限绑定1个微信号</div>
        {Object.keys(weChatBindingInfo).length > 0 && weChatBindingInfo?.bound === 1 && (
          <div>
            <div className="bound-item">
              <div className="bound-item-tips">已绑定的微信号: {weChatBindingInfo?.wechat_no || "-"}</div>
              <span data-testid="setunbond" onClick={setUnbound}>
                [解除绑定]
              </span>
            </div>
            {/* 交换status值  1 开启 0 关闭*/}
            {weChatBindingInfo?.receive_wechat_message === 1 ? (
              <div className="bound-item">
                <div className="bound-item-tips">微信消息推送: 已开启</div>
                <span data-testid="change-status" className="send-msg-btn" onClick={onHandleSetWeChatStatus(1)}>
                  [我要关闭]
                </span>
              </div>
            ) : (
              <div className="bound-item">
                <div className="bound-item-tips">微信消息推送: 已关闭</div>
                <span data-testid="change-status" className="send-msg-btn" onClick={onHandleSetWeChatStatus(0)}>
                  [我要开启]
                </span>
              </div>
            )}
          </div>
        )}

        {Object.keys(weChatBindingInfo).length > 0 && !weChatBindingInfo?.bound && (
          <div className="bind-method">
            <div className="bind-title">绑定微信步骤如下：</div>
            <div className="step tips">
              一、关注嘉吉粮油服务平台，扫描下方二维码或微信搜索“嘉吉粮油服务平台”
              {QRCodeModalVisible && QRCodeUrl ? "" : <img src={wxImg} alt="嘉吉粮油服务平台二维码" />}
            </div>
            <div className="step tips">
              二、扫描二维码，绑定个人微信
              <span data-testid="show-btn" className="click-word" onClick={onHandleQRCodeModalOpen}>
                点击生成绑定二维码
              </span>
              {QRCodeUrl && (
                <Modal
                  visible={QRCodeModalVisible}
                  title={null}
                  onCancel={onHandleQRCodeModalClose}
                  footer={null}
                  width={600}
                  centered
                  maskClosable={false}
                  destroyOnClose
                >
                  <div className="qrcode">
                    <div className="qrcode-title">仅限1名采购专员/经理扫码绑定，请勿将此二维码分享给司机！</div>
                    <QRCode id="qrcode" value={QRCodeUrl} size={230} fgColor="#000000" style={{ margin: "0 auto" }} />
                    <div className="tip">5分钟内有效</div>
                  </div>
                </Modal>
              )}
            </div>
            <div className="step tips">三、绑定成功后即可在微信获取预约提货通知</div>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default WechatDetails;
