import { Table } from "antd";
import { useEffect, useState } from "react";
import "./index.scss";

const TableList = (props: any) => {
  const { data, columns, rowSelection, loading } = props;
  const [dataSource, setDataSource] = useState(data);
  const [myColumns, setMyColumns] = useState(columns);

  useEffect(() => {
    const temp =
      data &&
      data.map((val: any, index: number) => {
        let valTemp = val;
        valTemp.index = index;
        return valTemp;
      });
    setDataSource(temp);
  }, [data]);

  useEffect(() => {
    let temp = columns ? columns : [];
    setMyColumns(temp);
  }, [columns]);

  return (
    <Table
      dataSource={dataSource}
      columns={myColumns}
      pagination={props.pagination}
      rowSelection={rowSelection}
      rowKey="index"
      size="small"
      loading={loading}
      scroll={{ x: "max-content" }}
    />
  );
};

export default TableList;
