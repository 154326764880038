import { Button, Modal, Radio, message } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
import "./index.scss";
import { documentBatchDownload } from "@/api/deliveryList";
import { getFilename, removeStrQuotes } from "@/utils/formatUtils";
import { downloadFile } from "@/utils";

interface Props {
  selectedRows: any;
  isEnabledAction: boolean;
  isText?: boolean;
}

const DownloadCOA = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [reportType, setReportType] = useState("");
  const [salesOrderNumberList, setSalesOrderNumberList] = useState([]);
  const [doNumbers, setDoNumbers] = useState([] as string[]);

  // 模态框下载提示内容
  const getNoticeContent = useCallback(() => {
    let headerText = "";
    let contentText = "";
    if (reportType) {
      contentText = salesOrderNumberList?.length > 0 ? salesOrderNumberList.join(",") : "";
      if (contentText) {
        headerText = reportType === "qualityReport" ? "您选择以下提单有质量报告：" : "您选择以下提单有电子磅单：";
      } else {
        headerText = reportType === "qualityReport" ? "您所选的提单没有可下载的质量报告" : "您所选的提单没有可下载的电子磅单"
      }
    }
    return (
      <div className="notice-content">
        {headerText && <div className="header-text">{headerText}</div>}
        {contentText && <div className="content-text">{contentText}</div>}
      </div>
    )
  }, [salesOrderNumberList, reportType]);

  // 关闭模态框
  const handleCloseModal = useCallback(() => {
    setModalVisible(false);
    setSalesOrderNumberList([]);
    setDoNumbers([]);
    setReportType("");
  }, []);

  // 批量下载报告
  const handleDownloadCOA = useCallback(() => {
    setModalVisible(true);
  }, []);

  // 报告类型选择
  const onReportTypeChange = useCallback((e: any) => {
    const selectedType = e.target.value;
    // 根据所选下载报告类型，获取对应可下载列表
    const downloadableList = props.selectedRows?.filter((item: any) => {
      if (selectedType === "qualityReport") {
        return [3, 4].includes(item.qualityDownloadFlag);
      }
      return item.poundDownloadFlag && [3, 4].includes(item.poundDownloadFlag);
    });
    const salesOrderNumberList = downloadableList?.length > 0 ? downloadableList.map((item: any) => item.salesOrderNumber) : [];
    const doNumberList = downloadableList?.length > 0 ? downloadableList.map((item: any) => item.doNumber) : []
    setReportType(selectedType);
    setSalesOrderNumberList(salesOrderNumberList);
    setDoNumbers(doNumberList);
  }, [props.selectedRows]);

  // 确认下载COA报告
  const doDownloadCOA = useCallback(() => {
    setLoading(true);
    handleCloseModal();
    documentBatchDownload({ doIds: doNumbers, type: reportType === "qualityReport" ? "COA" : "POUND"})
      .then((response: any) => {
        if (response) {
          const filename = getFilename(response.headers);
          downloadFile(response.data, removeStrQuotes(filename));
        } else {
          message.error("下载失败，请稍后重试。");
        }
      })
      .catch((exception: any) => {
        message.error("下载失败，请稍后重试。");
        console.log(exception);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [handleCloseModal, doNumbers, reportType]);

  return (
    <div className="download-coa">
      <Modal
        className="download-coa-modal"
        title="批量下载报告附件"
        maskClosable={false}
        visible={modalVisible}
        okButtonProps={{ disabled: doNumbers?.length === 0 }}
        okText="打包下载"
        onOk={doDownloadCOA}
        onCancel={handleCloseModal}>
        <div style={{ marginBottom: "10px" }}>请选择下载的报告类型</div>
        <Radio.Group onChange={onReportTypeChange} value={reportType}>
          <Radio value="qualityReport">质量报告</Radio>
          <Radio value="poundReport">电子磅单</Radio>
        </Radio.Group>
        {getNoticeContent()}
      </Modal>
      <Button
        type={props.isText ? "text" : "primary"}
        loading={loading}
        icon={<VerticalAlignBottomOutlined />}
        onClick={handleDownloadCOA}
        disabled={!props.isEnabledAction}
      >
        批量下载报告附件
      </Button>
    </div>
  )
}

export default DownloadCOA;