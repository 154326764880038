import { Table } from "antd";

interface Props {
  tableData: any;
  pagination: object;
  loading: boolean;
}
const TableList: React.FC<Props> = (props) => {
  const columns: any = [
    {
      title: "仓库代码",
      dataIndex: "warehouseCode",
      key: "warehouseCode"
    },
    {
      title: "产品余量",
      dataIndex: "remainQty",
      key: "remainQty"
    },
    {
      title: "买方名称",
      dataIndex: "customerName",
      key: "customerName"
    }
  ];
  return (
    <div className="table-list">
      <Table
        columns={columns}
        pagination={props.pagination}
        scroll={{ x: "max-content" }}
        dataSource={props.tableData}
        rowKey="id"
        loading={props.loading}
        size="small"
      />
    </div>
  );
};

export default TableList;