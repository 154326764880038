import { useCallback } from "react";
import { Button, message } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import { revertNotification } from "@/api/systemSetting";

const RevertNotificationButton = (props: any) => {
  const handleRevertNotification = useCallback(() => {
    revertNotification(props.id).then(res => {
      if (res.data.notices) {
        props.getListData();
      } else {
        message.error("撤回失败！");
      }
    });
  }, [props]);

  return (
    <div className="record-btn">
      <Button
        type="link"
        disabled={props.revertFlag === "1"}
        style={{ width: 40, padding: 0 }}
        onClick={handleRevertNotification}
      >
        <RollbackOutlined />
      </Button>
    </div>
  );
};
export default RevertNotificationButton;
