import { Transfer } from "antd";
import difference from "lodash/difference";
import { useCallback } from "react";
import DragSortingTable from "../DragSortingTable";

interface Props {
  leftColumns: any;
  rightColumns: any;
  dataSource: any;
  targetKeys: any;
  onChange: any;
  getChangedColumns: any;
}

interface Prop {
  direction: string;
  filteredItems: any;
  selectedKeys: any;
  onItemSelect: any;
  onItemSelectAll: any;
  disabled?: any;
}

// Customize Table Transfer
const TableTransfer = (props: Props) => {
  const { leftColumns, rightColumns, onChange, dataSource, getChangedColumns, targetKeys } = props;

  const getRowKey = useCallback((record: any) => {
    return record?.value;
  }, []);

  return (
    <Transfer
      dataSource={dataSource}
      targetKeys={targetKeys}
      rowKey={getRowKey}
      onChange={onChange}
      showSelectAll={false}
    >
      {(prop: Prop) => {
        const { direction, filteredItems, selectedKeys, onItemSelect, onItemSelectAll, disabled } = prop;
        const columns = direction === "left" ? leftColumns : rightColumns;
        const rowSelection = {
          selectedRowKeys: selectedKeys,
          getCheckboxProps: (item: any) => ({ disabled: disabled || item.remove === false }),
          onSelectAll(selected: any, selectedRows: any) {
            const treeSelectedKeys = selectedRows
              .filter((item: any) => {
                return item.remove !== false;
              })
              .map(({ key }: { key: any }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, selectedKeys)
              : difference(selectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }: { key: any }, selected: any) {
            onItemSelect(key, selected);
          }
        };

        return (
          <DragSortingTable
            direction={direction}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            getChangedColumns={getChangedColumns}
          />
        );
      }}
    </Transfer>
  );
};

export default TableTransfer;
