import { useState, useCallback } from "react";
import { Modal, Button, message, Form, Radio } from "antd";
import { forceInvoice } from "@/api/directSys";

const BuyerModal = (props: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [forceType, setForceType] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onValuesChange = useCallback((changedFields: any, allFields: any) => {
    setForceType(changedFields.forceType);
  }, []);

  const onFinish = useCallback((values: any) => {
    setLoading(true);
    const request = props.ids;
    forceInvoice(request, { forceType: values.forceType })
      .then((res: any) => {
        if (res?.result) {
          Modal.success({ title: "强制结算成功提示", content: res.description });
          props.forceInvoiceSuccess();
        } else {
          // Mark: 由于AR模块，错误码返回格式与其他不一致，此处暂时单独处理，最终可与后端协商在网络层拦截处理，保持一致性
          const errorMsg = res?.description?.split("detail:") || [];
          message.error(errorMsg.length > 1 ? errorMsg[1] : res?.description);
        }
      })
      .finally(() => {
        setIsModalVisible(false);
        setLoading(false);
      });
  }, [props]);

  const handleOk = useCallback(() => {
    form.submit();
  }, [form]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleClose = useCallback(() => {
    form.resetFields();
    setForceType("");
  }, [form]);

  const handleVisible = useCallback(() => {
    setIsModalVisible(!isModalVisible);
  }, [isModalVisible]);

  return (
    <>
      <Button type="primary" disabled={!props.hasSelected} onClick={handleVisible}>
        强制结算
      </Button>
      <Modal
        title="强制结算提示"
        maskClosable={false}
        visible={isModalVisible}
        footer={
          <Button type="primary" loading={loading} onClick={handleOk} disabled={!forceType}>
            确定
          </Button>
        }
        onCancel={handleCancel}
        afterClose={handleClose}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onValuesChange={onValuesChange}
          onFinish={onFinish}
        >
          <Form.Item
            label=""
            name="forceType"
            style={{ marginBottom: 0 }}
          >
            <Radio.Group>
              <Radio value="DO">按提单强制结算</Radio>
              <Radio value="CONTRACT">按合同强制结算</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default BuyerModal;
