// slot-booking 查询条件
import { useState, useEffect, useCallback } from "react";
import { Card, Button, message, DatePicker, Spin } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import UploadBtn from "@/components/upload-btn";
import { downloadFile, buildUrlWithEncoding } from "@/utils/common";
import { postReverseDownloadExcelApi } from "@/api/slotbooking";

import "./index.scss";

const { RangePicker } = DatePicker;

// 定义函数组件的props相关参数
type myProps = { reservation_filters: any };

const ReverseBooking = (props: myProps) => {
  const [, forceUpdate] = useState({});
  const [slotDateRange, setSlotDateRange] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>("");

  // 仅在[]中包含的state更改时更新
  useEffect(() => {
    forceUpdate({});
  }, []);

  // 设置上传按钮的配置信息
  const uploadConfig = {
    actionUrl: buildUrlWithEncoding("/slotbookingsap/reverseAppointment/batchedUpload", {
      factoryNo: props.reservation_filters.orgCode
    }),
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    sizeLimit: 10,
    showUploadList: false,
    btnText: "上传提货计划"
  };

  // 下载--获取提货计划模版
  const handleTemplateDownload = useCallback(() => {
    const downloadParams = {
      factoryNo: props.reservation_filters.orgCode,
      dateStr: null,
      dateStrTo: null,
      isTemplate: true
    };
    setIsLoading(true);
    setLoadingText("正在下载，请稍后......");
    postReverseDownloadExcelApi(downloadParams)
      .then((response: any) => {
        if (response) {
          downloadFile(response, `提货计划模版.xlsx`);
        } else {
          message.error("下载提货计划模版失败，请稍后重试。");
        }
        setIsLoading(false);
      })
      .catch((exception: any) => {
        console.log(exception);
        message.error("下载提货计划模版失败，请稍后重试。");
        setIsLoading(false);
      });
  }, [props.reservation_filters.orgCode]);

  // 只能选择当前天之前60天和往之后14天
  const disabledDate = useCallback((current: any) => {
    const tooLate = new Date().setDate(new Date().getDate() - 60);
    const tooEarly = new Date().setDate(new Date().getDate() + 14);
    return current && (current < tooLate || current > tooEarly);
  }, []);

  const handleDateRangeChange = useCallback((values: any, dateString: string[]) => {
    setSlotDateRange(dateString);
  }, []);

  // 下载--查看提货计划执行情况
  const handleSlotPlanDownload = useCallback(() => {
    if (slotDateRange && slotDateRange[0] && slotDateRange[1]) {
      const downloadParams = {
        factoryNo: props.reservation_filters.orgCode,
        dateStr: slotDateRange[0],
        dateStrTo: slotDateRange[1],
        isTemplate: false
      };
      setIsLoading(true);
      setLoadingText("正在下载，请稍后......");
      postReverseDownloadExcelApi(downloadParams)
        .then((response: any) => {
          if (response) {
            downloadFile(response, `散油计划执行情况(${slotDateRange[0]}-${slotDateRange[1]}).xlsx`);
            setSlotDateRange([]);
          } else {
            message.error("下载提货计划执行情况失败，请稍后重试。");
          }
          setIsLoading(false);
        })
        .catch((exception: any) => {
          message.error("下载提货计划执行情况失败，请稍后重试。");
          setIsLoading(false);
        });
    } else {
      message.warning("请先选择预约提货的起始日期和截止日期");
    }
  }, [props.reservation_filters.orgCode, slotDateRange]);

  return (
    <div className="reverse-booking">
      <Spin spinning={isLoading} tip={loadingText}>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb" }}
          bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
          title="预约时间窗配置"
          headStyle={{
            backgroundColor: "white",
            borderBottom: "none"
          }}
        >
          <div className="hdp-uf hdp-uf-dc">
            <div className="hdp-uf hdp-uf-vc action-item">
              <div className="item-title">上传</div>
              <UploadBtn upload_config={uploadConfig} />
              <Button data-testid="test-download-template" type="link" onClick={handleTemplateDownload}>
                获取模版
              </Button>
            </div>
            <div className="hdp-uf hdp-uf-vfe action-item action-download-plan">
              <div className="item-title download-text">下载</div>
              <div className="hdp-uf hdp-uf-dc">
                <p className="download-date-picker-text">预约提货的起始日期和截止日期</p>
                <RangePicker
                  data-testid="test-date-range-picker"
                  className="date-range"
                  value={slotDateRange[0] ? [moment(slotDateRange[0]), moment(slotDateRange[1])] : null}
                  disabledDate={disabledDate}
                  onChange={handleDateRangeChange}
                />
              </div>
              <Button
                data-testid="test-slot-plan-download"
                className="download-plan-btn"
                type="primary"
                icon={<DownloadOutlined />}
                onClick={handleSlotPlanDownload}
                disabled={!slotDateRange || !slotDateRange[0] || !slotDateRange[1]}
              >
                查看提货计划执行情况
              </Button>
            </div>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default ReverseBooking;
