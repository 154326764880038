import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Card, Form, Input, Select, Button, message, Row, Col } from "antd";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { allSupplierMutiple } from "@/utils/constant";
import { getFirmDetailListApi } from "@/api/slotbooking";
import "./index.scss";

import RemoteSelect from "@/pages/common/remoteSelect";
import { formatNumberToFixedThousands, formatReverseNumberToFixedThousands, getCode } from "@/utils/common";
import moment from "moment";

interface myProps {
  doSearch?: (arg0: any) => void;
  doRest: (arg0: any) => void;
  userPermissionsData: any;
  userDetailsData: any;
  switcherData: any;
  buyers: any;
  depositOverviewData: any;
  timeStamp: any;
  loading: boolean;
  defaultSearchValue?: any;
};

const AccountOverview = (props: myProps) => {
  const { switcherData, userPermissionsData, userDetailsData, buyers, depositOverviewData, timeStamp, loading, defaultSearchValue } = props;
  const [form] = Form.useForm();
  const [isSelectFetching, setIsSelectFetching] = useState(false);

  const isDisabledAction = (key: string) => {
    if (userPermissionsData["CNExternalUsers"]) {
      if (key === "businessLine" || key === "buyer") {
        return false;
      }
      return switcherData[key];
    } else if (userPermissionsData["CNInternalUsers"]) {
      return false;
    }
    return true;
  };

  // 清空重置buyer或productName的数据
  const resetBuyerValue = useCallback(() => {
    if (props.userPermissionsData["CNInternalUsers"]) {
      form.setFieldsValue({ customerCode: null });
    }
  }, [form, props.userPermissionsData]);

  const handleMySubmit = useCallback(
    (values?: any) => {
      let temp = { ...values };
      console.log("values=", JSON.stringify(values));
      // 将条件所有值传递给父组件
      if (props.doSearch) {
        // 此模块只传code，去掉中文
        if (temp.customerCode) {
          temp.customerCode = getCode(temp.customerCode);
        }
        temp.orgCode = "";
        if (values?.orgCode?.length > 0) {
          temp.orgCode = values.orgCode.join(",");
        }

        // 传递筛选的最终结果给父级组件
        props.doSearch(temp);
      }
    },
    [props]
  );

  const resetSearch = useCallback(() => {
    const {
      switcherData,
      userPermissionsData,
      userDetailsData
    } = props;
    form.resetFields();
    form.setFieldsValue({
      orgCode: switcherData.orgCode && switcherData.orgCode !== "All" ? [switcherData.orgCode] : []
    });
    if (userPermissionsData.CNExternalUsers && !userDetailsData?.isGroup) {
      form.setFieldsValue({
        customerCode: switcherData.customerName + "(" + switcherData.customerCode + ")"
      });
    }
    const values = { ...form.getFieldsValue(true) };
    props.doRest(values);
  }, [form, props]);

  // 外部Group用户筛选公司
  const onFilterOption = useCallback((inputValue: any, option: any) => {
    return ((option?.label as unknown) as string).toLowerCase().includes(inputValue?.toLowerCase());
  }, []);

  // 内部用户--获取买方选项(防抖输入查询)
  const fetchCustomerOptions_Internal = useCallback(
    (value: string | undefined, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      getFirmDetailListApi({
        customerName: value || ""
      })
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            callback([]);
            message.error(res.errorMsg);
          } else if (res.data?.customerList?.length > 0 && currentValue === value) {
            let data: any[] = res.data?.customerList?.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName + "(" + val.customerCode + ")",
                key: val.customerCode
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    []
  );

  const returnMarginBalance = useCallback((depositReceived: any, marginCallReceived: any) => {
    return (depositReceived === null && marginCallReceived === null) ? "-" : formatReverseNumberToFixedThousands(depositReceived + marginCallReceived, 2)
  }, []);

  const returnTotalMarginPayable  = useCallback((depositReceivable: any, marginCallReceivable: any) => {
    return (depositReceivable === null && marginCallReceivable === null) ? "-" : formatNumberToFixedThousands(depositReceivable + marginCallReceivable, 2)
  }, []);

  // 如果switcher有设置值，进行初始化相关数据
  // 只要switcher中的卖方 orgCode 的值发生变化需要处理的其他相关项
  useEffect(() => {
    resetBuyerValue();
  }, [form, userPermissionsData, switcherData.orgCode, resetBuyerValue]);

  // 只要switcher中的buyer的值发生变化需要处理的其他相关项
  useEffect(() => {
    if (switcherData) {
      if (userPermissionsData.CNInternalUsers) {
        form.setFieldsValue({
          orgCode: switcherData.orgCode && switcherData.orgCode !== "All" ? [switcherData.orgCode] : []
        });
      } else if (userPermissionsData.CNExternalUsers) {
        // 外部用户
        let buyerName = null;
        if (!userDetailsData?.isGroup) {
          buyerName = switcherData.customerName + "(" + switcherData.customerCode + ")";
        }
        form.setFieldsValue({
          orgCode: switcherData.orgCode && switcherData.orgCode !== "All" ? [switcherData.orgCode] : [],
          customerCode: buyerName
        });
      }
    }
  }, [form, switcherData, userPermissionsData, userDetailsData]);

  useEffect(() => {
    if (defaultSearchValue && Object.keys(defaultSearchValue).length) {
      form.resetFields();
      form.setFieldsValue({
        customerCode: defaultSearchValue.customerCode,
        orgCode: defaultSearchValue.orgCode,
      });
    }
  }, [form, defaultSearchValue]);

  return (
    <div className="deposit-overview-wrapper">
      <Card
        style={{ boxShadow: "0 5px 9px #dedbdb" }}
        bodyStyle={{ paddingTop: 0 }}
        title="查询保证金总览"
        headStyle={{
          backgroundColor: "white",
          borderBottom: "none"
        }}>
        <div className="search-filter">
          <Form
            form={form}
            name="search_filter_form"
            layout="vertical"
            onFinish={handleMySubmit}
            initialValues={{
              customerCode: null,
              orgCode: [],
            }}
          >
            <Row gutter={{ xs: 8, md: 16 }}>
              <Col md={5} xs={24} >
                {props.userPermissionsData["CNInternalUsers"] && (
                  <Form.Item name="customerCode">
                    <RemoteSelect
                      dataTestId="test-internalUser-customerCode-select-01"
                      placeholder="请输入公司名称"
                      fetchOptions={fetchCustomerOptions_Internal}
                      loading={isSelectFetching}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                )}
                {props.userPermissionsData["CNExternalUsers"] && !props.userDetailsData?.isGroup && (
                  <Form.Item name="customerCode">
                    <Input disabled />
                  </Form.Item>
                )}
                {/* 外部用户且为集团账户的买方输入框,当前不用判断是否为饲料厂用户 */}
                {props.userPermissionsData["CNExternalUsers"] && props.userDetailsData?.isGroup && (
                  <Form.Item shouldUpdate name="customerCode">
                    <Select
                      showSearch
                      placeholder="请选择公司名称"
                      disabled={isDisabledAction("buyer")}
                      options={buyers}
                      optionFilterProp="label"
                      filterOption={onFilterOption}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col md={6} xs={24}>
                <Form.Item shouldUpdate name="orgCode">
                  <Select
                    placeholder="请选择卖方公司"
                    mode="multiple"
                    maxTagCount={"responsive" as const}
                    options={allSupplierMutiple()}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col className="hdp-uf hdp-uf-vc hdp-uf-ww">
                <Form.Item shouldUpdate>
                  {() => (
                    <>
                      <Button
                        data-testid="reset-btn"
                        style={{ marginRight: 10, backgroundColor: "#5c5c5c" }}
                        className="reset-btn"
                        onClick={resetSearch}
                      >
                        清空
                      </Button>
                      <Button
                        data-testid="test-search-basic-filters-01"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        icon={<SearchOutlined />}
                        disabled={!form.getFieldValue("customerCode")}
                      >
                        查询
                      </Button>
                    </>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="content">
          {depositOverviewData && Object.keys(depositOverviewData).length > 0 ? (
            <div className="search-result">
              <Row className="result-row" justify="start" gutter={[{ xs: 8, md: 16 }, { xs: 8, md: 16 }]}>
                <Col md={9} xs={24}>
                  <div className="result-col-item">
                    <span className="label">已支付保证金余额（元）：</span>
                    <span className="value">{returnMarginBalance(depositOverviewData.depositReceived, depositOverviewData.marginCallReceived)}</span>
                  </div>
                  <div className="result-col-item">
                    <span className="label">已支付履约保证金余额（元）：</span>
                    <span className="value">{depositOverviewData.depositReceived === null ? "-" : formatReverseNumberToFixedThousands(depositOverviewData.depositReceived, 2)}</span>
                  </div>
                  <div className="result-col-item">
                    <span className="label">已支付追加保证金余额（元）：</span>
                    <span className="value">{depositOverviewData.marginCallReceived === null ? "-" : formatReverseNumberToFixedThousands(depositOverviewData.marginCallReceived, 2)}</span>
                  </div>
                </Col>
                <Col md={9} xs={24}>
                  <div className="result-col-item">
                    <span className="label">应支付保证金总额（元）：</span>
                    <span className="value">{returnTotalMarginPayable(depositOverviewData.depositReceivable, depositOverviewData.marginCallReceivable)}</span>
                  </div>
                  <div className="result-col-item">
                    <span className="label">应支付履约保证金（元）：</span>
                    <span className="value">{depositOverviewData.depositReceivable === null ? "-" : formatNumberToFixedThousands(depositOverviewData.depositReceivable, 2)}</span>
                  </div>
                  <div className="result-col-item">
                    <span className="label">应支付追加保证金（元）：</span>
                    <span className="value">{depositOverviewData.marginCallReceivable === null ? "-" : formatNumberToFixedThousands(depositOverviewData.marginCallReceivable, 2)}</span>
                  </div>
                </Col>
              </Row>
              {
                timeStamp && (
                  <Row>
                    <Col className="tips time-stamp-notice" md={24} xs={24}>
                      <ExclamationCircleOutlined />
                      <span className="label">以上数据系截止至【{moment(timeStamp).format("YYYY年MM月DD日 HH:mm")}】，请以实际为准。</span>
                    </Col>
                  </Row>
                )
              }
            </div>
          ) : (<div className="tips">
            <ExclamationCircleOutlined />
            <span className="label">请输入以上信息后查看。</span>
          </div>
          )}
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userPermissionsData: state.userPermissions,
  switcherData: state.switcher,
  userDetailsData: state.userDetails
});

export default connect(mapStateToProps, null)(AccountOverview);