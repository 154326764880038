import { useCallback, useState } from "react";
import { Modal, Button } from "antd";
import { deleteBanTimeApi } from "@/api/slotbooking";

const DeleteConfirm = (props: any) => {
  const delId = props.delId;
  const itemStatus = props.itemStatus;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = useCallback(() => {
    deleteBanTimeApi({ id: delId }).then(res => {
      if (res.data) {
        props.getListData();
      }
    });
    setIsModalVisible(false);
  }, [delId, props]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleVisible = useCallback(() => {
    setIsModalVisible(!isModalVisible);
  }, [isModalVisible]);

  return (
    <>
      <Button
        type="link"
        onClick={handleVisible}
        disabled={itemStatus === "1"}
        style={{
          padding: 0,
          width: 28,
          height: 22,
          color: `${itemStatus === "1" ? "rgba(0, 0, 0, 0.25)" : "#D9001B"}`
        }}
      >
        删除
      </Button>
      <Modal
        title="确认删除？"
        maskClosable={false}
        visible={isModalVisible}
        footer={
          <Button type="primary" onClick={handleOk}>
            确定
          </Button>
        }
        onCancel={handleCancel}
      >
        您确定删除该条配置时间吗？
      </Modal>
    </>
  );
};
export default DeleteConfirm;
