import React, { useCallback, useEffect, useState } from "react";
import { AutoComplete } from "antd";

// 车辆限载吨位输入或者选择
const TruckLoadingLimitItem: React.FC<{
  truckLoadingLimitOptions: any[];
  value?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: (value: string, option: any) => void;
}> = props => {
  const [value, setValue] = useState<string | undefined>(props.value);

  const onHandleChange = useCallback(
    (newValue: string, currentOption: any) => {
      setValue(newValue);
      props.onChange && props.onChange(newValue, currentOption);
    },
    [props]
  );

  useEffect(() => {
    if (props.truckLoadingLimitOptions?.length > 0) {
      const newValue = props.truckLoadingLimitOptions?.[0]?.value;
      onHandleChange(newValue, props.truckLoadingLimitOptions?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.truckLoadingLimitOptions]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <AutoComplete
      placeholder="请输入"
      value={value}
      options={props.truckLoadingLimitOptions}
      onChange={onHandleChange}
    />
  );
};

export default TruckLoadingLimitItem;
