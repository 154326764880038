import { useCallback, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Row, message } from "antd";
import { DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { downloadCustomerAccountItemList, getCustomerAccountItemListApi, getCustomerAccountItemTimeApi, savePayableDetailsFieldsOrderApi } from "@/api/availableBalance";
import { downloadFile, formatNumberToFixedThousands } from "@/utils/common";
import SearchFilter from "./components/searchFilters";
import { deviceWidth, isMobileDevice } from "@/utils";
import FieldSetting from "@/pages/common/fieldSetting";
import TableList from "./components/tableList";
import moment from "moment";

import "./index.scss";

interface Props {
  switcher: any,
  userDetails: any,
  permissions: any,
  defaultSearchValue?: any
}
const PayableDetails = (props: Props) => {
  const { permissions, switcher, userDetails, defaultSearchValue } = props;
  const [tableData, setTableData] = useState([]);
  const [fieldsDesc, setFieldsDesc] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [shouldPayTotal, setShouldPayTotal] = useState(null);
  const [searchValue, setSearchValue] = useState({} as any);
  const [customerAccountItemTime, setCustomerAccountItemTime] = useState("");

  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    showSizeChanger: true,
    showQuickJumper: true,
    size: deviceWidth() > 576 ? ("default" as any) : ("small" as any),
  });

  // table翻页回调函数
  const onPaginationChange = (page: number) => {
    setPagination({ ...pagination, current: page })
  };

  // 可根据角色不同，给到不同的字段信息
  const filterFieldsList = useCallback((dataSource: any) => {
    if (dataSource) {
      const externalUsersIgnoreFields: any = ["profitCenter", "localCurrency"];
      if (permissions.CNExternalUsers) {
        dataSource = dataSource.filter((val: any) => !externalUsersIgnoreFields.includes(val.value));
      }
      return dataSource;
    }
  }, [permissions.CNExternalUsers]);

// 获取数据截止时间
const getCustomerAccountItemTime = useCallback((params: object) => {
  getCustomerAccountItemTimeApi(params)
    .then((res: any) => {
      if (res?.data) {
        // 获取现金余额数据截止时间
        const customeraccountitemdetailsData: any = res?.data.find((val: any) => {
          return val.entityName === "customeraccountitemdetails";
        });
        setCustomerAccountItemTime(customeraccountitemdetailsData?.updatedDateTime || "");
      }
    })
    .catch((exception: any) => {
      setCustomerAccountItemTime("");
      console.log(`Failure: ${exception}`);
    })
}, [setCustomerAccountItemTime]);

  // 获取应付货款明细tableData
  const getPayableDetailsTableData = useCallback((params: any) => {
    setLoading(true);
    getCustomerAccountItemListApi(params)
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else if (res.data) {
          const tableData = Array.isArray(res.data.list) ? res.data.list : [];
          const allFields = JSON.parse(res.data.fieldsOrder.allFields);
          const fieldsDesc = filterFieldsList(JSON.parse(res.data.fieldsOrder.fieldDesc));
          const paginationTemp = { ...pagination, total: res.data.metadata?.totalCount ?? 0 };
          setTableData(tableData);
          setAllFields(allFields);
          setFieldsDesc(fieldsDesc);
          setPagination(paginationTemp);
          setShouldPayTotal(res.data.total);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        setTableData([]);
        setPagination({ ...pagination, current: 1, total: 0 });
        setShouldPayTotal(null);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [filterFieldsList, pagination]);

  const getColumns = useCallback((data: any, dataSource: any, setVisibleFun: any) => {
    savePayableDetailsFieldsOrderApi({
      type: "customerAccountItem",
      fieldDesc: JSON.stringify(data.map((item: any) => dataSource.find((val: any) => val.value === item)).filter((val: any) => val))
    })
      .then(Response => {
        if (Response) {
          getPayableDetailsTableData(searchValue);
          getCustomerAccountItemTime({ customerCode: searchValue.customerCode });
        }
      })
      .catch(() => {
        message.error("字段设置失败，请稍后重试！");
      })
      .finally(() => {
        setVisibleFun(false);
      });
  }, [searchValue, getPayableDetailsTableData, getCustomerAccountItemTime]);

  // 查询应付货款明细 -- to do
  const handleSearch = (value: any) => {
    setSearchValue(value);
    // 获取应付货款明细tableData
    setLoading(true);
    getPayableDetailsTableData(value);
    getCustomerAccountItemTime({ customerCode: value.customerCode });
  };

  // 下载报表
  const handleDownloadReports = useCallback(() => {
    setDownloadLoading(true);
    downloadCustomerAccountItemList(searchValue)
      .then((response: any) => {
        if (response) {
          downloadFile(response, `应付货款明细列表(${moment().format("YYYY-MM-DD")}).xlsx`);
        } else {
          message.error("下载失败，请稍后重试。");
        }
      })
      .catch((exception: any) => {
        message.error("下载失败，请稍后重试。");
        console.log(exception);
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  }, [searchValue]);

  // 对账明细Card组件的extra信心展示
  const cardExtra = () => {
    return (
      <div className="hdp-uf hdp-uf-vc hdp-uf-ww card-extra">
        <div className="extra-total extra-item">
          <span>共 </span>
          <span>{pagination.total}</span>
          <span> 条</span>
        </div>
        {!isMobileDevice() && (
          <div className="extra-item">
            <FieldSetting fieldDesc={fieldsDesc} allFields={allFields} getColumns={getColumns} />
          </div>
        )}
        <div className="extra-item">
          <Button
            data-testid="test-download-reports"
            type="primary"
            loading={downloadLoading}
            icon={<DownloadOutlined />}
            disabled={pagination.total === 0}
            onClick={handleDownloadReports}>
            下载报表
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="payable-details-wrapper">
      <div className="search-filter">
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb" }}
          bodyStyle={{ paddingTop: 0 }}
          title="应付货款明细"
          headStyle={{
            backgroundColor: "white",
            borderBottom: "none"
          }}
        >
          <SearchFilter defaultSearchValue={defaultSearchValue} doSearch={handleSearch} permissions={permissions} userDetails={userDetails} switcher={switcher} />
        </Card>
      </div>
      <div className="content">
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
          bodyStyle={{ paddingTop: 0 }}
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
          title="应付货款明细（总额为列表显示记录的所有金额的汇总）"
          extra={cardExtra()}>
          <Row style={{ marginTop: 0, marginBottom: 20, padding: "0 13px" }}>
            <Col md={6} xs={12}>
              <div className="money-item">
                <span className="label">应付货款总额（元）：</span>
                <span className="value">{shouldPayTotal === null ? "-" : formatNumberToFixedThousands(shouldPayTotal, 2)}</span>
              </div>
            </Col>
          </Row>
          <TableList
            tableData={tableData}
            pagination={{
              ...pagination,
              onChange: onPaginationChange
            }}
            fieldsDesc={fieldsDesc}
            loading={loading}
          />
          {customerAccountItemTime &&
            <div className="tips">
              <ExclamationCircleOutlined />
              <span className="label">以上数据系截止至【{moment(customerAccountItemTime).format("YYYY年MM月DD日 HH:mm")}】，请以实际为准。</span>
            </div>}
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  switcher: state.switcher,
  userDetails: state.userDetails,
  permissions: state.userPermissions
});

export default connect(mapStateToProps, null)(PayableDetails);